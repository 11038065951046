import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const AddOnBusinessModel = () => {
  const { t } = useTranslation('AddOnBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 1,
    title: t('title_bm01'),
    subTitle: t('subTitle_bm01'),
    main: t('main_bm01'),
    when: t('when_bm01'),
    who: t('who_bm01'),
    what: t('what_bm01'),
    how: t('how_bm01'),
    why: t('why_bm01'),
    examples: rtl
      ? [
          'ريان إير - 1985 - شركة الخطوط الجوية الأيرلندية الإقليمية',
          'Bosch Engineering GmbH (BEG) - 1999 - شركة التصنيع الألمانية',
          'مرسيدس بنز - 1926 - شركة السيارات الألمانية متعددة الجنسيات',
          'بي أم دبليو - 1916 - شركة ألمانية متعددة الجنسيات',
          'SAP - 1972 - شركة البرمجيات الألمانية متعددة الجنسيات',
          'سيجا - 1960 - مطور وناشر ألعاب فيديو يابانية متعددة الجنسيات.',
        ]
      : [
          'Ryanair - 1985 - Regional Irish Airline Company',
          'Bosch Engineering GmbH (BEG) - 1999 - German Company',
          'Mercedes-Benz - 1926 - German Automotive Corporation',
          'BMW - 1916 - German Company',
          'SAP - 1972 - German Software Corporation',
          'Sega - 1960 - Japanese Video Game Developer',
        ],
    tags: rtl
      ? [
          ' أسواق يصعب تقسيمها',
          'تفضيلات العملاء المتباينة',
          'ميزات اضافية',
          'عمليات الإعداد المتميزة',
          'تكلفة منخفضة',
          'عدد كبير من العملاء',
          'مُكَمِّلات',
        ]
      : [
          'Hard-to-Segment Markets',
          'Divergent Customer Preferences',
          'Extra Features',
          'Premium Set-ups',
          'Low Cost',
          'High Number Customers',
          'Accessories',
        ],
    hashTagType: 'businessModelTemp',
    considerations: t('considerations_bm01'),
    combinations: ['$LBM54', '$LBM40', '$LBM22'],
  }
}

export default AddOnBusinessModel
