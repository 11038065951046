import React from 'react'
import MemberGotOutCover from './ChannelNotificationModelComps/MemberGotOutCover'
import NewChannelAdmissionRequestReplyCover from './ChannelNotificationModelComps/NewChannelAdmissionRequestReplyCover'
import NewChannelPostCover from './ChannelNotificationModelComps/NewChannelPostCover'
import ChannelMentionCover from './ChannelNotificationModelComps/ChannelMentionCover'
import ListItemScreenViewModels from './ListItemScreenViewModels'

const ChannelNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'visitor',
      conditions: [<NewChannelAdmissionRequestReplyCover {...notifProps} />],
    },
    {
      relation: 'holders',
      conditions: [
        <MemberGotOutCover {...notifProps} />,
        <NewChannelPostCover {...notifProps} />,
        <ChannelMentionCover {...notifProps} />,
      ],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default ChannelNotificationModel
