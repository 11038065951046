import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import EditableCardsWindow from '../../../../../../../fileSystem/EditableCardsWindow'
import TitleHeader from '../../../../../../../smallComponents/Header'
import NRAConceptCardContent from '../NewResearchActivityFormComps/NRAConceptCardContent'
import NewConceptForm from './ResearchMainConceptsComps/NewConceptForm'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import i18next from 'i18next'

const ResearchMainConcepts = ({ DCGProps, depManager, activity }) => {
  const [isUpdated, setIsUpdated] = useState(false)
  const { project } = DCGProps
  const [resActConcepts, setResActConcepts] = useState([])
  const [isLoading, setIsLoading] = useState()
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const fetchResActConcepts = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/researchConcepts/ResActResearchConcepts/${activity._id}`
        )
        setResActConcepts(res.data.researchConcepts)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (project?._id) fetchResActConcepts()
  }, [project?._id, isUpdated])

  const modResearchConcepts = resActConcepts?.map((researchactivity) => ({
    ...researchactivity,
    title: researchactivity.Title,
    id: researchactivity._id,
  }))
  const lang = i18next.language
  const rtl = lang === 'ar'
  const authorised =
    user?._id === depManager?.userId || user?._id === project?.userId

  return (
    <>
      <TitleHeader
        headerText={rtl ? 'المبادئ الرئيسية للبحث' : 'Research Main Concepts'}
      />
      <EditableCardsWindow
        showAddFab={authorised}
        cards={modResearchConcepts}
        DCGProps={DCGProps}
        StandardCardContent={NRAConceptCardContent}
        modelProps={{ researchActivityId: activity._id, setResActConcepts }}
        NewElementFormComponentProps={{
          setResActConcepts,
          activity,
        }}
        SearchIDFExtConfigs={{
          researchConcept: true,
        }}
        profileProps={{ setResActConcepts, depManager }}
        enableSearching
        NewElementFormComponent={NewConceptForm}
        newFormDialogTitle={rtl ? 'مبدأ جديد' : 'New Concept'}
        placeholderValue={rtl ? 'مبادئ' : 'Concepts'}
        wrpperStyle={{
          maxHeight: 500,
          minHeight: 250,
          overflowY: 'auto',
        }}
      />
    </>
  )
}

export default ResearchMainConcepts
