import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ar from 'javascript-time-ago/locale/ar'
import { convertNumbers2Arabic } from 'to-arabic-numbers'
import i18next from 'i18next'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ar)

const TimeAgoFormattor = ({ date }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const timeAgo = rtl ? new TimeAgo('ar') : new TimeAgo('en-US')
  return rtl
    ? convertNumbers2Arabic(timeAgo.format(new Date(date))).replace(
        'قبل',
        'منذ'
      )
    : timeAgo.format(new Date(date))
}

export default TimeAgoFormattor
