import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const TaskMarkedDoneReplyCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `مسؤول المهمة الخاصة بك قام بالرد على طلب اكتمال المهمة`
    : 'Your task moderator has responded to your task completion request'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default TaskMarkedDoneReplyCover
