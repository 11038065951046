import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  NotificationBodyWithAccessCard,
  NotificationViewModel,
} from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const NewProjectProjectMeetingReplyNotif = (props) => {
  const { notification, sender, isDarkModeActive } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const response = notification?.accompaniedData?.response
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <article style={{ width: '100%' }}>
              <Link to={`/profile/${sender?._id}`}>{sender.name} </Link>
              {rtl
                ? 'قام بالرد على دعوة الاجتماع بـ '
                : ' replied to your meeting invitation by'}{' '}
              <strong>
                {response === 'refused'
                  ? rtl
                    ? 'الرفض'
                    : 'refusing'
                  : response === 'modify'
                  ? rtl
                    ? 'رغبة فى التعديل'
                    : 'Modification Request'
                  : rtl
                  ? 'القبول'
                  : 'accepting'}
              </strong>
              <br />
              <div
                style={{
                  margin: '20px 5px 5px 5px',
                  border: `1px dashed ${isDarkModeActive ? 'white' : 'black'}`,
                  borderRadius: 15,
                  padding: 10,
                  display:
                    !notification?.accompaniedData?.modification && 'none',
                }}
              >
                {notification?.accompaniedData?.modification}
              </div>
            </article>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default NewProjectProjectMeetingReplyNotif
