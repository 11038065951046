import React from 'react'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import NotificationViewModel from '../../../NotificationViewModel'
import { NotificationBodyWithAccessCard } from '../../..'
import i18next from 'i18next'

const GlobalInquiryRemoveContributorNotif = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? `(ت)قام ${sender?.name} بحذفك من قائمة المشاركين و كذلك قناة المشاركة الخاصة برؤيته/رؤيتها`
              : `${sender?.name} has removed you from his(her) global inquiry contributors list and the associated channel`
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default GlobalInquiryRemoveContributorNotif
