import { useState, useEffect } from 'react'

export default function ModifiedFormChecker(
  configs,
  formData,
  // if the form is empty and you want to check only if it's not empty and editied - like in creating new form that doeas not require all the data to be filled
  checkNotEmpty,
  // if you want to comapre the data of the form to another object - like in updating values
  comparedData
) {
  //   checking the ediitng state
  const [isFormModified, setIsFormModified] = useState(false)

  useEffect(() => {
    let tempArray = []
    configs.forEach((config) => {
      return formData[config.title.split(' ').join('')] ===
        (checkNotEmpty ? '' : comparedData[config.title.split(' ').join('')])
        ? tempArray.push(0)
        : tempArray.push(1)
    })

    let boolean = Boolean(
      tempArray.reduce((prev, current) => prev + current) > 0
    )
    setIsFormModified(!boolean)
  }, [formData, comparedData])
  return isFormModified
}
