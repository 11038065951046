import React from 'react'
import TitleHeader from '../../../../../../../smallComponents/Header'
import SingleContributorModel from '../../../../../../../membersSystems/SingleContributorModel'
import OneUserCall from '../../../../../../../apiCalls/OneUserCall'
import Loading from '../../../../../../../smallComponents/Loading'
import i18next from 'i18next'

const InquirPairing = ({ DCGProps, inquiry, user }) => {
  const { isLoading, modUser } = OneUserCall({
    userId: inquiry?.pairedUserId,
  })
  const lang = i18next.language
  const rtl = lang === 'ar'
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <TitleHeader headerText={rtl ? 'إقتران المتطلب' : 'Inquiry Pairing'} />
      <SingleContributorModel
        windowType={'userContributor'}
        member={{
          ...modUser,
          hashTagType: 'userContributor',
        }}
        DCGProps={DCGProps}
        emptySentence={
          rtl
            ? 'هذا المتطلب لم يتم ربطه بعد بأى مساهم، يمكنك ان تختار من طلبات الاقتران'
            : 'This inquiry is not paired yet, Choose a contributor from the requests to Pair it with.'
        }
      />
    </>
  )
}

export default InquirPairing
