import React from 'react'
import NoResultsModel from '../NoResultsModel'

const SearchNonAlphebetic = ({
  searchResults,
  SearchedElementComponent,
  freeSearch,
  SearchedElementComponentProps,
  searchQuery,
  searchElements,
  // for file system
  extraCondition,
  elementKey,
}) => {
  const responsesComponents = [
    <>
      {(
        extraCondition
          ? extraCondition && searchQuery.length > 0
          : searchQuery.length > 0
      ) ? (
        searchResults.length > 0 ? (
          searchResults.map((element, elementIndex) => (
            <SearchedElementComponent
              key={elementKey ? element[elementKey] : elementIndex}
              elementIndex={elementIndex}
              element={element}
              props={SearchedElementComponentProps}
            />
          ))
        ) : (
          <NoResultsModel searchQuery={searchQuery} />
        )
      ) : (
        searchElements?.map((element, elementIndex) => (
          <SearchedElementComponent
            key={elementKey ? element[elementKey] : elementIndex}
            element={element}
            elementIndex={elementIndex}
            props={SearchedElementComponentProps}
          />
        ))
      )}
    </>,
    // free search when i need just results to appear when they exist withiut any other componentd, and this is suitable for example for the Hashtag suggetsing system
    searchResults?.map((element, elementIndex) => (
      <SearchedElementComponent
        key={elementKey ? element[elementKey] : elementIndex}
        elementIndex={elementIndex}
        element={element}
        props={SearchedElementComponentProps}
      />
    )),
  ]

  return freeSearch
    ? (
        extraCondition
          ? extraCondition && searchQuery.length > 0
          : searchQuery.length > 0
      )
      ? responsesComponents[1]
      : null
    : responsesComponents[0]
}

export default SearchNonAlphebetic
