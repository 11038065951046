import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import NotificationViewModel from '../../../NotificationViewModel'
import i18next from 'i18next'

const CampaignChanges = (props) => {
  const style = {
    margin: 'auto 4px',
  }
  const { campaign, project, notification } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          notification={notification}
          {...props}
          notificationBodyContent={
            <>
              {rtl ? `حملة ترويجية` : 'A campaign'}
              <span style={style}>{campaign.title}</span> {rtl ? 'فى' : 'in'}
              <Link to={`/projectProfile/${project?._id}`} style={style}>
                {project?.ProjectName}
              </Link>{' '}
              {rtl ? `تم تعديلها` : `has been modified`}
            </>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default CampaignChanges
