import React from 'react'
import EmptyComponentModel from '../emptyComponentModel/EmptyComponentModel'
import SearchIcon from '@mui/icons-material/Search'
import withStyles from '@mui/styles/withStyles'
import CustomEmpty from '../smallComponents/CustomEmpty'
import i18next from 'i18next'

const styles = () => ({
  warpper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  searchIcon: {
    fontSize: 20,
  },
  query: {
    fontStyle: 'oblique',
    fontWeight: 400,
  },
})

const NoResultsModel = ({ searchQuery, classes, isDarkModeActive }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <CustomEmpty
      isDarkModeActive={isDarkModeActive}
      emptyMssg={
        <EmptyComponentModel
          emptySentence={
            <section className={classes.warpper}>
              <SearchIcon
                className={classes.searchIcon}
                style={{
                  margin: rtl ? '3px 0px -3px 10px' : '3px 10px -3px 0',
                }}
              />
              <span>
                {searchQuery.length === 1 ? (
                  <>
                    {rtl
                      ? 'لا توجد نتائج بحث تبدأ بـ'
                      : 'No Results Start with'}{' '}
                    <span className={classes.query}>{searchQuery}</span>
                  </>
                ) : (
                  <>
                    {rtl ? 'لا توجد نتائج بحث ' : 'No Search Results'}
                    {searchQuery?.length > 0 ? (
                      <>
                        {' '}
                        {rtl ? 'لـ' : 'for'}{' '}
                        <span className={classes.query}>{searchQuery}</span>
                      </>
                    ) : null}
                  </>
                )}
              </span>
            </section>
          }
        />
      }
      imageKey='noGSResults'
    />
  )
}

const WrappedNoResultsModel = withStyles(styles)(NoResultsModel)

export default WrappedNoResultsModel
