import {
  FaFilePdf,
  FaServicestack,
  FaTasks,
  FaBusinessTime,
  FaRegStar,
  FaSuitcase,
  FaMagic,
  FaCalculator,
  FaResolving,
  FaApper,
  FaUser,
  FaPaintRoller,
  FaMoneyBill,
  FaStrava,
} from 'react-icons/fa'
import { FcBarChart, FcIdea, FcQuestions } from 'react-icons/fc'
import { GrAction, GrConfigure, GrChannel } from 'react-icons/gr'
import { AiFillFastForward, AiOutlineAntDesign } from 'react-icons/ai'
import PDFDetectorElement from '../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DectectorsElements/PDFDetectorElement'
import { GiMushroomHouse, GiVote } from 'react-icons/gi'

const HashTagCardsConfigs = () => {
  return [
    {
      hashTagType: 'pdf',
      Icon: FaFilePdf,
      iconColor: '#5B61A1',
      hashTagColor: '#004857',
      titleProperty: 'name',
      TextFormattorComp: PDFDetectorElement,
      hashTagSymbol: '$PDF$',
    },
    {
      hashTagType: 'product',
      Icon: FaServicestack,
      iconColor: '#002D82',
      hashTagColor: '#006A89',
      TextFormattorComp: null,
      hashTagSymbol: '$PROD$',
      titleProperty: 'ProductName',
    },
    {
      hashTagType: 'productStrategy',
      Icon: FaStrava,
      iconColor: '#002A7C',
      hashTagColor: '#007AA2',
      TextFormattorComp: null,
      hashTagSymbol: '$PRODST$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'job',
      Icon: FaBusinessTime,
      iconColor: '@00216D',
      hashTagColor: '#008DC0',
      TextFormattorComp: null,
      hashTagSymbol: '$JOB$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'task',
      Icon: FaTasks,
      iconColor: '#001A5F',
      hashTagColor: '#009DCF',
      TextFormattorComp: null,
      hashTagSymbol: '$TASK$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'resource',
      Icon: FaRegStar,
      iconColor: '#6C85BD',
      hashTagColor: '#86BEDA',
      TextFormattorComp: null,
      hashTagSymbol: '$RES$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'issue',
      Icon: FaSuitcase,
      iconColor: '#003B85',
      hashTagColor: '#AED3E3',
      TextFormattorComp: null,
      hashTagSymbol: '$ISS$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'campaign',
      Icon: FaMagic,
      iconColor: '#003777',
      hashTagColor: '#003F5D',
      TextFormattorComp: null,
      hashTagSymbol: '$CAMP$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'businessModelTemp',
      Icon: FaCalculator,
      iconColor: '#002A5E',
      hashTagColor: '#00527C',
      TextFormattorComp: null,
      hashTagSymbol: '$BMT$',
      titleProperty: 'title',
    },
    {
      hashTagType: 'idea',
      Icon: FcIdea,
      iconColor: null,
      hashTagColor: '#00609C',
      TextFormattorComp: null,
      hashTagSymbol: '$MI$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'inquiryProfile',
      Icon: FcQuestions,
      iconColor: null,
      hashTagColor: '#006DB2',
      TextFormattorComp: null,
      hashTagSymbol: '$MIQ$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'globalInquiry',
      Icon: GrAction,
      iconColor: '#002F5C',
      hashTagColor: '#4E97D1',
      TextFormattorComp: null,
      hashTagSymbol: '$MGIQ$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'markStraAspect',
      Icon: AiFillFastForward,
      iconColor: '#006DB2',
      hashTagColor: '#7BB4E3',
      TextFormattorComp: null,
      hashTagSymbol: '$MSA$',
      titleProperty: 'title',
    },
    {
      hashTagType: 'researchActivity',
      Icon: FaResolving,
      iconColor: '#4E97D1',
      hashTagColor: '#A3CEEF',
      TextFormattorComp: null,
      hashTagSymbol: '$RESACT$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'researchConcept',
      Icon: GrConfigure,
      iconColor: '#7BB4E3',
      hashTagColor: '#002F5C',
      TextFormattorComp: null,
      hashTagSymbol: '$RESCON$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'researchApp',
      Icon: FaApper,
      iconColor: '#A3CEEF',
      hashTagColor: '#00527C',
      TextFormattorComp: null,
      hashTagSymbol: '$RESAPP$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'channel',
      Icon: GrChannel,
      iconColor: '#002F5C',
      hashTagColor: '#00609C',
      TextFormattorComp: null,
      hashTagSymbol: '$PCHAN$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'projectResource',
      Icon: FaRegStar,
      iconColor: '#00527C',
      hashTagColor: '#006DB2',
      TextFormattorComp: null,
      hashTagSymbol: '$PRORES$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'investPack',
      Icon: FaPaintRoller,
      iconColor: '#006DB2',
      hashTagColor: '#4E97D1',
      TextFormattorComp: null,
      hashTagSymbol: '$INVPACK$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'protocol',
      Icon: FaPaintRoller,
      iconColor: '#4E97D1',
      hashTagColor: '#7BB4E3',
      TextFormattorComp: null,
      hashTagSymbol: '$RESPROT$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'finStat',
      Icon: FaMoneyBill,
      iconColor: '#7BB4E3',
      hashTagColor: '#A3CEEF',
      TextFormattorComp: null,
      hashTagSymbol: '$FINST$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'projectContributor',
      Icon: FaUser,
      iconColor: '#A3CEEF',
      hashTagColor: '#002F5C',
      TextFormattorComp: null,
      hashTagSymbol: '$PROJCON$',
      titleProperty: 'name',
    },
    {
      hashTagType: 'meeting',
      Icon: GiMushroomHouse,
      iconColor: '#002F5C',
      hashTagColor: '#004987',
      TextFormattorComp: null,
      hashTagSymbol: '$MEET$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'poll',
      Icon: GiVote,
      iconColor: '#004987',
      hashTagColor: '#004F92',
      TextFormattorComp: null,
      hashTagSymbol: '$POLL$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'productDesign',
      Icon: AiOutlineAntDesign,
      iconColor: '#90B1DB',
      hashTagColor: '#00569D',
      TextFormattorComp: null,
      hashTagSymbol: '$PRODDES$',
      titleProperty: 'Title',
    },
    {
      hashTagType: 'chartedData',
      Icon: FcBarChart,
      iconColor: '',
      hashTagColor: '#90B1DB',
      TextFormattorComp: null,
      hashTagSymbol: '$CHARTED$',
      titleProperty: 'Title',
    },
  ]
}

export default HashTagCardsConfigs
