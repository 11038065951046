import React from 'react'
import AccessCardGenerator from '../../../notificationsSystem/NotificationsComponents/ContractsNotificationsComponents/ContractsNotifsDialogRightComponents/NotificationBodyWithAccessCardComps/AccessCardGenerator'
import TextPost from '../../../postSystem/TextPost'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  wrapper: {
    width: '100%',
    margin: '0 10px 10px 10px !important',
  },
})

const GenericPostBodyModel = ({ postBodyProps, element, classes }) => {
  const { isDarkModeActive, socket, project } = postBodyProps
  return (
    <article
      className={classes.wrapper}
      style={{ color: isDarkModeActive ? 'white' : null }}
    >
      <TextPost {...postBodyProps} margins={'auto 10px 10px 10px'} />
      <AccessCardGenerator
        postMode
        element={element}
        socket={socket}
        modelProps={{ project }}
        isDarkModeActive={isDarkModeActive}
      />
    </article>
  )
}

const WrappedGenericPostBodyModel = withStyles(styles)(GenericPostBodyModel)

export default WrappedGenericPostBodyModel
