import { Button } from '@mui/material'
import React from 'react'
import { AiFillEdit } from 'react-icons/ai'
import ImagesSystem from '../../ImagesSystem'
import withStyles from '@mui/styles/withStyles'
import PostProps from '../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'

const styles = () => ({
  editWrapper: {
    backgroundColor: 'rgba(255,255,255, 0.6)',
    color: 'black',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 4,
    '&:hover': {
      fotnSize: 18,
      backgroundColor: 'rgba(255,255,255, 0.8)',
    },
  },
  labelButton: {
    padding: '0px !important',
    margin: '0px !important',
    textTransform: 'none !important',
    backgroundColor: 'transparent !important',
    borderRadius: '100% !important',
  },
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    borderRadius: 15,
    margin: 15,
  },
})

const NonEmptyEditableGallery = ({
  images,
  isDarkModeActive,
  GeneralDialogOpener,
  hideEdit,
  classes,
}) => {
  const imageProps = PostProps(
    {},
    {
      imageViewerProps: true,
    }
  )
  return (
    <>
      <ImagesSystem
        imagesArray={images}
        commentingSystemView={true}
        openFun={imageProps.imageViewerDialogOpener}
        isDarkModeActive={isDarkModeActive}
      />
      <ImagesSystem imageViewer={true} {...imageProps} imagesArray={images} />
      <section
        style={{
          position: 'absolute',
          right: 0,
          top: 10,
          display: hideEdit && 'none',
        }}
      >
        <Button
          component='label'
          color='inherit'
          className={classes.labelButton}
          onClick={() => GeneralDialogOpener()}
        >
          <span className={classes.editWrapper}>
            <AiFillEdit size={15} />
          </span>
        </Button>
      </section>
    </>
  )
}

const 
WrappedNonEmptyEditableGallery = withStyles(styles)(
  NonEmptyEditableGallery
)

export default WrappedNonEmptyEditableGallery
