import { IconButton } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  sideButtonWrapper: {
    position: 'absolute',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    height: '100%',
    zIndex: 1,
  },
  iconButton: {
    height: '30px !important',
    borderRadius: '50% !important',
    cursor: 'pointer !important',
  },
})

const GallerySideButton = ({
  classes,
  shiftFun,
  ShiftIcon,
  iconButtonEdge,
  rightLeftPosisionting,
  hideIconButton,
  iconColor,
  isDarkModeActive,
}) => {
  return (
    <section
      className={classes.sideButtonWrapper}
      style={rightLeftPosisionting}
    >
      <IconButton
        edge={iconButtonEdge}
        color='inherit'
        className={classes.iconButton}
        style={{ display: hideIconButton ? 'none' : null }}
        onClick={shiftFun}
      >
        <ShiftIcon
          style={{
            color: iconColor && !isDarkModeActive ? iconColor : 'white',
          }}
        />
      </IconButton>
    </section>
  )
}

const WrappedGallerySideButton = withStyles(styles)(GallerySideButton)

export default WrappedGallerySideButton
