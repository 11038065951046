import React from 'react'
import withStyles from '@mui/styles/withStyles'
import {
  ExpandedCardsSystem,
  ComplaintRequestDetails,
  DevOfferUnitDetails,
  InvestOfferUnitDetails,
} from './'
import RemovedItem from '../../smallComponents/RemovedItem'
import { Grid } from 'react-loader-spinner'
import DepartmentRequestDetails from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectToolsComponents/ProjectToolsComponents/ManagerToolsComponents/DepartmentManagerRequestsComps/DepartmentRequestDetails'
import SubDepRequestDetails from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectToolsComponents/ProjectToolsComponents/ManagerToolsComponents/SubDepartmentManagerRequestsComps/SubDepRequestDetails'
import InvestPackRequestDetails from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/InvestmentPackagesComps/InvestmentPackageRequestsComps/InvestPackRequestDetails'
import CampaignOfferDetails from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/CampaignsComponents/CampaignProfileComps/CampaignSpsonsiringOffersComps/CampaignOfferDetails'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'column',
  },
})

const RequestNotificationAccessModel = ({
  requestType,
  requestData,
  notificationBodyContent,
  classes,
  isDarkModeActive,
  project,
  sender,
  socket,
  isLoading,
}) => {
  const requests = [
    {
      requestType: 'complaint',
      configs: {
        UnitCardDetailsComponent: ComplaintRequestDetails,
      },
    },
    {
      requestType: 'development',
      configs: {
        UnitCardDetailsComponent: DevOfferUnitDetails,
      },
    },
    {
      requestType: 'investment',
      configs: {
        UnitCardDetailsComponent: InvestOfferUnitDetails,
      },
    },
    {
      requestType: 'depManager',
      configs: {
        UnitCardDetailsComponent: DepartmentRequestDetails,
      },
    },
    {
      requestType: 'subDepManager',
      configs: {
        UnitCardDetailsComponent: SubDepRequestDetails,
      },
    },
    {
      requestType: 'investment_pack',
      configs: {
        UnitCardDetailsComponent: InvestPackRequestDetails,
      },
    },
    {
      requestType: 'campaign',
      configs: {
        UnitCardDetailsComponent: CampaignOfferDetails,
      },
    },
  ]
  const request = requests.find(
    (req) => req.requestType === requestType
  ).configs
  const lang = i18next.language
  const rtl = lang === 'ar'
  return isLoading ? (
    <section
      style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}
    >
      <Grid
        height='80'
        width='80'
        color='#4fa94d'
        ariaLabel='grid-loading'
        radius='12.5'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
      />
    </section>
  ) : requestData ? (
    <article className={classes.wrapper}>
      <div style={{ fontStyle: 'oblique', padding: 10 }}>
        {notificationBodyContent}
      </div>
      <ExpandedCardsSystem
        cards={[requestData]}
        UnitCardDetailsComponent={request.UnitCardDetailsComponent}
        firsButtonConfigs={{ hideButton: true, props: {} }}
        DCGProps={{ isDarkModeActive, project, sender, socket }}
        searchSystemProps={{}}
        requestsUsers={[
          {
            firstName: sender?.name,
            lastName: '',
            profilePicture: sender?.image,
            ...sender,
          },
        ]}
        // to remove the request currently once it has been deleted
        // setRequests={setRequests}
      />
    </article>
  ) : (
    <RemovedItem
      removedMssg={rtl ? 'لقد تم مسح الطلب' : 'Request has been deleted'}
    />
  )
}

const WrappedRequestNotificationAccessModel = withStyles(styles)(
  RequestNotificationAccessModel
)

export default WrappedRequestNotificationAccessModel
