import i18next from 'i18next'
import { useEffect } from 'react'

const DocumentTitleChanger = (text) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  useEffect(() => {
    if (text) document.title = `${rtl ? 'هيوتوبيا' : 'Hutupia'} | ${text}`
  }, [text, rtl])
}

export default DocumentTitleChanger
