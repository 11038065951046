import React from 'react'
import ListItemScreenViewModels from './ListItemScreenViewModels'
import NewRoomDiscussionNotifCover from './RoomNotificationModelComps/NewRoomDiscussionNotifCover'
import RoomDiscussionMenitonedNotifCover from './RoomNotificationModelComps/RoomDiscussionMenitonedNotifCover'

const RoomNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'creator',
      conditions: [],
    },
    {
      relation: 'mentioned',
      conditions: [<RoomDiscussionMenitonedNotifCover {...notifProps} />],
    },
    {
      relation: 'member',
      conditions: [<NewRoomDiscussionNotifCover {...notifProps} />]
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default RoomNotificationModel
