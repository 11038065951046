import React from 'react'
import { MdVerified } from 'react-icons/md'
import { Link } from 'react-router-dom'
import FreeBar from '../../../skeleton/SkeletonComponents/FreeBar'

const PTLeftName = ({ user: genUser, postProps, wz }) => {
  const { post, isDarkModeActive, project, user: orUser } = postProps
  const user = orUser || genUser
  
  return (
    <Link
      to={
        user?.lastName
          ? `/profile/${post?.userId}`
          : `/projectProfile/${post?.userId}`
      }
      style={{
        color: isDarkModeActive ? 'white' : 'black',
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      }}
    >
      <span>
        {user?.lastName || project?.ProjectName || user?.ProjectName ? (
          user?.firstName ? (
            user?.firstName + ' ' + user?.lastName
          ) : (
            project?.ProjectName || user?.ProjectName
          )
        ) : (
          <FreeBar height={17} isDarkModeActive={isDarkModeActive} />
        )}
        {user?.lastName && user?.verified && (
          <MdVerified
            size={!wz && 15}
            color='blue'
            style={{ margin: wz ? '4px 5px -4px 5px' : '2px 4px -2px 4px' }}
          />
        )}
      </span>
    </Link>
  )
}

export default PTLeftName
