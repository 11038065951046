import i18next from 'i18next'
import React from 'react'

const NoElementsModel = ({ elements }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <section
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <span style={{ fontWeight: 500, margin: 20 }}>
        {rtl ? `لا يوجد ${elements} بعد` : `No ${elements} yet`}
      </span>
    </section>
  )
}

export default NoElementsModel
