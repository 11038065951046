import React, { useState } from 'react'
import TitleHeader from '../smallComponents/Header'
import FormDataInitialValueExtractor from '../formsSystem/FormDataInitialValueExtractor'
import FormDataLangModulator from '../formsSystem/FormDataLangModulator'
import GeneralPollConfigs from './PollAdditionDialogContentComps/GeneralPollConfigs'
import NewPollOptionForm from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectInteractionsComponents/ProjectInteractionsDialogContentComps/ProjectVotingsComps/NewPollFormComps/NewPollOptionForm'
import { MdAdd } from 'react-icons/md'
import ButtonsSystem from '../buttonsSystem/ButtonsSystem'
import ModelDialogWrappingIII from '../dialogModelUnit/ModelDialogWrappingIII'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import WindowProps from '../windowViewer/WindowProps'
import FormsSystem from '../formsSystem/FormsSystem'
import NewPollOptionsView from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectInteractionsComponents/ProjectInteractionsDialogContentComps/ProjectVotingsComps/NewPollFormComps/NewPollOptionsView'
import i18next from 'i18next'

const PollAdditionDialogContent = ({
  setPollData,
  windowCloser,
  isDarkModeActive,
  chosenHashTagElements,
  setChosenHashTagElements,
}) => {
  const [options, setOptions] = useState([])
  const [formData, setFormData] = useState({})
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const newformData = FormDataLangModulator(formData, t)
  FormDataInitialValueExtractor(GeneralPollConfigs(), setFormData)
  const {
    isWindowOpen: isWindowOpenII,
    windowOpener: windowOpenerII,
    windowCloser: windowCloserII,
  } = WindowProps()
  const dialogsArray = [
    {
      open: isWindowOpenII,
      content: (
        <NewPollOptionForm
          setOptions={setOptions}
          windowCloser={windowCloserII}
          DCGProps={{ isDarkModeActive }}
          setChosenHashTagElements={setChosenHashTagElements}
          chosenHashTagElements={chosenHashTagElements}
        />
      ),
    },
  ]
  const buttonsArray = [
    {
      title: rtl ? 'إضافة' : 'Add',
      funs: () => {
        setPollData({
          ...newformData,
          chosenHashTagElements,
          options: options?.map((op) => ({
            Title: op?.Title,
            _id: uuidv4(),
            votersIds: [],
          })),
        })
        windowCloser()
      },
      disabled: options?.length === 0,
    },
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: () => {
        windowCloser()
      },
    },
  ]
  return (
    <div style={{ color: isDarkModeActive && 'white' }}>
      <TitleHeader
        headerText={rtl ? 'أعد تفاصيل التصويت' : 'Set Voting Details'}
      />
      <FormsSystem
        formComponentsArray={GeneralPollConfigs()}
        formComponentsValues={formData}
        setFormComponentsValues={setFormData}
        setChosenHashTagElements={setChosenHashTagElements}
        chosenHashTagElements={chosenHashTagElements}
        isDarkModeActive={isDarkModeActive}
      />
      <TitleHeader
        headerText={
          <section
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span style={{ marginRight: 10 }}>
              {rtl ? 'أعد إختيارات التصويت' : 'Set Voting Options'}
            </span>
            <MdAdd
              size={26}
              style={{ margin: '3px auto -3px 10px', cursor: 'pointer' }}
              onClick={() => windowOpenerII()}
            />
          </section>
        }
      />
      <NewPollOptionsView options={options} />
      <ModelDialogWrappingIII
        dialogsArray={dialogsArray}
        isDarkModeActive={isDarkModeActive}
        dialogWidth='450px'
        dialogHeight='250px'
      />
      <ButtonsSystem buttonsArray={buttonsArray} />
    </div>
  )
}

export default PollAdditionDialogContent
