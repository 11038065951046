import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ContributionContractEndingReplyCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `قام ${sender?.name} بالرد على طلبك لإنهاء مشاركته`
    : `${sender?.name} replied about ending his contribution`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default ContributionContractEndingReplyCover
