import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NavigatorGenerator from '../../../../../../../navigatorCardsModel/NavigatorGenerator'
import i18next from 'i18next'

const IdeaNavigators = (props) => {
  const { idea } = props
  const [sharedPosts, setSharedPosts] = useState([])
  const [channels, setChannels] = useState([])
  useEffect(() => {
    const fetchIdeaPosts = async () => {
      try {
        const res = await axios.get(`/ideas/ideaPosts/${idea?._id}`)
        const resChannels = await axios.get(
          `/channels/elementChannels/${idea?._id}`
        )
        setSharedPosts(res.data.ideaPosts)
        setChannels(resChannels.data.channels)
      } catch (error) {
        console.log(error)
      }
    }
    fetchIdeaPosts()
  }, [idea?._id])

  const lang = i18next.language
  const rtl = lang === 'ar'
  const navigatorsArray = [
    {
      headerText: rtl ? 'منشورات' : 'Posts',
      unitsArray: sharedPosts,
      NavigatorCardsModelProps: {},
    },
    {
      headerText: rtl ? 'قنوات تستخدم هذه الفكرة' : 'Channels uses this Idea',
      unitsArray: channels,
      NavigatorCardsModelProps: {},
    },
  ]
  return <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
}

export default IdeaNavigators
