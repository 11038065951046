import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  NotificationBodyWithAccessCard,
  NotificationViewModel,
} from '../../../..'
import DepartmentsEnumArray from '../../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/DepartmentsEnumArray'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const DepartmentManagerNewNote = (props) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { sender, project, notification } = props
  const style = {
    margin: 'auto 4px',
  }
  NotificationAutoInterUpdator(props)
  const subDepId = notification?.accompaniedData?.subDepId
  const departmentId = notification?.accompaniedData?.departmentId
  const department = DepartmentsEnumArray.find(
    (dep) => dep?.id === departmentId
  )?.title
  const [subdep, setSubdep] = useState()
  useEffect(() => {
    const fetvhsubdep = async () => {
      try {
        const res = await axios.get(`/subdepartments/${subDepId}`)
        setSubdep(res.data.subdepartment)
      } catch (error) {
        console.log(error)
      }
    }
    if (subDepId) fetvhsubdep()
  }, [subDepId])
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <>
              {subDepId
                ? rtl
                  ? `قام مدير القسم الفرعى الخاص بك `
                  : `Your ${subdep?.Name} sub-department manager`
                : rtl
                ? `قام مدير القسم الخاص بك `
                : `Your ${department} department manager`}
              <Link to={`/profile/${sender?._id}`} style={style}>
                {sender?.name}
              </Link>{' '}
              {rtl ? `فى` : 'in'}
              <span style={style}>
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
              </span>{' '}
              {rtl ? `بإضافة ملاحظ جديدة` : 'added new note'}
            </>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default DepartmentManagerNewNote
