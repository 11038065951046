// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBpc-ETKUvz4_XNf-s5oYvZM5xj0dWqfco',
  authDomain: 'hutupia-300a3.firebaseapp.com',
  projectId: 'hutupia-300a3',
  storageBucket: 'hutupia-300a3.appspot.com',
  messagingSenderId: '533648100729',
  appId: '1:533648100729:web:385762b8992e7ba11bf3a8',
  measurementId: 'G-G2CXX9N51S',
}

// const firebaseConfig = {
//  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAFE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MESEAURMENT_ID,
// }

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const storage = getStorage()
export const db = getFirestore()
export const authentication = getAuth(app)
