import React from 'react'
import {
  NotificationViewModel,
  NotificationBodyWithAccessCard,
} from '../../../'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const ProductRequestReply = (props) => {
  const { product, project } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'

  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <section>
                الطلب المرسل الى المنتج{' '}
                <span style={style}>{product.productName}</span> تم الرد عليه
              </section>
            ) : (
              <section>
                Your Product <span style={style}>{product.productName}</span>
                Request to project
                <span style={style}>{project?.ProjectName}</span> has been
                refused/ accetpted
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default ProductRequestReply
