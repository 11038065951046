import React from 'react'
import JobNewRequestsCover from './JobNotificationModelComps/JobNewRequestsCover'
import JobRequestReplyCover from './JobNotificationModelComps/JobRequestReplyCover'
import { ListItemScreenViewModels } from '../../../'

const JobNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'creator',
      conditions: [<JobNewRequestsCover {...notifProps} />],
    },
    {
      relation: 'visitor',
      conditions: [<JobRequestReplyCover {...notifProps} />],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default JobNotificationModel
