import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@mui/material'
import FormsComponents from '../../FormsComponents'
import ImageUploaderSystem from '../../../uploadImageSystem/ImageUploaderSystem'
import { MdCamera } from 'react-icons/md'
import EmojiPopover from '../EmojiPopover'
import LinkPlayerAddButton from '../../../postSystem/LinksPlayerSystemComps/LinkPlayerAddButton'
import LinkPlayerPreview from '../../../postSystem/LinksPlayerSystemComps/LinkPlayerPreview'
import PollAddButton from '../../../generalPollSystem/PollAddButton'
import GeneralPollBody from '../../../generalPollSystem/GeneralPollBody'
import i18next from 'i18next'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})

const EditingDialogContent = ({ classes, formsCompsProps }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const {
    images,
    setImages,
    editingDialogClose,
    pathName,
    onSend,
    link,
    setLink,
    pollData,
    setPollData,
  } = formsCompsProps
  const formsCompsPropsII = {
    formComponentIndex: 0,
    ...formsCompsProps,
  }
  const { textContent, setTextContent, ...pollProps } = formsCompsProps
  return (
    <>
      <FormsComponents formsCompsProps={formsCompsPropsII} />
      <section className={classes.buttonsWrapper}>
        <Button
          variant='outlined'
          className={classes.button}
          disabled={Boolean(link) || Boolean(pollData)}
        >
          <ImageUploaderSystem
            imageIcon={<MdCamera size={20} />}
            funtionalityIndex={0}
            images={images}
            setImages={setImages}
            pathName={pathName}
          />
        </Button>
        <Button variant='outlined' className={classes.button}>
          <EmojiPopover {...formsCompsProps} iconSize={20} />
        </Button>
        <LinkPlayerAddButton
          {...formsCompsProps}
          disabled={Boolean(images?.length > 0) || Boolean(pollData)}
        />
        <PollAddButton
          {...pollProps}
          disabled={
            Boolean(images?.length > 0) ||
            typeof setPollData !== 'function' ||
            Boolean(link)
          }
        />
        <Button
          onClick={() => {
            setTextContent('')
            setImages([])
            setLink('')
            if (typeof setPollData === 'function') setPollData(null)
            onSend()
            editingDialogClose()
          }}
          className={classes.button}
          variant='outlined'
          disabled={images?.length === 0 && !textContent && !link && !pollData}
        >
          {rtl ? 'أرسل' : 'Send'}
        </Button>
      </section>
      <LinkPlayerPreview {...formsCompsProps} />
      {pollData && <GeneralPollBody {...pollProps} />}
      <ImageUploaderSystem
        funtionalityIndex={1}
        images={images}
        setImages={setImages}
      />
    </>
  )
}

const WrappedEditingDialogContent = withStyles(styles)(EditingDialogContent)

export default WrappedEditingDialogContent
