import React from 'react'
import { NotificationViewModel } from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const NewCEOAssignmentNotification = (props) => {
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        rtl
          ? 'لقد تم تعيين مديرا تنفيذيا جديدة للمشروع'
          : 'New ceo has been assigned to the project'
      }
      buttonsArray={[]}
    />
  )
}

export default NewCEOAssignmentNotification
