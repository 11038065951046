import { Popover } from '@mui/material'
import React, { useState } from 'react'
import { BsEmojiSmile } from 'react-icons/bs'
import WindowSize from '../../smallComponents/WindowSize'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import i18next from 'i18next'

const EmojiPopover = ({ setTextContent, isDarkModeActive, iconSize }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const windowSize = WindowSize()
  return (
    <>
      <BsEmojiSmile
        onClick={handlePopoverOpen}
        size={iconSize}
        style={{ cursor: 'pointer', margin: 5 }}
      />
      <Popover
        disableScrollLock
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: windowSize > 600 ? 'bottom' : 'top',
          horizontal: rtl ? 'right' : 'left',
        }}
      >
        <Picker
          data={data}
          onEmojiSelect={(e) => setTextContent((text) => text + e.native)}
          locale={rtl ? 'ar' : 'en'}
          theme={isDarkModeActive ? 'dark' : 'light'}
          searchPosition='none'
          // set='facebook'
          previewPosition='none'
        />
      </Popover>
    </>
  )
}

export default EmojiPopover
