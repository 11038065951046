import React from 'react'
import ShareExternalLinkDialogContent from './ShareExternalLinkDialogContent'
import ModelDialogWrappingIII from '../../../dialogModelUnit/ModelDialogWrappingIII'

const ShareExternalLinkDialog = ({
  noFSPanelProps,
  isWindowOpen,
  windowCloser,
}) => {
  const dialogsArray = [
    {
      open: isWindowOpen,
      content: (
        <ShareExternalLinkDialogContent
          noFSPanelProps={noFSPanelProps}
          windowCloser={windowCloser}
        />
      ),
    },
  ]
  return (
    isWindowOpen && (
      <ModelDialogWrappingIII
        dialogsArray={dialogsArray}
        {...noFSPanelProps}
        dialogWidth='400px'
        dialogHeight='auto'
      />
    )
  )
}

export default ShareExternalLinkDialog
