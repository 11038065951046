import React from 'react'
import {
  ListItemScreenViewModels,
  TaskAssignmentCover,
  TaskExtensionRequestCover,
  TaskModiifcationNotifCover,
  TaskMarkedDoneCover,
  TaskNewRequestsMssgCover,
  TaskTerminationCover,
} from '../../../'
import TaskMarkedDoneReplyCover from './TaskNotificationModelComponents/TaskMarkedDoneReplyCover'
import TaskRequestReplyCover from './TaskNotificationModelComponents/TaskRequestReplyCover'

const TaskNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'creator',
      conditions: [
        <TaskNewRequestsMssgCover {...notifProps} />,
        <TaskMarkedDoneCover {...notifProps} />,
        <TaskExtensionRequestCover {...notifProps} />,
      ],
    },
    {
      // for the tasks that i already woking on
      relation: 'pairedWith',
      conditions: [
        <TaskModiifcationNotifCover {...notifProps} />,
        <TaskAssignmentCover {...notifProps} />, //ok
        <TaskMarkedDoneReplyCover {...notifProps} />,
        <TaskTerminationCover {...notifProps} />,
        <TaskRequestReplyCover {...notifProps} />,
      ],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default TaskNotificationModel
