import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const BrandCosts = () => {
  const { t } = useTranslation('BrandCosts')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 41,
    category: t('category_ms41'),
    title: t('title_ms41'),
    subTitle: t('subTitle_ms41'),
    main: t('main_ms41'),
    how: t('how_ms41'),
    usedInModels: [],
    tags: rtl
      ? ['العلامة التجارية', 'استثمار الوقت']
      : ['Branding', 'Time investment'],
    examples: t('examples_ms41'),
    hashTagType: 'markStraAspect',
  }
}

export default BrandCosts
