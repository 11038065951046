import React from 'react'
import { ListItemScreenViewModels } from '../../../'
import ContributeButtonDevelopOffersCover from './DevelopmentNotificationModelComps/ContributeButtonDevelopOffersCover'
import DevelopmentRequestReplyCover from './DevelopmentNotificationModelComps/DevelopmentRequestReplyCover'
import NewDevelopmentRequestNotifCover from './DevelopmentNotificationModelComps/NewDevelopmentRequestNotifCover'

const DevelopmentNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'ceo',
      conditions: [
        <NewDevelopmentRequestNotifCover {...notifProps} />,
        <ContributeButtonDevelopOffersCover {...notifProps} />,
      ],
    },
    {
      relation: 'visitor',
      conditions: [<DevelopmentRequestReplyCover {...notifProps} />],
    },
  ]
  return (
    <ListItemScreenViewModels {...notifProps} models={models} />
  )
}

export default DevelopmentNotificationModel
