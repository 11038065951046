import React from 'react'
import TextPost from '../../../../../../../../postSystem/TextPost'
import RoomSMDisCenter from './RoomSMDisCenter'
import CommentsDialog from '../../../../../../../../commentsSystems/CommentsDialog'
import PostProps from '../../../../../../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import {
  CommentButtonConfig,
  LikeButtonConfig,
  PBCardsModel,
} from '../../../../../../../../feedsSystem'

const RoomSMDisBottom = (props) => {
  const { setIsCommentingSystemActive, isDarkModeActive } = props

  const postPropsII = {
    ...props,
    ...PostProps(
      { setIsCommentingSystemActive },
      {
        commentsDialogProps: true,
      }
    ),
  }

  const buttonProps = {
    ...postPropsII,
    styles: {
      bottomIcon: {
        cursor: 'pointer',
        marginLeft: 5,
        marginRight: 5,
        color: isDarkModeActive ? 'white' : null,
      },
      counterValue: {
        fontSize: 15,
        cursor: 'pointer',
        fontWeight: 550,
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        color: isDarkModeActive ? 'white' : null,
      },
    },
  }
  const buttonsArray = [
    LikeButtonConfig(buttonProps),
    CommentButtonConfig(buttonProps),
  ]

  return (
    <article
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <PBCardsModel buttonsArray={buttonsArray} {...props} />
      <CommentsDialog
        {...postPropsII}
        postBody={<RoomSMDisCenter {...postPropsII} />}
        postText={<TextPost {...postPropsII} />}
      />
    </article>
  )
}

export default RoomSMDisBottom
