import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Grid } from '@mui/material'

const styles = () => ({
  bookContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rightPartWrapper: {
    overFlowY: 'auto',
    padding: 15,
    width: '100% !important',
  },
  bookLeft: {
    display: 'flex !important',
    justifyContent: 'center !important',
    width: '100% !important',
  },
})

const BookDialogContentMobView = ({
  classes,
  bookLeft,
  bookRight,
  isDarkModeActive,
}) => {
  return (
    <Grid
      container
      className={classes.bookContainer}
      sx={{ color: isDarkModeActive ? 'white' : 'inherit' }}
    >
      <Grid item lg={6} className={classes.bookLeft}>
        {bookLeft}
      </Grid>
      <Grid item lg={6} className={classes.rightPartWrapper} id={'m-scroll-right'}>
        {bookRight}
      </Grid>
    </Grid>
  )
}

const WrappedBookDialogContentMobView = withStyles(styles)(
  BookDialogContentMobView
)

export default WrappedBookDialogContentMobView
