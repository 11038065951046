import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const GuaranteedAvailabilityBusinessModel = () => {
  const { t } = useTranslation('GuaranteedAvailabilityBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 24,
    title: t('title_bm24'),
    subTitle: t('subTitle_bm24'),
    main: t('main_bm24'),
    when: t('when_bm24'),
    who: t('who_bm24'),
    what: t('what_bm24'),
    how: t('how_bm24'),
    why: t('why_bm24'),
    examples: rtl
      ? [
          'شركة PHH - الولايات المتحدة الأمريكية',
          'IBM - تأسست عام 1911 - الولايات المتحدة الأمريكية',
          'هيلتي - تأسست عام 1941 - ليختنشتاين',
          'MachineryLink - الولايات المتحدة الأمريكية',
          'ABB Turbo Systems - تأسست عام 1883 - سويسرا',
        ]
      : [
          'PHH Corporation - USA',
          'IBM - Founded 1911 - USA',
          'Hilti - Founded 1941 - Liechtenstein',
          'MachineryLink - USA',
          'ABB Turbo Systems - Founded 1883 - Switzerland',
        ],
    tags: rtl
      ? [
          'التوجه نحو الحلول',
          'خدمة كاملة',
          'خدمة بدوام كامل',
          'ولاء العملاء',
          'عقد السعر الثابت',
          'عقد الخدمات الكاملة',
          'عملية مكلفة',
          'المنتج المعقد',
          'اعمال صيانة',
        ]
      : [
          'Solution-Oriented',
          'Complete Service',
          'Fulltime Service',
          'Customer Loyalty',
          'Flat Rate Contract',
          'Full Services Contract',
          'Costly Operation',
          'Complex Product',
          'Maintenance',
        ],
    considerations: t('considerations_bm24'),
    combinations: ['$LBM19'],
    hashTagType: 'businessModelTemp',
  }
}

export default GuaranteedAvailabilityBusinessModel
