import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const NewSubdDepCreationCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl
          ? `لقد تمت إضافتك الى قسم فرعى جديد`
          : 'You have been added to a new Sub-department'
      }
    />
  )
}

export default NewSubdDepCreationCover
