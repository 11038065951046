import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const JobRequestReplyCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl
          ? `تم الرد على وظيفة قمت بالتقديم اليها`
          : 'Your job application has neen replied to'
      }
    />
  )
}

export default JobRequestReplyCover
