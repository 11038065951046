import React, { useEffect, useState } from 'react'
import ImagesSystem from '../../../../../../../../postSystem/ImagesSystem'
import TextPost from '../../../../../../../../postSystem/TextPost'
import PostProps from '../../../../../../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import ImageUploaderSystem from '../../../../../../../../uploadImageSystem/ImageUploaderSystem'
import withStyles from '@mui/styles/withStyles'
import { MdCamera } from 'react-icons/md'
import FormsComponents from '../../../../../../../../formsSystem/FormsComponents'
import { Button } from '@mui/material'
import HashTagElementsRefiner from '../../../../../../../../smallComponents/HashTagElementsRefiner'
import LinksPlayerSystem from '../../../../../../../../postSystem/LinksPlayerSystem'
import LinkPlayerPreview from '../../../../../../../../postSystem/LinksPlayerSystemComps/LinkPlayerPreview'
import LinkPlayerAddButton from '../../../../../../../../postSystem/LinksPlayerSystemComps/LinkPlayerAddButton'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})

const NoteBody = ({ classes, notesProps }) => {
  const {
    note,
    editNoEditIndex,
    DCGProps,
    setNoteUpdateData,
    departmentId,
    subDepId,
  } = notesProps
  const { project } = DCGProps
  const {
    imageViewerDialogOpener,
    imageViewerDialogClose,
    isImageViewerDialogOpen,
  } = PostProps(
    {},
    {
      imageViewerProps: true,
    }
  )

  const [textContent, setTextContent] = useState(note?.text)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(note) || []
  )
  const formsCompsProps = {
    formComponentIndex: 0,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    departmentId,
    subDepId,
    ...DCGProps,
  }
  const [images, setImages] = useState(note?.images)
  const [playerLink, setPlayerLink] = useState(note?.playerLink)
  useEffect(() => {
    setNoteUpdateData((data) => ({
      ...data,
      images,
      text: textContent,
      chosenHashTagElements,
      playerLink,
    }))
  }, [images, textContent, chosenHashTagElements, playerLink])
  const editOrNoEditViews = [
    <section>
      <ImagesSystem imagesArray={images} openFun={imageViewerDialogOpener} />
      <ImagesSystem
        imageViewer={true}
        imageViewerDialogClose={imageViewerDialogClose}
        isImageViewerDialogOpen={isImageViewerDialogOpen}
        imagesArray={images}
        {...DCGProps}
      />
      <TextPost
        post={{
          postTextContent: textContent,
          chosenHashTagElements,
        }}
        {...DCGProps}
        DCGProps={DCGProps}
        margins='auto'
      />
      <LinksPlayerSystem url={note?.playerLink} />
    </section>,
    <section>
      <FormsComponents formsCompsProps={formsCompsProps} />
      <section className={classes.buttonsWrapper}>
        <Button
          variant='outlined'
          className={classes.button}
          disabled={Boolean(playerLink)}
        >
          <ImageUploaderSystem
            imageIcon={<MdCamera size={20} />}
            funtionalityIndex={0}
            images={images}
            setImages={setImages}
            pathName={`/projects/${project?._id}/notes`}
          />
        </Button>
        <LinkPlayerAddButton
          setLink={setPlayerLink}
          link={playerLink}
          {...DCGProps}
          disabled={Boolean(images?.length > 0)}
        />
      </section>
      <LinkPlayerPreview setLink={setPlayerLink} link={playerLink} />
      <ImageUploaderSystem
        funtionalityIndex={1}
        images={images}
        setImages={setImages}
        pathName={`/projects/${project?._id}/notes`}
      />
    </section>,
  ]
  return editOrNoEditViews[editNoEditIndex]
}

const WrappedNoteBody = withStyles(styles)(NoteBody)

export default WrappedNoteBody
