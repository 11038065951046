import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'
import FeedPostTopModel from '../../../feedsSystem/FeedPostTopModel'
import DarkModeColorCoder from '../../../smallComponents/DarkModeColorCoder'

const styles = () => ({
  postContainer: {
    width: '100%',
    borderRadius: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
  },
  postWrapper: {
    padding: '0 !important',
    borderRadius: '20px !important',
  },

  '@media (max-width: 600px)': {
    postContainer: {
      marginTop: 7,
    },
    postWrapper: {
      borderRadius: '0px !important',
    },
  },
})

const HomePostConatinerModel = ({ mainBody, classes, postProps }) => {
  const { isDarkModeActive } = postProps
  return (
    <div className={classes.postContainer}>
      <Card
        className={classes.postWrapper}
        sx={{
          color: isDarkModeActive ? 'white' : 'inherit',
          background: isDarkModeActive
            ? DarkModeColorCoder('Blue_gradient')
            : 'white',
        }}
      >
        <FeedPostTopModel postProps={postProps} />
        {mainBody}
      </Card>
    </div>
  )
}

const WrappedHomePostConatinerModel = withStyles(styles)(HomePostConatinerModel)

export default WrappedHomePostConatinerModel
