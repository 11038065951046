import React from 'react'
import withStyles from '@mui/styles/withStyles'
import PartnerProjectAvatarName from './CompanyLocalProfileComponents/PartnerProjectAvatarName'
import PartnerProjectChannels from './CompanyLocalProfileComponents/PartnerProjectChannels'

const styles = () => ({
  profileWrapper: {
    width: '100%',
  },
})

const CompanyLocalProfile = ({
  classes,
  project,
  hidePartnerChannles,
  DCGProps,
}) => {
  const company = project
  return (
    <article className={classes.profileWrapper}>
      <PartnerProjectAvatarName company={company} />
      <PartnerProjectChannels
        hidePartnerChannles={hidePartnerChannles}
        DCGProps={DCGProps}
        company={company}
      />
    </article>
  )
}

const WrappedCompanyLocalProfile = withStyles(styles)(CompanyLocalProfile)

export default WrappedCompanyLocalProfile
