import React from 'react'
import { DevelopmentRequestReply, NewDevelopmentRequestNotif } from '../../'
import ContributeButtonDevelopOffers from './DevelopmentNotifDisplayModelComps/ContributeButtonDevelopOffers'

const DevelopmentNotifDisplayModel = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const mssgs = [
    {
      relation: 'ceo',
      comp: [
        <NewDevelopmentRequestNotif {...notifProps} />,
        <ContributeButtonDevelopOffers {...notifProps} />,
      ],
    },
    {
      relation: 'visitor',
      comp: [<DevelopmentRequestReply {...notifProps} />],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default DevelopmentNotifDisplayModel
