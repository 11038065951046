import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const DepartmentManagerNewNoteCover = ({ notification, sender, project }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `قام مديرك فى ${project?.name} بنشر ملاحظة جديدة للتو`
    : `Your manager ${sender?.name} in ${project?.name} has just posted a new note`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default DepartmentManagerNewNoteCover
