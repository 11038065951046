import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const CustomerHistory = () => {
  const { t } = useTranslation('CustomerHistory')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 2,
    category: t('category_ms02'),
    title: t('title_ms02'),
    subTitle: t('subTitle_ms02'),
    main: t('main_ms02'),
    how: t('how_ms02'),
    usedInModels: ['LBM29'],
    tags: rtl
      ? [
          'البيئة الكلية',
          'تفضيلات العميل',
          'ابتكار المنتجات',
          'ابتكار الخدمة',
          'قرارات التسعير',
          'سلوك الشراء',
          'العملاء المستهدفون',
          'خدمة المنتج',
          "حساسية السعر'",
        ]
      : [
          'Macro Environment',
          'Customer Preferences',
          'Product Innovation',
          'Service Innovation',
          'Pricing Decisions',
          'Buying Behavior',
          'Target Customers',
          'Product Service',
          'Price Sensitivity',
        ],
    examples: t('examples_ms02'),
    hashTagType: 'markStraAspect',
  }
}

export default CustomerHistory
