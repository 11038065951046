import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ModelImagePreview from './GalleryInfoProfileTopModelComps/ModelImagePreview'
import axios from 'axios'
import TransparentBackCard from '../../../../../smallComponents/TransparentBackCard'
import TimeAgoFormattor from '../../../../../smallComponents/TimeAgoFormattor'
import FreeBar from '../../../../../skeleton/SkeletonComponents/FreeBar'
import i18next from 'i18next'

const styles = () => ({
  topWrapper: {
    display: 'flex',
    width: '100%',
  },
  wrapper: {
    marginTop: 10,
    width: '100%',
  },
  gridWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  creationDate: {
    fontSize: 11,
    fontWeight: 500,
  },
  elementUser: {
    fontSize: 12,
    fontWeight: 500,
  },
  elementTitle: {
    fontWeight: 500,
    fontSize: 25,
    marginBottom: 20,
    marginTop: 10,
  },
  '@media (max-width: 600px)': {
    topWrapper: {
      flexDirection: 'column',
    },
    gridWrapper: {
      margin: '10px 0 0 0',
    },
  },
})

const GalleryInfoProfileTopModel = ({ classes, galleryProps }) => {
  const { DCGProps, element, CustomTopContent } = galleryProps
  const { isDialogFullWidth } = DCGProps
  const [elementUser, setElementUser] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const ftechOneUser = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/users/oneUser/${element?.userId}`)
        setElementUser(res.data.user)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (element?.userId) ftechOneUser()
  }, [element?.userId])
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <TransparentBackCard
      {...DCGProps}
      content={
        <section
          className={classes.topWrapper}
          style={{ flexDirection: isDialogFullWidth ? 'row' : 'column' }}
        >
          <ModelImagePreview galleryProps={galleryProps} />
          <span
            className={classes.gridWrapper}
            style={{
              marginLeft: isDialogFullWidth && !rtl && 10,
              marginRight: isDialogFullWidth && rtl && 10,
              marginTop: !isDialogFullWidth && 10,
            }}
          >
            <span className={classes.elementTitle}>{element?.Title}</span>
            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <span className={classes.elementUser}>
                {isLoading ? (
                  <FreeBar
                    height={'20px'}
                    width={'150px'}
                    margin={'5px 0 0 0'}
                  />
                ) : (
                  elementUser?.firstName + ' ' + elementUser?.lastName
                )}
              </span>
              <span className={classes.creationDate}>
                <TimeAgoFormattor date={element?.createdAt} />
              </span>
              <section>{CustomTopContent}</section>
            </section>
          </span>
        </section>
      }
    />
  )
}

const WrappedGalleryInfoProfileTopModel = withStyles(styles)(
  GalleryInfoProfileTopModel
)

export default WrappedGalleryInfoProfileTopModel
