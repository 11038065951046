import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const PermissionRequestReply = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <section>
              <Link to={`/profile/${sender?._id}`}>{sender.name}</Link>
              {rtl
                ? `وافق على دخولك لعرض فكرته`
                : `has granted your access to his idea`}
            </section>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default PermissionRequestReply
