import React from 'react'
import CommentRepliesDialogContent from '../../commentsSystems/CommentingSystemComponents/CommentModelComponents/CommentRepliesDialogContent'
import NewFormDialogModel from '../../formsSystem/NewFormDialogModel'
import CustomEmpty from '../../smallComponents/CustomEmpty'
import {
  CommentsDialog,
  PostBodyModel,
  TextPost,
  FeedsSystem,
  PostProps,
} from './'
import i18next from 'i18next'

const GeneralNotifications = (props) => {
  const {
    isNotificationCardOpen,
    notificationCardDialogClose,
    post,
    isDarkModeActive,
    postsUsers,
    sharedPosts,
    sharedPostsUsers,
    setIsDarkModeActive,
    socket,
    commentId,
    showPost,
    comment,
  } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const ImageProps = PostProps({}, { imageViewerProps: true })
  const postUser = postsUsers
    ?.concat(sharedPostsUsers)
    ?.find((u) => post?.userId === u?._id)
  const gNotificationsComponents =
    post || comment ? (
      commentId && !showPost ? (
        <NewFormDialogModel
          NewFormComponent={
            <CommentRepliesDialogContent {...props} originalPostOption />
          }
          isNewFormDialogOpen={isNotificationCardOpen}
          isDarkModeActive={isDarkModeActive}
          clickAwayState={true}
          newFormDialogClose={notificationCardDialogClose}
          dialogTitle={rtl ? 'ردود' : 'Replies'}
        />
      ) : (
        <CommentsDialog
          isCommentsDialogOpen={isNotificationCardOpen}
          commentsDialogClose={notificationCardDialogClose}
          postBody={
            post?.feedsSystemCategory === 'home' ? (
              <FeedsSystem
                feedsSystemProps={{
                  post,
                  hideRightOptions: true,
                  hideTopAndBottom: true,
                  isDarkModeActive,
                  postsUsers,
                  sharedPosts,
                  sharedPostsUsers,
                }}
              />
            ) : (
              <PostBodyModel
                postBodyProps={{
                  post,
                  postTextMargins: 10,
                  ...ImageProps,
                  commentingSystemView: true,
                  isCommentingSystemActive: true,
                  socket,
                }}
              />
            )
          }
          postText={
            <TextPost
              post={post}
              isCommentingSystemActive={true}
              socket={socket}
            />
          }
          post={post}
          user={postUser}
          isDarkModeActive={isDarkModeActive}
          setIsDarkModeActiveII={setIsDarkModeActive}
          socket={socket}
        />
      )
    ) : (
      <CustomEmpty />
    )
  return post && gNotificationsComponents
}

export default GeneralNotifications
