import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { MdPlaylistAdd } from 'react-icons/md'
import UploaderComponent from '../../uploadPDFSystem/UploadPDFSystemComponents/UploaderComponent'
import { Tooltip } from '@mui/material'
import i18next from 'i18next'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const AddNewFile = ({ classes, noFSPanelProps }) => {
  const files = []
  const { showUploadPDF, seterrorMsg, setFiles } = noFSPanelProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <Tooltip title={rtl ? 'إضافة ملف PDF جديد' : 'Add New PDF File'}>
      <span
        className={classes.iconWrapper}
        style={{
          display: showUploadPDF ? null : 'none',
          marginLeft: -15,
          marginRight: -15,
        }}
        onClick={
          // to clear the error everytime i start uppload new file to refresh the errors
          () => {
            if (typeof seterrorMsg === 'function') seterrorMsg('')
          }
        }
      >
        <UploaderComponent
          files={files}
          setFiles={setFiles}
          UploadButtonComponent={<MdPlaylistAdd className={classes.icon} />}
          seterrorMsg={seterrorMsg}
          cutimizedUpload={true}
        />
      </span>
    </Tooltip>
  )
}

const WrappedAddNewFile = withStyles(styles)(AddNewFile)

export default WrappedAddNewFile
