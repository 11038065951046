import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Grid } from '@mui/material'
import TitleHeader from '../smallComponents/Header'
import i18next from 'i18next'

const styles = () => ({
  titleWrapper: {
    marginBottom: 10,
    width: '100%',
  },
  title: {
    fontWeight: 500,
    fonsize: 13,
  },
  '@media (max-width: 600px)': {
    titleWrapper: {},
    title: {
      fontWeight: 500,
      fonsize: 11,
    },
  },
})

const DetailsGridModel = ({
  headerText,
  infoArray,
  classes,
  hideHeader,
  isDarkModeActive,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <section style={{ width: '100%', color: isDarkModeActive && 'white' }}>
      <TitleHeader headerText={headerText} hideHeader={hideHeader} />
      {infoArray.map((info, infoIndex) => (
        <Grid
          container
          key={infoIndex}
          className={classes.titleWrapper}
          style={{ display: info?.hide && 'none' }}
        >
          <Grid item xs={3.5}>
            <span
              className={classes.title}
              style={{
                marginRight: !rtl && 10,
                marginLeft: rtl && 10,
              }}
            >
              {info.title}
            </span>
          </Grid>
          <Grid item xs={8.5}>
            <span className={classes.titleValue}>{info.titleValue}</span>
          </Grid>
        </Grid>
      ))}
    </section>
  )
}

const WrappedDetailsGridModel = withStyles(styles)(DetailsGridModel)

export default WrappedDetailsGridModel
