import React from 'react'
import NotificationCoverModel from '../../../NotificationCoverModel'
import i18next from 'i18next'

const PostLikeNotifCover = ({ notification, sender, post }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content =
    post?.likes?.length > 1
      ? rtl
        ? `أُعجب ${sender?.name} و ${
            post?.likes?.length - 1
          } أخرون بالمنشور الخاص بك"`
        : `${sender?.name} and ${
            post?.likes?.length - 1
          } others liked your post.`
      : rtl
      ? `أعجب ${sender?.name} بالمنشور الخاص بك`
      : `${sender?.name} liked your post`

  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default PostLikeNotifCover
