import React from 'react'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const NewIssueNotifs = (props) => {
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? 'هناك مشكلة جديدة أثيرت ضدك وهي تؤثر على شبكتك بشكل سيء. ترغب في حل المشكلة في أسرع وقت ممكن.'
              : 'There is a new issue that has been raised against you and it is affecting your network badly. You would like the issue to be resolved as soon as possible.'
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default NewIssueNotifs
