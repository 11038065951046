import React, { useContext, useEffect, useState } from 'react'
import DepNewContributorFormButtons from './DepNewContributorFormComponents/DepNewContributorFormButtons'
import withStyles from '@mui/styles/withStyles'
import TitleHeader from '../../../../smallComponents/Header'
import MultiSelectViewerSystem from '../../../../multiSelectViewerSystem/MultiSelectViewerSystem'
import axios from 'axios'
import { AuthContext } from '../../../../../context/AuthContext'
import ChosenMemberModel from '../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/SubdepartmentComps/NewSubDepForm/ChoosingSubDepMembersComps/ChosenMemberModel'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
})

const DepNewContributorForm = ({ classes, ...props }) => {
  const { newDepContribClose, isDarkModeActive, setClickAwayState, project } =
    props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const [selectedMembers, setSelectedMembers] = useState([])
  const [selectedElements, setSelectedElements] = useState([])
  // this is allowed only for the ceo of the project or the manager of the department
  // and i will check the authority based on the authority allowd in the settings of the project,
  //  if the manager is allosed to add members or not
  const [validContributors, setValidContributors] = useState([])
  const [notAssignedContibutors, setNotAssignedContributors] = useState([])
  const [notAssignedProfiles, setNotAssignedProfiles] = useState([])
  useEffect(() => {
    const fetchCont = async () => {
      try {
        const resV = await axios.get(
          `/users/interactions/validContributors/${user?._id}/?projectId=${project?._id}`
        )
        const resPC = await axios.get(
          `/projectContributors/addition/notDepAssignedContributors/${project?._id}`
        )
        setValidContributors(resV.data.validContributors)
        setNotAssignedContributors(resPC.data.contributors)
        setNotAssignedProfiles(resPC.data.profiles)
      } catch (error) {
        console.log(error)
      }
    }
    fetchCont()
  }, [user?._id])
  const contributors = validContributors.concat(
    notAssignedProfiles?.map((u) => ({
      ...u,
      ...notAssignedContibutors?.find((s) => s?.userId === u?._id),
    }))
  )
  useEffect(() => {
    setSelectedMembers(
      contributors.filter((member) => selectedElements.includes(member._id))
    )
  }, [selectedElements])

  return (
    <article
      className={classes.wrapper}
      style={{ color: isDarkModeActive && 'white' }}
    >
      <TitleHeader
        headerText={
          rtl
            ? 'اختر أي من المشاركين لإضافتهم إلى هذا القسم'
            : 'Choose which contributors to add to this department'
        }
        isDarkModeActive={isDarkModeActive}
      />
      <MultiSelectViewerSystem
        setClickAwayState={setClickAwayState}
        elements={contributors}
        ChosenElementModel={ChosenMemberModel}
        elementTitle={rtl ? 'عضو' : 'Member'}
        selectedElements={selectedElements}
        setSelectedElements={setSelectedElements}
        voidElementsAlertText={
          rtl
            ? 'You do not have any contributors'
            : 'ليس لديك أي مشاركين ، يجب إضافة بعضهم والمحاولة مرة أخرى'
        }
      />
      <DepNewContributorFormButtons
        newDepContribClose={newDepContribClose}
        isDataFilled={selectedMembers.length}
        selectedMembers={selectedMembers}
        {...props}
      />
    </article>
  )
}

const WrappedDepNewContributorForm = withStyles(styles)(DepNewContributorForm)

export default WrappedDepNewContributorForm
