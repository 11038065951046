import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import DepartmentsEnumArray from '../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/DepartmentsEnumArray'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const RoomDiscussionMenitonedNotif = (props) => {
  const { project, sender, notification } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const subDepId = notification?.accompaniedData?.subDepId
  const [subDep, setSubDep] = useState()
  useEffect(() => {
    const fetchSubDep = async () => {
      try {
        const res = await axios.get(`/subdepartments/${subDep}`)
        setSubDep(res.data.subdepartment)
      } catch (error) {
        console.log(error)
      }
    }
    if (subDepId) fetchSubDep()
  }, [subDep])
  const department = DepartmentsEnumArray.find(
    (dep) => dep.id === Number(notification?.accompaniedData?.departmentId)
  )?.title
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <>
              <Link to={`/profile/${sender?._id}`} style={style}>
                {sender?.name}
              </Link>{' '}
              {rtl ? (
                <>
                  قام بالإشارة اليك فى مناقشة فى قسم {t(department)}
                  القسم الفرعى {subDep?.Name}
                </>
              ) : (
                <>
                  mentioned you in a discussion in {department} department,{' '}
                  {subDep?.Name} sub-department
                </>
              )}
              <span style={style}>
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
              </span>
            </>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default RoomDiscussionMenitonedNotif
