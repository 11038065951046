import React from 'react'
import {
  SubDepPromotionNotifCover,
  ProjectDepromotionCover,
  DepartmentPromotionNotifCover,
  ListItemScreenViewModels,
} from '../../../'

const PromotionNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'promoted',
      conditions: [
        <SubDepPromotionNotifCover {...notifProps}  />,
        <DepartmentPromotionNotifCover {...notifProps}  />,
        <ProjectDepromotionCover {...notifProps}  />,
      ],
    },
  ]
  return (
    <ListItemScreenViewModels {...notifProps}  models={models} />
  )
}

export default PromotionNotificationModel
