import React, { useContext } from 'react'
import { AuthContext } from '../../../../../context/AuthContext'
import EditableGridModel from '../../../../formsSystem/EditableGridModel'
import TitleHeader from '../../../../smallComponents/Header'
import i18next from 'i18next'

const IdeaContent = ({
  idea,
  infoData,
  setInfoData,
  onSave,
  chosenHashTagElements,
  setChosenHashTagElements,
  DCGProps,
  newIdeaMode,
}) => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const placeholder = rtl ? 'قم بوصف فكرتك' : 'Describe your idea'
  const gridProps = {
    hideInfoTitles: !idea,
    hideEditingTools: idea && user?._id !== idea?.userId,
    hideHeader: true,
    infoArray: newIdeaMode
      ? [
          {
            title: 'Content',
            titleValue: idea?.Content,
            allowEmpty: true,
            wideMode: true,
            placeholder,
            minRows: 4,
          },
        ]
      : [
          {
            title: 'Title',
            titleValue: idea?.Title,
            allowEmpty: true,
          },
          {
            title: 'Content',
            titleValue: idea?.Content,
            allowEmpty: true,
            wideMode: true,
            placeholder,
            minRows: 4,
          },
        ],
    setInfoData,
    infoData,
    chosenHashTagElements,
    setChosenHashTagElements,
    onSave,
    ...DCGProps,
  }

  return (
    <section style={{ position: 'relative' }}>
      <TitleHeader headerText={rtl ? 'محتوى الفكرة' : 'Idea Content'} />
      <EditableGridModel gridProps={gridProps} />
    </section>
  )
}

export default IdeaContent
