import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import WindowProps from '../../windowViewer/WindowProps'
import ModelDialogWrappingIII from '../../dialogModelUnit/ModelDialogWrappingIII'
import LinkAdditionDialogContent from './LinkAdditionDialogContent'
import { Button } from '@mui/material'
import { SiAirplayaudio, SiAirplayvideo } from 'react-icons/si'
import i18next from 'i18next'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const LinkPlayerAddButton = ({
  classes,
  setLink,
  isDarkModeActive,
  disabled,
  link,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const dialogsArray = [
    {
      open: isWindowOpen,
      content: (
        <LinkAdditionDialogContent
          setLink={setLink}
          windowCloser={windowCloser}
          isDarkModeActive={isDarkModeActive}
          link={link}
        />
      ),
    },
  ]
  const [icon, setIcon] = useState(<SiAirplayaudio size={20} />)
  const [isAudio, setIsAudio] = useState(true)
  setInterval(() => {
    if (isAudio) {
      setIcon(<SiAirplayvideo size={20} />)
    } else {
      setIcon(<SiAirplayaudio size={20} />)
    }
    setIsAudio(!isAudio)
  }, 10000)
  return (
    <>
      <Button
        disabled={disabled}
        onClick={windowOpener}
        variant='outlined'
        className={classes.button}
      >
        <span
          style={{
            animation: 'fadeInOut 10s ease-in-out infinite',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {icon}
          <style>
            {`@keyframes fadeInOut {
                0% {
                    opacity: ${isAudio ? 1 : 0}
                }
                50% {
                    opacity: ${isAudio ? 0 : 1}
                }
                100% {
                    opacity: ${isAudio ? 1 : 0}
                }
            }`}
          </style>
        </span>
      </Button>
      <ModelDialogWrappingIII
        dialogsArray={dialogsArray}
        isDarkModeActive={isDarkModeActive}
        dialogWidth='450px'
        dialogHeight='350px'
      />
    </>
  )
}

const WrappedLinkPlayerAddButton = withStyles(styles)(LinkPlayerAddButton)

export default WrappedLinkPlayerAddButton
