import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../context/AuthContext'
import OneUserCall from '../../../../../../apiCalls/OneUserCall'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'
import HashTagElementsRefiner from '../../../../../../smallComponents/HashTagElementsRefiner'
import Loading from '../../../../../../smallComponents/Loading'
import taskInfoArray from './TaskDetailsComponents/TaskDetailsConfigs'
import TaskDetailsHeaderText from './TaskDetailsComponents/TaskDetailsHeaderText'

const TaskDetails = ({
  task,
  membersProfiles,
  setProjectTasks,
  DCGProps,
  departmentId,
  subDepId,
}) => {
  const { user } = useContext(AuthContext)
  const { isDarkModeActive, socket, project } = DCGProps
  const { t } = useTranslation()
  const [taskInfoData, setTaskInfoData] = useState({
    Deadline: task?.TaskDeadline,
    Description: task?.TaskDescription,
  })

  const { isLoading, user: repPaired } = OneUserCall({
    userId: task?.TaskPairedContributorId,
  })

  const { isLoading: isLoadingII, user: repModerator } = OneUserCall({
    userId: task?.TaskModeratorId,
  })

  const pairedMember =
    membersProfiles?.find((m) => m._id === task?.TaskPairedContributorId) ||
    repPaired
  const moderator =
    membersProfiles?.find((m) => m._id === task?.TaskModeratorId) ||
    repModerator
  const infoArray = taskInfoArray({
    task,
    membersProfiles,
    user,
    t,
    pairedMember,
    moderator,
  })
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(task) || []
  )
  const onSave = async () => {
    if (task?._id)
      try {
        const res = await axios.patch(`/tasks/${task?._id}`, {
          TaskDeadline: taskInfoData.Deadline,
          TaskDescription: taskInfoData.Description,
          chosenHashTagElements,
        })
        if (typeof setProjectTasks === 'function')
          setProjectTasks((tasks) => [
            ...tasks.filter((t) => t._id !== task._id),
            res.data.task,
          ])
        socket?.current?.emit('addNotifUser', task?.TaskPairedContributorId)
        NotificationCreator({
          associativity: 'task',
          relation: 'pairedWith',
          conditionIndex: 0,
          accompaniedData: {
            projectId: project?._id,
            elements: [
              {
                hashTagType: task?.hashTagType,
                id: task?._id,
                title: task?.Title,
              },
            ],
          },
          category: 'contributors',
          userId: task?.TaskPairedContributorId,
          currentUser: user,
          socket,
        })
      } catch (error) {
        console.log(error)
      }
  }

  const gridProps = {
    hideEditingTools: user?._id !== task?.TaskModeratorId,
    headerText: (
      <TaskDetailsHeaderText task={task} isDarkModeActive={isDarkModeActive} />
    ),
    infoArray,
    infoData: taskInfoData,
    setInfoData: setTaskInfoData,
    isDarkModeActive,
    onSave,
    chosenHashTagElements,
    setChosenHashTagElements,
    departmentId,
    subDepId,
    ...DCGProps,
  }

  return isLoading || isLoadingII ? (
    <Loading />
  ) : (
    <EditableGridModel gridProps={gridProps} />
  )
}

export default TaskDetails
