import { ClickAwayListener, Dialog, DialogContent } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import AlertCard from './AlertMessgComponents/AlertCard'

const styles = () => ({
  cardsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
})

const AlertMessg = ({
  alertText,
  classes,
  setIsAlertDialogOpen,
  isAlertDialogOpen,
  isDarkModeActive,
}) => {
  return (
    <Dialog
      disableScrollLock
      open={isAlertDialogOpen}
      PaperProps={{
        style: {
          borderRadius: 10,
          background: isDarkModeActive && 'rgb(49, 48, 53)',
        },
      }}
    >
      <ClickAwayListener onClickAway={() => setIsAlertDialogOpen(false)}>
        <DialogContent>
          <section
            className={classes.cardsWrapper}
            style={{ color: isDarkModeActive && 'white' }}
          >
            <AlertCard alertText={alertText} />
          </section>
        </DialogContent>
      </ClickAwayListener>
    </Dialog>
  )
}

const WrappedAlertMessg = withStyles(styles)(AlertMessg)

export default WrappedAlertMessg
