import React from 'react'
import { StyleSheet } from '@react-pdf/renderer'
import GPDFDTitle from '../GeneralPDFDocumentComps/GPDFDTitle'
import List, { Item } from '../GeneralPDFDocumentComps/GPDFDList'

const styles = StyleSheet.create({
  termsContainer: {
    margin: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    fontFamily: 'Times-Roman',
  },
  term: {
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 10,
    textAlign: 'justify',
    flexGrow: 1,
  },
})

const ContractPDFTerms = () => {
  const contractTerms = [
    'The contracts or agreements used in Hutupia are considered as a tool to facilitate agreements between potential contributors. They are not considered legally binding by the website, and legal registration is required if an agreement has been reached and the PDF copy is intended for further use.',
    'The digital copy of the agreement will remain on the system as long as the contributors have not decided to remove it. The digital format will not be deleted or modified in any way unless approved by all parties included in the agreement.',
    'Digital copies are used to track the behavior of contributors in case of illegal behavior or fraud. The digital format is also used in the calculation of the Hutupia Rank of contributors. Any violation of the agreed terms between the contributors, which is followed by raising issues against the violators, will affect the Hutupia assessment chart and recommendations, and will alert other users.',
  ]
  return (
    <>
      <GPDFDTitle>DISCLAIMER</GPDFDTitle>
      <List>
        {contractTerms?.map((term, index) => (
          <Item key={index} style={styles.termsContainer}>
            {term}
          </Item>
        ))}
      </List>
    </>
  )
}

export default ContractPDFTerms
