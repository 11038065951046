import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const CampaignRequestReply = (props) => {
  const { campaign, project, notification } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          notification={notification}
          {...props}
          notificationBodyContent={
            <>
              {rtl ? `عرضك للحملة الترويجية` : 'Your Campaign offer'}
              <span style={style}>{campaign.title}</span> {rtl ? 'لـ' : 'to'}
              <Link to={`/projectProfile/${project?._id}`} style={style}>
                {project?.ProjectName}
              </Link>{' '}
              {rtl ? `تم الرد عليه` : `has been responded to`}
            </>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default CampaignRequestReply
