import { ref, deleteObject } from 'firebase/storage'
import { storage } from './config'

const deleteFile = (file) => {
  return new Promise(async (resolve, reject) => {
    const storageRef = ref(storage, file)
    try {
      await deleteObject(storageRef)
      console.log('image/pdf deleted successfuly')
    } catch (error) {
      reject(error)
    }
  })
}

export default deleteFile
