import React, { useEffect, useState } from 'react'
import HomePostConatinerModel from './HomePostConatinerModel'
import TextPost from '../../../postSystem/TextPost'
import SharedPostMiddle from './SharedPostComponents/SharedPostMiddle'
import SharedPostBottom from './SharedPostComponents/SharedPostBottom'
import axios from 'axios'

const SharedPost = ({ postProps }) => {
  const { post, sharedPosts, sharedPostsUsers, posts } = postProps
  const sharedPost =
    sharedPosts?.find((p) => p?._id === post?.sharedPostId) ||
    posts?.find((p) => p?._id === post?.sharedPostId)
  const sharedPostUser = sharedPostsUsers?.find(
    (u) => u?._id === sharedPost?.userId
  )
  const [currentlySharedPost, setCurrentlySharedPost] = useState()
  useEffect(() => {
    const fetchCurrentlySharedPost = async () => {
      try {
        const res = await axios.get(`/posts/${post?.sharedPostId}`)
        setCurrentlySharedPost(res.data.post)
      } catch (error) {
        console.log(error)
      }
    }
    if (!sharedPost && post?.sharedPostId) fetchCurrentlySharedPost()
  }, [post?.sharedPostId, sharedPost])
  return (
    <HomePostConatinerModel
      postProps={{
        ...postProps,
        hideAddPostImages: true,
      }}
      mainBody={
        <>
          <TextPost margins='auto 15px auto 15px' {...postProps} />
          <SharedPostMiddle
            postProps={{
              ...postProps,
              sharedPost,
              sharedPostUser,
            }}
          />
          <SharedPostBottom
            postProps={{
              ...postProps,
              sharedPost: sharedPost || currentlySharedPost,
            }}
          />
        </>
      }
    />
  )
}

export default SharedPost
