import React from 'react'
import { Link } from 'react-router-dom'
import {
  NotificationViewModel,
  NotificationBodyWithAccessCard,
} from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const ContractEditingEvent = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <article>
                قام
                <Link to={`/profile/${sender?._id}`}>{sender.name}</Link> بتعديل
                عقد خاص بك
              </article>
            ) : (
              <article>
                <Link to={`/profile/${sender?._id}`}>{sender.name}</Link> has
                modified a contract associated with you in
              </article>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default ContractEditingEvent
