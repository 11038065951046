import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import UserPostLikeIcon from './LikeButtonConfigComps/UserPostLikeIcon'
import ProjectPostLikeIcon from './LikeButtonConfigComps/ProjectPostLikeIcon'
import MultiMillify from '../../smallComponents/MultiMillify'
import ToastifyGeneralError from '../../smallComponents/ToastifyGeneralError'

const LikeButtonConfig = (props) => {
  const { post, styles } = props
  const { user } = useContext(AuthContext)
  const [likes, setLikes] = useState(post?.likes.length)
  const [isLiked, setIsLiked] = useState(post?.likes.includes(user?._id))
  const likeNumberHandler = async () => {
    setLikes((likes) => (isLiked ? likes - 1 : likes + 1))
    setIsLiked(!isLiked)
    try {
      if (user?._id && post?._id)
        await axios.put(`/posts/${post?._id}/like`, { userId: user?._id })
    } catch (error) {
      console.log(error)
      ToastifyGeneralError(error)
    }
  }

  let likeIconToggle =
    post?.feedsSystemCategory === 'page'
      ? ProjectPostLikeIcon({
          ...props,
          likeNumberHandler,
          likes,
          isLiked,
          user,
        })
      : UserPostLikeIcon({
          ...props,
          likeNumberHandler,
          likes,
          isLiked,
          user,
        })
  let likeIconHandler = !isLiked ? likeIconToggle[0] : likeIconToggle[1]
  return {
    icon: likeIconHandler,
    value: (
      <span
        style={{
          display: likes > 0 ? null : 'none',
          ...styles?.counterValue,
        }}
      >
        {MultiMillify(likes)}
      </span>
    ),
    openFun: '',
  }
}

export default LikeButtonConfig
