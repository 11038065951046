import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const SegmentationEffects = () => {
  const { t } = useTranslation('SegmentationEffects')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 28,
    category: t('category_ms28'),
    title: t('title_ms28'),
    subTitle: t('subTitle_ms28'),
    main: t('main_ms28'),
    how: t('how_ms28'),
    usedInModels: [],
    tags: rtl
      ? [
          'الاستهداف',
          'التسعير',
          'التمركز',
          'ماركة',
          'ميزة تنافسية',
          'تأثير السوق',
          'ريادة السوق',
          'أسعار مميزة',
          'الهوامش',
          'الربحية',
        ]
      : [
          'Targeting',
          'Retention',
          'Pricing',
          'Positioning',
          'Differentiation',
          'Brand',
          'Relationships',
          'Competitive Advantage',
          'Market Influence',
          'Market Leadership',
          'Premium Prices',
          'Margins',
          'Profitability',
        ],
    examples: t('examples_ms28'),
    hashTagType: 'markStraAspect',
  }
}

export default SegmentationEffects
