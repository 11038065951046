const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_START':
      return {
        user: null,
        isFetching: true,
        error: false,
      }
    case 'LOGIN_SUCCESS':
      return {
        user: action.payload,
        isFetching: false,
        error: false,
      }
    case 'LOGIN_FAILURE':
      return {
        user: null,
        isFetching: false,
        error: true,
      }
    case 'UPDATE':
      return {
        user: action.payload,
        isFetching: false,
        error: false,
      }
    case 'LOGOUT':
      return {
        user: null,
        isFetching: false,
        error: false,
      }
    case 'FOLLOW':
      return {
        ...state,
        user: {
          ...state.user,
          followings: [...state.user.followings, action.payload],
        },
      }
    case 'UNFOLLOW':
      return {
        ...state,
        user: {
          ...state.user,
          followings: state.user.followings.filter(
            (following) => following !== action.payload
          ),
        },
      }
    case 'FOLLOW_PROJECT':
      return {
        ...state,
        user: {
          ...state.user,
          followedProjects: [...state.user.followedProjects, action.payload],
        },
      }
    case 'UNFOLLOW_PROJECT':
      return {
        ...state,
        user: {
          ...state.user,
          followedProjects: state.user.followedProjects.filter(
            (projectId) => projectId !== action.payload
          ),
        },
      }
    case 'ADD_CONTRIBUTOR':
      return {
        ...state,
        user: {
          ...state.user,
          contributors: [...state.user.contributors, action.payload],
        },
      }
    case 'REMOVE_CONTRIBUTOR':
      return {
        ...state,
        user: {
          ...state.user,
          contributors: state.user.contributors.filter(
            (cont) => cont !== action.payload
          ),
        },
      }
    default:
      return state
  }
}

export default AuthReducer
