import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Link } from 'react-router-dom'
import { NotificationViewModel, NotificationBodyWithAccessCard } from '../../..'
import moment from 'moment'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    margin: 10,
    padding: 10,
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.1) ',
  },
  info: {
    fontWeight: 500,
    marginRight: 4,
    marginLeft: 4,
  },
})

const TaskAssignment = ({ classes, ...props }) => {
  NotificationAutoInterUpdator(props)
  const { sender, project, task } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <>
              <Link to={`/profile/${sender?._id}`} className={classes.info}>
                {sender?.name}
              </Link>
              {rtl ? 'وكَل اليك مهمة جديدة' : 'has assigned you a new task'}
              <span className={classes.info}>{task?.title}</span>
              {rtl ? 'فى' : 'in'}
              <Link
                to={`/projectProfile/${project?._id}`}
                className={classes.info}
              >
                {project?.name}
              </Link>
              {rtl ? 'بميعاد تسليم' : 'with a deadline'}
              <span className={classes.info}>
                {/* revise if it works */}
                {moment(task?.TaskDeadline).calendar()}
              </span>
            </>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

const WrappedTaskAssignment = withStyles(styles)(TaskAssignment)

export default WrappedTaskAssignment
