import React from 'react'
import FullInteractiveRequestDetailsModel from '../../../../../../../notificationsSystem/FullInteractiveRequestDetailsModel'
import i18next from 'i18next'

const ProductRequestDetails = (props) => {
  const { card, exprops, requestsUsers } = props
  const { product } = exprops
  const request = card
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)

  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <FullInteractiveRequestDetailsModel
      RequestText={
        <section>
          {requestingUser?.firstName + ' ' + requestingUser?.lastName}{' '}
          {rtl ? 'يعرض' : 'wants to'}{' '}
          <span style={{ fontWeight: 500 }}>{request.subgenre}</span>{' '}
          {rtl ? 'بهذا المنتج' : 'in this Product'}
        </section>
      }
      NotificationCreatorAcceptProps={{
        associativity: 'product',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [
          {
            hashTagType: product?.hashTagType,
            id: product?._id || product?.id,
            title: product?.ProductName,
            image: product?.image,
          },
        ],
      }}
      NotificationCreatorRefuseProps={{
        associativity: 'product',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [
          {
            hashTagType: product?.hashTagType,
            id: product?._id || product?.id,
            title: product?.ProductName,
            image: product?.image,
          },
        ],
      }}
      contributorProps={{
        projectId: product?.projectId,
        MembershipState:
          request.subgenre === 'invest' ? 'Investor' : 'Developer',
      }}
      requestingUser={requestingUser}
      {...props}
    />
  )
}

export default ProductRequestDetails
