import React, { useContext, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import InfoDataIntialStateGen from '../../../../../../formsSystem/InfoDataIntialStateGen'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import { useTranslation } from 'react-i18next'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import axios from 'axios'
import HashTagElementsRefiner from '../../../../../../smallComponents/HashTagElementsRefiner'
import { AuthContext } from '../../../../../../../context/AuthContext'
import i18next from 'i18next'

const styles = () => ({
  personalWrapper: {
    flexDirection: 'column',
    width: '100%',
  },
})

const UserAboutPersonal = ({ classes, ...props }) => {
  const { portfolio, user } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const { user: currentUser } = useContext(AuthContext)
  const authorised = portfolio?.userId === currentUser?._id
  const infoArray = [
    {
      title: 'About',
      titleValue: portfolio?.About,
      allowEmpty: authorised,
      wideMode: true,
      minRows: 4,
    },
    {
      title: 'Academic',
      titleValue: portfolio?.Academic,
      allowEmpty: authorised,
      wideMode: true,
      minRows: 4,
    },
    {
      title: 'Social_Life',
      titleValue: portfolio?.Social_Life,
      allowEmpty: authorised,
      wideMode: true,
      minRows: 4,
    },
    {
      title: 'Courses',
      titleValue: portfolio?.Courses,
      allowEmpty: authorised,
      wideMode: true,
      minRows: 4,
    },
    {
      title: 'Achievments',
      titleValue: portfolio?.Achievments,
      allowEmpty: authorised,
      wideMode: true,
      minRows: 4,
    },
  ]
  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(portfolio) || []
  )
  const newInfoData = FormDataLangModulator(infoData, t)
  const onSave = async () => {
    try {
      await axios.patch(`/portfolios/${portfolio?._id}`, {
        ...newInfoData,
        chosenHashTagElements,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const gridProps = {
    hideEditingTools: user?._id !== currentUser?._id,
    headerText: rtl ? 'معلومات شخصية' : 'Personal Info',
    infoArray,
    setInfoData,
    infoData,
    chosenHashTagElements,
    setChosenHashTagElements,
    onSave,
    ...props,
  }
  const noData = infoArray?.find((n) => n.titleValue)
  return (
    <article
      className={classes.personalWrapper}
      style={{
        display: authorised || noData ? 'flex' : 'none',
      }}
    >
      <EditableGridModel gridProps={gridProps} />
    </article>
  )
}

const WrappedUserAboutPersonal = withStyles(styles)(UserAboutPersonal)

export default WrappedUserAboutPersonal
