import { Grid, IconButton, ListItem } from '@mui/material'
import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { MdArrowRight, MdArrowLeft } from 'react-icons/md'
import { Bars } from 'react-loader-spinner'
import { useTranslation } from 'react-i18next'
import MultiMillify from '../smallComponents/MultiMillify'
import { convertNumbers2Arabic } from 'to-arabic-numbers'
import i18next from 'i18next'

const styles = () => ({
  calenderWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'Center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  monthCard: {
    display: 'flex',
    alignContent: 'center !important',
    justifyContent: 'space-around !important',
    cursor: 'pointer !important',
    flexDirection: 'column !important',
  },
  currentMonth: {
    display: 'flex',
    alignContent: 'center !important',
    justifyContent: 'space-around !important',
    cursor: 'pointer !important',
    backgroundColor: 'gray !important',
    color: 'white !important',
    flexDirection: 'column !important',
  },
  clickedMonth: {
    display: 'flex',
    alignContent: 'center !important',
    justifyContent: 'space-around !important',
    cursor: 'pointer !important',
    background:
      'linear-gradient(75deg, rgb(94, 94, 173) 15%, rgb(105, 113, 122) 100%) !important',
    color: 'white !important',
    flexDirection: 'column !important',
  },
  subTitleNo: {
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  monthName: {
    fontSize: 16,
    fontWeight: 500,
  },
  yearIcon: {
    fontSize: 23,
    '&:hover': {
      fontSize: 25,
    },
  },
  grid: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '5px !important',
  },
  navWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  '@media (max-width: 600px)': {
    subTitleNo: {
      fontSize: 9,
      fontWeight: 300,
    },
    monthName: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
})

const CalenderModel = ({
  classes,
  monthSubTitle,
  months,
  monthOnClick,
  isLoading,
}) => {
  const currentYear = new Date().getUTCFullYear()
  const currentMonth = new Date().getUTCMonth()
  const [chosenYear, setChosenYear] = useState(currentYear)
  const [clickedMonth, setClickedMonth] = useState(currentMonth)
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article className={classes.calenderWrapper}>
      <section className={classes.navWrapper}>
        <IconButton
          edge='start'
          color='inherit'
          onClick={() => {
            setChosenYear(chosenYear - 1)
            setClickedMonth(undefined)
          }}
        >
          {rtl ? (
            <MdArrowRight className={classes.yearIcon} />
          ) : (
            <MdArrowLeft className={classes.yearIcon} />
          )}
        </IconButton>
        {rtl ? convertNumbers2Arabic(`${chosenYear}`) : chosenYear}
        <IconButton
          edge='end'
          color='inherit'
          onClick={() => {
            setChosenYear(chosenYear + 1)
            setClickedMonth(undefined)
          }}
        >
          {rtl ? (
            <MdArrowLeft className={classes.yearIcon} />
          ) : (
            <MdArrowRight className={classes.yearIcon} />
          )}
        </IconButton>
      </section>
      <Grid container className={classes.grid}>
        {months.map((month, monthIndex) => (
          <Grid item lg={3} key={monthIndex}>
            <ListItem
              className={
                clickedMonth === monthIndex
                  ? classes.clickedMonth
                  : currentMonth === monthIndex && chosenYear === currentYear
                  ? classes.currentMonth
                  : classes.monthCard
              }
              onClick={() => {
                setClickedMonth(monthIndex)
                monthOnClick(monthIndex, chosenYear)
              }}
            >
              <span className={classes.monthName}>
                {rtl ? month.ar_month : month.month}
              </span>
              <span className={classes.subTitleNo}>
                {isLoading ? (
                  <Bars
                    height='10'
                    width='10'
                    color='#4fa94d'
                    ariaLabel='bars-loading'
                    wrapperStyle={{
                      marginRight: !rtl && '3px',
                      marginLeft: rtl && '3px',
                    }}
                    wrapperClass=''
                    visible={true}
                  />
                ) : (
                  MultiMillify(month.subTitleNo)
                )}{' '}
                {t(monthSubTitle)}
              </span>
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </article>
  )
}

const WrappedCalenderModel = withStyles(styles)(CalenderModel)

export default WrappedCalenderModel
