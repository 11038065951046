import i18next from 'i18next'
import React from 'react'

const InternalResources = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      {rtl
        ? 'الموارد الداخلية هي نقاط القوة والضعف في المنظمة سواء كانت ملموسة أو غير ملموسة. وهي تشمل المال والإدارة والقوى العاملة والتصنيع والأسواق والمواد والماكياج. يمكن أن تؤثر الموارد الداخلية على أداء وأهداف المنظمة. سلسلة القيمة هي سلسلة من الخطوات المتتالية التي تدخل في إنشاء منتج أو خدمة، من تصميمها الأولي إلى وصولها إلى باب العميل. تحدد سلسلة القيمة كل خطوة في العملية يتم فيها إضافة القيمة، بما في ذلك تحديد المصادر والتصنيع والتسويق. تساعد سلسلة القيمة على زيادة الكفاءة وتقليل تكاليف الأعمال'
        : 'Internal resources are the strengths and weaknesses of an organization that are either tangible or intangible. They include money, management, manpower, manufacturing, markets, materials and make-up. Internal resources can affect the performance and objectives of an organization. A value chain is a series of consecutive steps that go into the creation of a product or service, from its initial design to its arrival at a customer’s door. The value chain identifies each step in the process at which value is added, including sourcing, manufacturing and marketing. A value chain helps increase efficiency and reduce costs for a business'}
    </>
  )
}

export default InternalResources
