import i18next from 'i18next'
import { convertNumbers2Arabic } from 'to-arabic-numbers'

const MultiLangNumber = (number) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return rtl ? convertNumbers2Arabic(`${number}`) : number
}

export default MultiLangNumber
