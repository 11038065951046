import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const InquiryResponseCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `تفاعل ${sender?.name} مع المتطلب الخاص بك`
    : `Your inquiry has been interacted with by ${sender?.name}`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default InquiryResponseCover
