import React from 'react'
import EmptyComponentModel from '../emptyComponentModel/EmptyComponentModel'
import TitleHeader from '../smallComponents/Header'
import NavigatorCardsModel from './NavigatorCardsModel'
import DistributedWinCardsConfigurer from './NavigatorCardsModelComps/DistributedWinCardsConfigurer'

const NavigatorGenerator = ({ navigatorsArray, DCGProps }) => {
  const NavigatorCore = ({ navigator }) => (
    <NavigatorCardsModel
      unitsArray={DistributedWinCardsConfigurer(navigator?.unitsArray || [])}
      {...DCGProps}
      {...navigator?.NavigatorCardsModelProps}
      modelUnitProps={{
        distributedCard: true,
        DCGProps,
        unitsArray: navigator?.unitsArray,
        elementsImages: navigator?.elementsImages || [],
        ...navigator?.modelUnitProps,
      }}
    />
  )
  return navigatorsArray?.map((navigator, index) =>
    navigator?.emptySentence ? (
      <span key={index} style={{ width: '100%' }}>
        <TitleHeader
          headerText={navigator?.headerText}
          hideHeader={navigator?.hideHeader}
          {...DCGProps}
        />
        {navigator?.unitsArray?.length > 0 || navigator?.hideNavigator ? (
          <NavigatorCore navigator={navigator} />
        ) : (
          <EmptyComponentModel emptySentence={navigator?.emptySentence} />
        )}
        {navigator?.IntermediateCompontent}
      </span>
    ) : (
      <span key={index} style={{ width: '100%' }}>
        <span
          style={{
            display:
              navigator?.unitsArray?.length === 0 || navigator?.hideNavigator
                ? 'none'
                : null,
          }}
        >
          <TitleHeader
            headerText={navigator.headerText}
            hideHeader={navigator?.hideHeader}
            {...DCGProps}
          />
          <NavigatorCore navigator={navigator} />
        </span>
        {navigator?.IntermediateCompontent}
      </span>
    )
  )
}

export default NavigatorGenerator
