import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { EditableGridModel, NotificationViewModel } from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const MeetingUpdateNotice = (props) => {
  const { notification, isDarkModeActive, sender, project, socket } = props
  NotificationAutoInterUpdator(props)
  const [meeting, setMeeting] = useState({})
  const [isLoading, setIsLoading] = useState()
  const lang = i18next.language
  const rtl = lang === 'ar'

  useEffect(() => {
    const fetchMeeting = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/meetings/${notification?.accompaniedData?.elements[0]?.id}`
        )
        setMeeting(res.data.meeting)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (notification?.accompaniedData?.elements[0]?.id) fetchMeeting()
  }, [notification?.accompaniedData?.elements[0]?.id])
  const meetingInfo = [
    {
      title: 'Title',
      titleValue: meeting?.Title,
    },
    {
      title: 'Meeting_Purpose',
      titleValue: meeting?.Content,
    },
    {
      title: 'Date',
      titleValue: meeting?.Date,
    },
    {
      title: 'Time',
      titleValue: meeting?.Time,
    },
    {
      title: 'Location',
      titleValue: meeting?.Location,
    },
  ]
  const gridProps = {
    headerText: rtl ? 'تفاصيل الإجتماع' : 'Meeting Details',
    hideEditingTools: true,
    infoArray: meetingInfo,
    isDarkModeActive,
    project,
    socket,
  }
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <section style={{ width: '100%' }}>
          {rtl
            ? 'لقد تم الغاء اجتماع قد التحقت به'
            : 'A meeting you have joined, is cancelled'}
          <EditableGridModel gridProps={gridProps} />
        </section>
      }
      buttonsArray={[]}
    />
  )
}

export default MeetingUpdateNotice
