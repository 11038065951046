import React from 'react'
import TextPost from '../../../../postSystem/TextPost'
import PostProps from '../PostProps'
import PostCenter from './PostCenter'

const SocialPostMiddle = ({ postProps }) => {
  const {
    post,
    // image viewer
    imageViewerDialogOpener,
    imageViewerDialogClose,
    isImageViewerDialogOpen,
    // for commenting system that open from the image
    commentsDialogOpener,
    commentsDialogClose,
    isCommentsDialogOpen,
    //   for notification sys
    commentingSystemView,
    isDarkModeActive,
    user,
    socket,
    setPosts
  } = postProps
  return (
    <PostCenter
      postProps={{
        setPosts,
        socket,
        user,
        post,
        isDarkModeActive,
        // for commenting system that open from the image - the conditioning 'commentingSystemView' for the general notification system
        commentsDialogOpener: commentingSystemView
          ? null
          : commentsDialogOpener,
        commentsDialogClose: commentingSystemView ? null : commentsDialogClose,
        isCommentsDialogOpen: commentingSystemView
          ? null
          : isCommentsDialogOpen,
        commentingSystemView: commentingSystemView
          ? commentingSystemView
          : false,
        ...PostProps(
          {},
          {
            imageViewerProps: commentingSystemView,
          }
        ),
        postBody: (
          <PostCenter
            postProps={{
              post,
              imageViewerDialogOpener,
              imageViewerDialogClose,
              isImageViewerDialogOpen,
              commentingSystemView: true,
              isDarkModeActive,
              setPosts,
            }}
          />
        ),

        postText: <TextPost {...postProps} />,
      }}
    />
  )
}

export default SocialPostMiddle
