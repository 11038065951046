import React from 'react'
import '../skeleton.css'
import withStyles from '@mui/styles/withStyles'
import ProjectControlPanelSkeleton from './ProjectProfileSkeletonComps/ProjectControlPanelSkeleton'
import ProjectIntroSkeleton from './ProjectProfileSkeletonComps/ProjectIntroSkeleton'
import ProjectFeedLeftSkeleton from './ProjectProfileSkeletonComps/ProjectFeedLeftSkeleton'
import ProjectFeedRightSkeleton from './ProjectProfileSkeletonComps/ProjectFeedRightSkeleton'

const styles = () => ({
  profileContainer: {
    flex: 7.93,
  },
  profileWrapper: {
    flex: 19,
  },

  projectContainer: {
    margin: '0 10px 10px 10px',
  },

  projectWallPaperWrapper: {
    height: 170,
    marginBottom: 10,
    borderRadius: 15,
    position: 'relative',
    animation: 'skeleton 1s ease infinite alternate',
    backgroundColor: '#313131',
  },

  projectProfileWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  '@media (max-width: 600px)': {
    profileContainer: {
      display: 'inline-flex',
      alignContent: 'center',
      textSizeAdjust: 'auto',
      justifyContent: 'space-around',
      margin: 0,
      width: '100vw',
    },
    projectContainer: {
      margin: 0,
      width: '100vw',
    },
    projectWallPaperWrapper: {
      borderRadius: 0,
      marginBottom: 0,
      width: '100vw',
    },
    projectProfileWrapper: {
      flexDirection: 'column-reverse',
      width: '100vw',
    },
  },
})

const ProjectProfileSkeleton = ({ classes, projectProps }) => {
  return (
    <article className={classes.profileContainer}>
      <section className={classes.profileWrapper}>
        <div className={classes.projectContainer}>
          <div className={classes.projectWallPaperWrapper}></div>
          <ProjectIntroSkeleton />
          <ProjectControlPanelSkeleton projectProps={projectProps} />
          <article className={classes.projectProfileWrapper}>
            <ProjectFeedLeftSkeleton />
            <ProjectFeedRightSkeleton />
          </article>
        </div>
      </section>
    </article>
  )
}

const WrappedProjectProfileSkeleton = withStyles(styles)(ProjectProfileSkeleton)

export default WrappedProjectProfileSkeleton
