import React from 'react'
import {
  ListItemScreenViewModels,
  ContractEditingEventCover,
  ContractEndingEventCover,
} from '../../../'
import ContributionContractEndingCover from './ActiveContractNotificationModelComps/ContributionContractEndingCover'
import ContributionContractEndingReplyCover from './ActiveContractNotificationModelComps/ContributionContractEndingReplyCover'
import NewProjectOfferContractCover from './ActiveContractNotificationModelComps/NewProjectOfferContractCover'
import NewElementAddedCover from './ActiveContractNotificationModelComps/NewElementAddedCover'
import NewElementRemovedCover from './ActiveContractNotificationModelComps/NewElementRemovedCover'

const ActiveContractNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'opponent',
      conditions: [
        <NewProjectOfferContractCover {...notifProps} />,
        <ContractEditingEventCover {...notifProps} />,
        <ContractEndingEventCover {...notifProps} />,
      ],
    },
    {
      relation: 'contributor',
      conditions: [
        <ContributionContractEndingCover {...notifProps} />,
        <ContributionContractEndingReplyCover {...notifProps} />,
        <NewElementAddedCover {...notifProps} />,
        <NewElementRemovedCover {...notifProps} />,
      ],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default ActiveContractNotificationModel
