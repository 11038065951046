import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const TaskRequestReply = (props) => {
  const { project, sender, notification } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const response = notification?.accompaniedData?.response
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <section>
                قام{' '}
                <Link to={`/profile/${sender?._id}`}>
                  <span style={style}>{sender.name}</span>
                </Link>{' '}
                بالرد على طلب قيام بمهمة فى{' '}
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>{' '}
                بـ {response === 'refuse' ? 'الرفض' : 'القبول'}
              </section>
            ) : (
              <section>
                <Link to={`/profile/${sender?._id}`}>
                  <span style={style}>{sender.name}</span>
                </Link>
                replied to your request for a task in{' '}
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>{' '}
                by {response === 'refuse' ? 'Refusing' : 'Accepting'}
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default TaskRequestReply
