import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import {
  NewProjectPostModel,
  ChartPostModel,
  TextualPostModel,
  ProfileZeroPostModel,
} from './PostBodyModelComponents'
import GenericPostBodyModel from './PostBodyModelComponents/GenericPostBodyModel'
import axios from 'axios'

const styles = () => ({
  postBodyWrapper: {
    display: 'flex',
    textAlign: 'start',
    width: '100%',
  },
})

const PostBodyModel = ({ postBodyProps, classes }) => {
  // cardmediapost dialog
  const [isCardMediaPostDialogOpen, setIsCardMediaPostDialogOpen] =
    useState(false)
  const cardmediapostDialogOpener = () => {
    setIsCardMediaPostDialogOpen(true)
  }
  const cardmediapostDialogClose = () => {
    setIsCardMediaPostDialogOpen(false)
  }

  const [isDialogFullWidth, setIsDialogFullWidth] = useState(false)

  const { post } = postBodyProps
  const element = post?.postElement
  const [project, setProject] = useState(postBodyProps?.project)
  useEffect(() => {
    const fetchPostProject = async () => {
      try {
        const res = await axios.get(`/projects/oneProject/${post?.userId}`)
        setProject(res.data.project)
      } catch (error) {
        console.log(error)
      }
    }
    if (
      post?.userId &&
      post?.feedsSystemCategory === 'page' &&
      // i am not sure yet if project is pushed here anyway
      !postBodyProps?.project
    )
      fetchPostProject()
  }, [post?.userId, post?.feedsSystemCategory, postBodyProps?.project])
  const postBodyPropsII = {
    project,
    ...postBodyProps,
    isCardMediaPostDialogOpen,
    cardmediapostDialogOpener,
    cardmediapostDialogClose,
    isDialogFullWidth,
    setIsDialogFullWidth,
  }
  const postTypes = [
    <TextualPostModel postBodyProps={postBodyPropsII} />,
    <ChartPostModel postBodyProps={postBodyPropsII} />,
    <NewProjectPostModel postBodyProps={postBodyPropsII} />,
    <ProfileZeroPostModel postBodyProps={postBodyPropsII} />,
  ]
  return (
    <article className={classes.postBodyWrapper}>
      {element ? (
        <GenericPostBodyModel
          postBodyProps={postBodyPropsII}
          element={element}
        />
      ) : (
        postTypes[post.postTypeIndex]
      )}
    </article>
  )
}

const WrappedPostBodyModel = withStyles(styles)(PostBodyModel)

export default WrappedPostBodyModel
