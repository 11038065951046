import { Avatar } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  notificationUnitText: {
    margin: 'auto 10px auto 0',
    fontSize: '14px',
    fontWeight: '500 !important',
    overflow: 'hidden',
    width: 'auto',
  },

  avatar: {
    height: '50px !important',
    width: '50px !important',
  },
})

const ListItemScreenViewModels = ({
  classes,
  notification,
  models,
  sender,
  project,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const image = sender ? sender?.image : project ? project?.image : null
  const name = sender ? sender?.name : project ? project?.ProjectName : null
  return (
    <article style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <Avatar
        src={image}
        alt={`${name}'s image`}
        className={classes.avatar}
        sx={{ marginRight: !rtl && '10px', marginLeft: rtl && '10px' }}
      />
      <section className={classes.notificationUnitText}>
        {
          models?.find((model) => model?.relation === notification?.relation)
            ?.conditions[notification?.conditionIndex]
        }
      </section>
    </article>
  )
}

const WrappedListItemScreenViewModels = withStyles(styles)(
  ListItemScreenViewModels
)

export default WrappedListItemScreenViewModels
