import React from 'react'
import { ListItemScreenViewModels } from '../../..'
import InquiryResponseCover from './InquiryNotificationModelComps/InquiryResponseCover'
import InquiryReviewerResponseCover from './InquiryNotificationModelComps/InquiryReviewerResponseCover'

const InquiryNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'inquiryOwner',
      conditions: [<InquiryResponseCover {...notifProps} />],
    },
    {
      relation: 'inquiryReviewer',
      conditions: [<InquiryReviewerResponseCover {...notifProps} />],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default InquiryNotificationModel
