import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignContent: 'space-between',
  },
  title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    fontWeight: 600,
  },
  status: {
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: 'rgba(255, 255, 255, 0.192)',
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    margin: 5,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
})

const CardTitlesModel = ({
  classes,
  title,
  cardMark,
  markColor,
  isDarkModeActive,
  cardFooter,
}) => {
  return (
    <article className={classes.wrapper}>
      <span
        className={classes.status}
        style={{
          color: markColor ? markColor : isDarkModeActive ? 'white' : 'gray',
        }}
      >
        {cardMark}
      </span>
      <span className={classes.title}>{title}</span>
      <hr style={{ width: '100%' }} />
      {cardFooter}
    </article>
  )
}

const WrappedCardTitlesModel = withStyles(styles)(CardTitlesModel)

export default WrappedCardTitlesModel
