import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../../../buttonsSystem/ButtonsSystem'
import NewFormDialogModel from '../../../../../../../../../formsSystem/NewFormDialogModel'
import RequestCreator from '../../../../../../../../../notificationsSystem/RequestCreator'
import CentralTitle from '../../../../../../../../../smallComponents/CentralTitle'
import ClosedView from '../../../../../../../../../smallComponents/ClosedView'
import MultiLangNumber from '../../../../../../../../../smallComponents/MultiLangNumber'
import TransparentBackCard from '../../../../../../../../../smallComponents/TransparentBackCard'
import WindowProps from '../../../../../../../../../windowViewer/WindowProps'
import ContributionProjectsOptions from './ContributionProjectsOptions'
import withStyles from '@mui/styles/withStyles'
import TimeAgoFormattor from '../../../../../../../../../smallComponents/TimeAgoFormattor'
import ToolbarPanel from '../../../../../../../../../toolbarSystem/ToolbarPanel'
import TextEngine from '../../../../../../../../../textProcessingEngine/TextEngine'
import NoPermissionedInteraction from '../../../../../../../../../notificationsSystem/NoPermissionedInteraction'
import ElementRequestsSenders from '../../../../../../../../../notificationsSystem/ElementRequestsSenders'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
  },
})

const ChannelUnAuthorizedAccess = ({ DCGProps, channel, classes }) => {
  const { user } = useContext(AuthContext)
  const { isDarkModeActive } = DCGProps
  const senders = ElementRequestsSenders(channel?._id || channel?.id)
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const activeFun = async ({ project, userId }) => {
    RequestCreator({
      currentUser: user,
      genre: 'channel',
      subgenre: 'new_member',
      elementId: channel?._id,
      requestData: {
        project: {
          _id: DCGProps?.project?._id || project?._id,
          ProjectName: DCGProps?.project?.ProjectName || project?.ProjectName,
        },
      },
    })
  }
  const buttonsArray = [
    {
      title: rtl ? 'أطلب دخول للمحتوى' : 'Ask For Permisssion',
      funs: () => {
        windowOpener()
      },
    },
  ]
  const toolbarConfig = [
    {
      type: 'usersIcon',
      onOpen: '',
      count: String(MultiLangNumber(channel?.usersIds?.length)),
    },
    {
      type: 'projectsIcon',
      onOpen: '',
      count: String(MultiLangNumber(channel?.projectsIds?.length)),
    },
    // { type: 'contractIcon', onOpen: '', count: '' },
    {
      type: 'interestsIcon',
      onOpen: '',
      count: String(MultiLangNumber(channel?.elements?.length)),
    },
  ]

  const [interacted, setInteracted] = useState(false)
  const closeMessage = (
    <>
      <TransparentBackCard
        {...DCGProps}
        content={
          <section>
            <CentralTitle
              title={rtl ? 'الغرض من هذه القناة' : 'Channel Purpose'}
            />
            <TextEngine
              textContent={channel?.Description || ''}
              textPackage={channel}
              {...DCGProps}
            />
            <section className={classes.wrapper}>
              <TimeAgoFormattor date={channel?.createdAt} />
            </section>
            <ToolbarPanel config={toolbarConfig} />
            <section
              style={{
                border: `1px solid ${isDarkModeActive ? 'white' : 'black'}`,
                padding: 5,
                borderRadius: 15,
              }}
            >
              {rtl
                ? 'هذا المحتوى محمى، تحتاج الى إذن لعرضه'
                : 'This content is secured, you have to get permissioned to access it'}
            </section>
          </section>
        }
      />
      {senders === undefined ? (
        <NoPermissionedInteraction checking={true} />
      ) : senders?.includes(user?._id) || interacted ? (
        <NoPermissionedInteraction />
      ) : (
        <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
      )}
      <NewFormDialogModel
        NewFormComponent={
          <ContributionProjectsOptions
            DCGProps={DCGProps}
            windowCloser={windowCloser}
            activeFun={activeFun}
            centralTitle={
              rtl
                ? 'إختر فى أى هيئة تريد المشاركة فى هذه القناة'
                : 'Choose in what entity you want to share in this channel'
            }
            setInteracted={setInteracted}
          />
        }
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        {...DCGProps}
        clickAwayState={true}
        dialogTitle={rtl ? 'إختار الهيئة' : 'Choose Entity'}
      />
    </>
  )
  return <ClosedView {...DCGProps} closeMessage={closeMessage} />
}

const WrappedChannelUnAuthorizedAccess = withStyles(styles)(
  ChannelUnAuthorizedAccess
)

export default WrappedChannelUnAuthorizedAccess
