import React from 'react'
import { ListItemScreenViewModels } from '../../..'
import PermissionRequestCover from './VisionNotificationModelComps/PermissionRequestCover'
import PermissionRequestReplyCover from './VisionNotificationModelComps/PermissionRequestReplyCover'
import VisionOfferCover from './VisionNotificationModelComps/VisionOfferCover'
import VisionOfferReplyCover from './VisionNotificationModelComps/VisionOfferReplyCover'
import IdeaRemoveContributorNotifCover from './VisionNotificationModelComps/IdeaRemoveContributorNotifCover'

const VisionNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'visionOwner',
      conditions: [
        <VisionOfferCover {...notifProps} />,
        <PermissionRequestCover {...notifProps} />,
      ],
    },
    {
      relation: 'visionReviewer',
      conditions: [
        <VisionOfferReplyCover {...notifProps} />,
        <PermissionRequestReplyCover {...notifProps} />,
        <IdeaRemoveContributorNotifCover {...notifProps} />,
      ],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default VisionNotificationModel
