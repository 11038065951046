import React from 'react'
import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { GrAdd } from 'react-icons/gr'
import i18next from 'i18next'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const GeneralAddIcon = ({ classes, noFSPanelProps }) => {
  const { showGeneralAdd, GeneralDialogOpener, tippyContent } = noFSPanelProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <Tooltip title={rtl ? `أضف ${tippyContent}` : `Add ${tippyContent}`}>
      <span
        className={classes.iconWrapper}
        style={{ display: showGeneralAdd ? null : 'none' }}
        onClick={() => GeneralDialogOpener()}
      >
        <GrAdd className={classes.icon} />
      </span>
    </Tooltip>
  )
}

const WrappedGeneralAddIcon = withStyles(styles)(GeneralAddIcon)

export default WrappedGeneralAddIcon
