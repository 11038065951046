import i18next from 'i18next'
import React from 'react'

const ActivitiesAndCompetencies = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return rtl ? (
    <>
      أنشطة وكفاءات الشركة هي مفاهيم ذات صلة تصف ما تفعله الشركة ومدى نجاحها.
      الأنشطة هي المهام أو العمليات المحددة التي تقوم بها الشركة لخلق قيمة
      لعملائها. الكفاءات هي المهارات والمعرفة والقدرات التي تمتلكها الشركة أو
      تطورها لأداء أنشطتها. بعض الأمثلة على الكفاءات التجارية المشتركة هي
      التخطيط الاستراتيجي والقيادة وإدارة المشاريع والاهتمام بالتفاصيل³.
      <br />
      <br />
      قد تحتاج إلى الإجابة على هذه الأسئلة:
      <br />
      - ما هي الموارد الضرورية لضمان تقديم القيمة المقترحة الخاص بنا؟
      <br />
      <br />
      يعتمد هذا على نوع القيمة المقترحة الذي تقدمه لعملائك. القيمة المقترحة هو
      بيان يلخص لماذا يجب على العميل شراء منتجك أو خدمتك. يجب أن يسلط الضوء على
      الفوائد والميزات والتمايز في عرضك. بعض الأمثلة على الموارد التي قد تكون
      ضرورية لالقيم المقترحة المختلفة هي:
      <br />
      <br />
      <ul>
        <li>
          الجودة: قد تحتاج إلى عمال مهرة ومعدات متطورة ومواد عالية الجودة وأنظمة
          صارمة لمراقبة الجودة.
        </li>
        <li>
          الابتكار: قد تحتاج إلى مواهب إبداعية ومنشآت بحث وتطوير وحقوق ملكية
          فكرية وآليات تغذية راجعة.
        </li>
        <li>
          الملاءمة: قد تحتاج إلى قنوات توصيل سريعة وواجهات سهلة الاستخدام ومنصات
          عبر الإنترنت ودعم خدمة العملاء.
        </li>
      </ul>
      <br />
      <br />
      - كيف يمكننا تخصيص الموارد بكفاءة؟
      <br />
      هذا يعتمد على أهداف وأولويات شركتك. يعني تخصيص الموارد بكفاءة استخدامها
      بطريقة تزيد من إنتاجها أو تأثيرها مع تقليل تكلفتها أو هدرها. بعض الخطوات
      العامة التي قد تساعدك في تخصيص الموارد بكفاءة هي:
      <ul>
        <li>حدد أهدافك ومؤشرات الأداء الرئيسية (KPIs) لكل نشاط أو مشروع.</li>
        <li>
          تقدير متطلبات الموارد (مثل الوقت والمال والأشخاص والمعدات) لكل نشاط أو
          مشروع.
        </li>
        <li>
          قارن بين توفر الموارد (مثل الميزانية وقدرة الموظفين والمخزون) بمتطلبات
          الموارد.
        </li>
        <li>
          حدد أولويات الأنشطة أو المشاريع بناءً على مدى إلحاحها وأهميتها
          وتوافقها مع أهدافك.
        </li>
        <li>قم بتعيين أو توزيع الموارد وفقًا لأولوياتك ومراقبة استخدامها.</li>
      </ul>
    </>
  ) : (
    <>
      Activities and competencies of a company are related concepts that
      describe what a company does and how well it does it. Activities are the
      specific tasks or processes that a company performs to create value for
      its customers¹. Competencies are the skills, knowledge and abilities that
      a company possesses or develops to perform its activities². Some examples
      of common business competencies are strategic planning, leadership,
      project management and attention to detail³.
      <br />
      <br />
      You may need to answer these questions:
      <br />
      - What resources are essential to ensure we deliver on our value
      proposition?
      <br />
      <br />
      This depends on the type of value proposition you offer to your customers.
      A value proposition is a statement that summarizes why a customer should
      buy your product or service. It should highlight the benefits, features
      and differentiation of your offering. Some examples of resources that may
      be essential for different value propositions are:
      <br />
      <br />
      <ul>
        <li>
          Quality: You may need skilled workers, advanced equipment,
          high-quality materials and rigorous quality control systems.
        </li>
        <li>
          Innovation: You may need creative talent, research and development
          facilities, intellectual property rights and feedback mechanisms.
        </li>
        <li>
          Convenience: You may need fast delivery channels, user-friendly
          interfaces, online platforms and customer service support.
        </li>
      </ul>
      <br />
      <br />
      - How can we allocate the resources efficiently?
      <br />
      This depends on the goals and priorities of your company. Allocating
      resources efficiently means using them in a way that maximizes their
      output or impact while minimizing their cost or waste. Some general steps
      that may help you allocate resources efficiently are:
      <ul>
        <li>
          Define your objectives and key performance indicators (KPIs) for each
          activity or project.
        </li>
        <li>
          Estimate the resource requirements (such as time, money, people and
          equipment) for each activity or project.
        </li>
        <li>
          Compare the resource availability (such as budget, staff capacity and
          inventory) with the resource requirements.
        </li>
        <li>
          Prioritize the activities or projects based on their urgency,
          importance and alignment with your objectives.
        </li>
        <li>
          Assign or distribute the resources according to your priorities and
          monitor their utilization.
        </li>
      </ul>
    </>
  )
}

export default ActivitiesAndCompetencies
