import React from 'react'
import { NotificationViewModel } from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const NewSubDepManagerAssignmentNotification = (props) => {
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        rtl
          ? 'لقد تم تعيين مديرا جديداً للقسم الفرعى الخاص بك'
          : 'New manager has been assigned to your sub-department'
      }
      buttonsArray={[]}
    />
  )
}

export default NewSubDepManagerAssignmentNotification
