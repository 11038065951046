import React, { useEffect, useState } from 'react'
import TitleHeader from '../../../../../../smallComponents/Header'
import AspectInterrelations from './AspectInterrelations'
import AspectStructureUnit from './AspectStructureUnit'
import AspectButtons from './AspectButtons'
import AspectTitle from './AspectTitle'
import MarketingAspectCore from './MarketingAspectCore'
import TextEngineToolsFooter from '../../../../../../smallComponents/TextEngineToolsFooter'
import AspectTags from './AspectTags'
import axios from 'axios'
import BlocksLoader from '../../../../../../smallComponents/BlocksLoader'
import i18next from 'i18next'

const MarketingStrategyAspectProfile = (props) => {
  const { aspect, DCGProps, hidCustomEditing, externalLinkMode } = props
  const { project, isDarkModeActive } = DCGProps
  const [aspectMods, setAspectMods] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchAspectMods = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/marketingStrategiesCustomizations/${aspect?.id}/${project?._id}`
        )
        setAspectMods(res.data.aspect)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (project?._id) {
      fetchAspectMods()
    } else {
      setIsLoading(false)
    }
  }, [aspect?.id, project?._id])
  const lang = i18next.language
  const rtl = lang === 'ar'
  const aspectStructureConfigs = [
    {
      title: 'How',
      definition: rtl
        ? 'تفي بهذا الجانب من استراتيجية التسويق الخاصة بك؟'
        : ' to fulfill this aspect of your Marketing strategy?',
      aspectDerived: aspect.how,
      custom: aspectMods?.how,
      hideCustom: hidCustomEditing,
    },
    {
      title: 'Which',
      definition: rtl
        ? 'هو نموذج الأعمال الذي يجب أن يأخذ في الاعتبار هذا الجانب؟'
        : 'business model should consider this aspect?',
      aspectDerived: aspect.which,
      custom: aspectMods?.which,
      hideCustom: hidCustomEditing,
    },
    {
      title: 'Examples',
      definition: '',
      aspectDerived: aspect.examples,
      hideCustom: true,
    },
  ]

  return isLoading ? (
    <BlocksLoader {...DCGProps} />
  ) : (
    <div style={{ color: isDarkModeActive ? 'white' : 'black' }}>
      <AspectTitle {...props} />
      <MarketingAspectCore {...props} />
      <AspectTags {...props} />
      <TitleHeader
        headerText={
          rtl ? 'جانب من استراتيجية التسويق' : 'Marketing Strategy Aspect'
        }
      />
      {aspectStructureConfigs
        .filter((asp) => asp.aspectDerived)
        .map((str, index) => (
          <AspectStructureUnit
            subAspect={str}
            key={index}
            {...props}
            aspectMods={aspectMods}
          />
        ))}
      <AspectButtons {...props} />
      <AspectInterrelations {...props} />
      <TextEngineToolsFooter
        {...DCGProps}
        hideTETFooter={hidCustomEditing || externalLinkMode}
      />
    </div>
  )
}

export default MarketingStrategyAspectProfile
