import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: 30,
    fontWeight: 500,
    margin: '20px auto',
  },
})

const ChannelTitle = ({ channel, classes }) => {
  return <div className={classes.wrapper}>{channel?.Title}</div>
}

const WrappedChannelTitle = withStyles(styles)(ChannelTitle)

export default WrappedChannelTitle
