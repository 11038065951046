import React from 'react'
import ContributionContractEnding from '../ContractsNotifsDialogLeftComponents/ContractNotifListItemComponents/ActiveContractNotificationModelComps/ContributionContractEnding'
import ContributionContractEndingReply from '../ContractsNotifsDialogLeftComponents/ContractNotifListItemComponents/ActiveContractNotificationModelComps/ContributionContractEndingReply'
import NewElementAdded from '../ContractsNotifsDialogLeftComponents/ContractNotifListItemComponents/ActiveContractNotificationModelComps/NewElementAdded'
import NewProjectOfferContract from '../ContractsNotifsDialogLeftComponents/ContractNotifListItemComponents/ActiveContractNotificationModelComps/NewProjectOfferContract'
import NewElementRemoved from '../ContractsNotifsDialogLeftComponents/ContractNotifListItemComponents/ActiveContractNotificationModelComps/NewElementRemoved'
import {
  ContractEditingEvent,
  ContractEndingEvent,
} from './ActiveContractNotificationViewModelComponents/ContractNotifBodyComps'

const ActiveContractNotificationViewModel = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const contract = notification?.accompaniedData?.elements?.find(
    (e) => e?.hashTagType === 'contract'
  )
  const notifPropsII = {
    ...notifProps,
    contract,
  }
  const mssgs = [
    {
      relation: 'opponent',
      comp: [
        <NewProjectOfferContract {...notifPropsII} />, 
        <ContractEditingEvent {...notifPropsII} />,
        <ContractEndingEvent {...notifPropsII} />,
      ],
    },
    {
      relation: 'contributor',
      comp: [
        <ContributionContractEnding {...notifPropsII} />, 
        <ContributionContractEndingReply {...notifPropsII} />,
        <NewElementAdded {...notifPropsII} />,
        <NewElementRemoved {...notifPropsII} />,
      ],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default ActiveContractNotificationViewModel
