import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const CustomerMigration = () => {
  const { t } = useTranslation('CustomerMigration')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 5,
    category: t('category_ms05'),
    title: t('title_ms05'),
    subTitle: t('subTitle_ms05'),
    main: t('main_ms05'),
    how: t('how_ms05'),
    usedInModels: [],
    tags: rtl
      ? [
          'قرارات العملاء',
          'ملاحظات العملاء',
          "خدمة العملاء'",
          'فئات العملاء',
          'تجربة العميل',
        ]
      : [
          'Customer Decisions',
          'Customer Feedback',
          'Customer Service',
          'Customer Segmentation',
          'Tailed Offerings',
          'Customer Experience',
        ],
    examples: t('examples_ms05'),
    hashTagType: 'markStraAspect',
  }
}

export default CustomerMigration
