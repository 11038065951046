import React, { useState } from 'react'
import FourImagesModel from './ImagesSystemComponents/FourImagesModel'
import ImageViewer from './ImagesSystemComponents/ImageViewer'
import MultipleImagesModel from './ImagesSystemComponents/MultipleImagesModel'
import PostImagesGalleryView from './ImagesSystemComponents/PostImagesGalleryView'
import SingleImageModel from './ImagesSystemComponents/SingleImageModel'
import ThreeImagesModel from './ImagesSystemComponents/ThreeImagesModel'
import TwoImagesModel from './ImagesSystemComponents/TwoImagesModel'

const ImagesSystem = (props) => {
  const { imagesArray, imageViewer, commentingSystemView } = props
  const [viewedImageIndex, setViewedImageIndex] = useState(0)
  const imageGridsModels = [
    <SingleImageModel {...props} />,
    <TwoImagesModel {...props} />,
    <ThreeImagesModel {...props} />,
    <FourImagesModel {...props} />,
    <MultipleImagesModel {...props} />,
  ]
  return imageViewer ? (
    <ImageViewer
      {...props}
      setViewedImageIndex={setViewedImageIndex}
      viewedImageIndex={viewedImageIndex}
    />
  ) : commentingSystemView && imagesArray?.length > 0 ? (
    <PostImagesGalleryView
      {...props}
      setViewedImageIndex={setViewedImageIndex}
      viewedImageIndex={viewedImageIndex}
    />
  ) : imagesArray?.length === 1 ? (
    imageGridsModels[0]
  ) : imagesArray?.length === 2 ? (
    imageGridsModels[1]
  ) : imagesArray?.length === 3 ? (
    imageGridsModels[2]
  ) : imagesArray?.length === 4 ? (
    imageGridsModels[3]
  ) : imagesArray?.length > 4 ? (
    imageGridsModels[4]
  ) : null
}

export default ImagesSystem
