import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const IdeaRemoveContributorNotifCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `(ت)قام ${sender?.name} بحذفك من قائمة المشاركين و كذلك قناة المشاركة الخاصة بفكرته/فكرتها`
    : `${sender?.name} has removed you from his(her) idea contributors list and the associated channel`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default IdeaRemoveContributorNotifCover
