import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const LockInBusinessModel = () => {
  const { t } = useTranslation('LockInBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 31,
    title: t('title_bm31'),
    subTitle: t('subTitle_bm31'),
    main: t('main_bm31'),
    when: t('when_bm31'),
    who: t('who_bm31'),
    what: t('what_bm31'),
    how: t('how_bm31'),
    why: t('why_bm31'),
    examples: rtl
      ? [
          'جيليت - 1908 - الولايات المتحدة الأمريكية',
          'ليغو - 1932 - الدنمارك',
          'نستله - 1866 - سويسرا',
          'iTunes - 2001 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Gillette - 1908 - USA',
          'Lego - 1932 - Denmark',
          'Nestlé - 1866 - Switzerland',
          'iTunes - 2001 - USA',
        ],
    tags: rtl
      ? ['ولاء العملاء', 'يصعب رفضه', 'خدمة اساسية', 'ريادة السوق']
      : [
          'Customer Loyalty',
          'Hard to Dismiss',
          'Cornerstone Service',
          'Market Leader',
        ],
    considerations: t('considerations_bm31'),
    combinations: ['$LBM44', '$LBM13', '$LBM19', '$LBM12', '$LBM28', '$LBM24'],
    hashTagType: 'businessModelTemp',
  }
}

export default LockInBusinessModel
