const DepartmentsEnumArray = [
  {
    title: 'Production',
    image: '/assets/companyDepartments/production2.avif',
    id: 0,
  },
  {
    title: 'Marketing',
    image: '/assets/companyDepartments/marketing1.avif',
    id: 1,
  },
  {
    title: 'Adminstartion',
    image: '/assets/companyDepartments/managment1.avif',
    id: 2,
  },
  {
    title: 'R&D',
    image: '/assets/companyDepartments/development1.avif',
    id: 3,
  },
  {
    title: 'Financial',
    image: '/assets/companyDepartments/financial1.avif',
    id: 4,
  },
  {
    title: 'Legal',
    image: '/assets/companyDepartments/legal2.avif',
    id: 5,
  },
]


export default DepartmentsEnumArray