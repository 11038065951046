import React from 'react'
import { ListItemScreenViewModels } from '../../..'
import CampaignChangesCover from './CampaignNotificationModelComps/CampaignChangesCover'
import CampaignNewRequestsCover from './CampaignNotificationModelComps/CampaignNewRequestsCover'
import CampaignRequestReplyCover from './CampaignNotificationModelComps/CampaignRequestReplyCover'
import CampaignRemoveContributorNotifCover from './CampaignNotificationModelComps/CampaignRemoveContributorNotifCover'

const CampaignNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'creator',
      conditions: [<CampaignNewRequestsCover {...notifProps} />],
    },
    {
      relation: 'visitor',
      conditions: [<CampaignRequestReplyCover {...notifProps} />],
    },
    {
      relation: 'contributor',
      conditions: [
        <CampaignChangesCover {...notifProps} />,
        <CampaignRemoveContributorNotifCover {...notifProps} />,
      ],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default CampaignNotificationModel