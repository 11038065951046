import { List } from '@mui/material'
import React, { Suspense } from 'react'
import ContribNotifListItem from './ContributorsNotifsDialogLeftComponents/ContribNotifListItem'
import CustomCircularProgress from '../../../smallComponents/CustomCircularProgress'
import withStyles from '@mui/styles/withStyles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FallingLines } from 'react-loader-spinner'
import LoadMoreButton from '../../../smallComponents/LoadMoreButton'
import i18next from 'i18next'
const SearchEngine = React.lazy(() =>
  import('../../../searchSystem/SearchEngine')
)

const styles = () => ({
  notificationsArea: {
    overflowY: 'auto !important',
    position: 'absolute',
  },
})

const ContributorsNotifsDialogLeft = ({ classes, props }) => {
  const {
    isDarkModeActive,
    notifications,
    contribCurrentResponse,
    setPage,
    page,
  } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <section
        style={{
          overflowY: 'auto',
          marginTop: 20,
        }}
        id={'scrollableTarget2'}
      >
        <List
          className={classes.notificationsArea}
          sx={{
            background: isDarkModeActive
              ? 'rgba(255, 255, 255, 0.2) !important'
              : 'rgba(210, 221, 240, 0.2)  !important',
            borderRadius: '15px',
            marginRight: !rtl && '10px',
            marginLeft: rtl && '10px',
          }}
        >
          <Suspense fallback={<CustomCircularProgress />}>
            <InfiniteScroll
              dataLength={notifications.length}
              next={() => {
                setPage((prev) => prev + 1)
              }}
              hasMore={contribCurrentResponse}
              scrollThreshold={'1000px'}
              scrollableTarget={'scrollableTarget2'}
              loader={
                page === 1 ? (
                  <LoadMoreButton setPage={setPage} />
                ) : (
                  <FallingLines
                    color='#4fa94d'
                    width='100'
                    visible={true}
                    ariaLabel='falling-lines-loading'
                  />
                )
              }
            >
              <SearchEngine
                searchQuery={''}
                searchElements={notifications}
                SearchedElementComponent={ContribNotifListItem}
                SearchedElementComponentProps={props}
                searchIdtentifiers={[]}
              />
            </InfiniteScroll>
          </Suspense>
        </List>
      </section>
    </>
  )
}

const WrappedContributorsNotifsDialogLeft = withStyles(styles)(
  ContributorsNotifsDialogLeft
)

export default WrappedContributorsNotifsDialogLeft
