import i18next from 'i18next'
import React from 'react'

const RevenueStreams = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return rtl ? (
    <>
      مرحبا مجددا. تدفقات إيرادات الشركة هي مصادر الدخل التي تولدها الشركة من
      منتجاتها أو خدماتها². إنها عنصر مهم في نموذج العمل ، الذي يصف كيفية إنشاء
      الشركة وتقديمها والتقاطها لعملائها³. بعض الأمثلة على الأنواع الشائعة
      لتدفقات الإيرادات هي:
      <ul>
        <li>
          بيع الأصول: بيع حقوق الملكية لأصل مادي أو غير ملموس. على سبيل المثال ،
          بيع الكتب أو السيارات أو تراخيص البرامج.
        </li>
        <li>
          رسوم الاستخدام: فرض رسوم على العملاء بناءً على عدد مرات استخدامهم
          للخدمة¹. على سبيل المثال ، فرض رسوم على استهلاك الكهرباء أو المكالمات
          الهاتفية أو تأجير السيارات.
        </li>
        <li>
          رسوم الاشتراك: تحصيل رسوم من العملاء للوصول إلى خدمة ما لفترة زمنية
          محددة¹. على سبيل المثال ، فرض رسوم على عضوية Netflix أو عضوية الصالة
          الرياضية أو الاشتراك في المجلات.
        </li>
        <li>
          التأجير أو التأجير أو الإقراض: منح العملاء حق الوصول المؤقت إلى أحد
          الأصول مقابل رسوم. على سبيل المثال ، تأجير الشقق أو تأجير المعدات أو
          إقراض المال.
        </li>
        <li>
          الترخيص: السماح للعملاء باستخدام الملكية الفكرية المحمية مقابل
          الإتاوات. على سبيل المثال ، ترخيص الموسيقى أو براءات الاختراع أو
          العلامات التجارية.
        </li>
        <li>
          رسوم السمسرة: فرض رسوم على العملاء للوساطة بين طرفين أو أكثر¹. على
          سبيل المثال ، فرض عمولات على وكلاء العقارات أو سماسرة الأوراق المالية
          أو وكلاء السفر.
        </li>
        <li>
          رسوم الإعلان: تحصيل رسوم من العملاء مقابل عرض الإعلانات على منصة
          مملوكة للشركة¹. على سبيل المثال ، فرض رسوم على إعلانات Google أو
          إعلانات Facebook أو الإعلانات التلفزيونية.
        </li>
      </ul>
      <br />
      قد تحتاج إلى الإجابة على هذه الأسئلة:
      <br />
      <br />
      - ما هي مصادر دخلنا؟
      <br />
      يعتمد هذا على المنتجات أو الخدمات التي تقدمها وكيفية تسعيرها. قد يكون لديك
      واحد أو أكثر من تدفقات الإيرادات حسب نموذج عملك. لتحديد مصادر الدخل الخاصة
      بك ، تحتاج إلى الإجابة على الأسئلة التالية:
      <ul>
        <li>- من هم زبائنك؟ من الذي تستهدفه بمنتجاتك أو خدماتك؟</li>
        <li>
          - ما هو القيمة المقترحة الخاص بك؟ ما هي الفوائد التي تقدمها لعملائك؟
        </li>
        <li>
          - كيف تصل لعملائك؟ ما القنوات التي تستخدمها للتواصل وتقديم منتجاتك أو
          خدماتك؟
        </li>
        <li>- كيف تشحن عملائك؟ ما هي آليات التسعير التي تستخدمها؟</li>
      </ul>
      <br />
      - ما الذي يرغب العميل في دفعه؟
      <br />
      <br />
      هذا يعتمد على مقدار القيمة التي يرونها من منتجاتك أو خدماتك. العملاء على
      استعداد للدفع مقابل شيء يحل مشاكلهم أو يلبي احتياجاتهم أو يلبي رغباتهم.
      لتحديد ما يرغب عميلك في دفعه ، عليك الإجابة عن الأسئلة التالية:
      <ul>
        <li>
          ما هي نقاط ضعف العميل؟ ما التحديات التي يواجهونها والتي يمكن أن تتصدى
          لها منتجاتك أو خدماتك؟
        </li>
        <li>
          ما هي مكاسب العميل؟ ما الفوائد التي يتوقعونها من استخدام منتجاتك أو
          خدماتك؟
        </li>
        <li>
          كيف تميز نفسك عن المنافسين؟ ما الذي يجعل منتجاتك أو خدماتك فريدة
          ومتفوقة؟
        </li>
        <li>
          - كيف يدفع العملاء في الوقت الحاضر؟ كيف يجب أن يدفعوا في المستقبل؟
          <br />
          <br />
          هذا يعتمد على مدى ملاءمة ومرونة طرق الدفع الخاصة بك. يدفع العملاء
          حاليًا باستخدام النقود وبطاقات الائتمان وبطاقات الخصم والمدفوعات عبر
          الإنترنت (مثل PayPal) وما إلى ذلك قد يفضلون طرق دفع مختلفة حسب
          تفضيلاتهم وحالاتهم. لتحديد كيفية الدفع للعملاء في المستقبل ،
        </li>
      </ul>
      <br />
      <br />
      تحتاج للإجابة على هذه الأسئلة:
      <br />
      <br />
      <ul>
        <li>
          - كيف يمكنك جعل الدفع أسهل وأسرع للعملاء؟ هل يمكنك تقديم مدفوعات عبر
          الإنترنت ، أو مدفوعات عبر الهاتف المحمول (مثل Apple Pay) ، أو مدفوعات
          بدون تلامس ، وما إلى ذلك؟
        </li>
        <li>
          - كيف يمكنك جعل الدفع أكثر أمانًا وشفافية للعملاء؟ هل يمكنك تقديم
          التشفير والمصادقة (مثل القياسات الحيوية) والإيصالات وما إلى ذلك؟
        </li>
        <li>
          - كيف يمكنك جعل الدفع أكثر مرونة وجاذبية للعملاء؟ هل يمكنك تقديم
          خصومات وكوبونات (مثل Groupon) وبرامج ولاء وما إلى ذلك؟
        </li>
        <li>- ما مقدار مساهمة كل مصدر دخل في إجمالي حجم الأعمال؟</li>
      </ul>
      <br />
      هذا يعتمد على مدى ربحية كل تدفق من مصادر الدخل. معدل الدوران هو المبلغ
      الإجمالي للأموال التي تتلقاها الشركة من مبيعاتها خلال فترة معينة⁵. لحساب
      مقدار مساهمة كل تدفق من مصادر الدخل في إجمالي حجم الأعمال ، تحتاج إلى
      الإجابة على هذه الأسئلة:
      <ul>
        <li>
          كم عدد الوحدات التي تبيعها لكل تدفق دخل؟ يُعرف هذا أيضًا باسم حجم
          المبيعات.
        </li>
        <li>
          ما مقدار المال الذي تتقاضاه لكل وحدة لكل تدفق دخل؟ يُعرف هذا أيضًا
          بسعر البيع.
        </li>
        <li>
          ما مقدار المال الذي تنفقه لكل وحدة لكل تدفق دخل؟ يُعرف هذا أيضًا باسم
          تكلفة البضائع المباعة.
        </li>
      </ul>
      بعد ذلك ، يمكنك استخدام هذه الصيغة لمعرفة هامش المساهمة لكل تدفق إيرادات:
      <br />
      هامش المساهمة = سعر البيع - تكلفة البضائع المباعة
      <br />
      يخبرك هامش المساهمة بالمبلغ الذي تساهم به كل وحدة مباعة في تغطية تكاليفك
      الثابتة (مثل الإيجار والرواتب وما إلى ذلك) وتحقيق الربح.
    </>
  ) : (
    <>
      Hello again. Revenue streams of a company are the sources of income that a
      company generates from its products or services². They are an important
      element of a business model, which describes how a company creates,
      delivers and captures value for its customers³. Some examples of common
      types of revenue streams are:
      <ul>
        <li>
          Asset sale: Selling ownership rights to a physical or intangible
          asset¹. For example, selling books, cars or software licenses.
        </li>
        <li>
          Usage fee: Charging customers based on how often they use a service¹.
          For example, charging for electricity consumption, phone calls or car
          rentals.
        </li>
        <li>
          Subscription fee: Charging customers for access to a service for a
          fixed period of time¹. For example, charging for Netflix membership,
          gym membership or magazine subscription.
        </li>
        <li>
          Renting, leasing or lending: Granting customers temporary access to an
          asset for a fee¹. For example, renting out apartments, leasing
          equipment or lending money.
        </li>
        <li>
          Licensing: Allowing customers to use protected intellectual property
          in exchange for royalties¹. For example, licensing music, patents or
          trademarks.
        </li>
        <li>
          Brokerage fee: Charging customers for intermediating between two or
          more parties¹. For example, charging commissions for real estate
          agents, stock brokers or travel agents.
        </li>
        <li>
          Advertising fee: Charging customers for displaying ads on a platform
          owned by the company¹. For example, charging for Google ads, Facebook
          ads or TV commercials.
        </li>
      </ul>
      <br />
      You may need to answer these questions:
      <br />
      <br />
      - What are our sources of revenue?
      <br />
      This depends on what products or services you offer and how you price
      them. You may have one or more revenue streams depending on your business
      model. To identify your sources of revenue, you need to answer these
      questions:
      <ul>
        <li>
          - Who are your customer segments? Who are you targeting with your
          products or services?
        </li>
        <li>
          - What is your value proposition? What benefits do you offer to your
          customers?
        </li>
        <li>
          - How do you reach your customers? What channels do you use to
          communicate and deliver your products or services?
        </li>
        <li>
          - How do you charge your customers? What pricing mechanisms do you
          use?
        </li>
      </ul>
      <br />
      - What is the customer willing to pay for?
      <br />
      <br />
      This depends on how much value they perceive from your products or
      services. Customers are willing to pay for something that solves their
      problems, satisfies their needs or fulfills their desires. To determine
      what your customer is willing to pay for, you need to answer these
      questions:
      <ul>
        <li>
          What are the customer's pain points? What challenges do they face that
          your products or services can address?
        </li>
        <li>
          What are the customer's gains? What benefits do they expect from using
          your products or services?
        </li>
        <li>
          How do you differentiate yourself from competitors? What makes your
          products or services unique and superior?
        </li>
        <li>
          - How do customers pay in present? How should they pay in the future?
          <br />
          <br />
          This depends on how convenient and flexible your payment methods are.
          Customers pay in present by using cash, credit cards, debit cards,
          online payments (such as PayPal) etc.⁴ They may prefer different
          payment methods depending on their preferences and situations. To
          decide how customers should pay in the future,
        </li>
      </ul>
      <br />
      <br />
      you need to answer these questions:
      <br />
      <br />
      <ul>
        <li>
          - How can you make payment easier and faster for customers? Can you
          offer online payments, mobile payments (such as Apple Pay),
          contactless payments etc.?
        </li>
        <li>
          - How can you make payment more secure and transparent for customers?
          Can you offer encryption, authentication (such as biometrics),
          receipts etc.?
        </li>
        <li>
          - How can you make payment more flexible and attractive for customers?
          Can you offer discounts, coupons (such as Groupon), loyalty programs
          etc.?
        </li>
        <li>
          - How much does each revenue stream contribute to the overall
          turnover?
        </li>
      </ul>
      <br />
      This depends on how profitable each revenue stream is. Turnover is the
      total amount of money that a company receives from its sales during a
      given period⁵. To calculate how much each revenue stream contributes to
      the overall turnover, you need to answer these questions:
      <ul>
        <li>
          How many units do you sell per revenue stream? This is also known as
          sales volume.
        </li>
        <li>
          How much money do you charge per unit per revenue stream? This is also
          known as sales price.
        </li>
        <li>
          How much money do you spend per unit per revenue stream? This is also
          known as cost of goods sold.
        </li>
      </ul>
      Then, you can use this formula to find out the contribution margin of each
      revenue stream:
      <br />
      Contribution margin = Sales price – Cost of goods sold
      <br />
      The contribution margin tells you how much money each unit sold
      contributes to covering your fixed costs (such as rent, salaries etc.) and
      generating profit.
    </>
  )
}

export default RevenueStreams
