import React from 'react'
import { Document, Font } from '@react-pdf/renderer'
import GPDFDPage from './GeneralPDFDocumentComps/GPDFDPage'
import HashTagsLinksPage from './GeneralPDFDocumentComps/HashTagsLinksPage'

Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
})

Font.register({
  family: 'Lato',
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
})

Font.register({
  family: 'Lato Italic',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
})

Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
})

Font.register({
  family: 'Roboto',
  src: `/assets/fonts/Cairo-Black.ttf`,
})

const GeneralPDFDocument = ({ button, pages }) => {
  const { element, strategy, model, contract, plan } = button.PDFGenProps
  const genElement = element ||
    (strategy && { ...strategy, title: 'Strategy' }) ||
    (model && {
      ...model,
      title: 'Business Model',
    }) ||
    contract || plan && { ...plan, title: 'Plan' }
  const allPages = pages.concat(
    genElement?.chosenHashTagElements?.length > 0
      ? [<HashTagsLinksPage button={button} genElement={genElement} />]
      : []
  )
  return (
    <Document>
      {allPages?.map((page, i) => (
        <GPDFDPage
          page={page}
          key={i}
          button={button}
          genElement={genElement}
        />
      ))}
    </Document>
  )
}

export default GeneralPDFDocument
