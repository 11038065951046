import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const UsePathnameEffect = (Fun) => {
  const pathname = useLocation().pathname
  const firstUpdate = useRef(pathname)

  useEffect(() => {
    if (firstUpdate.current !== pathname) {
      typeof Fun === 'function' && Fun()
    }
  }, [pathname])
}

export default UsePathnameEffect
