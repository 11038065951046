const SettingsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        settings: action.payload,
        isFetching: true,
        error: false,
      }

    default:
      return state
  }
}

export default SettingsReducer
