import ChosenElementPropertyName from './ChosenElementPropertyName'
import TextSymbolPortion from './TextSymbolPortion'

// adding the hashtag name to the text and replacing the text added by the user
const HashTagReplacer = ({
  setTextContent,
  chosenHashtagComp,
  lastHashTagSymbol,
  textContent,
  lastHashTag,
}) => {
  const textSymbolPortion = TextSymbolPortion({
    lastHashTagSymbol,
    chosenHashtagComp,
  })
  const chosenElementPropertyName = ChosenElementPropertyName({
    lastHashTagSymbol,
    chosenHashtagComp,
  })
  return (
    typeof chosenHashtagComp === 'object' &&
    setTextContent(
      `${textContent.replace(
        lastHashTag,
        textSymbolPortion +
          chosenHashtagComp[chosenElementPropertyName].split(' ').join('')
      )}`
    )
  )
}

export default HashTagReplacer
