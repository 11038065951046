import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AccessCardGenerator from '../../../../../../notificationsSystem/NotificationsComponents/ContractsNotificationsComponents/ContractsNotifsDialogRightComponents/NotificationBodyWithAccessCardComps/AccessCardGenerator'

const IssueElements = (props) => {
  const { issue, DCGProps } = props
  const [issuedContract, setIssuesContract] = useState({})
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([])
  useEffect(() => {
    const fetchContract = async () => {
      try {
        const res = await axios.get(`/contracts/${issue?.contractId}`)
        setIssuesContract(res.data.contract)
        setUsers(res.data.users)
        setProjects(res.data.projects)
      } catch (error) {
        console.log(error)
      }
    }
    if (issue?.contractId) fetchContract()
  }, [issue?.contractId])
  return (
    <AccessCardGenerator
      element={issuedContract}
      {...DCGProps}
      modelProps={{ profileProps: { users, projects } }}
    />
  )
}

export default IssueElements
