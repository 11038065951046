import React from 'react'
import { Dna } from 'react-loader-spinner'

const DNALoader = () => {
  return (
    <Dna
      visible={true}
      height='80'
      width='80'
      ariaLabel='dna-loading'
      wrapperStyle={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
      wrapperClass='dna-wrapper'
    />
  )
}

export default DNALoader