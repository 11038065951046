import React from 'react'
import FreeSelectComponent from '../../formsSystem/FreeSelectComponent'
import { Input } from '@mui/material'
import DetailsGridModel from '../../formsSystem/DetailsGridModel'
import i18next from 'i18next'

const SpreadsheetChartConfigs = ({
  inputValue,
  setInputValue,
  stampType,
  setStampType,
  menuOptions,
  DCGProps,
  chartTypes,
  chartType,
  setChartType,
}) => {
  const { isDarkModeActive } = DCGProps
  const inputValueHandler = (event) => {
    setInputValue(event.currentTarget.value)
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  const initialStampInputs = [
    // for days
    <Input
      style={{ color: isDarkModeActive ? 'white' : 'black' }}
      value={inputValue}
      onChange={(event) => inputValueHandler(event)}
      type='date'
    />,
    // for months
    <Input
      style={{ color: isDarkModeActive ? 'white' : 'black' }}
      value={inputValue}
      onChange={(event) => inputValueHandler(event)}
      type='month'
    />,
    // for year
    <Input
      style={{ color: isDarkModeActive ? 'white' : 'black' }}
      value={inputValue}
      onChange={(event) => inputValueHandler(event)}
      type='number'
    />,
  ]
  const infoArray = [
    {
      title: rtl ? 'وحدة' : ' Step Unit',
      titleValue: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FreeSelectComponent
            {...DCGProps}
            onChange={(event) => {}}
            menuOptions={menuOptions}
            option={stampType}
            setOption={setStampType}
          />
          <div style={{ margin: 'auto 10px' }}>
            {stampType > 0 && initialStampInputs[stampType - 1]}
          </div>
        </div>
      ),
    },
    {
      title: rtl ? 'نوع الرسم' : 'Chart Type',
      titleValue: (
        <FreeSelectComponent
          {...DCGProps}
          onChange={(event) => {}}
          menuOptions={chartTypes}
          option={chartType}
          setOption={setChartType}
        />
      ),
    },
  ]
  return (
    <DetailsGridModel
      infoArray={infoArray}
      isDarkModeActive={isDarkModeActive}
    />
  )
}

export default SpreadsheetChartConfigs
