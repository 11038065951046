import React from 'react'
import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { BiShare } from 'react-icons/bi'
import ShareSystem from '../../sharingSystem/ShareSystem'
import FreePostGenerator from '../../feedsSystem/FreePostGenerator'
import PostProps from '../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import i18next from 'i18next'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const SharingToFeed = ({ classes, noFSPanelProps }) => {
  const { showShareToFeed, element, mode } = noFSPanelProps
  const { shareDialogOpener, shareDialogClose, isShareDialogOpen } = PostProps(
    {},
    {
      shareSystemProps: true,
    }
  )
  const shareSystemProps = {
    shareTypeIndex: 2,
    post:
      mode === 'chart_post'
        ? element
        : FreePostGenerator(element?.hashTagType, [], element),
    isShareDialogOpen,
    shareDialogClose,
    ...noFSPanelProps,
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <Tooltip title={rtl ? 'شارك فى منشور جديد' : 'Share to Feed'}>
        <span
          className={classes.iconWrapper}
          style={{ display: showShareToFeed ? null : 'none' }}
          onClick={() => {
            shareDialogOpener()
          }}
        >
          <BiShare className={classes.icon} />
        </span>
      </Tooltip>
      <ShareSystem shareSystemProps={shareSystemProps} />
    </>
  )
}

const WrappedSharingToFeed = withStyles(styles)(SharingToFeed)

export default WrappedSharingToFeed
