import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'

const DefaultLocationSetter = () => {
  const { user } = useContext(AuthContext)
  const [location, setLocation] = useState(null)
  useEffect(() => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition((position) =>
        setLocation(position)
      )
  }, [user?._id])

  const position = {
    latitude: location?.coords?.latitude,
    longitude: location?.coords?.longitude,
  }
  useEffect(() => {
    const updateUserLoaction = async () => {
      try {
        await axios.patch(`/users/location/${user?._id}`, {
          position,
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (position?.latitude && user?._id) updateUserLoaction()
  }, [user?._id, location])
}

export default DefaultLocationSetter
