import { Button } from '@material-ui/core'
import React from 'react'
import WindowProps from '../../../../components/windowViewer/WindowProps'
import PayPalCheckoutForm from './PayPalCheckoutForm'
import ModelDialogWrappingIII from '../../../../components/dialogModelUnit/ModelDialogWrappingIII'
import i18next from 'i18next'

const BillingButton = (props) => {
  const { processing, adCampaign } = props
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const dialogsArray = [
    {
      open: isWindowOpen,
      content: <PayPalCheckoutForm windowCloser={windowCloser} {...props} />,
    },
  ]
  const lang = i18next.language
  const rtl = lang === 'ar'
  const title = rtl ? 'دفع' : 'Checkout'
  return (
    <>
      <Button
        variant='contained'
        color='primary'
        size='large'
        onClick={windowOpener}
        disabled={!processing && adCampaign?.targetUsers?.length === 0}
        style={{
          width: '100%',
          direction: document.body.dir,
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        }}
      >
        {title}
      </Button>
      <ModelDialogWrappingIII
        dialogsArray={dialogsArray}
        dialogWidth='500px'
        dialogHeight='auto'
      />
    </>
  )
}

export default BillingButton
