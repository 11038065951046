import axios from 'axios'
import { useEffect, useState } from 'react'

const OneUserCall = ({ userId }) => {
  const [user, setUser] = useState()
  const [isLoading, setIsLoading] = useState(userId)
  useEffect(() => {
    const fetchPaired = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/users/oneUser/${userId}`)
        setUser(res.data.user)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (userId) fetchPaired()
  }, [userId])
  return {
    user,
    isLoading,
    modUser: {
      ...user,
      name: user?.firstName + ' ' + user?.lastName,
      id: user?._id,
      image: user?.profilePicture,
    },
  }
}

export default OneUserCall
