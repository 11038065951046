import React from 'react'
import {
  TaskDisplayModels,
  RoomNotifDisplyModel,
  PromotionDisplyModel,
  ProductDisplayModels,
  InvestmentNotifDisplyModel,
  DevelopmentNotifDisplayModel,
  InquiryNotificationViewModel,
  JobNotificationDisplayModel,
  VisionNotificationViewModel,
  ProjectNotificationDisplayBody,
  CampaignDisplayModels,
  ContributionNotificationViewModel,
  GlobalNotificationViewModel,
  ResourceNotificationViewModel,
} from '../'
import ChannelNotificationViewModel from './ContributorsNotifsDialogRightComponents/ChannelNotificationViewModel'

const ContributorsNotifsDialogRight = ({ notifProps }) => {
  const { notification } = notifProps

  const viewModels = [
    {
      associativity: 'project',
      modelComp: <ProjectNotificationDisplayBody notifProps={notifProps} />,
    },
    {
      associativity: 'product',
      modelComp: <ProductDisplayModels notifProps={notifProps} />,
    },
    {
      associativity: 'campaign',
      modelComp: <CampaignDisplayModels notifProps={notifProps} />,
    },
    {
      associativity: 'task',
      modelComp: <TaskDisplayModels notifProps={notifProps} />,
    },
    {
      associativity: 'job',
      modelComp: <JobNotificationDisplayModel notifProps={notifProps} />,
    },
    {
      associativity: 'promotion',
      modelComp: <PromotionDisplyModel notifProps={notifProps} />,
    },
    {
      associativity: 'room',
      modelComp: <RoomNotifDisplyModel notifProps={notifProps} />,
    },
    {
      associativity: 'invepstment',
      modelComp: <InvestmentNotifDisplyModel notifProps={notifProps} />,
    },
    {
      associativity: 'development',
      modelComp: <DevelopmentNotifDisplayModel notifProps={notifProps} />,
    },
    {
      associativity: 'inquiry',
      modelComp: <InquiryNotificationViewModel notifProps={notifProps} />,
    },
    {
      associativity: 'idea',
      modelComp: <VisionNotificationViewModel notifProps={notifProps} />,
    },
    {
      associativity: 'global',
      modelComp: <GlobalNotificationViewModel notifProps={notifProps} />,
    },
    {
      associativity: 'resource',
      modelComp: <ResourceNotificationViewModel notifProps={notifProps} />,
    },
    {
      associativity: 'contribution',
      modelComp: <ContributionNotificationViewModel notifProps={notifProps} />,
    },
    {
      associativity: 'channel',
      modelComp: <ChannelNotificationViewModel notifProps={notifProps} />,
    },
  ]

  return viewModels.filter(
    (mod) => mod.associativity === notification.associativity
  )[0].modelComp
}

export default ContributorsNotifsDialogRight
