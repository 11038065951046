import React from 'react'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import NotificationViewModel from '../../../NotificationViewModel'
import { NotificationBodyWithAccessCard } from '../../..'
import { Link } from 'react-router-dom'
import i18next from 'i18next'

const ChannelMention = (props) => {
  const { sender } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  NotificationAutoInterUpdator(props)
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <section>
                قام
                <Link to={`/profile/${sender?._id}`}>{sender.name}</Link>
                بالاشارة اليك فى مناقشة جديدة فى قناة مشتركة
              </section>
            ) : (
              <section>
                <Link to={`/profile/${sender?._id}`}>{sender.name}</Link>
                has mentioned you in a discussion in a channel
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default ChannelMention
