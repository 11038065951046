import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const MyAlternative = () => {
  const { t } = useTranslation('MyAlternative')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 36,
    title: t('title_bm36'),
    subTitle: t('subTitle_bm36'),
    main: t('main_bm36'),
    when: t('when_bm36'),
    who: t('who_bm36'),
    what: t('what_bm36'),
    how: t('how_bm36'),
    why: t('why_bm36'),
    examples: rtl
      ? ['سامسونج - 1967 - كوريا الجنوبية', 'فورد - 1903 - الولايات المتحدة']
      : ['Samsung - 1967 - South Korea', 'Ford - 1903 - United States'],
    tags: ['Low Prices'],
    considerations: t('considerations_bm36'),
    combinations: ['$LBM31'],
    hashTagType: 'businessModelTemp',
  }
}

export default MyAlternative
