import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../../../../../context/AuthContext'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import FormsSystem from '../../../../../../formsSystem/FormsSystem'
import NewFormDialogModel from '../../../../../../formsSystem/NewFormDialogModel'
import MeetingAttendees from '../../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectInteractionsComponents/ProjectInteractionsDialogContentComps/MeetingsHistoryComps/MeetingAttendees'
import GridLoader from '../../../../../../smallComponents/GridLoader'
import RemovedItem from '../../../../../../smallComponents/RemovedItem'
import StateGenerator from '../../../../../../smallComponents/StateGenerator'
import NotificationCreator from '../../../../../NotificationCreator'
import NotificationViewModel from '../../../../NotificationViewModel'
import i18next from 'i18next'

const MemberNewMeetingNotif = (props) => {
  const { isDarkModeActive, notification, sender, project, socket } = props
  const [meeting, setMeeting] = useState({})
  const [isLoading, setIsLoading] = useState()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [users, setUsers] = useState([])
  const { user } = useContext(AuthContext)
  const [projects, setProjects] = useState([])
  useEffect(() => {
    const fetchMeeting = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/meetings/${notification?.accompaniedData?.elements[0]?.id}`
        )
        setMeeting(res.data.meeting)
        setUsers(res.data.users)
        setProjects(res.data.projects)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (notification?.accompaniedData?.elements[0]?.id) fetchMeeting()
  }, [notification?.accompaniedData?.elements[0]?.id])

  const meetingInfo = [
    {
      title: 'Title',
      titleValue: meeting?.Title,
    },
    {
      title: 'Meeting_Purpose',
      titleValue: meeting?.Content,
    },
    {
      title: 'Date',
      titleValue: meeting?.Date,
    },
    {
      title: 'Time',
      titleValue: meeting?.Time,
    },
    {
      title: 'Location',
      titleValue: meeting?.Location,
    },
  ]
  const [interactedWith, setInteractedWith] = useState(
    notification?.interactedWith
  )
  const actionFuns = async ({ response }) => {
    try {
      if (response === 'agreed' && !meeting?.users?.includes(user?._id)) {
        // this will add the current user to the attendee list
        await axios.patch(`/meetings/${meeting?._id}`, {
          users: [...meeting?.users, user?._id],
        })
      }
      NotificationCreator({
        associativity: 'project',
        relation: 'ceo',
        conditionIndex: 1,
        accompaniedData: {
          response,
          elements: [
            {
              hashTagType: meeting?.hashTagType,
              id: meeting?._id,
              title: meeting?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: sender?._id,
        currentUser: user,
        socket,
      })
      setInteractedWith(true)
      await axios.patch(`/notifications/${notification?._id}`, {
        interactedWith: true,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const [formData, setFormData] = useState('modification content')
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({
      GeneralProps: true,
    })
  const sendModification = async () => {
    NotificationCreator({
      associativity: 'project',
      relation: 'ceo',
      conditionIndex: 1,
      accompaniedData: {
        response: 'modify',
        modification: formData?.Modification,
        elements: [
          {
            hashTagType: meeting?.hashTagType,
            id: meeting?._id,
            title: meeting?.Title,
          },
        ],
      },
      category: 'contributors',
      userId: sender?._id,
      currentUser: user,
      socket,
    })
    setInteractedWith(true)
    await axios.patch(`/notifications/${notification?._id}`, {
      interactedWith: true,
    })
  }

  const buttonsArray = [
    {
      title: rtl ? 'التحق بالاجتماع' : 'Join Meeting',
      funs: async () => {
        actionFuns({
          response: 'agreed',
        })
        // this will also add the current user to the attendees list
      },
      descProps: {},
      disabled: interactedWith,
    },
    {
      title: rtl ? 'الحضور' : 'Attendees',
      // this will show the attnedees of the meeting
      funs: GeneralDialogOpener,
      descProps: {},
    },
    {
      title: rtl ? 'رفض' : 'Refuse',
      funs: async () =>
        actionFuns({
          response: 'refused',
        }),

      descProps: {},
      disabled: interactedWith,
    },
    {
      title: rtl ? 'تعديل' : 'Modify',
      funs: sendModification,
      disabled:
        !formData?.Modification ||
        interactedWith ||
        !notification?.accompaniedData?.allowModifications,
    },
  ]

  const gridProps = {
    headerText: rtl ? 'تفاصيل الإجتماع' : 'Meeting Details',
    hideEditingTools: true,
    infoArray: meetingInfo,
    isDarkModeActive,
    project,
    socket,
  }

  return (
    <>
      <NotificationViewModel
        {...props}
        learningSectionContent={''}
        notificationBody={
          <section style={{ width: '100%' }}>
            <Link
              to={`/profile/${sender?._id}`}
              style={{ fontWeight: 500, marginRight: 4, marginLeft: 4 }}
            >
              {sender?.name}
            </Link>{' '}
            {rtl ? 'أعد اجتماعا جديدا فى' : 'set a new meeting in'}{' '}
            <Link
              to={`/projectProfile/${project?._id}`}
              style={{ fontWeight: 500, marginRight: 4, marginLeft: 4 }}
            >
              {project?.name}
            </Link>
            {isLoading ? (
              <GridLoader />
            ) : meeting ? (
              <EditableGridModel gridProps={gridProps} />
            ) : (
              <RemovedItem
                removedMssg={
                  rtl
                    ? 'تم الغاء أو إزالة الأجتماع'
                    : 'Meeting has been deleted or Canceled'
                }
              />
            )}
            {meeting && (
              <>
                <br />
                <br />
                <span
                  style={{
                    display:
                      (!notification?.accompaniedData?.allowModifications ||
                        interactedWith) &&
                      'none',
                    color: isDarkModeActive ? 'white' : 'black',
                  }}
                >
                  <FormsSystem
                    formComponentsArray={[
                      {
                        title: 'Modification',
                        formComponentType: 'text',
                        value: '',
                        placeholder: rtl
                          ? 'ما الذى تريد تغييره؟'
                          : 'What do you want to change ?',
                        multiLine: true,
                        minRows: 4,
                      },
                    ]}
                    formComponentsValues={formData}
                    setFormComponentsValues={setFormData}
                    {...props}
                  />
                </span>
              </>
            )}
          </section>
        }
        buttonsArray={buttonsArray}
      />
      <NewFormDialogModel
        NewFormComponent={
          <MeetingAttendees
            GeneralDialogClose={GeneralDialogClose}
            {...props}
            meeting={meeting}
            users={users}
            projects={projects}
          />
        }
        newFormDialogClose={GeneralDialogClose}
        isNewFormDialogOpen={isGeneralDialogOpen}
        {...props}
        clickAwayState={true}
      />
    </>
  )
}

export default MemberNewMeetingNotif
