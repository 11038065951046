import React from 'react'
import NotificationCoverModel from '../../../NotificationCoverModel'
import i18next from 'i18next'

const PostSharingNotifCover = ({ notification, sender, post }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const differentSharers = post?.shares?.filter(
    (id) => id !== sender?._id
  )?.length
  const content =
    differentSharers > 1
      ? rtl
        ? `قام ${sender?.name} و ${differentSharers} اخرون بمشاركة المنشور الخاص بك`
        : `${sender?.name} and ${differentSharers} others Shared your post.`
      : rtl
      ? `قام ${sender?.name} بمشاركة المنشور الخاص بك"`
      : `${sender?.name} Shared your post.`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default PostSharingNotifCover
