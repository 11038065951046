import React from 'react'
import withStyles from '@mui/styles/withStyles'
import TextEngine from '../../../../../../../../textProcessingEngine/TextEngine'
import TextDirectionDetector from '../../../../../../../../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const styles = () => ({
  title: {
    borderRadius: '50%',
    fontWeight: 500,
    fontSize: 20,
    height: 70,
    width: 70,
    position: 'absolute',
    top: 5,
    background:
      'linear-gradient(75deg,rgb(98, 98, 139) 25%,rgb(51, 176, 211) 96%)',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  definition: {
    fontSize: 17,
    background:
      'linear-gradient(75deg,rgb(98, 98, 13) 25%,rgb(51, 176, 211) 96%)',
    color: 'white',
    borderRadius: '10px 0',
    fontWeight: 500,
  },

  modelDervied: {
    fontSize: 14,
    padding: 10,
    marginBottom: 10,
    marginTop: 10,
    borderRadius: 10,
    background: 'rgba(217,224,252, 0.1)',
    fontStyle: 'oblique',
  },
})

const ModelStructureUnitTopPC = ({ model, classes, props, DCGProps }) => {
  const modelDerived = (
    <TextEngine
      textContent={model?.modelDervied || ''}
      {...DCGProps}
      notEngineProps={props}
    />
  )
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <div
        className={classes.title}
        style={{ left: !rtl && 30, right: rtl && 30 }}
      >
        {t(model.title)}
      </div>
      <div
        className={classes.definition}
        style={{
          padding: rtl ? '10px 110px 10px 10px' : '10px 10px 10px 110px',
        }}
      >
        {model.definition}
        <section
          className={classes.modelDervied}
          style={{ display: model.customModel ? 'none' : null }}
          dir={TextDirectionDetector(model?.modelDervied)}
        >
          {modelDerived}
        </section>
      </div>
    </>
  )
}

const WrapppedModelStructureUnitTopPC = withStyles(styles)(
  ModelStructureUnitTopPC
)

export default WrapppedModelStructureUnitTopPC
