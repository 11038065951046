import React from 'react'
import { NotificationViewModel, NotificationBodyWithAccessCard } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import { Link } from 'react-router-dom'
import i18next from 'i18next'

const TaskMarkedDoneReply = (props) => {
  const { notification, sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const response = notification?.accompaniedData?.response
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <>
                مسؤول المهمة الموكًلة اليك
                <Link
                  to={`/profile/${sender?._id}`}
                  style={{ margin: 'auto 4px' }}
                >
                  {sender?.name}
                </Link>
                {response === 'Granted'
                  ? 'صدّق على اكتمال مهمتك'
                  : 'يظن ان المهمة الموكلة لا تزال تحتاج الى مزيد من العمل'}
              </>
            ) : (
              <>
                Your task moderator
                <Link
                  to={`/profile/${sender?._id}`}
                  style={{ margin: 'auto 4px' }}
                >
                  {sender?.name}
                </Link>
                {response === 'Granted'
                  ? 'granted your task completion'
                  : 'thinks that the task still needs working'}
              </>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default TaskMarkedDoneReply
