import React from 'react'
import withStyles from '@mui/styles/withStyles'
import EditingSystem from '../../../../../../../../../../../../editingSystem/EditingSystem'
import PostProps from '../../../../../../../../../../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import PopoverMenu from '../../../../../../../../../../../../popoverMenu/PopoverMenu'

const styles = () => ({
  postTopRight: {
    display: 'flex',
    cursor: 'pointer',
    padding: 7,
  },
})

const ChannelProfileFeedSMDisTopRight = ({ classes, postProps }) => {
  const { hideRightOptions } = postProps
  const { postEditDialogOpener, postEditDialogClose, isPostEditDialogOpen } =
    PostProps(
      {},
      {
        editingSystemProps: true,
      }
    )
  const optionsArray = [
    {
      type: 'edit',
      funs: () => postEditDialogOpener(),
    },
  ]
  return (
    <div
      className={classes.postTopRight}
      style={{ display: hideRightOptions ? 'none' : 'flex' }}
    >
      <PopoverMenu optionsArray={optionsArray} />
      <EditingSystem
        {...postProps}
        disabledPostTypesDefaultValue={0}
        shareTypeIndex={4}
        isPostEditDialogOpen={isPostEditDialogOpen}
        postEditDialogClose={postEditDialogClose}
      />
    </div>
  )
}

const WrappedChannelProfileFeedSMDisTopRight = withStyles(styles)(
  ChannelProfileFeedSMDisTopRight
)

export default WrappedChannelProfileFeedSMDisTopRight
