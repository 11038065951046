import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const NewChannelAdmissionRequestReplyCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl
          ? `تم الرد على طلب الإضافة الخاص بك إلى قناة`
          : 'Your addmission request to a channel has been replied to'
      }
    />
  )
}

export default NewChannelAdmissionRequestReplyCover
