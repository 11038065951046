import React from 'react'
import NotificationViewModel from '../../../../NotificationViewModel'
import { Link } from 'react-router-dom'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const ProjectFollowNotif = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <section>
          <Link to={`/profile/${sender?._id}`}>{sender.name}</Link>{' '}
          <span>{rtl ? 'قام بمتابعة مشروعك' : 'Followed your project'}</span>
        </section>
      }
      buttonsArray={[]}
    />
  )
}

export default ProjectFollowNotif
