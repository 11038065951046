import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ProjectPostLikeCover = ({ notification, sender, project, post }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content =
    post?.likes?.length > 1
      ? rtl
        ? `أُعجب ${sender?.name} و ${post?.likes?.length - 1} أخرون منشور فى ${
            project?.name
          }`
        : `${sender?.name} and ${
            post?.likes?.length - 1
          } others liked a post in ${project?.name}.`
      : rtl
      ? `أعجب ${sender?.name} منشور فى ${project?.name} الخاص بك`
      : `${sender?.name} liked a post in ${project?.name}`

  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default ProjectPostLikeCover
