import React from 'react'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import { Link } from 'react-router-dom'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const JobNewRequest = (props) => {
  const { job, project } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <section>
                الوظيفة: <span style={style}>{job.title}</span> فى
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
                استقبلت طلبات تقديم, يمكنك مراجعتها
              </section>
            ) : (
              <section>
                Your Job offer<span style={style}>{job.title}</span> in
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
                has recieved new requests
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default JobNewRequest
