import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  shadowDegree: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    opacity: 0.5,
    background:
      'linear-gradient(75deg,rgb(70, 70, 82) 25%,rgb(164, 185, 190) 96%)',
    borderRadius: '40% 40% 0 0',
  },
  '@media (max-width: 600px)': {},
})

const ShadowGradient = ({
  classes,
  setIsRegionClicked,
  setTextRegionHeight,
  isRegionClicked,
  alwaysExpandedCondition,
  textContent,
}) => {
  let shadowNumer = textContent
  const shadows = shadowNumer
    ?.split('')
    .filter((_, index) => index < shadowNumer.length / 20 && index < 60)
  return (
    <section
      className={classes.shadowDegree}
      style={{
        display: isRegionClicked || alwaysExpandedCondition ? 'none' : 'block',
        height: shadows.length,
      }}
      onClick={() => {
        setIsRegionClicked(true)
        setTextRegionHeight('auto')
      }}
    ></section>
  )
}

const WrappedShadowGradient = withStyles(styles)(ShadowGradient)

export default WrappedShadowGradient
