import React from 'react'
import { Card } from '@mui/material'
import withStyles from '@mui/styles/withStyles'

// scroll bar styling does not work

const styles = () => ({
  suggestCard: {
    maxHeight: 300,
    overflowY: 'auto !important',
    borderRadius: '10px !important',
    boxShadow: '0px 16px 70px -12.125px rgba(0, 0, 0, 0.3) !important',
  },
  '@media (max-width: 600px)': {
    suggestCard: {
      maxHeight: 300,
      overflowY: 'auto !important',
      '::-webkit-scrollbar': {
        height: '7px !important',
        width: '7px !important',
        backgroundColor: 'rgb(217, 217, 233) !important',
        borderRadius: ' 10px !important',
      },
      '::-webkit-scrollbar-thumb': {
        height: '7px !important',
        borderRadius: ' 10px !important',
        boxShadow: 'inset 0 0 6px rgb(80, 77, 77) !important',
        width: '5px !important',
      },
    },
  },
})

const SuggListCard = ({ classes, cardContent, isDarkModeActive }) => {
  return (
    <Card
      className={classes.suggestCard}
      sx={{
        backgroundColor: isDarkModeActive ? 'rgb(71, 69, 76)' : 'white',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      {cardContent}
    </Card>
  )
}

const WrappedSuggListCard = withStyles(styles)(SuggListCard)

export default WrappedSuggListCard
