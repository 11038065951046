import { Avatar, ListItemText } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const styles = () => ({
  select: {
    fontSize: '12px !important',
    appearance: 'none !important',
    outline: 'none !important',
    width: '150px !important',
  },
  menuContractorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
  },
  contractorName: {
    fontSize: 12,
  },
})

const PairingMembersUnit = ({ classes, element }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  return element === 'Not_Paired_Yet' ? (
    t('Not_Paired_Yet')
  ) : (
    <ListItemText
      primary={
        <span className={classes.menuContractorWrapper}>
          <Avatar
            src={element?.profilePicture}
            style={{ height: 30, width: 30 }}
          />
          <span
            className={classes.contractorName}
            style={{ marginLeft: !rtl && 5, marginRight: rtl && 5 }}
          >
            {element?.firstName + ' ' + element?.lastName}
          </span>
        </span>
      }
    />
  )
}

const WrappedPairingMembersUnit = withStyles(styles)(PairingMembersUnit)

export default WrappedPairingMembersUnit
