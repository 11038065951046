import i18next from "i18next"

const NewJobFormConfigs = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const departments = [
    'Production',
    'Marketing',
    'Adminstration',
    'R&D',
    'Financial',
    'Legal',
  ]
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'عنوان الوظيفة' : 'Name Your Job',
    },
    {
      title: 'Description',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      placeholder: rtl ? 'صٍف الوظيفة' : 'Describe Your Job',
      minRows: 4,
    },
    {
      title: 'Skills',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      placeholder: rtl ? 'المهارات المطلوبة' : 'Required Skills',
      minRows: 4,
    },
    {
      title: 'Job_Department',
      formComponentType: 'select',
      value: departments[0],
      menuOptions: departments,
    },
  ]
}

export default NewJobFormConfigs
