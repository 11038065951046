import i18next from 'i18next'
import React from 'react'

const Loading = ({ isDarkModeActive }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignContent: 'center',
        alignItems: 'center',
        color: isDarkModeActive && 'white',
      }}
    >
      <span
        style={{
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
          fontWeight: 500,
        }}
      >
        {rtl ? 'جارى التحميل...' : 'Loading...'}
      </span>
    </div>
  )
}

export default Loading
