import React from 'react'
import MultiLangNumber from '../../../../../../smallComponents/MultiLangNumber'
import ToolbarPanel from '../../../../../../toolbarSystem/ToolbarPanel'

const IssueDetails = ({ issue, DCGProps }) => {
  const toolbarConfig = [
    {
      type: 'usersIcon',
      onOpen: '',
      count: String(MultiLangNumber(issue?.usersIds?.length)),
    },
    {
      type: 'projectsIcon',
      onOpen: '',
      count: String(MultiLangNumber(issue?.projectsIds?.length)),
    },
    // { type: 'contractIcon', onOpen: '', count: '' },
    {
      type: 'upVoteIcon',
      onOpen: '',
      count: String(MultiLangNumber(issue?.upvotes?.length)),
    },
    {
      type: 'downVoteIcon',
      onOpen: '',
      count: String(MultiLangNumber(issue?.downvotes?.length)),
    },
  ]
  return <ToolbarPanel config={toolbarConfig} />
}

export default IssueDetails
