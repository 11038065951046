import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  dotWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  dot: {
    borderRadius: '50%',
    height: 7,
    width: 7,
    margin: '2px 5px',
    border: '1px solid black',
  },
})

const NotificationStatusDots = ({ classes, dots }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const Dot = ({ dot }) => (
    <div className={classes.dot} style={{ backgroundColor: dot.color }}></div>
  )
  return (
    <section
      className={classes.dotWrapper}
      style={{ marginRight: !rtl && 10, marginLeft: rtl && 10 }}
    >
      {dots.map((dot, i) => (
        <Dot dot={dot} key={i} />
      ))}
    </section>
  )
}

const WrappedNotificationStatusDots = withStyles(styles)(NotificationStatusDots)

export default WrappedNotificationStatusDots
