import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationViewModel } from '../../../..'
import DepartmentsEnumArray from '../../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/DepartmentsEnumArray'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const MemberInterDepTransfer = (props) => {
  const { notification, project } = props
  NotificationAutoInterUpdator(props)
  const department = DepartmentsEnumArray.find(
    (dep) => dep.id === notification?.accompaniedData?.departmentId
  )?.title
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <section style={{ width: '100%' }}>
          {rtl
            ? `لقد تم نقلك الى قسم ${t(department)} فى ${project?.name}`
            : `You have been transfered to ${department} Department in ${project?.name}`}
        </section>
      }
      buttonsArray={[]}
    />
  )
}

export default MemberInterDepTransfer
