import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const RazorAndBladeBusinessModel = () => {
  const { t } = useTranslation('RazorAndBladeBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 44,
    title: t('title_bm44'),
    subTitle: t('subTitle_bm44'),
    main: t('main_bm44'),
    when: t('when_bm44'),
    who: t('who_bm44'),
    what: t('what_bm44'),
    how: t('how_bm44'),
    why: t('why_bm44'),
    examples: rtl
      ? [
          'جيليت - 1904 - الولايات المتحدة الأمريكية',
          'Hewlett-Packard - 1939 - الولايات المتحدة الأمريكية',
          'نستله - 1866 - سويسرا',
        ]
      : [
          'Gillette - 1904 - USA',
          'Hewlett-Packard - 1939 - USA',
          'Nestlé - 1866 - Switzerland',
        ],
    tags: rtl
      ? ['سعر منخفض', 'ولاء العملاء', 'إمكانية الوصول إلى المنتج']
      : ['Low Price', 'Customer Loyalty', 'Product Accessibility'],
    considerations: t('considerations_bm44'),
    combinations: ['$LBM31'],
    hashTagType: 'businessModelTemp',
  }
}

export default RazorAndBladeBusinessModel
