import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CentralTitle from '../../../../../../smallComponents/CentralTitle'
import TimeAgoFormattor from '../../../../../../smallComponents/TimeAgoFormattor'
import withStyles from '@mui/styles/withStyles'
import FreeBar from '../../../../../../skeleton/SkeletonComponents/FreeBar'

const styles = () => ({
  creationDate: {
    fontSize: 11,
    fontWeight: 500,
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  elementUser: {
    fontSize: 12,
    fontWeight: 500,
  },
})

const IssueTop = ({ issue, classes }) => {
  const [issuer, setIssuer] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchIssuer = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/users/oneUser/${issue?.userId}`)
        setIssuer(res.data.user)
      } catch (error) {}
      setIsLoading(false)
    }
    const ftechProjectUser = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/projects/oneProject/${issue?.userId}`)
        setIssuer(res.data.project)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (issue?.userId) {
      fetchIssuer()
    } else if (issue?.projectId) {
      ftechProjectUser()
    }
  }, [issue?.projectId, issue?.userId])
  return (
    <div className={classes.wrapper}>
      <CentralTitle title={issue?.Title} />
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <span className={classes.elementUser}>
          {isLoading ? (
            <FreeBar height={'20px'} width={'150px'} margin={'5px 0 0 0'} />
          ) : (
            issuer?.firstName + ' ' + issuer?.lastName
          )}
        </span>
        <span className={classes.creationDate}>
          <TimeAgoFormattor date={issue?.createdAt} />
        </span>
      </section>
    </div>
  )
}

const WrappedIssueTop = withStyles(styles)(IssueTop)

export default WrappedIssueTop
