import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const SubDepDeletionNotifCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl
          ? `لقد تم إغلاق القسم الفرعى الخاص بك`
          : 'A sub-department has been closed'
      }
    />
  )
}

export default SubDepDeletionNotifCover
