import React from 'react'
import PermissionRequest from './VisionNotificationViewModelComps/PermissionRequest.jsx'
import PermissionRequestReply from './VisionNotificationViewModelComps/PermissionRequestReply'
import VisionOffer from './VisionNotificationViewModelComps/VisionOffer'
import VisionOfferReply from './VisionNotificationViewModelComps/VisionOfferReply'
import IdeaRemoveContributorNotif from './VisionNotificationViewModelComps/IdeaRemoveContributorNotif'

const VisionNotificationViewModel = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const idea = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'idea'
  )
  const notifPropsII = {
    ...notifProps,
    idea,
  }
  const mssgs = [
    {
      relation: 'visionOwner',
      comp: [
        <VisionOffer {...notifPropsII} />,
        <PermissionRequest {...notifPropsII} />,
      ],
    },
    {
      relation: 'visionReviewer',
      comp: [
        <VisionOfferReply {...notifPropsII} />,
        <PermissionRequestReply {...notifPropsII} />,
        <IdeaRemoveContributorNotif {...notifPropsII} />,
      ],
    },
  ]
  return (
    <article
      style={{ color: isDarkModeActive ? 'white' : null, width: '100%' }}
    >
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default VisionNotificationViewModel
