import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  warapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-around',
    width: '100%',
    alignItems: 'center',
  },
})

const RemovedItem = ({ removedMssg, classes }) => {
  return (
    <section className={classes.warapper}>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
        }}
      >
        <img
          src='/assets/various/brokenEgg.png'
          alt='removed item'
          style={{ width: '45%', alignSelf: 'center', display: 'flex' }}
        />
        <span style={{  alignSelf: 'center', display: 'flex' }}>
          {removedMssg}
        </span>
      </section>
    </section>
  )
}

const WrappedRemovedItem = withStyles(styles)(RemovedItem)

export default WrappedRemovedItem
