import React, { useContext } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import UserProfileFollowUnfollow from '../../../profilesMicroComponents/UserProfileFollowUnfollow'
import ProjectFollowButton from '../../../projectProfile/ProjectProfileComponents/ProjectIntroComponents/ProjectCommBarComponents/ProjectFollowButton'

const PTLeftFollow = ({ postProps, user }) => {
  const { post, socket, project } = postProps
  const { user: currentUser } = useContext(AuthContext)
  const isPostUserNotFollowed =
    (user?.lastName && currentUser?.followings?.includes(post?.userId)) ||
    currentUser?.followedProjects?.includes(user?._id) ||
    currentUser?.followedProjects?.includes(project?._id)
  return (
    <section style={{ marginBottom: '5px' }}>
      {!isPostUserNotFollowed &&
        post?.userId !== currentUser?._id &&
        (user?.lastName ? (
          <UserProfileFollowUnfollow socket={socket} userId={post?.userId} />
        ) : (
          <ProjectFollowButton
            projectProps={{
              project,
              projectAdmins: [{ _id: project?.userId }],
              socket,
            }}
          />
        ))}
    </section>
  )
}

export default PTLeftFollow
