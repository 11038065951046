import React from 'react'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import CardTitlesModel from '../../../../../../../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCardComps/CardTitlesModel'
import i18next from 'i18next'

const ResearchApplicationContent = ({ opener, element, isDarkModeActive }) => {
  const application = element
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <CardTitlesModel
      title={application?.Title}
      isDarkModeActive={isDarkModeActive}
      cardFooter={
        <section style={{ width: '100%' }}>
          <ButtonsSystem
            buttonsArray={[
              {
                title: rtl ? 'عرض' : 'View',
                funs: () => opener(),
              },
            ]}
          />
        </section>
      }
    />
  )
}

export default ResearchApplicationContent
