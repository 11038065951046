import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { MdArrowLeft } from 'react-icons/md'
import i18next from 'i18next'

const styles = () => ({
  image: {
    width: '100%',
    borderRadius: 10,
    cursor: 'pointer',
    height: 'auto',
    borderRadius: 0,
    objectFit: 'contain',
  },
  galleryWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    overflowX: 'auto',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  backIcon: {
    fontSize: 20,
  },

  iconButton: {
    height: '30px !important',
    borderRadius: '50% !important',
    cursor: 'pointer !important',
    fontSize: '20 !important',
    cursor: 'pointer !important',
    position: 'absolute !important',
    top: '20px !important ',
    left: '20px !important',
    zIndex: '2 !important',
  },
})

const ImageViewerMobView = ({
  classes,
  imagesArray,
  imageViewerDialogClose,
  isImageViewerDialogOpen,
  isDarkModeActive,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const iamgesArrayII = typeof imagesArray === 'object' ? imagesArray : []
  return (
    isImageViewerDialogOpen && (
      <Dialog
        disableScrollLock
        fullScreen={true}
        open={Boolean(isImageViewerDialogOpen)}
        PaperProps={{
          style: {
            transition: '0.3s',
            background: isDarkModeActive ? 'rgb(49, 48, 53)' : 'white',
          },
        }}
      >
        <DialogContent
          className={classes.imageWrapper}
          sx={{ color: isDarkModeActive ? 'white' : 'inherit' }}
        >
          <div style={{ margin: 'auto -24px auto -24px' }}>
            <IconButton
              edge='start'
              color='inherit'
              className={classes.iconButton}
              onClick={() => imageViewerDialogClose()}
            >
              <MdArrowLeft className={classes.backIcon} />
            </IconButton>
            <div className={classes.galleryWrapper}>
              {iamgesArrayII?.map((image, imageIndex) => (
                <img
                  key={imageIndex}
                  src={image}
                  alt='Images Viewer System'
                  className={classes.image}
                  style={{
                    // the old in case it did not work
                    // marginRight: imageIndex === iamgesArrayII.length - 1 ? 0 : 4,
                    marginRight:
                      !rtl && imageIndex === iamgesArrayII.length - 1 ? 0 : 4,
                    marginLeft:
                      rtl && imageIndex === iamgesArrayII.length - 1 ? 0 : 4,
                  }}
                />
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  )
}

const WrappedImageViewerMobView = withStyles(styles)(ImageViewerMobView)

export default WrappedImageViewerMobView
