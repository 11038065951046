import React, { useContext, useEffect, useState } from 'react'
import JobRequests from './JobProfileComponents/JobRequests'
import JobDetails from './JobProfileComponents/JobDetails'
import TextEngineToolsFooter from '../../../../../smallComponents/TextEngineToolsFooter'
import JobInteractionButtons from './JobProfileComponents/JobInteractionButtons'
import axios from 'axios'
import { AuthContext } from '../../../../../../context/AuthContext'

const JobProfile = (props) => {
  const { DCGProps, depManager, job } = props
  const { project } = DCGProps
  const { user } = useContext(AuthContext)
  const [managerProfile, setManagerProfile] = useState(null)
  useEffect(() => {
    const fetchDepManager = async () => {
      try {
        const res = await axios.get(
          `/projectContributors/depManager/${job?.projectId}/2`
        )
        setManagerProfile(res.data.managerProfile)
      } catch (error) {
        console.log(error)
      }
    }
    fetchDepManager()
  }, [job?.projectId])

  const propsII = {
    ...props,
    // when i open the profile outside the project
    depManager: depManager ? depManager : managerProfile,
  }
  const authorised =
    user?._id === project?.userId ||
    user?._id === depManager?._id ||
    user?.createdProjects?.includes(job?.projectId) ||
    user?._id === managerProfile?._id
  return (
    <article
      style={{
        width: '100%',
        color: DCGProps?.isDarkModeActive ? 'white' : null,
      }}
    >
      <JobDetails {...propsII} />
      {authorised && <JobRequests {...propsII} />}
      <JobInteractionButtons {...propsII} />
      {authorised && <TextEngineToolsFooter {...DCGProps} />}
    </article>
  )
}

export default JobProfile
