import React from 'react'
import Skeleton from '../../../../../../../../../../skeleton/Skeleton'
import ChannelPostModel from './ChannelPostModel'
import RoomSMShiftButtons from '../../../../../../../ProjectDepartmentsComponents/RoomsComponents/RoomStructureModelComponents/RoomSMFeedComponents/RoomSMShiftButtons'

const ChannelSMFeed = (props) => {
  const { isLoading, posts } = props
  return (
    <>
      {isLoading ? (
        <Skeleton type={'feed'} {...props} />
      ) : (
        posts.map((post) => (
          <ChannelPostModel post={post} key={post?._id} props={props} />
        ))
      )}
      <RoomSMShiftButtons {...props} />
    </>
  )
}

export default ChannelSMFeed
