import axios from 'axios'
import React, { useContext } from 'react'
import { AuthContext } from '../../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import NotificationCreator from '../../../../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const IdeaRequestDetails = ({
  card,
  DCGProps,
  exprops,
  setRequests,
  requestsUsers,
}) => {
  const { idea } = exprops
  const { socket } = DCGProps
  const request = card
  const { user } = useContext(AuthContext)
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)
  const activeFun = async ({ response }) => {
    try {
      setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
      if (response === 'accept') {
        // this will add the user to the default channel
        const defaultChRes = await axios.patch(
          `/channels/channelsSystem/defaultChannel/${idea?._id || idea?.id}`,
          {
            contributorId: request?.userId,
          }
        )
        socket.current.emit('addNotifUser', request?.userId)
        NotificationCreator({
          associativity: 'idea',
          relation: 'visionReviewer',
          conditionIndex: 0,
          accompaniedData: {
            response,
            elements: [
              {
                hashTagType: idea?.hashTagType,
                id: idea?._id || idea?.id,
                title: idea?.Title || idea?.title,
              },
              {
                hashTagType: defaultChRes?.data?.channel?.hashTagType,
                id: defaultChRes?.data?.channel?._id,
                title: defaultChRes?.data?.channel?.Title,
              },
            ],
          },
          category: 'contributors',
          userId: request?.userId,
          currentUser: user,
          socket,
        })
        // will add the user to the contributors of the idea if the response is accept
        await axios.patch(`/ideas/addContributor/${idea?._id || idea?.id}`, {
          contributorId: request?.userId,
        })
      } else if (response === 'refuse') {
        socket.current.emit('addNotifUser', request?.userId)
        NotificationCreator({
          associativity: 'idea',
          relation: 'visionReviewer',
          conditionIndex: 0,
          accompaniedData: {
            response,
            elements: [
              {
                hashTagType: idea?.hashTagType,
                id: idea?._id || idea?.id,
                title: idea?.Title || idea?.title,
              },
            ],
          },
          category: 'contributors',
          userId: request?.userId,
          currentUser: user,
          socket,
        })
      }
      // request will be deleted after being responded to
      await axios.delete(`/requests/${request?._id}`)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteFun = async () => {
    try {
      await axios.delete(`/requests/${request?._id}`)
      setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
    } catch (error) {
      console.log(error)
    }
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray =
    request?.requestStatus === 'responded'
      ? [
          {
            title: rtl ? 'حذف' : 'Delete',
            funs: () => {
              deleteFun()
              toast.warning(
                rtl
                  ? 'تم مسح الطلب بنجاح'
                  : 'Request has been deleted successfully'
              )
            },
          },
        ]
      : request?.subgenre === 'contribute'
      ? [
          {
            title: rtl ? 'قبول' : 'Accept',
            funs: () => {
              activeFun({ response: 'accept' })
              toast.success(
                rtl
                  ? `تم قبول ${requestingUser?.firstName} و إضافته الى القناة الافتراضية و قائمة المشاركين الخاصة بالفكرة`
                  : `${requestingUser?.firstName} has been accepted and added to the default channel and contributors list of the idea`
              )
            },
          },
          {
            title: rtl ? 'رفض' : 'Refuse',
            funs: () => {
              activeFun({ response: 'refuse' })
              toast.info(rtl ? 'تم ارسال الرفض' : 'Refusal has been sent')
            },
          },
          {
            title: rtl ? 'حذف' : 'Delete',
            funs: () => {
              deleteFun()
              toast.warning(
                rtl
                  ? 'تم مسح الطلب بنجاح'
                  : 'Request has been deleted successfully'
              )
            },
          },
        ]
      : [
          {
            title: rtl ? 'حذف' : 'Delete',
            funs: () => {
              deleteFun()
              toast.warning(
                rtl
                  ? 'تم مسح الطلب بنجاح'
                  : 'Request has been deleted successfully'
              )
            },
          },
        ]
  return (
    <>
      <section>
        {rtl ? (
          <>
            يسأل {requestingUser?.firstName + ' ' + requestingUser?.lastName} عن
            {request?.subgenre === 'contribute'
              ? ' المشاركة فى فكرتك'
              : ' معلومات أكثر عن فكرتك'}
          </>
        ) : (
          <>
            {requestingUser?.firstName + ' ' + requestingUser?.lastName} Asks
            for
            {request?.subgenre === 'contribute'
              ? ' contribution to your idea'
              : ' more Declaration about the idea, you may chat with him!'}
          </>
        )}
      </section>
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </>
  )
}

export default IdeaRequestDetails
