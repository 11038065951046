import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import { Link } from 'react-router-dom'
import TextPost from '../../../postSystem/TextPost'
import { Card } from '@mui/material'
import DefaultImageGrapper from '../../../smallComponents/DefaultImageGrapper'
import i18next from 'i18next'

const styles = () => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
  },
  cardWrapper: {
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
    margin: '6px !important',
    height: 200,
  },
  cardImage: {
    objectFit: 'cover',
    opacity: 0.4,
    width: '100%',
  },
  textWrapper: {
    zIndex: 1,
    position: 'absolute',
    fontSize: 27,
    backdropFilter: 'blur(5px)',
    margin: 10,
  },
  '@media (max-width: 600px)': {
    textWrapper: {
      fontSize: 20,
    },
  },
})

const NewProjectPostModel = ({ postBodyProps, classes }) => {
  const { post, isDarkModeActive } = postBodyProps
  const [project, setProject] = useState()
  useEffect(() => {
    const fetchNewProject = async () => {
      try {
        const res = await axios.get(`/projects/${post?.projectId}`)
        setProject({
          name: res.data.project.ProjectName,
          image: res.data.project.projectCover,
          userId: res.data.project.userId,
          _id: res.data.project._id,
        })
      } catch (error) {
        console.log(error)
      }
    }

    if (post?.projectId) fetchNewProject()
  }, [post?.projectId])
  const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article className={classes.container}>
      <TextPost {...postBodyProps} margins={'auto 15px'} />
      <Card
        className={classes.cardWrapper}
        sx={{
          backgroundColor: isDarkModeActive
            ? 'rgba(255, 255, 255, 0.192)'
            : 'inherit',
          color: isDarkModeActive ? 'white' : 'inherit',
        }}
      >
        <img
          src={project?.image || DefaultImageGrapper('noCover')}
          className={classes.cardImage}
          alt='Overview Image'
        />
        <span className={classes.textWrapper}>
          {rtl ? (
            <>
              تم انشاء مشروع جديد{' '}
              <Link
                to={`/projectProfile/${project?._id}`}
                style={{
                  color: isDarkModeActive ? 'white' : 'black',
                  cursor: 'pointer',
                }}
              >
                <strong>{project?.name}</strong>
              </Link>{' '}
            </>
          ) : (
            <>
              New Project{' '}
              <Link
                to={`/projectProfile/${project?._id}`}
                style={{
                  color: isDarkModeActive ? 'white' : 'black',
                  cursor: 'pointer',
                }}
              >
                <strong>{project?.name}</strong>
              </Link>{' '}
              is craeted
            </>
          )}
        </span>
      </Card>
    </article>
  )
}

const WrappedNewProjectPostModel = withStyles(styles)(NewProjectPostModel)

export default WrappedNewProjectPostModel
