import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NavigatorGenerator from '../../../../../../navigatorCardsModel/NavigatorGenerator'
import MemberAssignedTasksConfigs from '../../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/MemberProfileComponents/MemberActivityComponents/MemberAssignedTasksConfigs'
import CustomCircularProgress from '../../../../../../smallComponents/CustomCircularProgress'
import CustomEmpty from '../../../../../../smallComponents/CustomEmpty'
import TitleHeader from '../../../../../../smallComponents/Header'
import ModeratedTasks from './TasksCalenderDialogContentComps/ModeratedTasks'
import i18next from 'i18next'

const TasksCalenderDialogContent = ({
  isDarkModeActive,
  socket,
  chosenMonth,
  user,
}) => {
  const [tasks, setTasks] = useState([])
  const [projects, setProjects] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const year = chosenMonth?.chosenYear
  const month = chosenMonth?.monthIndex
  const lang = i18next.language
  const rtl = lang === 'ar'
  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/tasks/tasksCalender/${user?._id}/?month=${month}&year=${year}`
        )
        setTasks(res.data.tasks)
        setProjects(res.data.projects)
      } catch (err) {}
      setIsLoading(false)
    }
    if (year && month !== undefined) fetchPosts()
  }, [user?._id, month, year])
  return isLoading ? (
    <CustomCircularProgress />
  ) : tasks.length > 0 ? (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {projects?.map((project, i) => (
        <NavigatorGenerator
          key={project?._id + i}
          DCGProps={{ isDarkModeActive, socket, project }}
          navigatorsArray={[
            MemberAssignedTasksConfigs({
              user,
              headerText: rtl
                ? `مهام ${project?.ProjectName}`
                : `${project?.ProjectName}'s Assocated Tasks`,
              DCGProps: { isDarkModeActive, socket, project },
              tasks: tasks.filter(
                (task) =>
                  task?.projectId === project?._id &&
                  task?.TaskPairedContributorId === user?._id
              ),
            }),
          ]}
        />
      ))}
      <TitleHeader
        headerText={
          rtl ? 'مهام تقوم بالإشراف عليها' : `Tasks Modereated by You`
        }
      />
      <ModeratedTasks
        isDarkModeActive={isDarkModeActive}
        socket={socket}
        tasks={tasks.filter((task) => task?.TaskModeratorId === user?._id)}
        projects={projects}
      />
    </article>
  ) : (
    <CustomEmpty
      emptyMssg={rtl ? 'لا مهام لهذا الشهر' : 'No Tasks for this Month'}
      imageKey='noTasks'
      isDarkModeActive={isDarkModeActive}
    />
  )
}

export default TasksCalenderDialogContent
