import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const FunctionAsAService = () => {
  const { t } = useTranslation('FunctionAsAService')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 77,
    title: t('title_bm77'),
    subTitle: t('subTitle_bm77'),
    main: t('main_bm77'),
    when: t('when_bm77'),
    who: t('who_bm77'),
    what: t('what_bm77'),
    how: t('how_bm77'),
    why: t('why_bm77'),
    examples: rtl
      ? [
          'Airbnb - 2008 - الولايات المتحدة',
          'Netflix - 1997 - الولايات المتحدة',
          'Spotify - 2006 - السويد',
          'أوبر - 2009 - الولايات المتحدة',
          'سلاك - 2013 - الولايات المتحدة',
          'اكسبيديا - 1996 - الولايات المتحدة',
          'ناسا - 1958 - الولايات المتحدة',
          'كوكا كولا - 1892 - الولايات المتحدة',
          'GE (General Electric) - 1892 - الولايات المتحدة',
        ]
      : [
          'Airbnb - 2008 - United States',
          'Netflix - 1997 - United States',
          'Spotify - 2006 - Sweden',
          'Uber - 2009 - United States',
          'Slack - 2013 - United States',
          'Expedia - 1996 - United States',
          'NASA - 1958 - United States',
          'Coca-Cola - 1892 - United States',
          'GE (General Electric) - 1892 - United States',
        ],
    tags: rtl
      ? [
          'قائم على الاستخدام',
          'قابلية التوسع',
          'المرونة',
          'صيانة مخفضة',
          'تقليل الوقت',
          'نمو السوق',
          'التبني السريع',
          'فعالية التكلفة',
          'طلب العملاء',
          'تكاليف البنية التحتية',
          'كفاءة العملية',
          'تطوير المنتجات',
          'الحوسبة بدون خادم',
          'حوسبة سحابية',
          'الوظيفة كخدمة',
          'معمارية بدون خادم',
        ]
      : [
          'Usage-Based',
          'Scalability',
          'Flexibility',
          'Reduced Maintenance',
          'Reduced Time',
          'Market Growth',
          'Rapid Adoption',
          'Cost-Efficiency',
          'Customer Demand',
          'Infrastructure Costs',
          'Operational Efficiency',
          'Product Development',
          'Serverless Computing',
          'Cloud Computing',
          'Function-as-a-Service',
          'Serverless Architecture',
        ],
    considerations: t('considerations_bm77'),
    combinations: ['$LBM40', '$LBM54', '$LBM22', '$LBM71'],
    hashTagType: 'businessModelTemp',
  }
}

export default FunctionAsAService
