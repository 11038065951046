import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandedCardsSystem from '../../../../../../expandedCardsSystem/ExpandedCardsSystem'
import CustomCircularProgress from '../../../../../../smallComponents/CustomCircularProgress'
import TitleHeader from '../../../../../../smallComponents/Header'
import MultiLangNumber from '../../../../../../smallComponents/MultiLangNumber'
import InvestPackRequestDetails from './InvestmentPackageRequestsComps/InvestPackRequestDetails'

const InvestmentPackageRequests = ({ DCGProps, pack }) => {
  const [requests, setRequests] = useState([])
  const [requestsUsers, setRequestsUsers] = useState([])
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/requests/elementRequests/${pack?._id}`)
        setRequests(res.data.requests)
        setRequestsUsers(res.data.requestsUsers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchRequests()
  }, [pack?._id])
  return isLoading ? (
    <CustomCircularProgress />
  ) : (
    <>
      <TitleHeader
        headerText={`${MultiLangNumber(requests?.length)} ${t('Requests')}`}
      />
      <ExpandedCardsSystem
        cards={requests}
        UnitCardDetailsComponent={InvestPackRequestDetails}
        firsButtonConfigs={{
          hideButton: true,
          props: { pack },
        }}
        searchSystemProps={{}}
        DCGProps={DCGProps}
        requestsUsers={requestsUsers}
        setRequests={setRequests}
      />
    </>
  )
}

export default InvestmentPackageRequests
