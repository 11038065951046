import axios from 'axios'
import React from 'react'
import FullInteractiveRequestDetailsModel from '../../../../../../../notificationsSystem/FullInteractiveRequestDetailsModel'
import i18next from 'i18next'

const JobRequestDetails = (props) => {
  const { card, exprops, requestsUsers } = props
  const { job } = exprops
  const request = card
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)
  const lang = i18next.language
  const rtl = lang === 'ar'

  const onAccept = async () => {
    try {
      await axios.patch(`/jobs/${job?._id}`, {
        JobStatus: 'Closed',
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <FullInteractiveRequestDetailsModel
      RequestText={
        <section>
          {requestingUser?.firstName + ' ' + requestingUser?.lastName}{' '}
          {rtl ? 'قدّم على هذه الوظيفة' : 'applied for this job opportunity'}
        </section>
      }
      onAccept={onAccept}
      NotificationCreatorAcceptProps={{
        associativity: 'job',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [
          {
            hashTagType: job?.hashTagType,
            id: job?._id || job?.id,
            title: job?.Title || job?.title,
          },
        ],
      }}
      NotificationCreatorRefuseProps={{
        associativity: 'job',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [
          {
            hashTagType: job?.hashTagType,
            id: job?._id || job?.id,
            title: job?.Title || job?.title,
          },
        ],
      }}
      contributorProps={{
        projectId: job?.projectId,
        departmentId: job?.departmentId,
        rule: request?.data?.rule,
        RuleTitle: job?.Title || job?.title,
        RuleDescription: job?.Description,
      }}
      requestingUser={requestingUser}
      {...props}
    />
  )
}

export default JobRequestDetails
