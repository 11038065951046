import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const NoFrillsBusinessModel = () => {
  const { t } = useTranslation('NoFrillsBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 35,
    title: t('title_bm35'),
    subTitle: t('subTitle_bm35'),
    main: t('main_bm35'),
    when: t('when_bm35'),
    who: t('who_bm35'),
    what: t('what_bm35'),
    how: t('how_bm35'),
    why: t('why_bm35'),
    examples: rtl
      ? [
          'فورد - 1903 - الولايات المتحدة الأمريكية',
          'نظام أرافيند للعناية بالعيون - 1976 - الهند',
          'ماكدونالدز - 1940 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Ford - 1903 - USA',
          'Aravind Eye Care System - 1976 - India',
          'McDonald’s - 1940 - USA',
        ],
    tags: rtl
      ? [
          'أسعار منخفضة',
          'بلا زخرفة',
          'استراتيجية التسعير',
          'أسعار منخفضة',
          'المستوى الأدنى',
          'ميزات اضافية',
          'المنتجات الأساسية',
          'سوق الجملة',
          'حساس للسعر',
        ]
      : [
          'No Frills',
          'Pricing Strategy',
          'Low Prices',
          'Minimum Level',
          'Extra Features',
          'Basic Products',
          'Mass Market',
          'Price-Sensitive',
        ],
    considerations: t('considerations_bm35'),
    combinations: ['$LBM16', '$LBM15', '$LBM50', '$LBM61'],
    hashTagType: 'businessModelTemp',
  }
}

export default NoFrillsBusinessModel
