import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const ReverseInnovationBusinessModel = () => {
  const { t } = useTranslation('ReverseInnovationBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 48,
    title: t('title_bm48'),
    subTitle: t('subTitle_bm48'),
    main: t('main_bm48'),
    when: t('when_bm48'),
    who: t('who_bm48'),
    what: t('what_bm48'),
    how: t('how_bm48'),
    why: t('why_bm48'),
    examples: rtl
      ? [
          'جنرال إلكتريك (الولايات المتحدة الأمريكية) - تأسست عام 1892',
          'Haier (الصين) - تأسست عام 1984',
          'نوكيا - تأسست عام 1865',
          'داسيا لوجان - تأسست عام 2004',
        ]
      : [
          'General Electric (USA) - founded in 1892',
          'Haier (China) - founded in 1984',
          'Nokia - founded in 1865',
          'Dacia Logan - founded in 2004',
        ],
    tags: rtl
      ? ['سعر منخفض', 'بحث وتطوير ديناميكي']
      : ['Low Price', 'Dynamic R&D'],
    considerations: t('considerations_bm48'),
    combinations: ['$LBM47'],
    hashTagType: 'businessModelTemp',
  }
}

export default ReverseInnovationBusinessModel
