import { useState, useEffect } from 'react'

const TypingChecker = ({ textContent }) => {
  const [isTyping, setIsTypeing] = useState(false)
  useEffect(() => {
    if (textContent.endsWith(' ')) {
      setIsTypeing(true)
    } else {
      setIsTypeing(false)
    }
  }, [textContent])
  return { isTyping }
}

export default TypingChecker
