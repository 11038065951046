import i18next from "i18next"

const NewGlobalInquiryFormConfigs = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'العنوان' : 'Global Inquiry Title',
      required: true,
    },
    {
      title: 'Description',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'التفاصيل' : 'Global Inquiry Description',
      required: true,
      multiLine: true,
      minRows: 4,
    },
  ]
}

export default NewGlobalInquiryFormConfigs
