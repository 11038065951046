import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { NotificationViewModel } from '../../../..'
import GridLoader from '../../../../../../smallComponents/GridLoader'
import RemovedItem from '../../../../../../smallComponents/RemovedItem'
import PollCard from './NewPollNotificationComps/PollCard'
import i18next from 'i18next'

const NewPollNotification = (props) => {
  const { notification, project } = props
  // the target of audince will be determined by the creator of the poll - he may target 'all contributors', managers, sub-dep managers ..etc.
  const [interactedWith, setInteractedWith] = useState(
    notification?.interactedWith
  )
  const defaultAction = async () => {
    await axios.patch(`/notifications/${notification?._id}`, {
      interactedWith: 'true',
    })
    setInteractedWith(true)
  }
  const [poll, setPoll] = useState()
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchPoll = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/polls/${notification?.accompaniedData?.elements[0]?.id}`
        )
        setPoll(res.data.poll)
        setOptions(res.data.options)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (notification?.accompaniedData?.elements[0]?.id) fetchPoll()
  }, [notification?.accompaniedData?.elements[0]?.id])
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <div style={{ width: '100%' }}>
          {rtl ? 'لديك تصويت جديد من' : 'You have a new voting from'}{' '}
          <Link
            to={`/projectProfile/${project?._id}`}
            style={{ fontWeight: 500, marginRight: 4, marginLeft: 4 }}
          >
            {project?.name}
          </Link>{' '}
          {rtl ? 'لتبدى رأبك فيه' : 'to provide your opinion for.'}
          <br />
          <br />
          {isLoading ? (
            <GridLoader />
          ) : poll ? (
            <PollCard
              poll={poll}
              options={options}
              setOptions={setOptions}
              defaultAction={defaultAction}
              interactedWith={interactedWith}
              {...props}
            />
          ) : (
            <RemovedItem
              removedMssg={
                rtl ? 'لقد تم الغاء او مسح التصويت' : 'Voting has been deleted'
              }
            />
          )}
        </div>
      }
      buttonsArray={[]}
    />
  )
}

export default NewPollNotification
