import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const Barriers = () => {
  const { t } = useTranslation('Barriers')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 12,
    category: t('category_ms12'),
    title: t('title_ms12'),
    subTitle: t('subTitle_ms12'),
    main: t('main_ms12'),
    how: t('how_ms12'),
    usedInModels: ['$LBM54', '$LBM22', '$LBM40'],
    tags: rtl
      ? [
          'حواجز مقصودة',
          'حواجز غير مقصودة',
          'العملاء المستهدفون',
          'العملاء المبعدين',
          'دوران المبيعات',
          'موقع العلامة التجارية',
          'المتغيرات والسوق',
        ]
      : [
          'Intentional Barriers',
          'Unintentional Barriers',
          'Targeted Customers',
          'Repelled Customers',
          'Sales Turnover',
          'Brand Position',
          'Variants, and Market',
        ],
    examples: t('examples_ms12'),
    hashTagType: 'markStraAspect',
  }
}

export default Barriers
