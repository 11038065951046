import i18next from 'i18next'
import React from 'react'

const DistributionChannels = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      {rtl
        ? 'للوصول إلى عملائنا، نحتاج إلى التأكد من أن قنواتنا تتكامل بشكل صحيح مع أنشطة أعمالنا الأخرى، وأنها تتوافق مع احتياجات عملائنا. يمكننا الجمع بين قنوات مختلفة، مثل التسويق الرقمي وتسويق المحتوى والنشرات الإخبارية وغير ذلك، للوصول إلى جمهورنا المستهدف وتلبية احتياجاتهم.'
        : 'To reach our customers, we need to ensure that our channels are properly integrated with our other business activities, and that they correspond to our customer needs. We can combine different channels, such as digital marketing, content marketing, newsletters, and more, to reach our target audience and meet their needs.'}
    </>
  )
}

export default DistributionChannels
