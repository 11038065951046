import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const TrashToCashBusinessModel = () => {
  const { t } = useTranslation('TrashToCashBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 56,
    title: t('title_bm56'),
    subTitle: t('subTitle_bm56'),
    main: t('main_bm56'),
    when: t('when_bm56'),
    who: t('who_bm56'),
    what: t('what_bm56'),
    how: t('how_bm56'),
    why: t('why_bm56'),
    examples: rtl
      ? [
          'Duales System Deutschland - تأسست 1994 - ألمانيا',
          'مختبر فريتاغ - تأسس عام 1993 - سويسرا',
          'Greenwire - تأسست عام 2008 - إنجلترا',
          'إميكو - تأسست عام 1944 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Duales System Deutschland - Founded 1994 - Germany',
          'Freitag lab - Founded 1993 - Switzerland',
          'Greenwire - Founded 2008 - England',
          'Emeco - Founded 1944 - USA',
        ],
    tags: rtl
      ? [
          'إعادة التدوير',
          'تكاليف الشراء الصفري',
          'الفوز',
          'سعر منخفض',
          'مقياس اقتصادي',
          'أعيد استخدامها',
          'صديق للبيئة',
        ]
      : [
          'Recycling',
          'Zero-Purchase Costs',
          'Win-win',
          'Low Price',
          'Economies of Scale',
          'Reused',
          'Environment Friendly',
        ],
    considerations: t('considerations_bm56'),
    combinations: [],
    hashTagType: 'businessModelTemp',
  }
}

export default TrashToCashBusinessModel
