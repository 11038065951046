const HashTagElementsRefiner = (element) => {
  const textArray =
    element && Object?.values(element).filter((res) => typeof res === 'string')
  const properHahstags =
    textArray &&
    element?.chosenHashTagElements?.filter((hash) =>
      textArray?.find((t) => t?.includes(hash?.identifier))
    )
  const hashMap = {}
  const uniqueResults = properHahstags
    ?.filter((u) => u?.mentionElement?.id)
    ?.filter((item, _) => {
      let alreadyExists = hashMap?.hasOwnProperty(item.mentionElement?.id)
      return alreadyExists ? false : (hashMap[item.mentionElement?.id] = 1)
    })
  return uniqueResults
}

export default HashTagElementsRefiner
