import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ProjectPostCommentCover = ({ notification, sender, project }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `قام ${sender.name} بالتعليق على منشور فى ${project?.name}`
    : `${sender.name} commented on a post in ${project?.name}`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default ProjectPostCommentCover
