import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const Positioning = () => {
  const { t } = useTranslation('Positioning')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 32,
    category: t('category_ms32'),
    title: t('title_ms32'),
    subTitle: t('subTitle_ms32'),
    main: t('main_ms32'),
    how: t('how_ms32'),
    usedInModels: [],
    tags: rtl
      ? [
          'إدراك العملاء',
          "عمل ناجح'",
          'وضع المنتج',
          'سلالم عقلية',
          'خلق ميزة',
          'فهم العميل',
          'ملكية المنصب',
        ]
      : [
          'Customer Perception',
          'Successful Business',
          'Product Positioning',
          'Mental Ladders',
          'Advantage Creation',
          'Customer Understanding',
          'Position Ownership',
        ],
    examples: t('examples_ms32'),
    hashTagType: 'markStraAspect',
  }
}

export default Positioning
