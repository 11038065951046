import React from 'react'
import { useTranslation } from 'react-i18next'
import BulletSeparator from '../../../../smallComponents/BulletSeparator'

const PostTypeFlag = ({ postProps }) => {
  const { post } = postProps
  const { t } = useTranslation()
  return (
    post?.postElement?.hashTagType && (
      <>
        {t(
          post?.postElement?.hashTagType?.charAt(0).toUpperCase() +
            post?.postElement?.hashTagType?.slice(1)
        )}
        <BulletSeparator />
      </>
    )
  )
}

export default PostTypeFlag
