import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const CustomerDescription = () => {
  const { t } = useTranslation('CustomerDescription')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 1,
    category: t('category_ms01'),
    title: t('title_ms01'),
    subTitle: t('subTitle_ms01'),
    main: t('main_ms01'),
    how: t('how_ms01'),
    usedInModels: [],
    tags: rtl
      ? [
          'الفئة العمرية',
          'ظروف عائلية',
          'نوع العمل',
          'حجم المنظمة',
          'موقع جغرافي',
          'مجموعات العملاء',
          'تطوير المنتج',
          'خدمة التوصيل',
          'الأنشطة الترويجية',
          'أنشطة المبيعات',
          'تغييرات الأسعار',
          'مقدمة المنتج',
          'ملاحظات العملاء',
          'الدراسات الاستقصائية',
          'مقابلات',
          'مجموعات التركيز',
        ]
      : [
          'Age Group',
          'Family Circumstances',
          'Business Type',
          'Organization Size',
          'Geographical Location',
          'Customer Groups',
          'Product Development',
          'Service Delivery',
          'Promotional Activities',
          'Sales Activities',
          'Price Changes',
          'Product Introduction',
          'Customer Feedback',
          'Surveys',
          'Interviews',
          'Focus Groups',
        ],
    examples: t('examples_ms01'),
    hashTagType: 'markStraAspect',
  }
}

export default CustomerDescription
