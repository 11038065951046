import i18next from "i18next"

const newProductFormConfigs = (designer) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Product_Designer',
      formComponentType: 'text',
      value: `${designer?.firstName + ' ' + designer?.lastName}`,
      disabled: true,
      hideInPost: true,
    },
    {
      title: 'Type',
      formComponentType: 'select',
      value: 'Product',
      menuOptions: ['Product', 'Service'],
      hideInPost: true,
    },
    {
      title: 'Product_Name',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'اسم المنتج' : 'Name Your Product',
    },
  ]
}

export default newProductFormConfigs
