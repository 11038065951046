import React from 'react'
import ImagesSystem from '../../../postSystem/ImagesSystem'
import PostProps from '../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import LinksPlayerSystem from '../../../postSystem/LinksPlayerSystem'

const CommentCenter = ({ comment, isDarkModeActive }) => {
  const {
    imageViewerDialogOpener,
    imageViewerDialogClose,
    isImageViewerDialogOpen,
  } = PostProps({}, { imageViewerProps: true })
  return (
    <span style={{ marginBottom: 5 }}>
      <ImagesSystem
        imagesArray={comment?.images}
        openFun={() => imageViewerDialogOpener()}
      />
      <ImagesSystem
        imageViewer={true}
        imageViewerDialogClose={imageViewerDialogClose}
        isImageViewerDialogOpen={isImageViewerDialogOpen}
        imagesArray={comment?.images}
        commentingSystemView={true}
        isDarkModeActive={isDarkModeActive}
      />
      <LinksPlayerSystem url={comment?.playerLink} />
    </span>
  )
}

export default CommentCenter
