import React from 'react'
import NavigatorGenerator from '../../navigatorCardsModel/NavigatorGenerator'
import DefaultImageGrapper from '../../smallComponents/DefaultImageGrapper'
import i18next from 'i18next'

const ContractHolders = (props) => {
  const { users, projects, DCGProps } = props
  const { project } = DCGProps
  const contractedUsers = users?.map((us) => ({
    id: us?._id,
    hashTagType: project ? 'projectContributor' : 'userContributor',
    title: `${us?.firstName + ' ' + us?.lastName}`,
    image: us?.profilePicture || DefaultImageGrapper('noUserAvatar'),
  }))
  const contractedProejcts = projects?.map((pro) => ({
    id: pro?._id,
    hashTagType: 'partnerCompany',
    title: pro?.ProjectName,
    image: pro?.projectPhoto || DefaultImageGrapper('noProjectAvatar'),
  }))
  const pathNameGen = (project) => `/projectProfile/${project?.id}`
  const lang = i18next.language
  const rtl = lang === 'ar'
  const navigatorsArray = [
    {
      headerText: rtl ? 'مشاريع مُتعاقد معها' : 'Contracted Projects',
      unitsArray: contractedProejcts || [],
      modelUnitProps: {
        pathName: pathNameGen,
      },
      NavigatorCardsModelProps: {},
    },
    {
      headerText: rtl ? 'مستتخدمين متعاقد معهم' : 'Contracted Users',
      unitsArray: contractedUsers || [],
      modelUnitProps: {},
      NavigatorCardsModelProps: {},
    },
  ]
  return <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
}

export default ContractHolders
