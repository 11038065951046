import React from 'react'
import { useTranslation } from 'react-i18next'
import FullInteractiveRequestDetailsModel from '../../../../../../../../notificationsSystem/FullInteractiveRequestDetailsModel'
import i18next from 'i18next'

const DevOfferUnitDetails = (props) => {
  const { card, requestsUsers } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const request = card
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)
  const { t } = useTranslation()
  return (
    <FullInteractiveRequestDetailsModel
      RequestText={
        <section>
          {rtl
            ? `يعرض ${
                requestingUser?.firstName + ' ' + requestingUser?.lastName
              } استثمار ${t(request?.subgenre)} `
            : `${
                requestingUser?.firstName + ' ' + requestingUser?.lastName
              } offers a ${request?.subgenre} investment.`}
        </section>
      }
      NotificationCreatorAcceptProps={{
        associativity: 'development',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [],
      }}
      NotificationCreatorRefuseProps={{
        associativity: 'development',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [],
      }}
      contributorProps={{ MembershipState: 'Developer' }}
      requestingUser={requestingUser}
      {...props}
    />
  )
}

export default DevOfferUnitDetails
