import React from 'react'
import IssueNotifsDownVote from './IssueNotificationViewModelComps/IssueNotifsDownVote'
import IssueNotifsUpVote from './IssueNotificationViewModelComps/IssueNotifsUpVote'
import NewIssueNotifs from './IssueNotificationViewModelComps/NewIssueNotifs'

const IssueNotificationViewModel = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const mssgs = [
    {
      relation: 'issued',
      comp: [
        <NewIssueNotifs {...notifProps}/>, //used
      ],
    },
    {
      relation: 'voting',
      comp: [
        <IssueNotifsUpVote {...notifProps}/>,
        <IssueNotifsDownVote {...notifProps}/>,
      ],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default IssueNotificationViewModel
