import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const CustomerSatisfaction = () => {
  const { t } = useTranslation('CustomerSatisfaction')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 8,
    category: t('category_ms08'),
    title: t('title_ms08'),
    subTitle: t('subTitle_ms08'),
    main: t('main_ms08'),
    how: t('how_ms08'),
    usedInModels: [],
    tags: rtl
      ? [
          'رضا العملاء',
          'الدراسات الاستقصائية',
          'تحليلات',
          'استراتيجيات القياس',
          'سلوك العميل',
          'تفضيلات العميل',
          'تجربة العميل',
          'نجاح الأعمال',
        ]
      : [
          'Customer Satisfaction',
          'Surveys',
          'Analytics',
          'Measurement Strategies',
          'Focus Groups',
          'Online Forums',
          'Customer Behaviour',
          'Customer Preferences',
          'Customer Experience',
          'Business Success',
        ],
    examples: t('examples_ms08'),
    hashTagType: 'markStraAspect',
  }
}

export default CustomerSatisfaction
