import React from 'react'
import TextPost from '../../../postSystem/TextPost'
import withStyles from '@mui/styles/withStyles'
import CommentsDialog from '../../../commentsSystems/CommentsDialog'
import ImagesSystem from '../../../postSystem/ImagesSystem'
import SocialPostMiddle from '../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/SocialPostComponents/SocialPostMiddle'
import PostProps from '../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'

const styles = () => ({
  postWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
})

const TextualPostModel = ({ postBodyProps, classes }) => {
  const { post } = postBodyProps
  return (
    <article className={classes.postWrapper}>
      <TextPost {...postBodyProps} margins={'auto 15px'} />
      <section
        style={{
          display: post?.images ? null : 'none',
        }}
      >
        <SocialPostMiddle
          postProps={PostProps(postBodyProps, {
            imageViewerProps: true,
            commentsDialogProps: true,
          })}
        />
      </section>
    </article>
  )
}

const WrappedTextualPostModel = withStyles(styles)(TextualPostModel)

export default WrappedTextualPostModel
