import React from 'react'
import NavigatorGenerator from '../../../../../../navigatorCardsModel/NavigatorGenerator'
import DefaultImageGrapper from '../../../../../../smallComponents/DefaultImageGrapper'
import i18next from 'i18next'

const UserPortfolioNavigators = ({
  isDarkModeActive,
  socket,
  publishedIdeas,
  publishedInquiries,
  publishedGlobalInquiries,
  userEstablishedProjects,
  userResources,
  userIssues,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const pathNameGen = (project) => `/projectProfile/${project?._id}`
  const navigatorsArray = [
    {
      headerText: rtl ? 'مشاكل' : 'Issues',
      unitsArray: userIssues,
      modelUnitProps: {},
      hideNavigator: userIssues?.length === 0,
    },
    {
      headerText: rtl ? 'مشروعات' : 'Projects',
      unitsArray: userEstablishedProjects,
      elementsImages: userEstablishedProjects?.map((project) => ({
        id: project?._id,
        image: project?.projectPhoto || DefaultImageGrapper('noProjectAvatar'),
      })),
      modelUnitProps: {
        pathName: pathNameGen,
      },
      hideNavigator: userEstablishedProjects?.length === 0,
    },
    {
      headerText: rtl ? 'الأفكار التى تم نشرها' : 'User Published Ideas',
      unitsArray: publishedIdeas,
      modelUnitProps: {
        hideIdeaRequests: true,
      },
      elementsImages: publishedIdeas?.map((res) => ({
        id: res._id,
        image: res.images[0],
      })),
      hideNavigator: publishedIdeas?.length === 0,
    },
    {
      headerText: rtl ? 'الموارد المتاحة' : 'Available Resources',
      unitsArray: userResources,
      elementsImages: userResources?.map((resource) => ({
        id: resource?._id,
        image: resource?.images[0],
      })),
      modelUnitProps: {
        hideOwner: true,
      },
      hideNavigator: userResources?.length === 0,
    },
    {
      headerText: rtl ? 'المتطلبات المنشورة' : 'Published Inquiries',
      unitsArray: publishedInquiries,
      modelUnitProps: {
        hideInquiryRequests: true,
      },
      elementsImages: publishedInquiries?.map((res) => ({
        id: res._id,
        image: res.images[0],
      })),
      hideNavigator: publishedInquiries?.length === 0,
    },
    {
      headerText: rtl
        ? 'الرؤى العالمية المنشورة'
        : 'Published Global Inquiries',
      unitsArray: publishedGlobalInquiries,
      modelUnitProps: {},
      elementsImages: publishedGlobalInquiries?.map((res) => ({
        id: res._id,
        image: res.images[0],
      })),
      hideNavigator: publishedGlobalInquiries?.length === 0,
    },
  ]
  return (
    <NavigatorGenerator
      DCGProps={{ socket, isDarkModeActive }}
      navigatorsArray={navigatorsArray}
    />
  )
}

export default UserPortfolioNavigators
