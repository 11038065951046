import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const RoompostMenitonedNotifCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `قام ${sender?.name} بالاإشارة اليك فى مناقشة`
    : `${sender?.name} has mentioned you in a room discussion`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default RoompostMenitonedNotifCover
