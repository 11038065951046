import React from 'react'
import NavigatorCardsModel from '../../../../../../../navigatorCardsModel/NavigatorCardsModel'
import TitleHeader from '../../../../../../../smallComponents/Header'
import i18next from 'i18next'

const ProductStrategyFulfilledAspects = ({
  DCGProps,
  chosenCards,
  setChosenCards,
}) => {
  const unitsArray = chosenCards
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'إزالة' : 'Remove',
      funs: (element) => {
        setChosenCards(
          chosenCards.filter(
            (card) =>
              card.id !== element.id && card.hashTagType === element.hashTagType
          )
        )
      },
    },
  ]
  return (
    <>
      <TitleHeader
        headerText={rtl ? 'جوانب الاستراتجية' : 'Strategy Aspects'}
      />
      <NavigatorCardsModel
        unitsArray={unitsArray}
        {...DCGProps}
        modelUnitProps={{
          elementsImages: [],
          imageKey: 'markStraAspect',
          windowProps: {
            windowCardName: 'aspect',
            windowType: 'markStraAspect',
            DCGProps,
          },
          buttonsArray,
        }}
      />
    </>
  )
}

export default ProductStrategyFulfilledAspects
