import i18next from "i18next"

const GeneralPollConfigs = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Set_Your_Question',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'غرض التصويت' : 'Voting Subject',
      multiLine: true,
      minRows: 3,
    },
    {
      title: 'Set_Deadline',
      formComponentType: 'date',
      value: '',
    },
  ]
}

export default GeneralPollConfigs
