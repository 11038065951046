import React from 'react'
import { useTranslation } from 'react-i18next'
import MultiLangNumber from '../../../../../../../smallComponents/MultiLangNumber'
import ModTasks from '../../ModTasks'
import i18next from 'i18next'

const TaskDetailsHeaderText = ({ task: oldTask, isDarkModeActive }) => {
  const task = ModTasks({ projectTasks: [oldTask] })[0]
  // to transorm the 2013-9-31 format into ms format
  const taskDeadline = new Date(task.TaskDeadline).getTime()

  const daysLeft = Math.ceil(
    (taskDeadline - Date.now()) / (24 * 60 * 60 * 1000)
  )
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <span>
      {rtl ? 'تفاصيل المهمة' : 'Task Details'}{' '}
      <span
        style={{
          color:
            task.TaskStatus === 'Done'
              ? 'green'
              : task.TaskStatus === 'Still'
              ? 'blue'
              : task.TaskStatus === 'Terminated'
              ? isDarkModeActive
                ? 'white'
                : 'gray'
              : task.TaskStatus === 'Due Today'
              ? 'yellow'
              : 'Red',
          fontSize: 16,
          fontStyle: 'oblique',
          fontWeight: 400,
        }}
      >
        {t(task.TaskStatus)}
      </span>
      <span
        style={{
          display: task.TaskStatus === 'Still' ? null : 'none',
          fontSize: 16,
          fontWeight: 400,
          marginLeft: !rtl && 10,
          marginRight: rtl && 10,
        }}
      >
        ( {daysLeft > 0 && MultiLangNumber(daysLeft)}{' '}
        {rtl ? 'أيام متبقية' : 'Days Left'})
      </span>
    </span>
  )
}

export default TaskDetailsHeaderText
