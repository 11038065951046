import React from 'react'
import withStyles from '@mui/styles/withStyles'
import NotificationStatusDots from './NotificationCoverModelComps/NotificationStatusDots'
import moment from 'moment'
import i18next from 'i18next'

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },

  dateWrapper: {
    fontSize: 12,
    fontWeight: 400,
  },
})

const NotificationCoverModel = ({
  classes,
  notifCoverContent,
  notification,
}) => {
  const dots = [
    {
      color: notification?.opened ? 'green' : 'white',
    },
    {
      color: notification?.interactedWith ? 'green' : 'white',
    },
  ]

  const lang = i18next.language
  const rtl = lang === 'ar'

  return (
    <section
      className={classes.container}
      style={{
        fontWeight: !notification?.opened && 'bold',
        textAlign: rtl ? 'right' : 'left',
      }}
    >
      {notifCoverContent}
      <section className={classes.wrapper}>
        <NotificationStatusDots dots={dots} />
        <span
          className={classes.dateWrapper}
          style={{ fontWeight: !notification?.opened && 'bold' }}
        >
          {moment(notification.createdAt).calendar()}
        </span>
      </section>
    </section>
  )
}

const WrappedNotificationCoverModel = withStyles(styles)(NotificationCoverModel)

export default WrappedNotificationCoverModel
