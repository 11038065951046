import React from 'react'
import { NotificationBodyWithAccessCard } from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import NotificationViewModel from '../../../../NotificationViewModel'
import i18next from 'i18next'

const NewElementAdded = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? `قام ${sender?.name}  بإضافة عنصر جديد لعقد انت مُسجل به`
              : `${sender?.name} added a new element to a contract you are in`
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default NewElementAdded
