import React, { useState } from 'react'
import SelectProfileAvatarModel from '../../../../formsSystem/SelectProfileAvatarModel'
import NewContribFormButtons from './NewContribFormComponents/NewContribFormButtons'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
})

const NewContributorForm = ({
  newContribClose,
  setClickAwayState,
  isDarkModeActive,
  classes,
}) => {
  //select contributors
  const [selectNewContributor, setSelectNewContributor] = useState(null)
  const choosingContributors = (event) => {
    const value = event.target.value
    setSelectNewContributor(value)
  }

  const contributors = []
  return (
    <article className={classes.wrapper}>
      <SelectProfileAvatarModel
        setClickAwayState={setClickAwayState}
        elements={contributors}
        isDarkModeActive={isDarkModeActive}
        selectedElements={selectNewContributor}
        choosingElements={choosingContributors}
      />
      <NewContribFormButtons
        newContribClose={newContribClose}
        isDataFilled={selectNewContributor}
      />
    </article>
  )
}

const WrappedNewContributorForm = withStyles(styles)(NewContributorForm)

export default WrappedNewContributorForm
