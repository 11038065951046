import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Chip } from '@mui/material'
import TitleHeader from '../../../../../../../smallComponents/Header'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    fontSize: 15,
    fontWeight: 500,
    padding: 10,
    margin: 10,
    borderRadius: 10,
    background: 'rgba(217,224,252, 0.1)',
    fontStyle: 'oblique',
  },
})

const ModelPopularity = ({ DCGProps, model, classes }) => {
  const { isDarkModeActive } = DCGProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <TitleHeader
        headerText={rtl ? 'مدى شيوع النموذج' : 'Model Popularity'}
        subHeaderText={
          rtl
            ? 'تم استخدام هذا النموذج بنجاح في مشاريع واقعية'
            : 'This Model Has been successfully used in real-life projects'
        }
      />
      <article className={classes.wrapper}>
        <section
          style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}
        >
          {model.examples.map((example, idnex) => (
            <Chip
              key={idnex}
              label={example}
              style={{
                color: isDarkModeActive ? 'white' : null,
                margin: '2px',
              }}
            />
          ))}
        </section>
      </article>
    </>
  )
}

const WrappedModelPopularity = withStyles(styles)(ModelPopularity)

export default WrappedModelPopularity
