import React from 'react'
import deleteFile from '../../../firebase/deleteFile'
import NewFormDialogModel from '../NewFormDialogModel'
import EditingDialogContent from './FullActiveOutlineInputComponents/EditingDialogContent'
import i18next from 'i18next'

const EditingInputDialog = ({ formsCompsProps }) => {
  const { formComponentIndex, ...props } = formsCompsProps
  const {
    isWindowOpen,
    windowCloser,
    setTextContent,
    setImages,
    isDarkModeActive,
    newImages,
  } = props

  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NewFormDialogModel
      NewFormComponent={
        <EditingDialogContent
          formsCompsProps={{
            editingDialogClose: windowCloser,
            ...props,
          }}
        />
      }
      newFormDialogClose={async () => {
        Promise.all(
          newImages.map(async (image) => {
            if (image) await deleteFile(image)
          })
        )
        windowCloser()
        setTextContent('')
        setImages([])
      }}
      isNewFormDialogOpen={isWindowOpen}
      isDarkModeActive={isDarkModeActive}
      clickAwayState={true}
      dialogTitle={rtl ? 'تحرير' : 'Editing'}
    />
  )
}

export default EditingInputDialog
