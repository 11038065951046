import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ProjectPostShareCover = ({ notification, sender, project, post }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const differentSharers = post?.shares?.filter(
    (id) => id !== sender?._id
  )?.length
  const content =
    differentSharers > 1
      ? rtl
        ? `قام ${sender?.name} و ${differentSharers} اخرون بمشاركة منشور فى ${project?.name}`
        : `${sender?.name} and ${differentSharers} others Shared a post in ${project?.name}.`
      : rtl
      ? `قام ${sender?.name} بمشاركة منشور فى ${project?.name}`
      : `${sender?.name} Shared a post in ${project?.name}.`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default ProjectPostShareCover
