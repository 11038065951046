import React from 'react'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import NotificationViewModel from '../../../../NotificationViewModel'
import i18next from 'i18next'

const ChangeSubDepNameNotif = (props) => {
  const { notification, project } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <section style={{ width: '100%' }}>
          {rtl
            ? `لقد تم تغيير اسم القسم الفرعى الخاص بك الى`
            : 'Name of your sub-department has been changed to'}{' '}
          {notification?.accompaniedData?.subdepartment?.Name}{' '}
          {rtl ? 'فى' : 'in'} {project?.name}
        </section>
      }
      buttonsArray={[]}
    />
  )
}

export default ChangeSubDepNameNotif
