import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  titleWrapper: {
    fontWeight: 500,
  },
})

const SuggUnitCard = ({ classes, element, card }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <section className={classes.container}>
      {card?.Icon && (
        <card.Icon
          style={{
            color: card?.iconColor,
            marginRight: !rtl && 5,
            marginLeft: rtl && 5,
          }}
        />
      )}
      <span
        className={classes.titleWrapper}
        style={{ direction: document.body.dir }}
      >
        {element[card?.titleProperty]}
      </span>
    </section>
  )
}

const WrappedSuggUnitCard = withStyles(styles)(SuggUnitCard)

export default WrappedSuggUnitCard
