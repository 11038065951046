import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { Error as ErrorIcon } from '@material-ui/icons'
import DocumentTitleChanger from '../components/smallComponents/DocumentTitleChanger'
import i18next from 'i18next'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#F5F5F5',
    margin: '-15px 0 0 0',
  },
  icon: {
    fontSize: 120,
    color: '#f44336',
  },
  heading: {
    marginTop: '32px',
    marginBottom: '16px',
    fontWeight: 'bold',
    fontSize: '32px',
    color: '#000',
  },
  message: {
    marginBottom: '32px',
    fontSize: '18px',
    color: '#666',
  },
  button: {
    textTransform: 'none',
    fontSize: '16px',
    backgroundColor: '#2196f3',
    color: '#fff',
    padding: '12px 24px',
    borderRadius: '4px',
  },
}

const BrokenLink = ({ classes }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  DocumentTitleChanger(rtl ? 'خطأ ٤٠٤' : 'Error 404')
  return (
    <div className={classes.root}>
      <ErrorIcon
        className={classes.icon}
        style={{ fontSize: 120, color: '#f44336' }}
      />
      <Typography variant='h1' className={classes.heading}>
        {rtl ? '٤٠٤' : '404'}
      </Typography>
      <Typography variant='body1' className={classes.message}>
        {rtl
          ? 'عفوًا! لم يتم العثور على الصفحة التي طلبتها.'
          : 'Oops! The page you requested could not be found.'}
      </Typography>
      <Button
        variant='contained'
        className={classes.button}
        style={{ backgroundColor: '#2196f3' }}
        component={Link}
        to='/'
      >
        {rtl ? 'العودة إلى الصفحة الرئيسية' : 'Go back to homepage'}
      </Button>
    </div>
  )
}

const WrappedBrokenLink = withStyles(styles)(BrokenLink)

export default WrappedBrokenLink
