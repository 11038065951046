import React from 'react'
import NewRoomDiscussionNotif from './RoomNotifDisplyModelComps/NewRoomDiscussionNotif'
import RoomDiscussionMenitonedNotif from './RoomNotifDisplyModelComps/RoomDiscussionMenitonedNotif'

const RoomNotifDisplyModel = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const mssgs = [
    {
      relation: 'creator',
      comp: [],
    },
    {
      relation: 'mentioned',
      comp: [<RoomDiscussionMenitonedNotif {...notifProps} />],
    },
    {
      relation: 'member',
      comp: [<NewRoomDiscussionNotif {...notifProps} />],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.find((mssg) => mssg.relation === notification.relation).comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default RoomNotifDisplyModel
