import React, { useContext, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import NavigatorGenerator from '../../../../../navigatorCardsModel/NavigatorGenerator'
import { AuthContext } from '../../../../../../context/AuthContext'
import axios from 'axios'
import { useState } from 'react'
import BlocksLoader from '../../../../../smallComponents/BlocksLoader'
import MemberAvatarName from '../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/MemberProfileComponents/MemberAvatarName'
import DefaultImageGrapper from '../../../../../smallComponents/DefaultImageGrapper'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    marginBottom: 10,
  },
  '@media (max-width: 600px)': {
    wrapper: {
      flexDirection: 'column',
    },
  },
})

const UsersContributorProfile = ({
  classes,
  member: memberI,
  user: memberII,
  DCGProps,
}) => {
  const { user } = useContext(AuthContext)
  const [sharedResources, setSharedResources] = useState([])
  const [commonProjects, setCommonProjects] = useState([])
  const [commonData, setCommonData] = useState({})
  const member = memberI ? memberI : memberII
  const [isLoading, setIsLoading] = useState(true)
  const { isDarkModeActive } = DCGProps

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const res = await axios.post(`/resources/usersSharedResources`, {
          usersIds: [user?._id, member?._id],
        })
        const resProj = await axios.get(
          `/projects/o/usersCommonProjects/?userIdI=${user?._id}&userIdII=${member?._id}`,
          {
            usersIds: [user?._id, member?._id],
          }
        )
        const resData = await axios.get(
          `/users/contributors/profile/commonData/?userIdI=${user?._id}&userIdII=${member?._id}`
        )
        setCommonProjects(resProj.data.projects)
        setSharedResources(res.data.resources)
        setCommonData(resData.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [user?._id, member?._id])
  const lang = i18next.language
  const rtl = lang === 'ar'
  const navigatorsArray = [
    {
      headerText: rtl ? 'موارد متشاركة' : 'Shared Resources',
      unitsArray: sharedResources,
      elementsImages: sharedResources?.map((res) => ({
        id: res._id,
        image: res.images[0],
      })),
    },
    {
      headerText: rtl ? 'مشروعات مشتركة' : 'Common Projects',
      elementsImages: commonProjects?.map((project) => ({
        id: project._id,
        image: project?.projectPhoto || DefaultImageGrapper('noProjectAvatar'),
      })),
      unitsArray: commonProjects,
      modelUnitProps: {
        pathName: (pro) => `/projectProfile/${pro?._id}`,
      },
    },
    {
      headerText: rtl ? 'الأفكار المشتركة' : 'Common Ideas',
      unitsArray: commonData?.ideas,
      elementsImages: commonData?.ideas?.map((res) => ({
        id: res._id,
        image: res.images[0],
      })),
      modelUnitProps: {},
      hideNavigator: commonData?.ideas?.length === 0,
    },
    {
      headerText: rtl ? 'المتطلبات المشتركة' : 'Common Inquiries',
      unitsArray: commonData?.inquiries,
      elementsImages: commonData?.inquiries?.map((res) => ({
        id: res._id,
        image: res.images[0],
      })),
      modelUnitProps: {},
      hideNavigator: commonData?.inquiries?.length === 0,
    },
    {
      headerText: rtl ? 'الرؤى العالمية المشتركة' : 'Common Global Inquiries',
      unitsArray: commonData?.globalInquiries,
      modelUnitProps: {},
      elementsImages: commonData?.globalInquiries?.map((res) => ({
        id: res._id,
        image: res.images[0],
      })),
      hideNavigator: commonData?.globalInquiries?.length === 0,
    },
    {
      headerText: rtl ? 'القنوات المشتركة' : 'Common Channels',
      unitsArray: commonData?.channels,
      modelUnitProps: {},
      hideNavigator: commonData?.channels?.length === 0,
    },
  ]

  return isLoading ? (
    <BlocksLoader {...DCGProps} />
  ) : (
    <article
      className={classes.wrapper}
      style={{ color: isDarkModeActive && 'white' }}
    >
      <MemberAvatarName member={{ ...member, userId: member?._id }} />
      <NavigatorGenerator
        DCGProps={DCGProps}
        navigatorsArray={navigatorsArray}
      />
    </article>
  )
}

const WrappedUsersContributorProfile = withStyles(styles)(
  UsersContributorProfile
)

export default WrappedUsersContributorProfile
