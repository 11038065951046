import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(15, 41, 145, 0.6)',
    color: theme.palette.common.white,
    width: '100%',
  },
}))

const PostAdCostView = ({ adCampaign }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography
        style={{
          fontFamily:
            "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
        }}
        variant='h5'
      >{`${adCampaign.cost || 0}$`}</Typography>
    </Box>
  )
}

export default PostAdCostView
