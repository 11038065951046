import React from 'react'
import withStyles from '@mui/styles/withStyles'
import '../skeleton.css'
import FreeBar from './FreeBar'

const styles = () => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minWidth: '270px',
    justifyContent: 'center',
    alignItems: 'center',
    animation: 'skeleton 2s ease infinite alternate',
  },
  left: {
    height: 80,
    width: '100%',
    backgroundColor: '#313131',
    borderRadius: 20,
  },
  notificationUnit: {
    margin: '10px',
    borderRadius: '10px !important',
    minWidth: 150,
  },
})

const NotificationsSkeleton = ({ classes }) => {
  return (
    <div className={classes.notificationUnit}>
      <div className={classes.listItem}>
        <div className={classes.left}>
          <div
            style={{
              margin: '10px 10px 5px 10px',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <div
              style={{
                width: 30,
                height: 30,
                animation: 'skeleton 0.5s ease infinite alternate',
                background: 'rgba(147,165,194)',
                borderRadius: '50%',
                margin: 'auto 5px',
              }}
            ></div>
            <FreeBar height={15} width={'30%'} />
          </div>
          <div style={{ margin: 5 }}>
            <FreeBar height={30} width={'80%'} />
          </div>
        </div>
      </div>
    </div>
  )
}

const WrappedNotificationsSkeleton = withStyles(styles)(NotificationsSkeleton)

export default WrappedNotificationsSkeleton
