import i18next from "i18next"

const newSharedResourceConfig = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'اسم المورد' : 'Name Your Resource',
    },
    {
      title: 'Description',
      formComponentType: 'text',
      value: '',
      placeholder: rtl
        ? 'ضع وصفاً يساعد على استخدام المورد بشكل فعّال'
        : 'Describe your resource',
      multiLine: true,
      minRows: 5,
    },
    {
      title: 'Potential_Uses',
      formComponentType: 'text',
      value: '',
      placeholder: rtl
        ? 'تحدث عن الإستخدامات المحتلمة'
        : 'Discuss potential uses',
      multiLine: true,
      minRows: 5,
    },
  ]
}

export default newSharedResourceConfig
