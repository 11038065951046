import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const Substitutes = () => {
  const { t } = useTranslation('Substitutes')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 22,
    category: t('category_ms22'),
    title: t('title_ms22'),
    subTitle: t('subTitle_ms22'),
    main: t('main_ms22'),
    how: t('how_ms22'),
    usedInModels: [],
    tags: rtl
      ? [
          'مخاطر العمل',
          'تهديدات بديلة',
          'تحليل السوق',
          'دوافع العملاء',
          'حلول مبتكرة',
          'مشهد تنافسي',
          'أسعار جذابة',
          'تكيف السوق',
        ]
      : [
          'Business Risk',
          'Substitute Threats',
          'Market Analysis',
          'Customer Motivations',
          'Innovative Solutions',
          'Competitive Landscape',
          'Attractive Pricing',
          'Market Adaptation',
        ],
    examples: t('examples_ms22'),
    hashTagType: 'markStraAspect',
  }
}

export default Substitutes
