import React from 'react'
import MemberGotOut from './ChannelNotificationViewModelComps/MemberGotOut'
import NewChannelAdmissionRequestReply from './ChannelNotificationViewModelComps/NewChannelAdmissionRequestReply'
import NewChannelPost from './ChannelNotificationViewModelComps/NewChannelPost'
import ChannelMention from './ChannelNotificationViewModelComps/ChannelMention'

const ChannelNotificationViewModel = ({ notifProps }) => {
  const { isDarkModeActive, notification } = notifProps
  const channel = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'channel'
  )
  const notifPropsII = {
    ...notifProps,
    channel,
  }
  const mssgs = [
    {
      relation: 'visitor',
      comp: [<NewChannelAdmissionRequestReply {...notifPropsII} />],
    },
    {
      relation: 'holders',
      comp: [
        <MemberGotOut {...notifProps} />,
        <NewChannelPost {...notifProps} />,
        <ChannelMention {...notifProps} />,
      ],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default ChannelNotificationViewModel
