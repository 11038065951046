import { Divider } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import moment from 'moment'
import TextEngine from '../../../textProcessingEngine/TextEngine'
import i18next from 'i18next'

const styles = () => ({
  textWrapper: {
    fontStyle: 'oblique',
  },
  topHeader: {
    fontSize: 11,
    marginBottom: 10,
  },
})

const ModificationUnitBody = ({
  classes,
  modification,
  isDarkModeActive,
  DCGProps,
}) => {
  const [user, setUser] = useState()
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${modification?.userId}`)
        setUser(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    if (modification?.userId) fetchUser()
  }, [modification?.userId])
  const lang = i18next.language
  const rtl = lang === 'ar'
  const date = moment(modification.createdAt).format('MMMM Do YYYY, h:mm a')
  return (
    <>
      <section className={classes.topHeader}>
        {rtl ? (
          <>
            طلب {user?.firstName + ' ' + user?.lastName} التعديل {date}
          </>
        ) : (
          <>
            Modification Requested on {date} by{' '}
            {user?.firstName + ' ' + user?.lastName}
          </>
        )}
      </section>
      <section className={classes.textWrapper}>
        <TextEngine
          textContent={modification?.Content || ''}
          textPackage={modification}
          {...DCGProps}
        />
      </section>
      <Divider
        style={{ marginTop: 5, background: isDarkModeActive && 'white' }}
      />
    </>
  )
}

const WrappedModificationUnitBody = withStyles(styles)(ModificationUnitBody)

export default WrappedModificationUnitBody
