import React from 'react'
import {
  Contract,
  CompanyLocalProfile,
  JobProfile,
  DepTaskProfile,
  ProductServiceProfile,
  CampaignProfile,
  IssueProfile,
  BusinessModelModel,
  ModelProfile,
  GlobalInquiryProfile,
  IdeaProfile,
  InquiryProfile,
  UsersContributorProfile,
  MemberProfile,
  PartnershipChannelProfile,
  ResearchActivityProfile,
  MarketingStrategyAspectProfile,
  FeedPostWindowProps,
  TaskCardContent,
  JobCardModelContent,
  CampaignCardModelContent,
  ContractCardContent,
  IssueCardContent,
  ResearchAppProfile,
  ResearchConceptProfile,
  ProtocolProfile,
  InvestmentPackageProfile,
  FinStatProfile,
  ProductStrategyProfile,
  ResourceProfile,
} from '.'
import CommentsDialog from '../../commentsSystems/CommentsDialog'
import ManagerNoteProfile from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DeparmentModels/ManagerModelComponents/ManagerNotesFeedComponents/ManagerNoteProfile'
import MeetingProfile from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectInteractionsComponents/ProjectInteractionsDialogContentComps/MeetingsHistoryComps/MeetingProfile'
import PollProfile from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectInteractionsComponents/ProjectInteractionsDialogContentComps/ProjectVotingsComps/PollProfile'
import ProductDesignProfile from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/ProductionDepartmentComps/DesignComponents/ProductDesignProfile'
import ChartedProfile from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectReflectionsComponents/ProjectReflectionsDialogContentComps/ChartedDataComps/ChartedProfile'

const WindowViewerSystemConfigs = (props) => {
  return [
    {
      windowType: 'contract',
      comp: <Contract {...props} />,
      windowCardName: 'contract',
      imageKey: 'contract',
      hashTagType: 'contract',
      StandardCardContent: ContractCardContent,
      backendRoute: 'contracts',
    },
    {
      windowType: 'product',
      comp: <ProductServiceProfile {...props} />,
      windowCardName: 'product',
      imageKey: 'product',
      hashTagType: 'product',
      StandardCardContent: '',
      backendRoute: 'products',
    },
    {
      windowType: 'task',
      comp: <DepTaskProfile {...props} hideSimilarTasks={true} />,
      windowCardName: 'task',
      imageKey: 'task',
      hashTagType: 'task',
      StandardCardContent: TaskCardContent,
      backendRoute: 'tasks',
    },
    {
      windowType: 'job',
      comp: <JobProfile {...props} />,
      windowCardName: 'job',
      imageKey: 'job',
      hashTagType: 'job',
      StandardCardContent: JobCardModelContent,
      backendRoute: 'jobs',
    },
    {
      windowType: 'partnerCompany',
      comp: <CompanyLocalProfile {...props} />,
      windowCardName: 'project',
      imageKey: '',
      hashTagType: 'partnerCompany',
      cardMarkProp: '',
      backendRoute: 'projects',
    },
    {
      windowType: 'inquiryProfile',
      comp: <InquiryProfile {...props} />,
      windowCardName: 'inquiry',
      imageKey: 'inquiry',
      cardMarkProp: 'inquiryStatus',
      hashTagType: 'inquiryProfile',
      backendRoute: 'inquiries',
    },
    {
      windowType: 'resource',
      comp: <ResourceProfile {...props} />,
      windowCardName: 'resource',
      imageKey: 'resource',
      StandardCardContent: '',
      hashTagType: 'resource',
      backendRoute: 'resources',
    },
    {
      windowType: 'globalInquiry',
      comp: <GlobalInquiryProfile {...props} />,
      windowCardName: 'globalInquiry',
      imageKey: 'globalInquiry',
      hashTagType: 'globalInquiry',
      cardMarkProp: 'globalInquiryStatus',
      backendRoute: 'globalInquiries',
    },
    {
      windowType: 'idea',
      comp: <IdeaProfile {...props} />,
      windowCardName: 'idea',
      imageKey: 'idea',
      cardMarkProp: 'ideaStatus',
      hashTagType: 'idea',
      backendRoute: 'ideas',
    },
    {
      windowType: 'issue',
      comp: <IssueProfile {...props} />,
      windowCardName: 'issue',
      imageKey: 'issue',
      StandardCardContent: IssueCardContent,
      hashTagType: 'issue',
      backendRoute: 'issues',
    },
    {
      windowType: 'campaign',
      comp: <CampaignProfile {...props} />,
      windowCardName: 'campaign',
      imageKey: 'campaign',
      StandardCardContent: CampaignCardModelContent,
      hashTagType: 'campaign',
      backendRoute: 'campaigns',
    },
    {
      windowType: 'businessModelTemp',
      comp: <ModelProfile {...props} />,
      windowCardName: 'model',
      imageKey: 'model',
      StandardCardContent: '',
      hashTagType: 'businessModelTemp',
      backendRoute: '',
    },
    {
      windowType: 'businessModel',
      comp: <BusinessModelModel {...props} />,
      windowCardName: '',
      imageKey: '',
      StandardCardContent: '',
      hashTagType: '',
      backendRoute: '',
    },
    {
      windowType: 'feedPost',
      comp: <CommentsDialog {...FeedPostWindowProps(props)} />,
      windowCardName: 'post',
      imageKey: 'post',
      StandardCardContent: '',
      hashTagType: 'feedPost',
      cardMarkProp: '',
      backendRoute: 'posts',
    },
    {
      windowType: 'managerNote',
      comp: <ManagerNoteProfile {...props} />,
      windowCardName: 'note',
      imageKey: 'post',
      StandardCardContent: '',
      hashTagType: 'managerNote',
      cardMarkProp: '',
      backendRoute: 'managerNotes',
    },
    {
      windowType: 'userContributor',
      comp: <UsersContributorProfile {...props} />,
      windowCardName: 'member',
      imageKey: '',
      StandardCardContent: '',
      hashTagType: 'userContributor',
      backendRoute: 'users/oneUser',
    },
    {
      windowType: 'projectContributor',
      comp: <MemberProfile {...props} />,
      windowCardName: 'member',
      imageKey: '',
      StandardCardContent: '',
      hashTagType: 'projectContributor',
      backendRoute: 'projectContributors',
    },
    {
      windowType: 'markStraAspect',
      comp: <MarketingStrategyAspectProfile {...props} />,
      windowCardName: 'aspect',
      imageKey: 'markStraAspect',
      StandardCardContent: '',
      hashTagType: 'markStraAspect',
      backendRoute: '',
    },
    {
      windowType: 'channel',
      comp: <PartnershipChannelProfile {...props} />,
      windowCardName: 'channel',
      imageKey: 'channel',
      StandardCardContent: '',
      hashTagType: 'channel',
      backendRoute: 'channels',
    },
    {
      windowType: 'researchActivity',
      comp: <ResearchActivityProfile {...props} />,
      windowCardName: 'activity',
      imageKey: 'researchActivity',
      cardMarkProp: 'activityPhase',
      hashTagType: 'researchActivity',
      backendRoute: 'researchActivities',
    },
    {
      windowType: 'researchConcept',
      comp: <ResearchConceptProfile {...props} />,
      windowCardName: 'concept',
      imageKey: 'researchConcept',
      StandardCardContent: '',
      hashTagType: 'researchConcept',
      backendRoute: 'researchConcepts',
    },
    {
      windowType: 'researchApp',
      comp: <ResearchAppProfile {...props} />,
      windowCardName: 'application',
      imageKey: 'researchApp',
      StandardCardContent: '',
      hashTagType: 'researchApp',
      backendRoute: 'researchApps',
    },
    {
      windowType: 'investPack',
      comp: <InvestmentPackageProfile {...props} />,
      windowCardName: 'pack',
      imageKey: 'investPack',
      hashTagType: 'investPack',
      backendRoute: 'investmentPackages',
    },
    {
      windowType: 'productStrategy',
      comp: <ProductStrategyProfile {...props} />,
      windowCardName: 'strategy',
      imageKey: 'product',
      hashTagType: 'productStrategy',
      cardMarkProp: '',
      backendRoute: 'marketStrategies',
    },
    {
      windowType: 'protocol',
      comp: <ProtocolProfile {...props} rescueProtocol />,
      windowCardName: 'protocol',
      imageKey: 'protocol',
      hashTagType: 'protocol',
      cardMarkProp: '',
      backendRoute: 'protocols',
    },
    {
      windowType: 'finStat',
      comp: <FinStatProfile {...props} />,
      windowCardName: 'finStat',
      imageKey: 'finStat',
      hashTagType: 'finStat',
      cardMarkProp: '',
      backendRoute: 'financialStatments',
    },
    {
      windowType: 'productDesign',
      comp: <ProductDesignProfile {...props} />,
      windowCardName: 'design',
      imageKey: 'product',
      hashTagType: 'productDesign',
      cardMarkProp: '',
      backendRoute: 'productDesigns',
    },
    {
      windowType: 'meeting',
      comp: <MeetingProfile {...props} />,
      windowCardName: 'meeting',
      imageKey: 'meeting',
      hashTagType: 'meeting',
      cardMarkProp: '',
      backendRoute: 'meetings',
    },
    {
      windowType: 'poll',
      comp: <PollProfile {...props} />,
      windowCardName: 'poll',
      imageKey: 'poll',
      hashTagType: 'poll',
      cardMarkProp: '',
      backendRoute: 'polls',
    },
    {
      windowType: 'chartedData',
      comp: <ChartedProfile {...props} />,
      windowCardName: 'charted',
      imageKey: 'charted',
      hashTagType: 'chartedData',
      cardMarkProp: '',
      backendRoute: 'chartedData',
    },
  ]
}

export default WindowViewerSystemConfigs
