import React from 'react'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const NewResourceContractingReqNotif = (props) => {
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? `لديك طلب جديد للمشاركة فى موردك`
              : `You have new request for sharing in your resource`
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default NewResourceContractingReqNotif
