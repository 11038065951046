import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const SoftwareLicensing = () => {
  const { t } = useTranslation('SoftwareLicensing')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 81,
    title: t('title_bm81'),
    subTitle: t('subTitle_bm81'),
    main: t('main_bm81'),
    when: t('when_bm81'),
    who: t('who_bm81'),
    what: t('what_bm81'),
    how: t('how_bm81'),
    why: t('why_bm81'),
    examples: rtl
      ? [
          'مايكروسوفت - تأسست عام 1975 - الولايات المتحدة',
          'Adobe Systems - تأسست عام 1982 - الولايات المتحدة',
          'أوتوديسك - تأسست عام 1982 - الولايات المتحدة',
          'IBM - تأسست عام 1911 - الولايات المتحدة',
          'أوراكل - تأسست عام 1977 - الولايات المتحدة',
          'SAP SE - تأسست عام 1972 - ألمانيا',
          'Citrix Systems - تأسست عام 1989 - الولايات المتحدة',
          'Symantec - تأسست عام 1982 - الولايات المتحدة',
          'مكافي - تأسست عام 1987 - الولايات المتحدة',
          'Trend Micro - تأسست عام 1988 - اليابان',
        ]
      : [
          'Microsoft - founded 1975 - United States',
          'Adobe Systems - founded 1982 - United States',
          'Autodesk - founded 1982 - United States',
          'IBM - founded 1911 - United States',
          'Oracle - founded 1977 - United States',
          'SAP SE - founded 1972 - Germany',
          'Citrix Systems - founded 1989 - United States',
          'Symantec - founded 1982 - United States',
          'McAfee - founded 1987 - United States',
          'Trend Micro - founded 1988 - Japan',
        ],
    tags: rtl
      ? ['الاحتفاظ بالعملاء', 'المشكلات القانونية', 'الحلول القيّمة']
      : ['Customer Retention', 'Legal Issues', 'Valuable Solutions'],
    considerations: t('considerations_bm81'),
    combinations: [],
    hashTagType: 'businessModelTemp',
  }
}

export default SoftwareLicensing
