import React from 'react'
import { StyleSheet, View } from '@react-pdf/renderer'
import GPDFBodyModelRight from './GPDFBodyModelRight'
import ElementCreator from './ElementCreator'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  },
  leftColumn: {
    flexDirection: 'column',
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
})

const GPDFDBodyModel = ({
  GPDFBodyModelTop,
  GPDFBodyModelRightProps,
  GPDFBodyModelImageBottom,
  button,
  GPDFBodyModelRightCo,
  hideCreator,
}) => {
  return (
    <>
      {GPDFBodyModelTop}
      {!hideCreator && <ElementCreator button={button} />}
      <View style={styles.container}>
        {GPDFBodyModelImageBottom && (
          <View style={styles.leftColumn}>{GPDFBodyModelImageBottom}</View>
        )}
        {GPDFBodyModelRightCo ? (
          GPDFBodyModelRightCo
        ) : (
          <GPDFBodyModelRight {...GPDFBodyModelRightProps} />
        )}
      </View>
    </>
  )
}

export default GPDFDBodyModel
