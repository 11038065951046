import React from 'react'
import NewFormDialogModel from '../../../formsSystem/NewFormDialogModel'
import DepNewContributorForm from './DepContributorAdditionComps/DepNewContributorForm'
import i18next from 'i18next'

const DepContributorAddition = (props) => {
  const { newDepContribClose, isNewDepContribOpen } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NewFormDialogModel
      NewFormComponent={<DepNewContributorForm {...props} />}
      newFormDialogClose={newDepContribClose}
      isNewFormDialogOpen={isNewDepContribOpen}
      {...props}
      clickAwayState={true}
      dialogTitle={rtl ? 'أضف الى القسم' : 'Add to Department'}
    />
  )
}

export default DepContributorAddition
