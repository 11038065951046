import React, { useContext, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import Media from 'react-media'
import AspectStructureUnitTopPC from './AspectStructureUnitComps/AspectStructureUnitTopPC'
import AspectStructureUnitTopMob from './AspectStructureUnitComps/AspectStructureUnitTopMob'
import axios from 'axios'
import HashTagElementsRefiner from '../../../../../../smallComponents/HashTagElementsRefiner'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../context/AuthContext'
import i18next from 'i18next'

const styles = () => ({
  container: {
    margin: 10,
  },
  topWrapper: {
    position: 'relative',
    with: '100%',
    minHeight: 70,
    paddingTop: 20,
    marginBottom: 10,
  },
  '@media (max-width: 600px)': {
    container: {
      margin: 0,
    },
  },
})

const AspectStructureUnit = ({
  classes,
  subAspect,
  DCGProps,
  aspect,
  aspectMods,
  customStrategy,
  externalLinkMode,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const { project } = DCGProps
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(aspectMods) ||
      HashTagElementsRefiner(customStrategy) ||
      []
  )
  const infoArray = subAspect?.customModel
    ? subAspect?.infoArray
    : [
        {
          title: rtl
            ? `تخصيص ${t(subAspect.title)}`
            : `Customize ${subAspect.title}`,
          titleValue: subAspect?.custom,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
      ]

  const [infoData, setInfoData] = useState({})
  const newInfoData = FormDataLangModulator(infoData, t)
  const onSave = async () => {
    try {
      subAspect?.customModel
        ? await axios.patch(`/projectsMarketingStratgies/${project?._id}`, {
            ...newInfoData,
            chosenHashTagElements,
          })
        : await axios.patch(
            `/marketingStrategiesCustomizations/${aspect?.id}/${project?._id}`,
            {
              [subAspect.title.toLowerCase()]:
                infoData[
                  rtl
                    ? `تخصيص ${t(subAspect.title)}`.split(' ').join('')
                    : `Customize ${subAspect.title}`.split(' ').join('')
                ],
              chosenHashTagElements,
            }
          )
    } catch (error) {
      console.log(error)
    }
  }

  const mediaComponents = [
    <AspectStructureUnitTopPC subAspect={subAspect} DCGProps={DCGProps} />,
    <AspectStructureUnitTopMob subAspect={subAspect} DCGProps={DCGProps} />,
  ]

  const gridProps = {
    hideEditingTools: user?._id !== project?.userId,
    hideHeader: true,
    infoArray,
    setInfoData,
    infoData,
    setChosenHashTagElements,
    chosenHashTagElements,
    onSave,
    ...DCGProps,
  }
  return (
    <article className={classes.container}>
      <section className={classes.topWrapper}>
        <Media query='(max-width: 600px)'>
          {(matches) => {
            return matches ? mediaComponents[1] : mediaComponents[0]
          }}
        </Media>
      </section>
      <section
        style={{
          display:
            (subAspect?.hideCustom || !project || externalLinkMode) && 'none',
        }}
      >
        <EditableGridModel gridProps={gridProps} />
      </section>
    </article>
  )
}

const WrappedAspectStructureUnit = withStyles(styles)(AspectStructureUnit)

export default WrappedAspectStructureUnit
