import axios from 'axios'
import React, { useEffect, useState } from 'react'
import EditableGridModel from '../../../../../../../formsSystem/EditableGridModel'
import TitleHeader from '../../../../../../../smallComponents/Header'
import TextEngineToolsFooter from '../../../../../../../smallComponents/TextEngineToolsFooter'
import i18next from 'i18next'

const ProductStrategyPreparation = ({
  chosenCards,
  strategy,
  DCGProps,
  setProjectMarketStrategies,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    strategy?.chosenHashTagElements || []
  )
  const infoArray = chosenCards?.map((card) => ({
    title: card?.title,
    titleValue:
      (strategy?.details &&
        strategy?.details[card?.title?.split(' ')?.join('')]) ||
      '',
    allowEmpty: true,
    placeholder: card.placeholder,
    wideMode: true,
    minRows: 6,
  }))

  const initailState = (configs) => {
    let tempObj = {}
    configs.forEach((config) => {
      tempObj[config.title.split(' ').join('')] = config.titleValue
    })
    return tempObj
  }
  const [infoData, setInfoData] = useState(initailState(infoArray))
  useEffect(() => {
    setInfoData(initailState(infoArray))
  }, [chosenCards?.length])
  const modelTempsIds = chosenCards.map((card) => card?.id)
  const onSave = async () => {
    if (strategy?._id)
      try {
        const res = await axios.patch(`/marketStrategies/${strategy?._id}`, {
          details: infoData,
          chosenHashTagElements,
          modelTempsIds,
        })
        setProjectMarketStrategies((prods) => [
          ...prods.filter((p) => p._id !== strategy?._id),
          res.data.productStrategy,
        ])
      } catch (error) {
        console.log(error)
      }
  }

  const gridProps = {
    ...DCGProps,
    infoArray,
    setChosenHashTagElements,
    chosenHashTagElements,
    hideHeader: true,
    setInfoData,
    infoData,
    onSave,
    departmentId: 1,
  }
  return (
    <div>
      <TitleHeader headerText={rtl ? 'إعداد' : 'Prepartion'} />
      <EditableGridModel gridProps={gridProps} />
      <TextEngineToolsFooter {...DCGProps} />
    </div>
  )
}

export default ProductStrategyPreparation
