import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const TargetthePoorBusinessModel = () => {
  const { t } = useTranslation('TargetthePoorBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 55,
    title: t('title_bm55'),
    subTitle: t('subTitle_bm55'),
    main: t('main_bm55'),
    when: t('when_bm55'),
    who: t('who_bm55'),
    what: t('what_bm55'),
    how: t('how_bm55'),
    why: t('why_bm55'),
    examples: rtl
      ? [
          'بنك جرامين - تأسس 1983 - بنغلاديش',
          'Hindustan Unilever - تأسست عام 1933 - الهند',
          'تاتا - تأسست عام 1945 - الهند',
          'وول مارت - تأسست عام 1962 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Grameen Bank - Founded 1983 - Bangladesh',
          'Hindustan Unilever - Founded 1933 - India',
          'Tata - Founded 1945 - India',
          'Wal-Mart - Founded 1962 - USA',
        ],
    tags: rtl
      ? ['المسؤولية الاجتماعية', 'العلاقات العامة']
      : ['Social Responsibility', 'Public Relations'],
    considerations: t('considerations_bm55'),
    combinations: ['$LBM49', '$LBM56'],
    hashTagType: 'businessModelTemp',
  }
}

export default TargetthePoorBusinessModel
