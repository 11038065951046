import i18next from "i18next"

const NewInquiryFormsConfigs = () => {
  const categories = ['Planning', 'Donation', 'Personal Guide', 'Special Help']
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'عنوان المتطلب' : 'Inquiry Title',
      required: true,
    },
    {
      title: 'Description',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'وصف المتطلب' : 'Inquiry Description',
      multiLine: true,
      minRows: 4,
    },

    {
      title: 'Offered_Back',
      formComponentType: 'text',
      value: '',
      placeholder: rtl
        ? 'ماذا تعرض فى المقابل'
        : 'What do your offer for who will do this task?',
      multiLine: true,
      minRows: 4,
    },
    {
      title: 'Basic_Skills',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      placeholder: rtl ? 'المهارات المطلوبة' : 'Define Basic Skills..',
      minRows: 4,
    },
  ]
}

export default NewInquiryFormsConfigs
