import React from 'react'
import TextDirectionDetector from '../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'

const CentralTitle = ({ title }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        fontSize: 20,
        fontWeight: 500,
        marginTop: 15,
        direction: TextDirectionDetector(title)
      }}
    >
      {title}
    </div>
  )
}

export default CentralTitle
