import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'
import { useTranslation } from 'react-i18next'

const InvestPackPDFBody = ({ button }) => {
  const { element } = button.PDFGenProps
  const { t } = useTranslation()
  const configs = Array.from({ length: 8 }, (_, index) => ({
    title: `new_invest_title_${index + 1}`,
    titleValue:
      element[
        t(`new_invest_title_${index + 1}`, { lng: 'en' })
          .split(' ')
          .join('')
      ],
    disableTrans: true,
  }))
  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
        mainContent: element?.Description,
      }}
      button={button}
    />
  )
}

export default InvestPackPDFBody
