import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const SoftwareAsAService = () => {
  const { t } = useTranslation('SoftwareAsAService')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 76,
    title: t('title_bm76'),
    subTitle: t('subTitle_bm76'),
    main: t('main_bm76'),
    when: t('when_bm76'),
    who: t('who_bm76'),
    what: t('what_bm76'),
    how: t('how_bm76'),
    why: t('why_bm76'),
    examples: rtl
      ? [
          'Salesforce - 1999 - الولايات المتحدة',
          'Zoom - 2011 - الولايات المتحدة',
          'سلاك - 2013 - الولايات المتحدة',
          'Atlassian - 2002 - أستراليا',
          'HubSpot - 2006 - الولايات المتحدة',
          'DocuSign - 2003 - الولايات المتحدة',
          'Zendesk - 2007 - الولايات المتحدة',
          'Shopify - 2006 - كندا',
          'أسانا - 2008 - الولايات المتحدة',
          'شريط - 2010 - الولايات المتحدة',
        ]
      : [
          'Salesforce - 1999 - United States',
          'Zoom - 2011 - United States',
          'Slack - 2013 - United States',
          'Atlassian - 2002 - Australia',
          'HubSpot - 2006 - United States',
          'DocuSign - 2003 - United States',
          'Zendesk - 2007 - United States',
          'Shopify - 2006 - Canada',
          'Asana - 2008 - United States',
          'Stripe - 2010 - United States',
        ],
    tags: rtl
      ? [
          'تسليم البرمجيات',
          'حوسبة سحابية',
          'قابلية التوسع',
          'المرونة',
          'التحديثات التلقائية',
          'إمكانية الوصول',
        ]
      : [
          'Software Delivery',
          'Cloud Computing',
          'Scalability',
          'Flexibility',
          'Automatic Updates',
          'Accessibility',
        ],
    considerations: t('considerations_bm76'),
    combinations: ['$LBM22', '$LBM75', '$LBM74', '$LBM54', '$LBM81'],
    hashTagType: 'businessModelTemp',
  }
}

export default SoftwareAsAService
