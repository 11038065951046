import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const OrchestratorBusinessModel = () => {
  const { t } = useTranslation('OrchestratorBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 39,
    title: t('title_bm39'),
    subTitle: t('subTitle_bm39'),
    main: t('main_bm39'),
    when: t('when_bm39'),
    who: t('who_bm39'),
    what: t('what_bm39'),
    how: t('how_bm39'),
    why: t('why_bm39'),
    examples: rtl
      ? [
          'نايك - تأسست عام 1964 - الولايات المتحدة الأمريكية',
          'Airtel - تأسست عام 1995 - اتصالات',
          'Li & Fung - تأسست عام 1906 - الصين',
        ]
      : [
          'Nike - Founded 1964 - USA',
          'Airtel - Founded 1995 - Telecommunication',
          'Li & Fung - Founded 1906 - China',
        ],
    tags: rtl
      ? ['تكلفة منخفضة', 'فوز للجميع', 'منسق الشبكة']
      : ['Low Cost', 'Win-win', 'Network Orchestrator'],
    considerations: t('considerations_bm39'),
    combinations: ['$LBM35'],
    hashTagType: 'businessModelTemp',
  }
}

export default OrchestratorBusinessModel
