import React from 'react'
import HomeFeedsSystemModels from './FeedsSystemComponents/HomeFeedsSystemModels'
import ProjectFeedsSystemModels from './FeedsSystemComponents/ProjectFeedsSystemModels'

const FeedsSystem = ({ feedsSystemProps }) => {
  const { post } = feedsSystemProps
  return  post?.feedsSystemCategory === 'home' &&
    // this condition is for the new project post
    post?.postFeedIndex !== 2 ? (
    <HomeFeedsSystemModels feedsSystemProps={feedsSystemProps} />
  ) : post?.feedsSystemCategory === 'page' || post?.postFeedIndex === 2 ? (
    <ProjectFeedsSystemModels feedsSystemProps={feedsSystemProps} />
  ) : null
}

export default FeedsSystem
