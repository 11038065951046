import axios from 'axios'
import React, { useContext, useState } from 'react'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import FreePostGenerator from '../../../../../../../feedsSystem/FreePostGenerator'
import EditableGridModel from '../../../../../../../formsSystem/EditableGridModel'
import InfoDataIntialStateGen from '../../../../../../../formsSystem/InfoDataIntialStateGen'
import PostProps from '../../../../../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import ShareSystem from '../../../../../../../sharingSystem/ShareSystem'
import CustomCircularProgress from '../../../../../../../smallComponents/CustomCircularProgress'
import HashTagElementsRefiner from '../../../../../../../smallComponents/HashTagElementsRefiner'
import { useTranslation } from 'react-i18next'
import FormDataLangModulator from '../../../../../../../formsSystem/FormDataLangModulator'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import i18next from 'i18next'

const RescueProtocol = ({ postMode, protocol, DCGProps }) => {
  const { project, isDarkModeActive } = DCGProps
  const { user } = useContext(AuthContext)
  const [createdProtocol, setCreatedProtocol] = useState(null)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const [isCreating, setIsCreating] = useState(false)
  const [isClicked, setIsCliocked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { shareDialogOpener, shareDialogClose, isShareDialogOpen } = PostProps(
    {},
    {
      shareSystemProps: true,
    }
  )

  const rescueInfo = [
    {
      title: 'Title',
      titleValue: protocol?.Title,
      allowEmpty: true,
      wideMode: true,
    },
    {
      title: 'The_Situation',
      titleValue: protocol?.TheSituation,
      allowEmpty: true,
      wideMode: true,
      minRows: 6,
    },
    {
      title: 'Expectation',
      titleValue: protocol?.Expectation,
      allowEmpty: true,
      wideMode: true,
      minRows: 6,
    },
  ]
  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(rescueInfo))
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(protocol) || []
  )
  const newInfoData = FormDataLangModulator(infoData, t)
  const gridProps = {
    hideEditingTools: user?._id !== project?.userId,
    headerText: rtl ? 'بروتوكول إنقاذ' : 'Rescue Protocol',
    infoArray: rescueInfo,
    setInfoData,
    infoData,
    chosenHashTagElements,
    setChosenHashTagElements,
    ...DCGProps,
  }

  const shareSystemProps = {
    shareTypeIndex: 2,
    post: FreePostGenerator('protocol', [], createdProtocol),
    isShareDialogOpen,
    shareDialogClose,
    ...DCGProps,
    departmentId: 2,
    user: project,
  }

  const buttonsArray = postMode
    ? []
    : [
        {
          title: isClicked
            ? rtl
              ? 'تم الإنشاء'
              : 'Created'
            : rtl
            ? isCreating
              ? 'جارى الإنشاء'
              : 'إنشاء'
            : isCreating
            ? 'Creating...'
            : 'Create',
          funs: async () => {
            setIsCreating(true)
            setIsLoading(true)
            try {
              setIsCliocked(true)
              const res = await axios.post(`/protocols`, {
                ...newInfoData,
                projectId: project?._id,
                chosenHashTagElements,
              })
              setCreatedProtocol({
                ...res.data.protocol,
                id: res.data.protocol?._id,
              })
            } catch (error) {
              console.log(error)
            }
            setIsCreating(false)
            setIsLoading(false)
          },
          disabled: isClicked,
        },
        {
          title:
            isClicked && isLoading ? (
              <CustomCircularProgress />
            ) : rtl ? (
              'نشر'
            ) : (
              'Publish'
            ),
          funs: shareDialogOpener,
          disabled: !createdProtocol && !isClicked,
        },
      ]

  return (
    <div style={{ color: isDarkModeActive ? 'white' : 'black' }}>
      <EditableGridModel gridProps={gridProps} />
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
      <ShareSystem shareSystemProps={shareSystemProps} />
    </div>
  )
}

export default RescueProtocol
