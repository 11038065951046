import i18next from "i18next"

const NewAppFormConfigs = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
    },
    {
      title: 'Content',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'المحتوى' : 'Content',
      multiLine: true,
      minRows: 5,
    },
  ]
}

export default NewAppFormConfigs
