import { Avatar } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import moment from 'moment'
import { Link } from 'react-router-dom'
import i18next from 'i18next'

const styles = () => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
  dateWrapper: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
})

const RequestingUserModel = ({ classes, card, requestsUsers, DCGProps }) => {
  const { isDarkModeActive } = DCGProps
  const request = card
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <section className={classes.container}>
      <section className={classes.wrapper}>
        <Link to={`/profile/${request?.userId}`}>
          <Avatar
            className={classes.avatar}
            src={requestingUser?.profilePicture}
            sx={{ marginRight: !rtl && '10px', marginLeft: rtl && '10px' }}
          />
        </Link>
        <Link to={`/profile/${request?.userId}`}>
          <strong style={{ color: isDarkModeActive ? 'white' : 'black' }}>
            {requestingUser?.firstName + ' ' + requestingUser?.lastName}
          </strong>
        </Link>
      </section>
      <div className={classes.dateWrapper}>
        <span>{moment(request?.createdAt).format('MMMM Do YYYY, h:mm a')}</span>
      </div>
    </section>
  )
}

const WrappedRequestingUserModel = withStyles(styles)(RequestingUserModel)

export default WrappedRequestingUserModel
