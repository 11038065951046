import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

import GPDFDTitle from '../../../PDFTemplatesComps/GeneralPDFDocumentComps/GPDFDTitle'
import GPDFDList, {
  Item,
} from '../../../PDFTemplatesComps/GeneralPDFDocumentComps/GPDFDList'

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Lato Bold',
    fontSize: 11,
    marginBottom: 10,
  },
})

const ProjectsView = ({ name, projects }) => (
  <View>
    <Text style={styles.title}>{name}</Text>
    <GPDFDList>
      {projects.map((project, i) => (
        <Item key={i}>{'   ' + project?.ProjectName}</Item>
      ))}
    </GPDFDList>
  </View>
)

const ContractPDFProjects = ({ projects }) => (
  <View>
    <GPDFDTitle>Contractors</GPDFDTitle>
    <ProjectsView name='Projects' projects={projects} />
  </View>
)

export default ContractPDFProjects
