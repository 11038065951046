import React from 'react'
import { ProfileShareModel } from './'
import i18next from 'i18next'

const HomeShare = ({ shareSystemProps }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <ProfileShareModel
      shareSystemProps={{
        ...shareSystemProps,
        placeholder: rtl ? 'فكر و غيّر عالَمك' : 'Think and Change the World',
        shareOptionsModelType: 'home',
        feedsSystemCategory: 'home',
      }}
    />
  )
}

export default HomeShare
