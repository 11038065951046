import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import ElementRequestsSenders from '../../../../../../../notificationsSystem/ElementRequestsSenders'
import NoPermissionedInteraction from '../../../../../../../notificationsSystem/NoPermissionedInteraction'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const ProductInteractionButtons = ({ postMode, DCGProps, product }) => {
  const { socket } = DCGProps
  const { user } = useContext(AuthContext)
  const senders = ElementRequestsSenders(product?._id || product?.id)
  const [improve, setImporve] = useState(false)
  const [invest, setInvest] = useState(false)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const activeFun = async (subgenre) => {
    const newRequest = {
      userId: user?._id,
      projectId: product?.projectId,
      genre: 'product',
      elementId: product?._id || product?.id,
      subgenre,
      requestData: {
        contributorProfileId: product?.ProductDesignerId,
      },
    }
    try {
      const res = await axios.post('/requests', newRequest)
      socket.current.emit('addNotifUser', product?.ProductDesignerId)
      NotificationCreator({
        associativity: 'product',
        relation: 'creator',
        conditionIndex: 0,
        accompaniedData: {
          requestSubGenre: subgenre,
          projectId: product?.projectId,
          requestId: res.data.request._id,
          elements: [
            {
              hashTagType: product?.hashTagType,
              id: product?._id || product?.id,
              title: product?.ProductName,
              image: product?.image,
            },
          ],
        },
        category: 'contributors',
        userId: product?.ProductDesignerId,
        currentUser: user,
        socket,
        CRNDelete: true,
        element: product,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const buttonsArray = [
    {
      title: rtl ? 'تطوير' : 'Improve',
      funs: () => {
        setImporve(true)
        activeFun('improve')
        toast.success(
          rtl ? 'تم ارسال عرضك بنجاح' : 'Your offer has been sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: improve,
    },
    {
      title: rtl ? 'إستثمر' : 'Invest',
      funs: () => {
        setInvest(true)
        activeFun('invest')
        toast.success(
          rtl ? 'تم ارسال عرضك بنجاح' : 'Your offer has been sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: invest,
    },
  ]
  return senders === undefined ? (
    <NoPermissionedInteraction checking={true} />
  ) : senders?.includes(user?._id) || invest ? (
    <NoPermissionedInteraction />
  ) : (
    <ButtonsSystem
      buttonsArray={buttonsArray}
      hide={
        !postMode ||
        !user?.createdProjects?.includes(product?.projectId) ||
        !user?.contractedProjectsIds?.includes(product?.projectId)
      }
      {...DCGProps}
    />
  )
}

export default ProductInteractionButtons
