import React from 'react'
import { Avatar, Divider } from '@mui/material'
import { RiBuilding2Fill } from 'react-icons/ri'
import withStyles from '@mui/styles/withStyles'
import FreeBar from '../../../../../../../skeleton/SkeletonComponents/FreeBar'
import { Link } from 'react-router-dom'
import moment from 'moment'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  },
  avatar: {
    width: '70px !important',
    height: '70px !important',
    margin: '5px !important',
    cursor: 'pointer !important',
    marginBottom: 10,
  },

  date: {
    fontWeight: 500,
    fontSize: 16,
  },

  '@media (max-width: 600px)': {
    avatar: {
      width: '50px !important',
      height: '50px !important',
    },
  },
})

const TaskProject = ({ classes, project, isDarkModeActive, task }) => {
  return (
    <div className={classes.wrapper}>
      <Link to={`/projectProfile/${project?._id}`}>
        <Avatar className={classes.avatar} src={project?.projectPhoto}>
          <RiBuilding2Fill size={35} />
        </Avatar>
      </Link>
      <Link
        to={`/projectProfile/${project?._id}`}
        style={{
          color: isDarkModeActive ? 'white' : 'black',
          marginBottom: 10,
        }}
      >
        <strong style={{ fontSize: 18 }}>
          {project?.ProjectName ? (
            project?.ProjectName
          ) : (
            <FreeBar height={20} isDarkModeActive={isDarkModeActive} />
          )}
        </strong>
      </Link>
      {/* <Divider
        style={{
          background: isDarkModeActive ? 'white' : 'inherit',
          width: '100%',
        }}
      /> */}
      <span className={classes.date}>{moment(task.createdAt).calendar()}</span>
    </div>
  )
}

const WrappedTaskProject = withStyles(styles)(TaskProject)

export default WrappedTaskProject
