import { useEffect } from 'react'

const FormDataInitialValueExtractor = (config, setFormData) => {
  const formattor = () => {
    let tempObj = {}
    config.forEach((con) => {
      tempObj[con.title.split(' ').join('')] =
        typeof con.value === 'string'
          ? con.value.split('_').join(' ')
          : con.value
    })
    return tempObj
  }
  useEffect(() => {
    setFormData(formattor())
  }, [])
}

export default FormDataInitialValueExtractor
