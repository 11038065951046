import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditableGridModel from '../../../formsSystem/EditableGridModel'
import FormDataLangModulator from '../../../formsSystem/FormDataLangModulator'
import InfoDataIntialStateGen from '../../../formsSystem/InfoDataIntialStateGen'
import HashTagElementsRefiner from '../../../smallComponents/HashTagElementsRefiner'
import ToastifyGeneralError from '../../../smallComponents/ToastifyGeneralError'

const ChannelPurpose = ({
  DCGProps,
  channel,
  setCards,
  departmentId,
  subDepId,
}) => {
  const infoArray = [
    {
      title: 'Title',
      titleValue: channel?.Title,
      allowEmpty: true,
    },
    {
      title: 'Description',
      titleValue: channel?.Description,
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
  ]
  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))
  const { t } = useTranslation()
  const newInfoData = FormDataLangModulator(infoData, t)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(channel) || []
  )
  const onSave = async () => {
    try {
      const res = await axios.patch(`/channels/${channel?._id}`, {
        ...newInfoData,
        chosenHashTagElements,
      })
      if (typeof setCards === 'function')
        setCards((chs) => [
          ...chs.filter((p) => p._id !== channel?._id),
          res.data.channel,
        ])
    } catch (error) {
      console.log(error)
      ToastifyGeneralError(error)
    }
  }

  const gridProps = {
    hideHeader: true,
    infoArray,
    infoData,
    setInfoData,
    ...DCGProps,
    chosenHashTagElements,
    setChosenHashTagElements,
    onSave,
    departmentId,
    subDepId,
  }
  return <EditableGridModel gridProps={gridProps} />
}

export default ChannelPurpose
