import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'

const ProductDesignPDFBody = ({ button }) => {
  const { element } = button.PDFGenProps
  const configs = [
    {
      title: 'Product_Idea',
      titleValue: element?.ProductIdea,
      disableTrans: true,
    },
    {
      title: 'Special_Benefits',
      titleValue: element?.SpecialBenefits,
      disableTrans: true,
    },
    {
      title: 'Unique_Features',
      titleValue: element?.UniqueFeatures,
      disableTrans: true,
    },
    {
      title: 'Pricing_Plan',
      titleValue: element?.PricingPlan,
      disableTrans: true,
    },
    {
      title: 'Limits_and_Liabilities',
      titleValue: element?.LimitsandLiabilities,
      disableTrans: true,
    },
    {
      title: 'Production_and_Delivery',
      titleValue: element?.ProductionandDelivery,
      disableTrans: true,
    },
    {
      title: 'Suppliers',
      titleValue: element?.Suppliers,
      disableTrans: true,
    },
    {
      title: 'Product_Description',
      titleValue: element?.ProductDescription,
      disableTrans: true,
    },
    {
      title: 'Market_Channels',
      titleValue: element?.MarketChannels,
      disableTrans: true,
    },
  ]
  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
      }}
      button={button}
    />
  )
}

export default ProductDesignPDFBody
