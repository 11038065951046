import React from 'react'
import ReactPlayer from 'react-player'
import WindowSize from '../smallComponents/WindowSize'

const LinksPlayerSystem = ({ url }) => {
  const windowSize = WindowSize()
  const isSoundCloud =
    typeof url === 'string' &&
    (url?.startsWith('https://soundcloud.com/') ||
      url?.startsWith('https://on.soundcloud.com/'))
  return (
    url && (
      <ReactPlayer
        url={url}
        controls={true}
        // light={true}
        // playing={true}
        pip={true}
        width={windowSize > 600 ? 'auto' : '100%'}
        height={
          windowSize > 600
            ? isSoundCloud
              ? 100
              : 450
            : isSoundCloud
            ? 90
            : 250
        }
      />
    )
  )
}

export default LinksPlayerSystem
