// src/utils/getUserCountry.js
import axios from 'axios';

const getUserCountry = async () => {
    try {
        const response = await axios.get('https://ipapi.co/json/');
        return response.data.country_code;
    } catch (error) {
        console.error('Error fetching user country:', error);
        return 'US'; // Default to US if there's an error
    }
};

export default getUserCountry;