import React from 'react'
import { MdAdd } from 'react-icons/md'

const SpreadGridHeaderIconComp = ({
  header,
  windowOpener,
  setHeaderIndex,
  i,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <div
        style={{
          fontSize: 18,
        }}
      >
        {header}
      </div>
      <MdAdd
        style={{
          cursor: 'pointer',
          '&:hover': {
            fontSize: 18,
          },
        }}
        size={15}
        onClick={() => {
          windowOpener()
          setHeaderIndex(i)
        }}
      />
    </div>
  )
}

export default SpreadGridHeaderIconComp
