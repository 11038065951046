import React from 'react'
import NotificationCoverModel from '../../../NotificationCoverModel'
import i18next from 'i18next'

const NewReplyNotificationCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `قام ${sender?.name} بالرد على تعليق لك`
    : `${sender?.name} replied to your comment`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default NewReplyNotificationCover
