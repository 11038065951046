import React from 'react'
import AdsManagmentModel from './AdsManagmentModel'
import PostAdsPage from './PostAdsManagementComps/PostAdsPage'
import DocumentTitleChanger from '../../components/smallComponents/DocumentTitleChanger'
import i18next from 'i18next'

const PostAdsManagement = (props) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  DocumentTitleChanger(rtl ? 'صفحة الاعلان' : 'Ad Page')
  return <AdsManagmentModel Component={PostAdsPage} ComponentProps={props} />
}

export default PostAdsManagement
