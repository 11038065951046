import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
  },
  '@media (max-width: 600px)': {
    emptyContainer: {
      justifyContent: 'space-around',
      height: '100vh',
    },
  },
})

const EmptyComponentModel = ({
  classes,
  emptySentence,
  heightShort,
  fontSize,
  fontWeight,
}) => {
  return (
    <article
      className={classes.emptyContainer}
      style={{
        height: `calc(100% - ${heightShort ? heightShort : '0px'})`,
        fontSize: fontSize || 20,
        fontWeight: fontWeight || 600,
      }}
    >
      {emptySentence}
    </article>
  )
}

const WrappedEmptyComponentModel = withStyles(styles)(EmptyComponentModel)

export default WrappedEmptyComponentModel
