import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ContractEndingEventCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={rtl ? 'إلغاء عقد' : 'Contract termination'}
    />
  )
}

export default ContractEndingEventCover
