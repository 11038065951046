import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const DepManagerManagerToolsNewRequestsCover = ({
  notification,
  sender,
  project,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `لديك طلب جديد من ${sender?.name} فى ${project?.name}`
    : `You have new requests form ${sender?.name} in ${project?.name}.`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default DepManagerManagerToolsNewRequestsCover
