import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CentralTitle from '../../../../../../smallComponents/CentralTitle'
import TextEngine from '../../../../../../textProcessingEngine/TextEngine'
import i18next from 'i18next'

const IssueContractGeneralAgreement = (props) => {
  const { issue, DCGProps } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [elements, setElements] = useState([])
  useEffect(() => {
    const fetchContractElements = async () => {
      try {
        const res = await axios.get(`/contractsElements/g/${issue?.contractId}`)
        setElements(res.data.elements)
      } catch (error) {
        console.log(error)
      }
    }
    fetchContractElements()
  }, [issue?.contractId])
  const mainElement = elements?.find((e) => e?.elementId === issue?.contractId)

  return (
    <div>
      <CentralTitle
        title={rtl ? 'الاتفاق العام بالعقد' : 'General Agreement'}
      />
      <TextEngine
        textContent={mainElement?.agreement || ''}
        textPackage={mainElement}
        {...DCGProps}
      />
    </div>
  )
}

export default IssueContractGeneralAgreement
