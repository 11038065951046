import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationViewModel, NotificationBodyWithAccessCard } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  marginRight: 4,
  marginLeft: 4,
}

const TaskTermination = (props) => {
  const { sender, project, task } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <>
              {rtl ? 'مهمة' : 'Your task'}
              <span style={style}>{task?.title}</span> {rtl ? 'فى' : 'in'}
              <Link to={`/projectProfile/${project?._id}`} style={style}>
                {project.name}
              </Link>{' '}
              {rtl ? 'تم إالغاؤها بواسطة' : 'has been Terminated by'}
              <Link to={`/profile/${sender?._id}`} style={style}>
                {sender?.name}
              </Link>
            </>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default TaskTermination
