import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RequestNotificationAccessModel } from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import NotificationViewModel from '../../../../NotificationViewModel'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const DepManagerManagerToolsNewRequests = (props) => {
  const { notification, project, sender } = props
  const [request, setRequest] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchRequest = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/requests/${notification?.accompaniedData?.requestId}`
        )
        setRequest(res.data.request)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (notification?.accompaniedData?.requestId) fetchRequest()
  }, [notification?.accompaniedData?.requestId])
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <RequestNotificationAccessModel
          {...props}
          requestType='depManager'
          requestData={request}
          isLoading={isLoading}
          notificationBodyContent={
            rtl ? (
              <section>
                قام
                <Link to={`/profile/${sender?._id}`}>{sender?.name}</Link> من
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>{' '}
                بإرسال طلب لك
              </section>
            ) : (
              <section>
                <Link to={`/profile/${sender?._id}`}>{sender?.name}</Link> in
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>{' '}
                sent you a request
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default DepManagerManagerToolsNewRequests
