export default function URLLinkDetector(fragment) {
  // let urlRegex = /(https?:\/\/[^\s]+)/g
  let urlRegex =
    /(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(?:\/[\w#!:.?+=&%@!\/-]+)?/g
  let detectedURLs = fragment
    ?.split(' ')
    ?.filter((word) => word?.match(urlRegex))
    .concat(' ')

  let nonDetectedURLs = fragment
    ?.split(urlRegex)
    ?.filter((part, index) => !part?.match(urlRegex))

  return detectedURLs.map((url, urlIndex) => (
    <span key={urlIndex}>
      {nonDetectedURLs[urlIndex]}
      <a href={url}>{url}</a>
    </span>
  ))
}
