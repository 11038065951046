import React from 'react'
import { Dialog, DialogContent } from '@mui/material'
import DeleteMessage from './DeleteConfirmComponents/DeleteMessage'
import DeleteButtons from './DeleteConfirmComponents/DeleteButtons'

const DeleteConfirmDialog = (props) => {
  const { isConfirmDeleteDialogOpen, isDarkModeActive } = props
  return (
    <Dialog
      disableScrollLock
      open={isConfirmDeleteDialogOpen}
      PaperProps={{
        style: {
          borderRadius: 10,
          background: isDarkModeActive ? 'rgb(49, 48, 53, 0.6)' : 'white',
          backdropFilter: 'blur(4px)',
          color: isDarkModeActive && 'white',
        },
      }}
    >
      <DialogContent>
        <div style={{ color: isDarkModeActive && 'white' }}>
          <DeleteMessage {...props} />
          <DeleteButtons {...props} />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteConfirmDialog
