import i18next from 'i18next'
import React from 'react'

const ValueProposition = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return rtl ? (
    <>
      القيمة المقترحة هو وعد بالقيمة صرحت به الشركة يلخص كيفية تقديم العميل
      لمنتج أو خدمة الشركة وتجربتها واكتسابها. القيمة المقترحة هو أيضًا بيان
      موجز للفوائد التي تقدمها الشركة للعملاء الذين يشترون منتجاتها أو خدماتها.
      يهدف القيمة المقترحة إلى إقناع أصحاب المصلحة أو المستثمرين أو العملاء بأن
      الشركة أو منتجاتها أو خدماتها جديرة بالاهتمام.
      <br />
      <br />
      بعض الأسئلة التي يمكن أن تساعد في تحديد القيمة المقترحة هي:
      <br />
      <br />
      <ul>
        <li>
          ما مشكلة العميل التي نحاول حلها؟ يمكن أن يساعد هذا السؤال في تحديد
          نقاط الألم أو التحديات التي يواجهها العميل وكيف يمكن للمنتج أو الخدمة
          معالجتها.
        </li>
        <li>
          ما هي احتياجات العملاء التي نحاول إشباعها؟ يمكن أن يساعد هذا السؤال في
          تحديد رغبات أو أهداف العميل وكيف يمكن للمنتج أو الخدمة تحقيقها.
        </li>
        <li>
          ما هي المنتجات والخدمات الخاصة بالقطاعات التي نقدمها لعملائنا؟ يمكن أن
          يساعد هذا السؤال في تحديد السوق المستهدف أو المكانة التي يلبيها المنتج
          أو الخدمة وكيف تلبي احتياجاتهم الخاصة.
        </li>
        <li>
          ما هي القيمة التي نولدها لعملائنا؟ يمكن أن يساعد هذا السؤال في تحديد
          النتائج أو الفوائد التي يتلقاها العميل من استخدام المنتج أو الخدمة
          وكيف تعمل على تحسين وضعهم.
        </li>
        <li>
          كيف يختلف القيمة المقترحة لدينا عن عرض المنافسة؟ يمكن أن يساعد هذا
          السؤال في تحديد نقاط البيع الفريدة أو المزايا التي تميز المنتج أو
          الخدمة عن البدائل الأخرى في السوق وسبب تفوقها.
        </li>
      </ul>
    </>
  ) : (
    <>
      A value proposition is a promise of value stated by a company that
      summarizes how the benefit of the company’s product or service will be
      delivered, experienced, and acquired by the customer. A value proposition
      is also a concise statement of the benefits that a company is delivering
      to customers who buy its products or services. A value proposition is
      meant to convince stakeholders, investors, or customers that a company or
      its products or services are worthwhile.
      <br />
      <br />
      Some questions that can help define value proposition are:
      <br />
      <br />
      <ul>
        <li>
          What customer problem we attempting to solve? This question can help
          identify the pain points or challenges that the customer faces and how
          the product or service can address them.
        </li>
        <li>
          What customer needs we trying to satisfy? This question can help
          identify the desires or goals that the customer has and how the
          product or service can fulfill them.
        </li>
        <li>
          What segment-specific products and services do we offer our customers?
          This question can help identify the target market or niche that the
          product or service caters to and how it meets their specific needs.
        </li>
        <li>
          What value do we generate for our customers? This question can help
          identify the outcomes or benefits that the customer receives from
          using the product or service and how it improves their situation.
        </li>
        <li>
          How does our value proposition differ from this of the competition?
          This question can help identify the unique selling points or
          advantages that distinguish the product or service from other
          alternatives in the market and why it is superior.
        </li>
      </ul>
    </>
  )
}

export default ValueProposition
