import React from 'react'
import { Card } from '@mui/material'

const ImageUpdatorPopoverWrapper = ({ component }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
      }}
    >
      {component}
    </Card>
  )
}

export default ImageUpdatorPopoverWrapper
