import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ContributeButtonInvestOffersCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl ? `لديك عرض استثمار جديد` : 'You have a new investment offer'
      }
    />
  )
}

export default ContributeButtonInvestOffersCover
