import React, { useState } from 'react'
import WindowViewerSystem from '../../windowViewer/WindowViewerSystem'
import { Card } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import WindowProps from '../../windowViewer/WindowProps'
import ModelUnitCardMedia from './ModelUnitComponents/ModelUnitCardMedia'
import ModelUnitCardContent from './ModelUnitComponents/ModelUnitCardContent'

const styles = () => ({
  wrapper: {
    borderRadius: '20px !important',
    margin: '2px 5px !important',
    width: '100% !important',
    display: 'flex !important',
    flexDirection: 'column',
    alignContent: 'space-around !important',
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    fontWeight: 600,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
  },
  '@media (max-width: 600px)': {
    cardContentWrapper: {},
    wrapper: {
      borderRadius: '10px !important',
      minWidth: '40vw !important',
      height: 'auto !important',
    },
  },
})

const ModelUnit = ({
  classes,
  unit,
  unitsArray,
  isDarkModeActive,
  modelUnitProps,
}) => {
  const { distributedCard } = modelUnitProps
  const [chosenCardId, setChosenCardId] = useState(1)
  // const chosenCard = unitsArray.filter((mod) => mod.id === chosenCardId)[0]
  const [chosenCard, setChosenCard] = useState()
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  // window props
  const wProps = distributedCard
    ? {
        windowCloser,
        [chosenCard?.windowProps?.windowCardName]: chosenCard,
        ...chosenCard?.windowProps,
        ...modelUnitProps,
      }
    : // for the dirstbuted cards
      {
        windowCloser,
        [modelUnitProps?.windowProps?.windowCardName]: unit,
        ...modelUnitProps?.windowProps,
        ...modelUnitProps,
      }
  const wWindoType = distributedCard
    ? chosenCard?.windowProps?.windowType
    : // for the dirstbuted cards
      modelUnitProps?.windowProps?.windowType
  return (
    <Card
      className={classes.wrapper}
      sx={{
        background: isDarkModeActive ? 'rgba(255,255,255,0.2)' : null,
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <ModelUnitCardMedia modelUnitProps={modelUnitProps} unit={unit} />
      <ModelUnitCardContent
        setChosenCard={setChosenCard}
        setChosenCardId={setChosenCardId}
        windowOpener={windowOpener}
        unit={unit}
        isDarkModeActive={isDarkModeActive}
        modelUnitProps={modelUnitProps}
      />
      {chosenCard && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
          isDarkModeActive={isDarkModeActive}
          props={wProps}
          windowType={wWindoType}
        />
      )}
    </Card>
  )
}

const WrappedModelUnit = withStyles(styles)(ModelUnit)

export default WrappedModelUnit
