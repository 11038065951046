import React from 'react'
import AdditionToNewProject from './ContributionNotificationViewModelComps/AdditionToNewProject'
import NewProjectContributionOffer from './ContributionNotificationViewModelComps/NewProjectContributionOffer'
import NewProjectContributionOfferReply from './ContributionNotificationViewModelComps/NewProjectContributionOfferReply'

const ContributionNotificationViewModel = ({ notifProps }) => {
  const { isDarkModeActive, notification } = notifProps
  const mssgs = [
    {
      relation: 'projectMemberCandidate',
      comp: [
        <NewProjectContributionOffer {...notifProps} />,
        <AdditionToNewProject {...notifProps} />,
      ],
    },
    {
      relation: 'newProjectOwner',
      comp: [<NewProjectContributionOfferReply {...notifProps} />],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default ContributionNotificationViewModel
