import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar, ListItemIcon } from '@mui/material'
import { Link } from 'react-router-dom'

const styles = () => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifycontent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: '80px !important',
    height: '80px !important',
    borderRadius: '10px !important',
    cursor: 'pointer',
  },
  gridElementName: {
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
  },
})

const ContributorProjectSquareAvatar = ({
  classes,
  element,
  isDarkModeActive,
}) => {
  return (
    <article
      className={classes.listItem}
      style={{ color: isDarkModeActive ? 'white' : 'black' }}
    >
      <ListItemIcon>
        <Link
          to={
            element.firstName
              ? `/profile/${element?._id}`
              : `/projectProfile/${element?._id}`
          }
        >
          <Avatar
            src={
              element.profilePicture || element?.projectPhoto || element?.image
            }
            variant='square'
            className={classes.avatar}
          />
        </Link>
      </ListItemIcon>
      <span className={classes.gridElementName}>
        {element.firstName
          ? element.firstName + ' ' + element?.lastName
          : element.ProjectName || element?.name}
      </span>
    </article>
  )
}

const WrappedContributorProjectSquareAvatar = withStyles(styles)(
  ContributorProjectSquareAvatar
)

export default WrappedContributorProjectSquareAvatar
