import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import DetailsGridModel from '../../formsSystem/DetailsGridModel'
import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import FreeBar from '../../skeleton/SkeletonComponents/FreeBar'
import moment from 'moment'
import i18next from 'i18next'

const styles = () => ({
  warpper: {
    width: '100%',
    display: 'flex',
    margin: 'auto 20px',
    flexDirection: 'column',
  },
})

const ChartPostDescription = ({ classes, post, isDarkModeActive }) => {
  const { projectId, chartData } = post
  const [project, setProject] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const lang = i18next.language
  const rtl = lang === 'ar'
  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/projects/${projectId}`)
        setProject(res.data.project)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    if (projectId) fetchProject()
  }, [projectId])
  const startDate = moment(chartData[0]?._id).format('YYYY-MM-DD')
  const endDate = moment(chartData[chartData?.length - 1]?._id).format(
    'YYYY-MM-DD'
  )
  return (
    <section className={classes.warpper}>
      <Divider
        style={{
          backgroundColor: isDarkModeActive ? 'white' : null,
          width: '70%',
          // marginTop: 5,
        }}
      />
      <section style={{ fontSize: 10 }}>
        <DetailsGridModel
          infoArray={[
            {
              title: rtl ? 'مشروع' : 'Project',
              titleValue: isLoading ? (
                <FreeBar height={'8px'} width={'40px'} margin={'5px 0 0 0'} />
              ) : (
                <Link to={`/projectProfile/${projectId}`}>
                  {project?.ProjectName}
                </Link>
              ),
            },
            {
              title: rtl ? 'مدى البيانات' : 'Data Range',
              titleValue: rtl
                ? `من ${startDate} الى ${endDate}`
                : `From ${startDate} to ${endDate} `,
            },
          ]}
        />
      </section>
    </section>
  )
}

const WrappedChartPostDescription = withStyles(styles)(ChartPostDescription)

export default WrappedChartPostDescription
