import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ProductRequestReplyCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl ? 'تم الرد على طلبك' : 'Your request has been replied to.'
      }
    />
  )
}

export default ProductRequestReplyCover
