import i18next from 'i18next'
import React from 'react'
import TimePicker from 'react-time-picker'

const TimeInputEditingZone = ({ setInfoValue, infoValue, info }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div>
      <TimePicker
        onChange={(e) => setInfoValue(e)}
        value={infoValue}
        required={info?.required}
        locale={rtl && 'ar'}
        // locale={lang} --suggested
        disabled={info?.disabled}
      />
    </div>
  )
}

export default TimeInputEditingZone
