import React from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  sentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tipWrapper: {
    fontSize: 13,
  },
  icon: {
    color: 'green',
    fontSize: 17,
  },
})

const NotificationSentCard = ({
  classes,
  notificationTitle,
  confirmationTip,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const defaultTitle = rtl ? 'تم إرسال العرض' : 'Offer Sent'
  const defaultTip = rtl
    ? 'سوف تتلقى اشعاراًً بالرد فى إشعارات المشاركين لديك'
    : 'You will recieve a notification with response in contributors Notifications rolls'
  return (
    <article className={classes.cardWrapper}>
      <section className={classes.sentWrapper}>
        <AiFillCheckCircle
          className={classes.icon}
          style={{ marginRight: !rtl && 4, marginLeft: rtl && 4 }}
        />
        <span style={{ fontWeight: 500, fontSize: 18 }}>
          {notificationTitle ? notificationTitle : defaultTitle}
        </span>
      </section>
      <section className={classes.tipWrapper}>
        {confirmationTip || notificationTitle ? confirmationTip : defaultTip}
      </section>
    </article>
  )
}

const WrappedNotificationSentCard = withStyles(styles)(NotificationSentCard)

export default WrappedNotificationSentCard
