import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import Loading from '../../../components/smallComponents/Loading'
import AdsCampaignUnit from './AdsExplorerPageComps/AdsCampaignUnit'
import { Toolbar } from '@mui/material'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import i18next from 'i18next'

const useStyles = makeStyles({
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '1rem',
  },
  header: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  campaignList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridGap: '1rem',
  },
  emptyMessage: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '4rem',
    color: '#666',
  },
})

const AdsExplorerPage = () => {
  const { user } = useContext(AuthContext)
  const [adsCampaigns, setAdsCampaigns] = useState([])
  const [isLoading, setIsLoading] = useState()
  const classes = useStyles()
  const lang = i18next.language
  const rtl = lang === 'ar'

  useEffect(() => {
    const fetchCampaigns = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/adsManagment/userAdsCampaign/${user?._id}`
        )
        setAdsCampaigns(res.data.adsCampaigns)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (user?._id) fetchCampaigns()
  }, [user?._id])

  return (
    <div className={classes.container}>
      <Toolbar />
      <h1 className={classes.header}>
        {rtl ? 'حملات تم انشاؤها' : 'Created Campaigns'}
      </h1>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {adsCampaigns?.length > 0 ? (
            <div className={classes.campaignList}>
              {adsCampaigns?.map((adCampaign) => {
                return (
                  <AdsCampaignUnit
                    key={adCampaign?._id}
                    adCampaign={adCampaign}
                  />
                )
              })}
            </div>
          ) : (
            <p className={classes.emptyMessage}>
              You do not have any campaigns yet.
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default AdsExplorerPage
