import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  optionwrapper: {
    background: 'rgba(255,255,255,0.2)',
    padding: 10,
    borderRadius: 15,
    margin: '4px auto',
  },
})

const NewPollOptionsView = ({ options, classes, rtl }) => {
  return (
    <>
      {options?.map((option, i) => (
        <div key={i} className={classes.optionwrapper}>
          {i + 1} {option?.Title}
        </div>
      ))}
    </>
  )
}

const WrappedNewPollOptionsView = withStyles(styles)(NewPollOptionsView)

export default WrappedNewPollOptionsView
