import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const ShareholdersvsCustomerRetention = () => {
  const { t } = useTranslation('ShareholdersvsCustomerRetention')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 26,
    category: t('category_ms26'),
    title: t('title_ms26'),
    subTitle: t('subTitle_ms26'),
    main: t('main_ms26'),
    how: t('how_ms26'),
    usedInModels: [],
    tags: rtl
      ? [
          'المحافظة على العملاء',
          'قيمة حقوق المساهمين',
          'عرض خارجي',
          'منظر داخلي',
          'بناء العلاقات',
          'ماركات قوية',
          'مراقبة الكلفة',
          'الاستثمار الفعال',
          'وفاء',
          'ولاء العميل',
          'عوائد متزايدة',
          'زيادة الأرباح',
        ]
      : [
          'Customer Retention',
          'Shareholder Value',
          'External View',
          'Internal View',
          'Building Relationships',
          'Strong Brands',
          'Cost Control',
          'Efficient Investment',
          'Loyalty',
          'Customer Loyalty',
          'Increased Returns',
          'Increased Profits',
        ],
    examples: t('examples_ms26'),
    hashTagType: 'markStraAspect',
  }
}

export default ShareholdersvsCustomerRetention
