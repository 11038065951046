import axios from 'axios'
import React, { useState } from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import { AuthContext } from '../../../../../../context/AuthContext'
import NotificationCreator from '../../../../NotificationCreator'
import i18next from 'i18next'

const PermissionRequest = (props) => {
  const { idea, sender, notification, socket } = props
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isClicked, setIsClicked] = useState(false)
  const buttonsArray = [
    {
      title: isClicked || notification?.interactedWith ? 'Granted' : 'Grant',
      funs: async () => {
        try {
          await axios.patch(`/ideas/addPermission/${idea?.id}`, {
            perUserId: sender?._id,
          })
          await axios.patch(`/notifications/${notification?._id}`, {
            interactedWith: true,
          })

          setIsClicked(true)
          socket?.current?.emit('addNotifUser', idea?.userId)
          NotificationCreator({
            associativity: 'idea',
            relation: 'visionReviewer',
            conditionIndex: 1,
            accompaniedData: {
              elements: [{ ...idea }],
            },
            category: 'contributors',
            userId: sender?._id,
            currentUser: user,
            socket,
          })
        } catch (error) {
          console.log(error)
        }
      },
      disabled: isClicked || notification?.interactedWith,
      descProps: {
        granted: 'permission request',
      },
    },
  ]
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <section>
              <Link to={`/profile/${sender?._id}`}>{sender.name}</Link>{' '}
              {rtl
                ? `يطلب اذناً لعرض فكرتك`
                : `asks for permission to your idea`}
            </section>
          }
        />
      }
      buttonsArray={buttonsArray}
    />
  )
}

export default PermissionRequest
