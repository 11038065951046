import { TextField } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import HashTagSuggestSystem from '../../suggestionsSystem/HashTagSuggestSystem'
import withStyles from '@mui/styles/withStyles'
import NewFormDialogModel from '../NewFormDialogModel'
import EditingDialogContent from './FullActiveOutlineInputComponents/EditingDialogContent'
import { SettingsContext } from '../../../context/SettingsContext/SettingsContext'
import { CgAttachment } from 'react-icons/cg'
import { AiOutlineSend } from 'react-icons/ai'
import deleteFile from '../../../firebase/deleteFile'
import EmojiPopover from './EmojiPopover'
import i18next from 'i18next'

const styles = () => ({
  iconsWrapper: {
    display: 'flex',
    alignItems: 'center',
    alignCotent: 'center',
  },
})

const CustomPCChatInput = ({ classes, formsCompsProps }) => {
  const { formComponentIndex, ...props } = formsCompsProps
  const {
    setTextContent,
    textContent,
    isDarkModeActive,
    placeholder,
    cursorDirection,
    setImages,
    images,
    onSend,
    onKeyDown,
    disabled_chat,
  } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isSuggeCardClicked, setIsSuggeCardClicked] = useState(false)
  const formsCompsPropsII = {
    ...formsCompsProps,
    setIsSuggeCardClicked,
    isSuggeCardClicked,
  }
  const { settings } = useContext(SettingsContext)
  const [isEditingDialogOpen, setIsEditingDialogOpen] = useState(false)
  const editingDialogOpener = () => {
    setIsEditingDialogOpen(true)
  }

  const editingDialogClose = () => {
    setIsEditingDialogOpen(false)
  }
  const anchorRef = useRef(null)
  return (
    <span
      ref={anchorRef}
      style={{
        flexDirection: rtl ? 'row' : 'row-reverse',
        display: 'flex',
        width: '100%',
        margin: 'auto 5px',
      }}
    >
      <section
        className={classes.iconsWrapper}
        style={{ flexDirection: rtl ? 'row' : 'row-reverse' }}
      >
        <AiOutlineSend
          size={23}
          style={{ margin: 5, cursor: 'pointer' }}
          onClick={() => {
            setTextContent('')
            setImages([])
            onSend()
          }}
        />
        <CgAttachment
          onClick={editingDialogOpener}
          size={23}
          style={{ cursor: 'pointer' }}
        />
        <EmojiPopover {...formsCompsProps} iconSize={23} />
      </section>
      <TextField
        placeholder={placeholder}
        onChange={(event) => {
          setTextContent(event.currentTarget.value)
          setIsSuggeCardClicked(false)
        }}
        value={textContent}
        onKeyDown={
          settings?.EnterAsSend && typeof onKeyDown === 'function'
            ? onKeyDown
            : undefined
        }
        InputProps={{
          style: {
            borderRadius: 15,
            padding: 10,
            color: isDarkModeActive ? 'white' : 'black',
            direction: cursorDirection,
            backgroundColor: isDarkModeActive
              ? 'rgba(255, 255, 255, 0.3)'
              : null,
          },
        }}
        fullWidth
        margin='dense'
        multiline
        rows='2'
        variant='outlined'
        disabled={disabled_chat}
      />

      <HashTagSuggestSystem
        {...formsCompsPropsII}
        anchorRef={!isEditingDialogOpen && anchorRef}
        cursorDirection={cursorDirection}
      />
      <NewFormDialogModel
        NewFormComponent={
          <EditingDialogContent
            formsCompsProps={{
              editingDialogClose,
              ...props,
            }}
          />
        }
        newFormDialogClose={() => {
          Promise.all(
            images.map(async (image) => {
              if (image) await deleteFile(image)
            })
          )
          editingDialogClose()
          setTextContent('')
          setImages([])
        }}
        isNewFormDialogOpen={isEditingDialogOpen}
        isDarkModeActive={isDarkModeActive}
        clickAwayState={true}
        dialogTitle={rtl ? 'تحرير' : 'Editing'}
      />
    </span>
  )
}

const WrappedCustomPCChatInput = withStyles(styles)(CustomPCChatInput)

export default WrappedCustomPCChatInput
