import React, { useContext, useState } from 'react'
import TitleHeader from '../../../../../../../smallComponents/Header'
import ResearchActivityFormConfigs from '../NewResearchActivityFormComps/ResearchActivityFormConfigs'
import EditableGridModel from '../../../../../../../formsSystem/EditableGridModel'
import { useTranslation } from 'react-i18next'
import InfoDataIntialStateGen from '../../../../../../../formsSystem/InfoDataIntialStateGen'
import HashTagElementsRefiner from '../../../../../../../smallComponents/HashTagElementsRefiner'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import axios from 'axios'
import FormDataLangModulator from '../../../../../../../formsSystem/FormDataLangModulator'
import i18next from 'i18next'

const ResearchActivityDetails = (props) => {
  const { activity, setCards, setIsUpdated, depManager, DCGProps } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const { project } = DCGProps
  const infoArray = ResearchActivityFormConfigs().map((config, index) => ({
    title: config.title,
    titleValue: activity[config.title.split('_').join('')] || '',
    allowEmpty: true,
    wideMode: index !== 0,
    minRows: config?.minRows,
  }))

  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))
  const { t } = useTranslation()
  const newInfoData = FormDataLangModulator(infoData, t)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(activity) || []
  )
  const onSave = async () => {
    if (activity?._id) {
      try {
        const res = await axios.patch(`/researchActivities/${activity?._id}`, {
          ...newInfoData,
          chosenHashTagElements,
        })
        setCards((acts) => [
          ...acts.filter((p) => p._id !== activity?._id),
          res.data.researchactivity,
        ])
      } catch (error) {
        console.log(error)
      }
      setIsUpdated(true)
    }
  }
  const gridProps = {
    hideEditingTools:
      user?._id !== depManager?.userId && user?._id !== project?.userId,
    ...props,
    infoArray,
    infoArray,
    setInfoData,
    infoData,
    setChosenHashTagElements,
    chosenHashTagElements,
    hideHeader: true,
    ...DCGProps,
    onSave,
  }
  return (
    <>
      <TitleHeader
        headerText={rtl ? 'تفاصيل النشاط البحثى' : 'Research Activity Details'}
      />
      <span style={{ margin: 'auto 10px' }}>
        <EditableGridModel gridProps={gridProps} />
      </span>
    </>
  )
}

export default ResearchActivityDetails
