import React from 'react'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const IssueNotifsUpVote = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? `قام ${sender?.name} بالتصويت لصالحك فى مشكلة`
              : `${sender?.name} has voted for you in an issue.`
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default IssueNotifsUpVote
