import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../context/AuthContext'
import GeneralConfirmationMssg from '../../../../confirmationSystem/GeneralConfirmationMssg'
import NotificationsSentConfirmDialog from '../../../../confirmationSystem/NotificationsSentConfirmDialog'
import EditableGridModel from '../../../../formsSystem/EditableGridModel'
import FormDataLangModulator from '../../../../formsSystem/FormDataLangModulator'
import NotificationCreator from '../../../../notificationsSystem/NotificationCreator'
import HashTagElementsRefiner from '../../../../smallComponents/HashTagElementsRefiner'
import StateGenerator from '../../../../smallComponents/StateGenerator'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  termsTitle: {
    fontWeight: 500,
    fontSize: 15,
    padding: 5,
  },
})

const ContractTermBody = ({ classes, ...props }) => {
  const { conEl, DCGProps, contract, setModifications, members, main } = props
  const { isDarkModeActive, socket, project } = DCGProps
  const { user } = useContext(AuthContext)
  const [infoData, setInfoData] = useState({
    Agreement: conEl?.agreement,
  })
  const { t } = useTranslation()
  const newInfoData = FormDataLangModulator(infoData, t)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(conEl) || []
  )
  const newModification = {
    userId: user?._id,
    elementId: conEl?._id,
    Content: newInfoData?.Agreement,
    chosenHashTagElements,
    agreedUsersIds: user?._id,
  }
  const onSave = async () => {
    try {
      const resMods = await axios.post(
        `/contractElementModifications`,
        newModification
      )
      setModifications((mods) => [resMods.data.modification, ...mods])
      members?.map((m) => {
        socket?.current?.emit('addNotifUser', m)
        NotificationCreator({
          associativity: 'activeContract',
          relation: 'opponent',
          conditionIndex: 1,
          accompaniedData: {
            projectId: project?._id,
            elements: [
              {
                hashTagType: contract?.hashTagType,
                id: contract?._id,
                title: contract?.Title,
              },
            ],
          },
          category: 'deals',
          userId: m,
          currentUser: user,
          socket,
        })
      })
    } catch (error) {}
  }
  const {
    isGeneralDialogOpen,
    GeneralDialogOpener,
    GeneralDialogClose,
    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    clickedButtonId,
    confirmDialogOpener,
  } = StateGenerator({ GeneralProps: true, confirmDialogProps: true })
  const lang = i18next.language
  const rtl = lang === 'ar'
  const confirmmationTitles = [
    {
      title: rtl
        ? 'تم إرسال التعديلات للمتعاقدين للموافقة عليها'
        : 'Modification Sent to contractors for approval',
      tip: rtl
        ? 'سوف تتلقى اشعاراُ بالرد فى إشعارات العقود الخاص بك'
        : 'You will recieve a notification with response in contracts Notifications rolls',
    },
  ]

  const infoArray = [
    {
      title: 'Agreement',
      titleValue: conEl?.agreement,
      allowEmpty: true,
      wideMode: true,
      minRows: 4,
      placeholder: rtl ? 'شروط الاتفاق' : 'Agreement Terms',
    },
  ]
  const gridProps = {
    hideEditingTools: !members?.includes(user?._id),
    headerText: (
      <span className={classes.termsTitle}>
        {main ? (
          conEl?.Title || conEl?.ProductName
        ) : rtl ? (
          <>شروط اتفاق {conEl?.Title || conEl?.ProductName}</>
        ) : (
          <>{conEl?.Title || conEl?.ProductName}'s Agreement Terms:</>
        )}
      </span>
    ),
    hideInfoTitles: true,
    infoArray,
    infoData,
    setInfoData,
    isDarkModeActive,
    chosenHashTagElements,
    setChosenHashTagElements,
    onSave: () => {
      if (newInfoData?.Agreement?.trim() !== conEl?.agreement?.trim())
        GeneralDialogOpener()
    },
    ...DCGProps,
  }

  return (
    <>
      <EditableGridModel gridProps={gridProps} />
      <GeneralConfirmationMssg
        {...DCGProps}
        confirmMessg={
          rtl
            ? 'هل أنت متأكد من إرسال هذا التعديل الى المتعاقدين ليتم قبولة بشكل نهائى؟'
            : 'Are your sure you want to send your contractor to agree on this modification to permanently change it?'
        }
        confirmTitle={
          rtl
            ? 'لابد ان يتفق جميع المتعاقدين على أى تعديل'
            : 'Modiifcation should be agreed by contractors'
        }
        generalConfirmDialogClose={() => {
          GeneralDialogClose()
        }}
        generalConfirmAction={() => {
          onSave()
          confirmDialogOpener()
        }}
        isGeneralConfirmDialogOpen={isGeneralDialogOpen}
      />
      <NotificationsSentConfirmDialog
        confirmmationTitles={confirmmationTitles}
        confirmationRTitleIndex={clickedButtonId}
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        {...DCGProps}
      />
    </>
  )
}

const WrappedContractTermBody = withStyles(styles)(ContractTermBody)

export default WrappedContractTermBody
