import React, { useEffect, useState } from 'react'
import MiniFileSystem from '../fileSystem/MiniFileSystem'
import WindowProps from '../windowViewer/WindowProps'
import WindowViewerSystem from '../windowViewer/WindowViewerSystem'

const MultipleMembersViewer = ({
  DCGProps,
  cards,
  windowType,
  memberOptions,
  subDepId,
  departmentId,
  subDepManager,
  emptySentence,
  setMembers,
  modelProps,
}) => {
  const { isDarkModeActive } = DCGProps
  const [memberId, setMemberId] = useState()
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const MFSGProps = {
    cards,
    MiniFSModelIndex: 3,
    setCardId: setMemberId,
    cardId: memberId,
    DCGProps,
    ...DCGProps,
  }
  const member = cards?.find((mem) => mem.id === memberId)
  const modelPropsII = {
    hideTippy: true,
    disablePorfileOpener: true,
    windowOpener,
    memberOptions,
    subDepManager,
    members: cards,
    setMembers,
    ...modelProps,
  }
  return (
    <>
      <article
        style={{
          maxHeight: 500,
          height: 'auto',
          overflowY: 'auto',
        }}
      >
        <MiniFileSystem
          MFSGProps={MFSGProps}
          modelProps={modelPropsII}
          searchSystemProps={{ placeholderValue: emptySentence }}
          folderPathProps={{}}
          noFSPanelPropsSecondView={{ hideNoFileSystemPanel: true }}
          noFSPanelPropsFirstView={{ hideNoFileSystemPanel: true }}
        />
      </article>
      {isWindowOpen && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
          isDarkModeActive={isDarkModeActive}
          windowType={windowType}
          props={{
            member,
            DCGProps,
            ...DCGProps,
            subDepId,
            departmentId,
          }}
        />
      )}
    </>
  )
}

export default MultipleMembersViewer
