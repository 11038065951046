import React from 'react'
import NotificationCoverModel from '../../../NotificationCoverModel'
import i18next from 'i18next'

const InvetmentOfferReplyCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl
          ? 'عرض الاسثتمار الخاص بك تم الرد عليه'
          : 'Your investment offer has been replied to'
      }
    />
  )
}

export default InvetmentOfferReplyCover
