import React from 'react'
import { ActiveContractNotificationViewModel } from '../'
import IssueNotificationViewModel from './ContractsNotifsDialogRightComponents/IssueNotificationViewModel'

const ContractsNotifsDialogRight = ({ notifProps }) => {
  const { notification } = notifProps
  const viewModels = [
    {
      associativity: 'activeContract',
      modelComp: (
        <ActiveContractNotificationViewModel notifProps={notifProps} />
      ),
    },
    {
      associativity: 'issue',
      modelComp: <IssueNotificationViewModel notifProps={notifProps} />,
    },
  ]
  return viewModels.find(
    (mod) => mod.associativity === notification.associativity
  )?.modelComp
}

export default ContractsNotifsDialogRight
