import React, { useState } from 'react'
import TextProcessor from './TextEngineComponents/TextProcessor'
import SearchSystem from '../searchSystem/SearchSystem'
import TextDirectionDetector from './TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'
import ReadeMore from './TextEngineComponents/ReadeMore'
import i18next from 'i18next'

const TextEngine = (props) => {
  const { textContent, activateReadMore } = props
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const searchDialogOpener = () => {
    setIsSearchDialogOpen(true)
  }
  const searchDialogClose = () => {
    setIsSearchDialogOpen(false)
  }
  const [searchQuerry, setSearchQuerry] = useState('')
  // for break
  let textFragments = textContent.split(/\n/g)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <span dir={TextDirectionDetector(textContent)} style={{ width: '100%' }}>
      {activateReadMore ? (
        <ReadeMore
          {...props}
          setSearchQuerry={setSearchQuerry}
          searchDialogOpener={searchDialogOpener}
        />
      ) : (
        textFragments.map((fragment, index) => (
          <span key={index}>
            <br style={{ display: index === 0 ? 'none' : null }} />
            <TextProcessor
              {...props}
              textContent={fragment}
              setSearchQuerry={setSearchQuerry}
              searchDialogOpener={searchDialogOpener}
            />
          </span>
        ))
      )}
      <SearchSystem
        isSearchDialogOpen={isSearchDialogOpen}
        searchDialogClose={searchDialogClose}
        searchQuerry={searchQuerry}
        placeholder={
          rtl
            ? 'إبحث المشروعات، المساهمين...'
            : 'Search Projects, Contributors...'
        }
        {...props}
      />
    </span>
  )
}

export default TextEngine
