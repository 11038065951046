import i18next from 'i18next'
import React from 'react'

const PinnedTag = ({ hide }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div
      style={{
        borderRadius: 10,
        border: '1px dotted green',
        margin: '0 10px',
        fontSize: 10,
        display: hide ? null : 'none',
        padding: '0 2px',
      }}
    >
      {rtl ? 'مثبت' : 'Pinned'}
    </div>
  )
}

export default PinnedTag
