import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NavigatorCardsModel from '../../../../../../../navigatorCardsModel/NavigatorCardsModel'
import TitleHeader from '../../../../../../../smallComponents/Header'
import i18next from 'i18next'

const ProductPartnershipChannels = ({ design, DCGProps }) => {
  const [channels, setChannels] = useState([])
  useEffect(() => {
    const fetchUdeaPosts = async () => {
      try {
        const resChannels = await axios.get(
          `/channels/elementChannels/${design?.productId}`
        )
        setChannels(resChannels.data.channels)
      } catch (error) {
        console.log(error)
      }
    }
    fetchUdeaPosts()
  }, [design?.productId])
  const unitsArray = channels
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    unitsArray?.length > 0 && (
      <>
        <TitleHeader
          headerText={
            rtl
              ? `قنوات التشارك الخاصة ب ${design?.Title}`
              : `Partnership Channels for ${design?.Title}`
          }
        />
        <NavigatorCardsModel
          unitsArray={unitsArray}
          {...DCGProps}
          modelUnitProps={{
            elementsImages: [],
            imageKey: 'channel',
            windowProps: {
              windowCardName: 'channel',
              windowType: 'channel',
              DCGProps,
            },
          }}
        />
      </>
    )
  )
}

export default ProductPartnershipChannels
