import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import TextEngineToolsFooter from '../../../../../../smallComponents/TextEngineToolsFooter'
import InvestmentPackageDetails from './InvestmentPackageDetails'
import InvestmentPackageInteractionButtons from './InvestmentPackageProfileComps/InvestmentPackageInteractionButtons'
import InvestmentPackageRequests from './InvestmentPackageRequests'
import i18next from 'i18next'

const InvestmentPackageProfile = (props) => {
  const { pack, DCGProps, depManager } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { project } = DCGProps
  const { user } = useContext(AuthContext)
  const [managerProfile, setManagerProfile] = useState(null)
  useEffect(() => {
    const fetchDepManager = async () => {
      try {
        const res = await axios.get(
          `/projectContributors/depManager/${pack?.projectId}/2`
        )
        setManagerProfile(res.data.managerProfile)
      } catch (error) {
        console.log(error)
      }
    }
    fetchDepManager()
  }, [pack?.projectId])
  const propsII = {
    ...props,
    // when i open the profile outside the project
    depManager: depManager ? depManager : managerProfile,
  }

  const authorised =
    user?._id === project?.userId ||
    user?._id === depManager?._id ||
    user?.createdProjects?.includes(pack?.projectId) ||
    user?._id === managerProfile?._id
  return (
    <article
      style={{
        width: '100%',
        color: DCGProps?.isDarkModeActive ? 'white' : null,
      }}
    >
      <InvestmentPackageDetails {...propsII} />
      {authorised && <InvestmentPackageRequests {...propsII} />}
      <InvestmentPackageInteractionButtons {...propsII} />
      <TextEngineToolsFooter {...DCGProps} hideTETFooter={!authorised} />
    </article>
  )
}

export default InvestmentPackageProfile
