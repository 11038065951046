import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const BrandLeadership = () => {
  const { t } = useTranslation('BrandLeadership')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 38,
    category: t('category_ms38'),
    title: t('title_ms38'),
    subTitle: t('subTitle_ms38'),
    main: t('main_ms38'),
    how: t('how_ms38'),
    usedInModels: [],
    tags: rtl
      ? [
          'خرائط الإدراك الحسي',
          'وضع العلامة التجارية',
          'هوية العلامة التجارية',
          'الاحترام العام',
          'صورة العلامة التجارية',
        ]
      : [
          'Perceptual Mapping',
          'Brand Positioning',
          'Brand Identity',
          'Public Respect',
          'Brand Image',
        ],
    examples: t('examples_ms38'),
    hashTagType: 'markStraAspect',
  }
}

export default BrandLeadership
