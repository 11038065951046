import React from 'react'
import ContractPDFBody from './ContractPDFDocumentComps/ContractPDFBody'
import GeneralPDFDocument from './GeneralPDFDocument'
import ContractPDFTerms from './ContractPDFDocumentComps/ContractPDFTerms'

const ContractPDFDocument = (props) => {
  return (
    <GeneralPDFDocument
      pages={[<ContractPDFBody {...props} />, <ContractPDFTerms {...props} />]}
      {...props}
    />
  )
}

export default ContractPDFDocument
