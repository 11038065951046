import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  NotificationViewModel,
  NotificationBodyWithAccessCard,
} from '../../../../'
import { AuthContext } from '../../../../../../../context/AuthContext'
import NotificationCreator from '../../../../../NotificationCreator'
import i18next from 'i18next'

const ContractEndingEvent = (props) => {
  const { sender, notification, project, socket } = props
  const [interactedWith, setInteractedWith] = useState(
    notification?.interactedWith
  )
  const contractId =
    notification?.accompaniedData?.elements &&
    notification?.accompaniedData?.elements[0]?.id
  const [isLoading, setIsLoading] = useState()
  const { user } = useContext(AuthContext)

  const actionFun = async () => {
    setIsLoading(true)
    // notifiy the sender by approval
    socket.current.emit('addNotifUser', sender?._id)
    NotificationCreator({
      associativity: 'activeContract',
      relation: 'contributor',
      conditionIndex: 1,
      accompaniedData: {
        projectId: project?._id,
      },
      category: 'deals',
      userId: sender?._id,
      currentUser: user,
      socket,
    })
    const senderProfRes = await axios.get(`/users/oneUser/${sender?._id}`)
    try {
      await axios.patch(`/notifications/${notification?._id}`, {
        interactedWith: true,
      })
      // delete the contract
      await axios.delete(`/contracts/${contractId}`)
      // delete the contributur profile
      await axios.delete(`/projectContributors/${sender?._id}/${project?._id}`)
      // remove the project from users contracted rpojects
      await axios.patch(`/users/${sender?._id}`, {
        userId: sender?._id,
        contractedProjectsIds:
          senderProfRes?.data?.user?.contractedProjectsIds?.filter(
            (p) => p !== project?._id
          ),
      })
      setInteractedWith(true)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl
        ? isLoading
          ? 'جارى تفعيل برتوكول الغاء التشارك'
          : 'تأكيد'
        : isLoading
        ? 'Activating Contribution Ending Protocol'
        : 'Confirm',
      funs: () => actionFun(),
      disabled: interactedWith || isLoading,
    },
  ]
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <article>
                يريد
                <Link to={`/profile/${sender?._id}`}>{sender.name}</Link> بإلغاء
                تعاقد مع شركتك
              </article>
            ) : (
              <article>
                <Link to={`/profile/${sender?._id}`}>{sender.name}</Link> wants
                to end a contract with your project
              </article>
            )
          }
        />
      }
      buttonsArray={buttonsArray}
    />
  )
}

export default ContractEndingEvent
