import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const Differentiation = () => {
  const { t } = useTranslation('Differentiation')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 34,
    category: t('category_ms34'),
    title: t('title_ms34'),
    subTitle: t('subTitle_ms34'),
    main: t('main_ms34'),
    how: t('how_ms34'),
    usedInModels: [],
    tags: rtl
      ? [
          'استراتيجيات التمايز',
          'تجزئة السوق',
          'التمركز',
          'القيمة للعملاء',
          'ميزة تنافسية',
          'حماية العلامة التجارية',
        ]
      : [
          'Differentiation Strategies',
          'Market Segmentation',
          'Positioning',
          'Customer Value',
          'Competitive Advantage',
          'Brand Protection',
        ],
    examples: t('examples_ms34'),
    hashTagType: 'markStraAspect',
  }
}

export default Differentiation
