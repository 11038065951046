import React from 'react'
import NotificationViewModel from '../../../NotificationViewModel'
import { NotificationBodyWithAccessCard } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const ProductNewRequestsMssg = (props) => {
  const { product, project } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <section>
                لقد حصل منتجك <span style={style}>{product?.productName}</span>{' '}
                فى <span style={style}>{project?.name}</span> على طلبات جديده
                تحتاج للمراجعة
              </section>
            ) : (
              <section>
                Your product
                <span style={style}>{product?.productName}</span>in
                <span style={style}>{project?.name}</span>
                Project, has new Requests need revision
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default ProductNewRequestsMssg
