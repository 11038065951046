import React from 'react'
import BusinessModelPDFBody from './BusinessModelPDFDocumentComps/BusinessModelPDFBody'
import GeneralPDFDocument from './GeneralPDFDocument'

const BusinessModelPDFDocument = (props) => {
  return (
    <GeneralPDFDocument
      pages={[<BusinessModelPDFBody {...props} />]}
      {...props}
    />
  )
}

export default BusinessModelPDFDocument
