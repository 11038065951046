import i18next from 'i18next'
import { toast } from 'react-toastify'

const ToastifyGeneralError = (error) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  toast.error(
    <div>
      {rtl
        ? 'حدث خطأ ما. يرجى المحاولة مرة أخرى لاحقًا.'
        : 'An error occurred. Please try again later.'}
    </div>
  )
}

export default ToastifyGeneralError
