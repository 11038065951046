import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const WhiteLabelBusinessModel = () => {
  const { t } = useTranslation('WhiteLabelBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 60,
    title: t('title_bm60'),
    subTitle: t('subTitle_bm60'),
    main: t('main_bm60'),
    when: t('when_bm60'),
    who: t('who_bm60'),
    what: t('what_bm60'),
    how: t('how_bm60'),
    why: t('why_bm60'),
    examples: rtl
      ? [
          'فوكسكون - 1974 - تايوان',
          'أغذية ريشيليو - 1986 - الولايات المتحدة الأمريكية',
          'Printing In A Box - 1999 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Foxconn - 1974 - Taiwan',
          'Richelieu Foods - 1986 - USA',
          'Printing In A Box - 1999 - USA',
        ],
    tags: rtl
      ? ['بضائع بدون اسم', 'مربحة للجانبين']
      : ['No-name Goods', 'Win-win'],
    considerations: t('considerations_bm60'),
    combinations: ['$LBM28', '$LBM37'],
    hashTagType: 'businessModelTemp',
  }
}

export default WhiteLabelBusinessModel
