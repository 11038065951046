import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const MarketingRevenues = () => {
  const { t } = useTranslation('MarketingRevenues')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 3,
    category: t('category_ms03'),
    title: t('title_ms03'),
    subTitle: t('subTitle_ms03'),
    main: t('main_ms03'),
    how: t('how_ms03'),
    usedInModels: ['$LBM2', '$LBM54'],
    tags: rtl
      ? [
          'إيرادات الأعمال',
          'إجمالي الإنفاق',
          'الحصة السوقية',
          'ملاحظات العملاء',
          'استراتيجيات',
          "فرص متنامية'",
        ]
      : [
          'Business Revenues',
          'Total Spend',
          'Market Share',
          'Customer Feedback',
          'Strategies',
          'Growth Opportunities',
        ],
    examples: t('examples_ms03'),
    hashTagType: 'markStraAspect',
  }
}

export default MarketingRevenues
