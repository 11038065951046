import React, { useState } from 'react'
import { MdCamera } from 'react-icons/md'
import { Button } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { v4 as uuidv4 } from 'uuid'
import Resizer from 'react-image-file-resizer'
import uploadImage from '../../firebase/uploadImage'
import Loading from '../smallComponents/Loading'

const styles = () => ({
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    borderRadius: 15,
    margin: 15,
  },
  bigIcon: {
    cursor: 'pointer',
    opacity: 0.5,
    psoition: 'relative',
  },
  bigIcon2: {
    cursor: 'pointer',
    opacity: 0.3,
    borderRadius: '50%',
    zIndex: 1,
    '&:hover': {
      fotnSize: 95,
      backgroundColor: 'rgba(255,255,255, 0.8)',
      opacity: 0.6,
    },
  },
  imageWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  changeImageIcon: {
    position: 'absolute',
    zIndex: 2,
  },

  camerIconWrapper: {
    backgroundColor: 'rgba(255,255,255, 0.6)',
    color: 'black',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      fotnSize: 18,
      backgroundColor: 'rgba(255,255,255, 0.8)',
    },
  },
  labelButton: {
    padding: '0px !important',
    margin: '0px !important',
    textTransform: 'none !important',
    backgroundColor: 'transparent !important',
    borderRadius: '50% !important',
  },
})

const ImageUploaderFrame = ({
  classes,
  setImage,
  isDarkModeActive,
  height,
  pathName,
  ResizerProps,
}) => {
  const {
    maxWidth, // Is the maxWidth of the resized new image.
    maxHeight, // Is the maxHeight of the resized new image.
    compressFormat, // Is the compressFormat of the resized new image.
    quality, // Is the quality of the resized new image.
  } = ResizerProps
  const [isLoading, setIsLoading] = useState(false)
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        compressFormat || 'JPEG',
        quality || 100,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })
  const imageHandler = async (event) => {
    setIsLoading(true)
    const file = event.target.files[0]
    try {
      const uri = await resizeFile(file)
      if (uri) {
        const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop()
        const url = await uploadImage(uri, `${pathName}/${imageName}`)
        setImage(url)
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <div
      className={classes.container}
      style={{
        border: `1px dotted ${isDarkModeActive ? 'white' : 'black'}`,
        height: height ? height : 300,
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <span className={classes.changeImageIcon}>
            <Button
              component='label'
              color='inherit'
              className={classes.labelButton}
            >
              <span className={classes.camerIconWrapper}>
                <MdCamera size={23} />
              </span>
              <input
                type='file'
                hidden
                onChange={imageHandler}
                accept='image/png, image/gif, image/jpeg, image/jpg'
              />
            </Button>
          </span>
          <MdCamera size={45} className={classes.bigIcon} />
          <span className={classes.changeImageIcon}>
            <Button
              component='label'
              color='inherit'
              className={classes.labelButton}
            >
              <span className={classes.camerIconWrapper}>
                <MdCamera
                  size={90}
                  className={classes.bigIcon2}
                  color={'blue'}
                />
              </span>
              <input
                type='file'
                hidden
                onChange={imageHandler}
                accept='image/png, image/gif, image/jpeg, image/jpg'
              />
            </Button>
          </span>
        </>
      )}
    </div>
  )
}

const WrappedImageUploaderFrame = withStyles(styles)(ImageUploaderFrame)

export default WrappedImageUploaderFrame
