import axios from 'axios'
import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../../../../../../../../../../context/AuthContext'
import { FeedContext } from '../../../../../../../../../../../context/FeedContext/FeedContext'
import FormsComponents from '../../../../../../../../../../formsSystem/FormsComponents'
import NotificationCreator from '../../../../../../../../../../notificationsSystem/NotificationCreator'
import i18next from 'i18next'

const ChannelProfileSMTop = ({
  isDarkModeActive,
  setPosts,
  DCGProps,
  channel,
}) => {
  // i should concat the admins of projects associated with the channel
  const [members, setMembers] = useState([])
  useEffect(() => {
    const fetchCeoIds = async () => {
      try {
        const res = await axios.get(
          `/contracts/contractProjectsCeos/${channel?.contractId}`
        )
        setMembers(res.data.ceosIds)
      } catch (error) {
        console.log(error)
      }
    }
    if (channel?.contractId) fetchCeoIds()
  }, [channel?.contractId])
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [textContent, setTextContent] = useState('')
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const [images, setImages] = useState([])
  const { user } = useContext(AuthContext)
  const { socket } = DCGProps
  const { notification, dispatch } = useContext(FeedContext)
  const [playerLink, setPlayerLink] = useState()
  const [pollData, setPollData] = useState(null)

  const newDisucssion = {
    channelId: channel?._id,
    userId: user._id,
    postTextContent: textContent,
    chosenHashTagElements,
    images,
    feedsSystemCategory: 'channel',
    likes: [],
    playerLink,
    pollData,
  }
  const [newPost, setNewPost] = useState()
  const onSend = async () => {
    if (user._id && channel?._id) {
      try {
        const res = await axios.post(`/posts`, newDisucssion)
        setPosts((pts) => [res.data.post, ...pts])
        Promise.all(
          members?.map(async (m) => {
            socket.current.emit('addNotifUser', m)
            NotificationCreator({
              associativity: 'channel',
              relation: 'holders',
              conditionIndex: 1,
              accompaniedData: {
                elements: [
                  {
                    id: res.data.post?._id,
                    hashTagType: res.data.post?.hashTagType,
                    title: rtl ? 'مناقشة' : 'Channel Discussion',
                  },
                  {
                    id: channel?._id,
                    hashTagType: channel?.hashTagType,
                    title: channel?.Title || channel?.title,
                  },
                ],
              },
              category: 'contributors',
              userId: m,
              currentUser: user,
              socket,
            })
          })
        )
        setNewPost(res.data.post)
      } catch (error) {
        console.log(error)
      }
      if (notification?.mentionedUsersIds && newPost) {
        Promise.all(
          notification?.mentionedUsersIds
            ?.filter((id) => members?.includes(id))
            ?.map(async (id) =>
              NotificationCreator({
                associativity: 'channel',
                relation: 'holders',
                conditionIndex: 2,
                accompaniedData: {
                  elements: [
                    {
                      id: newPost?._id,
                      hashTagType: newPost?.hashTagType,
                      title: rtl ? 'مناقشة' : 'Channel Discussion',
                    },
                    {
                      id: channel?._id,
                      hashTagType: channel?.hashTagType,
                      title: channel?.Title || channel?.title,
                    },
                  ],
                },
                category: 'contributors',
                userId: id,
                currentUser: user,
                socket,
              })
            )
        )
        dispatch({
          type: 'REMOVE_MENTION',
        })
      }
    }
  }

  const formsCompsProps = {
    formComponentIndex: 1,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    placeholder: rtl ? 'مناقشة جديدة' : 'New Discussion',
    images,
    setImages,
    isDarkModeActive,
    onSend,
    pathName: `/posts/channelProfilePosts/${channel?._id}`,
    DCGProps,
    ...DCGProps,
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        onSend()
        setTextContent('')
        setImages([])
      }
    },
    link: playerLink,
    setLink: setPlayerLink,
    pollData,
    setPollData,
  }
  return <FormsComponents formsCompsProps={formsCompsProps} />
}

export default ChannelProfileSMTop
