import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  mssgWrapper: {
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  deletedName: {
    fontSize: 17,
  },
  alert: {
    fontSize: 12,
    fontWeight: 600,
  },
})

const GeneralConfirmMessg = ({ classes, confirmMessg, confirmTitle }) => {
  return (
    <section className={classes.mssgWrapper}>
      <span>{confirmTitle}</span>
      <span className={classes.alert}>{confirmMessg}</span>
    </section>
  )
}

const WrappedGeneralConfirmMessg = withStyles(styles)(GeneralConfirmMessg)

export default WrappedGeneralConfirmMessg
