import React from 'react'
import {
  CardMediaModelII,
  MemberCardModel,
  ImageCardModel,
  CardMediaModel,
  IconCardModel,
} from '../'
import StandardCard from './MFSCardsViewModels/StandardCard'
import WideCardModel from './MFSCardsViewModels/WideCardModel'

const MFSCardsView = ({ element, elementIndex, props }) => {
  const { MFSGProps } = props
  const { MiniFSModelIndex, FreeCardModel } = MFSGProps

  const MFSCardsViewModels = [
    //0 - like project modeling index - this is suitable for comapnies also
    <CardMediaModel
      element={element}
      elementIndex={elementIndex}
      props={props}
    />,
    //1 - like market analysis index
    <ImageCardModel
      element={element}
      elementIndex={elementIndex}
      props={props}
    />,
    //2- like investment offers in CEO tools
    <IconCardModel
      element={element}
      elementIndex={elementIndex}
      props={props}
    />,
    // 3- like offline contributors
    <MemberCardModel
      element={element}
      elementIndex={elementIndex}
      props={props}
    />,
    // 4 like intro
    <CardMediaModelII
      element={element}
      elementIndex={elementIndex}
      props={props}
    />,
    //5 for dyanmic designs with no specific blueprint
    FreeCardModel ? (
      <FreeCardModel
        element={element}
        elementIndex={elementIndex}
        props={props}
      />
    ) : null,
    // 6 standard, it will replace task card. job card, issue card , and resource card and will inherits the design of the task card
    <StandardCard
      element={element}
      elementIndex={elementIndex}
      props={props}
    />,
    // 7 Setting cards
    'placeholder',
    // wide card mdoe
    <WideCardModel
      element={element}
      elementIndex={elementIndex}
      props={props}
    />,
  ]

  return MFSCardsViewModels[MiniFSModelIndex]
}

export default MFSCardsView
