import React, { useState } from 'react'
import BookDialogModel from '../../bookDialogModel/BookDialogModel'
import ContractsNotifsDialogLeft from './ContractsNotificationsComponents/ContractsNotifsDialogLeft'
import ContractsNotifsDialogRight from './ContractsNotificationsComponents/ContractsNotifsDialogRight'
import i18next from 'i18next'

const ContractsNotifications = (props) => {
  const { isNotificationCardOpen, notificationCardDialogClose, notification } =
    props
  const [chosenNotification, setChosenNotification] = useState(notification)
  const notifProps = {
    chosenNotification,
    setChosenNotification,
    ...props,
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <BookDialogModel
      bookDialogTitlte={
        rtl ? 'إشعارات الإتفاقات و العقود' : 'Deals and Contracts Notifications'
      }
      bookDialogClose={notificationCardDialogClose}
      isBookDialogOpen={isNotificationCardOpen}
      bookLeft={<ContractsNotifsDialogLeft props={notifProps} />}
      bookRight={
        <ContractsNotifsDialogRight
          notifProps={{
            ...notifProps,
            notification: chosenNotification,
          }}
        />
      }
      {...notifProps}
    />
  )
}

export default ContractsNotifications
