import React from 'react'
import { AreaChart, Area, Tooltip, ResponsiveContainer, Text } from 'recharts'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'
import MultiMillify from '../../../smallComponents/MultiMillify'
import moment from 'moment'
import i18next from 'i18next'

const styles = () => ({
  label: {
    fontWeight: 500,
  },
})

const ChartBody = ({
  classes,
  parameterId,
  parametersDataArray,
  containerWidth,
  containerHeight,
  areaChartMargins,
  isDarkModeActive,
}) => {
  const formattorStyle = {
    fontStyle: 'oblique',
    color: isDarkModeActive ? 'white' : 'black',
    fontWeight: 600,
  }

  const { t } = useTranslation()
  const parametrizedCharts = parametersDataArray?.map((enData, dataIndex) => {
    const lang = i18next.language
    const rtl = lang === 'ar'
    const dataKey = enData && Object.keys(enData[0]).find((k) => k !== '_id')
    const arData = enData?.map((d) => ({
      _id: d?._id,
      [t(dataKey)]: d[dataKey],
    }))
    const data = rtl ? arData : enData

    return (
      <AreaChart
        data={data}
        margin={
          areaChartMargins
            ? areaChartMargins
            : { top: 0, left: 0, right: 0, bottom: 0 }
        }
        key={dataIndex}
      >
        <Tooltip
          formatter={(data) => (
            <span style={formattorStyle}>{MultiMillify(data)}</span>
          )}
          contentStyle={{
            borderRadius: 10,
            backgroundColor: isDarkModeActive ? 'rgba(0,0,0,0.2)' : '#c3c8ec',
          }}
          cursor={false}
          borderRadius={200}
          itemStyle={{ color: 'blue', fontWeight: 500 }}
          labelFormatter={(index) =>
            moment(data[index]?._id).format('DD MMM YYYY')
          }
          labelClassName={classes.label}
        />
        <Area
          animationBegin={800}
          animationDuration={2500}
          type='monotone'
          dataKey={t(dataKey)}
          stroke='rgb(34, 175, 218)'
          fill='rgb(10, 75, 95)'
          strokeWidth={4}
        />
        <Text textAnchor='middle' targetX={'_id'} />
      </AreaChart>
    )
  })

  return (
    <ResponsiveContainer height={containerHeight} width={containerWidth}>
      {parametrizedCharts[parameterId]}
    </ResponsiveContainer>
  )
}

const WrappedChartBody = withStyles(styles)(ChartBody)

export default WrappedChartBody
