import React from 'react'
import {
  ProductRequestReplyCover,
  ProductNewRequestsMssgCover,
  ListItemScreenViewModels,
} from '../../../'

const ProductNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'creator',
      conditions: [<ProductNewRequestsMssgCover {...notifProps} />],
    },
    {
      relation: 'visitor',
      conditions: [<ProductRequestReplyCover {...notifProps} />],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default ProductNotificationModel
