import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const DirectSaleSegments = () => {
  const { t } = useTranslation('DirectSaleSegments')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 31,
    category: t('category_ms31'),
    title: t('title_ms31'),
    subTitle: t('subTitle_ms31'),
    main: t('main_ms31'),
    how: t('how_ms31'),
    usedInModels: [],
    tags: rtl
      ? [
          'رضا العملاء',
          'B2C Marketing',
          'البيع بالجملة',
          'تجزئة السوق',
          'استراتيجية العلامة التجارية',
          'التجارة الإجتماعية',
          'التسويق الرقمي',
          'تجربة العميل',
          'الحصة السوقية',
        ]
      : [
          'Customer Satisfaction',
          'B2C Marketing',
          'Wholesale Selling',
          'Market Segmentation',
          'Branding Strategy',
          'Social Commerce',
          'Digital Marketing',
          'Customer Experience',
          'Market Share',
        ],
    examples: t('examples_ms31'),
    hashTagType: 'markStraAspect',
  }
}

export default DirectSaleSegments
