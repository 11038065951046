import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const TheSegmentOfOne = () => {
  const { t } = useTranslation('TheSegmentOfOne')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 23,
    category: t('category_ms23'),
    title: t('title_ms23'),
    subTitle: t('subTitle_ms23'),
    main: t('main_ms23'),
    how: t('how_ms23'),
    usedInModels: [],
    tags: rtl
      ? [
          "تجزئة السوق'",
          'تسويق متميز',
          'مزيج مميز',
          'هدف السوق',
          'عرض مميز',
          'الأسواق الجماعية',
          'الجزء الأول',
          "احتياجات السوق'",
          'شرائح العملاء',
          'استراتيجيات السوق',
          'احتياجات العميل',
          'مزيج السوق',
          'عروض مخصصة',
          'جهود السوق',
          'عائد الاستثمار',
        ]
      : [
          'Market Segmentation',
          'Distinct Marketing',
          'Distinct Mix',
          'Market Target',
          'Distinct Offer',
          'Mass Markets',
          'Segment One',
          'Market Needs',
          'Customer Segments',
          'Market Strategies',
          'Customer Needs',
          'Market Mix',
          'Tailored Offerings',
          'Market Efforts',
          'Return Investment',
        ],
    examples: t('examples_ms23'),
    hashTagType: 'markStraAspect',
  }
}

export default TheSegmentOfOne
