import React from 'react'
import {
  ListItemScreenViewModels,
  NewResourceContractingReqNotifCover,
} from '../../..'
import ResourceReqReplyCover from './ResourceNotificationModelComps/ResourceReqReplyCover'

const ResourceNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'resourceOwner',
      conditions: [<NewResourceContractingReqNotifCover {...notifProps} />],
    },
    {
      relation: 'resourceContractor',
      conditions: [<ResourceReqReplyCover {...notifProps} />],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default ResourceNotificationModel
