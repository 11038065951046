import React from 'react'
import {
  ProjectFollowNotif,
  NewProjectProjectMeetingRequestNotif,
  DepManagerManagerToolsNewRequests,
  MemberNewMeetingNotif,
  NewCEOAssignmentNotification,
  NewDepManagerAssignmentNotification,
  NewPollNotification,
  NewSubDepManagerAssignmentNotification,
  PollResultsNotification,
  MeetingUpdateNotice,
  DepartmentManagerNewNote,
  MemberInterDepTransfer,
  MemberInterSubDepTransfer,
  ProjectPostLike,
  ProjectPostShare,
  ProjectPostComment,
  NewProjectProjectMeetingReplyNotif,
  NewSubdDepCreation,
  NewSubDepManagmentAssignment,
  SubDepManagerManagerToolsNewRequests,
  SubDepRemovalNotif,
} from '../../../'
import ChangeSubDepNameNotif from './ProjectNotificationDisplayBodyComps/ChangeSubDepNameNotif'
import SubDepDeletionNotif from './ProjectNotificationDisplayBodyComps/SubDepDeletionNotif'
import DepContribRemovalNotif from './ProjectNotificationDisplayBodyComps/DepContribRemovalNotif'
import AddToBardNotif from './ProjectNotificationDisplayBodyComps/AddToBardNotif'
import RemoveFromBoardNotif from './ProjectNotificationDisplayBodyComps/RemoveFromBoardNotif'

const ProjectNotificationDisplayBody = ({ notifProps }) => {
  const { isDarkModeActive, notification } = notifProps
  const mssgs = [
    {
      relation: 'admin',
      comp: [
        <ProjectFollowNotif {...notifProps} />,
        <ProjectPostLike {...notifProps} />,
        <ProjectPostShare {...notifProps} />,
        <ProjectPostComment {...notifProps} />,
      ],
    },
    {
      relation: 'ceo',
      comp: [
        <NewProjectProjectMeetingRequestNotif {...notifProps} />,
        <NewProjectProjectMeetingReplyNotif {...notifProps} />,
      ],
    },
    {
      relation: 'depManager',
      comp: [<DepManagerManagerToolsNewRequests {...notifProps} />],
    },
    {
      relation: 'subDepManager',
      comp: [
        'placeholder',
        <NewSubDepManagmentAssignment {...notifProps} />,
        <SubDepManagerManagerToolsNewRequests {...notifProps} />,
      ],
    },
    {
      relation: 'member',
      comp: [
        // this one serves for both dep and subdep
        <DepartmentManagerNewNote {...notifProps} />,
        <ChangeSubDepNameNotif {...notifProps} />,
        <MemberNewMeetingNotif {...notifProps} />,
        // meeting update notice not used anay where
        <MeetingUpdateNotice {...notifProps} />,
        <MemberInterDepTransfer {...notifProps} />,
        <MemberInterSubDepTransfer {...notifProps} />,
        <NewSubdDepCreation {...notifProps} />,
        <SubDepRemovalNotif {...notifProps} />,
        <SubDepDeletionNotif {...notifProps} />,
        <DepContribRemovalNotif {...notifProps} />,
      ],
    },
    {
      relation: 'assignments',
      comp: [
        <NewCEOAssignmentNotification {...notifProps} />,
        <NewDepManagerAssignmentNotification {...notifProps} />,
        <NewSubDepManagerAssignmentNotification {...notifProps} />,
      ],
    },
    {
      relation: 'poll',
      comp: [
        <NewPollNotification {...notifProps} />,
        <PollResultsNotification {...notifProps} />,
      ],
    },
    {
      relation: 'board',
      comp: [
        <AddToBardNotif {...notifProps} />,
        <RemoveFromBoardNotif {...notifProps} />,
      ],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.find((mssg) => mssg.relation === notification.relation).comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default ProjectNotificationDisplayBody
