import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import DefaultImageGrapper from '../../../smallComponents/DefaultImageGrapper'
import WindowViewerSystemConfigs from '../../../windowViewer/WindowViewerSystemComps/WindowViewerSystemConfigs'

const DefaultGalleryImage = ({ element }) => {
  const wind = WindowViewerSystemConfigs().find(
    (con) => con.hashTagType === element.hashTagType
  )
  return (
    <LazyLoadImage
      effect='blur'
      src={DefaultImageGrapper(wind?.imageKey)}
      alt={`Default Gallery`}
      width={'100%'}
      height={'100%'}
      style={{ borderRadius: 10 }}
    />
  )
}

export default DefaultGalleryImage
