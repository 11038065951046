import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { LinearProgress } from '@mui/material'
import i18next from 'i18next'

const styles = () => ({
  topbarConatainer: {
    height: 'calc(100vh - 15px)',
    width: 'calc(100vw - 15px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    userSelect: 'none',
  },
  logo: {
    fontSize: '3rem',
    fontWeight: 'bold',
    fontFamily: "'Inter', sans-serif",
    color: 'rgb(0, 119, 181)',
    alignItems: 'center',
    display: 'flex',
  },
  linearProgress: {
    width: 180,
  },
  '@media (max-width: 600px)': {
    logo: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      alignItems: 'center',
      display: 'flex',
    },
    linearProgress: {
      width: 100,
    },
  },
})

const LazyHutupiaCover = ({ classes }) => {
  const lang = i18next.language
  return (
    <article className={classes.topbarConatainer}>
      <span className={classes.logo}>
        <span className={classes.logo}>
          {lang === 'ar' ? 'هيوتيوبيا' : 'hutupia'}
        </span>
      </span>
      <LinearProgress className={classes.linearProgress} value={10} />
    </article>
  )
}

const WrappedLazyHutupiaCover = withStyles(styles)(LazyHutupiaCover)

export default WrappedLazyHutupiaCover
