import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard } from '../../..'
import NotificationViewModel from '../../../NotificationViewModel'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const NewChannelAdmissionRequestReply = (props) => {
  const { sender, notification } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  NotificationAutoInterUpdator(props)
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <section>
                <Link to={`/profile/${sender?._id}`}>{sender.name}</Link> تم
                الرد على طلب قبولك في قناة بـ{' '}
                {notification?.accompaniedData?.response}
              </section>
            ) : (
              <section>
                <Link to={`/profile/${sender?._id}`}>{sender.name}</Link> Your
                addmission request to a channel has been replied to by{' '}
                {notification?.accompaniedData?.response}
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default NewChannelAdmissionRequestReply
