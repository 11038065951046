import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const DigitisationBusinessModel = () => {
  const { t } = useTranslation('DigitisationBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 13,
    title: t('title_bm13'),
    subTitle: t('subTitle_bm13'),
    main: t('main_bm13'),
    when: t('when_bm13'),
    who: t('who_bm13'),
    what: t('what_bm13'),
    how: t('how_bm13'),
    why: t('why_bm13'),
    examples: rtl
      ? [
          'Switzerland’s Swissquote.ch - الخدمات المصرفية الافتراضية - 1999 - سويسرا',
          'Austria’s bankdirekt.at - الخدمات المصرفية الافتراضية - 1999 - النمسا',
          'أول مباشر لبريطانيا العظمى - الخدمات المصرفية الافتراضية - 1989 - بريطانيا العظمى',
          'بنك VTB الروسي المباشر - الخدمات المصرفية الافتراضية - 1992 - روسيا',
          'فيسبوك - 2004 - الولايات المتحدة الأمريكية',
          'جوجل - 1998 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Switzerland’s Swissquote.ch - virtual banking - 1999 - Switzerland',
          'Austria’s bankdirekt.at - virtual banking - 1999 - Austria',
          'Great Britain’s First Direct - virtual banking - 1989 - Great Britain',
          'Russia’s VTB direct bank - virtual banking - 1992 - Russia',
          'Facebook - 2004 - USA',
          'Google - 1998 - USA',
        ],
    tags: rtl
      ? [
          'إنترنت',
          'اتصل بالانترنت',
          'تسويق أفضل',
          'توفر السوق',
          'رضا العملاء',
          'تكلفة منخفضة',
          'العمل عبر الإنترنت',
          'الدراسة عن بعد',
          'الأعمال الافتراضية',
        ]
      : [
          'Internet',
          'Go Online',
          'Better Marketing',
          'Market Availability',
          'Customer Satisfaction',
          'Low Cost',
          'Online Work',
          'Distance Learning',
          'Virtual Business',
        ],
    considerations: t('considerations_bm13'),
    combinations: ['$LBM10', '$LBM29', '$LBM16', '$LBM61'],
    hashTagType: 'businessModelTemp',
  }
}

export default DigitisationBusinessModel
