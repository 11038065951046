import React from 'react'
import {
  TaskTermination,
  TaskNewRequestsMssg,
  TaskMarkedDone,
  TaskModiifcationNotif,
  TaskExtensionRequest,
  TaskAssignment,
} from '../../'
import TaskMarkedDoneReply from './TaskDisplayModelsComps/TaskMarkedDoneReply'
import TaskRequestReply from './TaskDisplayModelsComps/TaskRequestReply'
import TasksCalender from './TaskDisplayModelsComps/TasksCalender'

const TaskDisplayModels = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const task = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'task' 
  )

  const notifPropsII = {
    ...notifProps,
    task,
  }
  const mssgs = [
    {
      relation: 'creator',
      comp: [
        <TaskNewRequestsMssg {...notifPropsII} />,
        <TaskMarkedDone {...notifPropsII} />,
        <TaskExtensionRequest {...notifPropsII} />,
      ],
    },
    {
      relation: 'pairedWith',
      comp: [
        <TaskModiifcationNotif {...notifPropsII} />,
        <TaskAssignment {...notifPropsII} />,
        <TaskMarkedDoneReply {...notifPropsII} />,
        <TaskTermination {...notifPropsII} />,
        <TaskRequestReply {...notifPropsII} />,
      ],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      <TasksCalender {...notifPropsII} />
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default TaskDisplayModels
