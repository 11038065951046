import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  bodyWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    margin: 10,
    padding: 10,
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.1) ',
    fontStyle: 'oblique',
  },
})

const NotificationBody = ({ classes, notificationBody }) => {
  return <div className={classes.bodyWrapper}>{notificationBody}</div>
}

const WrappedNotificationBody = withStyles(styles)(NotificationBody)

export default WrappedNotificationBody
