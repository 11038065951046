import React from 'react'
import Media from 'react-media'
import ModelUnit from './NavigatorCardsModelComps/ModelUnit'
import NavigatorCardsMob from './NavigatorCardsModelComps/NavigatorCardsMob'
import NavigatorCardsPC from './NavigatorCardsModelComps/NavigatorCardsPC'

const NavigatorCardsModel = ({
  unitsArray,
  isDarkModeActive,
  setChosenCardId,
  modelUnitProps,
  isDialogFullWidth,
}) => {
  const units = unitsArray?.map((unit, index) => (
    <ModelUnit
      key={index}
      unit={unit}
      isDarkModeActive={isDarkModeActive}
      setChosenCardId={setChosenCardId}
      unitsArray={unitsArray}
      modelUnitProps={modelUnitProps}
    />
  ))
  const mediaComponents = [
    <NavigatorCardsPC
      isDarkModeActive={isDarkModeActive}
      units={units}
      unitsArray={unitsArray}
      isDialogFullWidth={isDialogFullWidth}
    />,
    <NavigatorCardsMob
      isDarkModeActive={isDarkModeActive}
      units={units}
      unitsArray={unitsArray}
    />,
  ]
  return (
    <Media query='(max-width: 600px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  )
}

export default NavigatorCardsModel
