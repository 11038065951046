import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar } from '@mui/material'
import { Link } from 'react-router-dom'
import FreeBar from '../../../../../../../../../../../../skeleton/SkeletonComponents/FreeBar'
import axios from 'axios'
import TimeAgoFormattor from '../../../../../../../../../../../../smallComponents/TimeAgoFormattor'
import WindowProps from '../../../../../../../../../../../../windowViewer/WindowProps'
import WindowViewerSystem from '../../../../../../../../../../../../windowViewer/WindowViewerSystem'
import BulletSeparator from '../../../../../../../../../../../../smallComponents/BulletSeparator'
import i18next from 'i18next'

const styles = () => ({
  avatarNameCategory: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 10,
  },
  companyName: {
    fontSize: 18,
    fontWeight: 500,
    cursor: 'pointer',
  },
  postingTime: {
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 500,
  },
  avatar: {
    cursor: 'pointer',
    width: '50px !important',
    height: '50px !important',
  },
  '@media (max-width: 600px)': {
    companyName: {
      fontSize: 13,
    },
    postingTime: {
      fontSize: 11,
    },
    avatar: {
      cursor: 'pointer',
      width: '40px !important',
      height: '40px !important',
    },
  },
})

const ChannelProfileFeedSMDisTopLeft = ({ classes, postProps, channel }) => {
  const {
    post,
    postsUsers,
    isDarkModeActive,
    socket,
    user: postUser,
  } = postProps
  const [user, setUser] = useState(
    postUser ? postUser : postsUsers?.find((u) => u?._id === post?.userId)
  )
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  useEffect(() => {
    const fetchRepUser = async () => {
      try {
        const res = await axios.get(`/users/${post?.userId}`)
        setUser(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    if (!postUser || postUser?._id !== post?.userId) fetchRepUser()
  }, [postUser, post?.userId])
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article className={classes.avatarNameCategory}>
      <Link to={`/projectProfile/${post?.userId}`}>
        <Avatar
          className={classes.avatar}
          src={user?.profilePicture}
          sx={{ margin: rtl ? '0 0 0 10px' : '0 10px 0 0' }}
        />
      </Link>
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <span className={classes.companyName}>
          {user?.firstName ? (
            user?.firstName + ' ' + user?.lastName
          ) : (
            <FreeBar height={20} isDarkModeActive={isDarkModeActive} />
          )}
        </span>
        <span
          className={classes.postingTime}
          style={{ marginRight: !rtl && 10, marginRight: rtl && 10 }}
        >
          <TimeAgoFormattor date={post?.createdAt} />
          <BulletSeparator />
          <span className={classes.postingTime} onClick={() => windowOpener()}>
            {channel?.Title}
          </span>
        </span>
      </section>
      {channel && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
          isDarkModeActive={isDarkModeActive}
          props={{
            windowCloser,
            channel,
            DCGProps: { isDarkModeActive, socket },
          }}
          windowType={'channel'}
        />
      )}
    </article>
  )
}

const WrappedChannelProfileFeedSMDisTopLeft = withStyles(styles)(
  ChannelProfileFeedSMDisTopLeft
)

export default WrappedChannelProfileFeedSMDisTopLeft
