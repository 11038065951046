import React from 'react'
import LocalMarStarAspectDetectorElement from '../DectectorsElements/LocalMarStarAspectDetectorElement'
import DetectionCore from '../DetectionCore'

const LocalMarStarAspectDetector = (props) => {
  return (
    <DetectionCore
      {...props}
      hashTagSymbol={'$LMSA'}
      TextFormattorComp={LocalMarStarAspectDetectorElement}
      // NextDetection={ResearchActivityDetector}
      // furtherDetection
    />
  )
}

export default LocalMarStarAspectDetector