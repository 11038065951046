import React from 'react'
import { View, Text, StyleSheet, Link } from '@react-pdf/renderer'
import ExternalLinkGenerator from '../../../../../smallComponents/ExternalLinkGenerator'

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    fontFamily: 'Roboto',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
  },
  itemTitle: {
    fontSize: 10,
    flex: 1,
    fontWeight: 'bold',
    marginRight: 5,
    fontFamily: 'Roboto',
  },
  itemLink: {
    fontSize: 10,
    color: 'blue',
    fontFamily: 'Roboto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  image: {
    width: '100%',
    marginBottom: 10,
  },
  itemLinkContainer: {
    width: '70%',
  },
})

const HashTagsLinksList = ({ elementsArray }) => {
  return (
    <View>
      {elementsArray?.map((ob, i) => {
        const elementLink = decodeURIComponent(ExternalLinkGenerator(ob)) // Decode the link
        return (
          <View style={styles.itemContainer} key={i}>
            <Text style={styles.itemTitle}>{ob?.title}</Text>
            <View style={styles.itemLinkContainer}>
              <Link style={styles.itemLink} src={elementLink}>
                {elementLink}
              </Link>
            </View>
          </View>
        )
      })}
    </View>
  )
}

const HashTagsLinksPage = ({ genElement }) => {
  const elementsArray = genElement?.chosenHashTagElements?.map((h) => {
    const el = h?.mentionElement
    return {
      title: el?.title || el?.Title || el?.name || el?.ProductName,
      _id: el?.id,
      hashTagType: el?.hashTagType,
    }
  })

  return (
    <>
      <View>
        <Text style={styles.header}>Mentioned Links</Text>
      </View>
      <HashTagsLinksList elementsArray={elementsArray} />
    </>
  )
}

export default HashTagsLinksPage
