import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar } from '@mui/material'
import NotificationStatusDots from '../NotificationCoverModelComps/NotificationStatusDots'
import { RiBuilding2Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import moment from 'moment'
import i18next from 'i18next'

const styles = () => ({
  rightWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  avatarName: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: '50px !important',
    height: '50px !important',
    cursor: 'pointer !important',
  },
  name: {
    fontWeight: 500,
    fontSize: 16,
    cursor: 'pointer',
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 10,
    padding: 10,
  },

  notificationDate: {
    fontWeight: 500,
    fontSize: 13,
  },
})

const NotificationViewTop = ({
  classes,
  notification,
  sender,
  project,
  isDarkModeActive,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const image = project ? project.image : sender ? sender.image : null
  const name = project ? project.name : sender ? sender.name : null
  const dots = [
    {
      color: notification?.opened ? 'green' : 'white',
    },
    {
      color: notification?.interactedWith ? 'green' : 'white',
    },
  ]
  return (
    <section className={classes.topWrapper}>
      <section className={classes.avatarName}>
        <Link
          to={
            project
              ? `/projectProfile/${project?._id}`
              : `/profile/${sender?._id}`
          }
        >
          <Avatar
            src={image}
            className={classes.avatar}
            sx={{ marginRight: !rtl && '10px', marginLeft: rtl && '10px' }}
          >
            {project && <RiBuilding2Fill />}
          </Avatar>
        </Link>
        <Link
          to={
            project
              ? `/projectProfile/${project?._id}`
              : `/profile/${sender?._id}`
          }
        >
          <span
            className={classes.name}
            style={{ color: isDarkModeActive ? 'white' : 'black' }}
          >
            {name}
          </span>
        </Link>
      </section>
      <section className={classes.rightWrapper}>
        <NotificationStatusDots dots={dots} />
        <section className={classes.notificationDate}>
          {moment(notification.createdAt).format('MMMM Do YYYY, h:mm a')}
        </section>
      </section>
    </section>
  )
}

const WrappedNotificationViewTop = withStyles(styles)(NotificationViewTop)

export default WrappedNotificationViewTop
