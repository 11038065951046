import React from 'react'
import ViewTitle from './EditableInfoUnitComponents/ViewTitle'
import withStyles from '@mui/styles/withStyles'
import HelperText from './EditableInfoUnitComponents/HelperText'
import DateInputEditingZone from './EditableInfoUnitComponents/DateInputEditingZone'
import AutoSizeEditingZone from './EditableInfoUnitComponents/AutoSizeEditingZone'
import SavedView from './EditableInfoUnitComponents/SavedView'
import EditingButtons from './EditableInfoUnitComponents/EditingButtons'
import TimeInputEditingZone from './EditableInfoUnitComponents/TimeInputEditingZone'

const styles = () => ({
  titleWrapper: {
    marginBottom: 10,
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: 'rgba(217,224,252, 0.2)',
    padding: 15,
    borderRadius: 10,
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
})

const EditableInfoUnitMob = ({ classes, gridProps }) => {
  const { info, infoValue, editing } = gridProps
  return (
    <article
      className={classes.titleWrapper}
      style={{
        display: infoValue || info?.allowEmpty ? 'flex' : 'none',
      }}
    >
      <section className={classes.topWrapper}>
        <ViewTitle {...gridProps} />
        <EditingButtons {...gridProps} />
      </section>
      <section className={classes.body}>
        <HelperText {...gridProps} />
        <section>
          {editing ? (
            info?.type === 'date' ? (
              <DateInputEditingZone {...gridProps} />
            ) : info?.type === 'time' ? (
              <TimeInputEditingZone {...gridProps} />
            ) : (
              <AutoSizeEditingZone {...gridProps} />
            )
          ) : (
            <SavedView {...gridProps} />
          )}
        </section>
      </section>
    </article>
  )
}

const WrappedEditableInfoUnitMob = withStyles(styles)(EditableInfoUnitMob)

export default WrappedEditableInfoUnitMob
