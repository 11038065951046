import { IconButton } from '@mui/material'
import React from 'react'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md'
import ModelDialogWrappingIII from '../dialogModelUnit/ModelDialogWrappingIII'
import i18next from 'i18next'

const Activation = (props) => {
  const {
    NewFormComponent,
    newFormDialogClose,
    isNewFormDialogOpen,
    isDarkModeActive,
    clickAwayState,
    dialogTitle,
  } = props
  const dialogsArray = [
    {
      open: Boolean(isNewFormDialogOpen),
      content: NewFormComponent,
    },
  ]

  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <ModelDialogWrappingIII
      inactivateClickAway={clickAwayState}
      dialogsArray={dialogsArray}
      dialogWidth='600px'
      dialogHeight='600px'
      isDarkModeActive={isDarkModeActive}
      dialogTitle={dialogTitle}
      dialogIcon={
        <IconButton
          edge='start'
          color='inherit'
          onClick={() => {
            newFormDialogClose()
          }}
        >
          {dialogTitle && !rtl ? (
            <MdArrowRight style={{ fontSize: 20, cursor: 'pointer' }} />
          ) : (
            <MdArrowLeft style={{ fontSize: 20, cursor: 'pointer' }} />
          )}
        </IconButton>
      }
    />
  )
}
const NewFormDialogModel = (props) => {
  const { isNewFormDialogOpen } = props
  if (isNewFormDialogOpen) {
    return <Activation {...props} />
  }
}

export default NewFormDialogModel
