import React, { useContext, useEffect, useState } from 'react'
import {
  ContractHolders,
  ContractSubject,
  ContractButtons,
  AddContractElementPermission,
  ContractTerms,
} from './ContractComponents'
import ContractIntro from './ContractComponents/ContractIntro'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'

const Contract = (props) => {
  const { DCGProps, channel, contract } = props
  const { isDarkModeActive } = DCGProps
  const { user } = useContext(AuthContext)
  const [members, setMembers] = useState([])
  useEffect(() => {
    const fetchCeoIds = async () => {
      try {
        const res = await axios.get(
          `/contracts/contractProjectsCeos/${
            channel?.contractId || contract?._id || contract?.id
          }`
        )
        setMembers(res.data.ceosIds)
      } catch (error) {}
    }
    if (channel?.contractId || contract?._id || contract?.id) fetchCeoIds()
  }, [channel?.contractId, contract?._id, contract?.id])
  const propsII = {
    ...props,
    members,
  }
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        color: isDarkModeActive && 'white',
      }}
    >
      <ContractIntro {...propsII} />
      <ContractSubject {...propsII} />
      <ContractHolders {...propsII} />
      {members?.includes(user?._id) && <ContractButtons {...propsII} />}
      <AddContractElementPermission {...propsII} />
      <ContractTerms {...propsII} />
    </section>
  )
}

export default Contract
