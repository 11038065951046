import React from 'react'
import withStyles from '@mui/styles/withStyles'
import UserResult from './UserResult'
import FeedsSystem from '../../../feedsSystem/FeedsSystem'
import Skeleton from '../../../skeleton/Skeleton'
import LoadMoreButton from '../../../smallComponents/LoadMoreButton'
import DNALoader from '../../../smallComponents/DNALoader'

const styles = () => ({
  wrapper: {
    width: 600,
  },
  '@media (max-width: 600px)': {
    wrapper: {
      width: '100%',
    },
  },
})

const AllResults = ({ classes, searchProps, results }) => {
  const {
    isDarkModeActive,
    setNewSearchQuerry,
    setSearchQuerryState,
    postsUsers,
    sharedPosts,
    sharedPostsUsers,
    socket,
    setPage,
    currentResponse,
    page,
    isLoading,
  } = searchProps

  return (
    <article className={classes.wrapper}>
      {isLoading && page === 1 ? (
        <Skeleton type={'feed'} />
      ) : (
        results?.map((post, index) => {
          const postUser = postsUsers?.find((user) => user._id === post.userId)
          return post?.firstName || post?.ProjectName ? (
            <UserResult result={post} key={post?._id + index} socket={socket} />
          ) : (
            <FeedsSystem
              key={post?._id + index}
              feedsSystemProps={{
                user: postUser,
                post,
                socket,
                hideRightOptions: true,
                isDarkModeActive,
                postsUsers,
                sharedPosts,
                sharedPostsUsers,
                engineFuns: (word) => {
                  setNewSearchQuerry(word)
                  setSearchQuerryState(word)
                },
              }}
            />
          )
        })
      )}
      {currentResponse && !isLoading && <LoadMoreButton setPage={setPage} />}
      {isLoading && page > 1 && <DNALoader />}
    </article>
  )
}

const WrapepdAllResults = withStyles(styles)(AllResults)

export default WrapepdAllResults
