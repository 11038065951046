import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const FreemiumBusinessModel = () => {
  const { t } = useTranslation('FreemiumBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 22,
    title: t('title_bm22'),
    subTitle: t('subTitle_bm22'),
    main: t('main_bm22'),
    when: t('when_bm22'),
    who: t('who_bm22'),
    what: t('what_bm22'),
    how: t('how_bm22'),
    why: t('why_bm22'),
    examples: rtl
      ? [
          'Hotmail - تأسس في التسعينيات - الولايات المتحدة الأمريكية - أعمال تجارية عبر الإنترنت',
          'LinkedIn - تأسست عام 2002 - الولايات المتحدة الأمريكية',
          'سكايب - تأسست عام 2003 - صناعة الاتصالات',
          'سبوتيفي - تأسست عام 2006 - السويد - بث الموسيقى',
          'دروب بوكس - تأسست عام 2007 - الولايات المتحدة الأمريكية - استضافة الملفات',
        ]
      : [
          'Hotmail - Founded in 1990s - USA - Internet Business',
          'LinkedIn - Founded 2002 - USA',
          'Skype - Founded 2003 - Telecommunication Industry',
          'Spotify - Founded 2006 - Sweden - Music Streaming',
          'Dropbox - Founded 2007 - USA - File Hosting',
        ],
    tags: rtl
      ? [
          'تسوية الأعمال',
          'جذب العملاء',
          'تكلفة منخفضة',
          'قاعدة العملاء الأمثل',
          'حصة سوقية سريعة',
        ]
      : [
          'Business Leveling',
          'Customer Attraction',
          'Low Cost',
          'Optimized Customer Base',
          'Quick Market Share',
        ],
    considerations: t('considerations_bm22'),
    combinations: ['$LBM13', '$LBM17', '$LBM40', '$LBM63'],
    hashTagType: 'businessModelTemp',
  }
}

export default FreemiumBusinessModel
