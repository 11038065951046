import React from 'react'
import {
  NewResourceContractingReqNotif,
} from '../../'
import ResourceReqReply from './ResourceNotificationViewModelComponents/ResourceReqReply'

const ResourceNotificationViewModel = ({ notifProps }) => {
  const { isDarkModeActive, notification } = notifProps
  const mssgs = [
    {
      relation: 'resourceOwner',
      comp: [<NewResourceContractingReqNotif {...notifProps} />],
    },
    {
      relation: 'resourceContractor',
      comp: [<ResourceReqReply {...notifProps} />],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default ResourceNotificationViewModel
