import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { CSVLink } from 'react-csv'
import { Tooltip } from '@mui/material'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const DownloadCVS = ({ classes, noFSPanelProps }) => {
  const {
    downloadTippy,
    showCVSDownloader,
    data,
    headers,
    filename,
    isDarkModeActive,
  } = noFSPanelProps
  return (
    <Tooltip title={downloadTippy}>
      <span
        className={classes.iconWrapper}
        style={{ display: showCVSDownloader && data ? null : 'none' }}
      >
        <CSVLink
          data={data || []}
          target='_blank'
          headers={headers}
          filename={filename}
          style={{
            textDecoration: 'none',
            marginBottom: -5,
            padding: 0,
            color: isDarkModeActive ? 'white' : 'black',
          }}
        >
          <FaCloudDownloadAlt className={classes.icon} />
        </CSVLink>
      </span>
    </Tooltip>
  )
}

const WrappedDownloadCVS = withStyles(styles)(DownloadCVS)

export default WrappedDownloadCVS
