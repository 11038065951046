import React from 'react'
import HashTagCardsConfigs from '../../../../../suggestionsSystem/HashTagCardsConfigs'
import LocalModelDetectorElement from '../../DectectorsElements/LocalModelDetectorElement'
import DetectionCore from '../../DetectionCore'
import LocalMarStarAspectDetector from '../../Detectors/LocalMarStarAspectDetector'

const NextDetectionModel = (props) => {
  const { text } = props
  const frgmentor = () => {
    let temp = []
    text.forEach((frag) => {
      frag
        .split(' ')
        .forEach((word) => word.startsWith('$') && word && temp.push(word))
    })
    return temp
  }
  const textFragment = frgmentor()[0]
  const detector = HashTagCardsConfigs().filter((det) =>
    textFragment?.startsWith(det.hashTagSymbol)
  )[0]

  const hashTagSymbol = detector?.hashTagSymbol || undefined

  return hashTagSymbol ? (
    <DetectionCore
      {...props}
      hashTagSymbol={hashTagSymbol}
      TextFormattorComp={detector?.TextFormattorComp}
      furtherDetection={textFragment}
    />
  ) : (
    <DetectionCore
      {...props}
      hashTagSymbol={'$LBM'}
      TextFormattorComp={LocalModelDetectorElement}
      NextDetection={LocalMarStarAspectDetector}
      furtherDetection
    />
  )
}

export default NextDetectionModel
