import React, { useContext, useState } from 'react'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import CentralTitle from '../../../smallComponents/CentralTitle'
import axios from 'axios'
import { AuthContext } from '../../../../context/AuthContext'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import FileSystemGetOutAction from '../../FileSystemGetOutAction'
import ToastifyGeneralError from '../../../smallComponents/ToastifyGeneralError'
import i18next from 'i18next'

const RemoveFromBoardDialogContent = ({ noFSPanelProps, windowCloser }) => {
  const { socket, member, project, isDarkModeActive } = noFSPanelProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: windowCloser,
      disabled: isLoading,
    },
    {
      title: rtl ? 'إزالة' : 'Remove',
      disabled: isLoading,
      funs: async () => {
        try {
          setIsLoading(true)
          await axios.patch(
            `/projectContributors/${member?.userId}/${project?._id}`,
            {
              MembershipState: 'Contributor',
            }
          )

          socket.current.emit('addNotifUser', member?.userId)
          NotificationCreator({
            associativity: 'project',
            relation: 'board',
            conditionIndex: 1,
            accompaniedData: {
              projectId: project?._id,
            },
            category: 'contributors',
            userId: member?.userId,
            currentUser: user,
            socket,
          })
          setIsLoading(false)
          windowCloser()
          FileSystemGetOutAction(noFSPanelProps)
        } catch (error) {
          console.log(error)
          ToastifyGeneralError(error)
        }
      },
    },
  ]
  return (
    <article style={{ color: isDarkModeActive && 'white' }}>
      <CentralTitle
        title={rtl ? 'إزالة من مجلي الإدارة' : 'End Contribution'}
      />
      <p>
        {rtl
          ? 'عند قرارك بإزالة عضو من مجلس الإدارة، سيتم إخطار العضو المعني تلقائياً بإجراء الإزالة بمجرد تنفيذ القرار. قد يكون من المفيد التفكير في إجراء استطلاع لآراء باقي أعضاء مجلس الإدارة قبل إجراء القرار بإزالة شخص ما من المجلس.'
          : 'If you decide to remove a board member, the board member will be automatically notified of their removal once the action is taken. It may be helpful to consider sending a poll to other board members before making the decision to remove someone from the board.'}
      </p>
      <ButtonsSystem buttonsArray={buttonsArray} />
    </article>
  )
}

export default RemoveFromBoardDialogContent
