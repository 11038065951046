import { Input } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  input: {
    margin: '0',
    width: '90%',
    borderRadius: 10,
    padding: '4px 10px',
    fontWeight: 500,
  },

  '@media (max-width: 600px)': {
    input: {
      fontSize: 12,
    },
  },
})

const NumberInputEditingZone = ({
  classes,
  isDarkModeActive,
  setInfoValue,
  infoValue,
}) => {
  return (
    <Input
      className={classes.input}
      sx={{
        color: isDarkModeActive ? 'white' : 'black',
        backgroundColor: isDarkModeActive
          ? 'rgb(255, 255, 255, 0.1)'
          : 'rgba(217,224,252, 0.2)',
      }}
      type='number'
      defaultValue={infoValue}
      onChange={(event) => setInfoValue(event.target.value)}
    />
  )
}

const WrappedNumberInputEditingZone = withStyles(styles)(NumberInputEditingZone)

export default WrappedNumberInputEditingZone