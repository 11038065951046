import { useState, useEffect } from 'react'

const StateGenerator = (propsPackages) => {
  //general dialoge funs
  const [isGeneralDialogOpen, setIsGeneralDialogOpen] = useState(false)
  const GeneralDialogOpener = () => {
    setIsGeneralDialogOpen(true)
  }
  const GeneralDialogClose = () => {
    setIsGeneralDialogOpen(false)
  }

  //Chat dialoge funs
  const [isChatDialogOpen, setIsChatDialogOpen] = useState(false)
  const chatDialogOpener = () => {
    setIsChatDialogOpen(true)
  }
  const chatDialogClose = () => {
    setIsChatDialogOpen(false)
  }

  // new product form
  const [isNewProductFormOpen, setIsNewProductFormOpen] = useState(false)
  const newProductFormOpener = () => {
    setIsNewProductFormOpen(true)
  }
  const newProductFormClose = () => {
    setIsNewProductFormOpen(false)
  }
  // new task form
  const [isNewTaskFormOpen, setIsNewTaskFormOpen] = useState(false)

  const newTaskFormOpener = () => {
    setIsNewTaskFormOpen(true)
  }

  const newTaskFormClose = () => {
    setIsNewTaskFormOpen(false)
  }

  // new job form
  const [isNewJobFormOpen, setIsNewJobFormOpen] = useState(false)

  const newJobFormOpener = () => {
    setIsNewJobFormOpen(true)
  }

  const newJobFormClose = () => {
    setIsNewJobFormOpen(false)
  }

  // contract template
  const [isContractTemplateOpen, setIsContractTemplateOpen] = useState(false)

  const contractTemplateOpener = () => {
    setIsContractTemplateOpen(true)
  }

  const contractTemplateClose = () => {
    setIsContractTemplateOpen(false)
  }

  // confirm dialog funs
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsConfirmDialogOpen(false)
      if (typeof propsPackages.timeOutFun === 'function' && isConfirmDialogOpen)
        propsPackages.timeOutFun()
    }, 10000)
  }, [isConfirmDialogOpen === true])
  const [clickedButtonId, setclickedButtonId] = useState(0)

  const confirmDialogOpener = () => {
    setIsConfirmDialogOpen(true)
  }

  const GeneralProps = propsPackages.GeneralProps
    ? { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose }
    : {}
  const chatProps = propsPackages.chatProps
    ? { isChatDialogOpen, chatDialogOpener, chatDialogClose }
    : {}

  const productFormProps = propsPackages.productFormProps
    ? {
        isNewProductFormOpen,
        newProductFormOpener,
        newProductFormClose,
      }
    : {}
  const taskFormProps = propsPackages.taskFormProps
    ? { isNewTaskFormOpen, newTaskFormOpener, newTaskFormClose }
    : {}
  const jobFormProps = propsPackages.jobFormProps
    ? {
        isNewJobFormOpen,
        newJobFormOpener,
        newJobFormClose,
      }
    : {}
  const contractTempProps = propsPackages.contractTempProps
    ? {
        isContractTemplateOpen,
        contractTemplateOpener,
        contractTemplateClose,
      }
    : {}
  const confirmDialogProps = propsPackages.confirmDialogProps
    ? {
        isConfirmDialogOpen,
        setIsConfirmDialogOpen,
        clickedButtonId,
        setclickedButtonId,
        confirmDialogOpener,
      }
    : {}
  return {
    ...chatProps,
    ...productFormProps,
    ...taskFormProps,
    ...jobFormProps,
    ...contractTempProps,
    ...confirmDialogProps,
    ...GeneralProps,
  }
}

export default StateGenerator
