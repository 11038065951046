import React from 'react'
import { ListItemScreenViewModels } from '../../..'
import GlobalInquiryOfferCover from './GlobalNotificationModelComps/GlobalInquiryOfferCover'
import GlobalInquiryOfferReplyCover from './GlobalNotificationModelComps/GlobalInquiryOfferReplyCover'
import GlobalInquiryRemoveContributorNotifCover from './GlobalNotificationModelComps/GlobalInquiryRemoveContributorNotifCover'

const GlobalNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'globalCreator',
      conditions: [<GlobalInquiryOfferCover {...notifProps} />],
    },
    {
      relation: 'globalReviewer',
      conditions: [
        <GlobalInquiryOfferReplyCover {...notifProps} />,
        <GlobalInquiryRemoveContributorNotifCover {...notifProps} />,
      ],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default GlobalNotificationModel
