const GPDFTextHashModifier = ({ button, text }) => {
  const { element, strategy, model, contract, plan } = button.PDFGenProps
  const genElement = element || strategy || plan || model || contract
  const chosenHashTagElements = genElement?.chosenHashTagElements
  const modifiedContent = chosenHashTagElements?.reduce((acc, curr) => {
    const identifier = curr.identifier.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const replacement = (
      curr.mentionElement.title ||
      curr.mentionElement.Title ||
      curr.mentionElement.name ||
      curr.mentionElement.ProductName
    )?.replace(/[$&]/g, '\\$&')

    return acc?.replace(new RegExp(identifier, 'g'), replacement)
  }, text)

  return modifiedContent
}

export default GPDFTextHashModifier
