import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import moment from 'moment'
import FreeBar from '../../../skeleton/SkeletonComponents/FreeBar'
import CommentMorevert from './CommentTopComps/CommentMorevert'
import SmallButtonWrapper from '../../../smallComponents/SmallButtonWrapper'
import { Link } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { AuthContext } from '../../../../context/AuthContext'
import { MdVerified } from 'react-icons/md'
import i18next from 'i18next'

const styles = () => ({
  topWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commentTime: {
    fontSize: 12,
    fontWeight: 400,
  },
  commentAuthorName: {
    fontWeight: 500,
    margin: 'auto 10px',
  },
  namAvatar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const CommentTop = ({ classes, ...props }) => {
  const { comment, commentUser, setShowPost, originalPostOption } = props
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div className={classes.topWrapper}>
      <div className={classes.namAvatar}>
        <Link to={`/profile/${commentUser?._id}`}>
          <Avatar
            src={commentUser?.profilePicture}
            className={classes.avatar}
          />
        </Link>
        <section className={classes.commentAuthorName}>
          <div style={{ fontWeight: 500 }}>
            {commentUser?.firstName ? (
              commentUser?.firstName + ' ' + commentUser?.lastName
            ) : (
              <div style={{ width: '20%' }}>
                <FreeBar height={15} {...props} width={'100px'} />
              </div>
            )}
            {commentUser?.verified && (
              <MdVerified color='blue' style={{ margin: '2px 5px -2px 5px' }} />
            )}
          </div>
          <span className={classes.commentTime}>
            {moment(comment?.createdAt).fromNow()}
          </span>
        </section>
      </div>
      {comment?.userId === user?._id && (
        <SmallButtonWrapper
          {...props}
          button={
            <section
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: !originalPostOption && 'none',
                  fontSize: 10,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (typeof setShowPost === 'function')
                    setShowPost((show) => !show)
                }}
              >
                {rtl ? 'عرض المنشور' : 'Show Post'}
              </div>
              <CommentMorevert {...props} />
            </section>
          }
        />
      )}
    </div>
  )
}

const WrappedCommentTop = withStyles(styles)(CommentTop)

export default WrappedCommentTop
