import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Button, OutlinedInput } from '@mui/material'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import i18next from 'i18next'

const styles = () => ({
  input: {
    height: 35,
    width: '100%',
    marginTop: 10,
  },
})

const SPSHeaderEditingDialog = ({
  classes,
  setHeaders,
  headers,
  isDarkModeActive,
  windowCloser,
  headerIndex,
}) => {
  const [change, setChange] = useState(headers[headerIndex])
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'تعديل' : 'Update',
      funs: () => {
        let newHeaders = headers
        newHeaders[headerIndex] = change
        setHeaders(newHeaders)
        windowCloser()
      },
    },
    {
      title: rtl ? 'إزالة' : 'Remove',
      funs: () => {
        setHeaders(headers.filter((_, i) => i !== headerIndex))
        windowCloser()
      },
      disbaled: headerIndex === 1,
    },
    {
      title: rtl ? 'عمود جديد' : 'New Column',
      funs: () => {
        setHeaders((h) => [...h, change])
        windowCloser()
      },
    },
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: () => {
        windowCloser()
      },
    },
  ]
  return (
    <>
      <OutlinedInput
        className={classes.input}
        onChange={(event) => {
          setChange(event.currentTarget.value)
        }}
        value={change || ''}
        placeholder={rtl ? 'أضف رابط' : 'Add link'}
        style={{
          color: isDarkModeActive ? 'white' : 'black',
          borderRadius: 20,
          marginRight: 5,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setHeaders((h) => [...h, change])
        }}
      />
      <ButtonsSystem buttonsArray={buttonsArray} />
    </>
  )
}

const WrappedSPSHeaderEditingDialog = withStyles(styles)(SPSHeaderEditingDialog)

export default WrappedSPSHeaderEditingDialog
