import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const NewChannelPostCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl
          ? `قام ${sender?.name} بنشر مناقشة جديدة فى قناة مشتركة`
          : `${sender?.name} has started a new discussion in a channel`
      }
    />
  )
}

export default NewChannelPostCover
