import React from 'react'
import { Button } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import withStyles from '@mui/styles/withStyles'
import { MdAdd, MdRemove } from 'react-icons/md'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row'
  },
})

const SpreadGridAddRowButton = ({ setRows, classes }) => {
  return (
    <div className={classes.buttonsWrapper}>
      <Button
        className={classes.button}
        onClick={() => setRows((rs, index) => [...rs, { id: uuidv4() }])}
      >
        <MdAdd size={15} />
      </Button>
      <Button
        className={classes.button}
        onClick={() =>
          setRows((rs, index) => rs?.filter((_, i) => i < rs?.length - 1))
        }
      >
        <MdRemove size={15} />
      </Button>
    </div>
  )
}

const WrappedSpreadGridAddRowButton = withStyles(styles)(SpreadGridAddRowButton)

export default WrappedSpreadGridAddRowButton
