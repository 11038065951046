import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  mssgWrapper: {
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  deletedName: {
    fontSize: 17,
  },
  alert: {
    fontSize: 12,
    fontWeight: 600,
  },
})

const DeleteMessage = ({ classes, deletedObjectName, deletionAlert }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <section className={classes.mssgWrapper}>
      <span>
        {rtl
          ? 'هل انت متأكد من الرغبة فى مسح :'
          : 'Are You Sure You Want to Delete :'}
        <span className={classes.deletedName}> "{deletedObjectName}" </span>
      </span>
      <span className={classes.alert}>{deletionAlert}</span>
    </section>
  )
}

const WrappedDeleteMessage = withStyles(styles)(DeleteMessage)

export default WrappedDeleteMessage
