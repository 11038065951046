import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import EditableGridModel from '../../../../../../../formsSystem/EditableGridModel'
import Loading from '../../../../../../../smallComponents/Loading'
import i18next from 'i18next'

const ProductTechnicals = ({
  product,
  isDarkModeActive,
  membersProfiles,
  DCGProps,
  subDepId,
}) => {
  const [designer, setDesigner] = useState(
    membersProfiles?.find((p) => p._id === product?.ProductDesignerId)
  )
  useEffect(() => {
    const fetchDesigner = async () => {
      try {
        const res = await axios.get(
          `/users/oneUser/${product?.ProductDesignerId}`
        )
        setDesigner(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    if (!designer) fetchDesigner()
  }, [product?.ProductDesignerId])
  const productDesigner = designer?.firstName + ' ' + designer?.lastName
  const lang = i18next.language
  const rtl = lang === 'ar'
  const productInfo = [
    {
      title: 'Product_Designer',
      titleValue: productDesigner || product?.productCreator,
    },
    {
      title: 'Addition_Date',
      titleValue: moment(product.createdAt).calendar(),
    },
  ]

  const gridProps = {
    headerText: rtl ? 'تفاصيل تقنية' : 'Product Technicals',
    infoArray: productInfo,
    isDarkModeActive,
    hideEditingTools: true,
    departmentId: 0,
    subDepId,
    ...DCGProps,
  }
  return designer ? <EditableGridModel gridProps={gridProps} /> : <Loading />
}

export default ProductTechnicals
