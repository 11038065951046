import React from 'react'
import GlobalInquiryOffer from './GlobalNotificationViewModelComps/GlobalInquiryOffer'
import GlobalInquiryOfferReply from './GlobalNotificationViewModelComps/GlobalInquiryOfferReply'
import GlobalInquiryRemoveContributorNotif from './GlobalNotificationViewModelComps/GlobalInquiryRemoveContributorNotif'

const GlobalNotificationViewModel = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const globalinquiry = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'globalInquiry'
  )
  const notifPropsII = {
    ...notifProps,
    globalinquiry,
  }
  const mssgs = [
    {
      relation: 'globalCreator',
      comp: [<GlobalInquiryOffer {...notifPropsII} />],
    },
    {
      relation: 'globalReviewer',
      comp: [
        <GlobalInquiryOfferReply {...notifPropsII} />,
        <GlobalInquiryRemoveContributorNotif {...notifPropsII} />,
      ],
    },
  ]
  return (
    <article
      style={{ color: isDarkModeActive ? 'white' : null, width: '100%' }}
    >
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default GlobalNotificationViewModel
