import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ChannelProfileFeedSMDisTopLeft from './ChannelProfileFeedSMDisTopComps/ChannelProfileFeedSMDisTopLeft'
import ChannelProfileFeedSMDisTopRight from './ChannelProfileFeedSMDisTopComps/ChannelProfileFeedSMDisTopRight'
import axios from 'axios'

const styles = () => ({
  topWrapper: {
    marginRight: 10,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'space-between',
    // width: '100%',
    alignItems: 'center',
  },
  '@media (max-width: 600px)': {
    topWrapper: {
      marginRight: 0,
      marginLeft: 0,
      justifyContent: 'space-between',
      width: 'auto',
      alignItems: 'center',
    },
  },
})

const ChannelProfileFeedSMDisTop = ({ classes, postProps }) => {
  const { post, channel: incomingChannel } = postProps
  const [channel, setChannel] = useState(incomingChannel)
  useEffect(() => {
    const fetchPostChannel = async () => {
      try {
        const res = await axios.get(`/channels/${post?.channelId}`)
        setChannel(res.data.channel)
      } catch (error) {
        console.log(error)
      }
    }
    if (post?.channelId && !incomingChannel) fetchPostChannel()
  }, [post?.channelId, incomingChannel])
  return (
    <article className={classes.topWrapper}>
      <ChannelProfileFeedSMDisTopLeft postProps={postProps} channel={channel} />
      <ChannelProfileFeedSMDisTopRight
        postProps={postProps}
        channel={channel}
      />
    </article>
  )
}

const WrappedChannelProfileFeedSMDisTop = withStyles(styles)(
  ChannelProfileFeedSMDisTop
)

export default WrappedChannelProfileFeedSMDisTop
