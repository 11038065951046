import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { NotificationViewModel } from '../../..'
import { AuthContext } from '../../../../../../context/AuthContext'
import DepartmentsEnumArray from '../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/DepartmentsEnumArray'
import NotificationCreator from '../../../../NotificationCreator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}
const NewProjectContributionOffer = (props) => {
  const { socket, project, sender, notification } = props
  const [interactedWith, setInteractedWith] = useState(
    notification?.interactedWith
  )
  const { user, dispatch } = useContext(AuthContext)
  const alreadyInProject = user?.contractedProjectsIds?.includes(project?._id)
  const [isLoading, setIsLoading] = useState()
  const actionFun = async (response) => {
    setIsLoading(true)
    socket.current.emit('addNotifUser', sender?._id)
    NotificationCreator({
      associativity: 'contribution',
      relation: 'newProjectOwner',
      conditionIndex: 0,
      accompaniedData: {
        projectId: project?._id,
        response,
      },
      category: 'contributors',
      userId: sender?._id,
      currentUser: user,
      socket,
    })
    await axios.patch(`/notifications/${notification?._id}`, {
      interactedWith: 'true',
    })
    setInteractedWith(true)
    setIsLoading(false)
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: isLoading
        ? rtl
          ? 'جارى إرسال الرد...'
          : 'Sending...'
        : rtl
        ? 'قبول'
        : 'Accept',
      funs: async () => {
        actionFun('accepted')
        const resCont = await axios.post(`/projectContributors`, {
          projectId: project?._id,
          userId: user?._id,
          departmentId: Number(notification?.accompaniedData?.departmentId),
        })
        //  notifyin the project owner that his offer hs been accepted
        if (resCont?.data?.contributor)
          NotificationCreator({
            associativity: 'contribution',
            relation: 'newProjectOwner',
            conditionIndex: 0,
            accompaniedData: {
              projectId: project?._id,
              MembershipState: resCont?.data?.contributor?.MembershipState,
            },
            category: 'contributors',
            userId: sender?._id,
            currentUser: user,
            socket,
          })
        // adding the project to the users data
        if (!user?.contractedProjectsIds.includes(project?._id)) {
          await axios.patch(`/users/${user?._id}`, {
            userId: user?._id,
            contractedProjectsIds: [
              ...user?.contractedProjectsIds,
              project?._id,
            ],
          })
          if (!user?.followedProjects?.includes(project?._id)) {
            const res = await axios.patch(`/users/${user?._id}`, {
              userId: user?._id,
              followedProjects: [...user?.followedProjects, project?._id],
            })
            dispatch({
              type: 'UPDATE',
              payload: res.data.user,
            })
          }
        }
      },
      disabled: interactedWith || isLoading || alreadyInProject,
      descProps: {
        acceptIndex: 0,
      },
    },
    {
      title: isLoading
        ? rtl
          ? 'جارى إرسال الرد...'
          : 'Sending...'
        : rtl
        ? 'رفض'
        : 'Refuse',
      funs: () => actionFun('refused'),
      disabled: interactedWith || isLoading || alreadyInProject,
      descProps: {
        refuseIndex: 3,
      },
    },
  ]
  const department = DepartmentsEnumArray.find(
    (dep) => dep.id === Number(notification?.accompaniedData?.departmentId)
  )?.title
  const { t } = useTranslation()
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={
        alreadyInProject
          ? rtl
            ? 'انت بالفعل مشترك بهذا المشروع'
            : 'You are already in this project'
          : ''
      }
      notificationBody={
        rtl ? (
          <div>
            <Link to={`/profile/${sender?._id}`} style={style}>
              {sender?.name}
            </Link>{' '}
            يعرض عليك منصبا فى قسم {t(department)}{' '}
            <Link to={`/projectProfile/${project?._id}`} style={style}>
              {project?.ProjectName}
            </Link>{' '}
            يمكنك قبول هذا العرض عن طريق الرد بالقبول ، وبعد ذلك سيرسل لك عقدًا
            للموافقة عليه.
            <br />
            بقبولك ، سيتم تسجيلك تلقائيًا في قائمة المساهمين في المشروع ، ومع
            ذلك لن تتمكن من الوصول حتى يتم إرسال العقد إليك
          </div>
        ) : (
          <div>
            <Link to={`/profile/${sender?._id}`} style={style}>
              {sender?.name}
            </Link>{' '}
            offered you a position in {department} department of{' '}
            <Link to={`/projectProfile/${project?._id}`} style={style}>
              {project?.ProjectName}
            </Link>{' '}
            project, you may accept this offer by replying by accept, after
            which he will send you a contract to agree on.
            <br />
            By accepting you will be automatically enrolled to the project
            contributors list, yet you will not have access untill the contract
            is sent to you
          </div>
        )
      }
      buttonsArray={buttonsArray}
    />
  )
}

export default NewProjectContributionOffer
