import { Grid } from '@mui/material'
import i18next from 'i18next'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const FourImagesModel = ({ imagesArray, openFun }) => {
  const lastThressImages = imagesArray.filter((_, index) => index !== 0)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <LazyLoadImage
        effect='blur'
        src={imagesArray[0]}
        alt='Images Viewer System'
        height={300}
        width={'100%'}
        style={{
          cursor: 'pointer',
          width: '100%',
          objectFit: 'cover',
          height: '100%',
        }}
        onClick={() => {
          openFun()
        }}
      />
      <Grid container>
        {lastThressImages.map((image, imageIndex) => (
          <Grid
            item
            xs={4}
            style={{ display: 'flex', justifyContent: 'center' }}
            key={imageIndex}
          >
            <img
              src={image}
              alt='Images Viewer System'
              style={{
                width: '100%',
                cursor: 'pointer',
                objectFit: 'cover',
                height: 150,
                margin:
                  imageIndex === 0
                    ? rtl
                      ? '0 0 0 2.5px'
                      : '0 2.5px 0 0'
                    : imageIndex === 1
                    ? rtl
                      ? '0 2.5px 0 2.5px'
                      : '0 2.5px 0 2.5px'
                    : rtl
                    ? '0 2.5px 0 0'
                    : '0 0 0 2.5px',
              }}
              onClick={() => {
                openFun()
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default FourImagesModel
