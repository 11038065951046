import { Avatar } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { RiBuilding2Fill } from 'react-icons/ri'

const styles = () => ({
  avatar: {
    width: '50px !important',
    height: '50px !important',
    margin: '10px !important',
  },
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    jutifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    fontWeight: 500,
  },
})
const ShareTopProfileInfo = ({ classes, shareSystemProps, generalShare }) => {
  const { isDarkModeActive, user, project, shareTypeIndex } = shareSystemProps

  return (
    <section
      className={classes.container}
      style={{ color: isDarkModeActive ? 'white' : null }}
    >
      <span className={classes.wrapper}>
        <span className={classes.innerWrapper}>
          <Avatar
            className={classes.avatar}
            src={user?.profilePicture || project?.projectPhoto}
          >
            {project && <RiBuilding2Fill size={25} />}
          </Avatar>
          <span className={classes.name}>
            {shareTypeIndex === 0 ||
            ((shareTypeIndex === 1 || generalShare || user?.firstName) &&
              !project)
              ? user?.firstName + ' ' + user?.lastName
              : project?.ProjectName}
          </span>
        </span>
      </span>
    </section>
  )
}

const WrappedShareTopProfileInfo = withStyles(styles)(ShareTopProfileInfo)

export default WrappedShareTopProfileInfo
