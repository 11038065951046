import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const FractionalOwnershipBusinessModel = () => {
  const { t } = useTranslation('FractionalOwnershipBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 20,
    title: t('title_bm20'),
    subTitle: t('subTitle_bm20'),
    main: t('main_bm20'),
    when: t('when_bm20'),
    who: t('who_bm20'),
    what: t('what_bm20'),
    how: t('how_bm20'),
    why: t('why_bm20'),
    examples: rtl
      ? [
          'نت جيت - 1964 - الولايات المتحدة',
          'Mobility Carsharing - 2011 - سويسرا',
          'écurie25 - 2014 - فرنسا',
          'HomeBuy - 2018 - ألمانيا',
        ]
      : [
          'NetJets - 1964 - United States',
          'Mobility Carsharing - 2011 - Switzerland',
          'écurie25 - 2014 - France',
          'HomeBuy - 2018 - Germany',
        ],
    tags: rtl
      ? [
          'خدمات مكلفة',
          'مالك جزئي',
          'تقاسم الممتلكات',
          'تخصيص العملاء المستهدفين',
        ]
      : [
          'Costly Services',
          'Partial Owner',
          'Property Sharing',
          'Customize Customers Target',
        ],
    considerations: t('considerations_bm20'),
    combinations: ['$LBM68'],
    hashTagType: 'businessModelTemp',
  }
}

export default FractionalOwnershipBusinessModel
