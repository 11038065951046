import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ProductNewRequestsMssgCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={rtl ? 'طلبات جديدة لمنتج' : 'New product requests'}
    />
  )
}

export default ProductNewRequestsMssgCover
