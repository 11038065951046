import React from 'react'
import { ListItemScreenViewModels } from '../../..'
import IssueNotifsDownVoteCover from './IssueNotificationModelComps/IssueNotifsDownVoteCover'
import IssueNotifsUpVoteCover from './IssueNotificationModelComps/IssueNotifsUpVoteCover'
import NewIssueNotifsCover from './IssueNotificationModelComps/NewIssueNotifsCover'

const IssueNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'issued',
      conditions: [<NewIssueNotifsCover {...notifProps} />],
    },
    {
      relation: 'voting',
      conditions: [
        <IssueNotifsUpVoteCover {...notifProps}/>,
        <IssueNotifsDownVoteCover {...notifProps}/>,
      ],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default IssueNotificationModel
