import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const ShopInShopBusinessModel = () => {
  const { t } = useTranslation('ShopInShopBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 51,
    title: t('title_bm51'),
    subTitle: t('subTitle_bm51'),
    main: t('main_bm51'),
    when: t('when_bm51'),
    who: t('who_bm51'),
    what: t('what_bm51'),
    how: t('how_bm51'),
    why: t('why_bm51'),
    examples: rtl
      ? [
          'بوش - 1886 - ألمانيا',
          'Deutsche Post - 1995 - ألمانيا',
          'Tim Hortons Inc - 1964 - كندا',
          'تشيبو - 1949 - ألمانيا',
        ]
      : [
          'Bosch - 1886 - Germany',
          'Deutsche Post - 1995 - Germany',
          'Tim Hortons Inc - 1964 - Canada',
          'Tchibo - 1949 - Germany',
        ],
    tags: rtl
      ? [
          'الفوز للجانبين',
          'الإيرادات الثابتة',
          'تكاليف تسويق منخفضة',
          'ولاء العميل',
          'رضا العملاء',
          'عقود الامتياز',
          'مفهوم المول',
          'الوعي بالعلامة التجارية',
          'رؤية العلامة التجارية',
        ]
      : [
          'Win-win',
          'Fixed Revenues',
          'Low Marketing Costs',
          'Customer Loyalty',
          'Customer Satisfaction',
          'Franchising Contracts',
          'Mall Concept',
          'Brand Awareness',
          'Brand Visibility',
        ],
    considerations: t('considerations_bm51'),
    combinations: ['$LBM31', '$LBM12', 'LBM21', '$LBM53'],
    hashTagType: 'businessModelTemp',
  }
}

export default ShopInShopBusinessModel
