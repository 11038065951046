import React, { useEffect, useState } from 'react'
import NotificationCoverModel from '../../../NotificationCoverModel'
import Loading from '../../../../../smallComponents/Loading'
import axios from 'axios'
import MultiLangNumber from '../../../../../smallComponents/MultiLangNumber'
import i18next from 'i18next'

const LikeReplyMentionNotificationCover = ({ notification, sender }) => {
  const [comment, setComment] = useState(null)
  const commentId = notification?.accompaniedData?.replyId
  const [isLoading, setIsLoading] = useState(commentId)
  useEffect(() => {
    const fetchComment = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/comments/${commentId}`)
        setComment(res.data.comment)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (commentId) fetchComment()
  }, [commentId])
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content =
    comment?.likes?.length > 1
      ? rtl
        ? `أعجب ${sender?.name} و ${MultiLangNumber(
            comment?.likes?.length - 1
          )} أخرون بردٍ لك على تعليق`
        : `${sender?.name} and ${
            comment?.likes?.length - 1
          } others liked your reply on a comment.`
      : rtl
      ? `أعجب ${sender?.name} برد لك على تعليق`
      : `${sender?.name} liked your reply on a comment`
  return isLoading ? (
    <Loading />
  ) : (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default LikeReplyMentionNotificationCover
