import React, { useState } from 'react'
import { Button } from '@mui/material'
import { MdCamera } from 'react-icons/md'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import ImageUploaderSystem from '../../../uploadImageSystem/ImageUploaderSystem'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})

const ImagesEditableGalleryForm = ({ classes, formProps, DCGProps }) => {
  const { GeneralDialogClose, handleClose, onContinue, newImages } = formProps
  const [isUploading, setIsUploading] = useState(false)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: () => {
        GeneralDialogClose()
        handleClose()
      },
    },
    {
      title: rtl ? 'إستمرار' : 'Continue',
      funs: async () => {
        onContinue()
        GeneralDialogClose()
      },
      disabled: !newImages?.length || isUploading,
    },
  ]
  return (
    <article>
      <section className={classes.buttonsWrapper}>
        <Button variant='outlined' className={classes.button}>
          <ImageUploaderSystem
            imageIcon={<MdCamera size={20} />}
            funtionalityIndex={0}
            setIsUploading={setIsUploading}
            {...formProps}
          />
        </Button>
      </section>
      <ImageUploaderSystem funtionalityIndex={1} {...formProps} />
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </article>
  )
}

const WrappedImagesEditableGalleryForm = withStyles(styles)(
  ImagesEditableGalleryForm
)

export default WrappedImagesEditableGalleryForm
