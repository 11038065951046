import React from 'react'
import { NotificationViewModel } from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const MemberInterSubDepTransfer = (props) => {
  NotificationAutoInterUpdator(props)
  const { project } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <section style={{ width: '100%' }}>
          {rtl
            ? `لقد تم نقلك الى قسم فرعى جديد فى ${project?.name}`
            : `You have been transfered to a new sub-department in ${project?.name}`}
        </section>
      }
      buttonsArray={[]}
    />
  )
}

export default MemberInterSubDepTransfer
