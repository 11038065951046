import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'

const FinStatPDFBody = ({ button }) => {
  const { element } = button.PDFGenProps
  const configs = Object.keys(element?.FinStatProps).map((key) => ({
    title: key.split('-').join(' '),
    titleValue: element?.FinStatProps[key],
    disableTrans: true,
  }))
  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
      }}
      button={button}
    />
  )
}

export default FinStatPDFBody
