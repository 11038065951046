import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  NotificationBodyWithAccessCard,
  NotificationViewModel,
  WindowProps,
  WindowViewerSystem,
} from '../../..'
import { AuthContext } from '../../../../../../context/AuthContext'
import NotificationCreator from '../../../../NotificationCreator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}
const NewProjectContributionOfferReply = (props) => {
  const { sender, project, notification, socket, isDarkModeActive } = props
  const { user } = useContext(AuthContext)
  const [interactedWith, setInteractedWith] = useState(
    notification?.interactedWith
  )
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const [contract, setContract] = useState()
  const [isLoading, setIsLoading] = useState()

  const response = notification?.accompaniedData?.response
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: isLoading
        ? rtl
          ? 'جارى إنشاء عقد...'
          : 'Generating Contract...'
        : rtl
        ? 'تعاقد'
        : 'Contract',
      funs: async () => {
        setIsLoading(true)
        const resContract = await axios.post(`/contracts`, {
          projectsIds: project?._id,
          usersIds: sender?._id,
          Title: `${project?.name} - ${sender?.name} Contract`,
        })
        setContract(resContract?.data?.contract)
        if (resContract)
          await axios.patch(`/notifications/addElement/${notification?._id}`, {
            title: resContract?.data?.contract?.Title,
            id: resContract?.data?.contract?._id,
            hashTagType: resContract?.data?.contract?.hashTagType,
          })
        windowOpener()
        setInteractedWith(true)
        await axios.patch(`/notifications/${notification?._id}`, {
          interactedWith: true,
        })
        // adding the contract to the project contributor profile
        await axios.patch(
          `/projectContributors/${sender?._id}/${project?._id}`,
          {
            contractId: resContract?.data?.contract?._id,
          }
        )
        socket.current.emit('addNotifUser', sender?._id)
        NotificationCreator({
          associativity: 'activeContract',
          relation: 'opponent',
          conditionIndex: 0,
          accompaniedData: {
            projectId: project?._id,
            elements: [
              {
                title: resContract?.data?.contract?.Title,
                id: resContract?.data?.contract?._id,
                hashTagType: resContract?.data?.contract?.hashTagType,
              },
            ],
          },
          category: 'deals',
          userId: sender?._id,
          currentUser: user,
          socket,
        })
        setIsLoading(false)
      },
      disabled: interactedWith || isLoading,
    },
  ]
  return (
    <>
      <NotificationViewModel
        {...props}
        learningSectionContent={''}
        notificationBody={
          <NotificationBodyWithAccessCard
            {...props}
            notificationBodyContent={
              response === 'accepted' ? (
                rtl ? (
                  <div>
                    <Link to={`/profile/${sender?._id}`} style={style}>
                      {sender?.name}
                    </Link>{' '}
                    وافق على المشاركة فى مشروعك{' '}
                    <Link to={`/projectProfile/${project?._id}`} style={style}>
                      {project?.name}
                    </Link>
                    ،يمكنك الآن إرسال عقد المساهمة إليه ، للموافقة على الشروط ،
                    يمكنك إرسال العقد ذهابًا وإيابًا عدة مرات حتى يتم الوصول إلى
                    الموافقة.
                  </div>
                ) : (
                  <div>
                    <Link to={`/profile/${sender?._id}`} style={style}>
                      {sender?.name}
                    </Link>{' '}
                    accepted your offer of contribution to{' '}
                    <Link to={`/projectProfile/${project?._id}`} style={style}>
                      {project?.name}
                    </Link>
                    , you may now send him contribution contract, to agree on
                    the terms, you may send the contract back and forth many
                    times until consent has been reached.
                  </div>
                )
              ) : rtl ? (
                <div>
                  <Link to={`/profile/${sender?._id}`} style={style}>
                    {sender?.name}
                  </Link>{' '}
                  قام برفض عرضك للمشاركة فى{' '}
                  <Link to={`/projectProfile/${project?._id}`} style={style}>
                    {project?.name}
                  </Link>
                </div>
              ) : (
                <div>
                  <Link to={`/profile/${sender?._id}`} style={style}>
                    {sender?.name}
                  </Link>{' '}
                  has refused your offer of contribution to{' '}
                  <Link to={`/projectProfile/${project?._id}`} style={style}>
                    {project?.name}
                  </Link>
                </div>
              )
            }
          />
        }
        buttonsArray={buttonsArray}
      />
      {contract && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
          {...props}
          props={{ contract, DCGProps: { socket, isDarkModeActive } }}
          windowType='Contract'
        />
      )}
    </>
  )
}

export default NewProjectContributionOfferReply
