import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'
import axios from 'axios'
import AllHashtagsCounts from '../../../rightbar/rightbar-Components/RightbarChartComponents/ChartProgressiveEngineComponents/AllHashtagsCounts'
import TextDirectionDetector from '../../../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'
import { MdTrendingUp } from 'react-icons/md'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    alignContent: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: 'rgba(217,224,252, 0.2) ',
  },
  defaultCard: {
    width: '60%',
    borderRadius: '10px !important',
  },
  hashTag: {
    fontSize: 24,
  },
  '@media (max-width: 600px)': {
    wrapper: {},
    defaultCard: {
      width: '100%',
    },
  },
})

const SearchDefaultView = ({
  classes,
  isDarkModeActive,
  setNewSearchQuerry,
  setSearchQuerryState,
}) => {
  const [hashtags, setHashtags] = useState([])
  useEffect(() => {
    const fetchHashtags = async () => {
      try {
        const res = await axios.get(`/searchSystems/hashtags/highest`)
        setHashtags(res.data.hashtags)
      } catch (error) {
        console.log(error)
      }
    }
    fetchHashtags()
  }, [])

  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article className={classes.wrapper}>
      <Card
        className={classes.defaultCard}
        sx={{
          background: isDarkModeActive ? 'rgb(49, 48, 53, 0.3)' : 'white',
          color: isDarkModeActive ? 'white' : null,
        }}
      >
        <section
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            padding: 10,
          }}
        >
          <span
            style={{
              marginRight: !rtl && 10,
              marginLeft: rtl && 10,
              fontWeight: 500,
            }}
          >
            {rtl ? 'الأكثر شيوعاً' : 'Trending'}
          </span>
          <MdTrendingUp size={30} color={'blue'} />
        </section>
        {hashtags.map((hash, index) => (
          <section
            key={index}
            style={{ cursor: 'pointer', fontWeight: 500, padding: 7 }}
            dir={TextDirectionDetector(hash?._id)}
            onClick={() => {
              setSearchQuerryState(hash?._id)
              setNewSearchQuerry(hash?._id)
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 10,
                padding: 10,
                backgroundColor: isDarkModeActive
                  ? 'rgba(255,255,255,0.2)'
                  : 'rgba(64,136,230,0.2)',
              }}
            >
              <div>
                {index + 1} - {hash?._id}
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  borderRadius: 15,
                  backgroundColor: isDarkModeActive
                    ? 'rgba(255,255,255,0.3)'
                    : 'rgba(64,136,230,0.3)',
                }}
              >
                {hash?.count}
              </div>
            </div>
          </section>
        ))}
      </Card>
    </article>
  )
}

const WrappedSearchDefaultView = withStyles(styles)(SearchDefaultView)

export default WrappedSearchDefaultView
