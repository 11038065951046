import React from 'react'
import '../skeleton.css'
import withStyles from '@mui/styles/withStyles'
import DarkModeColorCoder from '../../smallComponents/DarkModeColorCoder'
import FreeBar from './FreeBar'
import WindowSize from '../../smallComponents/WindowSize'
import i18next from 'i18next'

const styles = () => ({
  postContainer: {
    width: '100%',
    borderRadius: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 5,
  },
  postWrapper: {
    padding: 0,
    borderRadius: 20,
    animation: 'skeleton 1s ease infinite alternate',
  },
  buttonWrapper: {
    display: 'flex',
    height: 40,
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    borderRadius: '20px',
    backgroundColor: '#313131',
    margin: '10px 5px 5px 5px',
  },
  postTopLeft: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  postCenter: {
    marginTop: 10,
    // height: 200,
    backgroundColor: '#313131',
    background: DarkModeColorCoder('Blue_gradient_transp'),
    width: '100%',
  },
  bottomWrapper: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'space-around',
  },
  '@media (max-width: 600px)': {
    postContainer: {
      marginTop: 7,
    },
    postWrapper: {
      borderRadius: '0px',
    },
  },
})

const FeedSkeleton = ({ classes, index }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const windowSize = WindowSize()
  return (
    <div
      className={classes.postContainer}
      style={{ marginTop: index === 0 ? 0 : 5 }}
    >
      <div className={classes.postWrapper}>
        <div
          className={classes.postCenter}
          style={{ borderRadius: windowSize > 600 ? 20 : 0 }}
        >
          <article className={classes.postTopLeft}>
            <div
              style={{
                margin: '10px',
                animation: 'skeleton 0.5s ease infinite alternate',
                background: 'rgba(147,165,194)',
                width: 50,
                height: 50,
                borderRadius: '50%',
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <FreeBar height={20} width={'20%'} />
              <span style={{ marginTop: 2 }}>
                <FreeBar height={10} width={'5%'} />
              </span>
            </div>
          </article>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <FreeBar height={20} width={'80%'} margin={'auto 10px'} />
            <span style={{ marginTop: 2, marginBottom: 2 }}>
              <FreeBar height={20} width={'40%'} margin={'auto 10px'} />
            </span>
            <FreeBar height={20} width={'60%'} margin={'auto 10px'} />
          </div>
          <div className={classes.bottomWrapper}>
            {Array.from({ length: 3 }, (_, index) => (
              <div
                className={classes.buttonWrapper}
                key={index}
                style={{
                  animation: 'skeleton 0.5s ease infinite alternate',
                  background: 'rgba(147,165,194)',
                }}
              >
                <FreeBar height={20} width={'20%'} />
                <FreeBar height={20} width={'40%'} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const WrappedFeedSkeleton = withStyles(styles)(FeedSkeleton)

export default WrappedFeedSkeleton
