const DarkModeColorCoder = (type) => {
  const colorCodes = [
    {
      type: 'Blue_gradient',
      code: 'linear-gradient(90deg, rgba(5, 5, 26, 0.8) 15%, rgba(79, 95, 114, 0.2) 100%)',
    },
    {
      type: 'Blue_gradient_transp',
      code: 'linear-gradient(90deg, rgba(5, 5, 26, 0.5) 15%, rgba(79, 95, 114, 0.2) 100%)',
    },
    {
      type: 'Grey_light',
      code: '#27292b',
    },
    {
      type: 'Grey_1x_light',
      code: '#505254',
    },
    {
      type: 'Grey_2x_light',
      code: '#83868a',
    },
    {
      type: 'Text_white',
      code: '#E4E6EB',
    },
  ]
  return colorCodes.find((c) => c?.type === type)?.code
}

export default DarkModeColorCoder
