import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ElementContributors from '../GeneralPDFDocumentComps/ElementContributors'
import GPDFDList, { Item } from '../GeneralPDFDocumentComps/GPDFDList'
import GPDFDTitle from '../GeneralPDFDocumentComps/GPDFDTitle'
import i18next from 'i18next'
import GPDFTextHashModifier from './GPDFTextHashModifier'

const lang = i18next.language
const rtl = lang === 'ar'
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: rtl ? 0 : 15,
    paddingRight: rtl ? 15 : 0,
    '@media max-width: 400': {
      paddingTop: 10,
    },
  },
  entryContainer: {
    marginBottom: 10,
  },
  date: {
    fontSize: 11,
    fontFamily: 'Lato Italic',
  },
  detailContainer: {
    flexDirection: 'row',
    fontFamily: 'Roboto',
  },
  detailLeftColumn: {
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
  },
  detailRightColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  bulletPoint: {
    fontSize: 10,
  },
  details: {
    fontSize: 10,
    fontFamily: 'Lato',
  },
  headerContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  rightColumn: {
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-end',
    justifySelf: 'flex-end',
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Roboto',
  },
  image: {
    width: '100%',
  },
  content: {
    marginTop: 20,
    marginBottom: 20,
    fontFamily: 'Roboto',
  },
  contentText: {
    fontSize: 12,
    fontFamily: 'Roboto',
    textAlign: 'justify',
  },
})

const GPDFBodyModelRight = ({
  button,
  configs,
  mainContent,
  contribPath,
  elementProp,
}) => {
  const { t } = useTranslation()
  const lang = i18next.language
  const content = GPDFTextHashModifier({ button, text: mainContent })
  return (
    <View style={styles.container}>
      {mainContent && (
        <>
          <GPDFDTitle>{lang === 'ar' ? 'المحتوى' : 'Content'}</GPDFDTitle>
          <View style={styles.content}>
            <Text style={styles.contentText}>{content}</Text>
          </View>
        </>
      )}
      <View style={styles.content}>
        <Text style={styles.title}>
          {lang === 'ar' ? 'التفاصيل' : 'Details'}
        </Text>
      </View>
      <GPDFDList>
        {configs?.map((fet, i) => (
          <Item key={i} style={styles.detailContainer}>
            {t(fet?.title)} :{' '}
            {fet?.disableTrans
              ? GPDFTextHashModifier({ button, text: fet?.titleValue })
              : t(fet?.titleValue?.split(' ')?.join('_'))}
          </Item>
        ))}
      </GPDFDList>
      {contribPath && (
        <ElementContributors
          button={button}
          path={contribPath}
          elementProp={elementProp}
        />
      )}
    </View>
  )
}

export default GPDFBodyModelRight
