import React, { useContext, useState } from 'react'
import TextEngineToolsFooter from '../../../../../../smallComponents/TextEngineToolsFooter'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ChartedDetails from './ChartedProfileComps/ChartedDetails'
import SpreadsheetChartViewer from '../../../../../../spreadSheetSystem/SpreadsheetChartViewer'
import CentralTitle from '../../../../../../smallComponents/CentralTitle'

const ChartedProfile = (props) => {
  const { charted, DCGProps } = props
  const { project } = DCGProps
  const { user } = useContext(AuthContext)
  const authorised = user?._id === project?.userId
  const [rows, setRows] = useState(charted?.rows)
  const [headers, setHeaders] = useState(charted?.headers)
  const [chartType, setChartType] = useState(Number(charted?.chartType))
  const [stampType, setStampType] = useState(Number(charted?.stampType))
  const [inputValue, setInputValue] = useState(charted?.startingPointInput)
  const propsII = {
    ...props,
    rows,
    setRows,
    headers,
    setHeaders,
    chartType,
    setChartType,
    stampType,
    setStampType,
    inputValue,
    setInputValue,
  }
  return (
    <article
      style={{
        width: '100%',
        color: DCGProps?.isDarkModeActive ? 'white' : null,
        paddingTop: 10,
      }}
    >
      <div style={{ margin: '15px auto' }}>
        <CentralTitle title={charted?.Title} />
      </div>
      <SpreadsheetChartViewer {...propsII} />
      <ChartedDetails {...props} />
      {authorised && <TextEngineToolsFooter {...DCGProps} />}
    </article>
  )
}

export default ChartedProfile
