import React from 'react'
import { NotificationViewModel } from '../../..'
import NotificationBodyWithAccessCard from '../../../ContractsNotificationsComponents/ContractsNotifsDialogRightComponents/NotificationBodyWithAccessCard'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const DevelopmentRequestReply = (props) => {
  const { notification } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? `لقد تم الرد على عرضك  بـ "${notification?.accompaniedData?.reponse}"`
              : `Your development offer has been replied to by "${notification?.accompaniedData?.reponse}"`
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default DevelopmentRequestReply
