import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const AuctionBusinessModel = () => {
  const { t } = useTranslation('AuctionBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 4,
    title: t('title_bm04'),
    subTitle: t('subTitle_bm04'),
    main: t('main_bm04'),
    when: t('when_bm04'),
    who: t('who_bm04'),
    what: t('what_bm04'),
    how: t('how_bm04'),
    why: t('why_bm04'),
    examples: rtl
      ? [
          'eBay - تأسست عام 1995 - الولايات المتحدة الأمريكية',
          'WineBid - تأسست عام 1996 - الولايات المتحدة الأمريكية',
          'Zopa - تأسست عام 2005 - المملكة المتحدة',
          'Elance - تأسست عام 2006 - الولايات المتحدة الأمريكية',
          'Ocean Tomo - تأسست عام 2009 - الولايات المتحدة الأمريكية',
        ]
      : [
          'eBay - Founded 1995 - USA',
          'WineBid - Founded 1996 - USA',
          'Zopa - Founded 2005 - UK',
          'Elance - Founded 2006 - USA',
          'Ocean Tomo - Founded 2009 - USA',
        ],
    tags: rtl
      ? [
          'المنتجات النادرة',
          'خصائص فريدة',
          'سعر غير مستقر',
          'استراتيجية تسعير غير معروفة',
          'تسعير حصة العميل',
          'المنتجات المستعملة',
        ]
      : [
          'Rare Products',
          'Unique Features',
          'Unsettled Price',
          'Unknown Pricing Strategy',
          'Customer Share Pricing',
          'Used Products',
        ],
    considerations: t('considerations_bm04'),
    combinations: ['$LBM2', '$LBM6'],
    hashTagType: 'businessModelTemp',
  }
}

export default AuctionBusinessModel
