// the idea here is to join the properties of the element with the configs of the woidow props that are important for the naviagator cards

import WindowViewerSystemComfigs from '../../windowViewer/WindowViewerSystemComps/WindowViewerSystemConfigs'

const DistributedWinCardsConfigurer = (elements) => {
  const configs = WindowViewerSystemComfigs().map((con) => ({
    hashTagType: con?.hashTagType,
    windowProps: {
      windowCardName: con?.windowCardName,
      windowType: con?.windowType,
      hideNoFileSystemPanel: true,
    },
    imageKey: con?.imageKey,
  }))

  const joiner = (element, config) => {
    element['windowProps'] = config?.windowProps
    element['imageKey'] = config?.imageKey
    return element
  }
  const configuredElements = elements?.map((element) => {
    let config = configs.filter(
      (con) => con?.hashTagType === element?.hashTagType
    )[0]
    return joiner(element, config)
  })

  return configuredElements
}

export default DistributedWinCardsConfigurer
