import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100%',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const HomeProjectButtonsModel = ({ classes, buttonsArray }) => {
  return buttonsArray.map((button, buttonIndex) => (
    <Button
      disabled={button.disabled}
      key={buttonIndex}
      variant='outlined'
      className={classes.button}
      style={{ display: button.displayConditions }}
      onClick={button.funs}
    >
      {button.title}
    </Button>
  ))
}

const WrappedHomeProjectButtonsModel = withStyles(styles)(
  HomeProjectButtonsModel
)

export default WrappedHomeProjectButtonsModel
