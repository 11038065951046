import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationViewModel, NotificationBodyWithAccessCard } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const TaskModiifcationNotif = (props) => {
  const { sender, project, task } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <section>
              {rtl ? 'مهمتك' : 'Your task'}{' '}
              <span style={style}>{task?.title}</span>
              <span style={{ display: project?.name ? null : 'none' }}>
                {rtl ? 'فى' : 'in'}{' '}
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>{' '}
              </span>
              {rtl ? 'تم تعديلها' : 'Has been modified by'}{' '}
              <Link to={`/profile/${sender?._id}`} style={style}>
                {sender?.name}
              </Link>
            </section>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default TaskModiifcationNotif
