import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const AffiliationBusinessModel = () => {
  const { t } = useTranslation('AffiliationBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 2,
    title: t('title_bm02'),
    subTitle: t('subTitle_bm02'),
    main: t('main_bm02'),
    when: t('when_bm02'),
    who: t('who_bm02'),
    what: t('what_bm02'),
    how: t('how_bm02'),
    why: t('why_bm02'),
    examples: rtl
      ? [
          'أمازون - 1994 - الولايات المتحدة الأمريكية',
          'جوجل - 1998 - الولايات المتحدة الأمريكية',
          'فيسبوك - 2004 - الولايات المتحدة الأمريكية',
          'Pinterest - 2010 - الولايات المتحدة الأمريكية',
          'يوتيوب - 2005 - الولايات المتحدة الأمريكية',
          'تويتر - 2006 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Amazon - 1994 - USA',
          'Google - 1998 - USA',
          'Facebook - 2004 - USA',
          'Pinterest - 2010 - USA',
          'YouTube - 2005 - USA',
          'Twitter - 2006 - USA',
        ],
    tags: rtl
      ? ['التسويق السهل', 'الشركات الناشئة', 'لا يمكن تحمل تكاليف التسويق']
      : ['Easy Marketing', 'Start-Ups', 'Cannot Afford Marketing'],
    considerations: t('considerations_bm02'),
    combinations: ['$LBM46', '$LBM52', '$LBM22', '$LBM30', '$LBM16', '$LBM61'],
    hashTagType: 'businessModelTemp',
  }
}

export default AffiliationBusinessModel
