import PostProps from './PostProps'
import HomePostConatinerModel from './HomePostConatinerModel'
import PostCenter from './SocialPostComponents/PostCenter'
import TextPost from '../../../postSystem/TextPost'
import SocialPostMiddle from './SocialPostComponents/SocialPostMiddle'
import SocialPostBottom from './SocialPostComponents/SocialPostBottom'

const SocialPost = ({ postProps }) => {
  const { post, isDarkModeActive, setPosts } = postProps
  return (
    <HomePostConatinerModel
      postProps={{
        ...postProps,
        // for the clicking on the top of the post effect
        postBody: (
          <PostCenter
            postProps={PostProps(
              { post, commentingSystemView: true, isDarkModeActive, setPosts },
              { imageViewerProps: true, commentsDialogProps: true }
            )}
          />
        ),
        postText: <TextPost {...postProps} isCommentingSystemActive={true} />,
      }}
      mainBody={
        <>
          <TextPost {...postProps} margins='15px' />
          <SocialPostMiddle
            postProps={PostProps(postProps, {
              imageViewerProps: true,
              commentsDialogProps: true,
            })}
          />
          <SocialPostBottom
            postProps={PostProps(postProps, {
              imageViewerProps: true,
              commentsDialogProps: false,
            })}
          />
        </>
      }
    />
  )
}

export default SocialPost
