import React, { useEffect, useState } from 'react'
import BookDialogModel from '../bookDialogModel/BookDialogModel'
import Media from 'react-media'
import CommentingPCVersion from './CommentingSystemComponents/CommentingPCVersion'
import CommentingMobileVersion from './CommentingSystemComponents/CommentingMobileVersion'
import axios from 'axios'
import FreeBar from '../skeleton/SkeletonComponents/FreeBar'
import ToastifyGeneralError from '../smallComponents/ToastifyGeneralError'
import i18next from 'i18next'

const ActivateComment = ({ user: postUser, ...props }) => {
  const {
    isCommentsDialogOpen,
    commentsDialogClose,
    postBody,
    isDarkModeActive,
    post,
    setIsDarkModeActiveII,
    externalLinkMode,
  } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isDarkModeActiveII, setIsDarkModeActive] = useState(isDarkModeActive)
  useEffect(() => {
    if (typeof setIsDarkModeActiveII === 'function')
      setIsDarkModeActiveII(isDarkModeActiveII)
  }, [isDarkModeActiveII])
  const [user, setUser] = useState(postUser)
  useEffect(() => {
    const fetchPostUser = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${post?.userId}`)
        setUser(res.data.user)
      } catch (error) {}
    }
    const ftechProjectUser = async () => {
      try {
        const res = await axios.get(`/projects/oneProject/${post?.userId}`)
        setUser(res.data.project)
      } catch (error) {
        console.log(error)
      }
    }
    if (
      (post?.userId && !postUser && post.feedsSystemCategory === 'home') ||
      (post?.userId && !postUser && post.feedsSystemCategory === 'room') ||
      (post?.userId && !postUser && post.feedsSystemCategory === 'channel')
    ) {
      fetchPostUser()
    } else if (post?.userId && !postUser) {
      ftechProjectUser()
    }
  }, [postUser, post?.userId])

  const [isCommentIconClicked, setIsCommentIconClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentResponse, setCurrentResponse] = useState(true)
  const [commentsUsers, setCommentsUsers] = useState([])
  const [postComments, setPostComments] = useState([])
  const [page, setPage] = useState(1)
  const fetchPostComments = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `/comments/postComments/${post?._id}/?page=${page}&limit=15`
      )
      setCurrentResponse(Boolean(res.data.comments?.length))
      setPostComments([...postComments, ...res.data.comments])
      setCommentsUsers([...commentsUsers, ...res.data.commentsUsers])
    } catch (error) {
      ToastifyGeneralError(error)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    if (post?._id) fetchPostComments()
  }, [post?._id, page])

  const commentProps = {
    ...props,
    setIsCommentIconClicked,
    isCommentIconClicked,
    isDarkModeActive: isDarkModeActiveII,
    postComments,
    setPostComments,
    isLoading,
    currentResponse,
    commentsUsers,
    page,
    setPage,
    setCommentsUsers,
    user,
  }
  const mediaComponents = [
    <CommentingPCVersion commentProps={commentProps} />,
    <CommentingMobileVersion commentProps={commentProps} />,
  ]
  return (
    <BookDialogModel
      bookDialogTitlte={
        user?.firstName || user?.ProjectName ? (
          rtl ? (
            `منشور ${user?.firstName || user?.ProjectName}`
          ) : (
            `${user?.firstName || user?.ProjectName}'s Post`
          )
        ) : (
          <span style={{ width: 100 }}>
            <FreeBar height={20} isDarkModeActive={isDarkModeActive} />
          </span>
        )
      }
      bookDialogClose={commentsDialogClose}
      isBookDialogOpen={isCommentsDialogOpen}
      bookLeft={postBody}
      bookRight={
        externalLinkMode ? (
          mediaComponents[0]
        ) : (
          <Media query='(max-width: 600px)'>
            {(matches) => {
              return matches ? mediaComponents[1] : mediaComponents[0]
            }}
          </Media>
        )
      }
      isDarkModeActive={isDarkModeActiveII}
      setIsDarkModeActive={setIsDarkModeActive}
      externalLinkMode={externalLinkMode}
    />
  )
}
const CommentsDialog = (props) => {
  const { isCommentsDialogOpen, externalLinkMode } = props
  if (isCommentsDialogOpen || externalLinkMode) {
    return <ActivateComment {...props} />
  }
}

export default CommentsDialog
