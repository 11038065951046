const CountriesPhoneKeys = () => {
  return [
    { name: 'Algeria (‫الجزائر‬‎)', code: '213' },
    { name: 'Argentina', code: '54' },
    { name: 'Australia', code: '61' },
    { name: 'Austria (Österreich)', code: '43' },
    { name: 'Belgium (België)', code: '32' },
    { name: 'Brazil (Brasil)', code: '55' },
    { name: 'Canada', code: '1' },
    { name: 'Chile', code: '56' },
    { name: 'China', code: '86' },
    { name: 'Denmark (Danmark)', code: '45' },
    { name: 'Egypt (‫مصر‬‎)', code: '20' },
    { name: 'Finland (Suomi)', code: '358' },
    { name: 'France', code: '33' },
    { name: 'Germany (Deutschland)', code: '49' },
    { name: 'Greece (Ελλάδα)', code: '30' },
    { name: 'India', code: '91' },
    { name: 'Iraq (‫العراق‬‎)', code: '964' },
    { name: 'Italy (Italia)', code: '39' },
    { name: 'Japan (日本)', code: '81' },
    { name: 'Jordan (‫الأردن‬‎)', code: '962' },
    { name: 'Kuwait (‫الكويت‬‎)', code: '965' },
    { name: 'Lebanon (‫لبنان‬‎)', code: '961' },
    { name: 'Libya (‫ليبيا‬‎)', code: '218' },
    { name: 'Mexico (México)', code: '52' },
    { name: 'Morocco (‫المغرب‬‎)', code: '212' },
    { name: 'Netherlands (Nederland)', code: '31' },
    { name: 'Norway (Norge)', code: '47' },
    { name: 'Oman (‫عُمان‬‎)', code: '968' },
    { name: 'Palestine (‫فلسطين‬‎)', code: '970' },
    { name: 'Qatar (‫قطر‬‎)', code: '974' },
    { name: 'Russia (Россия)', code: '7' },
    { name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)', code: '966' },
    { name: 'Somalia (Soomaaliya)', code: '252' },
    { name: 'South Korea (대한민국)', code: '82' },
    { name: 'Spain (España)', code: '34' },
    { name: 'Sudan (‫السودان‬‎)', code: '249' },
    { name: 'Sweden (Sverige)', code: '46' },
    { name: 'Switzerland (Schweiz)', code: '41' },
    { name: 'Syria (‫سوريا‬‎)', code: '963' },
    { name: 'Tunisia (‫تونس‬‎)', code: '216' },
    { name: 'Turkey (Türkiye)', code: '90' },
    { name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', code: '971' },
    { name: 'United Kingdom', code: '44' },
    { name: 'United States', code: '1' },
    { name: 'Yemen (‫اليمن‬‎)', code: '967' },
    // Add more countries as needed
  ]
}

export default CountriesPhoneKeys
