import React from 'react'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const InquiryReviewerResponse = (props) => {
  const { sender, notification } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const response = notification?.accompaniedData?.response
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            response === 'refuse'
              ? rtl
                ? `قام ${sender?.name} برفض طلبك مشاركتك`
                : `${sender?.name} has refused your request`
              : rtl
              ? `قام برفض طلبك ${sender?.name}`
              : `${sender?.name} has refused your request`
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default InquiryReviewerResponse
