import React from 'react'
import GeneralPDFDocument from './GeneralPDFDocument'
import MarketingStrategyPDFBody from './MarketingStrategyPDFDocumentComps/MarketingStrategyPDFBody'

const MarketingStrategyPDFDocument = (props) => {
  return (
    <GeneralPDFDocument
      pages={[<MarketingStrategyPDFBody {...props} />]}
      {...props}
    />
  )
}

export default MarketingStrategyPDFDocument
