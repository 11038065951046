import React from 'react'
import EditableCardsWindow from '../../../../../../../fileSystem/EditableCardsWindow'
import TitleHeader from '../../../../../../../smallComponents/Header'
import MarketingStrategyAspectsConfigs from '../../../../ProjectModelingComponents/MarketingStrategiesComponents/MarketingStrategyAspectsComps/MarketingStrategyAspectsConfigs'
import ProductStrategyCardContent from './ProductStrategyCardContent'
import i18next from 'i18next'

const ChooseProductStartegyAspects = ({
  DCGProps,
  windowSize,
  setChosenCards,
  chosenCards,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <TitleHeader
        headerText={rtl ? 'أضف جانبا تسويقياً' : 'Add Marketing Aspects'}
      />
      <EditableCardsWindow
        cards={MarketingStrategyAspectsConfigs()}
        DCGProps={DCGProps}
        StandardCardContent={ProductStrategyCardContent}
        SearchIDFExtConfigs={{}}
        enableSearching
        wrpperStyle={{
          maxHeight: 500,
          minHeight: 250,
          overflowY: 'auto',
          width: windowSize <= 600 ? '100vw' : '100%',
        }}
        setChosenCards={setChosenCards}
        chosenCards={chosenCards}
        placeholder={rtl ? 'جوانب التسويق' : 'Marketing Aspects'}
      />
    </>
  )
}

export default ChooseProductStartegyAspects
