import React from 'react'
import withStyles from '@mui/styles/withStyles'
import ButtonModelUnit from './ButtonsSystemComponents/ButtonModelUnit'
import PDFGeneratorButton from './ButtonsSystemComponents/PDFGeneratorButton'

const styles = () => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 10,
    padding: 10,
  },
})

const ButtonsSystem = ({ classes, ...props }) => {
  const { buttonsArray, hide } = props
  return (
    <section
      className={classes.buttonsWrapper}
      style={{ display: hide && 'none' }}
    >
      {buttonsArray.map((button, buttonIndex) =>
        button?.type === 'PDF' ? (
          <PDFGeneratorButton button={button} key={buttonIndex} {...props} />
        ) : (
          <ButtonModelUnit button={button} key={buttonIndex} {...props} />
        )
      )}
    </section>
  )
}
const WrappedButtonsSystem = withStyles(styles)(ButtonsSystem)

export default WrappedButtonsSystem
