import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const UltimateLuxuryBusinessModel = () => {
  const { t } = useTranslation('UltimateLuxuryBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 58,
    title: t('title_bm58'),
    subTitle: t('subTitle_bm58'),
    main: t('main_bm58'),
    when: t('when_bm58'),
    who: t('who_bm58'),
    what: t('what_bm58'),
    how: t('how_bm58'),
    why: t('why_bm58'),
    examples: rtl
      ? [
          'لامبورغيني - تأسست عام 1963 - إيطاليا',
          'مجموعة جميرا - تأسست عام 1997 - الإمارات العربية المتحدة',
          'أبوت داونينج - تأسس عام 1818 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Lamborghini - Founded 1963 - Italy',
          'Jumeirah Group - Founded 1997 - UAE',
          'Abbot Downing - Founded 1818 - USA',
        ],
    tags: rtl
      ? ['أبراج الإمارات', 'برج العرب', 'توقعات عالية', 'العملاء الأثرياء']
      : [
          'Emirates Towers',
          'Burj al Arab',
          'High Expectations',
          'Wealthy Customers',
        ],
    considerations: t('considerations_bm58'),
    combinations: ['$LBM64', '$LBM18'],
    hashTagType: 'businessModelTemp',
  }
}

export default UltimateLuxuryBusinessModel
