import React from 'react'
import withStyles from '@mui/styles/withStyles'
import TextEngine from '../../../textProcessingEngine/TextEngine'
import TextDirectionDetector from '../../../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'
import MultiMillify from '../../../smallComponents/MultiMillify'

const styles = () => ({
  titleValue: {
    borderRadius: 10,
    padding: '4px 10px',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
})

const SavedView = ({
  classes,
  infoValue,
  info,
  chosenHashTagElements,
  socket,
}) => {
  return (
    <section
      className={classes.titleValue}
      style={{
        width: info?.unEditable ? '100%' : '90%',
      }}
      dir={TextDirectionDetector(infoValue)}
    >
      {info?.type === 'number' ? (
        MultiMillify(infoValue)
      ) : typeof infoValue === 'string' ? (
        <TextEngine
          textContent={infoValue}
          textPackage={{
            chosenHashTagElements,
          }}
          charLimit={50}
          socket={socket}
        />
      ) : (
        infoValue
      )}
    </section>
  )
}

const WrappedSavedView = withStyles(styles)(SavedView)

export default WrappedSavedView
