import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const FranchisingBusinessModel = () => {
  const { t } = useTranslation('FranchisingBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 21,
    title: t('title_bm21'),
    subTitle: t('subTitle_bm21'),
    main: t('main_bm21'),
    when: t('when_bm21'),
    who: t('who_bm21'),
    what: t('what_bm21'),
    how: t('how_bm21'),
    why: t('why_bm21'),
    examples: rtl
      ? [
          'شركة سنجر - تأسست عام 1851 - الولايات المتحدة الأمريكية - امتياز ماكينات الخياطة',
          'ماكدونالد - تأسست عام 1940 - الولايات المتحدة الأمريكية - امتياز الغذاء',
          'صب واي - تأسست عام 1965 - الولايات المتحدة الأمريكية - امتياز الغذاء',
          'بيتزا هت - تأسست عام 1958 - الولايات المتحدة الأمريكية - الامتياز الغذائي',
          'كنتاكي فرايد تشيكن - تأسست عام 1952 - الولايات المتحدة الأمريكية - الامتياز الغذائي',
          'ستاربكس - تأسست عام 1971 - الولايات المتحدة الأمريكية - امتياز الأطعمة والمشروبات',
          '7-11 - تأسست عام 1927 - الولايات المتحدة الأمريكية - امتياز المتاجر الصغيرة',
          'ماريوت الدولية - تأسست عام 1993 - الولايات المتحدة الأمريكية - الامتياز الفندقي',
          'Natur House - تأسست 1987 - إسبانيا - الامتياز الغذائي',
          'هولسيم - تأسست عام 1912 - سويسرا - أعمال البناء',
        ]
      : [
          'Singer Corporation - Founded 1851 - USA - Sewing Machines Franchising',
          'McDonald - Founded 1940 - USA - Food Franchising',
          'Subway - Founded 1965 - USA - Food Franchising',
          'Pizza Hut - Founded 1958 - USA - Food Franchising',
          'KFC - Founded 1952 - USA - Food Franchising',
          'Starbucks - Founded 1971 - USA - Food and Beverage Franchising',
          '7-eleven - Founded 1927 - USA - Convenience Store Franchising',
          'Marriott International - Founded 1993 - USA - Hotel Franchising',
          'Natur House - Founded 1987 - Spain - Food Franchising',
          'Holcim - Founded 1912 - Switzerland - Construction Business',
        ],
    tags: rtl
      ? [
          'وضع يربح فيه الجميع',
          'التوسع السريع',
          'سوق في كل مكان',
          'شركاء',
          'تدريب',
          'مراقبة السوق غير المباشرة',
          'مخاطر منخفضة',
          'مشاركة المسؤولية',
          'التوزيع السريع',
        ]
      : [
          'Win-win Situation',
          'Rapid Expansion',
          'Market Everywhere',
          'Partners',
          'Training',
          'Indirect Market Control',
          'Low Risk',
          'Share Responsibility',
          'Quick Distribution',
        ],
    considerations: t('considerations_bm21'),
    combinations: ['$LBM16', '$LBM14', '$LBM22', '$LBM61'],
    hashTagType: 'businessModelTemp',
  }
}

export default FranchisingBusinessModel
