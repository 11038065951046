import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ContractEditingEventCover = ({ notification, sender, project }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `قام ${sender?.name} بتعديل عقد متعلق بك`
    : `${sender?.name} has modified a contract associated with you.`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default ContractEditingEventCover
