import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../context/AuthContext'
import { FeedContext } from '../../../../../context/FeedContext/FeedContext'
import deleteFile from '../../../../../firebase/deleteFile'
import i18next from 'i18next'

export default function PostDeleteButtonConfig({
  shareSystemProps,
  setDeletePost,
}) {
  const {
    setIsPostDeleted,
    shareDialogClose,
    disablePostTypeOptions,
    post,
    setPosts,
  } = shareSystemProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  // this is suppoed to delete all other images in either comments or replies
  const [urls, setUrls] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchPostUrls = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/urls/post/${post?._id}`)
        setUrls(res.data.urls)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (post?._id) fetchPostUrls()
  }, [post?._id])

  const { user } = useContext(AuthContext)
  const { dispatch } = useContext(FeedContext)
  const { t } = useTranslation()
  const notConfirmed = typeof setDeletePost === 'function'
  return {
    title: isLoading
      ? rtl
        ? 'جارى التجهيز'
        : 'Preparing'
      : t(notConfirmed ? 'Delete' : 'Confirm_Deletion'),
    funs: async () => {
      if (notConfirmed) {
        setDeletePost(true)
      } else {
        if (post?._id && post)
          try {
            await axios.delete(`/posts/${post?._id}`, { userId: user?._id })
            dispatch({
              type: 'DELETE_POST',
              payload: post,
            })
            setPosts((posts) => posts.filter((p) => p._id !== post?._id))
            Promise.all(
              post?.images?.map((image) => {
                if (image) deleteFile(image)
              })
            )
            Promise.all(
              urls?.map((image) => {
                if (image) deleteFile(image)
              })
            )
          } catch (error) {
            console.log(error)
          }
        // to delete the protocol in case of a protocol post
        if (post?.postElement?.hashTagType === 'protocol')
          try {
            await axios.delete(`/protocols/${post?.postElement?.id}`)
          } catch (error) {
            console.log(error)
          }

        shareDialogClose()
        setIsPostDeleted(true)
      }
    },
    displayConditions: disablePostTypeOptions ? null : 'none',
    disabled: isLoading,
  }
}
