import axios from 'axios'
import React, { useContext } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import { AuthContext } from '../../../../../../context/AuthContext'
import NotificationCreator from '../../../../NotificationCreator'
import i18next from 'i18next'

const VisionOffer = (props) => {
  const { user } = useContext(AuthContext)
  const { socket, idea, sender, notification } = props
  const [isClicked, setIsClicked] = useState(false)
  const activeFun = async ({ response }) => {
    try {
      setIsClicked(true)
      socket?.current?.emit('addNotifUser', sender?._id)
      // this will add the user to the default channel
      const defaultChRes = await axios.patch(
        `/channels/channelsSystem/defaultChannel/${idea?.id}`,
        {
          contributorId: sender?._id,
        }
      )
      NotificationCreator({
        associativity: 'idea',
        relation: 'visionReviewer',
        conditionIndex: 0,
        accompaniedData: {
          response,
          elements: [
            { ...idea },
            {
              hashTagType: defaultChRes?.data?.channel?.hashTagType,
              id: defaultChRes?.data?.channel?._id,
              title: defaultChRes?.data?.channel?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: sender?._id,
        currentUser: user,
        socket,
      })
      // will add the user to the contributors of the idea if the response is accept
      if (response === 'accept')
        await axios.patch(`/ideas/addContributor/${idea?._id}`, {
          contributorId: sender?._id,
        })
      await axios.patch(`/notifications/${notification?._id}`, {
        interactedWith: true,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray =
    notification?.accompaniedData?.requestSubGenre === 'contribute'
      ? [
          {
            title: rtl ? 'قبول' : 'Accept',
            funs: () => activeFun({ response: 'accept' }),
            disabled: isClicked || notification?.interactedWith,
          },
          {
            title: rtl ? 'رفض' : 'Refuse',
            funs: () => activeFun({ response: 'refuse' }),
            disabled: isClicked || notification?.interactedWith,
          },
        ]
      : []
  const reqType = notification?.accompaniedData?.requestSubGenre?.subgenre
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <section>
              <Link
                to={`/profile/${sender?._id}`}
                style={{ margin: 'auto 4px' }}
              >
                {sender.name}
              </Link>
              {reqType === 'contribute'
                ? rtl
                  ? `يعرض المشاركة فى فكرتك`
                  : `offers contribution to your idea`
                : rtl
                ? 'يسأل عن معلومات أكثر'
                : 'asks for more information'}
            </section>
          }
        />
      }
      buttonsArray={buttonsArray}
    />
  )
}

export default VisionOffer
