import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar, Grid, ListItem, ListItemIcon } from '@mui/material'
import FreeBar from '../FreeBar'
import '../../skeleton.css'

const styles = () => ({
  wrappingCard: {
    display: 'flex',
    margin: '10px 0 5px 10px',
    height: 'auto',
    flexDirection: 'column',
    padding: '10px 5px 5px 5px',
    borderRadius: '10px 10px 20px 20px !important',
  },

  listItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0px !important',
  },
  avatar: {
    width: '80px !important',
    height: '80px !important',
    borderRadius: '10px !important',
  },
  gridElementName: {
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
    width: '100%',
  },

  '@media (max-width: 600px)': {
    wrappingCard: {
      margin: '5px 0 5px 0',
      borderRadius: '0 !important',
    },
  },
})

const ProfileRightLoader = ({ classes }) => {
  return (
    <div className={classes.wrappingCard}>
      <Grid
        container
        direction='row'
        style={{ animation: 'skeleton 1s ease infinite alternate' }}
      >
        {Array.from({ length: 3 }, (_, index2) => (
          <Grid item xs={4} key={index2}>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <Avatar variant='square' className={classes.avatar}>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      animation: 'skeleton 1s ease infinite alternate',
                      backgroundColor: '#313131',
                    }}
                  ></div>
                </Avatar>
              </ListItemIcon>
              <span className={classes.gridElementName}>
                <FreeBar height={20} />
              </span>
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const WrappedProfileRightLoader = withStyles(styles)(ProfileRightLoader)

export default WrappedProfileRightLoader
