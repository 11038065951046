import axios from 'axios'
import React, { useContext } from 'react'
import { AuthContext } from '../../../../../../context/AuthContext'
import ECWFreeCardModel from '../../../../../fileSystem/EditableCardsWindowComps/ECWFreeCardModel'
import GeneralCardContent from '../../../../../generalPortalSystem/GeneralPortalSystemComponents/GeneralCardContent'
import WindowViewerSystemConfigs from '../../../../../windowViewer/WindowViewerSystemComps/WindowViewerSystemConfigs'

const AccessCardGenerator = ({
  isDarkModeActive,
  element,
  postMode,
  socket,
  modelProps,
  customCardFuns,
}) => {
  const { user } = useContext(AuthContext)
  const customCardFunsII =
    element.hashTagType === 'managerNote'
      ? async () => {
          if (modelProps?.project?._id && modelProps?.departmentId)
            try {
              await axios.patch(
                `/managerNotes/departmentManagerNotes/${modelProps?.project?._id}/${modelProps?.departmentId}`,
                { userId: user?._id }
              )
            } catch (error) {
              console.log(error)
            }
        }
      : customCardFuns
  const wind = WindowViewerSystemConfigs().find(
    (con) => con.hashTagType === element.hashTagType
  )
  const StandardCardContent = wind?.StandardCardContent
  const cardMarkProp = wind?.cardMarkProp

  const elementsImages =
    element?.images?.length || element?.image
      ? [
          {
            id: element?.id,
            image: element?.images ? element?.images[0] : element?.image,
          },
        ]
      : null
  
  return (
    <ECWFreeCardModel
      element={element}
      props={{
        modelProps: {
          StandardCardContent: StandardCardContent || GeneralCardContent,
          cardMarkProp,
          postMode,
          elementsImages,
          customCardFuns: customCardFunsII,
          ...modelProps,
        },
        MFSGProps: { isDarkModeActive, socket },
      }}
      accessCardMode
    />
  )
}

export default AccessCardGenerator
