import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import { AiFillEdit } from 'react-icons/ai'
import EditingSystem from '../../editingSystem/EditingSystem'
import PostProps from '../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import { AuthContext } from '../../../context/AuthContext'
import PopoverMenu from '../../popoverMenu/PopoverMenu'
import ShareExternalLinkDialog from '../../fileSystem/NoFileSystemPanelComponents/ExternalLinkSharingComps/ShareExternalLinkDialog'
import WindowProps from '../../windowViewer/WindowProps'

const styles = () => ({
  postTopRight: {
    display: 'flex',
    cursor: 'pointer',
    padding: 7,
  },
  icon: {
    fontSize: 20,
  },
  '@media (max-width: 600px)': {
    icon: {
      fontSize: 15,
    },
    morvertStyling: {
      fontSize: 14,
    },
  },
})

const PTRight = ({ classes, postProps }) => {
  const { post, hideRightOptions, project, isDarkModeActive } = postProps
  const { postEditDialogOpener, postEditDialogClose, isPostEditDialogOpen } =
    PostProps({}, { editingSystemProps: true })
  const { user } = useContext(AuthContext)
  const hideRight = project
    ? project?.userId !== user?._id
    : user?._id !== post?.userId
  const editingSysProps =
    post?.feedsSystemCategory === 'home'
      ? {
          shareTypeIndex: 1,
          isPostEditDialogOpen,
          postEditDialogClose,
          disabledPostTypesDefaultValue: 0,
          ...postProps,
          user,
        }
      : post?.feedsSystemCategory === 'room'
      ? {
          shareTypeIndex: 4,
          disabledPostTypesDefaultValue: 0,
          isPostEditDialogOpen,
          postEditDialogClose,
          ...postProps,
        }
      : {
          shareTypeIndex: 3,
          disabledPostTypesDefaultValue: 0,
          isPostEditDialogOpen,
          postEditDialogClose,
          ...postProps,
        }
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const optionsArray = [
    {
      type: 'edit',
      funs: () => {
        postEditDialogOpener()
      },
      hideOp: hideRight || post?.postTypeIndex === 3 || hideRightOptions,
    },
    {
      type: 'external-link',
      funs: () => {
        windowOpener()
      },
    },
  ]
  return (
    <div className={classes.postTopRight}>
      <span className={classes.morvertStyling}>
        <PopoverMenu optionsArray={optionsArray} />
      </span>
      <EditingSystem {...editingSysProps} />
      {isWindowOpen && (
        <ShareExternalLinkDialog
          noFSPanelProps={{ element: post, isDarkModeActive }}
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
        />
      )}
    </div>
  )
}

const WrappedPTRight = withStyles(styles)(PTRight)

export default WrappedPTRight
