import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import WindowViewerSystem from '../../../../windowViewer/WindowViewerSystem'
import WindowProps from '../../../../windowViewer/WindowProps'
import StateGenerator from '../../../../smallComponents/StateGenerator'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const NotificationViewButtonModel = ({ classes, button, isDarkModeActive }) => {
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  return (
    <>
      <Button
        onClick={() => {
          if (typeof button.funs === 'function') button.funs()
          if (button?.windowConfigs) windowOpener()
        }}
        className={classes.button}
        variant='outlined'
        disabled={button?.disabled}
      >
        {button.title}
      </Button>
      {button?.windowConfigs && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
          isDarkModeActive={isDarkModeActive}
          props={{
            windowCloser,
            ...button?.windowConfigs.props,
          }}
          windowType={button?.windowConfigs.windowType}
        />
      )}
    </>
  )
}

const WrappedNotificationViewButtonModel = withStyles(styles)(
  NotificationViewButtonModel
)

export default WrappedNotificationViewButtonModel
