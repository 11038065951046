import React from 'react'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import { Link } from 'react-router-dom'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const NewInvestmentOfferNotif = (props) => {
  const style = {
    margin: 'auto 4px',
  }
  const { project, pack } = props
  NotificationAutoInterUpdator(props)

  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={
        rtl
          ? 'بعد قبول هذا العرض ، يمكنك إرسال قناة استثمار إلى المستثمر المحتمل أو إنشاء قناة جديدة في قنوات مشروعك ، للتعامل مع المزيد من التفاعلات'
          : 'After accepting this offer you may send the potential investor an investment channel or create a new one in your project channels, to handle more interactions'
      }
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <section>
                باقتك الإستثمارية <span style={style}>{pack.title}</span> فى
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
                استلمت طلبات جديدة
              </section>
            ) : (
              <section>
                Your Investment Package <span style={style}>{pack.title}</span>{' '}
                in
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
                has recieved new requests
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default NewInvestmentOfferNotif
