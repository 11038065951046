import i18next from "i18next"

const InvestmentPackConfigs = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'عنوان الباقة' : 'Package Title',
      minRows: 4,
    },
    {
      title: 'Description',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 6,
      placeholder: rtl ? 'وصف الباقة' : 'Package Description',
    },
    {
      title: 'new_invest_title_1',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 6,
      placeholder: rtl
        ? 'يجب أن يكون هذا ملخصًا لما يدور حوله المشروع. يجب أن تكون واضحة وموجزة ، ويجب أن تعطي المستثمرين فكرة عما يمكن أن يتوقعوه من المشروع'
        : 'This should be a summary of what the project is about. It should be clear and concise, and should give investors an idea of what they can expect from the project',
    },
    {
      title: 'new_invest_title_2',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 6,
      placeholder: rtl
        ? 'يجب أن يشمل ذلك معلومات حول الأشخاص المشاركين في المشروع ، بما في ذلك خبراتهم ومؤهلاتهم. يجب أن تتضمن أيضًا معلومات حول الجوانب المالية للمشروع ، مثل مبلغ المال المطلوب وكيفية استخدامه'
        : 'This should include information about the people who are involved in the project, including their experience and qualifications. It should also include information about the financial aspects of the project, such as the amount of money that is needed and how it will be used',
    },
    {
      title: 'new_invest_title_3',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 6,
      placeholder: rtl
        ? 'يمكنك ذكر بعض البيانات المالية'
        : 'You may mention some financial data',
    },
    {
      title: 'new_invest_title_4',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 6,
      placeholder: rtl
        ? 'يجب أن يتضمن ذلك معلومات حول السوق المستهدف للمشروع ، بالإضافة إلى أي توقعات مبيعات تم إجراؤها'
        : 'This should include information about the target market for the project, as well as any sales projections that have been made',
    },
    {
      title: 'new_invest_title_5',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 6,
      placeholder: rtl
        ? 'يجب أن يتضمن ذلك معلومات حول الجوانب الفنية للمشروع ، مثل المعدات التي سيتم استخدامها والموارد التي ستكون مطلوبة. يجب أن تتضمن أيضًا معلومات حول القوى العاملة المطلوبة لإكمال المشروع ، بالإضافة إلى أي اعتبارات بيئية يجب أخذها في الاعتبار'
        : 'This should include information about the technical aspects of the project, such as the equipment that will be used and the resources that will be needed. It should also include information about the manpower that will be required to complete the project, as well as any environmental considerations that need to be taken into account',
    },
    {
      title: 'new_invest_title_6',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 6,
      placeholder: rtl
        ? 'يجب أن يتضمن ذلك معلومات حول مبلغ المال المطلوب لإكمال المشروع ، وكذلك كيفية تمويل المشروع. يجب أن تتضمن أيضًا معلومات حول العوائد المتوقعة على الاستثمار'
        : 'This should include information about the amount of money that is needed to complete the project, as well as how the project will be financed. It should also include information about the expected returns on the investment',
    },
    {
      title: 'new_invest_title_7',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 6,
      placeholder: rtl
        ? 'يجب أن يتضمن ذلك معلومات حول أي دعم حكومي متاح للمشروع ، بالإضافة إلى أي لوائح يجب اتباعها'
        : 'This should include information about any government support that is available for the project, as well as any regulations that need to be followed',
    },
    {
      title: 'new_invest_title_8',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 6,
      placeholder: rtl
        ? 'يجب أن يتضمن ذلك معلومات حول أي دعم حكومي متاح للمشروع ، بالإضافة إلى أي لوائح يجب اتباعها'
        : 'This should include information about any government support that is available for the project, as well as any regulations that need to be followed',
    },
  ]
}

export default InvestmentPackConfigs
