import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const UserDesignBusinessModel = () => {
  const { t } = useTranslation('UserDesignBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 59,
    title: t('title_bm59'),
    subTitle: t('subTitle_bm59'),
    main: t('main_bm59'),
    when: t('when_bm59'),
    who: t('who_bm59'),
    what: t('what_bm59'),
    how: t('how_bm59'),
    why: t('why_bm59'),
    examples: rtl
      ? [
          'Threadless - الولايات المتحدة الأمريكية - تأسست 2000',
          'ليغو - صناعة الألعاب - تأسست عام 1932 - الدنمارك',
          'بونوكو - تأسست عام 2007 - نيوزيلندا',
        ]
      : [
          'Threadless - USA - Founded 2000',
          'Lego - Toy Industry - Founded 1932 - Denmark',
          'Ponoko - Founded 2007 - New Zealand',
        ],
    tags: rtl
      ? ['مخاطر منخفضة', 'عملاء مبدعون']
      : ['Low Risk', 'Creative Customers'],
    considerations: t('considerations_bm59'),
    combinations: ['$LBM42', '$LBM34', '$LBM23'],
    hashTagType: 'businessModelTemp',
  }
}

export default UserDesignBusinessModel
