import React from 'react'
import axios from 'axios'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@mui/material'
import deleteFile from '../../../../firebase/deleteFile'
import { MdDelete } from 'react-icons/md'
import i18next from 'i18next'

const styles = () => ({
  text: {
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fotnSize: 16,
    fontWeight: 600,
    '&:hover': {
      fotnSize: 18,
    },
  },
  labelButton: {
    textTransform: 'none !important',
    backgroundColor: 'transparent !important',
    '&:hover': {
      opacity: '0.8 !important',
      backgroundColor: 'gray !important',
    },
  },
  textIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
})

const ImageUpdatorDeleteOption = ({
  classes,
  setImage,
  updateProps,
  remImage,
  setIsLoading,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <Button
      component='label'
      color='inherit'
      className={classes.labelButton}
      disabled={!remImage}
    >
      <span className={classes.text}>
        <section
          onClick={async () => {
            setIsLoading(true)
            setImage()
            try {
              if (updateProps)
                await axios.patch(updateProps?.path, updateProps?.payload)
              await deleteFile(remImage)
              console.log('deleted successfully')
            } catch (error) {
              console.log(error)
            }
            setIsLoading(false)
          }}
          className={classes.textIconWrapper}
        >
          <MdDelete
            size={20}
            style={{ marginRight: !rtl && 10, marginLeft: rtl && 10 }}
          />
          {rtl ? 'إزالة' : 'Remove'}
        </section>
      </span>
    </Button>
  )
}

const WrappedImageUpdatorDeleteOption = withStyles(styles)(
  ImageUpdatorDeleteOption
)

export default WrappedImageUpdatorDeleteOption
