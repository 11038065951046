import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const JobRequestReply = (props) => {
  const { job, project, notification } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const response = notification?.acoompaniedData?.requestSubGenre
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          notification={notification}
          {...props}
          notificationBodyContent={
            rtl ? (
              <section>
                تقديمك على الوظيفة:
                <span style={style}>{job.title}</span> الى
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.ProjectName}
                </Link>{' '}
                {response === 'refused' ? 'تم رفضه' : 'تم الرد عليه بعقد '}
              </section>
            ) : (
              <section>
                Your Job request
                <span style={style}>{job.title}</span> to
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.ProjectName}
                </Link>{' '}
                has been {notification?.acoompaniedData?.requestSubGenre}ed
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default JobRequestReply
