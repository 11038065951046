import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const PeertoPeerBusinessModel = () => {
  const { t } = useTranslation('PeertoPeerBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 42,
    title: t('title_bm42'),
    subTitle: t('subTitle_bm42'),
    main: t('main_bm42'),
    when: t('when_bm42'),
    who: t('who_bm42'),
    what: t('what_bm42'),
    how: t('how_bm42'),
    why: t('why_bm42'),
    examples: rtl
      ? [
          'eBay - تأسست عام 1995 - الولايات المتحدة الأمريكية',
          'كريغزلست - تأسست عام 1995 - الولايات المتحدة الأمريكية',
          'Zopa - تأسست عام 2005 - المملكة المتحدة',
          'friendsurance.com - تأسست عام 2009 - ألمانيا',
          'RelayRides - تأسست عام 2010 - الولايات المتحدة الأمريكية',
          'TIGER 21 - تأسست عام 1999 - الولايات المتحدة الأمريكية',
          'LinkedIn - تأسست عام 2003 - الولايات المتحدة الأمريكية',
          'Airbnb - تأسست عام 2008 - الولايات المتحدة الأمريكية',
        ]
      : [
          'eBay - Founded 1995 - USA',
          'Craigslist - Founded 1995 - USA',
          'Zopa - Founded 2005 - UK',
          'friendsurance.com - Founded 2009 - Germany',
          'RelayRides - Founded 2010 - USA',
          'TIGER 21 - Founded 1999 - USA',
          'LinkedIn - Founded 2003 - USA',
          'Airbnb - Founded 2008 - USA',
        ],
    tags: rtl
      ? ['منتجات خاصة', 'شراء سهل']
      : ['Private Products', 'Easy Purchase'],
    considerations: t('considerations_bm42'),
    combinations: ['$LBM16', '$LBM4', '$LBM6', '$LBM22', '$LBM61'],
    hashTagType: 'businessModelTemp',
  }
}

export default PeertoPeerBusinessModel
