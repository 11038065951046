import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  cardImageText: {
    display: 'flex',
    margin: '10px 25px 10px 25px',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    positiom: 'relative',
  },
  cardImage: {
    objectFit: 'cover',
    width: '110px',
    height: '110px',
    borderRadius: '10px',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    position: 'relative',
  },

  cardImageChecked: {
    objectFit: 'cover',
    width: '110px',
    height: '110px',
    borderRadius: '10px',
    position: 'absolute',
    opacity: 0.5,
  },
  cardTitle: {
    display: 'flex',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '15px',
    color: 'white',
    justifyContent: 'center',
  },

  titleCard: {
    padding: '3px !important',
    backgroundColor: 'rgb(210, 221, 240) !important',
    marginTop: '5px !important',
    width: '100% !important',
    borderRadius: '20px !important',
  },
  '@media (max-width: 600px)': {
    cardTitle: {
      fontSize: '12px',
    },
    cardImage: {
      width: '80px',
      height: '80px',
    },
    cardImageChecked: {
      width: '80px',
      height: '80px',
    },
  },
})

const DepartmentCardUnit = ({
  element,
  classes,
  setDepartmentId,
  elementIndex,
  departmentId,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={classes.cardImageText}
      onClick={() => setDepartmentId(elementIndex)}
    >
      <img
        src={element.image}
        alt={`${element.title}'s Image`}
        className={classes.cardImage}
      />
      <img
        style={{ display: departmentId === elementIndex ? null : 'none' }}
        src='/assets/various/check1.webp'
        alt={`${element.title}'s Image`}
        className={classes.cardImageChecked}
      />
      <Card className={classes.titleCard}>
        <span className={classes.cardTitle}>{t(element.title)}</span>
      </Card>
    </div>
  )
}

const WrappedDepartmentCardUnit = withStyles(styles)(DepartmentCardUnit)

export default WrappedDepartmentCardUnit
