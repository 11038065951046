import { Divider } from '@mui/material'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const TextEngineToolsFooter = ({
  isDarkModeActive,
  hideTETFooter,
  subSectionInd,
  sectionInd,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div
      style={{
        fontSize: 12,
        padding: 10,
        display: hideTETFooter ? 'none' : null,
        color: isDarkModeActive ? 'white' : 'inherit',
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      }}
    >
      <Divider
        style={{
          backgroundColor: isDarkModeActive ? 'white' : null,
          width: '70%',
          marginTop: 10,
        }}
      />
      <p>
        {rtl
          ? 'تذكر دوماًً أن معظم مدخلات النصوص فى هيوتيوبيا تسمح بالإشارة الى المستخدمين الأخرين عن طريق إضافة @ و بعدها اسم المستخدم, ثم تقوم بإختيار المستخدم المطلوب من القائمة المعروضة. هذه الميزة ممكنه ايضا لأى عنصر من عناصر المشروعات مثل'
          : 'Always remeber that for most inputs in Hutupia, you can always mention other users by typing @ then the name of that user, and pick his name form the emergent list. The same goes for most elements in any project like'}{' '}
        <i style={{ fontWeight: 500 }}>
          {rtl
            ? 'المنتجات، المهام، الحملات الترويجية، نماذج الأعمال، المشاكل، الأفكار، جوانب استراتيجيات التسويق، استراتيجيات المنتجات،الوظائف، المتطلبات، النشاطات البحثية،رسوم بيانية، تصميمات منتجات، قنوات التشارك و كذلك ملفات ال PDF'
            : 'Products, Tasks, Campaigns, Jobs, Business Model Templates, Resources, Issues, Ideas, Inquires, Global Inquiries, Marketing Strategy Aspects, Currently Opened Project Members, Products Strategies, Research Activities,Charted Data, Product Designs, Partnership Channels and PDFs'}
        </i>{' '}
        {rtl
          ? 'و لكن بدلاًً من استخدام @ يجب ان تستخدم $. كما يمكنك ايضا عمل هاشتاجات بإستخدام #. يجب ان تلاحظ انك غير مضطر لتذكر اسم العنصر المطلوب الإشارة اليه بالتحديد، فيمكنك تذكر أى محتوى مهم بداخله مثل "Done" عند محاولة الاشارة الى مهمة بالنسبة الى مشروع معين.'
          : "but instead of using @, you have to use $. You can also hashtag using #. But for these elements you do not have to mention the name strictly, you may also mention any property like: 'done', it gets your completed tasks relative to your project."}{' '}
        <span style={{ cursor: 'pointer' }}>
          <strong
            style={{ pointer: 'cursor' }}
            onClick={() => {
              window.location.href = `https://library.hutupia.com`
            }}
          >
            {t('Learn_More')}
          </strong>
        </span>
      </p>
    </div>
  )
}

export default TextEngineToolsFooter
