import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import FormsComponents from '../../formsSystem/FormsComponents'
import { FeedContext } from '../../../context/FeedContext/FeedContext'
import { useTranslation } from 'react-i18next'
import NotificationCreator from '../../notificationsSystem/NotificationCreator'
import ToastifyGeneralError from '../../smallComponents/ToastifyGeneralError'
import i18next from 'i18next'

const NewCommentModule = ({
  isDarkModeActive,
  setPostComments,
  post,
  socket,
  postComments,
  project,
  departmentId,
  subDepId,
  setCommentsUsers,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const { notification, dispatch } = useContext(FeedContext)
  const [textContent, setTextContent] = useState('')
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const [images, setImages] = useState([])
  const [playerLink, setPlayerLink] = useState()
  const [projectAdmins, setProjectAdmins] = useState([])
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const adminsRes = await axios.get(
          `/projects/o/projectAdmins/${post?.userId}`
        )
        setProjectAdmins(adminsRes.data.admins)
      } catch (error) {
        console.log(error)
      }
    }

    if (post?.feedsSystemCategory === 'page') fetchProject()
  }, [post?.userId])
  const newComment = {
    postId: post?._id,
    userId: user?._id,
    text: textContent,
    chosenHashTagElements,
    images,
    likes: [],
    playerLink,
  }
  const notMod = async () => {
    try {
      if (
        post?.feedsSystemCategory === 'home' ||
        post?.feedsSystemCategory === 'room' ||
        post?.feedsSystemCategory === 'channel'
      ) {
        await axios.delete('/notifications/customDelete/d', {
          headers: {},
          data: {
            userId: post?.userId,
            associativity: 'post',
            relation: 'creator',
            category: 'general',
            conditionIndex: 2,
            accompaniedData: {
              postId: post._id,
            },
            notThisUser: true,
          },
        })
      } else {
        Promise.all(
          projectAdmins?.map(async (m) => {
            await axios.delete('/notifications/customDelete/d', {
              headers: {},
              data: {
                userId: m?._id,
                associativity: 'project',
                relation: 'admin',
                category: 'contributors',
                conditionIndex: 3,
                accompaniedData: {
                  postId: post._id,
                  projectId: post?.userId,
                },
                notThisUser: true,
              },
            })
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSend = async () => {
    if (post?._id && user._id && (textContent || images)) {
      try {
        if (postComments?.length > 0 && post?.userId !== user?._id) notMod()
        const res = await axios.post(`/comments`, newComment)
        setPostComments((comms) => [res.data.comment, ...comms])
        setCommentsUsers((us) => [user, ...us])
      } catch (error) {
        ToastifyGeneralError(error)
      }
      if (
        post?.feedsSystemCategory === 'home' ||
        post?.feedsSystemCategory === 'room' ||
        post?.feedsSystemCategory === 'channel'
      ) {
        NotificationCreator({
          associativity: 'post',
          relation: 'creator',
          conditionIndex: 2,
          accompaniedData: { postId: post._id },
          category: 'general',
          userId: post?.userId,
          currentUser: user,
          socket,
        })
      } else {
        projectAdmins?.map((m) => {
          socket.current.emit('addNotifUser', m?._id)
          NotificationCreator({
            associativity: 'project',
            relation: 'admin',
            conditionIndex: 3,
            accompaniedData: {
              projectId: post?.userId,
              postId: post._id,
              elements: [
                {
                  id: post?._id,
                  hashTagType: post?.hashTagType,
                  title: rtl ? 'منشور' : 'Project Post',
                },
              ],
            },
            category: 'contributors',
            userId: m?._id,
            currentUser: user,
            socket,
          })
        })
      }
      // for mention notification
      if (post?._id && notification?.mentionedUsersIds) {
        Promise.all(
          notification?.mentionedUsersIds?.map(async (id) =>
            NotificationCreator({
              associativity: 'post',
              relation: 'mentioned',
              conditionIndex: 1,
              accompaniedData: { postId: post?._id },
              category: 'general',
              userId: id,
              currentUser: user,
              socket,
            })
          )
        )
        dispatch({
          type: 'REMOVE_MENTION',
        })
      }
    }
  }

  const { t } = useTranslation()

  const formsCompsProps = {
    formComponentIndex: 1,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    placeholder: t('Leave_a_Comment'),
    images,
    setImages,
    isDarkModeActive,
    project,
    onSend,
    pathName: `/comments/${post?._id}`,
    departmentId,
    subDepId,
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        onSend()
        setTextContent('')
        setImages([])
        setPlayerLink('')
      }
    },
    link: playerLink,
    setLink: setPlayerLink,
  }
  return user && <FormsComponents formsCompsProps={formsCompsProps} />
}

export default NewCommentModule
