import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextProcessor from './TextProcessor'

const ReadeMore = ({
  textContent,
  textPackage,
  setSearchQuerry,
  searchDialogOpener,
  textFuns,
  isDarkModeActive,
  charLimit,
}) => {
  const [isReadMoreClicked, setIsReadMoreClicked] = useState(false)
  const { t } = useTranslation()
  const lessRead = textContent.slice(0, charLimit) + '...'
  let textFragments =
    isReadMoreClicked || charLimit >= textContent.length
      ? textContent.split(/\n/g)
      : lessRead.split(/\n/g)

  const hideReadMore = charLimit >= textContent.length
  const CompI = ({ fragment, index, length }) => (
    <>
      <br style={{ display: index === 0 ? 'none' : null }} />
      <TextProcessor
        textPackage={textPackage}
        textContent={fragment}
        setSearchQuerry={setSearchQuerry}
        searchDialogOpener={searchDialogOpener}
        textFuns={textFuns}
        isDarkModeActive={isDarkModeActive}
      />
      <span
        onClick={() => setIsReadMoreClicked(true)}
        style={{
          display: hideReadMore || index < length - 1 ? 'none' : null,
          fontWeight: 500,
          cursor: 'pointer',
        }}
      >
        {t('Read_More')}
      </span>
    </>
  )

  return !isReadMoreClicked
    ? textFragments.map((fragment, index) => (
        <CompI
          key={index}
          index={index}
          fragment={fragment}
          length={textFragments?.length}
        />
      ))
    : textFragments.map((fragment, index) => (
        <span key={index}>
          <br style={{ display: index === 0 ? 'none' : null }} />
          <TextProcessor
            textPackage={textPackage}
            textContent={fragment}
            setSearchQuerry={setSearchQuerry}
            searchDialogOpener={searchDialogOpener}
            textFuns={textFuns}
            isDarkModeActive={isDarkModeActive}
          />
        </span>
      ))
}

export default ReadeMore
