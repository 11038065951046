import React, { useContext } from 'react'
import { FeedsSystem } from '../../../../components/notificationsSystem/NotificationsComponents'
import { AuthContext } from '../../../../context/AuthContext'

const AdPostView = ({ post, socket, project, isDarkModeActive }) => {
  const { user } = useContext(AuthContext)
  const postUser = post?.userId === user?._id ? user : project
  return (
    postUser && (
      <FeedsSystem
        feedsSystemProps={{
          post,
          user: postUser,
          hideRightOptions: true,
          isDarkModeActive,
          socket,
        }}
      />
    )
  )
}

export default AdPostView
