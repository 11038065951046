import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'
import BlocksLoader from '../../../../../../../../../smallComponents/BlocksLoader'
import CentralTitle from '../../../../../../../../../smallComponents/CentralTitle'
import ButtonsSystem from '../../../../../../../../../buttonsSystem/ButtonsSystem'
import { AuthContext } from '../../../../../../../../../../context/AuthContext'
import DefaultImageGrapper from '../../../../../../../../../smallComponents/DefaultImageGrapper'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const styles = () => ({
  elementsWrapper: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexFlow: 'row wrap',
    marginBottom: 10,
    position: 'relative',
  },
  viewCard: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    width: 'auto',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
  },
  cardImageText: {
    display: 'flex',
    margin: '10px 25px 10px 25px',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    positiom: 'relative',
  },
  cardImage: {
    objectFit: 'cover',
    width: '110px',
    height: '110px',
    borderRadius: '10px',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    position: 'relative',
  },

  cardImageChecked: {
    objectFit: 'cover',
    width: '110px',
    height: '110px',
    borderRadius: '10px',
    position: 'absolute',
    opacity: 0.5,
  },
  cardTitle: {
    display: 'flex',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '15px',
    color: 'white',
    justifyContent: 'center',
  },

  titleCard: {
    padding: '3px !important',
    backgroundColor: 'rgb(210, 221, 240) !important',
    marginTop: '5px !important',
    width: '100% !important',
    borderRadius: '20px !important',
  },
  '@media (max-width: 600px)': {
    cardTitle: {
      fontSize: '12px',
    },
    cardImage: {
      width: '80px',
      height: '80px',
    },
    cardImageChecked: {
      width: '80px',
      height: '80px',
    },
  },
})

const ContributionProjectsOptions = ({
  classes,
  DCGProps,
  activeFun,
  centralTitle,
  windowCloser,
  setInteracted,
}) => {
  const { isDarkModeActive } = DCGProps
  const { user } = useContext(AuthContext)
  const [EstablishedProjects, setEstablishedProjects] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [chosenProject, setChosenProject] = useState()
  const lang = i18next.language
  const rtl = lang === 'ar'
  useEffect(() => {
    const fetchEstablishedProjects = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/projects/userProjects/${user?._id}`)
        setEstablishedProjects(res.data.establishedProjects)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    fetchEstablishedProjects()
  }, [user?._id])
  const ProjectModel = ({ project }) => {
    return (
      <div
        className={classes.cardImageText}
        onClick={() => setChosenProject(project)}
        onDoubleClick={() => setChosenProject(undefined)}
      >
        <img
          src={project.projectPhoto || DefaultImageGrapper('noProjectAvatar')}
          alt={project.ProjectName}
          className={classes.cardImage}
        />
        <img
          style={{
            display: chosenProject?._id === project?._id ? null : 'none',
          }}
          src='/assets/various/check1.webp'
          alt={project.ProjectName}
          className={classes.cardImageChecked}
        />
        <Card className={classes.titleCard}>
          <span className={classes.cardTitle}>{project.ProjectName}</span>
        </Card>
      </div>
    )
  }

  const buttonsArray = [
    {
      title: rtl ? 'تأكيد' : 'Confirm',
      funs: () => {
        activeFun({ project: chosenProject })
        windowCloser()
        if (typeof setInteracted === 'function') setInteracted(true)
        toast.success(
          rtl ? 'تم ارسال طلبك بنجاح' : 'Your requests is sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: !chosenProject,
    },
    { title: rtl ? 'إلغاء' : 'Cancel', funs: () => windowCloser() },
    {
      title: rtl ? 'بنفسى' : 'As Myself',
      funs: () => {
        activeFun({ userId: user?._id })
        windowCloser()
        if (typeof setInteracted === 'function') setInteracted(true)
        toast.success(
          rtl ? 'تم ارسال طلبك بنجاح' : 'Your requests is sent successfully'
        )
      },
      cofirmMssg: true,
    },
  ]
  return isLoading ? (
    <BlocksLoader {...DCGProps} />
  ) : (
    <div style={{ color: isDarkModeActive && 'white' }}>
      <CentralTitle title={centralTitle} />
      {EstablishedProjects?.length > 0 ? (
        <Card className={classes.viewCard}>
          <section className={classes.elementsWrapper}>
            {EstablishedProjects?.map((project) => {
              return <ProjectModel project={project} key={project?._id} />
            })}
          </section>
        </Card>
      ) : rtl ? (
        'ليس لديك مشروعات للإختيار منها، يمكنك فقط اختيار "بنفسى"'
      ) : (
        "You do not have created projects to choose from, you can only choose 'As Myself'"
      )}
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </div>
  )
}

const WrappedContributionProjectsOptions = withStyles(styles)(
  ContributionProjectsOptions
)

export default WrappedContributionProjectsOptions
