import React from 'react'
import EditableInfoUnit from './EditableGridModelComponents/EditableInfoUnit'
import withStyles from '@mui/styles/withStyles'
import TitleHeader from '../smallComponents/Header'

const styles = () => ({
  detailsWrapper: {
    width: '100%',
  },
})

const EditableGridModel = ({ classes, gridProps }) => {
  const { headerShowInfo, infoArray, middleHeader } = gridProps

  return (
    <section className={classes.detailsWrapper}>
      {middleHeader ? (
        <div
          style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
        >
          <TitleHeader {...gridProps} showInfo={headerShowInfo} />
        </div>
      ) : (
        <TitleHeader {...gridProps} showInfo={headerShowInfo} />
      )}

      {infoArray.map((info, infoIndex) => (
        <EditableInfoUnit key={infoIndex} info={info} gridProps={gridProps} />
      ))}
    </section>
  )
}

const WrappedEditableGridModel = withStyles(styles)(EditableGridModel)

export default WrappedEditableGridModel
