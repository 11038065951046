import axios from 'axios'
import React, { useEffect, useState } from 'react'
import PollCard from '../../../../../../notificationsSystem/NotificationsComponents/ContributorsNotificationsComponents/ContributorsNotifsDialogRightComponents/ProjectNotificationDisplayModelComps/ProjectNotificationDisplayBodyComps/NewPollNotificationComps/PollCard'
import CustomCircularProgress from '../../../../../../smallComponents/CustomCircularProgress'
import withStyles from '@mui/styles/withStyles'
import DetailsGridModel from '../../../../../../formsSystem/DetailsGridModel'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { convertNumbers2Arabic } from 'to-arabic-numbers'
import MultiLangNumber from '../../../../../../smallComponents/MultiLangNumber'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    marginTop: 10,
  },
})

const PollProfile = ({ poll, DCGProps, classes }) => {
  const [options, setOptions] = useState([])
  const { isDarkModeActive } = DCGProps
  const [isLoading, setIsLoading] = useState(true)
  const [creator, setCreator] = useState()
  let allVoters = []
  options?.map((op) => {
    allVoters.push(...op?.votersIds)
  })
  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/pollsOptions/pollOptions/${poll?._id}`)
        setOptions(res.data.options)
      } catch (error) {
        console.log(error)
      }
    }
    const fetchCreator = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${poll?.userId}`)
        setCreator(res.data.user)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    if (poll?._id) fetchOptions()
    if (poll?.userId) fetchCreator()
  }, [poll?._id, poll?.userId])
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const threshold = rtl
    ? convertNumbers2Arabic(`${poll?.Threshold}`)
    : poll?.Threshold
  const currentVoters = rtl
    ? convertNumbers2Arabic(`${allVoters?.length}`)
    : allVoters?.length
  const infoArray = [
    {
      title: t('Created_By'),
      titleValue: creator?.firstName + ' ' + creator?.lastName,
    },
    {
      title: t('Creation_Date'),
      titleValue: moment(poll?.createdAt).format('MMM Do YY'),
    },
    {
      title: t('Deadline'),
      titleValue: moment(poll?.Deadline).format('MMM Do YY'),
      hide: !poll?.Deadline,
    },
    {
      title: t('Initial_Target'),
      titleValue: `${MultiLangNumber(threshold)} ${t('Voters')}`,
    },
    {
      title: t('Voters'),
      titleValue: `${MultiLangNumber(currentVoters)} ${t('Voters')}`,
    },
  ]
  return isLoading ? (
    <CustomCircularProgress />
  ) : (
    <section
      className={classes.wrapper}
      style={{ color: isDarkModeActive && 'white' }}
    >
      {options?.length > 0 && (
        <PollCard
          poll={poll}
          options={options}
          setOptions={setOptions}
          DCGProps={DCGProps}
        />
      )}
      <DetailsGridModel infoArray={infoArray} headerText={t('Summary')} />
    </section>
  )
}

const WrappedPollProfile = withStyles(styles)(PollProfile)

export default WrappedPollProfile
