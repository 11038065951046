import React from 'react'
import { ProductRequestReply, ProductNewRequestsMssg } from '../../'
import DefaultImageGrapper from '../../../../smallComponents/DefaultImageGrapper'

const ProductDisplayModels = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const product = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'product'
  )
  const notifPropsII = {
    ...notifProps,
    product,
  }
  const mssgs = [
    {
      relation: 'creator',
      comp: [<ProductNewRequestsMssg {...notifPropsII} />],
    },
    {
      relation: 'visitor',
      comp: [<ProductRequestReply {...notifPropsII} />],
    },
  ]

  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      <img
        src={product?.image || DefaultImageGrapper('product')}
        alt='product image'
        style={{ width: '100%', height: 200, objectFit: 'cover' }}
      />
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default ProductDisplayModels
