import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import NotificationViewButtonModel from './NotificationViewButtonsComps/NotificationViewButtonModel'
import axios from 'axios'
import i18next from 'i18next'

const styles = () => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 10,
    padding: 10,
  },
})

const NotificationViewButtons = ({
  classes,
  buttonsArray,
  isDarkModeActive,
  notification,
  notificationCardDialogClose,
  setContribNotifications,
  setDealsNotifications,
  DialogCloser,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isLoading, setIsLoading] = useState(false)
  const buttonsArrayII = buttonsArray?.concat([
    {
      title: isLoading
        ? rtl
          ? 'جارى المسح'
          : 'Deleting'
        : rtl
        ? 'إمسح'
        : 'Delete',
      funs: async () => {
        setIsLoading(true)
        try {
          await axios.delete(`/notifications/${notification?._id}`)
          if (typeof setContribNotifications === 'function')
            setContribNotifications((notifs) =>
              notifs.filter((n) => n?._id !== notification?._id)
            )
          if (typeof setDealsNotifications === 'function')
            setDealsNotifications((notifs) =>
              notifs.filter((n) => n?._id !== notification?._id)
            )
          // for clsoing the dialog in m view after deleting a notif
          if (typeof DialogCloser === 'function') DialogCloser()
        } catch (error) {
          console.log(error)
        }
        setIsLoading(false)
        notificationCardDialogClose()
      },
      disabled: isLoading,
    },
  ])
  return (
    <section className={classes.buttonsWrapper}>
      {buttonsArrayII.map((button, buttonIndex) => (
        <NotificationViewButtonModel
          button={button}
          key={buttonIndex}
          isDarkModeActive={isDarkModeActive}
        />
      ))}
    </section>
  )
}
const WrappedNotificationViewButtons = withStyles(styles)(
  NotificationViewButtons
)

export default WrappedNotificationViewButtons
