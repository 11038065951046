import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'
import RequestCreator from '../../../../../../../notificationsSystem/RequestCreator'
import { toast } from 'react-toastify'
import NoPermissionedInteraction from '../../../../../../../notificationsSystem/NoPermissionedInteraction'
import ElementRequestsSenders from '../../../../../../../notificationsSystem/ElementRequestsSenders'
import i18next from 'i18next'

const GlobalInquiryInteractionButons = ({
  postMode,
  globalInquiry,
  DCGProps,
}) => {
  const { socket } = DCGProps
  const { user } = useContext(AuthContext)
  const [dec, setDec] = useState(false)
  const [contrib, setContrib] = useState(false)
  const senders = ElementRequestsSenders(
    globalInquiry?._id || globalInquiry?.id
  )

  const activeFun = async (subgenre) => {
    try {
      socket.current.emit('addNotifUser', globalInquiry?.userId)
      NotificationCreator({
        associativity: 'global',
        relation: 'globalCreator',
        conditionIndex: 0,
        accompaniedData: {
          requestSubGenre: subgenre,
          elements: [
            {
              hashTagType: globalInquiry?.hashTagType,
              id: globalInquiry?._id,
              title: globalInquiry?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: globalInquiry?.userId,
        currentUser: user,
        socket,
      })
      RequestCreator({
        currentUser: user,
        genre: 'globalInquiry',
        subgenre,
        elementId: globalInquiry?._id,
        requestData: {},
      })
    } catch (error) {
      console.log(error)
    }
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'توضيح' : 'Declaration',
      funs: () => {
        setDec(true)
        activeFun('declaration')
        toast.success(
          rtl
            ? 'تم ارسال طلبك بنجاح'
            : 'Your request has been sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: dec,
    },
    {
      title: rtl ? 'شارك' : 'Contribute',
      funs: () => {
        setContrib(true)
        activeFun('contribute')
        toast.success(
          rtl
            ? 'تم ارسال طلبك بنجاح'
            : 'Your request has been sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: contrib,
    },
  ]
  return senders === undefined ? (
    <NoPermissionedInteraction checking={true} />
  ) : senders?.includes(user?._id) || contrib || dec ? (
    <NoPermissionedInteraction />
  ) : (
    <ButtonsSystem
      buttonsArray={buttonsArray}
      hide={
        !postMode ||
        user?._id === globalInquiry?.userId ||
        globalInquiry?.globalInquiryContributorsIds?.includes(user?._id)
      }
      {...DCGProps}
    />
  )
}

export default GlobalInquiryInteractionButons
