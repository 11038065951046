import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  hashtagged: {
    color: 'blue',
    fontStyle: 'oblique',
    cursor: 'pointer',
    fontWeight: 500,
  },
})

const HashTagDetector = ({
  classes,
  word,
  searchDialogOpener,
  setSearchQuerry,
  engineFuns,
}) => {
  return (
    <span
      className={classes.hashtagged}
      onClick={() => {
        if (typeof engineFuns !== 'function') {
          searchDialogOpener()
          setSearchQuerry(word)
        } else {
          engineFuns(word)
        }
      }}
    >
      {word}
    </span>
  )
}

const WrappedHashTagDetector = withStyles(styles)(HashTagDetector)

export default WrappedHashTagDetector
