import {
  AddOnBusinessModel,
  AffiliationBusinessModel,
  AikidoBusinessModel,
  AnynoumousMarket,
  AuctionBusinessModel,
  ReverseAuction,
  BarterBusinessModel,
  CrossSellingBusinessModel,
  CashMachineBusinessModel,
  Crowdfunding,
  Crowdsourcing,
  CustomerLoyaltyBusinessModel,
  AugmentedNetworkBusinessModel,
  DigitisationBusinessModel,
  DirectSellingBusinessModel,
  ECommerceBusinessModel,
  OnlineOfflineHybrid,
  FreemiumBusinessModel,
  ExperienceSellingBusinessModel,
  FlatRateBusinessModel,
  FractionalOwnershipBusinessModel,
  FromPushToPullBusinessModel,
  GuaranteedAvailabilityBusinessModel,
  HiddenRevenueBusinessModel,
  IngredientBrandingBusinessModel,
  IntegratorBusinessModel,
  FranchisingBusinessModel,
  LayerPlayerBusinessModel,
  LeverageCustomerDataBusinessModel,
  LicensingBusinessModel,
  LockInBusinessModel,
  LongTailBusinessModel,
  MakeMoreofIt,
  MassCustomisationBusinessModel,
  MyAlternative,
  NoFrillsBusinessModel,
  OpenBusiness,
  OpenSourceBusinessModel,
  OrchestratorBusinessModel,
  PayPerUse,
  PayWhatYouWantBusinessModel,
  PeertoPeerBusinessModel,
  PerformanceBasedContracting,
  RazorAndBladeBusinessModel,
  RentInsteadOfBuyBusinessModel,
  RevenueSharing,
  ReverseEngineeringBusinessModel,
  ReverseInnovationBusinessModel,
  RobinHoodBusinessModel,
  ComplementaryShopsBusinessModel,
  SelfService,
  ShopInShopBusinessModel,
  SolutionProviderBusinessModel,
  SubscriptionBusinessModel,
  SupermarketBusinessModel,
  TargetthePoorBusinessModel,
  TrashToCashBusinessModel,
  TwoSidedMarketBusinessModel,
  UltimateLuxuryBusinessModel,
  UserDesignBusinessModel,
  WhiteLabelBusinessModel,
  DropshippingBusinessModel,
  MembershipBusinessModel,
  PayPerClickBusinessModel,
  PureBundlingBusinessModel,
  TimeShareBusinessModel,
  TiredPrcingBusinessModel,
  UpsellingBusinessModel,
  EcosystemBusinessModel,
  MultiLevelMarketingBusinessModel,
  NetworkBusinessModel,
  SponsorshipBusinessModel,
  TransactionBasedBusinessModel,
  ConsultingAndProfessionalServices,
  FunctionAsAService,
  HybridCloud,
  InfrastructureAsAService,
  PlatformAsAService,
  ResellerAndReferralPrograms,
  SoftwareAsAService,
  SoftwareLicensing,
} from './BusinessModelsConfigsComps'

const BusinessModelsConfigs = () => {
  return [
    AddOnBusinessModel(),
    AffiliationBusinessModel(),
    AikidoBusinessModel(),
    AuctionBusinessModel(),
    ReverseAuction(),
    AnynoumousMarket(),
    BarterBusinessModel(),
    CashMachineBusinessModel(),
    CrossSellingBusinessModel(),
    Crowdfunding(),
    Crowdsourcing(),
    CustomerLoyaltyBusinessModel(),
    DigitisationBusinessModel(),
    DirectSellingBusinessModel(),
    AugmentedNetworkBusinessModel(),
    ECommerceBusinessModel(),
    OnlineOfflineHybrid(),
    ExperienceSellingBusinessModel(),
    FlatRateBusinessModel(),
    FractionalOwnershipBusinessModel(),
    FranchisingBusinessModel(),
    FreemiumBusinessModel(),
    // Final
    FromPushToPullBusinessModel(),
    GuaranteedAvailabilityBusinessModel(),
    HiddenRevenueBusinessModel(),
    IngredientBrandingBusinessModel(),
    IntegratorBusinessModel(),
    LayerPlayerBusinessModel(),
    LeverageCustomerDataBusinessModel(),
    LicensingBusinessModel(),
    LockInBusinessModel(),
    LongTailBusinessModel(),
    MakeMoreofIt(),
    MassCustomisationBusinessModel(),
    NoFrillsBusinessModel(),
    MyAlternative(),
    OpenBusiness(),
    OpenSourceBusinessModel(),
    OrchestratorBusinessModel(),
    PayPerUse(),
    PayWhatYouWantBusinessModel(),
    PeertoPeerBusinessModel(),
    PerformanceBasedContracting(),
    RazorAndBladeBusinessModel(),
    RentInsteadOfBuyBusinessModel(),
    RevenueSharing(),
    ReverseEngineeringBusinessModel(),
    ReverseInnovationBusinessModel(),
    RobinHoodBusinessModel(),
    SelfService(),
    ShopInShopBusinessModel(),
    ComplementaryShopsBusinessModel(),
    SolutionProviderBusinessModel(),
    SubscriptionBusinessModel(),
    TargetthePoorBusinessModel(),
    TrashToCashBusinessModel(),
    TwoSidedMarketBusinessModel(),
    UltimateLuxuryBusinessModel(),
    UserDesignBusinessModel(),
    WhiteLabelBusinessModel(),
    SupermarketBusinessModel(),
    DropshippingBusinessModel(),
    PayPerClickBusinessModel(),
    MembershipBusinessModel(),
    UpsellingBusinessModel(),
    PureBundlingBusinessModel(),
    TiredPrcingBusinessModel(),
    TimeShareBusinessModel(),
    TransactionBasedBusinessModel(),
    NetworkBusinessModel(),
    EcosystemBusinessModel(),
    SponsorshipBusinessModel(),
    MultiLevelMarketingBusinessModel(),
    PlatformAsAService(),
    InfrastructureAsAService(),
    SoftwareAsAService(),
    FunctionAsAService(),
    HybridCloud(),
    ResellerAndReferralPrograms(),
    ConsultingAndProfessionalServices(),
    SoftwareLicensing(),
  ]
}

export default BusinessModelsConfigs
