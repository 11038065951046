import React, { useContext, useState } from 'react'
import NotificationCreator from '../../../../../../../../notificationsSystem/NotificationCreator'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import UserPortfolioContent from '../../../../../../../../profile/ProfileComponents/ProfileTopComponents/ProfileOverviewComponents/UserPortfolioComps/UserPortfolioContent'
import axios from 'axios'
import WindowProps from '../../../../../../../../windowViewer/WindowProps'
import { AuthContext } from '../../../../../../../../../context/AuthContext'
import TextEngine from '../../../../../../../../textProcessingEngine/TextEngine'
import NewFormDialogModel from '../../../../../../../../formsSystem/NewFormDialogModel'
import WindowViewerSystem from '../../../../../../../../windowViewer/WindowViewerSystem'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const InvestOfferUnitDetails = ({
  card,
  DCGProps,
  exprops,
  setRequests,
  requestsUsers,
}) => {
  const {} = exprops
  const { socket, project } = DCGProps
  const request = card
  const { user } = useContext(AuthContext)
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isLoading, setIsLoading] = useState()
  const {
    isWindowOpen: isWindowOpenII,
    windowOpener: windowOpenerII,
    windowCloser: windowCloserII,
  } = WindowProps()
  const [contract, setContract] = useState()
  const [repsondedTo, setRespondedTo] = useState(false)
  const activeFun = async ({ response }) => {
    try {
      setIsLoading(true)
      setRespondedTo(true)
      if (typeof setRequests === 'function')
        setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
      socket.current.emit('addNotifUser', request?.userId)
      if (response === 'contract') {
        const resContract = await axios.post(`/contracts`, {
          projectsIds: project?._id,
          usersIds: request?.userId,
          Title: `${project?.name} - ${requestingUser?.name} Contract`,
        })
        setContract(resContract?.data?.contract)
        windowOpenerII()
        await axios.post(`/projectContributors`, {
          projectId: project?._id,
          userId: request?.userId,
          contractId: resContract?.data?.contract?._id,
          MembershipState: 'Investor',
        })
        if (resContract?.data?.contract)
          NotificationCreator({
            associativity: 'investment',
            relation: 'visitor',
            conditionIndex: 0,
            accompaniedData: {
              response,
              elements: [
                {
                  hashTagType: resContract?.data?.contract?.hashTagType,
                  id: resContract?.data?.contract?._id,
                  title: resContract?.data?.contract?.Title,
                },
              ],
            },
            category: 'contributors',
            userId: request?.userId,
            currentUser: user,
            socket,
          })
      }
      if (response === 'refuse') {
        NotificationCreator({
          associativity: 'investment',
          relation: 'visitor',
          conditionIndex: 0,
          accompaniedData: {
            response,
          },
          category: 'contributors',
          userId: request?.userId,
          currentUser: user,
          socket,
        })
      }
      // request will be deleted after being responded to
      await axios.delete(`/requests/${request?._id}`)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteFun = async () => {
    try {
      await axios.delete(`/requests/${request?._id}`)
      setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
    } catch (error) {
      console.log(error)
    }
  }

  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const buttonsArray = [
    {
      title: isLoading
        ? rtl
          ? 'جارى إنشاء عقد...'
          : 'Generating Contract...'
        : rtl
        ? 'تعاقد'
        : 'Contract',
      funs: () => {
        activeFun({ response: 'contract' })
        toast.info(
          rtl
            ? `تم انشاء عقد و ارساله الى ${requestingUser?.firstName}`
            : `A contract has been created and sent to ${requestingUser?.firstName}`
        )
      },
      disabled: isLoading || repsondedTo,
    },
    {
      title: rtl ? 'رفض' : 'Refuse',
      funs: () => {
        activeFun({ response: 'refuse' })
        toast.info(rtl ? 'تم ارسال الرفض' : 'Refusal has been sent')
      },
      disabled: isLoading || repsondedTo,
    },
    {
      title: rtl ? 'حذف' : 'Delete',
      funs: () => {
        deleteFun()
        toast.warning(
          rtl ? 'تم مسح الطلب بنجاح' : 'Request has been deleted successfully'
        )
      },
      disabled: isLoading || repsondedTo,
    },
    {
      title: rtl ? 'الملف الشخصى' : 'Portfolio',
      funs: () => windowOpener(),
      disabled: isLoading,
    },
  ]
  const { t } = useTranslation()
  return (
    <>
      <section>
        {requestingUser?.firstName + ' ' + requestingUser?.lastName + rtl
          ? 'بعرض'
          : 'offers a' + ' ' + t(request?.subgenre) + rtl
          ? 'كإستثمار'
          : 'investment.'}
      </section>
      <TextEngine
        textContent={request?.requestData?.content || ''}
        textPackage={{
          hashTagMentionElements: request?.requestData?.chosenHashTagElements,
        }}
        charLimit={50}
      />
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
      <NewFormDialogModel
        NewFormComponent={
          <UserPortfolioContent user={requestingUser} {...DCGProps} />
        }
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        {...DCGProps}
        clickAwayState={true}
        dialogTitle={
          rtl
            ? `الملف الشخصى لـ ${requestingUser?.firstName}`
            : `${requestingUser?.firstName}'s Portfolio`
        }
      />
      {contract && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpenII}
          windowCloser={windowCloserII}
          {...DCGProps}
          props={{ contract, DCGProps }}
          windowType='Contract'
        />
      )}
    </>
  )
}

export default InvestOfferUnitDetails
