import React, { useEffect, useState } from 'react'
import NavigatorGenerator from '../../../../../../navigatorCardsModel/NavigatorGenerator'
import axios from 'axios'
import i18next from 'i18next'

const CampaignNavigator = (props) => {
  const { campaign } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [channels, setChannels] = useState([])
  const [sharedPosts, setSharedPosts] = useState([])
  useEffect(() => {
    const fetchUdeaPosts = async () => {
      try {
        const res = await axios.get(`/campaigns/campaignPosts/${campaign?._id}`)
        const resChannels = await axios.get(
          `/channels/elementChannels/${campaign?._id}`
        )
        setChannels(resChannels.data.channels)
        setSharedPosts(res.data.campaignPosts)
      } catch (error) {
        console.log(error)
      }
    }
    fetchUdeaPosts()
  }, [campaign?._id])
  const navigatorsArray = [
    {
      headerText: rtl ? 'منشورات' : 'Published Posts',
      unitsArray: sharedPosts,
      NavigatorCardsModelProps: {},
    },
    {
      headerText: rtl
        ? 'قنوات استخدمت هذه الحملة'
        : 'Channels used this Campaign',
      unitsArray: channels,
      NavigatorCardsModelProps: {},
    },
  ]
  return <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
}

export default CampaignNavigator
