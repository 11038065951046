import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { FcIdea } from 'react-icons/fc'
import { Button } from '@mui/material'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    fontSize: 12,
    padding: 10,
    borderRadius: 15,
    padding: 10,
    marginBottom: 10,
    fontStyle: 'oblique',
    display: 'flex',
    flexDirection: 'column',
  },
  ideaWrapper: {
    margin: 'auto 10px',
    display: 'flex',
    // alignSelf: 'center',
    alignItems: 'center',
    alignComtent: 'center',
    jsutifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const HelperText = ({ classes, info, isDarkModeActive, defaultShow }) => {
  const [show, setShow] = useState(defaultShow)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div
      className={classes.wrapper}
      style={{
        display: info?.helpertext ? 'flex' : 'none',
        border: `1px solid ${isDarkModeActive ? 'white' : 'black'}`,
      }}
    >
      <section className={classes.ideaWrapper}>
        <FcIdea size={23} />
        <Button className={classes.button} onClick={() => setShow(!show)}>
          {rtl
            ? show
              ? 'اخفاء الدليل الإرشادى'
              : 'عرض الدليل الإرشادى'
            : show
            ? 'Hide Guide'
            : 'View Guide'}
        </Button>
      </section>
      {show && <div>{info?.helpertext}</div>}
    </div>
  )
}

const WrappedHelperText = withStyles(styles)(HelperText)

export default WrappedHelperText
