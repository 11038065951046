import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const SupermarketBusinessModel = () => {
  const { t } = useTranslation('SupermarketBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 61,
    title: t('title_bm61'),
    subTitle: t('subTitle_bm61'),
    main: t('main_bm61'),
    when: t('when_bm61'),
    who: t('who_bm61'),
    what: t('what_bm61'),
    how: t('how_bm61'),
    why: t('why_bm61'),
    examples: rtl
      ? [
          'بقالة الملك كولين - تأسست عام 1930 - الولايات المتحدة الأمريكية',
          'ميريل لينش - تأسست عام 1930 - الولايات المتحدة الأمريكية',
          'Toys R Us - تأسست عام 1948 - الولايات المتحدة الأمريكية',
          'The Home Depot - تأسس 1978 - الولايات المتحدة الأمريكية',
          'Best Buy - تأسست عام 1983 - الولايات المتحدة الأمريكية',
          'Fressnapf - تأسست عام 1985 - ألمانيا',
          'Staples - تأسست عام 1986 - الولايات المتحدة الأمريكية',
        ]
      : [
          'King Kullen Grocery - Founded 1930 - USA',
          'Merril Lynch - Founded 1930 - USA',
          'Toys R Us - Founded 1948 - USA',
          'The Home Depot - Founded 1978 - USA',
          'Best Buy - Founded 1983 - USA',
          'Fressnapf - Founded 1985 - Germany',
          'Staples - Founded 1986 - USA',
        ],
    tags: rtl
      ? ['سعر منخفض', 'عروض ترويجية وسيطة']
      : ['Low Price', 'Cross-Promotions'],
    considerations: t('considerations_bm61'),
    combinations: ['$LBM50', '$LBM32'],
    hashTagType: 'businessModelTemp',
  }
}

export default SupermarketBusinessModel
