import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'
import ToastifyGeneralError from '../../../../../../../smallComponents/ToastifyGeneralError'
import i18next from 'i18next'

const MoveToAnotherSubDepFormButtons = ({
  componentClose,
  member,
  DCGProps,
  subDepId,
  setMembers,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { project, socket } = DCGProps
  const { user } = useContext(AuthContext)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const buttonsArray = [
    {
      title: isConfirming
        ? rtl
          ? 'جارى تنشيط بروتوكول النقل'
          : 'Transfer Protocol is Activating'
        : rtl
        ? 'تأكيد'
        : 'Confirm',
      disabled: isConfirming || member?.subDepId === subDepId,
      funs: async () => {
        setIsConfirming(true)
        try {
          const res = await axios.patch(
            `/projectContributors/${member?.userId}/${project?._id}`,
            { subDepId, subDepManager: false }
          )
          setMembers((ms) => [
            ...ms?.filter((m) => m?.userId !== member?.userId),
            res.data.contributor,
          ])
          socket.current.emit('addNotifUser', member?.userId)
          NotificationCreator({
            associativity: 'project',
            relation: 'member',
            conditionIndex: 5,
            accompaniedData: { projectId: project?._id, subDepId },
            category: 'contributors',
            userId: member?.userId,
            currentUser: user,
            socket,
          })
        } catch (error) {
          ToastifyGeneralError(error)
        }
        setIsConfirming(false)
        toast.success(
          rtl
            ? 'تم إضافة العضو الى اعضاء القسم الفرعى بنجاح. ربما تحتاج الى إعادة فتح القسم لمراجعة التغييرات'
            : 'Member has been added to the sub-departments members successfully. You may reopen the department to see the changes.'
        )
        componentClose()
      },
    },
    { title: rtl ? 'إلغاء' : 'Cancel', funs: componentClose },
    {
      title: isRemoving
        ? rtl
          ? 'جارى تنشيط بروتوكول الحذف'
          : 'Removal Protocol is Activating'
        : rtl
        ? 'حذف'
        : 'Remove',
      disabled: isRemoving || !member?.subDepId,
      funs: async () => {
        setIsRemoving(true)
        try {
          const res = await axios.patch(
            `/projectContributors/${member?.userId}/${project?._id}`,
            { subDepId: null, subDepManager: false }
          )
          setMembers((ms) => [
            ...ms?.filter((m) => m?.userId !== member?.userId),
            res.data.contributor,
          ])
          socket.current.emit('addNotifUser', member?.userId)
          NotificationCreator({
            associativity: 'project',
            relation: 'member',
            conditionIndex: 7,
            accompaniedData: { projectId: project?._id, subDepId },
            category: 'contributors',
            userId: member?.userId,
            currentUser: user,
            socket,
          })
        } catch (error) {
          ToastifyGeneralError(error)
        }
        toast.success(
          rtl
            ? 'تم حذف العضو من القسم الفرعى بنجاح برجاء اعادة فتح القسم'
            : 'Members has bee removed successfully from the sub-department. Please reopen the department'
        )
        setIsRemoving(false)
        componentClose()
      },
    },
  ]
  return <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
}

export default MoveToAnotherSubDepFormButtons
