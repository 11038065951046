import React from 'react'
import ResearchActivityDetails from './ResearchActivityProfileComps/ResearchActivityDetails'
import ResearchApplications from './ResearchActivityProfileComps/ResearchApplications'
import ResearchdevelopmentChannels from './ResearchActivityProfileComps/ResearchdevelopmentChannels'
import ResearchMainConcepts from './ResearchActivityProfileComps/ResearchMainConcepts'
import ReserachActivityTop from './ResearchActivityProfileComps/ReserachActivityTop'

const ResearchActivityProfile = (props) => {
  const { DCGProps } = props
  const { isDarkModeActive } = DCGProps
  return (
    <article
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <ReserachActivityTop {...props} />
      <ResearchActivityDetails {...props} />
      <ResearchMainConcepts {...props} />
      <ResearchApplications {...props} />
      <ResearchdevelopmentChannels {...props} />
    </article>
  )
}

export default ResearchActivityProfile
