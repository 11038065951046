import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const RentInsteadOfBuyBusinessModel = () => {
  const { t } = useTranslation('RentInsteadOfBuyBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 45,
    title: t('title_bm45'),
    subTitle: t('subTitle_bm45'),
    main: t('main_bm45'),
    when: t('when_bm45'),
    who: t('who_bm45'),
    what: t('what_bm45'),
    how: t('how_bm45'),
    why: t('why_bm45'),
    examples: rtl
      ? [
          'زيروكس - 1906 - الولايات المتحدة الأمريكية',
          'Blockbuster LLC - 1985 - الولايات المتحدة الأمريكية',
          'CWS-boco - 1885 - سويسرا',
          'FlexPetz - 2009 - الولايات المتحدة الأمريكية',
          'Luxusbabe و RentAFriend - 2015 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Xerox - 1906 - USA',
          'Blockbuster LLC - 1985 - USA',
          'CWS-boco - 1885 - Switzerland',
          'FlexPetz - 2009 - USA',
          'Luxusbabe and RentAFriend - 2015 - USA',
        ],
    tags: rtl
      ? [
          'عمل بأسعار معقولة',
          'إمكانية الوصول إلى المنتج',
          'تكاليف منخفضة',
          'الأسعار الثابتة',
          'الإيرادات الثابتة',
        ]
      : [
          'Affordable Business',
          'Product Accessibility',
          'Low Costs',
          'Fixed Prices',
          'Fixed Revenues',
        ],
    considerations: t('considerations_bm45'),
    combinations: ['$LBM40'],
    hashTagType: 'businessModelTemp',
  }
}

export default RentInsteadOfBuyBusinessModel
