import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Divider } from '@mui/material'
import AccessCardGenerator from '../../../../../ContractsNotificationsComponents/ContractsNotifsDialogRightComponents/NotificationBodyWithAccessCardComps/AccessCardGenerator'
import TaskProject from './TaskProject'

const styles = () => ({
  container: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  rightCard: {
    display: 'flex',
    flex: 1.5,
    flexDirection: 'column',
    padding: 10,
    alignContent: 'flex-start',
    width: '100%',
  },
  taskAccess: {
    display: 'flex',
    flex: 1,
  },
  '@media (max-width: 600px )': {
    wrapper: {
      flexDirection: 'column',
    },
    taskAccess: {
      justifyContent: 'center',
    },
  },
})

const ModeratedTasks = ({
  classes,
  isDarkModeActive,
  socket,
  tasks,
  projects,
}) => {
  return (
    <section className={classes.container}>
      {tasks.map((task, taskIndex) => {
        const project = projects.find(
          (project) => project?._id === task?.projectId
        )
        return (
          <section className={classes.wrapper} key={task?._id}>
            <Divider
              style={{
                backgroundColor: isDarkModeActive ? 'white' : null,
                display: taskIndex === 0 ? 'none' : null,
                marginBottom: 10,
              }}
            />
            <section className={classes.taskAccess}>
              <AccessCardGenerator
                element={task}
                isDarkModeActive={isDarkModeActive}
                socket={socket}
                modelProps={{ project }}
              />
            </section>
            <section className={classes.rightCard}>
              <TaskProject
                project={project}
                isDarkModeActive={isDarkModeActive}
                socket={socket}
                task={task}
              />
            </section>
          </section>
        )
      })}
    </section>
  )
}

const WrappedModeratedTasks = withStyles(styles)(ModeratedTasks)

export default WrappedModeratedTasks
