import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../buttonsSystem/ButtonsSystem'
import NewFormDialogModel from '../../../../../../formsSystem/NewFormDialogModel'
import ElementRequestsSenders from '../../../../../../notificationsSystem/ElementRequestsSenders'
import NoPermissionedInteraction from '../../../../../../notificationsSystem/NoPermissionedInteraction'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'
import RequestCreator from '../../../../../../notificationsSystem/RequestCreator'
import WindowProps from '../../../../../../windowViewer/WindowProps'
import ContributionProjectsOptions from '../../../ProjectPartnershipsComponents/ProjectPartnershipsDialogContentComps/ProjectPartnershipsContentArraysComps/ProjectChannelsComps/ProjectPartnershipChannelsComps/PartnershipChaneelProfileComps/ContributionProjectsOptions'
import i18next from 'i18next'

const ResourceInteractionButtons = ({ postMode, DCGProps, resource }) => {
  const { socket } = DCGProps
  const { user } = useContext(AuthContext)
  const senders = ElementRequestsSenders(resource?._id || resource?.id)
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const activeFun = async ({ project, userId }) => {
    try {
      socket.current.emit('addNotifUser', resource?.userId)
      NotificationCreator({
        associativity: 'resource',
        relation: 'resourceOwner',
        conditionIndex: 0,
        accompaniedData: {
          projectId: project?._id,
          elements: [
            {
              hashTagType: resource?.hashTagType,
              id: resource?._id || resource?.id,
              title: resource?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: resource?.userId,
        currentUser: user,
        socket,
        CRNDelete: true,
        element: resource,
      })
      RequestCreator({
        currentUser: user,
        genre: 'resource',
        subgenre: 'share_in',
        elementId: resource?._id || resource?.id,
        requestData: {
          project: {
            _id: DCGProps?.project?._id || project?._id,
            ProjectName: DCGProps?.project?.ProjectName || project?.ProjectName,
          },
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'اطلب المشاركة فى المورد' : 'Share In',
      funs: () => {
        windowOpener()
      },
    },
  ]

  const [interacted, setInteracted] = useState(false)
  return senders === undefined ? (
    <NoPermissionedInteraction checking={true} />
  ) : senders?.includes(user?._id) || interacted ? (
    <NoPermissionedInteraction />
  ) : (
    <>
      <ButtonsSystem
        buttonsArray={buttonsArray}
        hide={
          !postMode ||
          resource?.userId === user?._id ||
          resource?.usersIds?.includes(user?._id) ||
          user?.createdProjects?.find((p) => resource?.projectsIds?.includes(p))
        }
        {...DCGProps}
      />
      <NewFormDialogModel
        NewFormComponent={
          <ContributionProjectsOptions
            DCGProps={DCGProps}
            windowCloser={windowCloser}
            activeFun={activeFun}
            centralTitle={
              rtl
                ? 'إختر فى أى هيئة تريد المشاركة فى هذا المورد'
                : 'Choose in what entity you want to share in this resource'
            }
            setInteracted={setInteracted}
          />
        }
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        {...DCGProps}
        clickAwayState={true}
        dialogTitle={rtl ? 'إختار الهيئة' : 'Choose Entity'}
      />
    </>
  )
}

export default ResourceInteractionButtons
