import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditableGridModel from '../../../../../../../formsSystem/EditableGridModel'
import FormDataLangModulator from '../../../../../../../formsSystem/FormDataLangModulator'
import InfoDataIntialStateGen from '../../../../../../../formsSystem/InfoDataIntialStateGen'
import HashTagElementsRefiner from '../../../../../../../smallComponents/HashTagElementsRefiner'
import TitleHeader from '../../../../../../../smallComponents/Header'
import NewGlobalInquiryFormConfigs from './NewGlobalInquiryFormConfigs'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import i18next from 'i18next'

const GlobalIInquiryDetails = (props) => {
  const { globalInquiry, setIsUpdated, setCards, DCGProps } = props
  const { user } = useContext(AuthContext)
  const infoArray = NewGlobalInquiryFormConfigs()
    .filter((info) => info.formComponentType === 'text')
    .map((config, index) => ({
      title: config?.title,
      titleValue: globalInquiry[config.title.split(' ').join('')] || '',
      allowEmpty: true,
      helpertext: config.helpertext,
      wideMode: index,
      placeholder: config.placeholder,
    }))

  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const newInfoData = FormDataLangModulator(infoData, t)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(globalInquiry) || []
  )
  const onSave = async () => {
    if (globalInquiry?._id) {
      try {
        const res = await axios.patch(
          `/globalinquiries/${globalInquiry?._id}`,
          {
            ...newInfoData,
            chosenHashTagElements,
          }
        )
        setCards((inqs) => [
          ...inqs.filter((p) => p._id !== globalInquiry?._id),
          res.data.globalInquiry,
        ])
      } catch (error) {
        console.log(error)
      }
      setIsUpdated(true)
    }
  }
  const gridProps = {
    hideEditingTools: user?._id !== globalInquiry?.userId,
    ...props,
    ...DCGProps,
    infoArray,
    setChosenHashTagElements,
    chosenHashTagElements,
    hideHeader: true,
    infoData,
    setInfoData,
    onSave,
  }
  return (
    <>
      <TitleHeader headerText={rtl ? 'تفاصيل' : 'Global Inquiry Details'} />
      <EditableGridModel gridProps={gridProps} />
    </>
  )
}

export default GlobalIInquiryDetails
