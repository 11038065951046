import { ClickAwayListener, Dialog, DialogContent } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import NotificationSentCard from './NotificationConfirmComponents/NotificationSentCard'

const styles = () => ({
  cardsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
})

const NotificationsSentConfirmDialog = ({
  classes,
  confirmmationTitles,
  confirmationRTitleIndex,
  isConfirmDialogOpen,
  setIsConfirmDialogOpen,
  isDarkModeActive,
}) => {
  return (
    <Dialog
      disableScrollLock
      open={isConfirmDialogOpen}
      PaperProps={{
        style: {
          borderRadius: 10,
          background: isDarkModeActive ? 'rgb(49, 48, 53, 0.6)' : 'white',
          backdropFilter: 'blur(4px)',
          color: isDarkModeActive ? 'white' : 'inherit',
        },
      }}
    >
      <ClickAwayListener onClickAway={() => setIsConfirmDialogOpen(false)}>
        <DialogContent>
          <section className={classes.cardsWrapper}>
            <NotificationSentCard
              notificationTitle={
                confirmmationTitles &&
                confirmmationTitles[confirmationRTitleIndex]?.title
              }
              confirmationTip={
                confirmmationTitles &&
                confirmmationTitles[confirmationRTitleIndex]?.tip
              }
            />
          </section>
        </DialogContent>
      </ClickAwayListener>
    </Dialog>
  )
}

const WrappedNotificationsSentConfirmDialog = withStyles(styles)(
  NotificationsSentConfirmDialog
)

export default WrappedNotificationsSentConfirmDialog
