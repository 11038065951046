import React from 'react'
import {
  NotificationBodyWithAccessCard,
  NotificationViewModel,
} from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const NewProjectOfferContract = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? `قام ${sender?.name} بإرسال عقد اليك`
              : `${sender?.name} has sent you a contract `
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default NewProjectOfferContract
