import React from 'react'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import NotificationViewModel from '../../../NotificationViewModel'
import i18next from 'i18next'

const AdditionToNewProject = (props) => {
  const { sender, notification, project } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const MembershipState = notification?.accompaniedData?.MembershipState
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        rtl
          ? `قام ${sender?.name} بضمك الى مشروعه`
          : `${sender?.name} added you to ${project?.name} project as ${MembershipState}`
      }
      buttonsArray={[]}
    />
  )
}

export default AdditionToNewProject
