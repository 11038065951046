import axios from 'axios'
import React, { useEffect, useState } from 'react'
import FeedsSystem from '../../feedsSystem/FeedsSystem'
import { ShareDialogDescription } from './'

const GeneralShare = ({ shareSystemProps }) => {
  const {
    post,
    project,
    isUsedIn,
    sharesCount,
    setSharesCount,
    isDarkModeActive,
    setIsPostShared,
    postsUsers,
    sharedPosts,
    sharedPostsUsers,
    setPosts,
  } = shareSystemProps

  const [repUser, setRepUser] = useState()

  const user =
    postsUsers?.find((u) => u?._id === post?.userId) ||
    sharedPostsUsers?.find((u) => u?._id === post?.userId) ||
    repUser

  useEffect(() => {
    const fetchRepUser = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${post?.userId}`)
        setRepUser(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    if (!user && post?.feedsSystemCategory === 'home') fetchRepUser()
  }, [user, post?.userId, post?.feedsSystemCategory])

  return (
    <article>
      <ShareDialogDescription shareSystemProps={shareSystemProps} />
      <FeedsSystem
        feedsSystemProps={{
          post,
          project,
          isUsedIn,
          hideBottom: true,
          hideRightOptions: true,
          sharesCount,
          setSharesCount,
          isDarkModeActive,
          setIsPostShared,
          user,
          postsUsers,
          sharedPosts,
          sharedPostsUsers,
          setPosts,
        }}
      />
    </article>
  )
}

export default GeneralShare
