import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NavigatorCardsModel from '../../../../../../../navigatorCardsModel/NavigatorCardsModel'
import TitleHeader from '../../../../../../../smallComponents/Header'
import i18next from 'i18next'

const PartnerProjectChannels = ({ hidePartnerChannles, DCGProps, company }) => {
  const { project } = DCGProps
  const [channels, setChannels] = useState([])
  useEffect(() => {
    const fetchSharedChannels = async () => {
      try {
        const res = await axios.get(
          `/channels/sharedChannels/${company?._id}/${project?._id}`
        )
        setChannels(res.data.channels)
      } catch (error) {
        console.log(error)
      }
    }
    if (company?._id && project?._id) fetchSharedChannels()
  }, [company?._id, project?._id])
  const unitsArray = channels
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <section
      style={{
        display: unitsArray.length === 0 || hidePartnerChannles ? 'none' : null,
      }}
    >
      <TitleHeader
        headerText={rtl ? 'القنوات المنشأة' : 'Established Channels'}
      />
      <NavigatorCardsModel
        unitsArray={unitsArray}
        {...DCGProps}
        modelUnitProps={{
          elementsImages: [],
          imageKey: 'channel',
          windowProps: {
            windowCardName: 'channel',
            windowType: 'channel',
            DCGProps,
          },
        }}
      />
    </section>
  )
}

export default PartnerProjectChannels
