import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import EmptyComponentModel from '../../../../../../../emptyComponentModel/EmptyComponentModel'
import TitleHeader from '../../../../../../../smallComponents/Header'
import Loading from '../../../../../../../smallComponents/Loading'
import PermissionedUserUnit from './PermissionedAccessUsersComps/PermissionedUserUnit'
import i18next from 'i18next'

const PermissionedAccessUsers = ({ idea, DCGProps }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const [permissionedUsers, setPermissionedUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchPerUsers = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/ideas/permissionedUsers/${idea?._id}`)
        setPermissionedUsers(res.data.permissionedUsers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchPerUsers()
  }, [idea?._id])
  return (
    <section
      style={{
        display: user?._id !== idea?.userId && 'none',
      }}
    >
      <TitleHeader
        headerText={rtl ? 'المستخدمين المسموح لهم' : 'Permissioned Users'}
      />
      <article
        style={{
          maxHeight: 500,
          height: 'auto',
          overflowY: 'auto',
        }}
      >
        {isLoading ? (
          <Loading />
        ) : permissionedUsers?.length > 0 ? (
          permissionedUsers.map((perUser) => (
            <PermissionedUserUnit
              perUser={perUser}
              key={perUser?._id}
              setPermissionedUsers={setPermissionedUsers}
              idea={idea}
              DCGProps={DCGProps}
            />
          ))
        ) : (
          <EmptyComponentModel
            emptySentence={
              rtl
                ? `لا يوجد مستخدمين مسموح لهم بعد`
                : `No Permissioned Users Yet`
            }
            fontSize={16}
            fontWeight={500}
          />
        )}
      </article>
    </section>
  )
}

export default PermissionedAccessUsers
