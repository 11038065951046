import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Button } from '@mui/material'
import IdeaContent from '../../../../../../../sharingSystem/ShareDialogContentComponents/ShareModels/NewIdeaGeneratorComps/IdeaContent'
import ModifiedFormChecker from '../../../../../../../formsSystem/ModifiedFormChecker'
import { NewIdeaFormsConfigs } from '../../../../../../../feedsSystem'
import {
  BusinessModelsConfigs,
  MarketingStrategyAspectsConfigs,
} from '../../../../../../../suggestionsSystem/HashTagSuggestSystemComponents'
import HashTagElementsRefiner from '../../../../../../../smallComponents/HashTagElementsRefiner'
import { useTranslation } from 'react-i18next'
import TransparentBackCard from '../../../../../../../smallComponents/TransparentBackCard'
import FormDataInitialValueExtractor from '../../../../../../../formsSystem/FormDataInitialValueExtractor'
import FormDataLangModulator from '../../../../../../../formsSystem/FormDataLangModulator'
import ReverseFormDataLangModulator from '../../../../../../../formsSystem/ReverseFormDataLangModulator'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import i18next from 'i18next'
const NewIdeaGenerator = React.lazy(() =>
  import(
    '../../../../../../../sharingSystem/ShareDialogContentComponents/ShareModels/NewIdeaGenerator'
  )
)

const IdeaBody = (props) => {
  const { idea, DCGProps, setIsUpdated, infoData, setInfoData, setCards } =
    props
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t: generalT } = useTranslation()
  const [formData, setFormData] = useState({})
  const newformData = FormDataLangModulator(formData, generalT)
  const modIdea = ReverseFormDataLangModulator(idea)
  FormDataInitialValueExtractor(NewIdeaFormsConfigs(modIdea), setFormData)
  const cards = BusinessModelsConfigs().concat(
    MarketingStrategyAspectsConfigs().map((card) => ({
      ...card,
      id: card?.id + 'm',
    }))
  )

  const newInfoData = FormDataLangModulator(infoData, generalT)

  const [chosenCards, setChosenCards] = useState(
    idea?.modelTempsIds.map((id) => cards.find((card) => card?.id === id)) || []
  )
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(idea) || []
  )

  const modelTempsIds = chosenCards.map((card) => card?.id)
  const onSave = async () => {
    if (idea?._id) {
      try {
        const res = await axios.patch(`/ideas/${idea?._id}`, {
          ...newInfoData,
          chosenHashTagElements,
        })
        if (typeof setCards === 'function')
          setCards((ideas) => [res.data.idea, ...ideas])
      } catch (error) {
        console.log(error)
      }
      setIsUpdated(true)
    }
  }
  const ideaGeneratorProps = {
    chosenCards,
    setChosenCards,
    DCGProps,
    ...DCGProps,
    ...props,
    infoData,
    setInfoData,
    formComponentsValues: formData,
    setFormComponentsValues: setFormData,
    onSave,
    mode: 'profile',
    hideTETFooter: true,
    margins: 'auto',
    setChosenHashTagElements,
    chosenHashTagElements,
  }
  const configModifier = () => {
    return NewIdeaFormsConfigs(modIdea).map((c) => ({
      title: c?.title.split('_').join(''),
      titleValue: c?.titleValue,
    }))
  }
  const [currentIdea, setCurrentIdea] = useState(idea)
  const [isSaving, setIsSaving] = useState(false)
  const onSaveChanges = async () => {
    try {
      if (idea?._id) {
        setIsSaving(true)
        const res = await axios.patch(`/ideas/${idea?._id}`, {
          ...newformData,
          modelTempsIds,
        })
        if (typeof setIsUpdated === 'function') setIsUpdated(true)
        if (typeof setCards === 'function')
          setCards((ideas) => [res.data.idea, ...ideas])
        setIsSaving(false)
        setCurrentIdea(res.data.idea)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const disabled =
    (ModifiedFormChecker(configModifier(), newformData, false, currentIdea) &&
      currentIdea?.modelTempsIds?.length === chosenCards?.length) ||
    isSaving
  return (
    <>
      <IdeaContent
        {...props}
        infoData={infoData}
        setInfoData={setInfoData}
        onSave={onSave}
        chosenHashTagElements={chosenHashTagElements}
        setChosenHashTagElements={setChosenHashTagElements}
      />
      <TransparentBackCard
        {...DCGProps}
        content={
          <>
            <NewIdeaGenerator ideaGeneratorProps={ideaGeneratorProps} />
            {user?._id === idea?.userId && (
              <Button
                sx={{
                  borderRadius: '10px',
                  margin: '2px !important',
                  textTransform: 'none',
                  fontWeight: '550',
                  width: '100%',
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                }}
                disabled={disabled}
                onClick={onSaveChanges}
              >
                {isSaving
                  ? rtl
                    ? 'جارى الحفظ'
                    : 'Saving...'
                  : rtl
                  ? 'احفظ التغييرات'
                  : ' Save Changes'}
              </Button>
            )}
          </>
        }
      />
    </>
  )
}

export default IdeaBody
