import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'
import WindowSize from '../../../smallComponents/WindowSize'
import i18next from 'i18next'

const styles = () => ({
  title: {
    fontWeight: 500,
    fonsize: 13,
  },

  '@media (max-width: 600px)': {
    title: {
      fonsize: 12,
    },
  },
})

const ViewTitle = ({ classes, info, hideInfoTitles }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const windowSize = WindowSize()
  const { t } = useTranslation()
  return (
    <span
      className={classes.title}
      style={{
        display: hideInfoTitles ? 'none' : null,
        marginLeft: !rtl && windowSize < 600 && 0,
        marginRight: !rtl && windowSize < 600 && 0,
      }}
    >
      {t(info.title)}
    </span>
  )
}

const WrappedViewTitle = withStyles(styles)(ViewTitle)

export default WrappedViewTitle
