import axios from 'axios'
import React, { useContext, useState } from 'react'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import EditableGridModel from '../../../../../../../../formsSystem/EditableGridModel'
import ModelImagePreview from '../../../../../../../../profile/ProfileComponents/ProfileControlPanelComponents/ControlPanelDialogsContentComponents/IdeasInquiriesComponents/GalleryInfoProfileTopModelComps/ModelImagePreview'
import TextEngineToolsFooter from '../../../../../../../../smallComponents/TextEngineToolsFooter'
import NewAppFormConfigs from './NewAppFormConfigs'
import { AuthContext } from '../../../../../../../../../context/AuthContext'
import i18next from 'i18next'

const ResearchAppProfile = ({
  DCGProps,
  setResActApps,
  setProjectResearchApps,
  windowCloser,
  application,
  depManager,
  project,
}) => {
  const { isDarkModeActive } = DCGProps
  const { user } = useContext(AuthContext)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    application?.chosenHashTagElements || []
  )
  const infoArray = NewAppFormConfigs()
    .filter((info) => info.formComponentType === 'text')
    .map((config, index) => ({
      title: config.title,
      titleValue: application[config.title.split('_').join('')] || '',
      allowEmpty: true,
      wideMode: index,
      minRows: 5,
    }))
  const [infoData, setInfoData] = useState({
    Title: application?.Title,
    Content: application?.Content,
  })
  const onSave = async () => {
    if (application?._id)
      try {
        const res = await axios.patch(`/researchApps/${application?._id}`, {
          ...infoData,
          chosenHashTagElements,
        })
        setResActApps((apps) => [
          ...apps.filter((p) => p._id !== application?._id),
          res.data.researchApp,
        ])
      } catch (error) {
        console.log(error)
      }
  }
  const authorised =
    user?._id === depManager?.userId || user?._id === project?.userId
  const gridProps = {
    hideEditingTools: !authorised,
    infoArray,
    setChosenHashTagElements,
    chosenHashTagElements,
    hideHeader: true,
    infoData,
    setInfoData,
    onSave,
    ...DCGProps,
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'حذف' : 'Delete',
      funs: async () => {
        windowCloser()
        try {
          await axios.delete(`/researchApps/${application?._id}`)
          if (typeof setResActApps === 'function')
            setResActApps((apps) =>
              apps.filter((c) => c?._id !== application?._id)
            )
        } catch (error) {
          console.log(error)
        }
      },
    },
  ]
  return (
    <article
      style={{ width: '100%', color: isDarkModeActive ? 'white' : null }}
    >
      <ModelImagePreview
        galleryProps={{
          element: application,
          emptyGalleryTitle: rtl ? 'التطبيق' : 'Application',
          DCGProps,
          patchPath: 'researchApps',
          hideEdit: !authorised,
        }}
      />
      <EditableGridModel gridProps={gridProps} />
      <ButtonsSystem buttonsArray={buttonsArray} />
      <TextEngineToolsFooter {...DCGProps} hideTETFooter={!authorised} />
    </article>
  )
}

export default ResearchAppProfile
