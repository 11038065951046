import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const BrandBreaking = () => {
  const { t } = useTranslation('BrandBreaking')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 39,
    category: t('category_ms39'),
    title: t('title_ms39'),
    subTitle: t('subTitle_ms39'),
    main: t('main_ms39'),
    how: t('how_ms39'),
    usedInModels: [],
    tags: rtl
      ? [
          'تغيير الاحتياجات',
          'الرسائل الصحيحة',
          'قيمة فريدة',
          'رسائل مختلطة',
          'الارتباك وعدم الثقة',
        ]
      : [
          'Brand Breaking',
          'Name Label',
          'Clear Promise',
          'Changing Needs',
          'Disinterested',
          'Relevant',
          'Right Messages',
          'Unique Value',
          'Mixed Messages',
          'Confusion Mistrust',
        ],
    examples: t('examples_ms39'),
    hashTagType: 'markStraAspect',
  }
}

export default BrandBreaking
