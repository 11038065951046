import React from 'react'
import InvetmentOfferReplyCover from '../../ContributorsNotifsDialogRightComponents/InvestmentNotifDisplyModelComps/InvetmentOfferReplyCover'
import ContributeButtonInvestOffersCover from './InvestmentNotificationModelComps/ContributeButtonInvestOffersCover'
import NewInvestmentOfferNotifCover from './InvestmentNotificationModelComps/NewInvestmentOfferNotifCover'
import ListItemScreenViewModels from './ListItemScreenViewModels'

const InvestmentNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'ceo',
      conditions: [
        <NewInvestmentOfferNotifCover {...notifProps} />,
        <ContributeButtonInvestOffersCover {...notifProps} />,
      ],
    },
    {
      relation: 'visitor',
      conditions: [<InvetmentOfferReplyCover {...notifProps} />],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default InvestmentNotificationModel
