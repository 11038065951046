import React from 'react'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import NotificationViewModel from '../../../../NotificationViewModel'
import i18next from 'i18next'

const NewSubdDepCreation = (props) => {
  const { notification, project } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <section style={{ width: '100%' }}>
          {rtl
            ? `لقد تمت إضافتك الى قسم فرعى جديد`
            : 'You have been added to a new sub-department'}{' '}
          {notification?.accompaniedData?.subdepartment?.Name} in{' '}
          {project?.ProjectName}
        </section>
      }
      buttonsArray={[]}
    />
  )
}

export default NewSubdDepCreation
