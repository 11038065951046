import React, { useContext, useEffect, useState } from 'react'
import NewFormDialogModel from '../../../../../formsSystem/NewFormDialogModel'
import StateGenerator from '../../../../../smallComponents/StateGenerator'
import TasksCalenderDialogContent from './TasksCalenderComps/TasksCalenderDialogContent'
import CalenderModel from '../../../../../calender/CalenderModel'
import axios from 'axios'
import { AuthContext } from '../../../../../../context/AuthContext'
import MonthsArray from '../../../../../calender/MonthsArray'
import MultiLangNumber from '../../../../../smallComponents/MultiLangNumber'
import i18next from 'i18next'

const TasksCalender = (props) => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const currentYear = new Date().getUTCFullYear()
  const currentMonth = new Date().getUTCMonth()
  const [chosenMonth, setChosenMonth] = useState({
    chosenYear: currentYear,
    monthIndex: currentMonth,
  })
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({
      GeneralProps: true,
    })
  const year = chosenMonth?.chosenYear
  const [counts, setCounts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchCounts = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/tasks/tasksCalender/count/${user?._id}/?year=${year}`
        )
        setCounts(res.data.counts)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchCounts()
  }, [user?._id, year])

  return (
    <>
      <CalenderModel
        monthOnClick={(monthIndex, chosenYear) => {
          GeneralDialogOpener()
          setChosenMonth({ monthIndex, chosenYear })
        }}
        monthSubTitle={rtl ? 'مهام' : 'Tasks'}
        months={MonthsArray().map((m, index) => ({
          month: m.month,
          ar_month: m.ar_month,
          subTitleNo: counts[index],
        }))}
        isLoading={isLoading}
      />
      <NewFormDialogModel
        NewFormComponent={
          <TasksCalenderDialogContent
            {...props}
            chosenMonth={chosenMonth}
            user={user}
          />
        }
        newFormDialogClose={GeneralDialogClose}
        isNewFormDialogOpen={isGeneralDialogOpen}
        {...props}
        clickAwayState={true}
        dialogTitle={
          rtl
            ? `مهامك لشهر ${
                MonthsArray()[chosenMonth?.monthIndex]?.ar_month +
                ' ' +
                MultiLangNumber(chosenMonth?.chosenYear)
              }`
            : `Your ${
                MonthsArray()[chosenMonth?.monthIndex]?.month +
                ' ' +
                chosenMonth?.chosenYear
              }'s Tasks`
        }
      />
    </>
  )
}

export default TasksCalender
