import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const TiredPrcingBusinessModel = () => {
  const { t } = useTranslation('TiredPrcingBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 67,
    title: t('title_bm67'),
    subTitle: t('subTitle_bm67'),
    main: t('main_bm67'),
    when: t('when_bm67'),
    who: t('who_bm67'),
    what: t('what_bm67'),
    how: t('how_bm67'),
    why: t('why_bm67'),
    examples: rtl
      ? [
          'HubSpot - 2006 - الولايات المتحدة الأمريكية',
          'PayPal - 1998 - الولايات المتحدة الأمريكية',
          'Adobe - 1982 - الولايات المتحدة الأمريكية',
          'مايكروسوفت - 1975 - الولايات المتحدة الأمريكية',
          'أمازون - 1994 - الولايات المتحدة الأمريكية',
          'دروب بوكس - 2007 - الولايات المتحدة الأمريكية',
        ]
      : [
          'HubSpot - 2006 - USA',
          'PayPal - 1998 - USA',
          'Adobe - 1982 - USA',
          'Microsoft - 1975 - USA',
          'Amazon - 1994 - USA',
          'Dropbox - 2007 - USA',
        ],
    tags: rtl
      ? ['مستويات مختلفة', 'حوافز']
      : ['Product Offering', 'Different Levels,', 'Incentives'],
    considerations: t('considerations_bm67'),
    combinations: ['$LBM40'],
    hashTagType: 'businessModelTemp',
  }
}

export default TiredPrcingBusinessModel
