import React from 'react'
import NotificationViewModel from '../../../NotificationViewModel'
import { NotificationBodyWithAccessCard } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import { Link } from 'react-router-dom'
import i18next from 'i18next'

const NewChannelPost = (props) => {
  const { sender } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  NotificationAutoInterUpdator(props)
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl ? (
              <section>
                قام
                <Link
                  to={`/profile/${sender?._id}`}
                  style={{ margin: 'auto 4px' }}
                >
                  {sender.name}
                </Link>
                بنشر مناقشة جديدة فى قناة مشتركة
              </section>
            ) : (
              <section>
                <Link
                  to={`/profile/${sender?._id}`}
                  style={{ margin: 'auto 4px' }}
                >
                  {sender.name}
                </Link>
                has published a discussion in a channel
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default NewChannelPost
