import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'

const ProductPDFBody = ({ button }) => {
  const { element } = button.PDFGenProps
  const configs = Object.keys(element?.ProductProperties)?.map((key) => ({
    title: key.split('-').join(' '),
    titleValue: element?.ProductProperties[key],
    disableTrans: true
  }))
  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
        mainContent: element?.Description,
      }}
      button={button}
    />
  )
}

export default ProductPDFBody
