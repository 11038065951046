import React, { useContext } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'
import ClosedView from '../../../../../../../smallComponents/ClosedView'
import SendConfirmationButton from '../../../../../../../smallComponents/SendConfirmationButton'
import i18next from 'i18next'

const NotPermissionView = ({ DCGProps, idea }) => {
  const { user } = useContext(AuthContext)
  const { socket } = DCGProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  const onSend = async () => {
    socket?.current?.emit('addNotifUser', idea?.userId)
    NotificationCreator({
      associativity: 'idea',
      relation: 'visionOwner',
      conditionIndex: 1,
      accompaniedData: {
        elements: [
          {
            hashTagType: idea?.hashTagType,
            id: idea?._id,
            title: idea?.Title,
          },
        ],
      },
      category: 'contributors',
      userId: idea?.userId,
      currentUser: user,
      socket,
    })
  }
  const closeMessage = (
    <>
      {rtl
        ? 'هذا المحتوى محمى، تحتاج الى إذن لعرضه'
        : 'This content is secured, you have to get permissioned to access it'}{' '}
      <br />
      <br />
      <SendConfirmationButton
        sendText={rtl ? 'أطلب دخول للمحتوى' : 'Ask For Permisssion'}
        confirmmationTitles={[
          {
            title: rtl ? 'تم إرسال الطلب' : 'Request Sent',
            tip: rtl
              ? 'سوف يتم اعلامك بالرد من خلال إشعارات المشاكرين لديك'
              : 'You will recieve a notification with response in contributors Notifications rolls',
          },
        ]}
        {...DCGProps}
        sentText={rtl ? 'تم إرسال الطلب' : 'Permission Request Sent'}
        onSend={onSend}
      />
    </>
  )
  return <ClosedView {...DCGProps} closeMessage={closeMessage} />
}

export default NotPermissionView
