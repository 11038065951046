import React from 'react'
import CommentModel from './CommentModel'
import NewComemntModuleMView from './CommentingMobileVersionComponents/NewComemntModuleMView'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import CommentsDialogCore from './CommentsDialogCore'
import LoadMoreButton from '../../smallComponents/LoadMoreButton'
import DNALoader from '../../smallComponents/DNALoader'

const CommentingMobileVersion = ({ commentProps }) => {
  const { postComments, setPage, currentResponse, page, isLoading } =
    commentProps
  return (
    <>
      <CommentsDialogCore commentProps={commentProps} />
      <section style={{ marginBottom: 60 }}>
        <ReactScrollWheelHandler
          downHandler={(e) => {
            setPage((prev) => prev + 1)
          }}
        >
          {postComments?.map((comment) => (
            <CommentModel
              comment={comment}
              key={comment?._id}
              {...commentProps}
            />
          ))}
          {currentResponse &&
            page !== 1 &&
            currentResponse < 5 &&
            !isLoading && <LoadMoreButton setPage={setPage} />}
          {isLoading && <DNALoader />}
        </ReactScrollWheelHandler>
      </section>
      <NewComemntModuleMView {...commentProps} />
    </>
  )
}

export default CommentingMobileVersion
