import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NotificationViewModel } from '../../../..'
import GridLoader from '../../../../../../smallComponents/GridLoader'
import MultiMillify from '../../../../../../smallComponents/MultiMillify'
import RemovedItem from '../../../../../../smallComponents/RemovedItem'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import PollCard from './NewPollNotificationComps/PollCard'
import i18next from 'i18next'

const PollResultsNotification = (props) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { notification } = props
  NotificationAutoInterUpdator(props)
  const [poll, setPoll] = useState()
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchPoll = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/polls/${notification?.accompaniedData?.elements[0]?.id}`
        )
        setPoll(res.data.poll)
        setOptions(res.data.options)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (notification?.accompaniedData?.elements[0]?.id) fetchPoll()
  }, [notification?.accompaniedData?.elements[0]?.id])
  let allVoters = []
  options?.map((op) => {
    allVoters.push(...op?.votersIds)
  })
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <div style={{ width: '100%' }}>
          {rtl
            ? `لقد حصلت على نتائج توصيت بـ ${MultiMillify(
                allVoters?.length
              )} صوتاً`
            : `Your Poll Results are ready with ${MultiMillify(
                allVoters?.length
              )} votes`}

          <br />
          <br />
          {isLoading ? (
            <GridLoader />
          ) : poll ? (
            <PollCard
              poll={poll}
              options={options}
              setOptions={setOptions}
              {...props}
            />
          ) : (
            <RemovedItem
              removedMssg={
                rtl ? 'لقد تم مسح أو إلغاء التصويت' : 'Voting has been deleted'
              }
            />
          )}
        </div>
      }
      buttonsArray={[]}
    />
  )
}

export default PollResultsNotification
