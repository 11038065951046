import React, { useEffect, useState } from 'react'
import TitleHeader from '../../../../../../smallComponents/Header'
import ExpandedCardsSystem from '../../../../../../expandedCardsSystem/ExpandedCardsSystem'
import JobRequestDetails from './JobRequestsComponents/JobRequestDetails'
import axios from 'axios'
import MultiLangNumber from '../../../../../../smallComponents/MultiLangNumber'
import { useTranslation } from 'react-i18next'
import Loading from '../../../../../../smallComponents/Loading'

const JobRequests = ({ DCGProps, job }) => {
  const { t } = useTranslation()
  const [requests, setRequests] = useState([])
  const [requestsUsers, setRequestsUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/requests/elementRequests/${job?._id}`)
        setRequests(res.data.requests)
        setRequestsUsers(res.data.requestsUsers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchRequests()
  }, [job?._id])

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <TitleHeader
        headerText={`${MultiLangNumber(requests?.length)} ${t('Requests')}`}
      />
      <ExpandedCardsSystem
        cards={requests}
        UnitCardDetailsComponent={JobRequestDetails}
        firsButtonConfigs={{
          hideButton: true,
          props: { job },
        }}
        searchSystemProps={{}}
        DCGProps={DCGProps}
        requestsUsers={requestsUsers}
        setRequests={setRequests}
      />
    </>
  )
}

export default JobRequests
