import React, { useContext, useEffect, useState } from 'react'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import CentralTitle from '../../../smallComponents/CentralTitle'
import axios from 'axios'
import { AuthContext } from '../../../../context/AuthContext'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import ToastifyGeneralError from '../../../smallComponents/ToastifyGeneralError'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const BreakContractDialog = ({ windowCloser, DCGProps, projects }) => {
  const { isDarkModeActive, socket } = DCGProps
  const project = projects[0]
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [tasksCount, setTasksCount] = useState(0)
  const [contributor, setContributor] = useState(null)
  const [ready, setReady] = useState(false)
  const [isChecking, setIsChecking] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      setIsChecking(true)
      try {
        const res = await axios.get(
          `/projectContributors/contractEnding/contributorTies/${project?._id}/${user?._id}`
        )
        setTasksCount(res.data.tasksCount)
        setContributor(res.data.contributor)
        setReady(res.data.ready)
      } catch (error) {}
      setIsChecking(false)
    }
    if (project?._id && user?._id) fetchData()
  }, [user?._id, project?._id])
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: windowCloser,
      disabled: isLoading,
    },
    {
      title: rtl
        ? isChecking
          ? 'جارى مراجعة البيانات'
          : isLoading
          ? 'جارى تنشيط بروتوكول الإلغاء'
          : 'تأكيد'
        : isChecking
        ? 'Checking Contributor Data'
        : isLoading
        ? 'Processing Contract Breaking Protocol'
        : 'Confirm',
      disabled: isLoading || !ready || isChecking,
      funs: async () => {
        try {
          setIsLoading(true)
          const res = await axios.get(`/contracts/${contributor?.contractId}`)
          if (res.data.contract) {
            socket.current.emit('addNotifUser', project?.userId)
            NotificationCreator({
              associativity: 'activeContract',
              relation: 'opponent',
              conditionIndex: 2,
              accompaniedData: {
                projectId: project?._id,
                elements: [
                  {
                    title: res.data.contract?.Title,
                    id: res.data.contract?._id,
                    hashTagType: res.data.contract?.hashTagType,
                  },
                ],
              },
              category: 'deals',
              userId: project?.userId,
              currentUser: user,
              socket,
            })
            setIsLoading(false)
            toast.info(
              rtl
                ? 'تم إرسال إخطارات لأصحاب المشروع لمراجعة طلبك'
                : 'A notifications sent to the project holders to revise your request'
            )
            windowCloser()
          }
        } catch (error) {
          ToastifyGeneralError(error)
        }
      },
    },
  ]
  return (
    <article style={{ color: isDarkModeActive && 'white' }}>
      <CentralTitle
        title={rtl ? 'إنهاء عقد التشارك' : 'Ending Contribution Contract'}
      />
      <div>
        <p> {rtl ? 'عزيزي المساهم،' : 'Dear Contributor,'} </p>
        <p>
          {rtl
            ? 'نأسف لسماع خبر رغبتك في الانسحاب من المشروع.'
            : 'We are sorry to hear about your desire to withdraw from the project.'}
        </p>
        <p>
          {rtl
            ? 'يرجى العلم بأن إزالة المساهم من المشروع يتطلب إغلاق جميع الارتباطات المرتبطة به والتأكد من عدم توليه منصب مدير لأي قسم أو قسم فرعي.'
            : 'Please be aware that removing a contributor from the project requires closing all associated ties and ensuring they do not hold any position as a manager of any department or subdepartment.'}
        </p>
        <div style={{ display: ready ? 'none' : null }}>
          <p>
            {rtl
              ? 'يرجى مراجعة هذه القيود للمتابعة:'
              : 'Please review these restrictions before proceeding:'}
          </p>
          {tasksCount > 0 || contributor?.departmentManager ? (
            <p>
              {rtl
                ? 'لا يمكن إزالتك من المشروع لأنك'
                : 'You cannot be removed from the project because'}
              {tasksCount > 0 && rtl
                ? `مسؤول عن تنفيذ ${tasksCount} مهام.`
                : tasksCount > 0
                ? ` are responsible for completing ${tasksCount} tasks.`
                : ''}
              {tasksCount > 0 && contributor?.departmentManager && rtl
                ? 'و'
                : contributor?.departmentManager
                ? 'You are also a department manager.'
                : ''}
              {rtl
                ? 'يرجى إكمال المهام المرتبطة بك وتحويل المسؤولية إلى شخص آخر قبل إزالتك من المشروع.'
                : 'Please complete your associated tasks and transfer responsibility to someone else before being removed from the project.'}
            </p>
          ) : null}
          {!tasksCount &&
          !contributor?.departmentManager &&
          contributor?.subDepManager ? (
            <p>
              {rtl
                ? 'لا يمكن إزالتك من المشروع لأنك مدير قسم فرعي.'
                : 'You cannot be removed from the project because you are a subdepartment manager.'}
              {rtl
                ? 'يرجى تحويل المسؤولية إلى شخص آخر والتأكد من عدم توليك أي منصب قبل الانسحاب.'
                : 'Please transfer responsibility to someone else and ensure you do not hold any position before withdrawing.'}
            </p>
          ) : null}
        </div>
        <p>
          {rtl
            ? 'يرجى عدم تولي أي منصب كمدير لأي قسم أو قسم فرعي في المشروع.'
            : 'Please do not hold any position as a manager of any department or subdepartment in the project.'}
        </p>
        <p>
          {rtl
            ? 'عند المتابعة، سيتم إرسال إشعار بطلبك إلى المسؤول  فى المشروع المقصود. وبمجرد الموافقة، سيتم إتمام العملية وسيتم فسخ العقد الحالي الذي يربطك بالمشروع.'
            : 'By proceeding, a request notification will be sent to the project. Once you agree, the process will be completed, and the current contract connecting you to the project will be terminated.'}
        </p>
      </div>
      <ButtonsSystem buttonsArray={buttonsArray} />
    </article>
  )
}

export default BreakContractDialog
