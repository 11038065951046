import React, { createContext, useEffect, useReducer } from 'react'
import AuthReducer from './AuthReducer'
import CryptoJS from 'crypto-js'
import cookies from 'js-cookie'

const USER_DECRYPT_SECRET_KEY = String(process.env.USER_DECRYPT_SECRET_KEY)

const INITIAL_STATE = {
  user: null,
  isFetching: false,
  error: false,
}

try {
  const encryptedUser = localStorage.getItem('hutupia_user')
  if (encryptedUser) {
    const decryptedData = CryptoJS.AES.decrypt(
      encryptedUser,
      USER_DECRYPT_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8)
    if (decryptedData) {
      INITIAL_STATE.user = JSON.parse(decryptedData)
    }
  }
} catch (error) {
  console.error(error)
}

export const AuthContext = createContext(INITIAL_STATE)

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)

  const ecryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(state.user),
    USER_DECRYPT_SECRET_KEY
  ).toString()

  // the reason i did so is that cookies cannot store more than 4kb
  // the data of the user grows up due to activity
  // so i will keep the needed info only
  const reduced_encrypted = CryptoJS.AES.encrypt(
    JSON.stringify({
      _id: state.user?._id,
      email: state.user?.email,
      firstName: state.user?.firstName,
      lastName: state.user?.lastName,
      isAdmin: state.user?.isAdmin,
      isEmailVerified: state.user?.isEmailVerified,
      gender: state.user?.gender,
      profilePicture: state.user?.profilePicture,
      verified: state.user?.verified,
      geoRagion: state.user?.geoRagion,
      position: state.user?.position,
      country: state.user?.country,
    }),
    USER_DECRYPT_SECRET_KEY
  ).toString()

  useEffect(() => {
    if (state.user) {
      // adding to the local storage of this domain
      localStorage.setItem('hutupia_user', ecryptedData)

      // sending it to other subdomians through cookies
      cookies.set('user', reduced_encrypted, {
        domain: '.hutupia.com',
        expires: 7,
        secure: true,
      })
    }
  }, [state.user])

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
