import React, { useState } from 'react'
import UpImageViewer from './ImageUploaderSystemComponents/UpImageViewer'
import UpImageButton from './ImageUploaderSystemComponents/UpImageButton'
import { v4 as uuidv4 } from 'uuid'
import Resizer from 'react-image-file-resizer'
import uploadImage from '../../firebase/uploadImage'
import deleteFile from '../../firebase/deleteFile'

const ImageUploaderSystem = ({
  imageIcon,
  funtionalityIndex,
  images,
  setImages,
  pathName,
  updateElementImages,
  setIsUploading,
}) => {
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })
  const [isLoading, setIsLoading] = useState(false)
  const imagesHandler = async (event) => {
    const file = event.target.files[0]
    try {
      setIsLoading(true)
      if (typeof setIsUploading === 'function') setIsUploading(true)
      const uri = await resizeFile(file)
      if (uri) {
        const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop()
        const imageURL = await uploadImage(uri, `${pathName}/${imageName}`)
        setImages([...images, imageURL])
      }
      setIsLoading(false)
    if (typeof setIsUploading === 'function') setIsUploading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const removeImage = async (imageIndex, image) => {
    setImages(images.filter((_, imgIndex) => imgIndex !== imageIndex))
    try {
      if (typeof updateElementImages === 'function')
        await updateElementImages(image)
    } catch (error) {
      console.log(error)
    }
    try {
      await deleteFile(image)
    } catch (error) {
      console.log(error)
    }
  }

  const components = [
    <UpImageButton
      imageIcon={imageIcon}
      imagesHandler={imagesHandler}
      isLoading={isLoading}
    />,
    <UpImageViewer images={images} removeImage={removeImage} />,
  ]

  return components[funtionalityIndex]
}

export default ImageUploaderSystem
