import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const FromPushToPullBusinessModel = () => {
  const { t } = useTranslation('FromPushToPullBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 23,
    title: t('title_bm23'),
    subTitle: t('subTitle_bm23'),
    main: t('main_bm23'),
    when: t('when_bm23'),
    who: t('who_bm23'),
    what: t('what_bm23'),
    how: t('how_bm23'),
    why: t('why_bm23'),
    examples: rtl
      ? [
          'Geberit - تأسست 1874 - سويسري',
          'تويوتا - تأسست عام 1937 - اليابان',
          'Bosch - تأسست عام 1886 - ألمانيا',
          'BMW - تأسست عام 1916 - ألمانيا',
          'زارا - تأسست 1975 - إسبانيا',
        ]
      : [
          'Geberit - Founded 1874 - Swiss',
          'Toyota - Founded 1937 - Japan',
          'Bosch - Founded 1886 - Germany',
          'BMW - Founded 1916 - Germany',
          'Zara - Founded 1975 - Spain',
        ],
    tags: rtl
      ? [
          'التركيز على العملاء',
          'البيع المركّز على العميل',
          'ممارسات تجارية صديقة للبيئة',
          'استراتيجيات عمل مبتكرة',
          'ريادة السوق',
          'إدارة سلسلة التوريد سريعة الاستجابة',
          'مستويات المخزون المنخفضة',
          'فعالية التكلفة',
          'نموذج الأعمال المستدامة',
          'التصنيع الآلي',
          'مهام سير العمل الموزعة',
          'تكاليف عامة مخفضة',
          'إنتاج في الوقت المناسب',
        ]
      : [
          'Customer-Centricity',
          'Customer-Focused Selling',
          'Eco-Friendly Business Practices',
          'Innovative Business Strategies',
          'Market Leadership',
          'Responsive Supply Chain Management',
          'Low Inventory Levels',
          'Cost Efficiency',
          'Sustainable Business Model',
          'Automated Manufacturing',
          'Distributed Workflows',
          'Minimized Overhead Costs',
          'Just-in-Time Production',
        ],
    considerations: t('considerations_bm23'),
    combinations: ['$LBM14'],
    hashTagType: 'businessModelTemp',
  }
}

export default FromPushToPullBusinessModel
