import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import InfoDataIntialStateGen from '../../../../../../formsSystem/InfoDataIntialStateGen'
import newSharedResourceConfig from '../../../../../../leftbarDirectoryComponents/feedsModels/PostBodyModelComponents/SharedResourcePostModelComps/SharedResourceConfigs'
import HashTagElementsRefiner from '../../../../../../smallComponents/HashTagElementsRefiner'
import { AuthContext } from '../../../../../../../context/AuthContext'
import i18next from 'i18next'

const ResourceProperties = ({
  isDarkModeActive,
  DCGProps,
  resource,
  setCards,
  setIsUpdated,
}) => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const infoArray = newSharedResourceConfig()
    .filter((info) => !info?.hideInPost)
    .map((config, index) => ({
      title: config.title,
      titleValue: resource[config.title.split('_').join('')] || '',
      allowEmpty: true,
      helpertext: config.helpertext,
      wideMode: index !== 0,
      minRows: config.minRows,
      placeholder: config.placeholder,
    }))
  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))
  const { t } = useTranslation()
  const newInfoData = FormDataLangModulator(infoData, t)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(resource) || []
  )
  const onSave = async () => {
    if (resource?._id) {
      try {
        const res = await axios.patch(`/resources/${resource?._id}`, {
          ...newInfoData,
          chosenHashTagElements,
        })
        setCards((resos) => [
          ...resos.filter((p) => p._id !== resource?._id),
          res.data.resource,
        ])
      } catch (error) {
        console.log(error)
      }
      setIsUpdated(true)
    }
  }
  const gridProps = {
    hideEditingTools: user?._id !== resource?.userId,
    headerText: rtl ? 'تفاصيل المورد' : 'Resource Properties',
    infoArray,
    setChosenHashTagElements,
    chosenHashTagElements,
    isDarkModeActive,
    infoData,
    setInfoData,
    onSave,
    ...DCGProps,
  }
  return <EditableGridModel gridProps={gridProps} />
}

export default ResourceProperties
