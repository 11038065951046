import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const SupplierPower = () => {
  const { t } = useTranslation('SupplierPower')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 20,
    category: t('category_ms20'),
    title: t('title_ms20'),
    subTitle: t('subTitle_ms20'),
    main: t('main_ms20'),
    how: t('how_ms20'),
    usedInModels: [],
    tags: rtl
      ? [
          'فعاله من حيث التكلفه',
          'الشراكات الاستراتيجية',
          'المشتريات المركزية',
          'الموردون البديلون',
        ]
      : [
          'Cost-Effective',
          'Strategic Partnerships',
          'Centralized Procurement',
          'Alternative Suppliers',
        ],
    examples: [t('examples_ms20')],
    hashTagType: 'markStraAspect',
  }
}

export default SupplierPower
