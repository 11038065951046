import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import ExpandedCardsSystem from '../../../../expandedCardsSystem/ExpandedCardsSystem'
import NavigatorGenerator from '../../../../navigatorCardsModel/NavigatorGenerator'
import TitleHeader from '../../../../smallComponents/Header'
import ActivitySectionDetails from './MemberActivityComponents/ActivitySectionDetails'
import ActivitySectionTitle from './MemberActivityComponents/ActivitySectionTitle'
import MemberAssignedTasksConfigs from './MemberActivityComponents/MemberAssignedTasksConfigs'
import MemberPromotions from './MemberActivityComponents/MemberPromotions'
import { AuthContext } from '../../../../../context/AuthContext'
import i18next from 'i18next'

const MemberActivity = (props) => {
  const { member, DCGProps } = props
  const { project } = DCGProps
  const [tasks, setTasks] = useState()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AuthContext)
  useEffect(() => {
    const fetchMemActivity = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/projectContributors/contributorActivity/${member?.userId}/${project?._id}`
        )
        setTasks(res.data.tasks)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (member?.userId && project?._id) fetchMemActivity()
  }, [member?.userId, project?._id])
  const activitySections = [
    {
      title: rtl ? 'ترقيات' : 'Promotions',
      component: <MemberPromotions {...props} />,
    },
  ]

  const firsButtonConfigs = {
    hideButton: true,
  }

  const navigatorsArray = [
    MemberAssignedTasksConfigs({
      tasks,
      hideNavigator: !tasks,
      DCGProps,
      user,
    }),
  ]
  return (
    <>
      <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
      <span
        style={{
          display: Number(member?.departmentId) === undefined && 'none',
        }}
      >
        <TitleHeader headerText={rtl ? 'نشاط' : 'Activity'} />
        <ExpandedCardsSystem
          cards={activitySections}
          UnitCardDetailsComponent={ActivitySectionDetails}
          firsButtonConfigs={firsButtonConfigs}
          UnitCardLeftComponent={ActivitySectionTitle}
          searchSystemProps={{}}
          {...props}
        />
      </span>
    </>
  )
}
export default MemberActivity
