import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../context/AuthContext'
import PopoverMenu from '../../../../popoverMenu/PopoverMenu'
import axios from 'axios'
import deleteFile from '../../../../../firebase/deleteFile'
import WindowProps from '../../../../windowViewer/WindowProps'
import FormsComponents from '../../../../formsSystem/FormsComponents'
import { FeedContext } from '../../../../../context/FeedContext/FeedContext'
import { useTranslation } from 'react-i18next'
import NotificationCreator from '../../../../notificationsSystem/NotificationCreator'
import ToastifyGeneralError from '../../../../smallComponents/ToastifyGeneralError'

const CommentMorevert = (props) => {
  const {
    comment,
    setPostComments,
    hideCommentBottom,
    post,
    socket,
    isDarkModeActive,
    project,
    departmentId,
    subDepId,
    setComment,
  } = props
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const [repliesUrls, setRepliesUrls] = useState([])
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const [textContent, setTextContent] = useState(comment?.text)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    comment?.chosenHashTagElements
  )
  const { notification, dispatch } = useContext(FeedContext)
  const [images, setImages] = useState(comment?.images)
  const [playerLink, setPlayerLink] = useState(comment?.playerLink)

  useEffect(() => {
    const fetchUrls = async () => {
      try {
        const res = await axios.get(`/urls/comment/${comment?._id}`)
        setRepliesUrls(res.data.urls)
      } catch (error) {
        console.log(error)
      }
    }
    if (!comment?.commentId) fetchUrls()
  }, [comment?._id])
  const optionsArray = [
    {
      type: 'edit',
      funs: () => {
        windowOpener()
        setTextContent(comment?.text)
        setImages(comment?.images)
        setChosenHashTagElements(comment?.chosenHashTagElements)
        setPlayerLink(comment?.playerLink)
      },
    },
    {
      type: 'delete',
      funs: async () => {
        try {
          Promise.all(
            comment?.images?.map(async (image) => {
              if (image) await deleteFile(image)
            })
          )
          Promise.all(
            repliesUrls?.map(async (image) => {
              if (image) await deleteFile(image)
            })
          )
          await axios.delete(`/comments/${comment?._id}`, {
            headers: {},
            data: {
              userId: user?._id,
            },
          })
          setPostComments((comms) =>
            comms?.filter((com) => com?._id !== comment?._id)
          )
        } catch (error) {
          console.log(error)
        }
      },
      disabled: hideCommentBottom,
    },
  ]

  const onSend = async () => {
    try {
      const res = await axios.patch(`/comments/${comment?._id}`, {
        ...comment,
        chosenHashTagElements,
        images,
        text: textContent,
        playerLink,
      })
      if (typeof setComment === 'function') setComment(res.data.comment)
      if (typeof setPostComments === 'function')
        setPostComments((comms) => {
          let oldIndex = comms?.findIndex((p) => p._id === comment?._id)
          return comms.map((p, i) => (i === oldIndex ? res.data.comment : p))
        })
      windowCloser()
    } catch (error) {
      console.log(error)
      ToastifyGeneralError(error)
    }
    // for mention notification
    if (notification?.mentionedUsersIds) {
      Promise.all(
        notification?.mentionedUsersIds?.map((id) =>
          NotificationCreator({
            associativity: 'post',
            relation: 'mentioned',
            conditionIndex: hideCommentBottom ? 2 : 1,
            accompaniedData: { postId: post?._id, commentId: comment?._id },
            category: 'general',
            userId: id,
            currentUser: user,
            socket,
          })
        )
      )
      dispatch({
        type: 'REMOVE_MENTION',
      })
    }
  }

  const formsCompsProps = {
    formComponentIndex: 5,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    placeholder: t('Leave_a_Comment'),
    images,
    setImages,
    onSend,
    pathName: `/comments/${post?._id}`,
    isWindowOpen,
    windowOpener,
    windowCloser,
    newImages: images?.filter((im) => !comment?.images?.includes(im)),
    socket,
    isDarkModeActive,
    project,
    departmentId,
    subDepId,
    link: playerLink,
    setLink: setPlayerLink,
  }
  return (
    <div
      style={{
        margin: 'auto 10px',
        display: comment?.userId !== user?._id && 'none',
      }}
    >
      <PopoverMenu optionsArray={optionsArray} />
      <FormsComponents formsCompsProps={formsCompsProps} />
    </div>
  )
}

export default CommentMorevert
