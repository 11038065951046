import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../../../context/AuthContext'
import NavigatorGenerator from '../../../../../../../../../navigatorCardsModel/NavigatorGenerator'
import axios from 'axios'
import NotificationCreator from '../../../../../../../../../notificationsSystem/NotificationCreator'
import AlertMessg from '../../../../../../../../../confirmationSystem/AlertMessg'
import DefaultImageGrapper from '../../../../../../../../../smallComponents/DefaultImageGrapper'
import i18next from 'i18next'

const ChannelNavigators = (props) => {
  const { channel, DCGProps, setCards, users, projects, members } = props
  const { socket, project } = DCGProps
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)

  const pathNameGen = (project) => `/projectProfile/${project?.id}`
  const channelledUsers = users
    ?.filter((u) => channel?.usersIds?.find((id) => u?._id === id))
    ?.map((us) => ({
      id: us?._id,
      hashTagType: 'userContributor',
      title: `${us?.firstName + ' ' + us?.lastName}`,
      name: `${us?.firstName + ' ' + us?.lastName}`,
      image: us?.profilePicture,
    }))
  const channelledProejcts = projects
    ?.filter((u) => channel?.projectsIds?.find((id) => u?._id === id))
    ?.map((pro) => ({
      id: pro?._id,
      title: pro?.ProjectName,
      image: pro?.projectPhoto || DefaultImageGrapper('noProjectAvatar'),
    }))

  const activeFun = async ({ associativity, relation, conditionIndex }) => {
    try {
      members?.map((m) => {
        socket.current.emit('addNotifUser', m)
        NotificationCreator({
          associativity,
          relation,
          conditionIndex,
          accompaniedData: {
            projectId: project?._id,
            elements: [
              {
                hashTagType: channel?.hashTagType,
                id: channel?._id,
                title: channel?.Title,
              },
            ],
          },
          category: 'deals',
          userId: m,
          currentUser: user,
          socket,
        })
      })
    } catch (error) {
      console.log(error)
    }
  }
  const [interesets, setIntersets] = useState(channel?.elements)
  const navigatorsArray = [
    {
      headerText: rtl ? 'المشروعات بالقناة' : 'Channelled Projects',
      unitsArray: channelledProejcts || [],
      modelUnitProps: {
        pathName: pathNameGen,
      },
      NavigatorCardsModelProps: {},
    },
    {
      headerText: rtl ? 'المشاركون بالقناة' : 'Channelled Users',
      unitsArray: channelledUsers || [],
      modelUnitProps: {},
      NavigatorCardsModelProps: {},
    },
    {
      headerText: rtl ? 'إهتمامتنا' : 'Our Interests',
      unitsArray: interesets || [],
      modelUnitProps: {
        buttonsArray: [
          {
            title: rtl ? 'حذف' : 'Remove',
            funs: async (el) => {
              if (
                (el?.userId && el?.userId === user?._id) ||
                (el?.projectId &&
                  user?.createdProjects?.includes(el?.projectId))
              ) {
                setIntersets((ints) => ints?.filter((i) => i?.id !== el?.id))
                // for every element only the owner can remove it
                try {
                  const res = await axios.patch(`/channels/${channel?._id}`, {
                    elements: channel?.elements?.filter(
                      (chel) => chel?.id !== el?.id
                    ),
                  })
                  await axios.delete(
                    `/contractsElements/${channel?._id}/${el?.id}`,
                    {
                      elements: channel?.elements?.filter(
                        (chel) => chel?.id !== el?.id
                      ),
                    }
                  )
                  if (typeof setCards === 'function') {
                    setCards((chs) => [
                      ...chs?.filter((ch) => ch?._id !== channel?._id),
                      res.data.channel,
                    ])
                  }
                } catch (error) {
                  console.log(error)
                }
                activeFun({
                  associativity: 'activeContract',
                  relation: 'contributor',
                  conditionIndex: 3,
                })
              } else {
                setIsAlertDialogOpen(true)
              }
            },
          },
        ],
      },
      NavigatorCardsModelProps: {},
    },
  ]
  return (
    <>
      <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
      <AlertMessg
        alertText={
          rtl
            ? 'يمكن فقط لأصحاب العناصر بإزالتها'
            : 'Only owners can remove their elements'
        }
        setIsAlertDialogOpen={setIsAlertDialogOpen}
        isAlertDialogOpen={isAlertDialogOpen}
        {...DCGProps}
      />
    </>
  )
}

export default ChannelNavigators
