const MonthsArray = () => {
  return [
    {
      month: 'Jan',
      subTitleNo: 0,
      ar_month: 'يناير',
    },
    {
      month: 'Feb',
      subTitleNo: 0,
      ar_month: 'فبراير',
    },
    {
      month: 'March',
      subTitleNo: 0,
      ar_month: 'مارس',
    },
    {
      month: 'Apr',
      subTitleNo: 0,
      ar_month: 'ابريل',
    },
    {
      month: 'May',
      subTitleNo: 0,
      ar_month: 'مايو',
    },
    {
      month: 'June',
      subTitleNo: 0,
      ar_month: 'يونيو',
    },
    {
      month: 'July',
      subTitleNo: 0,
      ar_month: 'يوليو',
    },
    {
      month: 'Aug',
      subTitleNo: 0,
      ar_month: 'أغسطس',
    },
    {
      month: 'Sep',
      subTitleNo: 0,
      ar_month: 'سبتمبر',
    },
    {
      month: 'Oct',
      subTitleNo: 0,
      ar_month: 'أكتوبر',
    },
    {
      month: 'Nov',
      subTitleNo: 0,
      ar_month: 'نوفمر',
    },
    {
      month: 'Dec',
      subTitleNo: 0,
      ar_month: 'ديسمبر',
    },
  ]
}

export default MonthsArray
