import React, { useState, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
const styles = () => ({
  wrapper: {
    display: 'flex',
    fontSize: 15,
    filter: 'brightness(70%)',
    color: 'white',
    fontWeight: 400,
    paddingTop: 13,
    paddingBottom: 5,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    flexDirection: 'column',
  },
  radioWrapper: {
    margin: 5,
  },
})

const RadioButtonsModel = ({
  classes,
  element,
  optionsSetState,
  optionsState,
}) => {
  const { inputName, title, value, radioOptions, disabled } = element
  const stateName = title
  const defaultOption = value

  // radio options
  const [chosenValueId, setChosenValueId] = useState(
    defaultOption ? defaultOption : 0
  )

  useEffect(() => {
    optionsSetState({
      ...optionsState,
      [stateName.split(' ').join('')]: radioOptions[chosenValueId],
    })
  }, [chosenValueId])

  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    setWindowSize(window.innerWidth)
  }, [window.innerWidth])

  return (
    <article
      className={classes.wrapper}
      // style={{ flexDirection: windowSize <= 600 ? 'column' : 'row' }}
    >
      {radioOptions.map((button, buttonIndex) => (
        <section key={buttonIndex} className={classes.radioWrapper}>
          <input
            type='radio'
            name={inputName}
            id={buttonIndex}
            defaultChecked={buttonIndex === chosenValueId}
            onClick={() => setChosenValueId(buttonIndex)}
            disabled={disabled}
          />
          <span>{button}</span>
        </section>
      ))}
    </article>
  )
}

const WrappedRadioButtonsModel = withStyles(styles)(RadioButtonsModel)

export default WrappedRadioButtonsModel
