import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { NotificationViewModel } from '../../..'
import DepartmentsEnumArray from '../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/DepartmentsEnumArray'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const ProjectDemotion = (props) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { project, sender, notification } = props
  NotificationAutoInterUpdator(props)
  const department = DepartmentsEnumArray.find(
    (dep) => dep.id === Number(notification?.accompaniedData?.departmentId)
  )?.title
  const { t } = useTranslation()
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <section>
          <Link
            to={`/profile/${sender?._id}`}
            style={{ fontWeight: 500, marginRight: 4, marginLeft: 4 }}
          >
            {sender?.name}
          </Link>{' '}
          {rtl
            ? `قام بإزالة ترقيتك كمديراَ لقسم ${t(department)} فى`
            : `has demoted you as manager of ${department} department in`}
          <Link
            to={`/projectProfile/${project?._id}`}
            style={{ fontWeight: 500, marginRight: 4, marginLeft: 4 }}
          >
            {project?.name}
          </Link>
        </section>
      }
      buttonsArray={[]}
    />
  )
}

export default ProjectDemotion
