import React, { useState } from 'react'
import FormsComponents from '../formsSystem/FormsComponents'
import withStyles from '@mui/styles/withStyles'
import SearchIcon from '@mui/icons-material/Search'
import MiniFileSystem from '../fileSystem/MiniFileSystem'
import SearchIdtentifiersExtractor from '../searchSystem/SearchIdtentifiersExtractor'
import ECWFreeCardModel from './EditableCardsWindowComps/ECWFreeCardModel'
import { GrAdd } from 'react-icons/gr'
import NewFormDialogModel from '../formsSystem/NewFormDialogModel'
import StateGenerator from '../smallComponents/StateGenerator'
import { Fab } from '@mui/material'

const styles = () => ({
  searchBar: {
    width: '100%',
    height: 30,
    backgroundColor: 'white',
    borderRadius: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    opacity: 0.35,
    '&:hover': {
      opacity: 0.8,
    },
    alignContent: 'center',
  },
  searchIcon: {
    fontSize: '20px !important',
    margin: '2px 10px -2px 10px',
    cursor: 'pointer',
  },
  fabButton: {
    position: 'absolute',
    zIndex: '999',
    float: 'right',
    margin: '10px',
    right: 10,
    bottom: 0,
  },
  searchInput: {
    border: 'none',
    width: '75%',
    '&:focus': {
      outline: 'none',
      opacity: 0.8,
    },
    '&::placeholder': {
      color: 'rgb(26, 23, 23)',
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      fontWeight: 200,
      fontSize: 13.2,
    },
  },
})

const EditableCardsWindow = ({
  classes,
  chosenCards,
  setChosenCards,
  DCGProps,
  cards,
  placeholder,
  SearchIDFExtConfigs,
  enableSearching,
  StandardCardContent,
  newFormDialogTitle,
  NewElementFormComponent,
  wrpperStyle,
  cardElementTitleProp,
  profileProps,
  elementsImages,
  NewElementFormComponentProps,
  modelProps,
  placeholderValue,
  project,
  elementKey,
  showAddFab,
}) => {
  const { isDarkModeActive } = DCGProps
  const [searchQuery, setSearchQuery] = useState('')
  const formsCompsProps = {
    formComponentIndex: 2,
    textContent: searchQuery,
    setTextContent: setSearchQuery,
    searchInputClass: classes.searchInput,
    placeholder,
    setSearchQuery,
    project,
    ...DCGProps,
  }

  const MFSGProps = {
    FreeCardModel: ECWFreeCardModel,
    cards,
    MiniFSModelIndex: 5,
    ...DCGProps,
    ...profileProps
  }

  const modelPropsII = {
    setChosenCards,
    chosenCards,
    StandardCardContent,
    cardElementTitleProp,
    profileProps,
    elementsImages,
    ...modelProps,
    elementKey,
  }
  const searchSystemProps = {
    placeholderValue,
    alphabeticOrderDir: true,
    searchQuery,
    FirstViewSearch: true,
    firstLetterSearch: true,
    firstViewSearchIdtentifiers:
      SearchIdtentifiersExtractor(SearchIDFExtConfigs),
  }
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({ GeneralProps: true })
  return (
    <section style={{ position: 'relative' }}>
      <section style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={classes.searchBar}
          style={{
            display: enableSearching || cards.length > 10 ? null : 'none',
          }}
        >
          <SearchIcon className={classes.searchIcon} />
          <FormsComponents formsCompsProps={formsCompsProps} />
        </div>
      </section>
      <article style={wrpperStyle}>
        <MiniFileSystem
          MFSGProps={MFSGProps}
          modelProps={modelPropsII}
          searchSystemProps={searchSystemProps}
          folderPathProps={{}}
          noFSPanelPropsSecondView={{ hideNoFileSystemPanel: true }}
          noFSPanelPropsFirstView={{ hideNoFileSystemPanel: true }}
        />
        <NewFormDialogModel
          NewFormComponent={
            NewElementFormComponent && (
              <NewElementFormComponent
                GeneralDialogClose={GeneralDialogClose}
                {...NewElementFormComponentProps}
                {...DCGProps}
                DCGProps={DCGProps}
              />
            )
          }
          newFormDialogClose={GeneralDialogClose}
          isNewFormDialogOpen={isGeneralDialogOpen}
          {...DCGProps}
          dialogTitle={newFormDialogTitle}
        />
        <span
          className={classes.fabButton}
          style={{ display: showAddFab ? 'flex' : 'none' }}
        >
          <Fab
            aria-label='add'
            className={classes.fab}
            onClick={GeneralDialogOpener}
            sx={{
              filter: isDarkModeActive ? 'grayScale()' : null,
              display: NewElementFormComponent ? 'flex' : 'none',
            }}
          >
            <GrAdd size={23} style={{ cursor: 'pointer' }} />
          </Fab>
        </span>
      </article>
    </section>
  )
}

const WrappedEditableCardsWindow = withStyles(styles)(EditableCardsWindow)

export default WrappedEditableCardsWindow
