import React, { useContext, useState } from 'react'
import EditableGridModel from '../../../../formsSystem/EditableGridModel'
import axios from 'axios'
import HashTagElementsRefiner from '../../../../smallComponents/HashTagElementsRefiner'
import { useTranslation } from 'react-i18next'
import FormDataLangModulator from '../../../../formsSystem/FormDataLangModulator'
import DepartmentsEnumArray from '../ProjectDepartmentsComponents/DepartmentsComponents/DepartmentsEnumArray'
import moment from 'moment'
import { AuthContext } from '../../../../../context/AuthContext'
import i18next from 'i18next'

const MemberBsicInfo = ({
  member,
  showMemberDepartment,
  DCGProps,
  authorized,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const { project } = DCGProps
  const { user } = useContext(AuthContext)
  const department = DepartmentsEnumArray?.find(
    (d) => d?.id === Number(member?.departmentId)
  )?.title
  const dep = showMemberDepartment
    ? [
        {
          title: 'Department',
          titleValue: department
            ? t(department)
            : rtl
            ? 'لم يلتحق بأى قسم'
            : 'Not Assigned to Any Department',
          unEditable: true,
        },
      ]
    : []
  const details = [
    {
      title: 'Rule_Title',
      titleValue: member?.RuleTitle,
      allowEmpty: true,
    },
    {
      title: 'Rule_Description',
      titleValue: member?.RuleDescription,
      allowEmpty: true,
    },
    {
      title: 'Membership_State',
      titleValue: t(member?.MembershipState),
      unEditable: true,
    },
    {
      title: 'Admission_Date',
      titleValue: moment(member?.createdAt).format('MMMM Do YYYY'),
      unEditable: true,
    },
  ].concat(dep)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(member) || []
  )

  const [infoData, setInfoData] = useState({
    RuleTitle: member?.RuleTitle,
    RuleDescription: member?.RuleDescription,
  })
  const newInfoData = FormDataLangModulator(infoData, t)

  const onSave = async () => {
    try {
      await axios.patch(
        `/projectContributors/${member?.userId}/${project?._id}`,
        {
          ...newInfoData,
          chosenHashTagElements,
        }
      )
      // i need to update the member in the current state of the members
      // setMembers..etc
    } catch (error) {
      console.log(error)
    }
  }

  const gridProps = {
    hideEditingTools: !authorized,
    headerText: rtl ? 'البيانات الأساسية' : 'Basic Info',
    infoArray: details,
    setInfoData,
    infoData,
    setChosenHashTagElements,
    chosenHashTagElements,
    onSave,
    ...DCGProps,
  }
  return <EditableGridModel gridProps={gridProps} />
}

export default MemberBsicInfo
