import i18next from "i18next"

const CampaignConfigs = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
      placeholder: rtl
        ? 'اختر عنواناً لحملتك'
        : 'Choose title for your campaign',
    },
    {
      title: 'Campaign_Start_Date',
      formComponentType: 'date',
      value: '',
    },
    {
      title: 'Campaign_End_Date',
      formComponentType: 'date',
      value: '',
    },
    {
      title: 'Campaign_Goal',
      formComponentType: 'text',
      value: '',
      placeholder: rtl
        ? 'ضع أهدافًا واضحة لحملتك'
        : 'Set clear goals for your campaign',
      helpertext: rtl
        ? 'أهداف الحملة ومتابعتها: ما الذي نحاول تحقيقه من خلال حملتنا وكيف سنعرف متى نحققها؟'
        : 'Campaign goals and tracking: What are we trying to achieve through our campaign and how will we know when we achieve it?',
      multiLine: true,
      minRows: 5,
    },
    {
      title: 'Campaign_Target_Audience',
      formComponentType: 'text',
      value: '',
      placeholder: rtl
        ? 'حدد الجماهير المستهدفة لحملتك'
        : 'Define target audiences for your campaign',
      helpertext: rtl
        ? 'الجمهور المستهدف ورؤية العملاء والاستهداف: من الذي نحاول الوصول إليه والتأثير فيه؟'
        : 'Target audience, customer insight and targeting: Who are we trying to reach and influence?',
      multiLine: true,
      minRows: 5,
    },
    {
      title: 'Campaign_Message',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'تطوير رسالة واضحة' : 'Develop a clear message',
      helpertext: rtl
        ? 'الرسائل والعروض الرئيسية للحملة: كيف نحاول وضع شركتنا ومنتجاتنا وخدماتنا؟ ما الحملة أو عروض المنتجات التي ستجذب جمهورنا وتحولهم؟'
        : 'Key campaign messages and offers: How are we trying to position our company, products, and services? What campaign or product offers will engage and convert our audience?',
      multiLine: true,
      minRows: 5,
    },
    {
      title: 'Campaign_Channels',
      formComponentType: 'text',
      value: '',
      placeholder: rtl
        ? 'راجع وحدد أنواع الوسائط المناسبة لحملتك'
        : 'Review and select the right types of media for your campaign',
      helpertext: rtl
        ? 'الخطة الإعلامية والميزانية للحملة: ما هي القنوات الإعلامية التي ستستخدمها للوصول إلى جمهورك المستهدف والتأثير فيه؟ ماذا سيكون تسلسل وتكامل الأنشطة الإعلامية؟'
        : 'Campaign media plan and budget: What media channels will you use to reach and influence your target audience? What will be the sequence and integration of media activities?',
      multiLine: true,
      minRows: 5,
    },
    {
      title: 'Campaign_Execution',
      formComponentType: 'text',
      value: '',
      placeholder: rtl
        ? 'ما يجب اختباره قبل بدء الحملة'
        : 'What needs to be tested before the campaign is live',
      helpertext: rtl
        ? 'تنفيذ الحملة: ما الذي يجب اختباره قبل بدء الحملة وتعديلها أثناء الحملة؟ يرتبط النموذج أيضًا بنصائح أكثر تفصيلاً في أدلةنا الأخرى حول مجالات مثل الاستهداف وتتبع الحملة من خلال التحليلات.'
        : 'Campaign execution: What needs to be tested before the campaign is live and adjusted during the campaign? The template also links to more detailed advice in our other guides on areas like targeting and campaign tracking through analytics.',
      multiLine: true,
      minRows: 5,
    },
  ]
}

export default CampaignConfigs
