import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import ButtonsSystem from '../buttonsSystem/ButtonsSystem'
import NewFormDialogModel from '../formsSystem/NewFormDialogModel'
import UserPortfolioContent from '../profile/ProfileComponents/ProfileTopComponents/ProfileOverviewComponents/UserPortfolioComps/UserPortfolioContent'
import TextEngine from '../textProcessingEngine/TextEngine'
import NotificationCreator from './NotificationCreator'
import WindowProps from '../windowViewer/WindowProps'
import WindowViewerSystem from '../windowViewer/WindowViewerSystem'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const FullInteractiveRequestDetailsModel = ({
  RequestText,
  NotificationCreatorRefuseProps,
  NotificationCreatorAcceptProps,
  contributorProps,
  DCGProps,
  setRequests,
  card,
  requestingUser,
  onAccept,
  disableAccept,
}) => {
  const refuseProps = NotificationCreatorRefuseProps
  const acceptProps = NotificationCreatorAcceptProps
  const { user } = useContext(AuthContext)
  const { socket } = DCGProps
  const [project, setProject] = useState(DCGProps?.project)
  const request = card
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isLoading, setIsLoading] = useState()
  const {
    isWindowOpen: isWindowOpenII,
    windowOpener: windowOpenerII,
    windowCloser: windowCloserII,
  } = WindowProps()
  const [reqUserProf, setReqUserProf] = useState()
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${request?.userId}`)
        setReqUserProf(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    const fetchProject = async () => {
      try {
        const res = await axios.get(
          `/projects/oneProject/${contributorProps?.projectId}`
        )
        setProject(res.data.project)
      } catch (error) {
        console.log(error)
      }
    }
    if (request?.userId) fetchUser()
    if (!project && contributorProps?.projectId) fetchProject()
  }, [request?.userId, contributorProps?.projectId])
  const [contract, setContract] = useState()
  const [repsondedTo, setRespondedTo] = useState(false)
  const alreadyInProject =
    reqUserProf?.contractedProjectsIds?.includes(
      project?._id || contributorProps?.projectId
    ) || project?.userId === request?.userId

  const contractTitle = rtl
    ? `عقد ${project?.name || project?.ProjectName} - ${
        requestingUser?.name ||
        reqUserProf?.firstName + ' ' + reqUserProf?.lastName
      }`
    : `${project?.name || project?.ProjectName} - ${
        requestingUser?.name ||
        reqUserProf?.firstName + ' ' + reqUserProf?.lastName
      } Contract`

  const activeFun = async ({ response }) => {
    try {
      // if (typeof setRequests === 'function')
      //   setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
      setIsLoading(true)
      setRespondedTo(true)
      socket.current.emit('addNotifUser', request?.userId)
      if (response === 'contract') {
        if (typeof onAccept === 'function') onAccept()
        // i will test if the user is already in the project
        if (alreadyInProject) {
          const contribProf = await axios.get(
            `/projectContributors/${request?.userId}/${
              project?._id || contributorProps?.projectId
            }`
          )
          if (contribProf?.data?.contributor?.contractId) {
            const contribContract = await axios.get(
              `/contracts/${contribProf?.data?.contributor?.contractId}`
            )
            setContract(contribContract?.data?.contract)
            windowOpenerII()
            await axios.patch(
              `/projectContributors/${request?.userId}/${
                project?._id || contributorProps?.projectId
              }`,
              contributorProps
            )
            if (contribContract?.data?.contract)
              NotificationCreator({
                ...acceptProps,
                accompaniedData: {
                  response,
                  projectId: project?._id || contributorProps?.projectId,
                  elements: [
                    {
                      hashTagType: contribContract?.data?.contract?.hashTagType,
                      id: contribContract?.data?.contract?._id,
                      title: contribContract?.data?.contract?.Title,
                    },
                    ...acceptProps?.elements,
                  ],
                },
                userId: request?.userId,
                currentUser: user,
                socket,
              })
          }
        } else {
          // for the new contributor
          const resContract = await axios.post(`/contracts`, {
            projectsIds: project?._id || contributorProps?.projectId,
            usersIds: request?.userId,
            Title: contractTitle,
          })
          setContract(resContract?.data?.contract)
          windowOpenerII()
          const resCont = await axios.post(`/projectContributors`, {
            projectId: project?._id || contributorProps?.projectId,
            userId: request?.userId,
            contractId: resContract?.data?.contract?._id,
            ...contributorProps,
          })
          // notifying the user that he has been added as an investor in the project -- i will create another notif here
          if (resCont?.data?.contributor)
            NotificationCreator({
              associativity: 'contribution',
              relation: 'projectMemberCandidate',
              conditionIndex: 1,
              accompaniedData: {
                projectId: project?._id || contributorProps?.projectId,
                MembershipState: resCont?.data?.contributor?.MembershipState,
              },
              category: 'contributors',
              userId: request?.userId,
              currentUser: user,
              socket,
            })

          if (resContract?.data?.contract)
            NotificationCreator({
              ...acceptProps,
              accompaniedData: {
                projectId: project?._id || contributorProps?.projectId,
                response,
                elements: [
                  {
                    hashTagType: resContract?.data?.contract?.hashTagType,
                    id: resContract?.data?.contract?._id,
                    title: resContract?.data?.contract?.Title,
                  },
                  ...acceptProps?.elements,
                ],
              },
              userId: request?.userId,
              currentUser: user,
              socket,
            })
          //   adding the project to the contracted project of the user
          if (
            !reqUserProf?.contractedProjectsIds.includes(
              project?._id || contributorProps?.projectId
            )
          ) {
            await axios.patch(`/users/${request?.userId}`, {
              userId: request?.userId,
              contractedProjectsIds: [
                ...reqUserProf?.contractedProjectsIds,
                project?._id || contributorProps?.projectId,
              ],
            })
          }
        }
      }
      if (response === 'refuse') {
        NotificationCreator({
          ...refuseProps,
          accompaniedData: {
            projectId: project?._id || contributorProps?.projectId,
            response,
            elements: [...refuseProps?.elements],
          },
          userId: request?.userId,
          currentUser: user,
          socket,
        })
      }
      // request will be deleted after being responded to
      await axios.delete(`/requests/${request?._id}`)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const deleteFun = async () => {
    try {
      await axios.delete(`/requests/${request?._id}`)
      setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
    } catch (error) {
      console.log(error)
    }
  }
  const buttonsArray = [
    {
      title: isLoading
        ? alreadyInProject
          ? rtl
            ? 'جارى فتح العقد...'
            : 'Openning Contract...'
          : rtl
          ? 'جارى إنشاء عقد...'
          : 'Generating Contract...'
        : rtl
        ? 'تعاقد'
        : 'Contract',
      funs: () => {
        activeFun({ response: 'contract' })
        if (!alreadyInProject)
          toast.success(
            rtl
              ? 'تم انشاء العقد بنجاح'
              : 'Contract has been created successfully'
          )
      },
      disabled: isLoading || repsondedTo || disableAccept,
    },
    {
      title: rtl ? 'رفض' : 'Refuse',
      funs: () => {
        activeFun({ response: 'refuse' })
        toast.info(rtl ? 'تم ارسال الرفض' : 'Refusal has been sent')
      },
      disabled: isLoading || repsondedTo,
    },
    {
      title: rtl ? 'حذف' : 'Delete',
      funs: () => {
        deleteFun()
        toast.warning(
          rtl ? 'تم مسح الطلب بنجاح' : 'Request has been deleted successfully'
        )
      },
      disabled: isLoading || repsondedTo,
    },
    {
      title: rtl ? 'الملف الشخصى' : 'Portfolio',
      funs: () => windowOpener(),
      disabled: isLoading,
    },
  ]
  return (
    <>
      {RequestText}
      <TextEngine
        textContent={request?.requestData?.content || ''}
        textPackage={request?.requestData}
        charLimit={50}
      />
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
      <NewFormDialogModel
        NewFormComponent={
          <UserPortfolioContent user={requestingUser} {...DCGProps} />
        }
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        {...DCGProps}
        clickAwayState={true}
        dialogTitle={
          rtl
            ? `الملف الشخصى لـ ${requestingUser?.firstName}`
            : `${requestingUser?.firstName}'s Portfolio`
        }
      />
      {contract && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpenII}
          windowCloser={windowCloserII}
          {...DCGProps}
          props={{ contract, DCGProps }}
          windowType='Contract'
        />
      )}
    </>
  )
}

export default FullInteractiveRequestDetailsModel
