import React from 'react'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import { Link } from 'react-router-dom'
import { NotificationViewModel } from '../../../..'
import i18next from 'i18next'

const RemoveFromBoardNotif = (props) => {
  const { project } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  NotificationAutoInterUpdator(props)
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        rtl ? (
          <>
            لقد تم حذفك من قائمة أعضاء مجلس الادارة بـ{' '}
            <Link
              to={`/projectProfile/${project?._id}`}
              style={{ fontWeight: 500, margin: 'auto 4px' }}
            >
              {project?.name}
            </Link>
          </>
        ) : (
          <>
            You have been removed from the board members of{' '}
            <Link
              to={`/projectProfile/${project?._id}`}
              style={{ fontWeight: 500, margin: 'auto 4px' }}
            >
              {project?.name}
            </Link>
          </>
        )
      }
      buttonsArray={[]}
    />
  )
}

export default RemoveFromBoardNotif
