import React from 'react'
import CampaignChanges from './JobNotificationDisplayModelComps/CampaignChanges'
import CampaignNewRequest from './JobNotificationDisplayModelComps/CampaignNewRequest'
import CampaignRequestReply from './JobNotificationDisplayModelComps/CampaignRequestReply'
import CampaignRemoveContributorNotif from './JobNotificationDisplayModelComps/CampaignRemoveContributorNotif'

const CampaignDisplayModels = ({ notifProps }) => {
  const { isDarkModeActive, notification } = notifProps
  const campaign = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'campaign'
  )
  const notifPropsII = {
    ...notifProps,
    campaign,
  }
  const mssgs = [
    {
      relation: 'creator',
      comp: [<CampaignNewRequest {...notifPropsII} />],
    },
    {
      relation: 'visitor',
      comp: [<CampaignRequestReply {...notifPropsII} />],
    },
    {
      relation: 'contributor',
      comp: [
        <CampaignChanges {...notifPropsII} />,
        <CampaignRemoveContributorNotif {...notifProps} />,
      ],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default CampaignDisplayModels
