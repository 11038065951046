import React, { useContext } from 'react'
import ProductServicesRequests from './ProductServiceProfileComponents/ProductServicesRequests'
import ProductServiceDetails from './ProductServiceProfileComponents/ProductServiceDetails'
import ProductSerProfileImage from './ProductServiceProfileComponents/ProductSerProfileImage'
import ProductTechnicals from './ProductServiceProfileComponents/ProductTechnicals'
import ProductInteractionButtons from './ProductServiceProfileComponents/ProductInteractionButtons'
import ProductNavigators from './ProductServiceProfileComponents/ProductNavigators'
import { AuthContext } from '../../../../../../../context/AuthContext'

const ProductServiceProfile = (props) => {
  const { DCGProps, product } = props
  const { user } = useContext(AuthContext)
  const { isDarkModeActive } = DCGProps
  return (
    <article
      style={{
        width: '100%',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <ProductSerProfileImage {...props} />
      <ProductNavigators {...props} />
      <ProductServiceDetails {...props} />
      {(user?.createdProjects?.includes(product?.projectId) ||
        user?.contractedProjectsIds?.includes(product?.projectId)) && (
        <ProductTechnicals {...props} />
      )}
      {(product?.ProductDesignerId === user?._id ||
        user?.createdProjects?.includes(product?.projectId)) && (
        <ProductServicesRequests {...props} />
      )}
      <ProductInteractionButtons {...props} />
    </article>
  )
}

export default ProductServiceProfile
