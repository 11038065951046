import React, { useState, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import AccessCardGenerator from './NotificationBodyWithAccessCardComps/AccessCardGenerator'
import WindowSize from '../../../../smallComponents/WindowSize'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
})

const NotificationBodyWithAccessCard = ({
  classes,
  notificationBodyContent,
  isDarkModeActive,
  notification,
  project,
  socket,
  setClickAwayState,
}) => {
  const windowSize = WindowSize()
  const accessCards = notification?.accompaniedData?.elements?.map(
    (card, i) => (
      <section style={{ margin: 10 }} key={i}>
        <AccessCardGenerator
          element={card}
          isDarkModeActive={isDarkModeActive}
          socket={socket}
          modelProps={{
            project,
            departmentId: notification?.accompaniedData?.departmentId,
            subDepId: notification?.accompaniedData?.subDepId,
            setClickAwayState,
          }}
        />
      </section>
    )
  )
  return (
    <article
      className={classes.wrapper}
      style={{
        flexDirection:
          windowSize <= 600 || accessCards?.length > 1 ? 'column' : 'row',
        alignItems: windowSize <= 600 ? 'center' : null,
      }}
    >
      <section>
        <div style={{ fontStyle: 'oblique', padding: 10 }}>
          {notificationBodyContent}
        </div>
      </section>
      <section>
        {accessCards?.length > 1 ? (
          <section
            style={{
              display: 'flex',
              flexDirection: windowSize <= 600 ? 'column' : 'row',
              flexFlow: 'row wrap',
              width: '100%',
              justifyContent: 'space-around',
              alignContent: 'center',
            }}
          >
            {accessCards}
          </section>
        ) : (
          accessCards
        )}
      </section>
    </article>
  )
}

const WrappedNotificationBodyWithAccessCard = withStyles(styles)(
  NotificationBodyWithAccessCard
)

export default WrappedNotificationBodyWithAccessCard
