import React, { useContext, useState } from 'react'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@mui/material'
import axios from 'axios'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import RequestCreator from '../../../../../../../notificationsSystem/RequestCreator'
import { toast } from 'react-toastify'
import ToastifyGeneralError from '../../../../../../../smallComponents/ToastifyGeneralError'
import i18next from 'i18next'

const styles = () => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    color: 'white !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const MoveToAnotherDepFormButtons = ({
  componentClose,
  classes,
  departmentId,
  member,
  DCGProps,
  setMembers,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { project, socket } = DCGProps
  const { user } = useContext(AuthContext)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isRemoved, setIsRemoved] = useState(false)
  return (
    <section className={classes.buttonsWrapper}>
      <Button
        variant='contained'
        className={classes.button}
        disabled={isConfirmed || Number(member?.departmentId) === departmentId}
        onClick={async () => {
          setIsConfirmed(true)
          try {
            if (user?._id === project?.userId) {
              const res = await axios.patch(
                `/projectContributors/${member?.userId}/${project?._id}`,
                { departmentId }
              )
              setMembers((ms) => [
                ...ms?.filter((m) => m?.userId !== member?.userId),
                res.data.contributor,
              ])
              socket.current.emit('addNotifUser', member?.userId)
              NotificationCreator({
                associativity: 'project',
                relation: 'member',
                conditionIndex: 4,
                accompaniedData: { projectId: project?._id, departmentId },
                category: 'contributors',
                userId: member?.userId,
                currentUser: user,
                socket,
              })
              toast.success(
                rtl
                  ? 'لقد تم نقل العضو الى القسم الجديد بنجاح برجاء اعادة الدخول للقسم لملاحظة التغييرات'
                  : 'Contributor has been moved successfully. Please reopen the department to notice the changes'
              )
            }
            if (user?._id !== project?.userId) {
              RequestCreator({
                currentUser: user,
                projectId: project?._id,
                genre: 'interdepartment_Transfer',
                subgenre: 'ceo_tools',
                requestData: {
                  contributorProfileId: member?.userId,
                  departmentId,
                },
              })
              toast.success(
                rtl
                  ? 'تم ارسال طلب الى المدير التنفيذى و سيتم تنفيذ العملية اذا وافق على طلبك'
                  : 'A request has been sent to the CEO. The process will be complete if they approved to the request.'
              )
            }
          } catch (error) {
            ToastifyGeneralError(error)
          }
          setIsConfirmed(false)
          componentClose()
        }}
      >
        {isConfirmed
          ? rtl
            ? 'تنشيط النقل بين الاقسام'
            : 'Inter-department Transfer Activation'
          : rtl
          ? 'تأكيد'
          : 'Confirm'}
      </Button>
      <Button
        variant='contained'
        className={classes.button}
        onClick={componentClose}
      >
        {rtl ? 'إلغاء' : 'Cancel'}
      </Button>
      <Button
        variant='contained'
        className={classes.button}
        disabled={isRemoved}
        onClick={async () => {
          setIsRemoved(true)
          if (user?._id === project?.userId) {
            const res = await axios.patch(
              `/projectContributors/${member?.userId}/${project?._id}`,
              { departmentId: null }
            )
            setMembers((ms) => [
              ...ms?.filter((m) => m?.userId !== member?.userId),
              res.data.contributor,
            ])
            NotificationCreator({
              associativity: 'project',
              relation: 'member',
              conditionIndex: 9,
              accompaniedData: { projectId: project?._id },
              category: 'contributors',
              userId: member?.userId,
              currentUser: user,
              socket,
            })
            toast.success(
              rtl
                ? 'لقد تم حذف العضو بنجاح برجاء اعادة الدخول للقسم لملاحظة التغييرات'
                : 'Contributor has been removed successfully. Please reopen the department to notice the changes'
            )
          }
          if (user?._id !== project?.userId) {
            RequestCreator({
              currentUser: user,
              projectId: project?._id,
              genre: 'department_removal',
              subgenre: 'ceo_tools',
              requestData: {
                contributorProfileId: member?.userId,
                departmentId,
              },
            })
            toast.success(
              rtl
                ? 'تم ارسال طلب الى المدير التنفيذى و سيتم تنفيذ العملية اذا وافق على طلبك'
                : 'A request has been sent to the CEO. The process will be complete if they approved to the request.'
            )
          }
          setIsRemoved(false)
          componentClose()
        }}
      >
        {isRemoved
          ? rtl
            ? 'بدء الحذف'
            : 'Removal Intiation'
          : rtl
          ? 'حذف'
          : 'Remove'}
      </Button>
    </section>
  )
}

const WrappedMoveToAnotherDepFormButtons = withStyles(styles)(
  MoveToAnotherDepFormButtons
)

export default WrappedMoveToAnotherDepFormButtons
