import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const NewResourceContractingReqNotifCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl
          ? `لديك طلب جديد للمشاركة فى موردك`
          : `You have new request for sharing in your resource`
      }
    />
  )
}

export default NewResourceContractingReqNotifCover
