import React, { useContext } from 'react'
import CardTitlesModel from '../../../../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCardComps/CardTitlesModel'
import { AuthContext } from '../../../../../../context/AuthContext'
import i18next from 'i18next'

const IssueCardContent = ({ element, isDarkModeActive }) => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <CardTitlesModel
      title={element?.Title || element?.title}
      isDarkModeActive={isDarkModeActive}
      cardMark={
        element?.userId === user?._id
          ? rtl
            ? 'بواسطتى'
            : 'By Me'
          : element?.usersIds?.includes(user?._id)
          ? rtl
            ? 'ضدى'
            : 'Against'
          : null
      }
      cardFooter={''}
    />
  )
}

export default IssueCardContent
