import React from 'react'
import CardTitlesModel from '../../../../../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCardComps/CardTitlesModel'

const ContractCardContent = ({ element, isDarkModeActive }) => {
  const contract = element
  return (
    <CardTitlesModel
      title={contract.title || contract?.Title}
      isDarkModeActive={isDarkModeActive}
    />
  )
}

export default ContractCardContent
