import React from 'react'
import '../skeleton.css'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  bar: {
    width: '100%',
    borderRadius: 20,
    animation: 'skeleton 1s ease infinite alternate',
  },
})

const FreeBar = ({ classes, height, isDarkModeActive, width, margin, }) => {
  return (
    <div
      className={classes.bar}
      style={{
        height,
        backgroundColor: isDarkModeActive ? 'white' : 'black',
        width,
        margin,
      }}
    ></div>
  )
}

const WrappedFreeBar = withStyles(styles)(FreeBar)

export default WrappedFreeBar
