import React, { useState } from 'react'
import {
  BookDialogModel,
  ContributorsNotifsDialogLeft,
  ContributorsNotifsDialogRight,
} from './'
import i18next from 'i18next'

const ContributorsNotifications = (props) => {
  const { isNotificationCardOpen, notificationCardDialogClose, notification } =
    props
  const [chosenNotification, setChosenNotification] = useState(notification)
  const notifProps = {
    chosenNotification,
    setChosenNotification,
    ...props,
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <BookDialogModel
      bookDialogTitlte={
        rtl ? 'إشعارات المساهمين' : "Contributors's Notifications"
      }
      bookDialogClose={notificationCardDialogClose}
      isBookDialogOpen={isNotificationCardOpen}
      bookLeft={<ContributorsNotifsDialogLeft props={notifProps} />}
      bookRight={
        <ContributorsNotifsDialogRight
          notifProps={{
            ...notifProps,
            notification: chosenNotification,
          }}
        />
      }
      {...notifProps}
    />
  )
}

export default ContributorsNotifications
