import React from 'react'
import './skeleton.css'
import ElementsSkeleton from './SkeletonComponents/ElementsSkeleton'
import FeedSkeleton from './SkeletonComponents/FeedSkeleton'
import NotificationsSkeleton from './SkeletonComponents/NotificationsSkeleton'
import ProjectProfileSkeleton from './SkeletonComponents/ProjectProfileSkeleton'
import UserProfileSkeleton from './SkeletonComponents/UserProfileSkeleton'
import NavigatorCardSkeleton from './SkeletonComponents/NavigatorCardSkeleton'
import ProfileCoverSkeleton from './SkeletonComponents/ProfileCoverSkeleton'

export default function Skeleton({ type, projectProps }) {
  const COUNTER = 8

  if (type === 'feed')
    return Array.from({ length: COUNTER }, (_, index) => (
      <FeedSkeleton key={index} index={index} />
    ))
  if (type === 'notificationList')
    return Array.from({ length: COUNTER }, (_, index) => (
      <NotificationsSkeleton key={index} />
    ))
  if (type === 'standardCard')
    return Array.from({ length: COUNTER }, (_, index) => (
      <ElementsSkeleton key={index} />
    ))

  if (type === 'project-profile')
    return <ProjectProfileSkeleton projectProps={projectProps} />
  if (type === 'user-profile') return <UserProfileSkeleton />
  if (type === 'navigator')
    return Array.from({ length: COUNTER }, (_, index) => (
      <NavigatorCardSkeleton key={index} />
    ))
  if (type === 'cover') return <ProfileCoverSkeleton />
}
