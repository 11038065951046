import { useTranslation } from 'react-i18next'

export default function PostCancelButtonConfig({ shareSystemProps }) {
  const { shareDialogClose, disablePostTypeOptions } = shareSystemProps
  const { t } = useTranslation()
  return {
    title: t('Cancel'),
    funs: () => {
      shareDialogClose()
    },
    displayConditions: disablePostTypeOptions ? null : 'none',
    disabled: false,
  }
}
