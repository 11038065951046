import React from 'react'
import NewProjectContributionOfferReplyCover from './ContributionNotificationModelComps/NewProjectContributionOfferReplyCover'
import NewProjectContributionOfferCover from './ContributionNotificationModelComps/NewProjectContributionOfferCover'
import ListItemScreenViewModels from './ListItemScreenViewModels'
import AdditionToNewProjectCover from './ContributionNotificationModelComps/AdditionToNewProjectCover'

const ContributionNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'projectMemberCandidate',
      conditions: [
        <NewProjectContributionOfferCover {...notifProps} />,
        <AdditionToNewProjectCover {...notifProps} />,
      ],
    },
    {
      relation: 'newProjectOwner',
      conditions: [<NewProjectContributionOfferReplyCover {...notifProps} />],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default ContributionNotificationModel
