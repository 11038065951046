import React from 'react'
import { Tooltip, IconButton, Paper } from '@mui/material'
import {
  Person as PersonIcon,
  BusinessCenter as ProjectIcon,
  Share as PartnershipIcon,
  Description as RequestsIcon,
  BugReport as IssuesIcon,
  InsertDriveFile as ContractIcon,
  PostAdd as PostsIcon,
  Extension as InterestsIcon,
  ThumbUp as UpVoteIcon,
  ThumbDown as DownVoteIcon,
} from '@mui/icons-material'
import i18next from 'i18next'

const IconConfig = {
  usersIcon: {
    icon: <PersonIcon />,
    tooltip: { en: 'Contributors', ar: 'المساهمين' },
  },
  projectsIcon: {
    icon: <ProjectIcon />,
    tooltip: { en: 'Contributing projects', ar: 'المشاريع المساهمة' },
  },
  partnershipIcon: {
    icon: <PartnershipIcon />,
    tooltip: { en: 'Partnership channel', ar: 'قناة الشراكة' },
  },
  requestsIcon: {
    icon: <RequestsIcon />,
    tooltip: { en: 'Requests', ar: 'الطلبات المرتبطة' },
  },
  issuesIcon: {
    icon: <IssuesIcon />,
    tooltip: { en: 'Issues', ar: 'المشاكل المرتبطة' },
  },
  contractIcon: {
    icon: <ContractIcon />,
    tooltip: { en: 'Contract', ar: 'العقد المرتبط' },
  },
  postsIcon: {
    icon: <PostsIcon />,
    tooltip: { en: 'Posts', ar: 'المنشورات المرتبطة' },
  },
  interestsIcon: {
    icon: <InterestsIcon />,
    tooltip: { en: 'Interests', ar: `الاهتمامات` },
  },
  upVoteIcon: {
    icon: <UpVoteIcon />,
    tooltip: { en: 'Upvotes', ar: 'التصويتات المؤيدة' },
  },
  downVoteIcon: {
    icon: <DownVoteIcon />,
    tooltip: { en: 'Downvotes', ar: 'التصويتات المعارضة' },
  },
}

function ToolbarPanel({ config }) {
  const lang = i18next.language
  const isRtl = lang === 'ar'
  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 1,
        margin: '0 auto',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        borderRadius: 20,
      }}
    >
      {config.map(({ type, onOpen, count }) => {
        const { icon, tooltip } = IconConfig[type]
        const title = isRtl ? tooltip.ar : tooltip.en
        return (
          <Tooltip key={type} title={title}>
            <IconButton
              onClick={typeof onOpen === 'function' ? onOpen : null}
              sx={{
                color: '#666',
                '&:hover': {
                  color: '#333',
                  transform: 'scale(1.2)',
                  transition: 'transform 0.2s ease-in-out',
                },
                '& .MuiIconButton-label': {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              }}
            >
              <div sx={{ fontSize: 12, fontWeight: 'bold', marginBottom: 1 }}>
                {count}
              </div>
              {icon}
            </IconButton>
          </Tooltip>
        )
      })}
    </Paper>
  )
}

export default ToolbarPanel
