import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
  },
  closeMessage: {
    fontWeight: 500,
    fontSize: 18,
    margin: 10,
  },
  vectorArt: {
    width: 200,
    height: 200,
  },
  // under testing - may cause problems
  '@media (max-width: 600px)': {
    container: {
      height: '100vh',
    },
  },
})

const ClosedView = ({ closeMessage, classes, isDarkModeActive }) => {
  return (
    <div className={classes.container}>
      <article className={classes.wrapper}>
        <img
          src='/assets/various/closed.png'
          alt='closed view'
          className={classes.vectorArt}
        />
        <section
          className={classes.closeMessage}
          style={{ color: isDarkModeActive && 'white' }}
        >
          {closeMessage}
        </section>
      </article>
    </div>
  )
}

const WrappedClosedView = withStyles(styles)(ClosedView)

export default WrappedClosedView
