import React, { useState } from 'react'
import FeedsSystem from '../../../../feedsSystem/FeedsSystem'
import TextPost from '../../../../postSystem/TextPost'
import HomePostBottom from '../../commonPostComponents/HomePostBottom'

const SharedPostBottom = ({ postProps }) => {
  const { isDarkModeActive, sharedPost, socket, setPosts } = postProps
  const [isDarkModeActiveII, setIsDarkModeActiveII] = useState(isDarkModeActive)
  return (
    <HomePostBottom
      postProps={{
        ...postProps,
        setIsDarkModeActiveII,
        sharedPost,
        postBody: (
          // configuration of the shared post
          <FeedsSystem
            feedsSystemProps={{
              // data of the post to be shared
              post: sharedPost,
              isUsedIn: 'shareSystem',
              homeHideRightOptions: true,
              isDarkModeActive: isDarkModeActiveII,
              socket,
              setPosts,
            }}
          />
        ),
        postText: <TextPost {...postProps} />,
      }}
    />
  )
}

export default SharedPostBottom
