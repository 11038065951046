import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { FcIdea } from 'react-icons/fc'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    padding: 10,
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.4) ',
  },
  ideaWrapper: {
    margin: 10,
  },
  contentWrapper: {
    fontSize: 13,
    fontFamily: 'Times New Roman (Headings CS)',
    fontStyle: 'oblique',
  },
})

const LearningSection = ({ classes, content }) => {
  return (
    <article className={classes.wrapper}>
      <section className={classes.ideaWrapper}>
        <FcIdea size={23} />
      </section>
      <section className={classes.contentWrapper}>{content}</section>
    </article>
  )
}

const WrappedLearningSection = withStyles(styles)(LearningSection)

export default WrappedLearningSection
