import React, { useContext, useEffect, useState } from 'react'
import TextEngineToolsFooter from '../../../../../../smallComponents/TextEngineToolsFooter'
import InquiryProfileTop from './InquiryProfileComps/InquiryProfileTop'
import InquiryProfileDetails from './InquiryProfileComps/InquiryProfileDetails'
import InquirPairing from './InquiryProfileComps/InquirPairing'
import InquiryRequests from './InquiryProfileComps/InquiryRequests'
import NavigatorGenerator from '../../../../../../navigatorCardsModel/NavigatorGenerator'
import InquiryInteractionButtons from './InquiryProfileComps/InquiryInteractionButtons'
import axios from 'axios'
import { AuthContext } from '../../../../../../../context/AuthContext'
import i18next from 'i18next'

const InquiryProfile = (props) => {
  const { inquiry, DCGProps, hideOwner } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const { isDarkModeActive } = DCGProps
  const [channels, setChannels] = useState([])
  const [sharedPosts, setSharedPosts] = useState([])
  useEffect(() => {
    const fetchUdeaPosts = async () => {
      try {
        const res = await axios.get(`/inquiries/inquiryPosts/${inquiry?._id}`)
        const resChannels = await axios.get(
          `/channels/elementChannels/${inquiry?._id}`
        )
        setChannels(resChannels.data.channels)
        setSharedPosts(res.data.inquiryPosts)
      } catch (error) {
        console.log(error)
      }
    }
    fetchUdeaPosts()
  }, [inquiry?._id])
  const navigatorsArray = [
    {
      headerText: rtl ? 'منشورات' : 'Published Posts',
      unitsArray: sharedPosts,
      NavigatorCardsModelProps: {},
    },
    {
      headerText: rtl
        ? 'قنوات استخدمت هذا المتطلب'
        : 'Channels used this Inquiry',
      unitsArray: channels,
      NavigatorCardsModelProps: {},
    },
  ]

  return (
    <article
      style={{
        color: isDarkModeActive ? 'white' : null,
        marginTop: 10,
        width: '100%',
      }}
    >
      <InquiryProfileTop {...props} />
      <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
      <InquiryProfileDetails {...props} user={user} />
      <InquirPairing {...props} user={user} />
      <InquiryRequests {...DCGProps} {...props} />
      <InquiryInteractionButtons {...props} />
      {user?._id === inquiry?.userId && (
        <TextEngineToolsFooter {...DCGProps} hideTETFooter={!hideOwner} />
      )}
    </article>
  )
}

export default InquiryProfile
