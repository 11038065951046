import React, { useEffect, useState } from 'react'
import AutoSizeTextArea from './FormsComponentsComponents/AutoSizeTextArea'
import CustomPCChatInput from './FormsComponentsComponents/CustomPCChatInput'
import FullActiveOutlineInput from './FormsComponentsComponents/FullActiveOutlineInput'
import FullActiveTextField from './FormsComponentsComponents/FullActiveTextField'
import FunctioningSearchInput from './FormsSystemComponents/FunctioningSearchInput'
import EditingInputDialog from './FormsComponentsComponents/EditingInputDialog'

const FormsComponents = ({ formsCompsProps }) => {
  const { formComponentIndex, textContent, placeholder } = formsCompsProps

  //  detecting the direction of the editing
  const [cursorDirection, setCursorDirection] = useState(document.body.dir)
  useEffect(() => {
    let ltrChars =
      'A-za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' +
      '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF'
    let rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC'
    let rtlDirCheck = new RegExp('^[^' + ltrChars + ']*[' + rtlChars + ']')
    if (textContent) {
      setCursorDirection(rtlDirCheck.test(textContent) ? 'rtl' : 'ltr')
    } else {
      setCursorDirection(rtlDirCheck.test(placeholder) ? 'rtl' : 'ltr')
    }
  }, [textContent])

  const formsCompsPropsII = {
    ...formsCompsProps,
    cursorDirection,
  }

  const formComponentsArray = [
    <FullActiveTextField formsCompsProps={formsCompsPropsII} />,
    <FullActiveOutlineInput formsCompsProps={formsCompsPropsII} />,
    <FunctioningSearchInput formsCompsProps={formsCompsPropsII} />,
    <CustomPCChatInput formsCompsProps={formsCompsPropsII} />,
    <AutoSizeTextArea formsCompsProps={formsCompsPropsII} />,
    <EditingInputDialog formsCompsProps={formsCompsPropsII} />,
  ]
  return formComponentsArray[formComponentIndex]
}

export default FormsComponents
