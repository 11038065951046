import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const ComplementaryShopsBusinessModel = () => {
  const { t } = useTranslation('ComplementaryShopsBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 52,
    title: t('title_bm52'),
    subTitle: t('subTitle_bm52'),
    main: t('main_bm52'),
    when: t('when_bm52'),
    who: t('who_bm52'),
    what: t('what_bm52'),
    how: t('how_bm52'),
    why: t('why_bm52'),
    examples: rtl ? ['بوش - 1886 - ألمانيا'] : ['Bosch - 1886 - Germany'],
    tags: rtl
      ? [
          'الفوز للجانبين',
          'الإيرادات الثابتة',
          'تكاليف تسويق منخفضة',
          'ولاء العميل',
          'رضا العملاء',
          'عقود الامتياز',
          'مفهوم المول',
          'خطر قليل',
          'رؤية العلامة التجارية',
        ]
      : [
          'Win-win',
          'Fixed Revenues',
          'Low Marketing Costs',
          'Customer Loyalty',
          'Customer Satisfaction',
          'Franchising Contracts',
          'Mall Concept',
          'Low Risk',
          'Brand Visibility',
        ],
    considerations: t('considerations_bm52'),
    combinations: ['$LBM28', '$LBM39', '$LBM24', '$LBM2', '$LBM23', '$LBM53'],
    hashTagType: 'businessModelTemp',
  }
}

export default ComplementaryShopsBusinessModel
