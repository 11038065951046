import React from 'react'
import { Grid } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import ExpandedUnitTopCardButtons from './ExpandedUnitTopCardComponents/ExpandedUnitTopCardButtons'
import ExpandedUnitTopCardLeft from './ExpandedUnitTopCardComponents/ExpandedUnitTopCardLeft'

const styles = () => ({
  unitWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },

  '@media (max-width: 600px)': {
    unitWrapper: {
      margin: '5px 0 5px 0',
    },
  },
})

const ExpandedUnitTopCard = ({ classes, ...props }) => {
  const { DCGProps } = props
  const { isDarkModeActive } = DCGProps
  return (
    <Grid container>
      <span
        className={classes.unitWrapper}
        sx={{
          backgroundColor: isDarkModeActive
            ? 'rgba(255, 255, 255, 0.192)'
            : 'inherit',
          color: isDarkModeActive ? 'white' : 'inherit',
        }}
      >
        <ExpandedUnitTopCardLeft {...props} />
        <ExpandedUnitTopCardButtons {...props} />
      </span>
    </Grid>
  )
}

const WrappedExpandedUnitTopCard = withStyles(styles)(ExpandedUnitTopCard)

export default WrappedExpandedUnitTopCard
