import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar } from '@mui/material'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import axios from 'axios'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const styles = () => ({
  listUnitWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  avatarNameCategory: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
  avatar: {
    cursor: 'pointer',
    width: '50px !important',
    height: '50px !important',
  },
  companyName: {
    fontSize: 18,
    fontWeight: 500,
    cursor: 'pointer',
  },
})

const PermissionedUserUnit = ({
  classes,
  perUser,
  setPermissionedUsers,
  idea,
  DCGProps,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'إزالة الإذن' : 'Remove Permission',
      funs: async () => {
        try {
          await axios.patch(`/ideas/removePermission/${idea?._id}`, {
            perUserId: perUser?._id,
          })
          setPermissionedUsers((us) =>
            us.filter((u) => u?._id !== perUser?._id)
          )
        } catch (error) {
          console.log(error)
        }
        toast.success(
          rtl
            ? 'تم إزالة الإذن بنجاح'
            : 'Permission has been removed successfully'
        )
      },
    },
  ]

  return (
    <article className={classes.listUnitWrapper}>
      <section className={classes.avatarNameCategory}>
        <Avatar
          src={perUser.profilePicture}
          className={classes.avatar}
          sx={{ margin: rtl ? '10px 0px 10px 10px' : '10px 10px 10px 0' }}
        />
        <span className={classes.companyName}>
          {perUser.firstName + ' ' + perUser.lastName}
        </span>
      </section>
      <section>
        <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
      </section>
    </article>
  )
}

const WrappedPermissionedUserUnit = withStyles(styles)(PermissionedUserUnit)

export default WrappedPermissionedUserUnit
