import React from 'react'
import CentralTitle from '../smallComponents/CentralTitle'
import i18next from 'i18next'

const NoPermissionedInteraction = ({ checking }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const title = checking
    ? rtl
      ? 'جارى المراجعة'
      : 'Checking'
    : rtl
    ? 'لقد قمت بالتفاعل مسبقا'
    : 'You have already interacted'
  return <CentralTitle title={title} />
}

export default NoPermissionedInteraction
