import React from 'react'
import GeneralPDFDocument from './GeneralPDFDocument'
import ProductPDFBody from './ProductPDFDocumentComps/ProductPDFBody'

const ProductPDFDocument = (props) => {
  return (
    <GeneralPDFDocument pages={[<ProductPDFBody {...props} />]} {...props} />
  )
}

export default ProductPDFDocument
