import axios from 'axios'
import React, { useState } from 'react'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import HashTagElementsRefiner from '../../../../../../smallComponents/HashTagElementsRefiner'
import i18next from 'i18next'

const FinStatProfile = ({ finStat, DCGProps, setCards }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const finStatArray = [
    {
      title: 'Title',
      titleValue: finStat?.Title,
      allowEmpty: true,
    },
  ].concat(
    finStat?.FinStatProps
      ? Object.keys(finStat?.FinStatProps).map((key) => ({
          title: key.split('-').join(' '),
          titleValue: finStat?.FinStatProps[key],
          allowEmpty: true,
          separator: '-',
        }))
      : []
  )

  const [data, setData] = useState({
    Title: finStat?.Title,
    ...finStat?.FinStatProps,
  })
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(finStat) || []
  )
  const { Title, ...rest } = data
  const onSave = async () => {
    if (finStat?._id)
      try {
        const res = await axios.patch(`/financialStatments/${finStat?._id}`, {
          Title,
          FinStatProps: rest,
          chosenHashTagElements,
        })
        setCards((stats) => [
          ...stats.filter((p) => p._id !== finStat?._id),
          res.data.finStat,
        ])
      } catch (error) {
        console.log(error)
      }
  }
  const gridProps = {
    headerText: rtl ? 'بيانات' : 'Statment',
    infoArray: finStatArray,
    setInfoData: setData,
    infoData: data,
    ...DCGProps,
    setChosenHashTagElements,
    chosenHashTagElements,
    onSave,
    departmentId: 4,
  }
  return <EditableGridModel gridProps={gridProps} />
}

export default FinStatProfile
