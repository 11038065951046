import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const LeverageCustomerDataBusinessModel = () => {
  const { t } = useTranslation('LeverageCustomerDataBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 29,
    title: t('title_bm29'),
    subTitle: t('subTitle_bm29'),
    main: t('main_bm29'),
    when: t('when_bm29'),
    who: t('who_bm29'),
    what: t('what_bm29'),
    how: t('how_bm29'),
    why: t('why_bm29'),
    examples: rtl
      ? [
          'أمازون - تأسست 1994 - الولايات المتحدة الأمريكية',
          'Google - تأسست عام 1998 - الولايات المتحدة الأمريكية',
          'Facebook - تأسست عام 2004 - الولايات المتحدة الأمريكية',
          'تويتر - تأسس عام 2006 - الولايات المتحدة الأمريكية',
          'Verizon - تأسست عام 2000 - الولايات المتحدة الأمريكية',
          'AT&T - تأسست عام 1983 - الولايات المتحدة الأمريكية',
          'Sprint - تأسست عام 1899 - الولايات المتحدة الأمريكية',
          '23andMe - تأسست عام 2006 - الولايات المتحدة الأمريكية',
          'PatientsLikeMe - تأسست عام 2004 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Amazon - Founded 1994 - USA',
          'Google - Founded 1998 - USA',
          'Facebook - Founded 2004 - USA',
          'Twitter - Founded 2006 - USA',
          'Verizon - Founded 2000 - USA',
          'AT&T - Founded 1983 - USA',
          'Sprint - Founded 1899 - USA',
          '23andMe - Founded 2006 - USA',
          'PatientsLikeMe - Founded 2004 - USA',
        ],
    tags: rtl
      ? [
          'كل ذلك بيانات',
          'البيانات هي النفط الجديد',
          'التنبؤ',
          'تحليل العملاء',
          'تحليل السلوك',
          'القدرة على التكيف',
          'مخاطر منخفضة',
        ]
      : [
          'It is All Data',
          'Data is the new Oil',
          'Forecasting',
          'Customer Analysis',
          'Behavior Analysis',
          'Adaptability',
          'Low Risk',
        ],
    considerations: t('considerations_bm29'),
    combinations: [
      '$LBM25',
      '$LBM13',
      '$LBM16',
      '$LBM17',
      '$LBM22',
      '$LBM12',
      '$LBM61',
    ],
    hashTagType: 'businessModelTemp',
  }
}

export default LeverageCustomerDataBusinessModel
