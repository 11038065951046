import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}))

const AvailableTargetUsers = ({ adCampaign }) => {
  const classes = useStyles()
  const targetUsers = adCampaign?.targetUsers?.length
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <Box className={classes.container}>
      <Typography
        variant='h5'
        style={{
          fontFamily:
            "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
        }}
      >
        {`${targetUsers}`} {rtl ? 'مستخدمين' : 'Users'}
      </Typography>
    </Box>
  )
}

export default AvailableTargetUsers
