import { Page, StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import GPDFDHeader from './GPDFDHeader'
import GPDFDPageNumber from './GPDFDPageNumber'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
})

const GPDFDPage = ({ page, button, genElement }) => {
  const { fileName } = button.PDFGenProps
  return (
    <Page style={styles.page}>
      <GPDFDHeader title={fileName} element={genElement} />
      {page}
      <GPDFDPageNumber />
    </Page>
  )
}

export default GPDFDPage
