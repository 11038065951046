import React, { useEffect } from 'react'
import ExpandedModelUnit from './ExpandedCardsSystemComponents/ExpandedModelUnit'
import SearchEngine from '../searchSystem/SearchEngine'
import NoElementsModel from '../smallComponents/NoElementsModel'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const ExpandedCardsSystem = (props) => {
  const { cards, searchSystemProps, noElementsTitle } = props
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const {
    searchQuery,
    activateSearchingCards,
    setPlaceholder,
    placeholderValue,
  } = searchSystemProps
  useEffect(() => {
    if (typeof setPlaceholder === 'function')
      setPlaceholder(`${t('Search')} ${placeholderValue}`)
  }, [placeholderValue])
  return (
    <section
      style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
    >
      {cards?.length === 0 ? (
        <NoElementsModel
          elements={
            noElementsTitle ? noElementsTitle : rtl ? 'طلبات' : 'Requests'
          }
        />
      ) : activateSearchingCards ? (
        <SearchEngine
          searchQuery={searchQuery}
          searchElements={cards}
          SearchedElementComponent={ExpandedModelUnit}
          SearchedElementComponentProps={props}
          firstLetterSearch={true}
          alphabeticOrder
          {...searchSystemProps}
        />
      ) : (
        cards
          ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((card, cardIndex) => (
            <ExpandedModelUnit
              props={props}
              element={card}
              elementIndex={cardIndex}
              key={card?._id || cardIndex}
            />
          ))
      )}
    </section>
  )
}

export default ExpandedCardsSystem
