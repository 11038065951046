import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const TransactionBasedBusinessModel = () => {
  const { t } = useTranslation('TransactionBasedBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 69,
    title: t('title_bm69'),
    subTitle: t('subTitle_bm69'),
    main: t('main_bm69'),
    when: t('when_bm69'),
    who: t('who_bm69'),
    what: t('what_bm69'),
    how: t('how_bm69'),
    why: t('why_bm69'),
    examples: rtl
      ? [
          'أمازون - 1994 - الولايات المتحدة الأمريكية',
          'أوبر - 2009 - الولايات المتحدة الأمريكية',
          'Airbnb - 2008 - الولايات المتحدة الأمريكية',
          'eBay - 1995 - الولايات المتحدة الأمريكية',
          'وول مارت - 1962 - الولايات المتحدة الأمريكية',
          'الهدف - 1902 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Amazon - 1994 - USA',
          'Uber - 2009 - USA',
          'Airbnb - 2008 - USA',
          'eBay - 1995 - USA',
          'Walmart - 1962 - USA',
          'Target - 1902 - USA',
        ],
    tags: rtl
      ? ['قائم على الخدمة', 'منتجات البيع بالتجزئة']
      : ['Service-Based', 'Retail Products'],
    considerations: t('considerations_bm69'),
    combinations: ['$LBM60', '$LBM10', '$LBM30'],
    hashTagType: 'businessModelTemp',
  }
}

export default TransactionBasedBusinessModel
