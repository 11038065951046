import React from 'react'
import {
  NotificationBody,
  LearningSectionContent,
  NotificationViewButtons,
  NotificationViewTop,
  LearningSection,
} from './'

const NotificationViewModel = (props) => {
  return (
    <article style={{ width: '100%', margin: '10px auto' }}>
      <NotificationViewTop {...props} />
      <NotificationBody {...props} />
      <NotificationViewButtons {...props} />
      <LearningSection content={<LearningSectionContent {...props} />} />
    </article>
  )
}

export default NotificationViewModel
