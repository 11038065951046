import React from 'react'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import CardTitlesModel from '../../../../../../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCardComps/CardTitlesModel'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const ProductStrategyCardContent = ({
  opener,
  element,
  setChosenCards,
  chosenCards,
  isDarkModeActive,
}) => {
  const modelAdditionCondition =
    chosenCards.filter(
      (el) => el.id === element.id && el.hashTagType === element.hashTagType
    ).length === 0
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'عرض' : 'Open',
      funs: opener,
    },
    {
      title: modelAdditionCondition
        ? rtl
          ? 'أضف'
          : 'Add'
        : rtl
        ? 'مُضاف'
        : 'Added',
      funs: () => {
        if (modelAdditionCondition) setChosenCards([...chosenCards, element])
        toast.success(
          rtl ? 'تم إضافة جانب بنجاح' : 'Aspect has been added successfully'
        )
      },
      disabled: !modelAdditionCondition,
    },
  ]
  return (
    <CardTitlesModel
      title={element.title}
      isDarkModeActive={isDarkModeActive}
      cardFooter={
        <ButtonsSystem
          buttonsArray={buttonsArray}
          isDarkModeActive={isDarkModeActive}
        />
      }
    />
  )
}

export default ProductStrategyCardContent
