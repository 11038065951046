import React from 'react'
import { InvetmentOfferReply, NewInvestmentOfferNotif } from '../../'
import ContributeButtonInvestOffers from './InvestmentNotifDisplyModelComps/ContributeButtonInvestOffers'

const InvestmentNotifDisplyModel = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const pack = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'investPack'
  )
  const notifPropsII = {
    ...notifProps,
    pack,
  }
  const mssgs = [
    {
      relation: 'ceo',
      comp: [
        <NewInvestmentOfferNotif {...notifPropsII} />,
        <ContributeButtonInvestOffers {...notifPropsII} />,
      ],
    },
    {
      relation: 'visitor',
      comp: [<InvetmentOfferReply {...notifPropsII} />],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default InvestmentNotifDisplyModel
