import { Divider } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import DNALoader from '../../../smallComponents/DNALoader'
import LoadMoreButton from '../../../smallComponents/LoadMoreButton'
import RepetionRemover from '../../../smallComponents/RepetionRemover'
import NotFound from '../../../windowViewer/WindowViewerSystemComps/NotFound'
import CommentModel from '../CommentModel'
import NewReply from './NewReply'

const CommentRepliesDialogContent = ({ comment: IncComment, ...props }) => {
  const { socket, isDarkModeActive, post } = props
  const [comment, setComment] = useState(IncComment)
  const [replies, setReplies] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentResponse, setCurrentResponse] = useState(true)
  const [repliesUsers, setRepliesUsers] = useState([])
  const [page, setPage] = useState(1)
  useEffect(() => {
    setIsLoading(true)
    const fetchReplies = async () => {
      try {
        const res = await axios.get(
          `/comments/replies/commentReplies/${comment?._id}/?page=${page}&limit=15`
        )
        setCurrentResponse(Boolean(res.data.replies.length))
        setReplies([...replies, ...res.data.replies])
        setRepliesUsers([...repliesUsers, ...res.data.repliesUsers])
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (comment?._id) fetchReplies()
  }, [comment?._id, page])
  const replyProps = {
    post,
    socket,
    isDarkModeActive,
    postComments: replies,
    setPostComments: setReplies,
    isLoading,
    currentResponse,
    commentsUsers: repliesUsers,
    page,
    setPage,
    replies,
    setReplies,
    setRepliesUsers,
  }

  return comment ? (
    <div
      style={{
        color: isDarkModeActive ? 'white' : 'black',
        marginBottom: 40,
      }}
    >
      <CommentModel
        {...props}
        hideCommentBottom
        setComment={setComment}
        comment={comment}
      />
      <Divider style={{ background: isDarkModeActive ? 'white' : 'black' }} />
      <ReactScrollWheelHandler
        downHandler={(e) => {
          setPage((prev) => prev + 1)
        }}
      >
        {RepetionRemover(replies)?.map((reply) => (
          <CommentModel
            comment={reply}
            key={reply?._id}
            {...replyProps}
            replyMode
          />
        ))}
        {currentResponse && !isLoading && <LoadMoreButton setPage={setPage} />}
        {isLoading && <DNALoader />}
      </ReactScrollWheelHandler>
      <NewReply
        {...props}
        {...replyProps}
        setComment={setComment}
        comment={comment}
      />
    </div>
  ) : (
    <NotFound isDarkModeActive={isDarkModeActive} />
  )
}

export default CommentRepliesDialogContent
