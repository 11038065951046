import { Button, ListItem } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import { useNavigate } from 'react-router-dom'
import deleteFile from '../../../../../../../../firebase/deleteFile'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import CentralTitle from '../../../../../../../smallComponents/CentralTitle'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  note: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    margin: 10,
    padding: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) ',
  },
})

const ProjectTermination = ({ classes, DCGProps }) => {
  const { project } = DCGProps
  const [ready, setReady] = useState(false)
  const [urls, setUrls] = useState([])
  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [counts, setCounts] = useState()
  const lang = i18next.language
  const rtl = lang === 'ar'
  useEffect(() => {
    const fetchReady = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/urls/projectCheck/deleteCheck/${project?._id}`
        )
        setReady(res.data.ready)
        setCounts(res.data)
        if (res.data.ready) {
          const resUrls = await axios.get(`/urls/projectUrls/${project?._id}`)
          setUrls(resUrls.data.urls)
        }
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (project?._id) fetchReady()
  }, [project?._id])
  const history = useNavigate()
  const buttonsArray = [
    {
      title: isLoading
        ? rtl
          ? 'جارى تنفيذ بروتوكول المسح'
          : 'Running Deletion Protocol'
        : rtl
        ? 'حذف المشروع'
        : 'Delete Project',
      disabled: isLoading || !ready,
      funs: async () => {
        if (ready)
          try {
            setIsLoading(true)
            Promise.all(
              urls?.map((image) => {
                if (image) deleteFile(image)
              })
            )
            await axios.delete(`/projects/${project?._id}`, {
              headers: {},
              data: { userId: user?._id },
            })
            setIsLoading(false)
            history('/home')
          } catch (error) {}
      },
    },
  ]
  return (
    <article className={classes.wrapper}>
      <CentralTitle title={rtl ? 'إنهاء المشروع' : 'Project Termination'} />
      <section className={classes.note}>
        {rtl
          ? 'يجب أن يكون هناك موافقة من أعضاء مجلس إدارتك حول هذه الخطوة، وقد يكون استطلاع الرأي هو الخيار المناسب.'
          : 'You should have consent from your board members about this step, and a poll may serve the purpose.'}
      </section>
      <section>
        {rtl
          ? 'يجب عليك إنهاء هذه العناصر قبل الحذف:'
          : 'You have to end these elements before deletion:'}
        <br />
        {counts?.channels !== 0
          ? `${counts?.channels} ${
              rtl ? 'قناة شراكة' : 'partnership channels'
            }, `
          : ''}
        {counts?.issues !== 0
          ? `${rtl ? 'حل' : 'resolve'} ${counts?.issues} ${
              rtl ? 'مشكلة' : 'issues'
            }, `
          : ''}
        {counts?.contracts !== 0
          ? `${rtl ? 'إنهاء' : 'terminate'} ${counts?.contracts} ${
              rtl ? 'عقد' : 'contracts'
            } `
          : ''}
        {rtl
          ? 'قبل أن تتمكن من حذف المشروع.'
          : 'before you will be able to delete the project.'}
      </section>
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </article>
  )
}

const WrappedProjectTermination = withStyles(styles)(ProjectTermination)

export default WrappedProjectTermination
