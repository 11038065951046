import React from 'react'
import SpreadsheetChart from './SpreadsheetChartViewerComps/SpreadsheetChart'
import moment from 'moment'
import NewFormDialogModel from '../formsSystem/NewFormDialogModel'
import SpreadsheetEditing from './SpreadsheetChartViewerComps/SpreadsheetEditing'
import WindowProps from '../windowViewer/WindowProps'
import i18next from 'i18next'

const SpreadsheetChartViewer = (props) => {
  const { DCGProps, stampType, inputValue, setInputValue } = props
  const { isDarkModeActive } = DCGProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const menuOptions = [
    {
      title: rtl ? 'عدد' : 'Number',
    },
    {
      title: rtl ? 'يوم' : 'Day',
    },
    {
      title: rtl ? 'شهر' : 'Month',
    },
    {
      title: rtl ? 'سنة' : 'Year',
    },
  ]

  const chartTypes = [
    { title: rtl ? 'خطى' : 'Line' },
    { title: rtl ? 'مساحة' : 'Area' },
  ]
  const StampValue = (rowIndex) => {
    const extras = [
      rowIndex + 1,
      moment(inputValue).add(rowIndex, 'days').format('YYYY-MMM-DD'),
      moment(inputValue).add(rowIndex, 'months').format('MMM'),
      Number(inputValue) + rowIndex,
    ]
    return extras[stampType]
  }
  const propsII = {
    ...props,
    inputValue,
    setInputValue,
    StampValue,
    menuOptions,
    chartTypes,
  }
  return (
    <div style={{ color: isDarkModeActive && 'white' }}>
      <SpreadsheetChart {...propsII} windowOpener={windowOpener} />
      <NewFormDialogModel
        NewFormComponent={
          <SpreadsheetEditing {...propsII} windowCloser={windowCloser} />
        }
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        {...DCGProps}
        {...props}
        dialogTitle={rtl ? 'تعديل الرسم البيانى' : 'Update Chart Data'}
      />
    </div>
  )
}

export default SpreadsheetChartViewer
