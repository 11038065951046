const PostGeneratorEngine = (
  postData,
  postConfigId,
  feedsSystemCategory,
  element
) => {
  const newHomePostConfigs = [
    {
      //0- social post - normal post
      feedsSystemCategory: 'home',
      homePostFeedModelIndex: 0,
      ...postData,
    },
    {
      // 1 home chart post
      feedsSystemCategory: 'home',
      homePostFeedModelIndex: 1,
      ...postData,
    },
    {
      //2- shared post
      feedsSystemCategory: 'home',
      homePostFeedModelIndex: 2,
      ...postData,
    },
    {
      //3- new project post
      feedsSystemCategory: 'home',
      homePostFeedModelIndex: 0,
      postTypeIndex: 8,
      ...postData,
    },
    {
      //4 any post with element
      feedsSystemCategory: 'home',
      ...postData,
    },
  ]

  const newPagePostConfigs = [
    {
      //0 normal post
      feedsSystemCategory: 'page',
      postTypeIndex: 0,
      ...postData,
    },
    {
      //1 chart post
      feedsSystemCategory: 'page',
      postTypeIndex: 1,
      ...postData,
    },
    {
      //2 any element post
      feedsSystemCategory: 'page',
      ...postData,
    },
  ]

  return {
    ...(feedsSystemCategory === 'home'
      ? postConfigId < 4
        ? newHomePostConfigs[postConfigId]
        : newHomePostConfigs[4]
      : postConfigId < 2
      ? newPagePostConfigs[postConfigId]
      : newPagePostConfigs[2]),
    postElement: {
      hashTagType: element?.hashTagType,
      id: element?.id || element?._id,
      title:
        element?.title ||
        element?.Title ||
        element?.Name ||
        element?.ProductName,
      image: element?.image || (element?.images && element?.images[0]),
    },
  }
}

export default PostGeneratorEngine
