import { Button } from '@mui/material'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import FormsComponents from '../../../formsSystem/FormsComponents'
import withStyles from '@mui/styles/withStyles'
import HashTagElementsRefiner from '../../../smallComponents/HashTagElementsRefiner'
import ToastifyGeneralError from '../../../smallComponents/ToastifyGeneralError'
import i18next from 'i18next'

const styles = () => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 5,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const ModificationTextForm = ({
  defaultText,
  GeneralDialogClose,
  isDarkModeActive,
  conEl,
  setModifications,
  classes,
  project,
  departmentId,
  subDepId,
  modification,
}) => {
  const { user } = useContext(AuthContext)
  const [textContent, setTextContent] = useState(defaultText)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(modification) || []
  )
  const newModification = {
    userId: user?._id,
    elementId: conEl?._id,
    Content: textContent,
    chosenHashTagElements,
    agreedUsersIds: user?._id,
  }
  const formsCompsProps = {
    formComponentIndex: 0,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    isDarkModeActive,
    project,
    departmentId,
    subDepId,
  }
  const [isUpdating, setIsUpdating] = useState()
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article>
      <FormsComponents formsCompsProps={formsCompsProps} />
      <section className={classes.buttonsWrapper}>
        <Button
          className={classes.button}
          onClick={async () => {
            setIsUpdating(true)
            try {
              const res = await axios.post(
                `/contractElementModifications`,
                newModification
              )
              setModifications((mods) => [res.data.modification, ...mods])
            } catch (error) {
              console.log(error)
              ToastifyGeneralError(error)
            }
            GeneralDialogClose()
            setIsUpdating(false)
          }}
        >
          {isUpdating
            ? rtl
              ? 'جارى تحديث البيانات...'
              : 'Updating...'
            : rtl
            ? 'إستمرار'
            : 'Continue'}
        </Button>
        <Button className={classes.button} onClick={() => GeneralDialogClose()}>
          {rtl ? 'إلغاء' : 'Cancel'}
        </Button>
      </section>
    </article>
  )
}

const WrappedModificationTextForm = withStyles(styles)(ModificationTextForm)

export default WrappedModificationTextForm
