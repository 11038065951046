import ConfigsPropsExtractor from './ConfigsPropsExtractor'
import { useState, useEffect } from 'react'
import PostGeneratorEngine from './PostGeneratorEngine'

export default function FreePostGenerator(
  hashTagType,
  configsFile,
  dataPackage,
  missingProps
) {
  const extractedPropsObject = ConfigsPropsExtractor({
    [hashTagType]: true,
    newPost: true,
  })
  const propsArray = configsFile.map((pro) => pro.title.split(' ').join(''))
  const [postData, setPostData] = useState()

  useEffect(() => {
    let tempObject = {}
    propsArray.forEach((prop) => {
      return (tempObject[prop] = dataPackage[prop])
    })
    setPostData({
      ...extractedPropsObject,
      ...tempObject,
      ...missingProps,
    })
  }, [])

  useEffect(() => {
    if (postData?.images && postData?.images.length === 0)
      setPostData({
        ...postData,
        images: '',
      })
  }, [postData])

  const pageHashTagTypes = [
    'product',
    'task',
    'job',
    'offers',
    'issue',
    'protocol',
    'campaign',
    'investPack',
  ]

  const postEngienGeneratorConfigs = [
    postData,
    hashTagType === 'newProject' ? 3 : 4,
    pageHashTagTypes?.includes(hashTagType) ? 'page' : 'home',
  ]

  const post = PostGeneratorEngine(
    postEngienGeneratorConfigs[0],
    postEngienGeneratorConfigs[1],
    postEngienGeneratorConfigs[2],
    dataPackage
  )

  return post
}
