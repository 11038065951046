import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const CampaignRequestReplyCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl
          ? `عرضك لحملة ترويجية تم الرد عليه`
          : `Your offer to a campagin has been replied to`
      }
    />
  )
}

export default CampaignRequestReplyCover
