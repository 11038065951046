import { Grid } from '@mui/material'
import React from 'react'
import EditingButtons from './EditableInfoUnitComponents/EditingButtons'
import AutoSizeEditingZone from './EditableInfoUnitComponents/AutoSizeEditingZone'
import SavedView from './EditableInfoUnitComponents/SavedView'
import ViewTitle from './EditableInfoUnitComponents/ViewTitle'
import DateInputEditingZone from './EditableInfoUnitComponents/DateInputEditingZone'
import HelperText from './EditableInfoUnitComponents/HelperText'
import withStyles from '@mui/styles/withStyles'
import TimeInputEditingZone from './EditableInfoUnitComponents/TimeInputEditingZone'
import NumberInputEditingZone from './EditableInfoUnitComponents/NumberInputEditingZone'

const styles = () => ({
  titleWrapper: {
    marginBottom: 10,
    width: '100%',
    position: 'relative',
    backgroundColor: 'rgba(217,224,252, 0.2)',
    padding: 15,
    borderRadius: 10,
  },
})

const EditableInfoUnitPC = ({ classes, gridProps }) => {
  const { info, hideEditingTools, infoValue, editing, hideInfoTitles } =
    gridProps
  return (
    <Grid
      container
      className={classes.titleWrapper}
      style={{
        display: infoValue || info?.allowEmpty ? null : 'none',
      }}
    >
      <Grid item xs={hideEditingTools ? 3.5 : hideInfoTitles ? 0 : 3}>
        <ViewTitle {...gridProps} />
      </Grid>
      <Grid
        item
        xs={
          hideEditingTools || info?.unEditable ? 8.5 : hideInfoTitles ? 10 : 7
        }
      >
        <HelperText {...gridProps} />
        {editing ? (
          info?.type === 'date' ? (
            <DateInputEditingZone {...gridProps} />
          ) : info?.type === 'time' ? (
            <TimeInputEditingZone {...gridProps} />
          ) : info?.type === 'number' ? (
            <NumberInputEditingZone {...gridProps} />
          ) : (
            <AutoSizeEditingZone {...gridProps} />
          )
        ) : (
          <SavedView {...gridProps} />
        )}
      </Grid>
      <Grid item xs={2}>
        <EditingButtons {...gridProps} />
      </Grid>
    </Grid>
  )
}

const WrappedEditableInfoUnitPC = withStyles(styles)(EditableInfoUnitPC)

export default WrappedEditableInfoUnitPC
