import axios from 'axios'
import { useEffect } from 'react'

const NotificationAutoInterUpdator = ({ notification }) => {
  useEffect(() => {
    const updateNotif = async () => {
      await axios.patch(`/notifications/${notification?._id}`, {
        interactedWith: 'true',
      })
    }
    if (!notification?.interactedWith) updateNotif()
  }, [notification?._id])
}

export default NotificationAutoInterUpdator
