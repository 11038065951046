import React from 'react'
import { NotificationViewModel, NotificationBodyWithAccessCard } from '../../..'
import { Link } from 'react-router-dom'
import moment from 'moment'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const TaskNewRequestsMssg = (props) => {
  const { project, task } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <>
              {rtl ? 'مهمة' : 'Your task'}
              <span style={style}>{task?.title}</span> {rtl ? 'فى' : 'in'}
              <span style={style}>
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
              </span>
              {rtl ? 'حصلت على طلبات جديدة' : 'has new requests need revision'}
            </>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default TaskNewRequestsMssg
