import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const NewElementRemovedCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `قام ${sender?.name}  بحذف عنصر من قناة انت مشارك بها`
    : `${sender?.name} removed an element to a channel you are in`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default NewElementRemovedCover
