import React from 'react'
import ProjectDepartments from '../../../../../../newProject/NewProjectComponents/NewBusinessProjectComponents/NewProjectMembersComponents/ProjectDepartmentsArrays'
import CardTitlesModel from '../../../../../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCardComps/CardTitlesModel'
import { useTranslation } from 'react-i18next'

const JobCardModelContent = ({ element, isDarkModeActive }) => {
  const job = element
  const { t } = useTranslation()
  return (
    <CardTitlesModel
      title={job?.title}
      cardMark={t(job.jobStatus)}
      markColor={
        job.jobStatus === 'Opened'
          ? 'blue'
          : job.jobStatus === 'Closed'
          ? isDarkModeActive
            ? 'white'
            : 'gray'
          : 'green'
      }
      isDarkModeActive={isDarkModeActive}
      cardFooter={
        <span style={{ fontSize: 14, fontWeight: 500 }}>
          {ProjectDepartments()[job.departmentId]?.name}
        </span>
      }
    />
  )
}

export default JobCardModelContent
