import { ref, uploadString, getDownloadURL } from 'firebase/storage'
import { storage } from './config'

const uploadImage = async (uri,filePath) => {
  return new Promise(async (resolve, reject) => {
    const storageRef = ref(storage, filePath)
    try {
      await uploadString(storageRef, uri, 'data_url')
      const url = await getDownloadURL(storageRef)
      resolve(url)
    } catch (error) {
      reject(error)
    }
  })
}

export default uploadImage
