import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import MultipleMembersViewer from '../../../../../../membersSystems/MultipleMembersViewer'
import TitleHeader from '../../../../../../smallComponents/Header'
import Loading from '../../../../../../smallComponents/Loading'
import { AuthContext } from '../../../../../../../context/AuthContext'
import { toast } from 'react-toastify'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'
import i18next from 'i18next'

const CampaignContributors = ({ DCGProps, campaign, depManager }) => {
  const { project, socket } = DCGProps
  const [contributors, setContributors] = useState([])
  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchContributors = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/campaigns/contributors/${campaign?._id}`)
        setContributors(res.data.contributors)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchContributors()
  }, [campaign?._id])
  const modContributors = contributors?.map((cont) => ({
    ...cont,
    name: cont.firstName + ' ' + cont.lastName,
    id: cont._id,
    image: cont.profilePicture,
    hashTagType: project ? 'projectContributor' : 'userContributor',
  }))
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [clicked, setClicked] = useState(false)
  const modelProps = {
    buttonsArray:
      user?._id === project?.userId || user?._id === depManager?._id
        ? [
            {
              title: rtl ? 'حذف' : 'Remove',
              disabled: clicked,
              funs: async (member) => {
                setClicked(true)
                const res = await axios.patch(
                  `/campaigns/removeContributor/${campaign?._id}`,
                  {
                    contributorId: member?._id,
                  }
                )
                const channel = res.data.channel
                // removing the elements
                if (channel) {
                  await axios.patch(`/channels/${channel?._id}`, {
                    elements: channel?.elements?.filter(
                      (e) => !res.data.elements?.includes(e?.id)
                    ),
                  })
                }
                socket.current.emit('addNotifUser', member?._id)
                NotificationCreator({
                  associativity: 'campaign',
                  relation: 'contributor',
                  conditionIndex: 1,
                  accompaniedData: {
                    elements: [
                      {
                        hashTagType: campaign?.hashTagType,
                        id: campaign?._id || campaign?.id,
                        title: campaign?.Title || campaign?.title,
                      },
                      {
                        id: channel._id,
                        hashTagType: channel.hashTagType,
                        title: channel?.Title || channel?.title,
                      },
                    ],
                  },
                  category: 'contributors',
                  userId: member?._id,
                  currentUser: user,
                  socket,
                })
                toast.warning(
                  rtl
                    ? 'تم حذف المشارك، قم بإعادة الدخول للحملة'
                    : 'Contributor has been removed successfully, please, reopen the campaign'
                )
              },
            },
          ]
        : [],
  }
  return (
    <div>
      <TitleHeader headerText={rtl ? 'مساهمون' : 'Contributors'} />
      {isLoading ? (
        <Loading />
      ) : (
        <MultipleMembersViewer
          modelProps={modelProps}
          cards={modContributors}
          windowType='projectContributor'
          DCGProps={DCGProps}
          emptySentence={rtl ? 'مساهمين' : 'contributors'}
        />
      )}
    </div>
  )
}

export default CampaignContributors
