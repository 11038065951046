import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const InfrastructureAsAService = () => {
  const { t } = useTranslation('InfrastructureAsAService')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 75,
    title: t('title_bm75'),
    subTitle: t('subTitle_bm75'),
    main: t('main_bm75'),
    when: t('when_bm75'),
    who: t('who_bm75'),
    what: t('what_bm75'),
    how: t('how_bm75'),
    why: t('why_bm75'),
    examples: rtl
      ? [
          'Amazon Web Services (AWS) - تأسست عام 2006 - الولايات المتحدة',
          'Microsoft Azure - تأسست عام 2010 - الولايات المتحدة',
          'Google Cloud Platform - تأسست عام 2008 - الولايات المتحدة',
          'IBM Cloud - تأسست عام 2007 - الولايات المتحدة',
          'Oracle Cloud Infrastructure - تأسست عام 2016 - الولايات المتحدة',
          'DigitalOcean - تأسست عام 2011 - الولايات المتحدة',
          'Vultr - تأسست عام 2014 - الولايات المتحدة',
          'Linode - تأسست عام 2003 - الولايات المتحدة',
          'Rackspace - تأسست عام 1998 - الولايات المتحدة',
          'Alibaba Cloud - تأسست عام 2009 - الصين',
        ]
      : [
          'Amazon Web Services (AWS) - Founded in 2006 - United States',
          'Microsoft Azure - Founded in 2010 - United States',
          'Google Cloud Platform - Founded in 2008 - United States',
          'IBM Cloud - Founded in 2007 - United States',
          'Oracle Cloud Infrastructure - Founded in 2016 - United States',
          'DigitalOcean - Founded in 2011 - United States',
          'Vultr - Founded in 2014 - United States',
          'Linode - Founded in 2003 - United States',
          'Rackspace - Founded in 1998 - United States',
          'Alibaba Cloud - Founded in 2009 - China',
        ],
    tags: rtl
      ? [
          'الخدمات السحابية',
          'الخبرات التقنية',
          'تطوير التطبيقات',
          'نماذج الاسعار',
          'اتفاقية مستوى الخدمة',
          'التسويق الرقمي',
          'دعم العملاء',
          'ميزة تنافسية',
          'إزدياد الدخل',
          'توفير في التكاليف',
          'علاقات طويلة الأجل',
        ]
      : [
          'Cloud Service',
          'Technical Expertise',
          'Application Development',
          'Cloud Platform',
          'Pricing Model',
          'Service Level Agreement',
          'Digital Marketing',
          'Customer Support',
          'Competitive Advantage',
          'Increased Revenue',
          'Cost Savings',
          'Long-term Relationships',
        ],
    considerations: t('considerations_bm75'),
    combinations: ['$LBM74', '$LBM76', '$LBM77', '$LBM78'],
    hashTagType: 'businessModelTemp',
  }
}

export default InfrastructureAsAService
