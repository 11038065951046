const FormDataLangModulator = (formData, t) => {
  const modeifedFormData = Object.entries(formData)
  let newFormData = {}
  modeifedFormData?.map((entry) => {
    newFormData[t(entry[0], { lng: 'en' }).split(' ').join('')] = entry[1]
  })
  return newFormData
}

export default FormDataLangModulator
