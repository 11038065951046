import React, { useState, useEffect } from 'react'
import { GiEarthAmerica } from 'react-icons/gi'
import { FaHome } from 'react-icons/fa'
import FreeSelectComponent from '../../../formsSystem/FreeSelectComponent'

const SharePostPrivacyOptions = ({ shareSystemProps }) => {
  const { setPostData, postData, shareTypeIndex, post } = shareSystemProps
  const menuOptions = [
    {
      title: 'Global',
      pureIcon: GiEarthAmerica,
    },
    {
      title: 'Local',
      pureIcon: FaHome,
    },
  ]
  const [option, setOption] = useState(1)
  const postPrivicies = ['Global', 'Local']
  useEffect(() => {
    setPostData({
      ...postData,
      privacyType: postPrivicies[option],
    })
  }, [option])

  return (
    <span>
      <FreeSelectComponent
        onChange={(e) => {
          console.log(
            'here place any function that will use the value of the selct e.target.value'
          )
        }}
        option={option}
        setOption={setOption}
        menuOptions={menuOptions}
        disabled={shareTypeIndex !== 2 || post?.projectId !== post?.userId}
        defaultValue={1}
        {...shareSystemProps}
      />
    </span>
  )
}

export default SharePostPrivacyOptions
