import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const MarketingDifferentSegments = () => {
  const { t } = useTranslation('MarketingDifferentSegments')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 24,
    category: t('category_ms24'),
    title: t('title_ms24'),
    subTitle: t('subTitle_ms24'),
    main: t('main_ms24'),
    how: t('how_ms24'),
    usedInModels: [],
    tags: rtl
      ? [
          'تجزئة السوق',
          'السوق المستهدف',
          'التسويق المركز',
          'التسويق المتميز',
          'التسويق غير متمايز',
          'التسويق المتخصص',
          'التسويق القائم على العملاء',
          'تسويق متكامل',
        ]
      : [
          'Market Segmentation',
          'Target Market',
          'Focused Marketing',
          'Differentiated Marketing',
          'Undifferentiated Marketing',
          'Niche Marketing',
          'Customer-Driven Marketing',
          'Integrated Marketing',
        ],
    examples: t('examples_ms24'),
    hashTagType: 'markStraAspect',
  }
}

export default MarketingDifferentSegments
