import React from 'react'
import { Button } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import PopoverOptionsConfigs from './PopoverOptionsConfigs'
import i18next from 'i18next'

const styles = () => ({
  text: {
    color: 'black',
    display: 'flex',
    width: '100%',
    alignContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fotnSize: 16,
    fontWeight: 600,
    '&:hover': {
      fotnSize: 18,
    },
  },
  labelButton: {
    textTransform: 'none !important',
    backgroundColor: 'transparent !important',
    '&:hover': {
      opacity: '0.8 !important',
      backgroundColor: 'rgba(31,126,222, 0.3) !important',
    },
  },
  textIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
})

const PopoverMenuOption = ({ classes, option, handlePopoverClose }) => {
  const Config = PopoverOptionsConfigs(option)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    Config?.PureIcon && (
      <Button
        component='label'
        color='inherit'
        className={classes.labelButton}
        onClick={() => {
          option.funs()
          if (!option?.loading) handlePopoverClose()
        }}
        disabled={option?.disabled}
      >
        <span className={classes.text}>
          <section
            className={classes.textIconWrapper}
            style={{ opacity: option?.disabled ? 0.5 : 1 }}
          >
            <Config.PureIcon
              size={20}
              style={{
                marginRight: !rtl && 10,
                marginLeft: rtl && 10,
                ...option?.iconStyle,
              }}
            />
            {option?.loading
              ? option?.loadingText
              : rtl
              ? Config?.ar_title
              : Config?.en_title}
          </section>
        </span>
      </Button>
    )
  )
}

const WrappedPopoverMenuOption = withStyles(styles)(PopoverMenuOption)

export default WrappedPopoverMenuOption
