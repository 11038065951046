import React from 'react'
import InquiryResponse from './InquiryNotificationViewModelComps/InquiryResponse'
import InquiryReviewerResponse from './InquiryNotificationViewModelComps/InquiryReviewerResponse'

const InquiryNotificationViewModel = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const inquiry = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'inquiryProfile'
  )
  const notifPropsII = {
    ...notifProps,
    inquiry,
  }
  const mssgs = [
    {
      relation: 'inquiryOwner',
      comp: [<InquiryResponse {...notifPropsII} />],
    },
    {
      relation: 'inquiryReviewer',
      comp: [<InquiryReviewerResponse {...notifPropsII} />],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default InquiryNotificationViewModel
