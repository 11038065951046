import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const Relationships = () => {
  const { t } = useTranslation('Relationships')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 15,
    category: t('category_ms15'),
    title: t('title_ms15'),
    subTitle: t('subTitle_ms15'),
    main: t('main_ms15'),
    how: t('how_ms15'),
    usedInModels: [],
    tags: rtl
      ? ['علاقات العملاء', 'اتصال ثنائي الاتجاه']
      : ['Customer Relationships', 'Two-Way Connection'],
    examples: t('examples_ms15'),
    hashTagType: 'markStraAspect',
  }
}

export default Relationships
