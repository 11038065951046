import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const TaskNewRequestsMssgCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? 'لديك طلبات جديدة على مهمة قمت بإنشاؤها'
    : 'Your Created Task, has recieved new requests'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default TaskNewRequestsMssgCover
