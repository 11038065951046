import { Popover } from '@mui/material'
import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { FeedContext } from '../../context/FeedContext/FeedContext'
import SearchIdtentifiersExtractor from '../searchSystem/SearchIdtentifiersExtractor'
import RepetionRemover from '../smallComponents/RepetionRemover'
import {
  SuggListItem,
  SearchEngine,
  SuggListCard,
  HashTagChosenElementPusher,
  HashTagReplacer,
  HashTagSystemData,
  LastHashTagCalculator,
  TypingChecker,
} from './HashTagSuggestSystemComponents'

const HashTagSuggestSystem = (props) => {
  const [chosenHashtagComp, setChosenHashtagComp] = useState()
  const {
    textContent,
    isSuggeCardClicked,
    project,
    departmentId,
    subDepId,
    chosenHashTagElements,
    anchorRef,
    cursorDirection,
    margin,
  } = props
  const { user } = useContext(AuthContext)
  const [searchQuery, setSearchQuery] = useState('')
  const { lastHashTag, lastHashTagSymbol } = LastHashTagCalculator(props)
  const { isTyping } = TypingChecker(props)
  const propsII = {
    chosenHashtagComp,
    lastHashTagSymbol,
    lastHashTag,
    ...props,
  }
  useEffect(() => {
    if (lastHashTag && !isTyping)
      setSearchQuery(lastHashTag.substring(1, lastHashTag.length))
  }, [textContent])
  useEffect(() => {
    setChosenHashtagComp()
    setSearchQuery('')
  }, [isSuggeCardClicked])
  useEffect(() => {
    HashTagReplacer(propsII)
  }, [isSuggeCardClicked])
  useEffect(() => {
    HashTagChosenElementPusher(propsII)
  }, [chosenHashtagComp, textContent])
  const combinedData = HashTagSystemData(
    searchQuery,
    project,
    departmentId,
    subDepId
  )
  const searchIdtentifiers = SearchIdtentifiersExtractor({
    idea: true,
    job: true,
    inquiry: true,
    product: true,
    task: true,
    resource: true,
    modelTemp: true,
  })

  const [hashTagUsers, setHashTagUsers] = useState([])
  useEffect(() => {
    const fetchHashtagusers = async () => {
      try {
        const res = await axios.get(
          `/searchSystems/mentionsearch/${user?._id}/?projectId=${project?._id}&&searchQuery=${searchQuery}`
        )
        setHashTagUsers(res.data.users)
      } catch (error) {
        console.log(error)
      }
    }
    if (searchQuery?.length > 3) fetchHashtagusers()
  }, [user?._id, project?._id, searchQuery])

  const { dispatch } = useContext(FeedContext)
  const modHashes = chosenHashTagElements
    ?.filter((m) => m?.identifier?.startsWith('@'))
    ?.map((m) => m?.mentionElement)

  const mentionedUsersIds = RepetionRemover(modHashes)?.map((m) => m?._id)
  useEffect(() => {
    if (modHashes?.length > 0)
      dispatch({
        type: 'MENTION',
        payload: {
          mentionedUsersIds,
        },
      })
  }, [modHashes?.length])

  // ----- popover -----
  const [anchorEl, setAnchorEl] = useState(null)
  const handlePopoverOpen = (event) => {
    setAnchorEl(anchorRef?.current)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const searchElements =
    lastHashTagSymbol === '$' ? combinedData || [] : hashTagUsers
  useEffect(() => {
    if (searchElements?.length > 0 && lastHashTag) {
      handlePopoverOpen()
    } else {
      handlePopoverClose()
    }
  }, [searchElements?.length, lastHashTag])
  return (
    searchElements?.length > 0 &&
    lastHashTag?.slice(1)?.length > 0 && (
      <Popover
        disableScrollLock
        style={{ margin }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: cursorDirection === 'rtl' ? 'right' : 'left',
        }}
        disableAutoFocus
      >
        <SuggListCard
          {...props}
          cardContent={
            <SearchEngine
              searchQuery={searchQuery}
              searchElements={searchElements}
              SearchedElementComponent={SuggListItem}
              SearchedElementComponentProps={{
                ...props,
                isTyping,
                setChosenHashtagComp,
                hashTagSymbol: lastHashTagSymbol,
                searchQuery,
                chosenHashtagComp,
              }}
              searchIdtentifiers={searchIdtentifiers}
              firstLetterSearch
              freeSearch
              disableSearch
              alphabeticOrder
              alphabeticOrderDir
            />
          }
        />
      </Popover>
    )
  )
}

export default HashTagSuggestSystem
