import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const ChangeSubDepNameNotifCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={
        rtl
          ? `لقد تم تغيير اسم القسم الفرعى الخاص بك`
          : 'Name of your subdepdepartment has been changed'
      }
    />
  )
}

export default ChangeSubDepNameNotifCover
