import React from 'react'
import {
  ProjectFollowNotifCover,
  NewProjectProjectMeetingRequestNotifCover,
  DepManagerManagerToolsNewRequestsCover,
  MemberNewMeetingNotifCover,
  ListItemScreenViewModels,
  NewCEOAssignmentNotificationCover,
  NewDepManagerAssignmentNotificationCover,
  NewPollNotificationCover,
  NewSubDepManagerAssignmentNotificationCover,
  PollResultsNotificationCover,
  MeetingUpdateNoticeCover,
  DepartmentManagerNewNoteCover,
  MemberInterDepTransferCover,
  MemberInterSubDepTransferCover,
  ProjectPostLikeCover,
  ProjectPostShareCover,
  ProjectPostCommentCover,
  NewProjectProjectMeetingReplyNotifCover,
  NewSubdDepCreationCover,
  SubDepRemovalNotifCover,
  NewSubDepManagmentAssignmentCover,
  SubDepManagerManagerToolsNewRequestsCover,
} from '../../../'
import ChangeSubDepNameNotifCover from './ProjectNotificationModelComps/ChangeSubDepNameNotifCover'
import SubDepDeletionNotifCover from './ProjectNotificationModelComps/SubDepDeletionNotifCover'
import DepContribRemovalNotifCover from './ProjectNotificationModelComps/DepContribRemovalNotifCover'
import AddToBardNotifCover from './ProjectNotificationModelComps/AddToBardNotifCover'
import RemoveFromBoardNotifCover from './ProjectNotificationModelComps/RemoveFromBoardNotifCover'

const ProjectNotificationModel = ({ notifProps }) => {
  const models = [
    {
      relation: 'admin',
      conditions: [
        <ProjectFollowNotifCover {...notifProps} />,
        <ProjectPostLikeCover {...notifProps} />,
        <ProjectPostShareCover {...notifProps} />,
        <ProjectPostCommentCover {...notifProps} />,
      ],
    },
    {
      relation: 'ceo',
      conditions: [
        <NewProjectProjectMeetingRequestNotifCover {...notifProps} />,
        <NewProjectProjectMeetingReplyNotifCover {...notifProps} />,
      ],
    },
    {
      relation: 'depManager',
      conditions: [<DepManagerManagerToolsNewRequestsCover {...notifProps} />],
    },
    {
      relation: 'subDepManager',
      conditions: [
        'placeholder',
        <NewSubDepManagmentAssignmentCover {...notifProps} />,
        <SubDepManagerManagerToolsNewRequestsCover {...notifProps} />,
      ],
    },
    {
      relation: 'member',
      conditions: [
        <DepartmentManagerNewNoteCover {...notifProps} />,
        <ChangeSubDepNameNotifCover {...notifProps} />,
        <MemberNewMeetingNotifCover {...notifProps} />,
        // meeting update notice not used anay where
        <MeetingUpdateNoticeCover {...notifProps} />,
        <MemberInterDepTransferCover {...notifProps} />,
        <MemberInterSubDepTransferCover {...notifProps} />,
        <NewSubdDepCreationCover {...notifProps} />,
        <SubDepRemovalNotifCover {...notifProps} />,
        <SubDepDeletionNotifCover {...notifProps} />,
        <DepContribRemovalNotifCover {...notifProps} />,
      ],
    },
    {
      relation: 'assignments',
      conditions: [
        <NewCEOAssignmentNotificationCover {...notifProps} />,
        <NewDepManagerAssignmentNotificationCover {...notifProps} />,
        <NewSubDepManagerAssignmentNotificationCover {...notifProps} />,
      ],
    },
    {
      relation: 'poll',
      conditions: [
        <NewPollNotificationCover {...notifProps} />,
        <PollResultsNotificationCover {...notifProps} />,
      ],
    },
    {
      relation: 'board',
      conditions: [
        <AddToBardNotifCover {...notifProps} />,
        <RemoveFromBoardNotifCover {...notifProps} />,
      ],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default ProjectNotificationModel
