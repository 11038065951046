import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  NotificationBodyWithAccessCard,
  NotificationViewModel,
  RequestNotificationAccessModel,
} from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const CampaignNewRequest = (props) => {
  const { project, campaign } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={
        rtl
          ? 'بعد قبول هذا العرض ، يمكنك إرسال قناة استثمار إلى المستثمر المحتمل أو إنشاء قناة جديدة في قنوات مشروعك ، للتعامل مع المزيد من التفاعلات'
          : 'After accepting this offer you may send the potential investor an investment channel or create a new one in your project channels, to handle more interactions'
      }
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <section>
              {rtl ? `حملتك` : 'Your Campaign'}{' '}
              <span style={style}>{campaign.title}</span>
              {rtl ? 'فى ' : 'in '}
              <Link to={`/projectProfile/${project?._id}`} style={style}>
                {project?.name}
              </Link>
              {rtl ? `استقبلت طلبات جديده` : 'has recieved new requests'}
            </section>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default CampaignNewRequest
