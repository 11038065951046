import React from 'react'
import NotificationCoverModel from '../../../NotificationCoverModel'
import i18next from 'i18next'

const ReplyMentionNotificationCover = ({ notification, sender }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `على تعليق ${sender?.name} بالاشارة اليك فى رد له`
    : `${sender?.name} mentioned you in his reply on a comment.`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default ReplyMentionNotificationCover
