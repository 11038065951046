import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import NotPermissionView from './IdeaProfileComponents/NotPermissionView'
import PermissionedView from './IdeaProfileComponents/PermissionedView'

const IdeaProfile = (props) => {
  const { idea, postMode } = props
  const { user } = useContext(AuthContext)
  const [isVisitorPermissioned, setIsVisitorPermissioned] = useState(
    user?._id === idea?.userId
  )
  useEffect(() => {
    if (idea?.permissionedUserIds?.includes(user?._id))
      setIsVisitorPermissioned(true)
  }, [idea?.permissionedUserIds, user?._id, idea?.userId])

  return postMode && user?._id !== idea?.userId ? (
    (idea?.secured && isVisitorPermissioned) || !idea?.secured ? (
      <PermissionedView {...props} />
    ) : (
      <NotPermissionView {...props} />
    )
  ) : (
    <PermissionedView {...props} />
  )
}

export default IdeaProfile
