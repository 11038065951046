import React from 'react'
import FeedSkeleton from '../FeedSkeleton'
import withStyles from '@mui/styles/withStyles'
import FreeBar from '../FreeBar'

const styles = () => ({
  projectActivityFeed: {
    display: 'flex',
    flex: '2 !important',
  },
  projectActivityFeedContainer: {
    display: 'flex',
    top: '70px',
    flex: 5.468,
    width: '100px',
    height: '100%',
  },
  projectFeedWrapper: {
    width: '100%',
  },

  container: {
    width: '100%',
    display: 'flex',
    marginTop: 10,
    flexDirection: 'column',
    borderRadius: '30px !important',
    '&:focus-within': {
      boxShadow: '0px 16px 70px -12.125px rgba(0, 0, 0, 0.3)',
    },
  },

  '@media (max-width: 600px)': {
    projectFeedWrapper: {
      width: '100%',
    },
    container: {
      marginTop: 5,
      borderRadius: '0 !important',
    },
  },
})

const ProjectFeedLeftSkeleton = ({ classes, isDarkModeActive }) => {
  return (
    <section className={classes.projectActivityFeed}>
      <article className={classes.projectActivityFeedContainer}>
        <div className={classes.projectFeedWrapper}>
          <div
            className={classes.container}
            style={{
              animation: 'skeleton 0.5s ease infinite alternate',
              background: 'rgba(147,165,194)',
            }}
          >
            <FreeBar
              height={50}
              width={'100%'}
              isDarkModeActive={isDarkModeActive}
            />
          </div>
          {Array.from({ length: 8 }, (_, index) => (
            <FeedSkeleton key={index} />
          ))}
        </div>
      </article>
    </section>
  )
}

const WrappedProjectFeedLeftSkeleton = withStyles(styles)(
  ProjectFeedLeftSkeleton
)

export default WrappedProjectFeedLeftSkeleton
