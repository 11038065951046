import React from 'react'
import TextPost from '../../../../postSystem/TextPost'
import HomePostBottom from '../../commonPostComponents/HomePostBottom'
import PostCenter from './PostCenter'

const SocialPostBottom = ({ postProps }) => {
  const {
    post,
    user,
    imageViewerDialogOpener,
    imageViewerDialogClose,
    isImageViewerDialogOpen,
    isDarkModeActive,
    setPosts,
  } = postProps
  return (
    <HomePostBottom
      postProps={{
        ...postProps,
        postBody: (
          <PostCenter
            postProps={{
              post,
              user,
              imageViewerDialogOpener,
              imageViewerDialogClose,
              isImageViewerDialogOpen,
              commentingSystemView: true,
              isDarkModeActive,
              setPosts,
            }}
          />
        ),
        postText: <TextPost {...postProps} noShadowedText={true} />,
      }}
    />
  )
}

export default SocialPostBottom
