import React from 'react'
import withStyles from '@mui/styles/withStyles'
import TextEngine from '../../../../../../../textProcessingEngine/TextEngine'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const styles = () => ({
  title: {
    borderRadius: '50%',
    fontWeight: 500,
    fontSize: 20,
    height: 70,
    width: 70,
    position: 'absolute',
    top: 5,
    background:
      'linear-gradient(75deg,rgb(76, 76, 110) 25%,rgb(40, 147, 177) 96%)',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  definition: {
    fontSize: 17,
    background:
      'linear-gradient(75deg, rgba(87, 172, 48, 0.877) 25%, rgb(44, 155, 185) 96%)',
    color: 'white',
    borderRadius: '10px 0',
    fontWeight: 500,
  },

  aspectDervied: {
    fontSize: 14,
    padding: 10,
    marginBottom: 10,
    marginTop: 10,
    borderRadius: 10,
    background: 'rgba(217,224,252, 0.1)',
    fontStyle: 'oblique',
  },
})

const AspectStructureUnitTopPC = ({ classes, ...props }) => {
  const { subAspect, DCGProps } = props
  const aspectDerived = (
    <TextEngine
      textContent={subAspect.aspectDerived || ''}
      {...DCGProps}
      notEngineProps={props}
    />
  )
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <div
        className={classes.title}
        style={{ left: !rtl && 30, right: rtl && 30 }}
      >
        {t(subAspect.title)}
      </div>
      <div
        className={classes.definition}
        style={{
          padding: rtl ? '10px 110px 10px 10px' : '10px 10px 10px 110px',
        }}
      >
        {subAspect.definition}
        <section
          className={classes.aspectDervied}
          style={{ display: subAspect.customModel ? 'none' : null }}
        >
          {aspectDerived}
        </section>
      </div>
    </>
  )
}

const WrapppedAspectStructureUnitTopPC = withStyles(styles)(
  AspectStructureUnitTopPC
)

export default WrapppedAspectStructureUnitTopPC
