import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import i18next from 'i18next'

const ModelProfileButtons = ({ DCGProps, hideModelButtons, model }) => {
  const { project } = DCGProps
  const [customModel, setCustomModel] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchCustomModel = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/projectsBusinessModels/${project?._id}`)
        setCustomModel(res.data.model)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (project?._id) fetchCustomModel()
  }, [project?._id])
  const [clicked, setClicked] = useState(
    customModel?.modelTempsIds?.includes(model?.id)
  )
  useEffect(() => {
    setClicked(customModel?.modelTempsIds?.includes(model?.id))
  }, [model?.id, customModel?.modelTempsIds])
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'أضف الى النماذج الفعالة' : 'Add to Active Templates',
      funs: async () => {
        if (!customModel?.modelTempsIds?.includes(model?.id)) {
          setClicked(true)
          try {
            await axios.patch(`/projectsBusinessModels/${project?._id}`, {
              modelTempsIds: [...customModel?.modelTempsIds, model?.id],
            })
          } catch (error) {
            console.log(error)
          }
        }
      },
      disabled: clicked,
    },
    {
      title: rtl ? 'أزل من النماذج الفعالة' : 'Remove from Active Templates',
      funs: async () => {
        if (customModel?.modelTempsIds?.includes(model?.id)) {
          setClicked(false)
          try {
            await axios.patch(`/projectsBusinessModels/${project?._id}`, {
              modelTempsIds: customModel?.modelTempsIds.filter(
                (id) => model?.id !== id
              ),
            })
          } catch (error) {
            console.log(error)
          }
        }
      },
      disabled: !clicked,
    },
  ]
  return (
    !isLoading && (
      <ButtonsSystem
        buttonsArray={buttonsArray}
        {...DCGProps}
        hide={hideModelButtons}
      />
    )
  )
}

export default ModelProfileButtons
