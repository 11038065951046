import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../context/AuthContext'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import InfoDataIntialStateGen from '../../../../../../formsSystem/InfoDataIntialStateGen'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'
import HashTagElementsRefiner from '../../../../../../smallComponents/HashTagElementsRefiner'
import CampaignConfigs from '../CampaignConfigs'
import i18next from 'i18next'

const CampaignDetails = ({
  campaign,
  DCGProps,
  setCards,
  setIsUpdated,
  depManager,
}) => {
  const { user } = useContext(AuthContext)
  const { socket, project } = DCGProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  const infoArray = CampaignConfigs().map((config, index) => ({
    title: config.title,
    titleValue: campaign[config.title.split('_').join('')] || '',
    allowEmpty: true,
    helpertext: config?.helpertext,
    wideMode: index !== 0,
    minRows: config?.minRows,
    placeholder: config?.placeholder,
    type: config?.formComponentType,
  }))

  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))
  const { t } = useTranslation()
  const newInfoData = FormDataLangModulator(infoData, t)

  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(campaign) || []
  )

  const onSave = async () => {
    if (campaign?._id) {
      try {
        const res = await axios.patch(`/campaigns/${campaign?._id}`, {
          ...newInfoData,
          chosenHashTagElements,
        })
        setCards((inqs) => [
          ...inqs.filter((p) => p._id !== campaign?._id),
          res.data.campaign,
        ])
        campaign?.campaignContributorsIds?.map((id) => {
          socket.current.emit('addNotifUser', id)
          NotificationCreator({
            associativity: 'campaign',
            relation: 'contributor',
            conditionIndex: 0,
            accompaniedData: {
              projectId: project?._id,
              elements: [
                {
                  id: res.data.campaign?._id,
                  hashTagType: res.data.campaign?.hashTagType,
                  title: res.data.campaign?.Title,
                },
              ],
            },
            category: 'contributors',
            userId: id,
            currentUser: user,
            socket,
          })
        })
      } catch (error) {
        console.log(error)
      }
      setIsUpdated(true)
    }
  }

  const gridProps = {
    hideEditingTools:
      user?._id !== depManager?._id && user?._id !== project?.userId,
    headerText: rtl ? 'تفاصيل الحملة' : 'Campaign Details',
    infoArray,
    setInfoData,
    infoData,
    onSave,
    setChosenHashTagElements,
    chosenHashTagElements,
    ...DCGProps,
    departmentId: 1,
  }
  return <EditableGridModel gridProps={gridProps} />
}

export default CampaignDetails
