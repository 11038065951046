import React, { useContext, useEffect, useState } from 'react'
import GlobalInquiryProfileTop from '../MyGlobalInquriesComponents/GlobalInquiryProfileComps/GlobalInquiryProfileTop'
import TextEngineToolsFooter from '../../../../../../smallComponents/TextEngineToolsFooter'
import GlobalInquiryRequests from './GlobalInquiryProfileComps/GlobalInquiryRequests'
import GlobalInquiryContributors from './GlobalInquiryProfileComps/GlobalInquiryContributors'
import GlobalIInquiryDetails from './GlobalInquiryProfileComps/GlobalIInquiryDetails'
import NavigatorGenerator from '../../../../../../navigatorCardsModel/NavigatorGenerator'
import GlobalInquiryInteractionButons from './GlobalInquiryProfileComps/GlobalInquiryInteractionButons'
import axios from 'axios'
import { AuthContext } from '../../../../../../../context/AuthContext'
import i18next from 'i18next'

const GlobalInquiryProfile = (props) => {
  const { globalInquiry, DCGProps } = props
  const { isDarkModeActive } = DCGProps
  const [sharedPosts, setSharedPosts] = useState([])
  const [channels, setChannels] = useState([])
  const { user } = useContext(AuthContext)
  useEffect(() => {
    const fetchUdeaPosts = async () => {
      try {
        const res = await axios.get(
          `/globalinquiries/globalInquiryPosts/${globalInquiry?._id}`
        )
        const resChannels = await axios.get(
          `/channels/elementChannels/${globalInquiry?._id}`
        )
        setSharedPosts(res.data.globalInquiryPosts)
        setChannels(resChannels.data.channels)
      } catch (error) {
        console.log(error)
      }
    }
    if (globalInquiry?._id) fetchUdeaPosts()
  }, [globalInquiry?._id])
  const lang = i18next.language
  const rtl = lang === 'ar'
  const navigatorsArray = [
    {
      headerText: rtl ? 'منشورات مُشاركة' : 'Shared Posts',
      unitsArray: sharedPosts,
      NavigatorCardsModelProps: {},
    },
    {
      headerText: rtl
        ? 'قنوات تشارك تستخدم هذه الرؤية'
        : 'Channels uses this Global Inquiry',
      unitsArray: channels,
      NavigatorCardsModelProps: {},
    },
  ]
  return (
    <article
      style={{
        color: isDarkModeActive ? 'white' : null,
        marginTop: 10,
        width: '100%',
      }}
    >
      <GlobalInquiryProfileTop {...props} />
      <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
      <GlobalIInquiryDetails {...props} />
      {user?._id === globalInquiry?.userId && (
        <GlobalInquiryRequests {...props} />
      )}
      <GlobalInquiryContributors {...props} />
      <GlobalInquiryInteractionButons {...props} />
      {user?._id === globalInquiry?.userId && (
        <TextEngineToolsFooter {...DCGProps} />
      )}
    </article>
  )
}

export default GlobalInquiryProfile
