import axios from 'axios'
import React, { useContext } from 'react'
import { AuthContext } from '../../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import NotificationCreator from '../../../../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const InquiryRequestDetails = ({
  card,
  DCGProps,
  exprops,
  setRequests,
  requestsUsers,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { inquiry } = exprops
  const { socket } = DCGProps
  const request = card
  const { user } = useContext(AuthContext)
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)
  const activeFun = async ({ response }) => {
    try {
      setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
      if (response === 'accept') {
        // this will add the user to the default channel
        const defaultChRes = await axios.patch(
          `/channels/channelsSystem/defaultChannel/${
            inquiry?._id || inquiry?.id
          }`,
          {
            contributorId: request?.userId,
          }
        )
        socket.current.emit('addNotifUser', request?.userId)
        NotificationCreator({
          associativity: 'inquiry',
          relation: 'inquiryReviewer',
          conditionIndex: 0,
          accompaniedData: {
            response,
            elements: [
              {
                hashTagType: inquiry?.hashTagType,
                id: inquiry?._id || inquiry?.id,
                title: inquiry?.Title || inquiry?.title,
              },
              {
                hashTagType: defaultChRes?.data?.channel?.hashTagType,
                id: defaultChRes?.data?.channel?._id,
                title: defaultChRes?.data?.channel?.Title,
              },
            ],
          },
          category: 'contributors',
          userId: request?.userId,
          currentUser: user,
          socket,
        })
        // will add the user to the contributors of the inquiry if the response is accept

        await axios.patch(`/inquiries/${inquiry?._id || inquiry?.id}`, {
          pairedUserId: request?.userId,
        })
      } else if (response === 'refuse') {
        socket.current.emit('addNotifUser', request?.userId)
        NotificationCreator({
          associativity: 'inquiry',
          relation: 'inquiryReviewer',
          conditionIndex: 0,
          accompaniedData: {
            response,
            elements: [
              {
                hashTagType: inquiry?.hashTagType,
                id: inquiry?._id || inquiry?.id,
                title: inquiry?.Title || inquiry?.title,
              },
            ],
          },
          category: 'contributors',
          userId: request?.userId,
          currentUser: user,
          socket,
        })
      }
      // request will be deleted after being responded to
      await axios.delete(`/requests/${request?._id}`)
    } catch (error) {
      console.log(error)
    }
  }
  const deleteFun = async () => {
    try {
      await axios.delete(`/requests/${request?._id}`)
      setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
    } catch (error) {
      console.log(error)
    }
  }
  const buttonsArray =
    request?.requestStatus === 'responded'
      ? [
          {
            title: rtl ? 'حذف' : 'Delete',
            funs: () => deleteFun(),
          },
        ]
      : request?.subgenre === 'start'
      ? [
          {
            title: rtl ? 'قبول' : 'Accept',
            funs: () => {
              activeFun({ response: 'accept' })
              toast.success(
                rtl
                  ? `تم قبول ${requestingUser?.firstName}`
                  : `${requestingUser?.firstName} has been accepted`
              )
            },
          },
          {
            title: rtl ? 'رفض' : 'Refuse',
            funs: () => {
              activeFun({ response: 'refuse' })
              toast.info(rtl ? 'تم ارسال الرفض' : 'Refusal has been sent')
            },
          },
          {
            title: rtl ? 'حذف' : 'Delete',
            funs: () => {
              deleteFun()
              toast.warning(
                rtl
                  ? 'تم مسح الطلب بنجاح'
                  : 'Request has been deleted successfully'
              )
            },
          },
        ]
      : [
          {
            title: rtl ? 'حذف' : 'Delete',
            funs: () => {
              deleteFun()
              toast.warning(
                rtl
                  ? 'تم مسح الطلب بنجاح'
                  : 'Request has been deleted successfully'
              )
            },
          },
        ]
  return (
    <>
      <section>
        {requestingUser?.firstName + ' ' + requestingUser?.lastName}{' '}
        {rtl ? (request?.subgenre === 'start' ? 'يعرض' : 'يسأل') : 'Asks for'}
        {request?.subgenre === 'start'
          ? rtl
            ? ' القيام بهذا المتطلب'
            : ' working on your inquiry'
          : rtl
          ? ' عن تفاصيل أكثر، يمكنك محادثته!'
          : ' more Declaration about the inquiry, you may chat with him!'}
      </section>
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </>
  )
}

export default InquiryRequestDetails
