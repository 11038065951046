import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'

const BusinessModelPDFBody = ({ button }) => {
  const { model } = button.PDFGenProps
  const configs = [
    {
      title: 'Customers',
      titleValue: model?.Customers,
      disableTrans: true,
    },
    {
      title: 'Stakeholder_Group',
      titleValue: model?.StakeholderGroup,
      disableTrans: true,
    },
    {
      title: 'Distribution_Channels',
      titleValue: model?.DistributionChannels,
      disableTrans: true,
    },
    {
      title: 'Customer_Segments',
      titleValue: model?.CustomerSegments,
      disableTrans: true,
    },
    {
      title: 'Value_Proposition',
      titleValue: model?.ValueProposition,
      disableTrans: true,
    },
    {
      title: 'Internal_Resources',
      titleValue: model?.InternalResources,
      disableTrans: true,
    },
    {
      title: 'Activities_and_Competencies',
      titleValue: model?.ActivitiesandCompetencies,
      disableTrans: true,
    },
    {
      title: 'Partners',
      titleValue: model?.Partners,
      disableTrans: true,
    },
    {
      title: 'Cost_Drivers',
      titleValue: model?.CostDrivers,
      disableTrans: true,
    },
    {
      title: 'Revenue_Streams',
      titleValue: model?.RevenueStreams,
      disableTrans: true,
    },
  ]
  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
      }}
      button={button}
    />
  )
}

export default BusinessModelPDFBody
