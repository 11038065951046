import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  bookContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'row',
  },
  BookLeft: {
    display: 'flex',
    flex: '1.5',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  BookRight: {
    display: 'flex',
    flex: '2',
    flexDirection: 'column',
    overflowY: 'auto',
  },

  BookRightWrapper: {
    display: 'flex',
    padding: '2px 10px 10px 10px',
    flexDirection: 'column',
  },
})

const BookDialogContentPCView = ({
  classes,
  bookLeft,
  bookRight,
  isDarkModeActive,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article
      className={classes.bookContainer}
      style={{
        color: isDarkModeActive && 'white',
      }}
    >
      <section
        className={classes.BookLeft}
        style={{ marginLeft: !rtl && 10, marginRight: rtl && 10 }}
      >
        {bookLeft}
      </section>
      <section className={classes.BookRight} id={'pc-scroll-right'}>
        <div className={classes.BookRightWrapper}>{bookRight}</div>
      </section>
    </article>
  )
}

const WrappedBookDialogContentPCView = withStyles(styles)(
  BookDialogContentPCView
)

export default WrappedBookDialogContentPCView
