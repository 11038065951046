import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const MultiLevelMarketingBusinessModel = () => {
  const { t } = useTranslation('MultiLevelMarketingBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 73,
    title: t('title_bm73'),
    subTitle: t('subTitle_bm73'),
    main: t('main_bm73'),
    when: t('when_bm73'),
    who: t('who_bm73'),
    what: t('what_bm73'),
    how: t('how_bm73'),
    why: t('why_bm73'),
    examples: rtl
      ? [
          'امواى - 1959 - الولايات المتحدة الأمريكية',
          'ماري كاي - 1963 - الولايات المتحدة الأمريكية',
          'تابروير - 1946 - الولايات المتحدة الأمريكية',
          'رودان + فيلدز - 2002 - الولايات المتحدة الأمريكية',
          'ناتورا وشركاه - 2010 - البرازيل',
          'هرباليفي - 1980 - الولايات المتحدة الأمريكية',
          'أفون - 1886 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Amway - 1959 - USA',
          'Mary Kay - 1963 - USA',
          'Tupperware - 1946 - USA',
          'Rodan + Fields - 2002 - USA',
          'Natura & Co. - 2010 - Brazil',
          'Herbalife - 1980 - USA',
          'Avon - 1886 - USA',
        ],
    tags: rtl
      ? [
          'المبيعات الهرمية',
          'مبيعات مباشرة',
          'فرق التوظيف',
          'قائم على العمولة',
          'عمل مستقل',
          'شراء بالجملة',
          'مكافآت الأداء',
        ]
      : [
          'Hierarchical Sales',
          'Direct Sales',
          'Recruitment Teams',
          'Commission-Based',
          'Independent Business',
          'Wholesale Purchase',
          'Performance Bonuses',
        ],
    considerations: t('considerations_bm73'),
    combinations: ['$LBM22', '$LBM10', '$LBM62'],
    hashTagType: 'businessModelTemp',
  }
}

export default MultiLevelMarketingBusinessModel
