import React from 'react'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const GlobalInquiryOfferReply = (props) => {
  const { sender, notification } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const response = notification?.accompaniedData?.response
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            response === 'refuse'
              ? rtl
                ? `قام ${sender?.name} برفض طلبك مشاركتك فى تطوير فكرته/فكرتها`
                : `${sender?.name} has refused your request for developing his(her) idea`
              : rtl
              ? `قام ${sender?.name}  بالمولفقة علي طلب مشاركتك لفكرته/فكرتها و قام بإضافتك الى قناة التشارك `
              : `${sender?.name} accepted your contribution request to his(her) idea.`
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default GlobalInquiryOfferReply
