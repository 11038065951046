import React from 'react'
import Media from 'react-media'
import ModelDialogWrappingIIMob from './ModelDialogWrappingIIComponents/ModelDialogWrappingIIMob'
import ModelDialogWrappingIIPC from './ModelDialogWrappingIIComponents/ModelDialogWrappingIIPC'

const ModelDialogWrappingII = (props) => {
  const mediaComponents = [
    <ModelDialogWrappingIIPC {...props} />,
    <ModelDialogWrappingIIMob {...props} />,
  ]

  return (
    <Media query='(max-width: 1000px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  )
}

export default ModelDialogWrappingII
