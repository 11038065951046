import React from 'react'
import FeedPostTopModel from '../../feedsSystem/FeedPostTopModel'
import HomePostBottom from '../../homeFeed/homeFeedComponents/commonPostComponents/HomePostBottom'
import RoomSMDisBottom from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/RoomsComponents/RoomStructureModelComponents/RoomSMFeedComponents/RoomPostModelComps/RoomSMDisBottom'
import RoomSMDisTop from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/RoomsComponents/RoomStructureModelComponents/RoomSMFeedComponents/RoomPostModelComps/RoomSMDisTop'
import ChannelProfileFeedSMDisBottom from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectPartnershipsComponents/ProjectPartnershipsDialogContentComps/ProjectPartnershipsContentArraysComps/ProjectChannelsComps/ProjectPartnershipChannelsComps/PartnershipChaneelProfileComps/ChannelProfileFeedComps/ChannelPostModelComps/ChannelProfileFeedSMDisBottom'
import ChannelProfileFeedSMDisTop from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectPartnershipsComponents/ProjectPartnershipsDialogContentComps/ProjectPartnershipsContentArraysComps/ProjectChannelsComps/ProjectPartnershipChannelsComps/PartnershipChaneelProfileComps/ChannelProfileFeedComps/ChannelPostModelComps/ChannelProfileFeedSMDisTop'

const CommentsDialogCore = ({ commentProps }) => {
  const {
    postText,
    post,
    user,
    setIsCommentIconClicked,
    isCommentIconClicked,
    isDarkModeActive,
    project: inProject,
    posts,
    socket,
    sharedPosts,
    sharedPostsUsers,
    postsUsers,
    setPosts,
  } = commentProps
  const project = inProject || (user?.ProjectName && user)
  return (
    <>
      {post?.feedsSystemCategory === 'room' ? (
        <RoomSMDisTop
          postProps={{
            hideRightOptions: true,
            post,
            project,
            isDarkModeActive,
            user,
            posts,
            postsUsers,
            setPosts,
          }}
        />
      ) : post?.feedsSystemCategory === 'channel' ? (
        <ChannelProfileFeedSMDisTop
          postProps={{
            hideRightOptions: true,
            post,
            project,
            isDarkModeActive,
            user,
            posts,
            postsUsers,
            setPosts,
          }}
        />
      ) : (
        <FeedPostTopModel
          postProps={{
            post,
            isDarkModeActive,
            hideRightOptions: true,
            user,
            posts,
            project,
            setPosts,
          }}
        />
      )}
      {postText}
      {post?.feedsSystemCategory === 'room' ? (
        <RoomSMDisBottom {...commentProps} />
      ) : post?.feedsSystemCategory === 'channel' ? (
        <ChannelProfileFeedSMDisBottom
          {...commentProps}
          DCGProps={{ socket }}
        />
      ) : (
        <HomePostBottom
          postProps={{
            post,
            user,
            commentSystemCommentIcon: true,
            setIsCommentIconClicked,
            isCommentIconClicked,
            project,
            isDarkModeActive,
            posts,
            socket,
            sharedPosts,
            sharedPostsUsers,
            setPosts,
          }}
        />
      )}
    </>
  )
}

export default CommentsDialogCore
