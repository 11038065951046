import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const ForecastingTomorrow = () => {
  const { t } = useTranslation('ForecastingTomorrow')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 10,
    category: t('category_ms10'),
    title: t('title_ms10'),
    subTitle: t('subTitle_ms10'),
    main: t('main_ms10'),
    how: t('how_ms10'),
    usedInModels: [],
    tags: rtl
      ? [
          'توقع العملاء',
          'التنبؤ بالحكم',
          'التنبؤ بالسلاسل الزمنية',
          'التنبؤ السببي',
          'متغيرات السوق',
          'معالجة البيانات',
        ]
      : [
          'Forecasting Customer',
          'Judgemental Forecasting',
          'Time Series Forecasting',
          'Causal Forecasting',
          'Market Variables',
          'Data Processing',
        ],
    examples: t('examples_ms10'),
    hashTagType: 'markStraAspect',
  }
}

export default ForecastingTomorrow
