import {
  Barriers,
  BusinessDefinition,
  BusinessPolarity,
  BuyerPower,
  Choice,
  CustomerBehaviour,
  MarketingRevenues,
  CustomerDescription,
  CustomerHistory,
  CustomerMigration,
  CustomerMotivation,
  CustomerPerception,
  CustomerSatisfaction,
  ForecastingTomorrow,
  LatentNeeds,
  NewEntrants,
  Relationships,
  Substitutes,
  SupplierPower,
  ValueMigration,
  MarketingDifferentSegments,
  SegmentationEffects,
  SegmentPriotirization,
  TheSegmentOfOne,
  DirectSaleSegments,
  SegmentLockOut,
  SegmentMigration,
  CommoditySlide,
  Differentiation,
  FuturePositions,
  Positioning,
  BrandBreaking,
  BrandCosts,
  Branding,
  BrandLeadership,
  BrandSecrets,
  BrandsManagment,
  CustomerCatchingAndKeeping,
  RetentionAsLoyalty,
  ShareholdersvsCustomerRetention,
} from './'
import MarketingInformationSystems from './MarketingStrategyAspectsConfigsComps/MarketingInformationSystems'

const MarketingStrategyAspectsConfigs = () => {
  return [
    CustomerDescription(),
    CustomerHistory(),
    MarketingRevenues(),
    CustomerPerception(),
    CustomerMigration(), //need arabic revision
    CustomerMotivation(),
    CustomerBehaviour(),
    CustomerSatisfaction(),
    LatentNeeds(), //need arabic revision
    ForecastingTomorrow(),
    MarketingInformationSystems(),
    Barriers(),
    CustomerCatchingAndKeeping(),
    Choice(),
    // Final Revision ------ DONE -----
    Relationships(),
    BusinessPolarity(),
    BusinessDefinition(),
    ValueMigration(),
    BuyerPower(),
    SupplierPower(),
    NewEntrants(),
    Substitutes(),
    TheSegmentOfOne(),
    MarketingDifferentSegments(),
    SegmentPriotirization(),
    ShareholdersvsCustomerRetention(),
    BrandsManagment(),
    SegmentationEffects(),
    SegmentMigration(),
    SegmentLockOut(),
    DirectSaleSegments(),
    Positioning(),
    CommoditySlide(),
    Differentiation(),
    FuturePositions(),
    Branding(),
    BrandSecrets(),
    BrandLeadership(),
    BrandBreaking(),
    RetentionAsLoyalty(),
    BrandCosts(),
  ]
}

export default MarketingStrategyAspectsConfigs
