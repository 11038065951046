import { useState } from 'react'

const WindowProps = () => {
  const [isWindowOpen, setIsWindowOpen] = useState(false)

  const windowOpener = () => {
    setIsWindowOpen(true)
  }

  const windowCloser = () => {
    setIsWindowOpen(false)
  }
  return {
    isWindowOpen,
    windowOpener,
    windowCloser,
  }
}

export default WindowProps
