import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const NewProjectContributionOfferCover = ({
  notification,
  sender,
  project,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `يعرض عليك ${sender?.name} المشاركة فى مشروعه`
    : `${sender?.name} offers you a contribution in ${project?.name} project`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default NewProjectContributionOfferCover
