import React from 'react'
import {
  SubDepPromotionNotif,
  ProjectDepromotion,
  DepartmentPromotionNotif,
} from '../../'

const PromotionDisplyModel = ({ notifProps }) => {
  const { notification, isDarkModeActive } = notifProps
  const mssgs = [
    {
      relation: 'promoted',
      comp: [
        <SubDepPromotionNotif {...notifProps} />,
        <DepartmentPromotionNotif {...notifProps} />,
        <ProjectDepromotion {...notifProps} />,
      ],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default PromotionDisplyModel
