import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import SideButton from '../../suggestionsSystem/SuggestionSystemComponents/SideButton'
import NavigatorCardsGrid from './NavigatorCardsGrid'
import i18next from 'i18next'

const styles = () => ({
  suggestCardWrapper: {
    borderRadius: '20px',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    maxWidth: '100%',
    overflowX: 'hidden',
  },
})

const NavigatorCardsPC = ({
  classes,
  isDarkModeActive,
  units,
  unitsArray,
  isDialogFullWidth,
}) => {
  const [unitId, setUnitId] = useState(0)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <section
      className={classes.suggestCardWrapper}
      style={{
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <section>
        <SideButton
          shiftFun={() => setUnitId(unitId - 1 >= 0 ? unitId - 1 : unitId)}
          shiftIcon={
            rtl ? (
              <MdArrowForwardIos
                style={{
                  color: unitId === 0 ? 'rgb(210, 221, 240)' : 'white',
                }}
              />
            ) : (
              <MdArrowBackIosNew
                style={{
                  color: unitId === 0 ? 'rgb(210, 221, 240)' : 'white',
                }}
              />
            )
          }
          iconButtonEdge='start'
        />
      </section>
      <NavigatorCardsGrid
        units={units}
        unitId={unitId}
        isDialogFullWidth={isDialogFullWidth}
      />
      <section>
        <SideButton
          shiftFun={() =>
            setUnitId(
              unitId + 1 < unitsArray.length - (isDialogFullWidth ? 2 : 1)
                ? unitId + 1
                : unitId
            )
          }
          shiftIcon={
            rtl ? (
              <MdArrowBackIosNew
                style={{
                  color:
                    unitId ===
                      unitsArray.length - (isDialogFullWidth ? 3 : 2) ||
                    unitsArray?.length <= (isDialogFullWidth ? 3 : 2)
                      ? 'rgb(210, 221, 240)'
                      : 'white',
                }}
              />
            ) : (
              <MdArrowForwardIos
                style={{
                  color:
                    unitId ===
                      unitsArray.length - (isDialogFullWidth ? 3 : 2) ||
                    unitsArray?.length <= (isDialogFullWidth ? 3 : 2)
                      ? 'rgb(210, 221, 240)'
                      : 'white',
                }}
              />
            )
          }
          iconButtonEdge='end'
        />
      </section>
    </section>
  )
}

const WrappedNavigatorCardsPC = withStyles(styles)(NavigatorCardsPC)

export default WrappedNavigatorCardsPC
