import i18next from 'i18next'
import millify from 'millify'
import { convertNumbers2Arabic } from 'to-arabic-numbers'

const MultiMillify = (number) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const engNumber = millify(number)
  if (number >= 999) {
    let onlyNumber = convertNumbers2Arabic(
      engNumber.substring(0, engNumber.length - 1)
    )
    let power = engNumber.charAt(engNumber.length - 1)
    let numberPower =
      power === 'K'
        ? number / 1000 > 2.1 &&
          number / 1000 < 11 &&
          String(number).charAt(1) === '0'
          ? 'آلاف'
          : 'الف'
        : 'M'
        ? number / 1000000 > 2.1 &&
          number / 1000000 < 11 &&
          String(number).charAt(1) === '0'
          ? 'ملايين'
          : 'مليون'
        : 'B'
        ? number / 1000000000 > 2.1 &&
          number / 1000000000 < 11 &&
          String(number).charAt(1) === '0'
          ? 'مليارات'
          : 'مليار'
        : null
    const arabicNumber = onlyNumber + ' ' + numberPower
    return rtl ? arabicNumber : engNumber
  } else {
    const arabicNumber = convertNumbers2Arabic(`${number}`)
    return rtl ? arabicNumber : engNumber
  }
}

export default MultiMillify
