import React from 'react'
import JobNewRequest from './JobNotificationDisplayModelComps/JobNewRequest'
import JobRequestReply from './JobNotificationDisplayModelComps/JobRequestReply'

const JobNotificationDisplayModel = ({ notifProps }) => {
  const { isDarkModeActive, notification } = notifProps
  const job = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'job'
  )
  const notifPropsII = {
    ...notifProps,
    job,
  }
  const mssgs = [
    {
      relation: 'creator',
      comp: [<JobNewRequest {...notifPropsII} />],
    },
    {
      relation: 'visitor',
      comp: [<JobRequestReply {...notifPropsII} />],
    },
  ]
  return (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      {
        mssgs.filter((mssg) => mssg.relation === notification.relation)[0].comp[
          notification.conditionIndex
        ]
      }
    </article>
  )
}

export default JobNotificationDisplayModel
