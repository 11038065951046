import axios from 'axios'
import CustomNewElementRequestNotifDeletor from './CustomNewElementRequestNotifDeletor'

const NotificationCreator = ({
  associativity,
  relation,
  conditionIndex,
  accompaniedData,
  category,
  userId,
  currentUser,
  socket,
  CRNDelete,
  element,
}) => {
  const senderId = currentUser?._id
  const newNotif = {
    associativity,
    relation,
    conditionIndex,
    accompaniedData: {
      ...accompaniedData,
      senderId,
    },
    category,
    userId,
  }

  const updateDB = async () => {
    if (CRNDelete)
      CustomNewElementRequestNotifDeletor({
        element,
        associativity,
        relation,
        category,
        conditionIndex,
      })
    if (userId && senderId) {
      socket.current.emit('sendNotification', {
        senderId,
        receiverId: userId,
        newNotif,
      })
      try {
        await axios.post('/notifications', newNotif)
      } catch (error) {
        console.log(error)
      }
    }
  }
  if (userId !== senderId) updateDB()
}

export default NotificationCreator
