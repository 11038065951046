import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import GPDFDList, {
  Item,
} from '../../../PDFTemplatesComps/GeneralPDFDocumentComps/GPDFDList'
import GPDFDTitle from '../../../PDFTemplatesComps/GeneralPDFDocumentComps/GPDFDTitle'
import GPDFTextHashModifier from '../../GeneralPDFDocumentComps/GPDFTextHashModifier'
import i18next from 'i18next'

const lang = i18next.language
const rtl = lang === 'ar'
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: rtl ? 0 : 15,
    paddingRight: rtl ? 15 : 0,
    '@media max-width: 400': {
      paddingTop: 10,
    },
  },
  entryContainer: {
    marginBottom: 10,
  },
  date: {
    fontSize: 11,
    fontFamily: 'Lato Italic',
  },
  detailContainer: {
    flexDirection: 'row',
    fontFamily: 'Roboto',
  },
  detailLeftColumn: {
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
  },
  detailRightColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  bulletPoint: {
    fontSize: 10,
  },
  details: {
    fontSize: 10,
    fontFamily: 'Lato',
  },
  headerContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    fontFamily: 'Roboto',
  },
  rightColumn: {
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-end',
    justifySelf: 'flex-end',
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Roboto',
  },
})

const ContractElement = ({ element }) => {
  const date = new Date(element?.createdAt).toLocaleString()
  return (
    <View style={styles.entryContainer}>
      <View style={styles.headerContainer}>
        <View style={styles.leftColumn}>
          <Text style={styles.title}>{element?.Title}</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.date}> {date}</Text>
        </View>
      </View>
      <GPDFDList>
        <Item style={styles.detailContainer}>
          {GPDFTextHashModifier({
            button: { PDFGenProps: { element } },
            text: element?.agreement,
          })}
        </Item>
      </GPDFDList>
    </View>
  )
}

const ContractPDFElements = ({ elements }) => (
  <View style={styles.container}>
    <GPDFDTitle>{lang === 'ar' ? 'المحتوى' : 'Content'}</GPDFDTitle>
    {elements.map((element, index) => (
      <ContractElement element={element} key={index} />
    ))}
  </View>
)
export default ContractPDFElements
