import React, { useContext } from 'react'
import GalleryInfoProfileTopModel from '../../GalleryInfoProfileTopModel'
import axios from 'axios'
import { Switch } from '@mui/material'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import DefaultChannelButton from '../../../../../../../channelsSystem/DefaultChannelButton'
import i18next from 'i18next'

const IdeaProfileTop = ({
  idea,
  DCGProps,
  setCards,
  handleChange,
  checked,
}) => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const onGetOut = async () => {
    try {
      await axios.patch(`/ideas/${idea?._id}`, {
        permissionedUserIds: idea?.permissionedUserIds?.filter(
          (u) => u !== user?._id
        ),
        ideaContributorsIds: idea?.ideaContributorsIds?.filter(
          (u) => u !== user?._id
        ),
      })
    } catch (error) {
      console.log(error)
    }
  }
  const galleryProps = {
    hideEdit: user?._id !== idea?.userId,
    element: idea,
    DCGProps,
    emptyGalleryTitle: rtl ? 'هذه الفكرة' : 'Idea',
    pathName: `/users/${idea?.userId}/ideas`,
    setElements: setCards,
    patchPath: `ideas`,
    updateElementImages: async (image) =>
      await axios.patch(`/ideas/${idea?._id}`, {
        images: idea?.images?.filter((im) => im === image),
      }),
    CustomTopContent: (
      <>
        <div
          style={{
            display: user?._id !== idea?.userId ? 'none' : 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            alignContent: 'center',
          }}
        >
          <div style={{ marginTop: '3px' }}>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
          <section style={{ fontSize: 15 }}>
            {checked
              ? rtl
                ? 'مُؤمّن'
                : 'Secured'
              : rtl
              ? 'غير مُؤمّن'
              : 'Open'}
          </section>
        </div>
        <div
          style={{
            marginTop: 10,
            display:
              idea?.ideaContributorsIds?.includes(user?._id) ||
              idea?.userId === user?._id
                ? null
                : 'none',
          }}
        >
          <DefaultChannelButton
            element={idea}
            DCGProps={DCGProps}
            onGetOut={onGetOut}
          />
        </div>
      </>
    ),
  }
  return <GalleryInfoProfileTopModel galleryProps={galleryProps} />
}

export default IdeaProfileTop
