import React from 'react'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import NotificationViewModel from '../../../../NotificationViewModel'
import i18next from 'i18next'

const ContributionContractEndingReply = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        rtl
          ? `وافق ${sender?.name} على إنهاء التشارك`
          : `${sender?.name} agreed on ending contribution.`
      }
      buttonsArray={[]}
    />
  )
}

export default ContributionContractEndingReply
