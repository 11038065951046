import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const AnynoumousMarket = () => {
  const { t } = useTranslation('AnynoumousMarket')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 6,
    title: t('title_bm06'),
    subTitle: t('subTitle_bm06'),
    main: t('main_bm06'),
    when: t('when_bm06'),
    who: t('who_bm06'),
    what: t('what_bm06'),
    how: t('how_bm06'),
    why: t('why_bm06'),
    examples: rtl ? ['MyHammer - تأسست عام 2005'] : ['MyHammer - Founded 2005'],
    tags: rtl
      ? [
          'عميل مخلص',
          'أي خدمات',
          'نشط دائمًا',
          'منتجات مختلفة',
          'السوق',
          'إضفاء الطابع الشخصي',
          'تخصص',
        ]
      : [
          'Loyal Customer',
          'Any Services',
          'Always Active',
          'Various Products',
          'Marketplace',
          'Personalization',
          'Specialization',
        ],
    considerations: t('considerations_bm06'),
    combinations: ['$LBM2', '$LBM62'],
    hashTagType: 'businessModelTemp',
  }
}

export default AnynoumousMarket
