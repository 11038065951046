import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import UsePathnameEffect from '../../smallComponents/UsePathnameEffect'
import ExternalLinkSharing from '../../fileSystem/NoFileSystemPanelComponents/ExternalLinkSharing'

const Activation = (props) => {
  const {
    dialogWidth,
    dialogTitle,
    dialogIcon,
    mdialogMargins,
    isDarkModeActive,
    element,
    linkShareElement,
  } = props
  UsePathnameEffect(element.closeFun)
  return (
    <Dialog
      disableScrollLock
      fullScreen
      open={element.open}
      disableEnforceFocus
      PaperProps={{
        style: {
          overflowX: mdialogMargins ? 'hidden' : 'inherit',
          background: isDarkModeActive ? 'rgb(49, 48, 53)' : 'white',
        },
      }}
    >
      {/* padding 0 creates scrolling in x dir, but its necessary for chat dialog */}
      <DialogTitle sx={{ color: isDarkModeActive ? 'white' : 'inherit' }}>
        <section
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'center',
            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
          }}
        >
          {linkShareElement && (
            <ExternalLinkSharing
              noFSPanelProps={{
                element: linkShareElement,
                showExternalLinkShare: linkShareElement,
                isDarkModeActive,
              }}
            />
          )}
          {dialogTitle}
          {dialogIcon}
        </section>
      </DialogTitle>
      <DialogContent
        style={{ padding: dialogWidth ? null : 0, margin: mdialogMargins }}
      >
        {element.content}
      </DialogContent>
    </Dialog>
  )
}

const ModelDialogWrappingIIIMob = (props) => {
  const { dialogsArray } = props
  return dialogsArray.map((element, elemntIdex) => {
    return (
      element.open && (
        <Activation {...props} key={elemntIdex} element={element} />
      )
    )
  })
}

export default ModelDialogWrappingIIIMob
