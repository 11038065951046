import React from 'react'
import NoFileSystemPanel from '../../NoFileSystemPanel'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  modelWrapper: {
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    // alignContent: 'center', 
  },
})

const MFSInsideModel = ({
  classes,
  MFSGProps,
  component,
  noFSPanelPropsSecondView,
  searchSystemProps,
  folderPathProps,
  modelIProps,
}) => {
  const { CardProfileComponent, setCardId } = MFSGProps

  const { profileProps } = modelIProps

  return (
    <article className={classes.modelWrapper}>
      <NoFileSystemPanel noFSPanelProps={noFSPanelPropsSecondView} />
      <CardProfileComponent
        component={component}
        searchSystemProps={searchSystemProps}
        MFSGProps={MFSGProps}
        folderPathProps={folderPathProps}
        profileProps={profileProps}
      />
    </article>
  )
}

const WrappedMFSInsideModel = withStyles(styles)(MFSInsideModel)

export default WrappedMFSInsideModel
