import React from 'react'
import withStyles from '@mui/styles/withStyles'
import Media from 'react-media'
import TextEngine from '../../../../../../textProcessingEngine/TextEngine'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const styles = () => ({
  container: {
    margin: 10,
  },
  title: {
    borderRadius: '50%',
    fontWeight: 500,
    fontSize: 18,
    height: 100,
    width: 100,
    position: 'absolute',
    top: 3,
    background:
      'linear-gradient(75deg,rgb(76, 76, 110) 25%,rgb(40, 147, 177) 96%)',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    border: 'solid 1px white',
  },
  topWrapper: {
    position: 'relative',
    with: '100%',
    minHeight: 70,
    paddingTop: 20,
  },
  definition: {
    fontSize: 17,
    background:
      'linear-gradient(75deg, rgba(87, 172, 48, 0.877) 25%, rgb(44, 155, 185) 96%)',
    color: 'white',
    borderRadius: '10px 0',
    fontWeight: 500,
  },
  aspectDervied: {
    fontSize: 15,
    padding: 10,
    marginBottom: 10,
    marginTop: 10,
    borderRadius: 10,
    background: 'rgba(217,224,252, 0.1)',
    fontStyle: 'oblique',
  },

  mdefinition: {
    fontSize: 17,
    color: 'white',
    borderRadius: '10px 0',
    fontWeight: 500,
  },
  '@media (max-width: 600px)': {
    container: {
      margin: 0,
    },
    aspectDervied: {
      margin: '35px 10px 10px 10px',
    },
  },
})

const MarketingAspectCore = ({ classes, aspect, DCGProps }) => {
  const content = (
    <TextEngine
      textContent={aspect?.main || ''}
      {...DCGProps}
      notEngineProps={{}}
    />
  )
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const mediaComponents = [
    // pc
    <>
      <div
        className={classes.title}
        style={{ left: !rtl && 30, right: rtl && 30 }}
      >
        {t('Discussion')}
      </div>
      <div
        className={classes.definition}
        style={{
          padding: rtl ? '10px 140px 10px 10px' : '10px 10px 10px 140px',
        }}
      >
        {aspect?.subTitle}
        <div
          className={classes.aspectDervied}
          style={{ display: aspect.customModel ? 'none' : null }}
        >
          {content}
        </div>
      </div>
    </>,
    // mob
    <section
      style={{
        background:
          'linear-gradient(75deg, rgba(87, 172, 48, 0.877) 25%, rgb(44, 155, 185) 96%)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px 0',
        color: 'white',
      }}
    >
      <div
        className={classes.title}
        style={{ left: !rtl && 30, right: rtl && 30 }}
      >
        {t('Discussion')}
      </div>
      <div
        className={classes.mdefinition}
        style={{
          padding: rtl ? '10px 140px 10px 10px' : '10px 10px 10px 140px',
        }}
      >
        {aspect?.subTitle}
      </div>
      <div
        className={classes.aspectDervied}
        style={{ display: aspect.customModel ? 'none' : null }}
      >
        {content}
      </div>
    </section>,
  ]
  return (
    <article className={classes.container}>
      <section className={classes.topWrapper}>
        <Media query='(max-width: 600px)'>
          {(matches) => {
            return matches ? mediaComponents[1] : mediaComponents[0]
          }}
        </Media>
      </section>
    </article>
  )
}

const WrappedMarketingAspectCore = withStyles(styles)(MarketingAspectCore)

export default WrappedMarketingAspectCore
