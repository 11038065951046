import axios from 'axios'
import i18next from 'i18next'
import cookies from 'js-cookie'
import { useContext, useEffect, useState } from 'react'
import { SettingsContext } from '../context/SettingsContext/SettingsContext'
import { AuthContext } from '../context/AuthContext'

const PageDirection = () => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const { settings: contSet } = useContext(SettingsContext)
  const cookieLang = cookies.get('i18next')
  const [settings, setSettings] = useState(contSet)
  
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await axios.get(`/userSettings/${user?._id}`)
        setSettings(res.data.settings)
      } catch (error) {
        console.log(error)
      }
    }
    if (user?._id) fetchSettings()
  }, [user?._id])
  const langCode = settings && settings?.Language?.toLowerCase()?.slice(0, 2)

  useEffect(() => {
    if (
      cookieLang === 'ar' ||
      lang === 'ar' ||
      settings?.Language === 'Arabic'
    ) {
      document.body.dir = 'rtl'
    }
    if (langCode) cookies.set('i18next', langCode, {
      domain: '.hutupia.com',
      expires: 7,
      secure: true,
    })
  }, [cookieLang, lang, settings?.Language, langCode])
}

export default PageDirection
