import ChosenElementPropertyName from './ChosenElementPropertyName'
import TextSymbolPortion from './TextSymbolPortion'

// pushing the chosen elements and their idnetifiers to the feed so i can further process them and add link and other properties

const HashTagChosenElementPusher = ({
  chosenHashtagComp,
  setChosenHashTagElements,
  lastHashTagSymbol,
  chosenHashTagElements,
}) => {
  const textSymbolPortion = TextSymbolPortion({
    lastHashTagSymbol,
    chosenHashtagComp,
  })
  const chosenElementPropertyName = ChosenElementPropertyName({
    lastHashTagSymbol,
    chosenHashtagComp,
  })
  return (
    typeof chosenHashtagComp === 'object' &&
    typeof setChosenHashTagElements === 'function' &&
    setChosenHashTagElements([
      ...chosenHashTagElements,
      {
        identifier: `${
          textSymbolPortion +
          chosenHashtagComp[chosenElementPropertyName].split(' ').join('')
        }`,
        mentionElement: chosenHashtagComp,
      },
    ])
  )
}

export default HashTagChosenElementPusher
