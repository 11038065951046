import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 10,
    padding: 10,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const RoomSMShiftButtons = ({ setPage, page, currentResponse, classes }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.button}
        disabled={page === 1}
        onClick={() => setPage((prev) => prev - 1)}
      >
        {rtl ? 'النقاشات السابقة' : 'Previous Discussions'}
      </Button>
      <Button
        className={classes.button}
        disabled={!currentResponse}
        onClick={() => setPage((prev) => prev + 1)}
      >
        {rtl ? 'النقاشات التالية' : 'Next Discussions'}
      </Button>
    </div>
  )
}

const WrappedRoomSMShiftButtons = withStyles(styles)(RoomSMShiftButtons)

export default WrappedRoomSMShiftButtons
