import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const MassCustomisationBusinessModel = () => {
  const { t } = useTranslation('MassCustomisationBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 34,
    title: t('title_bm34'),
    subTitle: t('subTitle_bm34'),
    main: t('main_bm34'),
    when: t('when_bm34'),
    who: t('who_bm34'),
    what: t('what_bm34'),
    how: t('how_bm34'),
    why: t('why_bm34'),
    examples: rtl
      ? [
          'Dell - 1984 - الولايات المتحدة الأمريكية',
          'ليفي - 1853 - الولايات المتحدة الأمريكية',
          'Adidas - 1949 - ألمانيا',
          'PersonalNOVEL - 2010 - ألمانيا',
          'mymuesli - تأسست عام 2007 - ألمانيا',
          'allmyTea - غير معروف - ألمانيا',
          'حقيبة فريدة - غير معروفة - ألمانيا',
          'Factory121 - غير معروف - ألمانيا',
        ]
      : [
          'Dell - 1984 - USA',
          'Levi - 1853 - USA',
          'Adidas - 1949 - Germany',
          'PersonalNOVEL - 2010 - Germany',
          'mymuesli - Founded 2007 - Germany',
          'allmyTea - Unknown - Germany',
          'Unique Bag - Unknown - Germany',
          'Factory121 - Unknown - Germany',
        ],
    tags: rtl
      ? [
          'قاعدة عملاء كبيرة',
          'ولاء العملاء',
          'تصميم المنتجات',
          'التخصيص',
          'الإنتاج بالجملة',
          'تفضيلات فردية',
          'أنظمة المنتجات المعيارية',
          'مباشر للعميل',
          'مي أديداس',
          'إضفاء الطابع الشخصي',
          'فعالية التكلفة',
          ' التخصيص التعاوني',
          'المرونة',
          'قابلية التوسع',
          'حزم المنتجات القابلة للتخصيص',
        ]
      : [
          'Big Customer Base',
          'Customer Loyalty',
          'Product Design',
          'Customization',
          'Mass Production',
          'Individual Preferences',
          'Modular Product Systems',
          'Direct To Customer',
          'Mi Adidas',
          'Personalization',
          'Cost Effectiveness',
          'Collaborative Customization',
          'Flexibility',
          'Scalability',
          'Customizable Product Packages',
        ],
    considerations: t('considerations_bm34'),
    combinations: ['$LBM50', '$LBM14', '$LBM23'],
    hashTagType: 'businessModelTemp',
  }
}

export default MassCustomisationBusinessModel
