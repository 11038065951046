import React, { useEffect, useState } from 'react'
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js'
import ButtonsSystem from '../../../../components/buttonsSystem/ButtonsSystem'
import axios from 'axios'
import PostAdCostView from './PostAdCostView'
import CentralTitle from '../../../../components/smallComponents/CentralTitle'
import i18next from 'i18next'

const PayPalCheckoutForm = ({ windowCloser, adCampaign, setAdCampaign }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const PAYPAL_CLIENTID = process.env.REACT_APP_PAYPAL_CLIENTID

  // const amount = adCampaign?.cost
  const amount = 15
  const style = { layout: 'vertical' }
  const currency = 'USD'
  const [success, setSuccess] = useState(false)

  const Buttons = () => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer()

    useEffect(() => {
      dispatch({
        type: 'resetOptions',
        value: {
          ...options,
          currency: currency,
        },
      })
    }, [currency])

    return (
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
              application_context: {
                shipping_preference: 'NO_SHIPPING',
                return_url: 'https://example.com/return',
                cancel_url: 'https://example.com/cancel',
              },
              intent: 'capture',
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId
            })
        }}
        onApprove={async (data, actions) => {
          const details = await actions.order.capture()
          const res = await axios.patch(`/adsManagment/${adCampaign?._id}`, {
            status: 'Promoted',
          })
          setAdCampaign(res.data.adCampaign)
          setSuccess(true)
        }}
        onError={(err) => {
          console.log(err)
        }}
      />
    )
  }

  return (
    <div className='App-body'>
      <CentralTitle title={rtl ? 'مقدار الدفع' : 'Amount of Payment'} />
      <br />
      <PostAdCostView adCampaign={adCampaign} />
      {success ? (
        rtl ? (
          'أخبار رائعة! تمت عملية الدفع لرسوم حملتك بنجاح وهي الآن مفعلة.'
        ) : (
          'Great news! The transaction for your campaign fees was successful and your campaign is now active.'
        )
      ) : (
        <>
          <PayPalScriptProvider
            options={{
              'client-id': PAYPAL_CLIENTID,
              debug: true,
              components: 'buttons',
              currency: 'USD',
            }}
          >
            <Buttons />
          </PayPalScriptProvider>
        </>
      )}
      <ButtonsSystem
        buttonsArray={[{ title: rtl ? 'غلق' : 'Close', funs: windowCloser }]}
      />
    </div>
  )
}

export default PayPalCheckoutForm
