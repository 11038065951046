import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import { AuthContext } from '../../../../../../context/AuthContext'
import NotificationCreator from '../../../../NotificationCreator'
import i18next from 'i18next'

const GlobalInquiryOffer = (props) => {
  const { user } = useContext(AuthContext)
  const { socket, globalInquiry, sender, notification } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isClicked, setIsClicked] = useState(false)
  const activeFun = async ({ response }) => {
    try {
      setIsClicked(true)
      socket?.current?.emit('addNotifUser', sender?._id)
      // this will add the user to the default channel
      const defaultChRes = await axios.patch(
        `/channels/channelsSystem/defaultChannel/${globalInquiry?.id}`,
        {
          contributorId: sender?._id,
        }
      )
      NotificationCreator({
        associativity: 'global',
        relation: 'globalReviewer',
        conditionIndex: 0,
        accompaniedData: {
          response,
          elements: [
            { ...globalInquiry },
            {
              hashTagType: defaultChRes?.data?.channel?.hashTagType,
              id: defaultChRes?.data?.channel?._id,
              title: defaultChRes?.data?.channel?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: sender?._id,
        currentUser: user,
        socket,
      })
      // will add the user to the contributors of the globalInquiry if the response is accept
      if (response === 'accept')
        await axios.patch(
          `/globalInquiries/addContributor/${globalInquiry?._id}`,
          {
            contributorId: sender?._id,
          }
        )
      await axios.patch(`/notifications/${notification?._id}`, {
        interactedWith: true,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const buttonsArray =
    notification?.accompaniedData?.requestSubGenre === 'contribute'
      ? [
          {
            title: rtl ? 'قبول' : 'Accept',
            funs: () => activeFun({ response: 'accept' }),
            disabled: isClicked || notification?.interactedWith,
          },
          {
            title: rtl ? 'رفض' : 'Refuse',
            funs: () => activeFun({ response: 'refuse' }),
            disabled: isClicked || notification?.interactedWith,
          },
        ]
      : []
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <section>
              <Link to={`/profile/${sender?._id}`}>{sender.name}</Link>{' '}
              {rtl
                ? 'يطلب المساهمة فى هذه الرؤية العالمية'
                : 'asks for contribution to your global inquiry'}
            </section>
          }
        />
      }
      buttonsArray={buttonsArray}
    />
  )
}

export default GlobalInquiryOffer
