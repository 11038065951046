import React from 'react'
import { GiEarthAmerica } from 'react-icons/gi'
import { FaHome } from 'react-icons/fa'
import withStyles from '@mui/styles/withStyles'
import BulletSeparator from '../../../../smallComponents/BulletSeparator'

const styles = () => ({
  privacyIcon: {
    margin: '2px 0 -2px 0',
  },
})

const PostPrivacyFlag = ({ classes, postProps, wz }) => {
  const { post } = postProps

  return (
    <>
      <BulletSeparator />
      {post?.privacyType === 'Global' ? (
        <GiEarthAmerica className={classes.privacyIcon} />
      ) : (
        <FaHome className={classes.privacyIcon} />
      )}
    </>
  )
}

const WrappedPostPrivacyFlag = withStyles(styles)(PostPrivacyFlag)

export default WrappedPostPrivacyFlag
