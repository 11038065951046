import React from 'react'
import GeneralPDFDocument from './GeneralPDFDocument'
import ManagerialPlanPDFBody from './ManagerialPlanPDFDocumentComps/ManagerialPlanPDFBody'

const ManagerialPlanPDFDocument = (props) => {
  return (
    <GeneralPDFDocument
      pages={[<ManagerialPlanPDFBody {...props} />]}
      {...props}
    />
  )
}

export default ManagerialPlanPDFDocument
