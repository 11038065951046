import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { NotificationViewModel, RequestNotificationAccessModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const ContributeButtonInvestOffers = (props) => {
  const { notification, project, sender } = props
  const [request, setRequest] = useState(null)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isLoading, setIsLoading] = useState(true)
  NotificationAutoInterUpdator(props)
  useEffect(() => {
    const fetchRequest = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/requests/${notification?.accompaniedData?.requestId}`
        )
        setRequest(res.data.request)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (notification?.accompaniedData?.requestId) fetchRequest()
  }, [notification?.accompaniedData?.requestId])
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={
        rtl
          ? 'بعد قبول هذا العرض ، يمكنك إرسال قناة استثمار إلى المستثمر المحتمل أو إنشاء قناة جديدة في قنوات مشروعك ، للتعامل مع المزيد من التفاعلات'
          : 'After accepting this offer you may send the potential investor an investment channel or create a new one in your project channels, to handle more interactions'
      }
      notificationBody={
        <RequestNotificationAccessModel
          {...props}
          requestType='investment'
          requestData={request}
          isLoading={isLoading}
          notificationBodyContent={
            rtl ? (
              <section>
                لديك عرض استثمارى جديد من
                <Link to={`/profile/${sender?._id}`}>{sender?.name}</Link> فى
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
              </section>
            ) : (
              <section>
                You have a new investment offer from{' '}
                <Link to={`/profile/${sender?._id}`}>{sender?.name}</Link> in
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
              </section>
            )
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default ContributeButtonInvestOffers
