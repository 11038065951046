import React from 'react'
import CampaignPDFBody from './CampaignPDFDocumentComps/CampaignPDFBody'
import GeneralPDFDocument from './GeneralPDFDocument'

const CampaignPDFDocument = (props) => {
  return (
    <GeneralPDFDocument pages={[<CampaignPDFBody {...props} />]} {...props} />
  )
}

export default CampaignPDFDocument
