import React from 'react'
import withStyles from '@mui/styles/withStyles'
import FormsComponents from '../../FormsComponents'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  textArea: {
    margin: '0',
    width: '96%',
    borderRadius: 10,
    padding: '4px 10px',
    fontWeight: 500,
  },
  wideTextArea: {
    margin: '0',
    width: '100%',
    borderRadius: 10,
    padding: '4px 0',
    fontWeight: 500,
  },
})

const AutoSizeEditingZone = ({
  classes,
  isDarkModeActive,
  setInfoValue,
  infoValue,
  setChosenHashTagElements,
  chosenHashTagElements,
  info,
  // to be deinfed
  project,
  departmentId,
  subDepId,
}) => {
  const { t } = useTranslation()
  const formsCompsProps = {
    placeholder: '   ' + (info?.placeholder || t(info.title)),
    formComponentIndex: 4,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent: infoValue,
    setTextContent: setInfoValue,
    isDarkModeActive,
    autoSizeClassName: info?.wideMode ? classes.wideTextArea : classes.textArea,
    project,
    departmentId,
    subDepId,
    minRows: info?.minRows,
  }
  return <FormsComponents formsCompsProps={formsCompsProps} />
}

const WrappedAutoSizeEditingZone = withStyles(styles)(AutoSizeEditingZone)

export default WrappedAutoSizeEditingZone
