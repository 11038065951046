import React from 'react'
import { Grid } from 'react-loader-spinner'

const GridLoader = () => {
  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
      }}
    >
      <Grid
        height='80'
        width='80'
        color='#4fa94d'
        ariaLabel='grid-loading'
        radius='12.5'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
      />
    </section>
  )
}

export default GridLoader