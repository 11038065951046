import { Button } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'
import GeneralConfirmationMssg from '../../../../../../confirmationSystem/GeneralConfirmationMssg'
import axios from 'axios'
import { AuthContext } from '../../../../../../../context/AuthContext'
import i18next from 'i18next'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    color: 'white !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const TaskTerminationButton = ({
  classes,
  task,
  DCGProps,
  setProjectTasks,
}) => {
  const { user } = useContext(AuthContext)
  const [clicked, setClicked] = useState(
    task.TaskStatus === 'Done' || task.TaskStatus === 'Terminated'
  )
  const { project, socket } = DCGProps
  // alert dialog funs
  const [isGeneralConfirmDialogOpen, setIsGeneralConfirmDialogOpen] =
    useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsGeneralConfirmDialogOpen(false)
    }, 10000)
  }, [isGeneralConfirmDialogOpen === true])

  useEffect(() => {
    if (socket?.current)
      socket?.current?.emit('addNotifUser', task?.TaskPairedContributorId)
  }, [])
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <Button
        disabled={clicked}
        onClick={() => {
          setIsGeneralConfirmDialogOpen(true)
        }}
        className={classes.button}
        variant='contained'
        sx={{
          backgroundColor:
            task.TaskStatus === 'Done' || task.TaskStatus === 'Terminated'
              ? 'GrayText'
              : null,
        }}
      >
        {rtl ? 'إلغاء المهمه' : 'Terminate Task'}
      </Button>
      <GeneralConfirmationMssg
        {...DCGProps}
        confirmMessg={''}
        confirmTitle={
          rtl ? 'إلغاء المهمة لا رجعة فيه' : 'Task Termination is Irreversible'
        }
        generalConfirmDialogClose={() => setIsGeneralConfirmDialogOpen(false)}
        generalConfirmAction={async () => {
          try {
            const res = await axios.patch(`/tasks/${task?._id}`, {
              TaskStatus: 'Terminated',
            })
            if (typeof setProjectTasks === 'function')
              setProjectTasks((tasks) => [
                ...tasks.filter((t) => t._id !== task._id),
                res.data.task,
              ])
            setClicked(true)
            NotificationCreator({
              associativity: 'task',
              relation: 'pairedWith',
              conditionIndex: 3,
              accompaniedData: {
                projectId: project?._id,
                elements: [
                  {
                    hashTagType: task?.hashTagType,
                    id: task?._id,
                    title: task?.Title,
                  },
                ],
              },
              category: 'contributors',
              userId: task?.TaskPairedContributorId,
              currentUser: user,
              socket,
            })
          } catch (error) {
            console.log(error)
          }
        }}
        isGeneralConfirmDialogOpen={isGeneralConfirmDialogOpen}
      />
    </>
  )
}

const WrappedTaskTerminationButton = withStyles(styles)(TaskTerminationButton)

export default WrappedTaskTerminationButton
