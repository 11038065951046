import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const ResellerAndReferralPrograms = () => {
  const { t } = useTranslation('ResellerAndReferralPrograms')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 79,
    title: t('title_bm79'),
    subTitle: t('subTitle_bm79'),
    main: t('main_bm79'),
    when: t('when_bm79'),
    who: t('who_bm79'),
    what: t('what_bm79'),
    how: t('how_bm79'),
    why: t('why_bm79'),
    examples: rtl
      ? [
          'HubSpot - 2006 - الولايات المتحدة',
          'Influitive - 2010 - كندا',
          'ReferralCandy - 2009 - سنغافورة',
        ]
      : [
          'HubSpot - 2006 - United States',
          'Influitive - 2010 - Canada',
          'ReferralCandy - 2009 - Singapore',
        ],
    tags: rtl
      ? ['إحالة العميل', 'سوق إعادة البيع', 'أعمال الإحالة']
      : ['Client-Referral', 'Resale Market', 'Referral Business'],
    considerations: t('considerations_bm79'),
    combinations: ['$LBM76'],
    hashTagType: 'businessModelTemp',
  }
}

export default ResellerAndReferralPrograms
