import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { AiFillEdit } from 'react-icons/ai'
import i18next from 'i18next'

const styles = () => ({
  editWrapper: {
    backgroundColor: 'rgba(255,255,255, 0.6)',
    color: 'black',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 4,
    '&:hover': {
      fotnSize: 18,
      backgroundColor: 'rgba(255,255,255, 0.8)',
    },
  },
  labelButton: {
    padding: '0px !important',
    margin: '0px !important',
    textTransform: 'none !important',
    backgroundColor: 'transparent !important',
    borderRadius: '100% !important',
  },
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    borderRadius: 15,
    margin: 15,
    height: 300,
  },
  bigEditWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 10,
  },
  galleryTitle: {
    fontWeight: 500,
    fontSize: 20,
    padding: 10,
  },
})

const EmptyEditableGallery = ({
  isDarkModeActive,
  GeneralDialogOpener,
  emptyGalleryTitle,
  classes,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div
      className={classes.container}
      style={{
        border: `1px dotted ${isDarkModeActive ? 'white' : 'black'}`,
      }}
    >
      <section className={classes.bigEditWrapper}>
        <span className={classes.galleryTitle}>
          {rtl
            ? `تعديل معرض صور ${emptyGalleryTitle}`
            : `Edit ${emptyGalleryTitle}'s Gallery`}
        </span>
        <Button
          component='label'
          color='inherit'
          className={classes.labelButton}
          onClick={() => GeneralDialogOpener()}
        >
          <span className={classes.editWrapper}>
            <AiFillEdit size={23} />
          </span>
        </Button>
      </section>
    </div>
  )
}

const WrappedEmptyEditableGallery = withStyles(styles)(EmptyEditableGallery)

export default WrappedEmptyEditableGallery
