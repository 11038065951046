import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const RemoveFromBoardNotifCover = ({ notification }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `تم حذفك من قائمة أعضاء مجلس الادارة`
    : ` You have been removed from the board members`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default RemoveFromBoardNotifCover
