import React from 'react'
import { ProfileShareModel } from './'

const RoomEdit = ({ shareSystemProps }) => {
  return (
    <ProfileShareModel
      shareSystemProps={{
        ...shareSystemProps,
        shareOptionsModelType: 'room',
        feedsSystemCategory: 'room',
      }}
    />
  )
}

export default RoomEdit
