import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import i18next from 'i18next'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const CloseWindow = ({ classes, noFSPanelProps }) => {
  const {
    closeWindow,
    hideCloseArrow,
    setSecondLevelFolderPath,
    setThirdLevelFolderPath,
    setFourthLevelFolderPath,
    members,
    setCardId,
    setShowSearchInput,
    setSearchQuery,
    setSearchInputValue,
    setIsSubDepSubdDepMemberProfileOpen,
    subdepartment,
    setHideFSPanel,
  } = noFSPanelProps
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const actionFuns = () => {
    if (typeof closeWindow === 'function') closeWindow()
    if (typeof setCardId === 'function') setCardId(undefined)
    if (subdepartment) {
      if (typeof setIsSubDepSubdDepMemberProfileOpen === 'function')
        setIsSubDepSubdDepMemberProfileOpen(false)
      if (typeof setHideFSPanel === 'function') setHideFSPanel(false)
    }
    if (typeof setSearchInputValue === 'function' && members)
      setSearchInputValue('')
    if (typeof setSecondLevelFolderPath === 'function')
      setSecondLevelFolderPath('')
    if (typeof setThirdLevelFolderPath === 'function')
      setThirdLevelFolderPath('')
    if (typeof setFourthLevelFolderPath === 'function')
      setFourthLevelFolderPath('')
    if (typeof setShowSearchInput === 'function') setShowSearchInput(false)
    if (typeof setSearchQuery === 'function') setSearchQuery('')
    if (typeof setSearchInputValue === 'function') setSearchInputValue('')
  }
  return (
    <Tooltip title={t('Back')}>
      <span
        className={classes.iconWrapper}
        style={{ display: hideCloseArrow ? 'none' : null }}
      >
        {rtl ? (
          <MdArrowRight className={classes.icon} onClick={actionFuns} />
        ) : (
          <MdArrowLeft className={classes.icon} onClick={actionFuns} />
        )}
      </span>
    </Tooltip>
  )
}

const WrappedCloseWindow = withStyles(styles)(CloseWindow)

export default WrappedCloseWindow
