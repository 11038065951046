import { TextareaAutosize } from '@mui/material'
import React, { useRef, useState } from 'react'
import HashTagSuggestSystem from '../../suggestionsSystem/HashTagSuggestSystem'

const AutoSizeTextArea = ({ formsCompsProps }) => {
  const {
    textContent,
    setTextContent,
    placeholder,
    isDarkModeActive,
    cursorDirection,
    autoSizeClassName,
    minRows,
    maxRows,
  } = formsCompsProps
  const [isSuggeCardClicked, setIsSuggeCardClicked] = useState(false)
  const formsCompsPropsII = {
    ...formsCompsProps,
    setIsSuggeCardClicked,
    isSuggeCardClicked,
  }
  const anchorRef = useRef(null)
  return (
    <>
      <TextareaAutosize
        ref={anchorRef}
        minRows={minRows || 1}
        maxRows={maxRows}
        className={autoSizeClassName}
        style={{
          color: isDarkModeActive ? 'white' : 'black',
          direction: cursorDirection,
          backgroundColor: isDarkModeActive
            ? 'rgb(255, 255, 255, 0.1)'
            : 'rgba(217,224,252, 0.2)',
        }}
        placeholder={placeholder}
        onChange={(event) => {
          setTextContent(event.currentTarget.value)
          setIsSuggeCardClicked(false)
        }}
        value={textContent}
      />
      <HashTagSuggestSystem
        {...formsCompsPropsII}
        anchorRef={anchorRef}
        cursorDirection={cursorDirection}
      />
    </>
  )
}

export default AutoSizeTextArea
