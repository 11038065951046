import React, { useContext, useEffect, useState } from 'react'
import ModEngine from '../../../modEngine/ModEngine'
import axios from 'axios'
import ContractTermBody from './ContractElementTermsComps/ContractTermBody'
import Loading from '../../../smallComponents/Loading'
import { AuthContext } from '../../../../context/AuthContext'

const ContractElementTerms = (props) => {
  const { conEl, members } = props
  const [modifications, setModifications] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const fetchMods = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/contractElementModifications/elementModiifcations/${conEl?._id}`
        )
        setModifications(res.data.modifications)
      } catch (error) {}
      setIsLoading(false)
    }
    if (conEl?._id) fetchMods()
  }, [conEl?._id])

  return isLoading ? (
    <Loading />
  ) : (
    <section>
      <ContractTermBody {...props} setModifications={setModifications} />
      {members?.includes(user?._id) && (
        <ModEngine
          modifications={modifications}
          currentPropertyName='terms'
          setModifications={setModifications}
          {...props}
        />
      )}
    </section>
  )
}

export default ContractElementTerms
