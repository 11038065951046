import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const NewProjectContributionOfferReplyCover = ({
  notification,
  sender,
  project,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `قام ${sender?.name} بالرد على عرضك لمشاركته مشروعك`
    : `${sender?.name} replied to your contribution offer for new project ${project?.name}`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default NewProjectContributionOfferReplyCover
