import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const LongTailBusinessModel = () => {
  const { t } = useTranslation('LongTailBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 32,
    title: t('title_bm32'),
    subTitle: t('subTitle_bm32'),
    main: t('main_bm32'),
    when: t('when_bm32'),
    who: t('who_bm32'),
    what: t('what_bm32'),
    how: t('how_bm32'),
    why: t('why_bm32'),
    examples: rtl
      ? [
          'أمازون - تأسست 1994 - الولايات المتحدة الأمريكية',
          'eBay - تأسست عام 1995 - الولايات المتحدة الأمريكية',
          'Netflix - تأسست عام 1997 - الولايات المتحدة الأمريكية',
          'آبل - تأسست عام 1976 - الولايات المتحدة الأمريكية',
          'YouTube - تأسس عام 2006 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Amazon - Founded 1994 - USA',
          'eBay - Founded 1995 - USA',
          'Netflix - Founded 1997 - USA',
          'Apple - Founded 1976 - USA',
          'YouTube - Founded 2006 - USA',
        ],
    tags: rtl
      ? ['إغراق السوق', 'مسابقة شرسة', 'توزيع معقد', 'شبكة ضخمة']
      : [
          'Flooding the Market',
          'Aggressive Competition',
          'Complex Distribution',
          'Huge Network',
        ],
    considerations: t('considerations_bm32'),
    combinations: ['$LBM16', '$LBM29', '$LBM34', '$LBM59', '$LBM31', '$LBM61'],
    hashTagType: 'businessModelTemp',
  }
}

export default LongTailBusinessModel
