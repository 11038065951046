import moment from 'moment'
import React from 'react'
import EditableGridModel from '../../../../../formsSystem/EditableGridModel'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const MemberPromotions = ({ member, DCGProps, departmentId, subDepId }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const titleValue = (pr) =>
    rtl
      ? pr?.Content?.department
        ? pr?.Content?.type === 'promotion'
          ? `ترقية كمديرا لقسم ${t(pr?.Content?.department)}`
          : `إقالة كمديرا لقسم ${t(pr?.Content?.department)}`
        : pr?.Content?.type === 'promotion'
        ? `ترقية كمديرا للقسم الفرعى ${pr?.Content?.subDep}`
        : `إقالة كمديرا للقسم الفرعى ${pr?.Content?.subDep}`
      : pr?.Content?.department
      ? pr?.Content?.type === 'promotion'
        ? `Promtion as
      ${pr?.Content?.department} department manager`
        : `Depromtion as 
      ${pr?.Content?.department} department manager`
      : pr?.Content?.type === 'promotion'
      ? `Promtion as ${pr?.Content?.subDep} sub-department manager`
      : `Depromtion as ${pr?.Content?.subDep} sub-department manager`
  const promotionsArray = member?.promotions?.map((pr) => ({
    title: moment(pr?.Date).calendar(),
    titleValue: titleValue(pr),
  }))
  const gridProps = {
    hideHeader: true,
    infoArray: promotionsArray,
    hideEditingTools: true,
    departmentId,
    subDepId,
    ...DCGProps,
  }
  return promotionsArray && <EditableGridModel gridProps={gridProps} />
}

export default MemberPromotions
