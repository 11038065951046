import React, { useState } from 'react'
import { ListItem } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import CustomCircularProgress from '../../../../smallComponents/CustomCircularProgress'
import {
  DevelopmentNotificationModel,
  InvestmentNotificationModel,
  RoomNotificationModel,
  ProjectNotificationModel,
  PromotionNotificationModel,
  ProductNotificationModel,
  JobNotificationModel,
  TaskNotificationModel,
  InquiryNotificationModel,
  VisionNotificationModel,
  GlobalNotificationModel,
  ResourceNotificationModel,
  ContributionNotificationModel,
  ChannelNotificationModel,
  CampaignNotificationModel,
} from '../../'

const styles = () => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100% !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
    cursor: 'pointer !important',
    '&:hover': {
      opacity: '0.8',
    },
  },
  clickedNotification: {
    backgroundColor: 'rgba(217,224,252, 0.6) !important',
    cursor: 'pointer !important',
    '&:hover': {
      opacity: '0.8',
    },
  },
})

const ContribNotifListItem = ({
  classes,
  props,
  element,
  toastDisabledFun,
}) => {
  const {
    isDarkModeActive,
    setChosenNotification,
    chosenNotification,
    sender,
    project,
    socket,
    // defined only for m view
    senders,
    projects,
    // this function is for the m version
    notificationDialogOpener,
    // undefined yet
    post,
    setContribNotifications,
  } = props
  // for m view
  const [notification, setNotification] = useState(element)
  const senderII = senders?.find(
    (se) => se._id === notification?.accompaniedData?.senderId
  )
  const projectII = projects?.find(
    (pro) => pro._id === notification?.accompaniedData?.projectId
  )
  const notifProps = {
    notification,
    isDarkModeActive,
    sender: senderII ? senderII : sender,
    project: projectII ? projectII : project,
    post,
    socket,
  }
  const notifModels = [
    {
      associativity: 'project',
      component: <ProjectNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'product',
      component: <ProductNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'campaign',
      component: <CampaignNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'task',
      component: <TaskNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'job',
      component: <JobNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'promotion',
      component: <PromotionNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'room',
      component: <RoomNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'investment',
      component: <InvestmentNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'development',
      component: <DevelopmentNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'inquiry',
      component: <InquiryNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'idea',
      component: <VisionNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'global',
      component: <GlobalNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'resource',
      component: <ResourceNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'contribution',
      component: <ContributionNotificationModel notifProps={notifProps} />,
    },
    {
      associativity: 'channel',
      component: <ChannelNotificationModel notifProps={notifProps} />,
    },
  ]
  return (
    <ListItem
      className={
        element?._id === chosenNotification?._id
          ? classes.clickedNotification
          : classes.listItem
      }
      onClick={async () => {
        if (notification._id && !toastDisabledFun) {
          try {
            const res = await axios.patch(
              `/notifications/${notification._id}`,
              {
                opened: true,
                shown: true,
              }
            )
            setNotification(res.data.notification)
            setContribNotifications((notifs) => {
              let oldIndex = notifs?.findIndex(
                (n) => n?._id === notification?._id
              )
              return notifs.map((p, i) =>
                i === oldIndex ? res.data.notification : p
              )
            })
          } catch (error) {
            console.log(error)
          }
        }
        if (typeof setChosenNotification === 'function')
          setChosenNotification(element)
        if (typeof notificationDialogOpener === 'function')
          notificationDialogOpener()
      }}
      style={{
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      {(sender ? !sender : !senderII) ? (
        <CustomCircularProgress />
      ) : (
        notifModels.find(
          (notif) => notif.associativity === notification?.associativity
        )?.component
      )}
    </ListItem>
  )
}

const WrappedContribNotifListItem = withStyles(styles)(ContribNotifListItem)

export default WrappedContribNotifListItem
