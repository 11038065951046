import React from 'react'
import DetectorElementModel from './CoreComponentComps/DetectorElementModel'
import NextDetectionModel from './CoreComponentComps/NextDetectionModel'
import BreaksDirLinkDetector from './CoreComponentComps/BreaksDirLinkDetector'

const CoreComponent = ({
  NextDetection,
  TextFormattorComp,
  furtherDetection,
  textPackage,
  word,
  isDarkModeActive,
  notEngineProps,
  nonDetectedFragments,
  wordIndex,
}) => {
  // now i need to replace each mentionedWord with the coresponding element component in each fragment
  const commonProps = {
    textPackage,
    isDarkModeActive,
    notEngineProps,
  }
  return (
    <>
      <>
        {furtherDetection ? (
          NextDetection ? (
            <NextDetection
              text={nonDetectedFragments}
              textIndex={wordIndex}
              {...commonProps}
            />
          ) : (
            <NextDetectionModel
              text={nonDetectedFragments}
              textIndex={wordIndex}
              {...commonProps}
            />
          )
        ) : (
          BreaksDirLinkDetector(nonDetectedFragments[wordIndex])
        )}
      </>
      <>
        {TextFormattorComp ? (
          <TextFormattorComp word={word} {...commonProps} />
        ) : (
          <DetectorElementModel word={word} {...commonProps} />
        )}
      </>
    </>
  )
}

export default CoreComponent
