import React from 'react'
import NotificationViewModel from '../../../../NotificationViewModel'
import { Link } from 'react-router-dom'
import { NotificationBodyWithAccessCard } from '../../../..'
import NotificationAutoInterUpdator from '../../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const ProjectPostLike = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <section>
              <Link to={`/profile/${sender?._id}`}>{sender.name}</Link>{' '}
              <span>{rtl ? 'أُعجب بمنشور' : 'liked a post'}</span>
            </section>
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default ProjectPostLike
