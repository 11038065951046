import React, { useEffect, useState } from 'react'
import MangScreenViewCard from '../../../../components/projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/ManagementComponents/ProjectManagementScreenComps/MangScreenViewCard'
import withStyles from '@mui/styles/withStyles'
import {
  FavoriteBorder as LikesIcon,
  ShareOutlined as SharesIcon,
  ChatBubbleOutline as CommentsIcon,
  VisibilityOutlined as ReachIcon,
} from '@material-ui/icons'
import axios from 'axios'

const styles = () => ({
  wrapper: {
    padding: '1rem',
    width: '100%',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  icon: {
    fontSize: '3rem !important',
  },
  '@media (max-width: 600px)': {
    wrapper: {
      padding: 0,
      width: '100%',
    },
    title: {
      fontSize: '22px',
      fontWeight: '500',
      marginBottom: '10px',
      marginTop: '10px',
    },
    icon: {
      fontSize: '2rem !important',
    },
  },
})

const AdCampaignAnalytics = ({ classes, adCampaign, post }) => {
  const [commentsCount, setCommentsCount] = useState(0)
  const reachNumber =
    (adCampaign?.targetSize || 0) - adCampaign?.targetUsers?.length

    useEffect(() => {
    const fetchCount = async () => {
      try {
        const res = await axios.get(`/comments/commentsCount/${post._id}`)
        setCommentsCount(res.data.commentsCount)
      } catch (error) {}
    }
    if (post._id) fetchCount()
  }, [post._id])
  const countsPairs = [
    {
      title: 'Likes',
      count: post?.likes?.length,
      icon: <LikesIcon color='primary' className={classes.icon} />,
    },
    {
      title: 'Shares',
      count: post?.shares?.length,
      icon: <SharesIcon color='primary' className={classes.icon} />,
    },
    {
      title: 'Comments',
      count: commentsCount,
      icon: <CommentsIcon color='primary' className={classes.icon} />,
    },
    {
      title: 'Reach',
      count: reachNumber,
      icon: <ReachIcon color='primary' className={classes.icon} />,
    },
  ]
  return (
    <div className={classes.wrapper}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexFlow: 'row wrap',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {countsPairs?.map((c, i) => {
          return <MangScreenViewCard key={i} c={c} />
        })}
      </div>
    </div>
  )
}

export default withStyles(styles)(AdCampaignAnalytics)
