const FeedReducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_POST':
      return {
        post: action.payload,
        error: false,
      }
    case 'UPDATE_POST':
      return {
        post: action.payload,
        error: false,
      }
    case 'DELETE_POST':
      return {
        post: action.payload,
        error: false,
      }
    case 'MENTION':
      return {
        notification: action.payload,
        error: false,
      }
    case 'REMOVE_MENTION':
      return {
        notification: null,
        error: false,
      }
    default:
      return state
  }
}

export default FeedReducer
