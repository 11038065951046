import React from 'react'
import SuggUnitCard from './SuggUnitCard'
import HashTagCardsConfigs from '../../HashTagCardsConfigs'

const SuggListItemCardGenerator = (element) => {
  const card = HashTagCardsConfigs().find(
    (car) => car.hashTagType === element?.hashTagType
  )
  return card ? <SuggUnitCard element={element} card={card} /> : null
}

export default SuggListItemCardGenerator
