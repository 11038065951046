import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar } from '@mui/material'
import { Link } from 'react-router-dom'
import i18next from 'i18next'

const styles = () => ({
  listUnitWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  avatarNameCategory: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
  avatar: {
    cursor: 'pointer',
    width: '50px !important',
    height: '50px !important',
  },
  companyName: {
    fontSize: 18,
    fontWeight: 500,
    cursor: 'pointer',
  },
})

const ListUnitModel = ({ classes, element, isDarkModeActive }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article
      className={classes.listUnitWrapper}
      style={{ color: isDarkModeActive && 'white' }}
    >
      <Link to={`/profile/${element?._id}`}>
        <section className={classes.avatarNameCategory}>
          <Avatar
            src={element.profilePicture}
            sx={{ margin: rtl ? '10px 0 10px 10px' : '10px 10px 10px 0' }}
            className={classes.avatar}
          />
          <span className={classes.companyName}>
            {element.firstName + ' ' + element.lastName}
          </span>
        </section>
      </Link>
    </article>
  )
}

const WrappedListUnitModel = withStyles(styles)(ListUnitModel)

export default WrappedListUnitModel
