import React from 'react'
import TimeAgoFormattor from '../../../smallComponents/TimeAgoFormattor'
import PostPrivacyFlag from './PTLeftFlagComps/PostPrivacyFlag'
import PostTypeFlag from './PTLeftFlagComps/PostTypeFlag'
import i18next from 'i18next'

const PTLeftFlags = ({ postProps, wz }) => {
  const { post } = postProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div style={{ fontSize: wz ? 12 : 10 }}>
      <PostTypeFlag postProps={postProps} />
      {post?.Promoted ? (
        <>{rtl ? 'مُمٌول' : 'Sponsered'}</>
      ) : (
        <>
          <TimeAgoFormattor date={post?.createdAt} />
          <PostPrivacyFlag postProps={postProps} />
        </>
      )}
    </div>
  )
}

export default PTLeftFlags
