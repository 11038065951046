import React, { useContext, useState } from 'react'
import { FaRegHandshake } from 'react-icons/fa'
import { GiShakingHands } from 'react-icons/gi'
import withStyles from '@mui/styles/withStyles'
import NotificationCreator from '../notificationsSystem/NotificationCreator'
import { AuthContext } from '../../context/AuthContext'
import axios from 'axios'

const styles = () => ({
  followChatOptionsIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 5,
    width: '80%',
  },
  followChatOptionsIconsItem: {
    fontSize: 21,
    padding: 5,
    cursor: 'pointer',
  },
})

const UserProfileAddContributor = ({ classes, userId, socket }) => {
  const { user, dispatch } = useContext(AuthContext)
  const [addContributor, setAddContributor] = useState(
    user?.contributors.includes(userId)
  )
  const onContributionAddtion = async () => {
    if (userId)
      try {
        if (addContributor) {
          await axios.put(`/users/${userId}/removeContributor`, {
            userId: user?._id,
          })
          dispatch({ type: 'REMOVE_CONTRIBUTOR', payload: userId })
        } else {
          await axios.put(`/users/${userId}/addContributor`, {
            userId: user?._id,
          })
          dispatch({ type: 'ADD_CONTRIBUTOR', payload: userId })
        }
        setAddContributor(!addContributor)
      } catch (error) {
        console.log(error)
      }
  }
  const contributorIcons = [
    <FaRegHandshake className={classes.followChatOptionsIconsItem} />,
    <GiShakingHands className={classes.followChatOptionsIconsItem} />,
  ]
  return (
    <span
      onClick={() => {
        onContributionAddtion()
        if (!addContributor)
          NotificationCreator({
            associativity: 'userProfile',
            relation: 'owner',
            conditionIndex: 1,
            accompaniedData: {},
            category: 'general',
            userId,
            currentUser: user,
            socket,
          })
      }}
    >
      {addContributor ? contributorIcons[1] : contributorIcons[0]}
    </span>
  )
}

const WrappedUserProfileAddContributor = withStyles(styles)(
  UserProfileAddContributor
)

export default WrappedUserProfileAddContributor
