import React from 'react'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import NotificationAutoInterUpdator from '../../../NotificationAutoInterUpdator'
import i18next from 'i18next'

const ResourceReqReply = (props) => {
  const { sender } = props
  NotificationAutoInterUpdator(props)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? `قام ${sender?.name} بالرد على طلبك للمشاركة فى المرود الخاص به"`
              : `${sender?.name} replied to your request for shring in his resource`
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default ResourceReqReply
