import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../../../context/AuthContext'
import ButtonsSystem from '../../../../buttonsSystem/ButtonsSystem'
import NotificationCreator from '../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'
import ToastifyGeneralError from '../../../../smallComponents/ToastifyGeneralError'
import i18next from 'i18next'

const ChannelRequestDetails = ({
  card,
  DCGProps,
  exprops,
  setRequests,
  requestsUsers,
}) => {
  const { channel, members } = exprops
  const { socket } = DCGProps
  const request = card
  const project = request?.requestData?.project
  const { user } = useContext(AuthContext)
  const acceptingUsers = request?.requestData?.acceptingUsers || []
  const [inseractedWith, setIneractedWith] = useState(
    acceptingUsers?.includes(user?._id)
  )
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const requestModifier = async () => {
    try {
      await axios.patch(`/requests/${request?._id}`, {
        requestData: {
          acceptingUsers: [...acceptingUsers, user?._id],
        },
      })
    } catch (error) {
      console.log(error)
      ToastifyGeneralError(error)
    }
  }
  const activeFun = async ({ response }) => {
    try {
      setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
      socket.current.emit('addNotifUser', request?.userId)
      NotificationCreator({
        associativity: 'channel',
        relation: 'visitor',
        conditionIndex: 0,
        accompaniedData: {
          response,
          elements: [
            {
              hashTagType: channel?.hashTagType,
              id: channel?._id,
              title: channel?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: request?.userId,
        currentUser: user,
        socket,
      })
      // will add the user to the contributors of the channel if the response is accept
      // this will add either the project or the contributor accroding to from where he is asking for the contribution, if a place where the project is not defined then the contributor will be added only, else will be the project only
      if (response === 'accept')
        await axios.patch(
          `/channels/addContributor/${channel?._id}`,
          project?._id
            ? {
                projectId: project?._id,
              }
            : {
                contributorId: request?.userId,
              }
        )
      // request will be deleted after being responded to
      await axios.delete(`/requests/${request?._id}`)
    } catch (error) {
      ToastifyGeneralError(error)
    }
  }

  const buttonsArray = [
    {
      title: rtl ? 'قبول' : 'Accept',
      funs: () => {
        setIneractedWith(true)
        if (
          members?.length === acceptingUsers?.length + 1 ||
          members?.length === acceptingUsers?.length
        ) {
          activeFun({ response: 'accept' })
          toast.success(
            rtl
              ? 'تم قيول الطلب من جميع المشاركين'
              : 'Request has been accepted by all contributors'
          )
        } else {
          requestModifier()
          toast.success(
            rtl ? 'تم تسجيل قرارك' : 'Your decision has been recorded'
          )
        }
      },
      disabled: inseractedWith,
    },
    {
      title: rtl ? 'رفض ' : 'Refuse',
      funs: () => {
        setIneractedWith(true)
        activeFun({ response: 'refuse' })
        toast.success(
          rtl ? 'تم تسجيل قرارك' : 'Your decision has been recorded'
        )
      },
      disabled: inseractedWith,
    },
  ]
  return (
    <>
      <section>
        {requestingUser?.firstName + ' ' + requestingUser?.lastName}{' '}
        {rtl ? (
          project ? (
            <>
              يطلب مشاركة مشروعة{' '}
              <Link to={project?._id}>{project?.ProjectName}</Link> فى القناة
            </>
          ) : (
            'يطلب المشاركة فى هذه القناة'
          )
        ) : project ? (
          <>
            asks for adding his project{' '}
            <Link to={project?._id}>{project?.ProjectName}</Link>
            to the channel
          </>
        ) : (
          'Asks for contribution to this channel'
        )}
      </section>
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </>
  )
}

export default ChannelRequestDetails
