import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import EditableGridModel from '../../../../../../../formsSystem/EditableGridModel'
import i18next from 'i18next'

const ProductServiceDetails = ({
  product,
  isDarkModeActive,
  hideEditingTools,
  setProjectProducts,
  managerProfile,
  DCGProps,
  departmentId,
  subDepId,
}) => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const productInfo = [
    {
      title: 'Product_Name',
      titleValue: product?.productName || product?.ProductName,
      allowEmpty: true,
    },
  ].concat(
    product?.ProductProperties
      ? Object.keys(product?.ProductProperties)?.map((key) => ({
          title: key.split('-').join(' '),
          titleValue: product?.ProductProperties[key],
          allowEmpty: true,
          separator: '-',
          wideMode: true,
        }))
      : []
  )

  const [productInfoData, setProductInfoData] = useState({
    ProductName: product.ProductName,
    ...product?.ProductProperties,
  })

  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    product?.chosenHashTagElements || []
  )

  const noAdminMode =
    product?.ProductDesignerId !== user?._id &&
    managerProfile?._id !== user?._id
  const { Product_Name, ProductName, ...rest } = productInfoData
  const onSave = async () => {
    if (product?._id)
      try {
        const res = await axios.patch(`/products/${product?._id}`, {
          ProductName: Product_Name,
          ProductProperties: rest,
          chosenHashTagElements,
        })
        if (typeof setProjectProducts === 'function' && res.data.product)
          setProjectProducts((prods) => [
            ...prods.filter((p) => p._id !== product?._id),
            res.data.product,
          ])
      } catch (error) {
        console.log(error)
      }
  }
  const gridProps = {
    headerText: rtl ? 'تفاصيل متخصصه' : 'Product Specifics',
    infoArray: productInfo,
    setInfoData: setProductInfoData,
    infoData: productInfoData,
    isDarkModeActive,
    setChosenHashTagElements,
    chosenHashTagElements,
    hideEditingTools: hideEditingTools || noAdminMode,
    onSave,
    ...DCGProps,
    departmentId,
    subDepId,
  }
  return <EditableGridModel gridProps={gridProps} />
}

export default ProductServiceDetails
