import React, { useEffect, useState } from 'react'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import withStyles from '@mui/styles/withStyles'
import ChannelCardUnit from './ProjectPartnershipChannelsViewerComps/ChannelCardUnit'
import { Card } from '@mui/material'
import WindowProps from '../../../windowViewer/WindowProps'
import WindowViewerSystem from '../../../windowViewer/WindowViewerSystem'
import axios from 'axios'
import { useContext } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import CustomEmpty from '../../../smallComponents/CustomEmpty'
import BlocksLoader from '../../../smallComponents/BlocksLoader'
import i18next from 'i18next'

const styles = () => ({
  channelsWrapper: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexFlow: 'row wrap',
    marginBottom: 10,
    position: 'relative',
  },
  viewCard: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    width: 'auto',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
  },
})

const ProjectPartnershipChannelsViewer = ({
  noFSPanelProps,
  GeneralDialogClose,
  classes,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { project, DCGProps } = noFSPanelProps
  const [channels, setChannels] = useState([])
  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchChannels = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          project?._id
            ? `/channels/projectChannels/${project?._id}`
            : `/channels/userChannels/${user?._id}`
        )
        setChannels(res.data.channels)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (project?._id || user?._id) fetchChannels()
  }, [project?._id, user?._id])
  const { isDarkModeActive, element } = noFSPanelProps
  const [channelId, setChannelId] = useState(undefined)
  // this the channel chosen
  const channel = channels?.find((ch) => ch?._id === channelId)
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: () => GeneralDialogClose(),
    },
    {
      title: rtl ? 'إستمرار' : 'Continue',
      funs: async () => {
        windowOpener()
      },
      disabled: channelId === undefined,
    },
  ]

  const contractId = channel?.contractId
  const [contract, setContract] = useState(null)
  useEffect(() => {
    const fetchContract = async () => {
      try {
        const res = await axios.get(`/contracts/${contractId}`)
        setContract(res.data.contract)
      } catch (error) {
        console.log(error)
      }
    }
    if (contractId) fetchContract()
  }, [contractId])
  return isLoading ? (
    <BlocksLoader {...noFSPanelProps} />
  ) : (
    <section style={{ color: isDarkModeActive ? 'white' : null }}>
      <Card className={classes.viewCard}>
        {channels?.length > 0 ? (
          <section className={classes.channelsWrapper}>
            {channels.map((channel, channelIndex) => (
              <ChannelCardUnit
                channel={channel}
                key={channel?._id}
                channelIndex={channelIndex}
                channelId={channelId}
                setChannelId={setChannelId}
              />
            ))}
          </section>
        ) : (
          <CustomEmpty
            emptyMssg={rtl ? 'ليس لديك قنوات مشاركة' : 'You have no channels'}
            isDarkModeActive={isDarkModeActive}
            imageKey='noSearchResults'
          />
        )}
      </Card>
      <ButtonsSystem
        buttonsArray={buttonsArray}
        isDarkModeActive={isDarkModeActive}
      />
      {channelId && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
          isDarkModeActive={isDarkModeActive}
          props={{
            windowCloser,
            contract,
            channel,
            element,
            showElementAdd: true,
            DCGProps,
          }}
          windowType={'contract'}
        />
      )}
    </section>
  )
}

const WrappedProjectPartnershipChannelsViewer = withStyles(styles)(
  ProjectPartnershipChannelsViewer
)

export default WrappedProjectPartnershipChannelsViewer
