import newSharedResourceConfig from '../leftbarDirectoryComponents/feedsModels/PostBodyModelComponents/SharedResourcePostModelComps/SharedResourceConfigs'
import NewGlobalInquiryFormConfigs from '../profile/ProfileComponents/ProfileControlPanelComponents/ControlPanelDialogsContentComponents/IdeasInquiriesComponents/MyGlobalInquriesComponents/GlobalInquiryProfileComps/NewGlobalInquiryFormConfigs'
import CampaignConfigs from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/CampaignsComponents/CampaignConfigs'
import InvestmentPackConfigs from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/InvestmentPackagesComps/InvestmentPackConfigs'
import ResearchActivityFormConfigs from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/ResearchDevelopmentDepartmentComps/ResearchDevelopmentResultsComps/NewResearchActivityFormComps/ResearchActivityFormConfigs'
import NewConceptFormConfigs from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/ResearchDevelopmentDepartmentComps/ResearchDevelopmentResultsComps/ResearchActivityProfileComps/ResearchMainConceptsComps/NewConceptFormComps/NewConceptFormConfigs'
import {
  NewInquiryFormsConfigs,
  NewIdeaFormsConfigs,
  newProductFormConfigs,
  newTaskFormConfigs,
  NewJobFormConfigs,
} from './'

export default function ConfigsPropsExtractor(keys) {
  const extractorUnit = (configs) => {
    return configs
      .map((pro) => pro.title)
      .forEach((prop, i) => {
        PropsObject[prop.split(' ').join('')] = configs[i].value
          .split('_')
          .join(' ')
      })
  }

  const newPostBasicProps = {
    privacyType: 'Local',
    postTextContent: '',
    sharedPostId: '',
  }
  const PropsObject = { ...(keys?.newPost ? newPostBasicProps : {}) }

  if (keys?.task) extractorUnit(newTaskFormConfigs(0, 0))
  if (keys?.product) extractorUnit(newProductFormConfigs())
  if (keys?.job) extractorUnit(NewJobFormConfigs())
  if (keys?.idea) extractorUnit(NewIdeaFormsConfigs())
  if (keys?.inquiry) extractorUnit(NewInquiryFormsConfigs())
  if (keys?.concept) extractorUnit(NewConceptFormConfigs())
  if (keys?.globalInquiry) extractorUnit(NewGlobalInquiryFormConfigs())
  if (keys?.research) extractorUnit(ResearchActivityFormConfigs())
  if (keys?.investPack) extractorUnit(InvestmentPackConfigs())
  if (keys?.campaign) extractorUnit(CampaignConfigs())
  if (keys?.resource) extractorUnit(newSharedResourceConfig())

  return PropsObject
}
