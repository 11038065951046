import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  NotificationViewModel,
  NotificationBodyWithAccessCard,
} from '../../../'
import NotificationCreator from '../../../../NotificationCreator'
import { AuthContext } from '../../../../../../context/AuthContext'
import axios from 'axios'
import i18next from 'i18next'

const style = {
  margin: 'auto 4px',
}

const TaskMarkedDone = (props) => {
  const { notification, sender, project, task, socket } = props
  const [isInteractedWith, setIsInteractedWith] = useState(
    notification?.accompaniedData?.response
  )
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: isInteractedWith
        ? rtl
          ? 'تم القبول'
          : 'Granted'
        : rtl
        ? 'قبول'
        : 'Grant',
      descProps: {
        granted: 'task completion',
      },
      disabled: isInteractedWith,
      funs: async () => {
        try {
          if (task) {
            setIsInteractedWith(true)
            await axios.patch(`/tasks/${task?.id}`, {
              TaskStatus: 'Done',
            })
            socket?.current?.emit('addNotifUser', task?.TaskPairedContributorId)
            NotificationCreator({
              associativity: 'task',
              relation: 'pairedWith',
              conditionIndex: 2,
              accompaniedData: {
                projectId: project?._id,
                response: 'Granted',
                elements: [{ ...task }],
              },
              category: 'contributors',
              userId: task?.TaskPairedContributorId,
              currentUser: user,
              socket,
            })
            await axios.patch(`/notifications/${notification?._id}`, {
              interactedWith: true,
            })
          }
        } catch (error) {
          console.log(error)
        }
      },
    },
    {
      title: rtl ? 'رفض' : 'Refuse',
      descProps: {
        refuseIndex: 2,
      },
      disabled: isInteractedWith,
      funs: async () => {
        try {
          if (task) {
            setIsInteractedWith(true)
            socket?.current?.emit('addNotifUser', task?.TaskPairedContributorId)
            NotificationCreator({
              associativity: 'task',
              relation: 'pairedWith',
              conditionIndex: 2,
              accompaniedData: {
                projectId: project?._id,
                response: 'Refused',
                elements: [{ ...task }],
              },
              category: 'contributors',
              userId: task?.TaskPairedContributorId,
              currentUser: user,
              socket,
            })
            await axios.patch(`/notifications/${notification?._id}`, {
              interactedWith: true,
            })
          }
        } catch (error) {
          console.log(error)
        }
      },
    },
  ]
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            <>
              {rtl ? 'مهمة' : 'Your task'}
              <span style={style}>{task?.title}</span>
              <span style={{ display: project?.name ? null : 'none' }}>
                {rtl ? 'فى' : 'in'}
                <Link to={`/projectProfile/${project?._id}`} style={style}>
                  {project?.name}
                </Link>
              </span>
              {rtl ? 'تم إنهاؤها بواسطة' : 'has been Marked done by'}
              <Link to={`/profile/${sender?._id}`} style={style}>
                {sender?.name}
              </Link>
            </>
          }
        />
      }
      buttonsArray={buttonsArray}
    />
  )
}

export default TaskMarkedDone
