import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import {
  Avatar,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import ElementsSelectButton from './ElementsSelectButton'
import AlertMessg from '../../confirmationSystem/AlertMessg'
import i18next from 'i18next'

const styles = () => ({
  elementsAndChooseButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
    justifyContent: 'space-between',
    margin: 10,
    padding: 10,
  },
  inlineChosenelement: {
    padding: 5,
    backgroundColor: 'rgb(210, 221, 240)',
    borderRadius: 15,
    color: 'white',
  },
  select: {
    fontSize: '12px !important',
    appearance: 'none !important',
    outline: 'none !important',
    width: '250px !important',
    height: '20px !important',
  },
  contractorName: {
    fontSize: 12,
  },
  menuContractorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
  },
  '@media (max-width: 600px)': {
    select: {
      width: '180px !important',
    },
    inputLabel: {
      fontSize: '14px !important',
    },
  },
})

const Chooseelements = ({
  classes,
  selectedElementsArray,
  selectedElements,
  choosingElements,
  setClickAwayState,
  isSelectedElements,
  elements,
  showElements,
  elementTitle,
  voidElementsAlertText,
  isDarkModeActive,
}) => {
  // alert dialog funs
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <section className={classes.elementsAndChooseButton}>
      <FormControl>
        <InputLabel className={classes.inputLabel} id='subDepelementsOptions'>
          {selectedElementsArray.length === 0
            ? rtl
              ? `اختر ${elementTitle}`
              : `Choose A ${elementTitle}`
            : rtl
            ? `اختر ${elementTitle} اخر`
            : `Choose Another ${elementTitle}`}
        </InputLabel>
        <Select
          labelId='subDepelementsOptions'
          multiple
          value={selectedElements}
          onChange={choosingElements}
          renderValue={(selectionArray) =>
            elements
              .filter((element) =>
                selectionArray.includes(element?._id || element?.id)
              )
              .map((chosenElement, index) => (
                <span className={classes.inlineChosenelement} key={index}>{`${
                  chosenElement.Title ||
                  chosenElement.title ||
                  chosenElement.name ||
                  chosenElement?.ProjectName ||
                  chosenElement?.firstName + ' ' + chosenElement?.lastName
                }`}</span>
              ))
          }
          autoWidth
          className={classes.select}
          variant='standard'
          onOpen={() => {
            if (typeof setClickAwayState === 'function') setClickAwayState(true)
            if (elements.length === 0) setIsAlertDialogOpen(true)
          }}
        >
          <MenuItem value='all'>
            <ListItemIcon>
              <Checkbox
                checked={isSelectedElements}
                indeterminate={
                  selectedElements.length > 0 &&
                  selectedElements.length < elements.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={rtl ? 'اختيار الكل' : 'Select All'} />
          </MenuItem>
          {elements.map((element, elementIndex) => (
            <MenuItem key={elementIndex} value={element?._id || element?.id}>
              <ListItemIcon>
                <Checkbox
                  checked={
                    selectedElements.indexOf(element?._id || element?.id) > -1
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span className={classes.menuContractorWrapper}>
                    <Avatar
                      src={
                        element.image ||
                        element?.profilePicture ||
                        element?.projectPhoto
                      }
                      style={{ height: 30, width: 30 }}
                    />
                    <span
                      className={classes.contractorName}
                      style={{ marginLeft: !rtl && 5, marginRight: rtl && 5 }}
                    >
                      {element.Title ||
                        element.title ||
                        element.name ||
                        element?.ProjectName ||
                        element?.firstName + ' ' + element?.lastName}
                    </span>
                  </span>
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ElementsSelectButton
        selectedElementsArray={selectedElementsArray}
        showElements={showElements}
      />
      <AlertMessg
        alertText={voidElementsAlertText}
        setIsAlertDialogOpen={setIsAlertDialogOpen}
        isAlertDialogOpen={isAlertDialogOpen}
        isDarkModeActive={isDarkModeActive}
      />
    </section>
  )
}

const WrappedChooseelements = withStyles(styles)(Chooseelements)

export default WrappedChooseelements
