import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import TextPost from '../../../../../../../../../../postSystem/TextPost'
import ChannelProfileFeedSMDisTop from './ChannelPostModelComps/ChannelProfileFeedSMDisTop'
import ChannelProfileFeedSMDisCenter from './ChannelPostModelComps/ChannelProfileFeedSMDisCenter'
import ChannelProfileFeedSMDisBottom from './ChannelPostModelComps/ChannelProfileFeedSMDisBottom'
import PostProps from '../../../../../../../../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'

const styles = () => ({
  disContainer: {
    borderRadius: '10px !important',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    padding: 10,
  },
})

const ChannelPostModel = ({ classes, props, post }) => {
  const { postsUsers, DCGProps } = props
  const { isDarkModeActive } = DCGProps
  const user = postsUsers?.find((u) => u?._id === post?.userId)
  const [isCommentingSystemActive, setIsCommentingSystemActive] =
    useState(false)
  const propsII = PostProps(
    { setIsCommentingSystemActive },
    {
      imageViewerProps: true,
      commentsDialogProps: true,
    }
  )
  const postProps = {
    post,
    user,
    isCommentingSystemActive,
    ...propsII,
    ...props,
    ...DCGProps,
  }
  return (
    <section
      className={classes.disContainer}
      style={{
        background: isDarkModeActive
          ? 'rgba(31,126,222, 0.1)'
          : 'rgba(0,0,0,0.1)',
      }}
    >
      <ChannelProfileFeedSMDisTop postProps={postProps} />
      <TextPost
        commentingSystemView={false}
        margins='auto 15px 15px 15px'
        {...postProps}
      />
      <ChannelProfileFeedSMDisCenter
        commentingSystemView={false}
        {...postProps}
      />
      {/* keep the props as they are, it is intentional in the bottom. */}
      <ChannelProfileFeedSMDisBottom {...postProps} />
    </section>
  )
}

const WrappedChannelPostModel = withStyles(styles)(ChannelPostModel)

export default WrappedChannelPostModel
