import { Avatar, Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TitleHeader from '../../../../../../smallComponents/Header'
import SubDepartmentCardUnit from './MoveToAnotherSubDepFormComponents/SubDepartmentCardUnit'
import withStyles from '@mui/styles/withStyles'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DefaultImageGrapper from '../../../../../../smallComponents/DefaultImageGrapper'
import MoveToAnotherSubDepFormButtons from './MoveToAnotherSubDepFormComponents/MoveToAnotherSubDepFormButtons'
import BlocksLoader from '../../../../../../smallComponents/BlocksLoader'
import i18next from 'i18next'

const styles = () => ({
  elementsWrapper: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexFlow: 'row wrap',
    marginBottom: 10,
    position: 'relative',
  },
  viewCard: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    width: 'auto',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
  },
  memWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
    alignItems: 'center',
  },
})

const MoveToAnotherSubDepForm = ({ classes, ...props }) => {
  const { member, DCGProps } = props
  const { project, isDarkModeActive } = DCGProps
  const [subDepartmentId, setSubDepartmentId] = useState(member?.subDepId)
  const [subDepartments, setSubDepartments] = useState([])
  const [isLoding, setIsLoading] = useState(true)
  const lang = i18next.language
  const rtl = lang === 'ar'

  useEffect(() => {
    const fetchSubdeps = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/subdepartments/departmentSubDeps/${project._id}/${member?.departmentId}`
        )
        setSubDepartments(res.data.subdepartments)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (project._id && member?.departmentId !== undefined) fetchSubdeps()
  }, [project._id, member?.departmentId])
  const modSubDepartments = subDepartments.map((subdep) => ({
    title: subdep?.Name,
    image: DefaultImageGrapper('subDep'),
    id: subdep?._id,
  }))
  return isLoding ? (
    <BlocksLoader {...DCGProps} />
  ) : (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      <TitleHeader
        headerText={rtl ? 'إختر القسم الفرعى' : 'Choose the Sub-Department'}
      />
      <section className={classes.memWrapper}>
        <Link to={'/profile'}>
          <Avatar src={member.image} />
        </Link>
        <span
          style={{
            marginLeft: !rtl && 10,
            marginRight: rtl && 10,
            fontWeight: 500,
          }}
        >
          {member.name}
        </span>
      </section>
      <Card className={classes.viewCard}>
        <section className={classes.elementsWrapper}>
          {modSubDepartments.map((element, elementIndex) => (
            <SubDepartmentCardUnit
              element={element}
              key={elementIndex}
              subDepartmentId={subDepartmentId}
              setSubDepartmentId={setSubDepartmentId}
            />
          ))}
        </section>
      </Card>
      <MoveToAnotherSubDepFormButtons {...props} subDepId={subDepartmentId} />
    </article>
  )
}

const WrappedMoveToAnotherSubDepForm = withStyles(styles)(
  MoveToAnotherSubDepForm
)

export default WrappedMoveToAnotherSubDepForm
