import React from 'react'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import TextEngine from '../../../../../../../textProcessingEngine/TextEngine'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    margin: 10,
    padding: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) ',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const DepartmentRequestDetails = ({
  classes,
  card,
  exprops,
  requestsUsers,
  DCGProps,
  setRequests,
}) => {
  const request = card
  const { textContent, chosenHashTagElements } = request?.requestData
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title: rtl ? 'حذف' : 'Delete',
      funs: async () => {
        try {
          if (typeof setRequests === 'function')
            setRequests((reqs) =>
              reqs.filter((req) => req._id !== request?._id)
            )
          await axios.delete(`/requests/${request?._id}`)
          toast.warning(
            rtl ? 'تم مسح الطلب بنجاح' : 'Request has been deleted successfully'
          )
        } catch (error) {
          console.log(error)
        }
      },
    },
  ]
  return (
    <article className={classes.wrapper}>
      <TextEngine
        textContent={textContent || ''}
        textPackage={{
          chosenHashTagElements,
        }}
        charLimit={50}
        {...DCGProps}
      />
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </article>
  )
}

const WrappedDepartmentRequestDetails = withStyles(styles)(
  DepartmentRequestDetails
)

export default WrappedDepartmentRequestDetails
