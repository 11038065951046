import React from 'react'
import NotificationStatusDots from '../NotificationCoverModelComps/NotificationStatusDots'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    marginBottom: 10,
  },
  dotWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
})

const LearningSectionContent = ({ classes, learningSectionContent }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const DefinedDots = ({ color1, color2, defn }) => (
    <span className={classes.dotWrapper}>
      <NotificationStatusDots
        dots={[
          {
            color: color1,
          },
          {
            color: color2,
          },
        ]}
      />
      <span>: {defn}</span>
    </span>
  )
  return (
    <section className={classes.wrapper}>
      <span className={classes.content}>{learningSectionContent}</span>
      <DefinedDots
        color1='white'
        color2='white'
        defn={rtl ? 'لم يتم فتح الإشعار بعد' : 'Notification is not opend yet'}
      />
      <DefinedDots
        color1='green'
        color2='white'
        defn={
          rtl
            ? 'تم فتح الإشعار لكن لم يتم التفاعل معه بعد'
            : 'Notification is opened but not interacted with'
        }
      />
      <DefinedDots
        color1='green'
        color2='green'
        defn={
          rtl
            ? 'تم التفاعل مع الإشعار'
            : 'Notification is opend and interacted with'
        }
      />
    </section>
  )
}

const WrappedLearningSectionContent = withStyles(styles)(LearningSectionContent)

export default WrappedLearningSectionContent
