import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import CommentTextModel from './CommentModelComponents/CommentTextModel'
import CommentBottom from './CommentModelComponents/CommentBottom'
import CommentTop from './CommentModelComponents/CommentTop'
import CommentCenter from './CommentModelComponents/CommentCenter'
import axios from 'axios'

const styles = () => ({
  commentWrapper: {
    margin: '10px 0px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 15,
    padding: 5,
    flexDirection: 'column',
  },
})

const CommentModel = ({ classes, ...props }) => {
  const { comment, isDarkModeActive, commentsUsers, hideCommentBottom } = props
  const [user, setUser] = useState(null)
  const commentUser = commentsUsers?.find((u) => u?._id === comment?.userId)
  useEffect(() => {
    const fetchCommentUser = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${comment?.userId}`)
        setUser(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }

    if (comment?.userId && !commentUser) fetchCommentUser()
  }, [comment?.userId])
  return (
    <article
      className={classes.commentWrapper}
      style={{
        background: hideCommentBottom
          ? null
          : isDarkModeActive
          ? 'rgba(31,126,222, 0.1)'
          : 'rgba(0,0,0,0.1)',
      }}
    >
      <CommentTop {...props} commentUser={commentUser ? commentUser : user} />
      <CommentTextModel {...props} />
      <CommentCenter {...props} />
      {!hideCommentBottom && <CommentBottom {...props} />}
    </article>
  )
}

const WrappedCommentModel = withStyles(styles)(CommentModel)

export default WrappedCommentModel
