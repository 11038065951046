import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../context/AuthContext'
import TextEngineToolsFooter from '../../../../../smallComponents/TextEngineToolsFooter'
import CampaignContributors from './CampaignProfileComps/CampaignContributors'
import CampaignDetails from './CampaignProfileComps/CampaignDetails'
import CampaignInteractionButtons from './CampaignProfileComps/CampaignInteractionButtons'
import CampaignNavigator from './CampaignProfileComps/CampaignNavigator'
import CampaignSponsoringOffers from './CampaignProfileComps/CampaignSponsoringOffers'
import CampaignTop from './CampaignProfileComps/CampaignTop'

const CampaignProfile = (props) => {
  const { campaign, DCGProps, depManager } = props
  const { isDarkModeActive, project } = DCGProps
  const { user } = useContext(AuthContext)
  const [managerProfile, setManagerProfile] = useState(null)
  useEffect(() => {
    const fetchDepManager = async () => {
      try {
        const res = await axios.get(
          `/projectContributors/depManager/${campaign?.projectId}/1`
        )
        setManagerProfile(res.data.managerProfile)
      } catch (error) {
        console.log(error)
      }
    }
    fetchDepManager()
  }, [campaign?.projectId])

  const propsII = {
    ...props,
    // when i open the profile outside the project
    depManager: depManager ? depManager : managerProfile,
  }
  const authorised =
    user?._id === project?.userId ||
    user?._id === depManager?._id ||
    user?.createdProjects?.includes(campaign?.projectId) ||
    user?._id === managerProfile?._id
  return (
    <article
      style={{ width: '100%', color: isDarkModeActive ? 'white' : null }}
    >
      <CampaignTop {...propsII} />
      <CampaignDetails {...propsII} />
      <CampaignNavigator {...propsII} />
      <CampaignContributors {...props} />
      {authorised && <CampaignSponsoringOffers {...propsII} />}
      <CampaignInteractionButtons {...propsII} />
      {authorised && <TextEngineToolsFooter {...DCGProps} />}
    </article>
  )
}

export default CampaignProfile
