import React, { useContext, useState } from 'react'
import EditableGridModel from '../../../../../../../formsSystem/EditableGridModel'
import InfoDataIntialStateGen from '../../../../../../../formsSystem/InfoDataIntialStateGen'
import axios from 'axios'
import FormDataLangModulator from '../../../../../../../formsSystem/FormDataLangModulator'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import { useTranslation } from 'react-i18next'
import HashTagElementsRefiner from '../../../../../../../smallComponents/HashTagElementsRefiner'
import i18next from 'i18next'

const ChartedDetails = ({ charted, DCGProps, setCards }) => {
  const { project } = DCGProps
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const infoArray = [
    {
      title: 'Title',
      titleValue: charted?.Title,
      allowEmpty: true,
    },
    {
      title: 'Description',
      titleValue: charted?.Description,
      allowEmpty: true,
      wideMode: true,
      minRows: 4,
    },
  ]
  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))

  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(charted) || []
  )
  const newInfoData = FormDataLangModulator(infoData, t)
  const onSave = async () => {
    try {
      const res = await axios.patch(`/chartedData/${charted?._id}`, {
        ...newInfoData,
        chosenHashTagElements,
      })
      setCards((charteds) => [
        ...charteds.filter((p) => p._id !== charted?._id),
        res.data.charted,
      ])
    } catch (error) {
      console.log(error)
    }
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  const gridProps = {
    hideEditingTools: user?._id !== project?.userId,
    headerText: rtl ? 'تفاصيل الرسم البيانى' : 'Chart Details',
    infoArray,
    setInfoData,
    infoData,
    onSave: onSave,
    chosenHashTagElements,
    setChosenHashTagElements,
    ...DCGProps,
    departmentId: 2,
  }
  return <EditableGridModel gridProps={gridProps} />
}

export default ChartedDetails
