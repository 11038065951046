import React, { Suspense, useState, useContext } from 'react'
import TextEngineToolsFooter from '../../../../../../../smallComponents/TextEngineToolsFooter'
import PermissionedAccessUsers from './PermissionedAccessUsers'
import IdeaContributors from './IdeaContributors'
import IdeaInteractionButtons from './IdeaInteractionButtons'
import IdeaProfileTop from './IdeaProfileTop'
import IdeaRequests from './IdeaRequests'
import IdeaNavigators from './IdeaNavigators'
import IdeaBody from './IdeaBody'
import BlocksLoader from '../../../../../../../smallComponents/BlocksLoader'
import axios from 'axios'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import i18next from 'i18next'

const PermissionedView = (props) => {
  const { DCGProps, idea } = props
  const { isDarkModeActive } = DCGProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [infoData, setInfoData] = useState({
    Title: idea?.Title,
    Content: idea?.Content,
  })
  const { user } = useContext(AuthContext)
  const [checked, setChecked] = useState(Boolean(idea?.secured))
  const handleChange = async () => {
    setChecked(!checked)
    try {
      await axios.patch(`/ideas/${idea?._id}`, { secured: !checked })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <article
      style={{
        color: isDarkModeActive ? 'white' : null,
        marginTop: 10,
        width: '100%',
      }}
    >
      <Suspense fallback={<BlocksLoader {...DCGProps} />}>
        <IdeaProfileTop
          {...props}
          handleChange={handleChange}
          checked={checked}
        />
        <IdeaNavigators {...props} />
        <IdeaBody {...props} infoData={infoData} setInfoData={setInfoData} />
        <IdeaRequests {...props} />
        <IdeaContributors {...props} />
        <PermissionedAccessUsers {...props} />
        <IdeaInteractionButtons {...props} />
        {user?._id === idea?.userId && <TextEngineToolsFooter {...DCGProps} />}
      </Suspense>
    </article>
  )
}

export default PermissionedView
