import axios from 'axios'
import React, { useContext, useState } from 'react'
import { NotificationBodyWithAccessCard } from '../../../..'
import { AuthContext } from '../../../../../../../context/AuthContext'
import NotificationCreator from '../../../../../NotificationCreator'
import NotificationViewModel from '../../../../NotificationViewModel'
import i18next from 'i18next'

const ContributionContractEnding = (props) => {
  const { notification, project, sender, socket } = props
  const [interactedWith, setInteractedWith] = useState(
    notification?.interactedWith
  )
  const [isLoading, setIsLoading] = useState(false)
  const { user, dispatch } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const defaultAction = async () => {
    await axios.patch(`/notifications/${notification?._id}`, {
      interactedWith: true,
    })
    setInteractedWith(true)
  }
  const contractId =
    notification?.accompaniedData?.elements &&
    notification?.accompaniedData?.elements[0]?.id
  const buttonsArray = [
    {
      title: rtl
        ? isLoading
          ? 'جارى تفعيل برتوكول الغاء التشارك'
          : 'موافقة'
        : isLoading
        ? 'Activating Contribution Ending Protocol'
        : 'Agree',
      funs: async () => {
        setIsLoading(true)
        // notifiy the sender by approval
        socket.current.emit('addNotifUser', sender?._id)
        NotificationCreator({
          associativity: 'activeContract',
          relation: 'contributor',
          conditionIndex: 1,
          accompaniedData: {
            projectId: project?._id,
          },
          category: 'deals',
          userId: sender?._id,
          currentUser: user,
          socket,
        })
        // delete the contract
        await axios.delete(`/contracts/${contractId}`)
        // delete the contributur profile
        await axios.delete(`/projectContributors/${user?._id}/${project?._id}`)
        // remove the project from users contracted rpojects
        const res = await axios.patch(`/users/${user?._id}`, {
          userId: user?._id,
          contractedProjectsIds: user?.contractedProjectsIds?.filter(
            (p) => p !== project?._id
          ),
        })
        dispatch({
          type: 'UPDATE',
          payload: res.data.user,
        })
        defaultAction()
        setIsLoading(false)
      },
      disabled: interactedWith || isLoading,
    },
  ]
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? `يريد ${sender?.name} أن ينهى مشاركتك`
              : `${sender?.name} wants to end your contribution`
          }
        />
      }
      buttonsArray={buttonsArray}
    />
  )
}

export default ContributionContractEnding
