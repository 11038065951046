import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'

const styles = () => ({
  cardImageText: {
    display: 'flex',
    margin: '10px 25px 10px 25px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconCard: {
    display: 'flex',
    width: '130px  !important',
    height: '130px  !important',
    borderRadius: '10px  !important',
    transition: '0.3s  !important',
    opacity: '0.8  !important',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)  !important',
    cursor: 'pointer  !important',
    justifyContent: 'center !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)  !important',
      width: '140px  !important',
      height: '140px  !important',
      opacity: '1  !important',
    },
  },
  cardTitle: {
    display: 'flex',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '15px',
    color: 'white',
    justifyContent: 'center',
  },

  titleCard: {
    padding: '3px !important',
    backgroundColor: 'rgb(210, 221, 240) !important',
    marginTop: '5px !important',
    width: '130px !important',
    borderRadius: '10px !important',
  },

  '@media (max-width: 600px)': {
    cardImageText: {
      margin: 10,
      alignItems: 'center',
      flexDirection: 'column',
    },
    cardTitle: {
      fontSize: '12px',
    },
    cardImage: {
      width: '90px',
      height: '90px',
    },
  },
})

const IconCardModel = ({ classes, element, elementIndex, props }) => {
  const { MFSGProps, folderPathProps, modelProps, searchSystemProps } = props
  const card = element
  const cardIndex = elementIndex
  const { setCardId } = MFSGProps
  const { setSecondLevelFolderPath, setThirdLevelFolderPath } = folderPathProps
  const { setShowSearchInput } = searchSystemProps
  const { folderPathNumber } = modelProps

  return (
    <div className={classes.cardImageText}>
      <Card
        className={classes.iconCard}
        onClick={() => {
          setCardId(cardIndex)
          if (typeof setShowSearchInput === 'function')
            setShowSearchInput(false)
          if (
            typeof setSecondLevelFolderPath === 'function' &&
            folderPathNumber === 2
          )
            setSecondLevelFolderPath(card.title)
          if (
            typeof setThirdLevelFolderPath === 'function' &&
            folderPathNumber === 3
          )
            setThirdLevelFolderPath(card.title)
        }}
      >
        <span>{card?.icon}</span>
      </Card>

      <Card className={classes.titleCard}>
        <span className={classes.cardTitle}>{card.title}</span>
      </Card>
    </div>
  )
}

const WrappedIconCardModel = withStyles(styles)(IconCardModel)

export default WrappedIconCardModel
