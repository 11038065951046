import React from 'react'
import withStyles from '@mui/styles/withStyles'
import DefaultImageGrapper from './DefaultImageGrapper'

const styles = () => ({
  warapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-around',
    width: '100%',
    alignItems: 'center',
    height: '100%',
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    fontWeight: 600,
    fontSize: 20,
    alignSelf: 'center',
    display: 'flex',
  },
})

const CustomEmpty = ({
  emptyMssg,
  classes,
  imageKey,
  isDarkModeActive,
  style,
}) => {
  return (
    <section
      className={classes.warapper}
      style={{ color: isDarkModeActive ? 'white' : 'inherit' }}
    >
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
        }}
      >
        <img
          src={DefaultImageGrapper(imageKey)}
          alt='Empty'
          style={{ width: '45%', alignSelf: 'center', display: 'flex' }}
        />
        <span className={classes.text} style={style}>
          {emptyMssg}
        </span>
      </section>
    </section>
  )
}

const WrappedCustomEmpty = withStyles(styles)(CustomEmpty)

export default WrappedCustomEmpty
