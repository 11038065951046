import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const NewProjectProjectMeetingRequestNotifCover = ({
  notification,
  project,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const content = rtl
    ? `لقد تلقيت طلب اجتماع جديد فى ${project?.name}`
    : `New Meeting request from ${project?.name}`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default NewProjectProjectMeetingRequestNotifCover
