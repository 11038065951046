import axios from 'axios'
import React, { useContext } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import DefaultChannelButton from '../../../../../../channelsSystem/DefaultChannelButton'
import GalleryInfoProfileTopModel from '../../../../../../profile/ProfileComponents/ProfileControlPanelComponents/ControlPanelDialogsContentComponents/IdeasInquiriesComponents/GalleryInfoProfileTopModel'
import i18next from 'i18next'

const ResourceProfileTop = ({ DCGProps, resource, setCards }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const onGetOut = async () => {
    try {
      await axios.patch(`/resources/${resource?._id}`, {
        usersIds: resource?.usersIds?.filter((u) => u !== user?._id),
        projectsIds: resource?.projectsIds?.filter(
          (p) => !user?.createdProjects?.includes(p)
        ),
      })
    } catch (error) {
      console.log(error)
    }
  }
  const galleryProps = {
    hideEdit: user?._id !== resource?.userId,
    element: resource,
    DCGProps,
    emptyGalleryTitle: rtl ? 'هذا المورد' : 'Resource',
    pathName: `/resources/${resource?._id}`,
    setElements: setCards,
    patchPath: `resources`,
    updateElementImages: async (image) => {
      await axios.patch(`/resources/${resource?._id}`, {
        images: resource?.images?.filter((im) => im === image),
      })
    },
    CustomTopContent: (
      <div
        style={{
          marginTop: 10,
          display:
            resource?.usersIds?.includes(user?._id) ||
            resource?.userId === user?._id ||
            user?.createdProjects?.find((p) =>
              resource?.projectsIds?.includes(p)
            )
              ? null
              : 'none',
        }}
      >
        <DefaultChannelButton
          element={resource}
          DCGProps={DCGProps}
          // this will remove the user from the resource all with his projects
          onGetOut={onGetOut}
        />
      </div>
    ),
  }
  return (
    <section style={{ marginTop: 10 }}>
      <GalleryInfoProfileTopModel galleryProps={galleryProps} />
    </section>
  )
}

export default ResourceProfileTop
