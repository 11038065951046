import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const CustomerPerception = () => {
  const { t } = useTranslation('CustomerPerception')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 4,
    category: t('category_ms04'),
    title: t('title_ms04'),
    subTitle: t('subTitle_ms04'),
    main: t('main_ms04'),
    how: t('how_ms04'),
    usedInModels: [],
    tags: rtl
      ? [
          'البيانات الكمية',
          'البيانات النوعية',
          'سلوك العميل',
          'ولاء العميل',
          'رضا العملاء',
          'مقابلات العملاء',
          'مجموعات التركيز',
          'قضايا عاطفية',
          'نجاح المنتج',
          'فشل المنتج',
        ]
      : [
          'Quantitative Data',
          'Qualitative Data',
          'Customer Behavior',
          'Customer Loyalty',
          'Customer Satisfaction',
          'Customer Interviews',
          'Focus Groups',
          'Emotional Issues',
          'Product Success',
          'Product Failure',
        ],
    examples: t('examples_ms04'),
    hashTagType: 'markStraAspect',
  }
}

export default CustomerPerception
