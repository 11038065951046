import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const LayerPlayerBusinessModel = () => {
  const { t } = useTranslation('LayerPlayerBusinessModel')
  const lang = i18next.language
  const rtl = lang === 'ar'
  return {
    id: 28,
    title: t('title_bm28'),
    subTitle: t('subTitle_bm28'),
    main: t('main_bm28'),
    when: t('when_bm28'),
    who: t('who_bm28'),
    what: t('what_bm28'),
    how: t('how_bm28'),
    why: t('why_bm28'),
    examples: rtl
      ? [
          'Dennemeyer - 1962 - مقره في لوكسمبورغ',
          'PayPal - 1998 - الولايات المتحدة',
        ]
      : [
          'Dennemeyer - 1962 - Luxembourg Based',
          'PayPal - 1998 - United States',
        ],
    tags: rtl
      ? [
          'لاعب منفرد',
          'تخصص',
          'الفوز للجانبين',
          'شركة إلى شركة',
          'رائدة في هذه الصناعة',
        ]
      : [
          'Solo-Player',
          'Specialization',
          'Win-win',
          'Business-to-Business',
          'Industry Leader',
        ],
    considerations: t('considerations_bm28'),
    combinations: ['$LBM39', '$LBM29', '$LBM30'],
    hashTagType: 'businessModelTemp',
  }
}

export default LayerPlayerBusinessModel
