import React from 'react'
import { NotificationBodyWithAccessCard, NotificationViewModel } from '../../..'
import i18next from 'i18next'

const MemberGotOut = (props) => {
  const { sender } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <NotificationViewModel
      {...props}
      learningSectionContent={''}
      notificationBody={
        <NotificationBodyWithAccessCard
          {...props}
          notificationBodyContent={
            rtl
              ? `لقد غادر ${sender?.name} قناة مشتركه بينكم`
              : `${sender?.name} has left a channel you are in`
          }
        />
      }
      buttonsArray={[]}
    />
  )
}

export default MemberGotOut
