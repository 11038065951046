import axios from 'axios'

const RequestCreator = ({
  genre,
  subgenre,
  requestData,
  currentUser,
  projectId,
  elementId,
}) => {
  const newRequest = {
    // the creater of the request
    userId: currentUser?._id,
    projectId,
    genre,
    subgenre,
    requestData,
    elementId,
  }
  const updateDB = async () => {
    try {
      const res = await axios.post('/requests', newRequest)
    } catch (error) {
      console.log(error)
    }
  }
  updateDB()
}

export default RequestCreator
