import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  confirmButton: {
    backgroundColor: 'rgb(75, 7, 7) !important',
    borderRadius: '10px  !important',
    margin: '2px  !important',
    textTransform: 'none  !important',
    color: 'white  !important',
    fontWeight: '550  !important',
    display: 'flex',
    '&:hover': {
      opacity: 0.8,
    },
  },
  cancelButton: {
    backgroundColor: 'rgb(7, 6, 65) !important',
    borderRadius: '10px  !important',
    margin: '2px  !important',
    textTransform: 'none  !important',
    color: 'white  !important',
    fontWeight: '550  !important',
    display: 'flex',
    '&:hover': {
      opacity: 0.8,
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const GeneralConfirmButtons = ({
  classes,
  generalConfirmDialogClose,
  generalConfirmAction,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <span className={classes.buttonWrapper}>
      <Button
        variant='contained'
        className={classes.cancelButton}
        onClick={() => {
          generalConfirmDialogClose()
        }}
      >
        {rtl ? 'إلغاء' : 'Cancel'}
      </Button>
      <Button
        variant='contained'
        className={classes.confirmButton}
        onClick={() => {
          generalConfirmAction()
          generalConfirmDialogClose()
        }}
      >
        {rtl ? 'تأكيد' : 'Confirm'}
      </Button>
    </span>
  )
}

const WrappedGeneralConfirmButtons = withStyles(styles)(GeneralConfirmButtons)

export default WrappedGeneralConfirmButtons
